import { UserModal } from '../models/usermanagement/userModel'
import { FilterModel } from '../models/utils/filterModel'
import apiService from './api/apiManager'

class UserManagementDataService {
  /**
   * Todo get function.
   */
  async apiUsersFetch() {
    return apiService.apiGet('/user-management/user/list')
  }

  /**
   * Todo post function.
   * @param {Object} user user.
   * @param {string} name user name.
   */
  async apiUserCreate(user: UserModal) {
    return apiService.apiPost('/user-management/user/add', user)
  }

  /**
   * Todo put function.
   * @param {Object} user user.
   * @param {number} id user id.
   * @param {string} name user name.
   */
  // async apiUserUpdate(user: { id: number; name: string }) {
  //   return apiService.apiPut('/user', user.id, user)
  // }

  async apiRolesFetch() {
    return apiService.apiGet('/user-management/role-and-permission/list')
  }

  async apiRoleHistoryFetch(id: number) {
    return apiService.apiGet(
      `/user-management/role-and-permission/${id}/history`
    )
  }
  async apiRolePermissionListFetch(id: number) {
    return apiService.apiGet(`/user-management/role-and-permission/view/${id}`)
  }
  async apiRoleCreate(role: {
    name: string
    description: string
    allPermissions: boolean
  }) {
    console.log(role)
    return apiService.apiPost('/user-management/role-and-permission/add', role)
  }
  async apiRoleActive(id: number) {
    return apiService.apiActiveInactive(
      `/user-management/role-and-permission/${id}/active`
    )
  }
  async apiRoleInactive(id: number) {
    return apiService.apiActiveInactive(
      `/user-management/role-and-permission/${id}/inactive`
    )
  }
  async apiUserInactive(epfNumber: string) {
    return apiService.apiActiveInactive(
      `/user-management/user/${epfNumber}/inactive`
    )
  }
  async apiUserActive(epfNumber: string) {
    return apiService.apiActiveInactive(
      `/user-management/user/${epfNumber}/active`
    )
  }

  async apiUserLock(epfNumber: string) {
    return apiService.apiPutCommon(
      `/user-management/user/${epfNumber}/lock`,
      {}
    )
  }

  async apiUserUnLock(epfNumber: string) {
    return apiService.apiPutCommon(
      `/user-management/user/${epfNumber}/unlock`,
      {}
    )
  }

  async apiUserBlock(epfNumber: string) {
    return apiService.apiActiveInactive(
      `/user-management/user/${epfNumber}/block`
    )
  }
  async apiUserUnblock(epfNumber: string) {
    return apiService.apiActiveInactive(
      `/user-management/user/${epfNumber}/unblock`
    )
  }

  async apiFetchUserDataByEpfNumber(epfNumber: string) {
    return apiService.apiGet(`/user-management/user/view/${epfNumber}`)
  }

  async apiFetchUserAuditHistory(epfNumber: string) {
    return apiService.apiGet(`/user-management/user/${epfNumber}/history`)
  }

  async apiFetchRole(roleId: number) {
    return apiService.apiGet(
      `/user-management/role-and-permission/view/${roleId}`
    )
  }

  async apiRoleUpdate(id: number | undefined, role: any) {
    return apiService.apiPutCommon(
      `/user-management/role-and-permission/modify/${id}`,
      role
    )
  }
  async apiUserUpdate(epfNumber: string | undefined, user: any) {
    return apiService.apiPutCommon(
      `/user-management/user/modify/${epfNumber}`,
      user
    )
  }
  async apiGetUsers(filterData: FilterModel) {
    return apiService.apiPost(`/user-management/user/list`, filterData)
  }
  async apiGetUsersCount(filterData: FilterModel) {
    return apiService.apiPost(`/user-management/user/count`, filterData)
  }
  async apiGetRoles(filterData: FilterModel) {
    return apiService.apiPost(
      `/user-management/role-and-permission/list`,
      filterData
    )
  }
  async apiGetRolesCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/user-management/role-and-permission/count`,
      filterData
    )
  }

  async apiRoleLock(id: number) {
    return apiService.apiPutCommon(
      `/user-management/role-and-permission/${id}/lock`,
      {}
    )
  }

  async apiRoleUnLock(id: number) {
    return apiService.apiPutCommon(
      `/user-management/role-and-permission/${id}/unlock`,
      {}
    )
  }
}

export default new UserManagementDataService()
