import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { ModuleModal } from '../../../models/package/moduleModal'
import { getUserModules } from '../../../store/user/selectors'
import {
  applicationFile,
  fileAllocations,
  fileRegistrationRoute,
} from '../../../utils/constants'
import ApplicationFile from './applicationFile/ApplicationFile'
import FileAllocation from './fileAllocation/FileAllocation'
import FileRegistrationModule from './fileRegistration/FileRegistrationModule'

const FileRegistration = () => {
  const { path } = useRouteMatch()
  const moduleList = useSelector(
    getUserModules('application-registration', 'file-registration')
  )

  const renderModules = () => {
    return moduleList.map((module: ModuleModal, index: number) => {
      return (
        <Col key={`module-${index}`} xs={12} sm={12} lg={4} className="mb-4">
          <TileButtonComponent
            name={module.description}
            routeURL={module.name}
          />
        </Col>
      )
    })
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">File Registration</Row>
        <Row className="page-tiles-layout">{renderModules()}</Row>
      </Route>
      <Route
        path={`${path}/${fileRegistrationRoute}`}
        component={FileRegistrationModule}
      />
      <Route path={`${path}/${applicationFile}`} component={ApplicationFile} />
      <Route path={`${path}/${fileAllocations}`} component={FileAllocation} />
    </Switch>
  )
}

export default FileRegistration
