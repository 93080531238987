import produce from 'immer'
import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import userDataService from '../../services/userService'
import { changeLoadingRequest } from '../../store/app/actionTypes'
import { getUser } from '../../store/user/selectors'
import { handleNotification } from '../../utils/utilFunctions'
import emlem from '../../assets/images/login-emlem.png'
import { officeSubTitle, officeTitle } from '../../utils/labels'
interface FormErrorModel {
  currentPasswordError: string
  newPasswordError: string
  confirmPasswordError: string
}

const ChangePasswordPageComponent = () => {
  const history = useHistory()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({} as FormErrorModel)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const findFormErrors = () => {
    const newErrors = {} as FormErrorModel
    // Current password error
    if (!currentPassword || currentPassword === '') {
      newErrors.currentPasswordError = 'cannot be blank!'
    }

    // New password error
    if (!newPassword || newPassword === '') {
      newErrors.newPasswordError = 'cannot be blank!'
    }

    // Confirm password error
    if (!confirmPassword || confirmPassword === '') {
      newErrors.confirmPasswordError = 'cannot be blank!'
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPasswordError = 'password not match'
    }
    return newErrors
  }

  async function handleSubmit() {
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setErrors(newErrors)
      dispatch(changeLoadingRequest())
      const results = await userDataService.apiChangePasswordFirstTime({
        username: user.epfNumber,
        password: currentPassword,
        newPassword: newPassword,
      })
      dispatch(changeLoadingRequest())
      handleNotification(results, 'Password Changed Successfully')
      if (results.status === 200) {
        history.push('/login')
      }
    }
  }
  return (
    <Container className="login-form-layout">
      <Row className="justify-content-center">
        <Col sm={12} md={6}>
          <Form>
            <Row className="justify-content-center">
              <img src={emlem} alt="" className="login-emlem" />
            </Row>
            <Row>
              <Col sm={12}>
                <div className="login-heading-title">{officeTitle}</div>
                <div className="login-sub-heading-title">{officeSubTitle}</div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <div className="login-heading-title">{'Password Change'}</div>
              </Col>
            </Row>
            <br></br>
            <Form.Group className="mb-3" controlId="changePasswordCurrent">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Current Password"
                isInvalid={!!errors.currentPasswordError}
                onChange={(e) => {
                  setCurrentPassword(e.target.value)
                  if (!!errors['currentPasswordError']) {
                    const newError = produce(errors, (draft) => {
                      draft.currentPasswordError = ''
                    })
                    setErrors(newError)
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPasswordError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="changePasswordNew">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                isInvalid={!!errors.newPasswordError}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                  if (!!errors['newPasswordError']) {
                    const newError = produce(errors, (draft) => {
                      draft.newPasswordError = ''
                    })
                    setErrors(newError)
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPasswordError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="changePasswordConfirm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                isInvalid={!!errors.confirmPasswordError}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                  if (!!errors['confirmPasswordError']) {
                    const newError = produce(errors, (draft) => {
                      draft.confirmPasswordError = ''
                    })
                    setErrors(newError)
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPasswordError}
              </Form.Control.Feedback>
            </Form.Group>
            <button className="login-btn" type="button" onClick={handleSubmit}>
              Submit
            </button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ChangePasswordPageComponent
