import React, { FC, useEffect, useState } from 'react'
import { Container, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import dashboardService from '../../services/dashboardService'
import { getSelectedTimeFilters } from '../../store/dashboard/selectors'
import { fileRedirectUtil } from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'

export interface AllocateModalProps {
  status: string
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
}

const DashboardItemsModal: FC<AllocateModalProps> = ({ status, onCancel }) => {
  const timeFilters = useSelector(getSelectedTimeFilters)
  const [applicationList, setApplicationList] = useState([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    async function getApplications() {
      const response = await dashboardService.apiDashboardDataView({
        status: status,
        to: timeFilters.filter.to,
        from: timeFilters.filter.value,
        timeFilterIndex: timeFilters.index,
      })
      console.log(response)
      console.log(applicationList)
      if (response.status === 200) {
        setApplicationList(response.data)
      }
      setLoading(false)
    }
    getApplications()
  }, [])

  const handleClick = (file: FileRegistrationModel) => {
    console.log(file)
    const basePath = fileRedirectUtil(
      file.status,
      file.programType,
      file.scheme,
      file.id
    )
    // switch (status.toUpperCase()) {
    //   case 'FILE DRAFTED':
    //     basePath = `application-registration/file-registration/file-registration/draft/${file.id}`
    //     break
    //   case 'APPLICATION DRAFTED':
    //     basePath = `application-registration/${file.programType.toLowerCase()}/${file.scheme.toLowerCase()}/view/${
    //       file.id
    //     }`
    //     break
    //   case 'APPLICATION CREATION PENDING':
    //     basePath = `application-registration/${file.programType.toLowerCase()}/${file.scheme.toLowerCase()}/view/${
    //       file.id
    //     }`
    //     break
    //   case 'APPLICATION RETURNED':
    //     basePath = `application-registration/${file.programType.toLowerCase()}/${file.scheme.toLowerCase()}/return/${
    //       file.id
    //     }`
    //     break
    //   default:
    //     basePath = ''
    //     break
    // }

    if (basePath != '') {
      history.push(basePath)
    }
  }

  const renderFilesList = () => {
    return applicationList.map((file: FileRegistrationModel, index: number) => {
      console.log(file)
      return (
        <Row
          className="file-item mb-3"
          key={`file-${index}`}
          role={'button'}
          onClick={() => {
            handleClick(file)
          }}
        >
          {file.status.toUpperCase() === 'FILE DRAFTED' ? (
            <>
              <div>
                <span className="header-span">Applicant : </span>
                <span>{file.applicantName}</span>
              </div>
              <div>
                <span className="header-span">Scheme : </span>
                <span>{file.scheme}</span>
              </div>
              <div>
                <span className="header-span">Created Date : </span>
                <span>
                  {new Date(file.createdDateTime * 1000).toLocaleString()}
                </span>
              </div>
            </>
          ) : (
            <>
              <div>
                <span className="header-span">{file.referenceNumber}</span>
              </div>
              <div>
                <span className="header-span">Applicant : </span>
                <span>{file.applicantName}</span>
              </div>
            </>
          )}
        </Row>
      )
    })
  }

  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="dashboard-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Application Files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="application-file-modal">
          {loading ? (
            <div className="row justify-content-center">
              <LoadingComponent />
            </div>
          ) : (
            <>{renderFilesList()}</>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default DashboardItemsModal
