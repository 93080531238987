import produce from 'immer'
import './ApplicationModify.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory, Route, Switch } from 'react-router-dom'
import IterminalTableComponent from '../../components/table/IterminalTableComponent'
import { filtersSetRequest } from '../../store/filter/actions'
import {
  compensationApplicationsFilters,
  reportTableColumn,
} from '../../utils/metaData'
import { getPageCount } from '../../utils/tableFunctions'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import FileView from './FileView'
import dashboard_icon from '../../assets/images/nav-icons/enquire-application.svg'
import ApplicationModificationService from '../../services/applicationModification/ApplicationModificationService'
import { CommonFilterModel } from '../../models/utils/commonFilterModel'
import FilterButton from '../../components/common/filterButton/FilterButton'

const ApplicationModify = () => {
  const [file, setFile] = useState({} as FileRegistrationModel)

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  } as CommonFilterModel)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const [state, setstate] = useState([])
  const [loading, setLoading] = useState(true)
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationApplicationsFilters }))

    getApplicationFiles()
  }, [])
  const columns = React.useMemo(() => reportTableColumn, [])

  async function getApplicationFiles() {
    await retriveDataCountAndData(filter)
    setLoading(false)
  }

  const retriveApplicationFilesData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)
    const applicationData = await ApplicationModificationService.apiGetFiles(
      newFilter
    )
    setstate(applicationData.data)
    setLoading(false)
  }

  async function retriveDataCountAndData(filterData: CommonFilterModel) {
    const applicationCountData =
      await ApplicationModificationService.apiGetFilesCount(filterData)
    if (applicationCountData.status === 200) {
      setDatacount(applicationCountData.data.count)
      setPageCount(getPageCount(applicationCountData.data.count, filter.limit))
      const applicationData = await ApplicationModificationService.apiGetFiles(
        filterData
      )
      setstate(applicationData.data)
    } else {
      setDatacount(0)
      setPageCount(0)
      setstate([])
    }
  }

  const handleView = (row: FileRegistrationModel) => {
    setFile(row)
    history.push(`${path}/view/${row.id}`)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      getApplicationFiles()
    }
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <img src={dashboard_icon} alt="" />
              <span className="m-2">Application Modification</span>
            </Col>
          </Row>
          <Row className="enquire-page-layout">
            <Col>
              {!loading && (
                <div className="mb-4 d-flex">
                  <Form.Control
                    type="text"
                    className="me-3"
                    onChange={(e: any) => {
                      const newFilter = produce(filter, (draft) => {
                        draft.filterValue = e.target.value
                        draft.limit = 10
                        draft.page = 1
                      })
                      setFilter(newFilter)
                    }}
                    onKeyDown={handleKeyPress}
                    placeholder="Enter text to filter data..."
                  />
                  <FilterButton onClick={getApplicationFiles} />
                </div>
              )}
              <div>
                <IterminalTableComponent
                  columns={columns}
                  data={state}
                  fetchData={retriveApplicationFilesData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={dataCount}
                  onClickRow={(row: any) => {
                    handleView(row)
                  }}
                />
              </div>
            </Col>
          </Row>
        </Route>
        <Route
          path={`${path}/view/:id`}
          render={(props) => <FileView applicationFile={file} {...props} />}
        />
      </Switch>
    </>
  )
}
export default ApplicationModify
