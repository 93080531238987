import React, { FC, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import './Modals.scss'
import Select from 'react-select'
import { reasonsForCancelApplication } from '../../utils/metaData'
import {
  chequeCancel,
  applicationReturnWithModifyIndex,
} from '../../utils/constants'

export interface ChequeCancelReturnConfirmationModalProps {
  name: string
  message: string
  isRemark?: boolean
  onConfirm?: any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  section?: string
  status?: string
  scheme?: string
}

const ChequeCancelReturnConfirmationModal: FC<ChequeCancelReturnConfirmationModalProps> =
  ({
    name,
    message,
    isRemark = false,
    onConfirm,
    onCancel,
    section,
    status,
    scheme,
  }) => {
    const [remark, setRemark] = useState('')

    let reasonsForCancelCheque = []

    if (scheme != 'RLP') {
      if (status == 'Print Pending') {
        reasonsForCancelCheque = [
          {
            label: 'Applicant deceased',
            value: 'Applicant deceased',
          },
          {
            label: 'Wrong Bank Details',
            value: 'Wrong Bank Details',
          },
          {
            label: 'Account Closed',
            value: 'Account Closed',
          },
          {
            label: 'Doman Account',
            value: 'Doman Account',
          },
        ]
      } else {
        reasonsForCancelCheque = [
          {
            label: 'Cheque Damage',
            value: 'Cheque Damage',
          },
          {
            label: 'Half Printed',
            value: 'Half Printed',
          },
          {
            label: 'Applicant deceased',
            value: 'Applicant deceased',
          },
          {
            label: 'Wrong Bank Details',
            value: 'Wrong Bank Details',
          },
          {
            label: 'Account Closed',
            value: 'Account Closed',
          },
          {
            label: 'Doman Account',
            value: 'Doman Account',
          },
        ]
      }
    } else {
      reasonsForCancelCheque = [
        {
          label: 'Cheque Damage',
          value: 'Cheque Damage',
        },
        {
          label: 'Half Printed',
          value: 'Half Printed',
        },
        {
          label: 'Wrong Bank Details',
          value: 'Wrong Bank Details',
        },
        {
          label: 'Account Closed',
          value: 'Account Closed',
        },
        {
          label: 'Doman Account',
          value: 'Doman Account',
        },
      ]
    }

    return (
      <Modal
        size="lg"
        show={true}
        onHide={onCancel}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="custom-modal-layout"
        contentClassName="custom-modal-content"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="custom-modal-header"
        >
          <Modal.Title id="example-modal-sizes-title-sm">{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRemark ? (
            <Form id="remark-form">
              {message}
              <Form.Group className="mb-3" controlId="remark">
                <Form.Label>Remark</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Remark"
                  value={remark}
                  onChange={(e) => {
                    setRemark(e.target.value)
                  }}
                />
              </Form.Group>
            </Form>
          ) : (
            <div>{message}</div>
          )}
          {section === chequeCancel && (
            <Form id="cancel-cheque-form">
              <Form.Group className="mb-3" controlId="remark">
                <Form.Label>Reason</Form.Label>
                <Select
                  placeholder="Select reason"
                  options={reasonsForCancelCheque}
                  onChange={(selectedOption: any) => {
                    setRemark(selectedOption.value)
                  }}
                />
              </Form.Group>
            </Form>
          )}
          {section === applicationReturnWithModifyIndex && (
            <Form id="cancel-cheque-form">
              <Form.Group className="mb-3" controlId="remark">
                <Form.Label>Level</Form.Label>
                <Select
                  placeholder="Select reason"
                  options={reasonsForCancelApplication}
                  onChange={(selectedOption: any) => {
                    setRemark(selectedOption.value)
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
          {isRemark ? (
            <>
              {remark !== '' && (
                <button
                  disabled={
                    (section === applicationReturnWithModifyIndex ||
                      section === chequeCancel) &&
                    !remark
                  }
                  className="save-button"
                  onClick={() => {
                    onConfirm(remark)
                  }}
                >
                  Ok
                </button>
              )}
            </>
          ) : (
            <button
              id="confirmation-ok"
              disabled={
                (section === applicationReturnWithModifyIndex ||
                  section === chequeCancel) &&
                !remark
              }
              className="save-button"
              onClick={() => {
                onConfirm(remark)
              }}
            >
              Ok
            </button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }

export default ChequeCancelReturnConfirmationModal
