import { FilterModel } from '../../models/utils/filterModel'
import apiService from '../api/apiManager'

class ReportDataService {
  async apiDetailsReportCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/reports/application-reports/application-details-report/count`,
      filterData
    )
  }

  async apiPaymentReportCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/reports/application-reports/application-payment-summary-report/count`,
      filterData
    )
  }

  async apiDetailsReportList(filterData: FilterModel) {
    return apiService.apiPostBinary(
      `/reports/application-reports/application-details-report`,
      filterData
    )
  }

  async getSummaryReportByChequeId(id: number) {
    return apiService.apiGet(
      `/cheque-payment/cheque-print/print/summary-report-by-cheque-id/${id}`
    )
  }
}

export default new ReportDataService()
