import React, { FC } from 'react'
import { Row } from 'react-bootstrap'
import SectionButton from './SectionButton'

export interface ITerminalWizardComponentProps {
  sectionList: any[]
  activeSection: number
  validationLevel: number
  onClick?: any
}

const ITerminalWizardComponent: FC<ITerminalWizardComponentProps> = ({
  sectionList,
  activeSection,
  validationLevel,
  onClick,
}) => {
  const renderSectionButton = () => {
    return sectionList.map((section: any, index: number) => {
      return (
        <SectionButton
          key={`section-button-${index}`}
          name={`${section.name}`}
          number={index + 1}
          isLeft={index === 0 ? false : true}
          isRight={index === sectionList.length - 1 ? false : true}
          available={validationLevel >= index}
          active={activeSection === index + 1}
          onClick={() => {
            onClick(index + 1)
          }}
        />
      )
    })
  }

  return (
    <Row className="section-layout">
      <Row className="section-tab-layout">{renderSectionButton()}</Row>
    </Row>
  )
}

export default ITerminalWizardComponent
