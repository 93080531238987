import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import { schemesList, status } from '../../utils/metaData'
import FilterButton from '../common/filterButton/FilterButton'
import Select from 'react-select'
import metaDataService from '../../services/metaDataService'
import { CommonTypeOption } from '../../models/selectOptionModels/commonOptionType'
import {
  getFilterObjectList,
  reselectFilterOption,
} from '../../utils/utilFunctions'

export interface FileAllocationFiltersProps {
  filterData: FilterOptionModel[]
  onFilter: any
}

const TypesOfInjuriesFilters: FC<FileAllocationFiltersProps> = ({
  filterData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFilter,
}) => {
  const [filters, setFilters] = useState(filterData)
  const [show, setShow] = useState(false)
  const options: CommonTypeOption[] = []
  const [injuryCategoryList, setInjuryCategoryList] = useState(options)
  const [typeofDisablement, setTypeofDisablement] = useState(options)
  const [requestedFilters, setRequestedFilters] = useState(
    [] as FilterOptionModel[]
  )
  useEffect(() => {
    setFilters(filterData)
    async function getMetaData() {
      const typeofDisablementData =
        await metaDataService.apiGetTypeOfDisablementList()
      const typeofDisablementList: CommonTypeOption[] = []
      for (let i = 0; i < typeofDisablementData.data.length; i++) {
        const typeofDisablementObject: CommonTypeOption = {
          value: typeofDisablementData.data[i].name,
          label: typeofDisablementData.data[i].name,
        }
        typeofDisablementList.push(typeofDisablementObject)
      }
      setTypeofDisablement(typeofDisablementList)
      const injuryCategoryListData =
        await metaDataService.apiGetCategoryOfInjuryList()
      const injuryCategoryList: CommonTypeOption[] = []
      for (let i = 0; i < injuryCategoryListData.data.length; i++) {
        const injuryCategoryObject: CommonTypeOption = {
          value: injuryCategoryListData.data[i].name,
          label: injuryCategoryListData.data[i].name,
        }
        injuryCategoryList.push(injuryCategoryObject)
      }
      setInjuryCategoryList(injuryCategoryList)
    }
    getMetaData()
  }, [show])
  return (
    <>
      <FilterButton
        onClick={() => {
          setShow(true)
        }}
      />
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false)
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="custom-modal-layout"
        contentClassName="custom-modal-content"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="custom-modal-header"
        >
          <Modal.Title id="example-modal-sizes-title-sm">{`Filter`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            {filters.length > 0 && (
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="typeofDisablement"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[0].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                        const newRequestedFilter = reselectFilterOption(
                          requestedFilters,
                          e.target.checked,
                          filters[0].property
                        )
                        setRequestedFilters(newRequestedFilter)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Type of Disablement`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      isMulti
                      isDisabled={!filters[0].selected}
                      options={typeofDisablement}
                      onChange={(selectedOption) => {
                        const returnFilters = getFilterObjectList(
                          selectedOption,
                          filters[0]
                        )
                        const previousFilters = requestedFilters.filter(
                          (rFilter: FilterOptionModel) => {
                            return rFilter.property != filters[0].property
                          }
                        )
                        const newRequestedFilters =
                          previousFilters.concat(returnFilters)
                        setRequestedFilters(newRequestedFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="categoryofInjury"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[1].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                        const newRequestedFilter = reselectFilterOption(
                          requestedFilters,
                          e.target.checked,
                          filters[1].property
                        )
                        setRequestedFilters(newRequestedFilter)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Category of Injury`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      isMulti
                      isDisabled={!filters[1].selected}
                      options={injuryCategoryList}
                      onChange={(selectedOption) => {
                        const returnFilters = getFilterObjectList(
                          selectedOption,
                          filters[1]
                        )
                        const previousFilters = requestedFilters.filter(
                          (rFilter: FilterOptionModel) => {
                            return rFilter.property != filters[1].property
                          }
                        )
                        const newRequestedFilters =
                          previousFilters.concat(returnFilters)
                        setRequestedFilters(newRequestedFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="injuryDescription"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[2].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[2].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                        const newRequestedFilter = reselectFilterOption(
                          requestedFilters,
                          e.target.checked,
                          filters[2].property
                        )
                        setRequestedFilters(newRequestedFilter)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Injury Description`}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[2].selected}
                      type="text"
                      value={filters[2].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters[2],
                          (draft: FilterOptionModel) => {
                            draft.value = e.target.value
                          }
                        )
                        const previousFilters = requestedFilters.filter(
                          (rFilter: FilterOptionModel) => {
                            return rFilter.property != filters[2].property
                          }
                        )
                        const newRequestedFilters =
                          previousFilters.concat(newFilters)
                        setRequestedFilters(newRequestedFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formScheme">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[3].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[3].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                        const newRequestedFilter = reselectFilterOption(
                          requestedFilters,
                          e.target.checked,
                          filters[3].property
                        )
                        setRequestedFilters(newRequestedFilter)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Scheme`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      isMulti
                      isDisabled={!filters[3].selected}
                      options={schemesList}
                      onChange={(selectedOption) => {
                        const returnFilters = getFilterObjectList(
                          selectedOption,
                          filters[3]
                        )
                        const previousFilters = requestedFilters.filter(
                          (rFilter: FilterOptionModel) => {
                            return rFilter.property != filters[3].property
                          }
                        )
                        const newRequestedFilters =
                          previousFilters.concat(returnFilters)
                        setRequestedFilters(newRequestedFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="status">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[4].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                        const newRequestedFilter = reselectFilterOption(
                          requestedFilters,
                          e.target.checked,
                          filters[4].property
                        )
                        setRequestedFilters(newRequestedFilter)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Status`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      isMulti
                      isDisabled={!filters[4].selected}
                      options={status}
                      onChange={(selectedOption) => {
                        const returnFilters = getFilterObjectList(
                          selectedOption,
                          filters[4]
                        )
                        const previousFilters = requestedFilters.filter(
                          (rFilter: FilterOptionModel) => {
                            return rFilter.property != filters[4].property
                          }
                        )
                        const newRequestedFilters =
                          previousFilters.concat(returnFilters)
                        setRequestedFilters(newRequestedFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="save-button"
            onClick={() => {
              onFilter(requestedFilters)
              setShow(false)
            }}
          >
            Filter Results
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TypesOfInjuriesFilters
