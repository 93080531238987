import { PdAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { PdDependentDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GsdDataService {
  async apiFetchGsdList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsd/view/list`,
      filterData
    )
  }

  async apiFetchGsdListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsd/view/count`,
      filterData
    )
  }
  async apiFetchGsdReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsd/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGsdReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsd/file-return/view/count`,
      filterData
    )
  }

  async apiFetchGsdFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsd/view/${id}`
    )
  }

  async apiGsdProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/proceed`,
      file
    )
  }

  async apiGsdModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/file-return/modify/${file.id}`,
      file
    )
  }

  async apiGsdReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/${id}/reject`,
      { remark: remark }
    )
  }

  async apiGsdReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/${id}/return`,
      { remark: remark }
    )
  }

  async apiGsdClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiGsdReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiGsdMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/${id}/merge`,
      idList
    )
  }

  async apiGsdAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/applicant`,
      file
    )
  }

  async apiGsdAddAggrivedPersonDetailsDetails(
    file: PdAggrivedPersonDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/${file.applicationFileId}/aggrieved_person`,
      file
    )
  }

  async apiGsdGetAggrivedPersonDetailsDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsd/view/${id}/aggrieved_person`
    )
  }

  async apiGsdGetDependentDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsd/view/${id}/dependents`
    )
  }

  async apiGsdAddDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/${file.applicationFileId}/dependent`,
      file
    )
  }

  async apiGsdDeleteDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiDelete(
      `/application-registration/compensation/gsd/add/${file.applicationFileId}/dependent`,
      file.id
    )
  }

  async apiGsdAddDependantNextOfKinDetails(file: NextOfKinModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/${file.applicationFileId}/dependent/${file.dependentId}/next_of_kin`,
      file
    )
  }

  async apiGsdGetDependantNextOfKinDetails(
    fileId: number,
    dependentId: number
  ) {
    return apiService.apiGet(
      `/application-registration/compensation/gsd/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiGsdGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsd/view/${id}/document`
    )
  }
  async apiGsdAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/gsd/add/${id}/document`,
      formData
    )
  }

  async apiGsdRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsd/add/${id}/register`,
      {}
    )
  }
}

export default new GsdDataService()
