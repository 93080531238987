import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {
  RouteComponentProps,
  useHistory,
  //   useLocation,
} from 'react-router-dom'
import { manageStampChargeModel } from '../../../models/systemConfiguration/manageStampCharges/manageStampChargeModel'
import manageStampChargesService from '../../../services/systemConfiguration/manageStampCharges/manageStampChargesService'
import stamp_img from '../../../assets/images/systemConfiguration/manage-stamp-charges.svg'
import { currencyMask } from '../../../utils/utilFunctions'

type TParams = { id: string }

const ManageStampChargerView = ({ match }: RouteComponentProps<TParams>) => {
  const [stampCharge, setSampCharge] = useState({} as manageStampChargeModel)
  const history = useHistory()
  useEffect(() => {
    async function getStampCharge() {
      const stampCharge = await manageStampChargesService.apiGetStampChargeByID(
        parseInt(match.params.id)
      )
      setSampCharge(stampCharge.data)
    }
    getStampCharge()
  }, [])
  const handleModify = () => {
    history.push(
      `/system-configuration/manage-stamp-charges/modify/${match.params.id}`
    )
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={stamp_img} alt="" />
          <span className="m-2">Manage Stamp Charges</span>
        </Col>
      </Row>
      <Row className="role-and-permission-body-layout mt-4">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Add Stamp Charges</div>
          <Form id="file-registration-form">
            <Form.Group className="mb-3" controlId="stampCharge">
              <Form.Label>Stamp Charge (Rs.)</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={
                  stampCharge.stampCharge
                    ? currencyMask(stampCharge.stampCharge)
                    : ''
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                disabled
                value={
                  !stampCharge.startDate
                    ? ''
                    : new Date(stampCharge.startDate * 1000)
                        .toISOString()
                        .substr(0, 10)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                disabled
                value={
                  !stampCharge.endDate
                    ? ''
                    : new Date(stampCharge.endDate * 1000)
                        .toISOString()
                        .substr(0, 10)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control type="text" disabled value={stampCharge.status} />
            </Form.Group>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button className="ok-button">Ok</button>
                <button className="modify-button" onClick={handleModify}>
                  Modify
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ManageStampChargerView
