import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { OtherDetailsModel } from '../../models/applicationRegistration/loan/otherDetails'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { currencyMask } from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'

export interface OtherDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  packageName?: string
}

const OtherDetailsSection: FC<OtherDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  packageName = 'application-registration',
}) => {
  const [loading, setLoading] = useState(true)
  const [otherDetails, setOtherDetails] = useState({} as OtherDetailsModel)

  useEffect(() => {
    async function getMetaData() {
      let otherDetailsData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        otherDetailsData = await enquireApplicationService.apiGetOthersDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        otherDetailsData = await common.apiGetOthersDetails(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }
      if (otherDetailsData.status === 200 && otherDetailsData.data.id != null) {
        setOtherDetails(otherDetailsData.data)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Other Details</div>
            <Form id="file-other-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Other Loan Details</div>
                <Row className="mb-4">
                  <Form.Group as={Col} controlId="formAnyRehabilitated">
                    <Form.Label>
                      Any Rehabilitated Loans received previously
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyRehabilitated"
                          label="Yes"
                          type="radio"
                          id={`formAnyRehabilitated-N`}
                          checked={
                            otherDetails.anyRehabilitatedLoansReceivedPreviously
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyRehabilitated"
                          label="No"
                          type="radio"
                          id={`formAnyRehabilitated-O`}
                          checked={
                            !otherDetails.anyRehabilitatedLoansReceivedPreviously
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {otherDetails.anyRehabilitatedLoansReceivedPreviously && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formOutStandingAmount">
                        <Form.Label>Outstanding Amount</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Outstanding Amount"
                          value={
                            otherDetails.outstandingAmount
                              ? currencyMask(otherDetails.outstandingAmount)
                              : ''
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formOutStandingBankName">
                        <Form.Label>{`Bank Name`}</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Bank Name"
                          value={otherDetails.bank?.name}
                        />
                      </Form.Group>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyPropertyCom">
                    <Form.Label>
                      Request for any Property Compensation
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyPropertyCom"
                          label="Yes"
                          type="radio"
                          id={`formAnyPropertyCom-N`}
                          checked={
                            otherDetails.requestForAnyPropertyCompensation
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyPropertyCom"
                          label="No"
                          type="radio"
                          id={`formAnyPropertyCom-O`}
                          checked={
                            !otherDetails.requestForAnyPropertyCompensation
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {otherDetails.requestForAnyPropertyCompensation && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDetailsOfApplicant">
                        <Form.Label>Details of the Application</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={otherDetails.detailsOfTheApplication}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formAnyPropertyDamage">
                        <Form.Label>
                          Any Property damages due to violence activities?
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Check
                              disabled
                              inline
                              name="formAnyPropertyDamage"
                              label="Yes"
                              type="radio"
                              id={`formAnyPropertyDamage-N`}
                              checked={
                                otherDetails.anyPropertyDamagesDuetoViolenceActivities
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              disabled
                              inline
                              name="formAnyPropertyDamage"
                              label="No"
                              type="radio"
                              id={`formAnyPropertyDamage-O`}
                              checked={
                                !otherDetails.anyPropertyDamagesDuetoViolenceActivities
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Row>
                  </>
                )}
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">
                  Self Employement Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formNature">
                    <Form.Label>
                      Nature of the Self-Employment that apply for loan
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Nature of the Self-Employment that apply for loan"
                      value={otherDetails.natureOfTheSelfEmployment}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formPreviousExp">
                    <Form.Label>
                      Have Previous experiences in self-employment that applying
                      for
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formPreviousExp"
                          label="Yes"
                          type="radio"
                          id={`formPreviousExp-N`}
                          checked={otherDetails.havePreviousExperiences}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formPreviousExp"
                          label="No"
                          type="radio"
                          id={`formPreviousExp-O`}
                          checked={
                            !otherDetails.anyPropertyDamagesDuetoViolenceActivities
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formYears">
                    <Form.Label>For how many years</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="For how many years"
                      value={otherDetails.yearOfPreviousExperiences}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formTrainedYears">
                    <Form.Label>If trained, for how many years</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="If trained, for how many years"
                      value={otherDetails.yearOfTrainedExperiences}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">Loan Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRequiredLoanAmount">
                    <Form.Label>Required Loan Amount</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Required Loan Amount"
                      value={
                        otherDetails.requiredLoanAmount
                          ? currencyMask(otherDetails.requiredLoanAmount)
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formRepaymentPeriod">
                    <Form.Label>Repayment period (in months)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Repayment period"
                      value={otherDetails.repaymentPeriod}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGracePeriod">
                    <Form.Label>Grace Period (in months)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Grace Period"
                      value={otherDetails.gracePeriod}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-4 mb-3">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formBankName">
                    <Form.Label>{`Bank Name`}</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Bank Name"
                      value={otherDetails.bank ? otherDetails.bank.name : ''}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBranchkName">
                    <Form.Label>{`Branch Name`}</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Branch Name"
                      value={
                        otherDetails.branch ? otherDetails.branch.name : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAccountNumber">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Account Number"
                      value={otherDetails.accountNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default OtherDetailsSection
