import produce from 'immer'
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { FilterModel } from '../../../models/utils/filterModel'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { userFilters } from '../../../utils/metaData'
import { getPageCount } from '../../../utils/tableFunctions'
import AddUserComponent from './AddUserComponent'
import ModifyUserComponent from './ModifyUserComponent'
import ViewUserComponent from './ViewUserComponent'
import UserManagementDataService from '../../../services/userManagementService'
import UserFilter from '../../../components/filters/UserFilter'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'

const UsersComponent = () => {
  const [state, setState] = useState([])
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('user-management', 'user')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: userFilters }))
    async function getData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getData()
  }, [location.pathname])

  async function retriveDataCountAndData(filterData: FilterModel) {
    const userDataCountData = await UserManagementDataService.apiGetUsersCount(
      filterData
    )
    setDatacount(userDataCountData.data.count)
    setPageCount(getPageCount(userDataCountData.data.count, filterOption.limit))
    const userData = await UserManagementDataService.apiGetUsers(filterData)
    setState(userData.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retriveData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const userData = await UserManagementDataService.apiGetUsers(newFilter)
    setState(userData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'EPF Number',
        accessor: 'epfNumber',
      },
      {
        Header: 'Created Date/Time',
        accessor: 'createdDateTime',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (epfNumber: number) => {
    history.push(`${path}/view/${epfNumber}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <span className="m-2">User</span>
        </Col>
      </Row>

      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <Row>
                <Col sm={6}>
                  {checkPermission('view') === true && !loading && (
                    <UserFilter
                      filterData={filters}
                      onFilter={(e: FilterOptionModel[]) => {
                        dispatch(filtersSetRequest({ filters: e }))
                        retriveFilterData(
                          e.filter(
                            (filter: FilterOptionModel) =>
                              filter.selected === true
                          )
                        )
                      }}
                    />
                  )}
                </Col>
                <Col sm={6} className="d-flex justify-content-end">
                  {checkPermission('add') === true && (
                    <ActionButtonComponent name="Add" routeURL="add" />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <IterminalTableComponent
                columns={columns}
                data={state}
                fetchData={retriveData}
                loading={loading}
                pageCount={pageCount}
                totalCount={dataCount}
                onClickRow={(row: any) => {
                  console.log(row)
                  handleView(row.epfNumber)
                }}
              />
            </Col>
          </Row>
        </Route>
        <Route path={`${path}/add`} component={AddUserComponent} />
        <Route path={`${path}/view/:epfNumber`} component={ViewUserComponent} />
        <Route
          path={`${path}/modify/:epfNumber`}
          component={ModifyUserComponent}
        />
      </Switch>
    </>
  )
}

export default UsersComponent
