import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import RejectionReturnReasonsServices from '../../../services/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsServices'
import { showTotast } from '../../../utils/toast'
import {
  rejectionReturnActive,
  rejectionReturnInActive,
} from '../../../utils/successMessages'
import { RejectionReturnReasonsModel } from '../../../models/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsModel'
type TParams = { id: string }
const ViewRejectionReturnReasons = ({
  match,
}: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [actionTypeList, setActionTypeList] = useState()
  const [rejectionReturnState, setRejectionReturnState] = useState(
    {} as RejectionReturnReasonsModel
  )
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'incident-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getIncidentTypesData() {
      const rejectionReturnData =
        await RejectionReturnReasonsServices.getRejectionReturnReasonsById(
          parseInt(match.params.id)
        )

      setRejectionReturnState(rejectionReturnData.data)
      const actionTypeList = rejectionReturnData.data.actionTypeList.map(
        (action: { actionType: string }) => {
          return action.actionType
        }
      )
      const actionType = actionTypeList.join(', ')
      setActionTypeList(actionType)
      setloading(false)
    }
    getIncidentTypesData()
  }, [])
  let statusAction = ''
  if (rejectionReturnState.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (rejectionReturnState.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData =
        await RejectionReturnReasonsServices.apiInactiveRejectionReturnReasons(
          parseInt(match.params.id)
        )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = rejectionReturnInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setRejectionReturnState(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData =
        await RejectionReturnReasonsServices.apiActiveRejectionReturnReasons(
          parseInt(match.params.id)
        )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = rejectionReturnActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setRejectionReturnState(activeData.data)
    }
  }
  const handleModify = () => {
    history.push(
      `/system-configuration/rejection-return-reason/modify/${match.params.id}`
    )
  }

  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-IncidentTypes-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              View Rejection/Return Reason
            </div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={rejectionReturnState.status}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') === true ||
                    checkPermission('Active') === true ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Reason Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={rejectionReturnState.reasonType}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Reason Description</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={rejectionReturnState.reasonDescription}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Action Type</Form.Label>
                    <Form.Control type="text" disabled value={actionTypeList} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="ok-button"
                    onClick={(e) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                  {checkPermission('modify') === true &&
                    rejectionReturnState.status === 'Active' && (
                      <button className="modify-button" onClick={handleModify}>
                        Modify
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewRejectionReturnReasons
