import React, { useEffect, useState } from 'react'

interface PaginationProps {
  totalCount: number
  currentPage: number
  pageSize: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
}) => {
  const [inputPage, setInputPage] = useState(currentPage)
  const totalPages = Math.ceil(totalCount / pageSize)

  useEffect(() => {
    setInputPage(currentPage)
  }, [currentPage])

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage)
      setInputPage(newPage)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    if (!isNaN(value)) {
      setInputPage(value)
    }
  }

  const handleInputBlur = () => {
    handlePageChange(inputPage)
  }

  const renderPageNumbers = (pageCount: number) => {
    const pageList: number[] = []
    if (pageCount > 10) {
      pageList.push(1)
      pageList.push(2)
      pageList.push(3)
      pageList.push(-1)
      pageList.push(pageCount - 2)
      pageList.push(pageCount - 1)
      pageList.push(pageCount)
    } else {
      for (let i = 0; i < pageCount; i++) {
        pageList.push(i + 1)
      }
    }

    return pageList.map((page) => (
      <>
        {page !== -1 && (
          <button
            className={`table-page-button ${
              currentPage === page ? 'active-page' : ''
            }`}
            key={`page-${page}`}
            onClick={() => handlePageChange(page)}
          >
            {`${page}`}
          </button>
        )}
        {page === -1 && <span className="pagination-elipses">...</span>}
      </>
    ))
  }

  return (
    <div className="pagination">
      <button
        className={`table-nav-button ${currentPage > 1 ? 'enable' : ''}`}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt; Previous
      </button>
      {renderPageNumbers(totalPages)}
      <button
        className={`table-nav-button ${
          currentPage < totalPages ? 'enable' : ''
        }`}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next &gt;
      </button>
      <div className="pagination-go-to-page">
        <span>Go to page</span>
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min="1"
          max={totalPages}
        />
      </div>
    </div>
  )
}

export default Pagination
