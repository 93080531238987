import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ITerminalWizardComponent from '../../../../components/common/ITerminalWizardComponent/ITerminalWizardComponent'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import DialogModal from '../../../../components/modals/DialogModal'
import { AffectedPropertyModel } from '../../../../models/applicationRegistration/compensation/affectedPropertyModel'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import common from '../../../../services/applicationRegistration/common'
import rlpService from '../../../../services/applicationRegistration/compensation/rlpService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { applicationReadyToModify } from '../../../../utils/constants'
import { rlpSectionList } from '../../../../utils/metaData'
import {
  getSectionFiveSuccessMessage,
  handleNotification,
} from '../../../../utils/utilFunctions'
import GpcSectionThree from '../sections/GpcSectionThree'
import SectionBoardOfTrustee from '../sections/SectionBoardOfTrustee'
import SectionFive from '../sections/SectionFive'
import SectionOne from '../sections/SectionOne'
import SectionTwo from '../sections/SectionTwo'

import SectionTwoView from '../../../../components/sections/SectionTwo'
import SectionAffectedProperty from '../../../../components/sections/SectionAffectedProperty'
import SectionBankDetails from '../../../../components/sections/SectionBankDetails'
import SectionFiveView from '../../../../components/sections/SupportDocumentSection'

const RlpFileRegistration = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  console.log(type)
  console.log(params)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const history = useHistory()
  const dispatch = useDispatch()
  async function getFileDetails() {
    const result = await rlpService.apiFetchRlpFile(parseInt(params.id))
    console.log(result)
    setFileRegistrationObject(result.data)
    setLoading(false)
  }
  useEffect(() => {
    getFileDetails()
  }, [])

  const handleOk = () => {
    history.goBack()
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleProceed = async () => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpProceed(fileRegistrationObject)
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      setActiveSection(activeSection + 1)
    }
    dispatch(changeLoadingRequest())
  }

  const handleReject = async (remark: string) => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpReject(
      fileRegistrationObject.id,
      remark
    )
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Reject Successfully')
    if (result.status === 200) {
      history.goBack()
    }
  }

  const handleReturn = async (remark: string) => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpReturn(
      fileRegistrationObject.id,
      remark
    )
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Return Successfully')
    if (result.status === 200) {
      history.goBack()
    }
  }

  const handleSectionTwo = async (
    type: string,
    sectionTwo: FileRegistrationModel
  ) => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpAddApplicantDetails(sectionTwo)
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      if (type === 'next') {
        setActiveSection(activeSection + 1)
      }
    }
    dispatch(changeLoadingRequest())
  }

  const handleSectionThree = async (
    type: string,
    sectionThree: AffectedPropertyModel
  ) => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpAddAffectedPropertyDetails(
      sectionThree,
      fileRegistrationObject.id
    )
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      const file = await rlpService.apiFetchRlpFile(parseInt(params.id))
      setFileRegistrationObject(file.data)
      if (type === 'next') {
        setActiveSection(activeSection + 1)
      }
    }
    dispatch(changeLoadingRequest())
  }

  const handleSectionFour = async (type: string) => {
    dispatch(changeLoadingRequest())
    if (type === 'next') {
      const application = await common.apiCompleteLevelFour(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )

      if (application.status == 204) {
        const pdFile = await rlpService.apiFetchRlpFile(parseInt(params.id))
        setFileRegistrationObject(pdFile.data)
        setActiveSection(activeSection + 1)
      } else {
        handleNotification(application, '')
      }
    }
    dispatch(changeLoadingRequest())
  }

  const handleSectionFive = async (type: string, formData: FormData) => {
    dispatch(changeLoadingRequest())
    let result = {} as ResponseObject
    let message = 'Application Drafted Successfully'
    if (type === 'save') {
      console.log(formData)
      result = await rlpService.apiRlpAddSupportingDocument(
        formData,
        fileRegistrationObject.id
      )
    } else {
      message = getSectionFiveSuccessMessage(fileRegistrationObject)
      result = await rlpService.apiRlpRegisterDocument(
        fileRegistrationObject.id
      )
      if (result.status === 200) {
        const pdFile = await rlpService.apiFetchRlpFile(parseInt(params.id))
        setFileRegistrationObject(pdFile.data)
        if (type === 'submit') {
          setShowModal(true)
        }
      }
    }

    handleNotification(result, message)
    dispatch(changeLoadingRequest())
  }

  const handleModify = async () => {
    dispatch(changeLoadingRequest())
    const result = await rlpService.apiRlpModify(fileRegistrationObject)
    handleNotification(result, applicationReadyToModify)
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      setActiveSection(activeSection + 1)
    }
    dispatch(changeLoadingRequest())
  }

  const handleNextClick = () => {
    setActiveSection(activeSection + 1)
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                Application Registration
              </span>
              <span className="m-2">
                {`Application reference : ${fileRegistrationObject.referenceNumber}`}
              </span>
            </Col>
          </Row>
          <ITerminalWizardComponent
            sectionList={rlpSectionList}
            activeSection={activeSection}
            validationLevel={fileRegistrationObject.validationLevel}
            onClick={(sectionNumber: number) => {
              setActiveSection(sectionNumber)
            }}
          />
          <Row className="section-layout">
            {activeSection === 1 && (
              <SectionOne
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleOk}
                onCancel={handleCancel}
                onProceed={handleProceed}
                onModify={handleModify}
                onReject={(remark: string) => handleReject(remark)}
                onReturn={(remark: string) => handleReturn(remark)}
                onReload={getFileDetails}
              />
            )}
            {activeSection === 2 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <SectionTwo
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(registerFile: FileRegistrationModel) => {
                    handleSectionTwo('next', registerFile)
                  }}
                  onSave={(registerFile: FileRegistrationModel) => {
                    handleSectionTwo('save', registerFile)
                  }}
                  onNextSection={handleNextClick}
                />
              ) : (
                <SectionTwoView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
            {activeSection === 3 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <GpcSectionThree
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(affectedProperty: AffectedPropertyModel) => {
                    handleSectionThree('next', affectedProperty)
                  }}
                  onSave={(affectedProperty: AffectedPropertyModel) => {
                    handleSectionThree('save', affectedProperty)
                  }}
                  onNextSection={handleNextClick}
                />
              ) : (
                <SectionAffectedProperty
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
            {activeSection === 4 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <SectionBoardOfTrustee
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={() => {
                    handleSectionFour('next')
                  }}
                  onSave={() => {
                    handleSectionFour('save')
                  }}
                  onNextSection={handleNextClick}
                />
              ) : (
                <SectionBankDetails
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  applicantName={fileRegistrationObject.applicantName}
                  scheme={fileRegistrationObject.scheme}
                />
              ))}
            {activeSection === 5 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <SectionFive
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(formData: FormData) => {
                    handleSectionFive('submit', formData)
                  }}
                  onSave={(formData: FormData) => {
                    handleSectionFive('save', formData)
                  }}
                />
              ) : (
                <SectionFiveView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
          </Row>
        </>
      )}
      {showModal && (
        <DialogModal
          name="Application Creation"
          body={
            <div className="custom-dialog-body">
              <p>{getSectionFiveSuccessMessage(fileRegistrationObject)}</p>
              <p>
                {`Application Number : ${fileRegistrationObject.referenceNumber}`}
              </p>
            </div>
          }
          onOk={() => {
            history.goBack()
          }}
        />
      )}
    </>
  )
}

export default RlpFileRegistration
