import React, { FC } from 'react'
import './SectionButton.scss'

export interface SectionButtonProps {
  name: string
  number: number
  isLeft: boolean
  isRight: boolean
  active?: boolean
  available?: boolean
  onClick?: any
}

const SectionButton: FC<SectionButtonProps> = ({
  name,
  number,
  isLeft,
  isRight,
  active = false,
  available = false,
  onClick,
}) => {
  const barClassName = `section-bar ${
    active ? 'active-bar' : available ? 'available-bar' : 'blocked-bar'
  }`
  const buttonClassName = `section-button-circle ${
    active
      ? 'active-section'
      : available
      ? 'available-section'
      : 'blocked-section'
  }`
  return (
    <>
      {isLeft && <div className={barClassName}></div>}
      <div className="section-button">
        <button
          className={buttonClassName}
          disabled={!available}
          onClick={onClick}
        >
          {number}
        </button>
        <div className="section-button-title">{`Section 0${number}`}</div>
        <div className="section-button-name">{name}</div>
      </div>
      {isRight && <div className={barClassName}></div>}
    </>
  )
}

export default SectionButton
