import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ActionListModal } from '../../models/package/actionListModal'
import applicationFiles from '../../services/applicationRegistration/applicationFiles'
import common from '../../services/applicationRegistration/common'
import { getUserModuleActions } from '../../store/user/selectors'
import { approve, reject, ApplicationReturn } from '../../utils/constants'
import { fileRegistrationHistoryColumns } from '../../utils/metaData'
import {
  checkPermission,
  checkStatsForApproval,
  checkStatsForReject,
  checkStatsForReturn,
} from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'
import SimilarRecordTable from '../common/similarRecordTable/SimilarRecordTable'
import ApplicationDataView from '../common/view/ApplicationDataView'
import ConfirmationModal from '../modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'

export interface SectionOneProps {
  fileRegistrationObject: FileRegistrationModel
  onClick?: any
  type?: string
  packageName: string
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

const SectionOne: FC<SectionOneProps> = ({
  fileRegistrationObject,
  onClick,
  type,
  packageName,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const [actionList, setActionList] = useState([] as ActionListModal[])
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true)

  const moduleActionList = useSelector(
    getUserModuleActions(
      packageName,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase()
    )
  )
  // const subModuleActionList = useSelector(
  //   getUserSubModuleActions(
  //     packageName,
  //     fileRegistrationObject.programType.toLowerCase(),
  //     fileRegistrationObject.scheme.toLowerCase(),
  //     'file-return'
  //   )
  // )

  useEffect(() => {
    // if (type === 'file-return') {
    //   setActionList(subModuleActionList)
    // } else {
    //   setActionList(moduleActionList)
    // }
    setActionList(moduleActionList)
    async function getFileDetails() {
      const fileHistoryData =
        await applicationFiles.apiFetchApplicationFilesHistory(
          fileRegistrationObject.id
        )
      const similarRecordData = await common.apiGetSimilarRecords(
        fileRegistrationObject.id
      )
      if (fileHistoryData.status === 200) {
        setHistoryList(fileHistoryData.data)
      }
      if (similarRecordData.status === 200) {
        setSimilarRecordList(similarRecordData.data)
      }
      setLoading(false)
    }
    getFileDetails()
  }, [])
  const handleConfirm = (remarkString: string) => {
    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
    onClick({ section: showConfirm.section, remark: remarkString })
  }
  let sectionName: string
  if (packageName === 'compensation-recommendation') {
    sectionName = 'Compensation Recommendations'
  } else if (packageName === 'compensation-approval') {
    sectionName = 'Approve Compensations'
  } else if (packageName === 'compensation-certification') {
    sectionName = 'Compensation Certification'
  } else {
    sectionName = ''
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Basic Information</div>
            <ApplicationDataView
              fileRegistrationObject={fileRegistrationObject}
              isOverAge={
                fileRegistrationObject.identificationType != 'BIRTH_CERTIFICATE'
              }
            />
          </Col>
          <Col sm={12} className="mt-3 mb-3 p-3">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="audit-history"
            >
              <Row className="tab-page-layout">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="audit-history">Audit History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="similar-records">
                      Similar Record
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Row>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="audit-history">
                    <Container fluid className="mt-4">
                      <ReactTableWithPaginationComponent
                        columns={fileRegistrationHistoryColumns}
                        data={historyList}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="similar-records">
                    <SimilarRecordTable similarRecordList={similarRecordList} />
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {type !== 'enquire' &&
              checkPermission('approve', actionList) &&
              checkStatsForApproval(
                fileRegistrationObject.status,
                packageName
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      const message =
                        'Do you wish to approve this application ?'
                      const section = approve
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: false,
                      })
                    }}
                  >
                    Approve
                  </button>
                </>
              )}
            {type !== 'enquire' &&
              checkPermission('reject', actionList) &&
              checkStatsForReject(
                fileRegistrationObject.status,
                packageName
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      const message = 'Do you wish to Reject application ?'
                      const section = reject
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: true,
                      })
                    }}
                  >
                    Reject
                  </button>
                </>
              )}
            {type !== 'enquire' &&
              checkPermission('return', actionList) &&
              checkStatsForReturn(
                fileRegistrationObject.status,
                packageName
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      const message = 'Do you wish to Return application ?'
                      const section = ApplicationReturn
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: true,
                      })
                    }}
                  >
                    Return
                  </button>
                </>
              )}

            <button
              className="cancel-button"
              onClick={() => {
                onClick({ section: 'ok', remark: '' })
              }}
            >
              Ok
            </button>
          </Col>
        </>
      )}
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }}
          isRemark={showConfirm.isRemark}
          onConfirm={(remark: string) => {
            handleConfirm(remark)
          }}
          reasonType={showConfirm.section}
          section={sectionName}
        />
      )}
    </>
  )
}

export default SectionOne
