import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { BankDetailsModel } from '../../../../models/applicationRegistration/compensation/bankDetailsModel'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import gpcService from '../../../../services/applicationRegistration/compensation/gpcService'
import gscService from '../../../../services/applicationRegistration/compensation/gscService'
import metaDataService from '../../../../services/metaDataService'
import { ResponseObject } from '../../../../services/api/apiManager'
import {
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  handleNotification,
  validateForm,
} from '../../../../utils/utilFunctions'
import { BankDetailsErrorModel } from '../../../../models/applicationRegistration/compensation/bankDetailsErrorModel'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../../store/user/selectors'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import NextOfKinModal from '../../../../components/modals/NextOfKinModal'
import common from '../../../../services/applicationRegistration/common'
import { NextOfKinModel } from '../../../../models/applicationRegistration/compensation/nextOfKinModel'
export interface GpcSectionFourProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
  onNextSection: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const GpcSectionFour: FC<GpcSectionFourProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
  onNextSection,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [loading, setLoading] = useState(true)
  const [bankDetails, setBankDetails] = useState({} as BankDetailsModel)
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [errors, setErrors] = useState({} as BankDetailsErrorModel)
  const [nextOfKin, setNextOfKin] = useState(false)
  const [nextOfKinDetails, setNextOfKinDetails] = useState({} as NextOfKinModel)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  useEffect(() => {
    async function getMetaData() {
      const bankData = await metaDataService.getBankList()
      if (bankData.status == 200) {
        setBankList(bankData.data)
      }

      let affectedPropertyData = {} as ResponseObject
      if (fileRegistrationObject.scheme === 'GPC') {
        affectedPropertyData = await gpcService.apiGetGpcBankData(
          fileRegistrationObject.id
        )
      } else if (fileRegistrationObject.scheme === 'GSC') {
        affectedPropertyData = await gscService.apiGetGscBankData(
          fileRegistrationObject.id
        )
      }

      if (affectedPropertyData.status === 200) {
        await getBranchesList(affectedPropertyData.data.bankId)
        setBankDetails(affectedPropertyData.data)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }
  const findErrors = () => {
    const newErrors = {} as BankDetailsErrorModel
    if (
      validateForm({
        type: number,
        value: bankDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankIdError = validateForm({
        type: number,
        value: bankDetails.bankId,
        section: 'Bank name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        value: bankDetails.branchId,
      } as ValidationObjectModel)
    ) {
      newErrors.branchIdError = validateForm({
        type: number,
        value: bankDetails.branchId,
        section: 'Branch name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        maxLength: 15,
        value: bankDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: text,
        maxLength: 15,
        value: bankDetails.accountNumber,
        section: 'Account number',
      } as ValidationObjectModel)
    }
    return newErrors
  }
  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const handleConfirm = async () => {
    console.log(fileRegistrationObject)
    if (showConfirm.section === 'save') {
      dispatch(changeLoadingRequest())
      let result = {} as ResponseObject
      if (fileRegistrationObject.scheme === 'GPC') {
        result = await gpcService.apiGpcAddBankDetails(
          bankDetails,
          fileRegistrationObject.id
        )
      } else {
        result = await gscService.apiGscAddBankDetails(
          bankDetails,
          fileRegistrationObject.id
        )
      }
      if (result.status === 200) {
        setBankDetails(result.data)
      }
      handleNotification(result, 'Application Drafted Successfully')
      dispatch(changeLoadingRequest())
      onSave()
    } else {
      onNext(bankDetails)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const onClickNextSection = () => {
    onNextSection()
  }

  const checkDisable = () => {
    if (
      fileRegistrationObject.modifyIndex === 0 ||
      fileRegistrationObject.modifyIndex === 4
    ) {
      return false
    }
    return true
  }

  const checkAddNextOfKin = () => {
    if (
      checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
      checkLoggedUser(user.epfNumber, fileRegistrationObject.allocateTo) &&
      fileRegistrationObject.modifyIndex == 5
    ) {
      return true
    }

    return false
  }

  const handleNextOfKinButton = async () => {
    dispatch(changeLoadingRequest())
    const nextOfKinData = await common.apiGetNextOfKin(
      'application-registration',
      fileRegistrationObject.id,
      bankDetails.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (nextOfKinData.status === 200) {
      setNextOfKinDetails(nextOfKinData.data)
    } else {
      const newNextOfKin = produce(
        nextOfKinDetails,
        (draft: NextOfKinModel) => {
          draft.dependentId = bankDetails.id
          draft.applicationFileId = fileRegistrationObject.id
          draft.newNic = false
        }
      )
      setNextOfKinDetails(newNextOfKin)
    }
    dispatch(changeLoadingRequest())
    setNextOfKin(true)
  }

  const handleSaveNextOfKin = async (nextOfKin: NextOfKinModel) => {
    dispatch(changeLoadingRequest())
    let message = 'Next of Kin Added Successfully'
    if (nextOfKin.id > 0) {
      message = 'Next of Kin Updated Successfully'
    }
    let results = {} as ResponseObject
    results = await common.apiUpdateNextOfKin(
      fileRegistrationObject.id,
      bankDetails.id,
      fileRegistrationObject.scheme.toLowerCase(),
      nextOfKin
    )
    handleNotification(results, message)
    dispatch(changeLoadingRequest())
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Bank Details</div>
            <Form id="bankDetails">
              <Form.Group className="mb-5" controlId="applicantName">
                <Form.Label>
                  Applicant Name{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  disabled={true}
                  value={fileRegistrationObject.applicantName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="bankName">
                <Form.Label>
                  Bank Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.bankIdError}
                  disabled={checkDisable()}
                  className="form-select"
                  as="select"
                  value={bankDetails.bankId}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.bankIdError = validateForm({
                        type: number,
                        value: bankDetails.bankId,
                        section: 'Bank name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      bankDetails,
                      (draft: BankDetailsModel) => {
                        draft.bankId = parseInt(e.target.value)
                        draft.branchId = 0
                      }
                    )
                    getBranchesList(parseInt(e.target.value))
                    setBankDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.bankIdError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {bankDetails.bankId == null || bankDetails.bankId == 0 ? (
                    <option value="0">--Select Bank--</option>
                  ) : (
                    ''
                  )}
                  {bankList.map(
                    (bank: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`bank-${index}`} value={bank.id}>
                          {bank.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.bankIdError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="branchkName">
                <Form.Label>
                  Branch Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.branchIdError}
                  className="form-select"
                  as="select"
                  disabled={
                    bankDetails.bankId == null ||
                    bankDetails.bankId == 0 ||
                    checkDisable()
                  }
                  value={bankDetails.branchId}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.branchIdError = validateForm({
                        type: number,
                        minValue: 1,
                        customMessage: 'Branch name cannot be empty !',
                        value: bankDetails.branchId,
                        section: 'Branch name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      bankDetails,
                      (draft: BankDetailsModel) => {
                        draft.branchId = parseInt(e.target.value)
                      }
                    )
                    setBankDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.branchIdError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {bankDetails.branchId == null || bankDetails.branchId == 0 ? (
                    <option value="0">--Select Branch--</option>
                  ) : (
                    ''
                  )}
                  {branchList.map(
                    (branch: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`branch-${index}`} value={branch.id}>
                          {branch.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.branchIdError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="accountNumber">
                <Form.Label>
                  Account Number{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.accountNumberError}
                  disabled={checkDisable()}
                  value={bankDetails.accountNumber}
                  placeholder={'Enter Account Number'}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.accountNumberError = validateForm({
                        type: text,
                        maxLength: 15,
                        value: bankDetails.accountNumber,
                        section: 'Account number',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      bankDetails,
                      (draft: BankDetailsModel) => {
                        draft.accountNumber = e.target.value
                      }
                    )
                    setBankDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.accountNumberError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.accountNumberError} />
              </Form.Group>
            </Form>

            {checkAddNextOfKin() && (
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="save-button custom-margin-right"
                  onClick={handleNextOfKinButton}
                >
                  Next of Kin
                </button>
              </Col>
            )}
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={bankDetails.id == null}
                    className={`${
                      bankDetails.id == null
                        ? 'disable-button '
                        : 'save-button '
                    } custom-margin-right`}
                    onClick={() => {
                      if (fileRegistrationObject.modifyIndex == 0) {
                        onClickNext()
                      } else {
                        onClickNextSection()
                      }
                    }}
                  >
                    Next
                  </button>
                  {(fileRegistrationObject.modifyIndex == 0 ||
                    fileRegistrationObject.modifyIndex == 4) && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  )}
                </>
              )}

            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
          {nextOfKin && (
            <NextOfKinModal
              nextOfKin={nextOfKinDetails}
              onCancel={() => {
                setNextOfKin(false)
              }}
              onSave={(nextOdKin: NextOfKinModel) => {
                setNextOfKin(false)
                handleSaveNextOfKin(nextOdKin)
              }}
              fileRegistartionObject={fileRegistrationObject}
            />
          )}
        </>
      )}
    </>
  )
}

export default GpcSectionFour
