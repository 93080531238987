import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { DSDivisionModal } from '../../../../models/administrativeArea/dsDivisionModal'
import { PdAggrivedPersonDetailsModel } from '../../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { OtherAssistanceModel } from '../../../../models/applicationRegistration/compensation/OtherAssistanceModel'
import { dsSelectionModal } from '../../../../models/applicationRegistration/fileRegistration/dsSelectionModel'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DistrictOption } from '../../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../../models/selectOptionModels/dsOption'
import { GnOption } from '../../../../models/selectOptionModels/gnOption'
import { IncidentTypeOption } from '../../../../models/selectOptionModels/incidentTypeOption'
import { InjuryTypeOption } from '../../../../models/selectOptionModels/injuryTypeOption'
import { ProvinceOption } from '../../../../models/selectOptionModels/provinceOption'
import { ResponseObject } from '../../../../services/api/apiManager'
import gsdService from '../../../../services/applicationRegistration/compensation/gsdService'
import gsiService from '../../../../services/applicationRegistration/compensation/gsiService'
import pdService from '../../../../services/applicationRegistration/compensation/pdService'
import piService from '../../../../services/applicationRegistration/compensation/piService'
import mpService from '../../../../services/applicationRegistration/compensation/mpService'
import commonService from '../../../../services/applicationRegistration/common'
import metaDataService from '../../../../services/metaDataService'
import {
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
  getProvincesOptions,
} from '../../../../store/administrativeArea/selectors'
import {
  checkApplicationRegistrationButtonEnable,
  checkGenderDisable,
  checkInstallmentMergeEnable,
  checkLoggedUser,
  checkNicMandatory,
  checkSectionThreeNextButtonDisable,
  checkStatsDraftOrModifyDraft,
  currencyMask,
  getAge,
  getConfirmationOfDeath,
  getConfirmationOfInjury,
  getInjuryTypeDisabled,
  getSelectedInjuryTypeList,
  handleNotification,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
  setInjuryTypeOptions,
  thousandSeperator,
  validateForm,
} from '../../../../utils/utilFunctions'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import { CompensationValueDtoModel } from '../../../../models/class/CompensationValueDto'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { InstallmentModel } from '../../../../models/applicationRegistration/compensation/installmentModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import { PdAggrivedPersonErrorModel } from '../../../../models/applicationRegistration/compensation/pd/pdAggrivedPersonErrorModel'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import { getUser } from '../../../../store/user/selectors'
import IdentificationTypesComponent from '../../../../components/common/identificationComponent/IdentificationTypesComponent'
import NicTypeSelectionComponent from '../../../../components/common/identificationComponent/NicTypeSelectionComponent'
import NicInputComponent from '../../../../components/common/identificationComponent/NicInputComponent'

export interface SectionThreeProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
  onNextSection: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}
const SectionThree: FC<SectionThreeProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
  onNextSection,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [titleList, setTitleList] = useState([])
  const [martialStatusList, setMartialList] = useState([])
  const [policeStationList, setPoliceStationList] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  const [typeOfDisablementList, setTypeOfDisablementList] = useState([])
  const [categoryInjuryList, setCategoryInjuryList] = useState([])
  const [disablementPeriodList, setDisablementPeriodList] = useState([])
  const [loading, setLoading] = useState(true)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])
  const [injuryList, setInjuryList] = useState([] as InjuryTypeOption[])
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState({} as PdAggrivedPersonErrorModel)
  // const [installmentMergeList, setInstallmentMergeList] = useState(
  //   [] as InstallmentModel[]
  // )
  const [selectedDeathCertificateDs, setSelectedDeathCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [otherAssistantance, setOtherAssistantance] = useState(
    {} as OtherAssistanceModel
  )
  const [aggrivedPersonDetails, setAggrivedPersonDetails] = useState({
    birthCertificateDsDivision: {} as DSDivisionModal,
  } as PdAggrivedPersonDetailsModel)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      const titleData = await metaDataService.apiTitleListFetch()
      const maritalData = await metaDataService.getMaritalList()
      const incidentTypeData = await metaDataService.apiIncidentTypeList()
      const injuryTypeData = await metaDataService.apiGetInjuryList()
      const policeStationData = await metaDataService.getPoliceStationList()
      const organizationData = await metaDataService.apiOrganizationFetch()
      const typeOfDisablementData =
        await metaDataService.apiGetTypeOfDisablementList()
      const categoryOfInjuryData =
        await metaDataService.apiGetCategoryOfInjuryList()
      const disablementPeriodData =
        await metaDataService.apiGetDisablementPeriodList()
      setTypeOfDisablementList(typeOfDisablementData.data)
      setCategoryInjuryList(categoryOfInjuryData.data)
      setDisablementPeriodList(disablementPeriodData.data)
      if (organizationData.status === 200) {
        setOrganizationList(organizationData.data)
      }
      const incidentTypeTempList: IncidentTypeOption[] = []
      for (let i = 0; i < incidentTypeData.data.length; i++) {
        const incident = {} as IncidentTypeOption
        incident.value = incidentTypeData.data[i].id
        incident.label = incidentTypeData.data[i].incidentType
        incidentTypeTempList.push(incident)
      }
      setIncidentList(incidentTypeTempList)
      setInjuryList(setInjuryTypeOptions(injuryTypeData.data))
      setTitleList(titleData.data)

      if (maritalData.status == 200) {
        setMartialList(maritalData.data)
      }
      if (policeStationData.status == 200) {
        setPoliceStationList(policeStationData.data)
      }
      let aggrivedDetailsData = {} as ResponseObject

      if (fileRegistrationObject.scheme === 'PD') {
        aggrivedDetailsData =
          await pdService.apiPdGetAggrivedPersonDetailsDetails(
            fileRegistrationObject.id
          )
      } else if (fileRegistrationObject.scheme === 'PI') {
        aggrivedDetailsData =
          await piService.apiPiGetAggrivedPersonDetailsDetails(
            fileRegistrationObject.id
          )
      } else if (fileRegistrationObject.scheme === 'GSD') {
        aggrivedDetailsData =
          await gsdService.apiGsdGetAggrivedPersonDetailsDetails(
            fileRegistrationObject.id
          )
      } else if (fileRegistrationObject.scheme === 'GSI') {
        aggrivedDetailsData =
          await gsiService.apiGsiGetAggrivedPersonDetailsDetails(
            fileRegistrationObject.id
          )
      } else if (fileRegistrationObject.scheme === 'MP') {
        aggrivedDetailsData =
          await mpService.apiMpGetAggrivedPersonDetailsDetails(
            fileRegistrationObject.id
          )
      }

      if (aggrivedDetailsData.data && aggrivedDetailsData.data.id != null) {
        const installmentData =
          await metaDataService.getApplicationInstallmentAmountList(
            fileRegistrationObject.id
          )

        if (installmentData.status === 200) {
          const sortedIntallment = installmentData.data.sort((a, b) => {
            return a.installmentNumber - b.installmentNumber
          })
          setInstallmentList(sortedIntallment)
        }

        if (getInjuryTypeDisabled(fileRegistrationObject.scheme)) {
          const newSelectedDeathCertificateDs = produce(
            selectedDeathCertificateDs,
            (draft: dsSelectionModal) => {
              draft.provinceId =
                fileRegistrationObject.deathCertificateDsDivision.provinceId
              draft.districtId =
                fileRegistrationObject.deathCertificateDsDivision.districtId
              draft.dsId = fileRegistrationObject.deathCertificateDsDivisionId
            }
          )
          setSelectedDeathCertificateDs(newSelectedDeathCertificateDs)
        }

        if (aggrivedDetailsData.data.otherAssistanceList.length > 0) {
          const newAssitanceDetails = produce(otherAssistantance, (draft) => {
            draft.institution =
              aggrivedDetailsData.data.otherAssistanceList[0].institution
            draft.receivedDate =
              aggrivedDetailsData.data.otherAssistanceList[0].receivedDate
            draft.amountReceived =
              aggrivedDetailsData.data.otherAssistanceList[0].amountReceived
          })
          setOtherAssistantance(newAssitanceDetails)
        }
        const newFormValue = produce(
          aggrivedDetailsData.data,
          (draft: PdAggrivedPersonDetailsModel) => {
            draft.incidentTypeId = fileRegistrationObject.incidentTypeId
            draft.deathCertificateNumber =
              fileRegistrationObject.deathCertificateNumber
            if (draft.birthCertificateDsDivision == null) {
              draft.birthCertificateDsDivision = {} as DSDivisionModal
            }
          }
        )
        setAggrivedPersonDetails(newFormValue)
      } else {
        const newFormValue = produce(
          aggrivedPersonDetails,
          (draft: PdAggrivedPersonDetailsModel) => {
            draft.applicationFileId = fileRegistrationObject.id
            draft.incidentTypeId = fileRegistrationObject.incidentTypeId
            draft.incidentDate = fileRegistrationObject.incidentDate
            draft.deathCertificateNumber =
              fileRegistrationObject.deathCertificateNumber
            draft.dcIssuedDsDivisionId =
              fileRegistrationObject.deathCertificateDsDivisionId
            draft.newNic = true
            draft.birthCertificateDsDivision = {} as DSDivisionModal
          }
        )
        setAggrivedPersonDetails(newFormValue)
        if (fileRegistrationObject.applicantType === 'Primary Applicant') {
          const newFormValue = produce(
            aggrivedPersonDetails,
            (draft: PdAggrivedPersonDetailsModel) => {
              draft.applicationFileId = fileRegistrationObject.id
              draft.incidentTypeId = fileRegistrationObject.incidentTypeId
              draft.fullName = fileRegistrationObject.applicantName
              draft.identificationType =
                fileRegistrationObject.identificationType
              draft.newNic = fileRegistrationObject.newNic
              draft.newNicNumber = fileRegistrationObject.newNicNumber
              draft.oldNicNumber = fileRegistrationObject.oldNicNumber
              draft.birthCertificateNumber =
                fileRegistrationObject.birthCertificateNumber
              draft.birthCertificateDsDivision =
                fileRegistrationObject.birthCertificateDsDivision
              draft.birthCertificateDsDivisionId =
                fileRegistrationObject.birthCertificateDsDivisionId
              draft.passportNumber = fileRegistrationObject.passportNumber
              draft.seniorCitizenId = fileRegistrationObject.seniorCitizenId
              draft.drivingLicenseNumber =
                fileRegistrationObject.drivingLicenseNumber
              draft.dateOfBirth = fileRegistrationObject.dateOfBirth
              draft.age = fileRegistrationObject.age
              draft.gender = fileRegistrationObject.gender
              draft.incidentDate = fileRegistrationObject.incidentDate
            }
          )
          setAggrivedPersonDetails(newFormValue)
        }
        if (
          fileRegistrationObject.scheme === 'PD' ||
          fileRegistrationObject.scheme == 'GSD'
        ) {
          const newSelectedDeathCertificateDs = produce(
            selectedDeathCertificateDs,
            (draft: dsSelectionModal) => {
              draft.provinceId =
                fileRegistrationObject.deathCertificateDsDivision.provinceId
              draft.districtId =
                fileRegistrationObject.deathCertificateDsDivision.districtId
              draft.dsId = fileRegistrationObject.deathCertificateDsDivisionId
            }
          )
          setSelectedDeathCertificateDs(newSelectedDeathCertificateDs)
        }
      }
      setLoading(false)
    }
    getMetaData()
  }, [fileRegistrationObject])

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }

  const getCompensationAssistanceAmount = (scheme: string) => {
    let assitanceAmount = 0
    if (scheme === 'PD' || scheme === 'PI') {
      assitanceAmount = otherAssistantance.amountReceived
        ? otherAssistantance.amountReceived
        : 0
    }
    return assitanceAmount
  }

  const handleCalculation = async () => {
    const clearError = produce(errors, (draft) => {
      draft.compensationAmountError = ''
    })
    setErrors(clearError)
    const compensationDataModel = CompensationValueDtoModel.create({
      balanceLoanAmount: aggrivedPersonDetails.balanceLoanAmount
        ? aggrivedPersonDetails.balanceLoanAmount
        : 0,
      damagePercentage: 0,
      recommendedAmount: 0,
      scheme: fileRegistrationObject.scheme,
      incidentTypeId: fileRegistrationObject.incidentTypeId,
      typeOfInjuryId: aggrivedPersonDetails.typeOfInjuryId,
      percentageOfLossOfEarningCapacity:
        aggrivedPersonDetails.percentageOfLossOfEarningCapacity,
      receivedTotalDonations: 0,
      otherAssistanceAmount: getCompensationAssistanceAmount(
        fileRegistrationObject.scheme
      ),
    })
    dispatch(changeLoadingRequest())
    const result = await metaDataService.apiGetCompensationValue(
      compensationDataModel
    )
    if (result.status === 200) {
      console.log(result.data)
      const newFormValue = produce(
        aggrivedPersonDetails,
        (draft: PdAggrivedPersonDetailsModel) => {
          draft.compensationAmount = result.data
        }
      )
      setAggrivedPersonDetails(newFormValue)
    }
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Compensation calculated Successfully')
  }

  const filterInjuryType = () => {
    return injuryList.filter((injury: InjuryTypeOption) => {
      if (
        injury.typeOfDisablement === aggrivedPersonDetails.typeOfDisablement &&
        injury.categoryOfInjury ===
          categoryInjuryList.filter((category) => {
            return category.id === aggrivedPersonDetails.categoryOfInjuryId
          })[0]?.name
      ) {
        return injury
      }
    })
  }
  const findErrors = () => {
    const newErrors = {} as PdAggrivedPersonErrorModel
    if (
      validateForm({
        type: text,
        value: aggrivedPersonDetails.title,
      } as ValidationObjectModel)
    ) {
      newErrors.titleError = validateForm({
        type: text,
        value: aggrivedPersonDetails.title,
        section: 'Title of the Aggrieved Person',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: aggrivedPersonDetails.fullName,
      } as ValidationObjectModel)
    ) {
      newErrors.fullNameError = validateForm({
        type: text,
        value: aggrivedPersonDetails.fullName,
        section: 'Full name',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: aggrivedPersonDetails.dateOfBirth,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.dateOfBirthError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: aggrivedPersonDetails.dateOfBirth,
        section: 'Date Of birth',
      } as ValidationObjectModel)
    }

    if (fileRegistrationObject.scheme != 'MP') {
      if (
        validateForm({
          type: number,
          value: aggrivedPersonDetails.identificationType,
        } as ValidationObjectModel)
      ) {
        newErrors.identificationTypeError = validateForm({
          type: number,
          value: aggrivedPersonDetails.identificationType,
          section: 'Identification type',
        } as ValidationObjectModel)
      }
    }

    if (aggrivedPersonDetails.identificationType === 'BIRTH_CERTIFICATE') {
      if (
        validateForm({
          type: text,
          maxLength: 4,
          value: aggrivedPersonDetails.birthCertificateNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.birthCertificateNumberError = validateForm({
          type: text,
          maxLength: 4,
          value: aggrivedPersonDetails.birthCertificateNumber,
          section: 'birth certificate number',
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: text,
          value: aggrivedPersonDetails.birthCertificateDsDivision.provinceId,
        } as ValidationObjectModel)
      ) {
        newErrors.birthCertificateProvinceError = validateForm({
          type: text,
          value: aggrivedPersonDetails.birthCertificateDsDivision.provinceId,
          section: 'Province',
        } as ValidationObjectModel)
      }

      if (aggrivedPersonDetails.birthCertificateDsDivision.provinceId) {
        if (
          validateForm({
            type: text,
            value: aggrivedPersonDetails.birthCertificateDsDivision.districtId,
          } as ValidationObjectModel)
        ) {
          newErrors.birthCertificateDistrictError = validateForm({
            type: text,
            value: aggrivedPersonDetails.birthCertificateDsDivision.districtId,
            section: 'District',
          } as ValidationObjectModel)
        }
      }
      if (aggrivedPersonDetails.birthCertificateDsDivision.districtId) {
        if (
          validateForm({
            type: text,
            value: aggrivedPersonDetails.birthCertificateDsDivisionId,
          } as ValidationObjectModel)
        ) {
          newErrors.birthCertificateDsDivisionIdError = validateForm({
            type: text,
            value: aggrivedPersonDetails.birthCertificateDsDivisionId,
            section: 'Ds Division',
          } as ValidationObjectModel)
        }
      }
    }
    if (fileRegistrationObject.scheme != 'MP') {
      if (checkNicMandatory(aggrivedPersonDetails.identificationType)) {
        if (
          validateForm({
            type: text,
            value: aggrivedPersonDetails.newNic
              ? aggrivedPersonDetails.newNicNumber
              : aggrivedPersonDetails.oldNicNumber,
          } as ValidationObjectModel)
        ) {
          newErrors.nicError = validateForm({
            type: text,
            value: aggrivedPersonDetails.newNic
              ? aggrivedPersonDetails.newNicNumber
              : aggrivedPersonDetails.oldNicNumber,
            section: 'NIC number of the Aggrieved Person',
          } as ValidationObjectModel)
        }
      }
    }

    if (aggrivedPersonDetails.identificationType === 'PASSPORT') {
      if (
        validateForm({
          type: text,
          maxLength: 8,
          value: aggrivedPersonDetails.passportNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.identificationDocError = validateForm({
          type: text,
          maxLength: 8,
          value: aggrivedPersonDetails.passportNumber,
          section: 'Passport Number',
        } as ValidationObjectModel)
      }
    }

    if (aggrivedPersonDetails.identificationType === 'TIC') {
      if (
        validateForm({
          type: text,
          maxLength: 30,
          value: aggrivedPersonDetails.ticNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.identificationDocError = validateForm({
          type: text,
          maxLength: 30,
          value: aggrivedPersonDetails.ticNumber,
          section: 'Tic Number',
        } as ValidationObjectModel)
      }
    }

    if (aggrivedPersonDetails.identificationType === 'SENIOR_CITIZEN_ID') {
      if (
        validateForm({
          type: text,
          maxLength: 13,
          value: aggrivedPersonDetails.seniorCitizenId,
        } as ValidationObjectModel)
      ) {
        newErrors.identificationDocError = validateForm({
          type: text,
          maxLength: 13,
          value: aggrivedPersonDetails.seniorCitizenId,
          section: 'Senior Citizen Identity Card Number',
        } as ValidationObjectModel)
      }
    }

    if (aggrivedPersonDetails.identificationType === 'DRIVING_LICENSE') {
      if (
        validateForm({
          type: text,
          maxLength: 15,
          value: aggrivedPersonDetails.drivingLicenseNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.identificationDocError = validateForm({
          type: text,
          maxLength: 15,
          value: aggrivedPersonDetails.drivingLicenseNumber,
          section: 'Driving license number',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm(
        {
          type: number,
          maxLength: 3,
          value: aggrivedPersonDetails.age,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.ageError = validateForm({
        type: number,

        maxLength: 3,
        value: aggrivedPersonDetails.age,
        section: 'Age',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: aggrivedPersonDetails.gender,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.genderError = validateForm({
        type: text,
        value: aggrivedPersonDetails.gender,
        section: 'Gender',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: aggrivedPersonDetails.maritalStatus,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.maritalStatusError = validateForm({
        type: text,
        value: aggrivedPersonDetails.maritalStatus,
        section: 'Marital status',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: aggrivedPersonDetails.address,
        maxLength: 255,
      } as ValidationObjectModel)
    ) {
      newErrors.addressError = validateForm({
        type: text,
        value: aggrivedPersonDetails.address,
        maxLength: 255,
        section: 'Permanent Address of Aggrieved person when incident occurred',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: aggrivedPersonDetails.provinceId,
      } as ValidationObjectModel)
    ) {
      newErrors.provinceIdError = validateForm({
        type: text,
        value: aggrivedPersonDetails.provinceId,
        section: 'Province',
      } as ValidationObjectModel)
    }
    if (aggrivedPersonDetails.provinceId) {
      if (
        validateForm({
          type: text,
          value: aggrivedPersonDetails.districtId,
        } as ValidationObjectModel)
      ) {
        newErrors.districtIdError = validateForm({
          type: text,
          value: aggrivedPersonDetails.districtId,
          section: 'District',
        } as ValidationObjectModel)
      }
    }
    if (fileRegistrationObject.scheme != 'MP') {
      if (aggrivedPersonDetails.districtId) {
        if (
          validateForm({
            type: text,
            value: aggrivedPersonDetails.dsDivisionId,
          } as ValidationObjectModel)
        ) {
          newErrors.dsDivisionIdError = validateForm({
            type: text,
            value: aggrivedPersonDetails.dsDivisionId,
            section: 'DS division',
          } as ValidationObjectModel)
        }
      }

      if (aggrivedPersonDetails.dsDivisionId) {
        if (
          validateForm({
            type: text,
            value: aggrivedPersonDetails.gnDivisionId,
          } as ValidationObjectModel)
        ) {
          newErrors.gnDivisionIdError = validateForm({
            type: text,
            value: aggrivedPersonDetails.gnDivisionId,
            section: 'GN division',
          } as ValidationObjectModel)
        }
      }
    }
    if (
      validateForm(
        {
          type: text,
          maxLength: 100,
          value: aggrivedPersonDetails.placeOfTheIncidentOccurred,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.placeOfTheIncidentOccurredError = validateForm({
        type: text,
        maxLength: 100,
        value: aggrivedPersonDetails.placeOfTheIncidentOccurred,
        section: 'Place of the Incident occurred',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: number,
        value: aggrivedPersonDetails.incidentTypeId,
      } as ValidationObjectModel)
    ) {
      newErrors.incidentTypeIdError = validateForm({
        type: number,
        maxValue: new Date().getTime() / 1000,
        value: aggrivedPersonDetails.incidentTypeId,
        section: 'Incidant Type',
      } as ValidationObjectModel)
    }
    if (aggrivedPersonDetails.reasonsForDamage) {
      if (
        validateForm({
          type: text,
          maxLength: 50,
          value: aggrivedPersonDetails.reasonsForDamage,
        } as ValidationObjectModel)
      ) {
        newErrors.reasonsForDamageError = validateForm({
          type: text,
          maxLength: 50,
          value: aggrivedPersonDetails.reasonsForDamage,
          section: 'Reasons for the damage',
        } as ValidationObjectModel)
      }
    }

    if (
      fileRegistrationObject.scheme === 'GSD' ||
      fileRegistrationObject.scheme === 'GSI'
    ) {
      if (
        validateForm({
          type: text,
          maxLength: 40,
          value: aggrivedPersonDetails.designation,
        } as ValidationObjectModel)
      ) {
        newErrors.designationError = validateForm({
          type: text,
          maxLength: 40,
          value: aggrivedPersonDetails.designation,
          section: 'Designation',
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: text,
          maxLength: 60,
          value: aggrivedPersonDetails.organization,
        } as ValidationObjectModel)
      ) {
        newErrors.organizationError = validateForm({
          type: text,
          maxLength: 60,
          value: aggrivedPersonDetails.organization,
          section: 'Organization',
        } as ValidationObjectModel)
      }
      if (
        validateForm(
          {
            type: text,
            maxLength: 15,
            value: aggrivedPersonDetails.epfOrStaffNumber,
          } as ValidationObjectModel,

          false
        )
      ) {
        newErrors.epfOrStaffNumberError = validateForm(
          {
            type: text,
            maxLength: 15,
            value: aggrivedPersonDetails.epfOrStaffNumber,
            section: 'EPF or Staff number',
          } as ValidationObjectModel,

          false
        )
      }
      if (
        validateForm(
          {
            type: number,
            maxLength: 6,
            value: aggrivedPersonDetails.salary,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.salaryError = validateForm(
          {
            type: number,
            maxLength: 6,
            value: aggrivedPersonDetails.salary,
            section: 'Monthly Salary/Wages/Earnings (Rs.)',
          } as ValidationObjectModel,
          false
        )
      }
      if (aggrivedPersonDetails.balanceLoanAmount) {
        if (
          validateForm({
            type: text,
            maxLength: 9,
            value: aggrivedPersonDetails.balanceLoanAmount,
          } as ValidationObjectModel)
        ) {
          newErrors.balanceLoanAmountError = validateForm({
            type: text,
            maxLength: 9,
            value: aggrivedPersonDetails.balanceLoanAmount,
            section: 'Balance loan amount',
          } as ValidationObjectModel)
        }
      }
    }
    if (getConfirmationOfDeath(fileRegistrationObject.scheme)) {
      if (
        validateForm(
          {
            type: number,
            maxLength: 6,
            value: aggrivedPersonDetails.funeralExpenses,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.issuedDateError = validateForm(
          {
            type: number,
            maxLength: 6,
            value: aggrivedPersonDetails.funeralExpenses,
            section: 'Funeral Expenses',
          } as ValidationObjectModel,
          false
        )
      }
      if (
        validateForm({
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: aggrivedPersonDetails.issuedDate,
        } as ValidationObjectModel)
      ) {
        newErrors.issuedDateError = validateForm({
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: aggrivedPersonDetails.issuedDate,
          section: 'Issued date',
        } as ValidationObjectModel)
      }
      if (
        validateForm(
          {
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.issuedPlace,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.issuedPlaceError = validateForm(
          {
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.issuedPlace,
            section: 'Issued place',
          } as ValidationObjectModel,
          false
        )
      }
      if (aggrivedPersonDetails.jmoReport) {
        if (
          validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.jmoReport,
          } as ValidationObjectModel)
        ) {
          newErrors.jmoReportError = validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.jmoReport,
            section: 'JMO report',
          } as ValidationObjectModel)
        }
      }
      if (aggrivedPersonDetails.coronerReport) {
        if (
          validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.coronerReport,
          } as ValidationObjectModel)
        ) {
          newErrors.coronerReportError = validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.coronerReport,
            section: 'Coroner report',
          } as ValidationObjectModel)
        }
      }
      if (aggrivedPersonDetails.coronerReport) {
        if (
          validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.other,
          } as ValidationObjectModel)
        ) {
          newErrors.otherError = validateForm({
            maxLength: 30,
            type: text,
            value: aggrivedPersonDetails.other,
            section: 'Any other valid report details',
          } as ValidationObjectModel)
        }
      }
    }

    if (getConfirmationOfInjury(fileRegistrationObject.scheme)) {
      if (
        validateForm({
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.diagnosedOrTreatmentsGivenBy,
        } as ValidationObjectModel)
      ) {
        newErrors.diagnosedOrTreatmentsGivenByError = validateForm({
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.diagnosedOrTreatmentsGivenBy,
          section: 'Diagnosed or Treatments given by',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.nameOfTheHospital,
        } as ValidationObjectModel)
      ) {
        newErrors.nameOfTheHospitalError = validateForm({
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.nameOfTheHospital,
          section: 'Name Of the hospital',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          value: aggrivedPersonDetails.typeOfDisablement,
        } as ValidationObjectModel)
      ) {
        newErrors.typeOfDisablementError = validateForm({
          type: text,
          value: aggrivedPersonDetails.typeOfDisablement,
          section: 'Type of Disablement',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          value: aggrivedPersonDetails.categoryOfInjuryId,
        } as ValidationObjectModel)
      ) {
        newErrors.categoryOfInjuryError = validateForm({
          type: text,
          value: aggrivedPersonDetails.categoryOfInjuryId,
          section: 'Category of injury',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          value: aggrivedPersonDetails.typeOfInjuryId,
        } as ValidationObjectModel)
      ) {
        newErrors.typeOfInjuryIdError = validateForm({
          type: text,
          value: aggrivedPersonDetails.typeOfInjuryId,
          section: 'Type of injury',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm(
        {
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.policeStation,
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.policeStationError = validateForm(
        {
          maxLength: 30,
          type: text,
          value: aggrivedPersonDetails.policeStation,
          section: 'Police station',
        } as ValidationObjectModel,
        false
      )
    }
    if (
      validateForm(
        {
          maxLength: 10,
          type: text,
          value: aggrivedPersonDetails.complaintNumber,
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.complaintNumberError = validateForm(
        {
          maxLength: 10,
          type: text,
          value: aggrivedPersonDetails.complaintNumber,
          section: 'Complaint number',
        } as ValidationObjectModel,
        false
      )
    }
    if (
      validateForm(
        {
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: aggrivedPersonDetails.complaintDate,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.complaintDateError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: aggrivedPersonDetails.complaintDate,
        section: 'Complaint date',
      } as ValidationObjectModel)
    }
    if (otherAssistantance.institution) {
      if (
        validateForm({
          type: 'text',
          maxLength: 40,
          value: otherAssistantance.institution,
        } as ValidationObjectModel)
      ) {
        newErrors.institutionError = validateForm({
          type: 'text',
          maxLength: 40,
          value: otherAssistantance.institution,
          section: 'Name of the institution',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: otherAssistantance.amountReceived,
        } as ValidationObjectModel)
      ) {
        newErrors.amountReceivedError = validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: otherAssistantance.amountReceived,
          section: 'Additional assistance amount ',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: otherAssistantance.amountReceived,
        } as ValidationObjectModel)
      ) {
        newErrors.amountReceivedError = validateForm({
          type: 'date',
          maxValue: new Date().getTime() / 1000,
          value: otherAssistantance.amountReceived,
          section: 'Received date',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm({
        type: 'text',
        value: aggrivedPersonDetails.compensationAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.compensationAmountError = validateForm({
        type: 'text',
        value: aggrivedPersonDetails.compensationAmount,
        section: 'Compensation amount',
      } as ValidationObjectModel)
    }
    if (
      fileRegistrationObject.scheme == 'PI' ||
      fileRegistrationObject.scheme == 'GSI'
    ) {
      if (aggrivedPersonDetails.percentageOfLossOfEarningCapacity > 100) {
        newErrors.percentageOfLossOfEarningCapacityError =
          'Percentage of Loss of Earning Capacity is not valid !'
      } else if (!aggrivedPersonDetails.percentageOfLossOfEarningCapacity) {
        newErrors.percentageOfLossOfEarningCapacityError =
          'Percentage of Loss of Earning Capacity cannot be empty !'
      }
    }
    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(aggrivedPersonDetails)
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNextSection = () => {
    onNextSection()
  }

  const handleConfirm = () => {
    const otherAssitanceList = [] as []

    if (otherAssistantance.institution) {
      otherAssitanceList.push(otherAssistantance)
    }

    const savedAggrivedData = produce(aggrivedPersonDetails, (draft) => {
      draft.birthCertificateDsDivision = null
      draft.otherAssistanceList = otherAssitanceList
    })
    if (showConfirm.section === 'save') {
      onSave(savedAggrivedData)
    } else {
      onNext(savedAggrivedData)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const checkMergeDisabled = (installment: InstallmentModel, index: number) => {
    if (installment.merge) {
      return true
    } else {
      // check not last element
      const lastInstallment = installmentList[installmentList.length - 1]
      if (lastInstallment.installmentNumber != installment.installmentNumber) {
        // check any next installment checked
        for (let i = index + 1; i < installmentList.length; i++) {
          if (installmentList[i].isChecked) {
            return true
            break
          }
        }
      }
    }
    return false
  }

  const checkPreviousHasMergeSame = (index: number, mergeNumber: number) => {
    if (index == 0 || mergeNumber == 0) {
      return false
    }
    const previousInstallment = installmentList[index - 1]
    if (previousInstallment.mergeNumber === mergeNumber) {
      return true
    }

    return false
  }

  const handleUnMerge = async (mergeNumber: number) => {
    const scheme = fileRegistrationObject.scheme.toLocaleLowerCase()
    dispatch(changeLoadingRequest())
    const results = await commonService.apiUnMergeInstallment(
      mergeNumber,
      fileRegistrationObject.id,
      scheme
    )

    const installmentData =
      await metaDataService.getApplicationInstallmentAmountList(
        fileRegistrationObject.id
      )

    if (installmentData.status === 200) {
      const sortedIntallment = installmentData.data.sort((a, b) => {
        return a.installmentNumber - b.installmentNumber
      })
      setInstallmentList(sortedIntallment)
    }
    dispatch(changeLoadingRequest())
    handleNotification(results, 'Installments unmerged successfully')
  }

  const renderMergedInstallmentByMegeNumber = (mergeNumber: number) => {
    const mergedInstallment = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.mergeNumber === mergeNumber) {
          return installment
        }
      }
    )

    return mergedInstallment.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div
            className={`${index != mergedInstallment.length - 1 ? 'mb-3' : ''}`}
            key={`installment-merge-${index}`}
          >
            {`Installment ${
              installment.installmentNumber
            } Rs. ${thousandSeperator(installment.installmentAmount)}`}
          </div>
        )
      }
    )
  }

  const renderInstallmentList = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div key={`installment-${installment.id}`}>
            {!checkPreviousHasMergeSame(index, installment.mergeNumber) && (
              <ul key={`installment-ul-${installment.id}`}>
                {installment.mergeNumber == 0 && (
                  <Form.Check
                    key={`installment-check-${installment.id}`}
                    label={`Installment ${
                      installment.installmentNumber
                    } Rs. ${thousandSeperator(installment.installmentAmount)}`}
                    name={`installment`}
                    className="custom-form-check"
                    onChange={(e) => {
                      if (e.target.checked) {
                        checkIsMergeAvailable(installment)
                      } else {
                        const newInstallmentList = produce(
                          installmentList,
                          (draft: InstallmentModel[]) => {
                            const indexNumber = draft.findIndex(
                              (x) => x.id === installment.id
                            )
                            if (indexNumber > -1) {
                              draft[indexNumber].isChecked = false
                            }
                          }
                        )
                        setInstallmentList(newInstallmentList)
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    disabled={checkMergeDisabled(installment, index)}
                    checked={installment.merge || installment.isChecked}
                  />
                )}
                {installment.mergeNumber > 0 && (
                  <div className="row mb-0">
                    <div className="col max-width-content">
                      <Form.Check
                        className="custom-form-check"
                        disabled={true}
                        checked={true}
                        label={renderMergedInstallmentByMegeNumber(
                          installment.mergeNumber
                        )}
                      />
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className={`save-button`}
                        onClick={() => {
                          handleUnMerge(installment.mergeNumber)
                        }}
                      >
                        Un Merge
                      </button>
                    </div>
                  </div>
                )}
              </ul>
            )}
          </div>
        )
      }
    )
  }

  const checkIsMergeAvailable = (checkInstallment: InstallmentModel) => {
    // check any previous checked installments
    let addStatus = true
    const previousInstallments = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.isChecked) {
          return installment
        }
      }
    )

    if (previousInstallments.length > 0) {
      const indexId = installmentList.findIndex(
        (x) => x.id === checkInstallment.id
      )
      const previousInstallment = installmentList[indexId - 1]
      if (!previousInstallment.isChecked) {
        addStatus = false
      }
    }
    if (addStatus) {
      const newInstallmentList = produce(
        installmentList,
        (draft: InstallmentModel[]) => {
          const indexNumber = draft.findIndex(
            (x) => x.id === checkInstallment.id
          )
          if (indexNumber > -1) {
            draft[indexNumber].isChecked = true
          }
        }
      )
      setInstallmentList(newInstallmentList)
    }

    // const previousInstallments = installmentList.filter(
    //   (installment: InstallmentModel) => {
    //     if (
    //       installment.installmentNumber < checkInstallment.installmentNumber &&
    //       (installment.merge || !installment.isChecked)
    //     ) {
    //       return installment
    //     }
    //   }
    // )

    // if (previousInstallments.length == 0) {
    //   const newInstallmentList = produce(
    //     installmentList,
    //     (draft: InstallmentModel[]) => {
    //       const indexNumber = draft.findIndex(
    //         (x) => x.id === checkInstallment.id
    //       )
    //       if (indexNumber > -1) {
    //         draft[indexNumber].isChecked = true
    //       }
    //     }
    //   )
    //   setInstallmentList(newInstallmentList)
    // }
  }

  // const handleMergeSelect = (installment: InstallmentModel) => {
  //   const newMergeList = produce(installmentMergeList, (draft) => {
  //     draft.push(installment)
  //   })
  //   setInstallmentMergeList(newMergeList)
  // }

  const handleMerge = async () => {
    const mergeList = [] as number[]
    installmentList.forEach((installment: InstallmentModel) => {
      if (installment.isChecked) {
        mergeList.push(installment.installmentNumber)
      }
    })
    const scheme = fileRegistrationObject.scheme.toLocaleLowerCase()
    dispatch(changeLoadingRequest())
    const results = await commonService.apiMergeInstallment(
      mergeList,
      fileRegistrationObject.id,
      scheme
    )

    const installmentData =
      await metaDataService.getApplicationInstallmentAmountList(
        fileRegistrationObject.id
      )

    if (installmentData.status === 200) {
      const sortedIntallment = installmentData.data.sort((a, b) => {
        return a.installmentNumber - b.installmentNumber
      })
      setInstallmentList(sortedIntallment)
    }
    dispatch(changeLoadingRequest())
    handleNotification(results, 'Installment Merged Successfully')
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Aggrieved Person Details</div>
            <Form id="file-aggrieved-person-details-form">
              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>
                  Title <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.titleError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  className="form-select"
                  as="select"
                  value={aggrivedPersonDetails.title}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.titleError = validateForm({
                        type: text,
                        value: aggrivedPersonDetails.title,
                        section: 'Title of the Aggrieved Person',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      aggrivedPersonDetails,
                      (draft: PdAggrivedPersonDetailsModel) => {
                        draft.title = e.target.value
                      }
                    )
                    setAggrivedPersonDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.titleError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {1 === 1 ? <option value="">--Select Title--</option> : ''}
                  {titleList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.titleError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantName">
                <Form.Label>
                  Full Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.fullNameError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  type="text"
                  placeholder="Enter Full Name"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.fullNameError = validateForm({
                        type: text,
                        value: aggrivedPersonDetails.fullName,
                        section: 'Full name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      aggrivedPersonDetails,
                      (draft: PdAggrivedPersonDetailsModel) => {
                        draft.fullName = e.target.value
                      }
                    )
                    setAggrivedPersonDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.fullNameError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={aggrivedPersonDetails.fullName}
                />
                <InvalidFeedback message={errors.fullNameError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSelection">
                <Form.Label>Identification Type</Form.Label>
                <IdentificationTypesComponent
                  isDisabled={fileRegistrationObject.modifyIndex > 0}
                  identificationType={aggrivedPersonDetails.identificationType}
                  onChange={(type: string) => {
                    const newFormValue = produce(
                      aggrivedPersonDetails,
                      (draft: PdAggrivedPersonDetailsModel) => {
                        draft.identificationType = type
                      }
                    )
                    setAggrivedPersonDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.identificationTypeError = ''
                    })
                    setErrors(clearError)
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantDob">
                <Form.Label>
                  Date of birth{' '}
                  {fileRegistrationObject.scheme != 'MP' && (
                    <span className="required-field-astrix">*</span>
                  )}
                </Form.Label>
                <ReactDatePicker
                  maxDate={new Date()}
                  disabled={
                    fileRegistrationObject.modifyIndex > 0 ||
                    aggrivedPersonDetails.identificationType === 'NIC'
                  }
                  className={
                    errors.dateOfBirthError
                      ? 'date-picker-validate'
                      : 'form-control'
                  }
                  placeholderText="Date of Birth"
                  selected={
                    aggrivedPersonDetails.dateOfBirth != null
                      ? new Date(aggrivedPersonDetails.dateOfBirth * 1000)
                      : null
                  }
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.dateOfBirthError = validateForm({
                        type: 'date',
                        maxValue: new Date().getTime() / 1000,
                        value: aggrivedPersonDetails.dateOfBirth,
                        section: 'Date of Birth',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(date: Date) => {
                    const clearError = produce(errors, (draft) => {
                      draft.dateOfBirthError = ''
                      draft.identificationTypeError = ''
                      draft.identificationDocError = ''
                      draft.nicError = ''
                      if (
                        aggrivedPersonDetails.identificationType !=
                        'BIRTH_CERTIFICATE'
                      ) {
                        draft.birthCertificateNumberError = ''
                        draft.birthCertificateProvinceError = ''
                        draft.birthCertificateDistrictError = ''
                        draft.birthCertificateDsDivisionError = ''
                      }
                    })
                    setErrors(clearError)
                    const age = getAge(date)
                    if (age > 16) {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.age = age
                          draft.dateOfBirth = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                    } else {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.age = age
                          draft.dateOfBirth = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                    }
                  }}
                  onSelect={(date: any) => {
                    console.log(date)
                    const age = getAge(date)
                    if (age > 16) {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.age = age
                          draft.dateOfBirth = date.getTime() / 1000
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                    } else {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.age = age
                          draft.dateOfBirth = date.getTime() / 1000
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                    }
                  }}
                />
                <InvalidFeedback message={errors.dateOfBirthError} />
              </Form.Group>
              {aggrivedPersonDetails.identificationType &&
                aggrivedPersonDetails.identificationType !=
                  'BIRTH_CERTIFICATE' && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formNICNumber">
                        <Form.Label>
                          NIC Number{' '}
                          {checkNicMandatory(
                            aggrivedPersonDetails.identificationType
                          ) &&
                            fileRegistrationObject.scheme != 'MP' && (
                              <span className="required-field-astrix">*</span>
                            )}
                        </Form.Label>
                        <NicTypeSelectionComponent
                          isDisabled={fileRegistrationObject.modifyIndex > 0}
                          newNic={aggrivedPersonDetails.newNic}
                          onChange={(value: boolean) => {
                            const newFormValue = produce(
                              aggrivedPersonDetails,
                              (draft: PdAggrivedPersonDetailsModel) => {
                                draft.newNic = value
                              }
                            )
                            setAggrivedPersonDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.nicError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <NicInputComponent
                          errors={errors}
                          isDisabled={fileRegistrationObject.modifyIndex > 0}
                          incidentDate={
                            new Date(fileRegistrationObject.incidentDate * 1000)
                          }
                          newNic={aggrivedPersonDetails.newNic}
                          value={
                            aggrivedPersonDetails.newNic
                              ? aggrivedPersonDetails.newNicNumber
                              : aggrivedPersonDetails.oldNicNumber
                          }
                          onChange={(identity: any) => {
                            if (identity.error) {
                              if (
                                aggrivedPersonDetails.identificationType ===
                                'NIC'
                              ) {
                                const clearError = produce(errors, (draft) => {
                                  draft.nicError = 'Not a valid nic number'
                                })
                                setErrors(clearError)
                              }
                            } else {
                              const newFormValue = produce(
                                aggrivedPersonDetails,
                                (draft: PdAggrivedPersonDetailsModel) => {
                                  draft.newNicNumber = identity.newNic
                                  draft.oldNicNumber = identity.oldNic
                                  draft.dateOfBirth =
                                    identity.birthDate.getTime() / 1000
                                  draft.age = identity.age
                                  draft.gender = identity.gender
                                }
                              )
                              setAggrivedPersonDetails(newFormValue)
                              if (
                                aggrivedPersonDetails.identificationType ===
                                'NIC'
                              ) {
                                const clearError = produce(errors, (draft) => {
                                  draft.dateOfBirthError = ''
                                  draft.ageError = ''
                                })
                                setErrors(clearError)
                              }
                            }
                          }}
                        />
                      </Form.Group>
                    </Row>
                    {aggrivedPersonDetails.identificationType &&
                      aggrivedPersonDetails.identificationType != 'NIC' && (
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formPSDNumber">
                            <Form.Label>
                              {renderIdentificationTypeName(
                                aggrivedPersonDetails.identificationType
                              )}{' '}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Form.Control
                              isInvalid={!!errors.identificationDocError}
                              type="text"
                              disabled={
                                aggrivedPersonDetails.identificationType ===
                                  '' ||
                                aggrivedPersonDetails.identificationType ===
                                  null ||
                                fileRegistrationObject.modifyIndex > 0
                              }
                              placeholder={`Enter ${renderIdentificationTypeName(
                                aggrivedPersonDetails.identificationType
                              )}`}
                              onChange={(e) => {
                                const newFormValue = produce(
                                  aggrivedPersonDetails,
                                  (draft: PdAggrivedPersonDetailsModel) => {
                                    switch (
                                      aggrivedPersonDetails.identificationType
                                    ) {
                                      case 'PASSPORT': {
                                        //statements;
                                        draft.passportNumber = e.target.value
                                        break
                                      }
                                      case 'TIC': {
                                        draft.ticNumber = e.target.value
                                        break
                                      }
                                      case 'SENIOR_CITIZEN_ID': {
                                        //statements;
                                        draft.seniorCitizenId = e.target.value
                                        break
                                      }
                                      case 'DRIVING_LICENSE': {
                                        //statements;
                                        draft.drivingLicenseNumber =
                                          e.target.value
                                        break
                                      }
                                      default: {
                                        break
                                      }
                                    }
                                  }
                                )
                                setAggrivedPersonDetails(newFormValue)
                                const clearError = produce(errors, (draft) => {
                                  draft.identificationDocError = ''
                                })
                                setErrors(clearError)
                              }}
                              onBlur={() => {
                                const newErrors = produce(
                                  errors,
                                  (draft: PdAggrivedPersonErrorModel) => {
                                    switch (
                                      aggrivedPersonDetails.identificationType
                                    ) {
                                      case 'PASSPORT': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 8,
                                            value:
                                              aggrivedPersonDetails.passportNumber,
                                            section: 'Passport Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'TIC': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 30,
                                            value:
                                              aggrivedPersonDetails.ticNumber,
                                            section: 'Tic Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'SENIOR_CITIZEN_ID': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 13,
                                            value:
                                              aggrivedPersonDetails.seniorCitizenId,
                                            section:
                                              'Senior Citizen Identity Card Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'DRIVING_LICENSE': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 15,
                                            value:
                                              aggrivedPersonDetails.drivingLicenseNumber,
                                            section: 'Driving license number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      default: {
                                        break
                                      }
                                    }
                                  }
                                )
                                setErrors(newErrors)
                              }}
                              value={renderIdentificationTypeValue(
                                aggrivedPersonDetails.identificationType,
                                aggrivedPersonDetails
                              )}
                            />
                            <InvalidFeedback
                              message={errors.identificationDocError}
                            />
                          </Form.Group>
                        </Row>
                      )}
                  </>
                )}

              <Form.Group className="mb-3" controlId="idNumberRemark">
                <Form.Label>ID Number Remark</Form.Label>
                <Form.Control
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  maxLength={45}
                  type="text"
                  placeholder="Enter Number Remark"
                  onChange={(e) => {
                    const newFormValue = produce(
                      aggrivedPersonDetails,
                      (draft: PdAggrivedPersonDetailsModel) => {
                        draft.idNumberRemark = e.target.value
                      }
                    )
                    setAggrivedPersonDetails(newFormValue)
                  }}
                  value={aggrivedPersonDetails.idNumberRemark}
                />
              </Form.Group>

              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">
                  Birth Certificate Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>
                      Birth Certificate Number{' '}
                      {aggrivedPersonDetails.identificationType ==
                      'BIRTH_CERTIFICATE' ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.birthCertificateNumberError}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        fileRegistrationObject.applicantType ==
                          'Primary Applicant'
                      }
                      type="text"
                      placeholder="Enter Birth Certificate Number"
                      onBlur={() => {
                        if (aggrivedPersonDetails.birthCertificateNumber) {
                          const newErrors = produce(errors, (draft) => {
                            draft.birthCertificateNumberError = validateForm({
                              type: text,
                              maxLength: 4,
                              value:
                                aggrivedPersonDetails.birthCertificateNumber,
                              section: 'birth certificate number',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.birthCertificateNumber = e.target.value
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.birthCertificateNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={aggrivedPersonDetails.birthCertificateNumber}
                    />
                    <InvalidFeedback
                      message={errors.birthCertificateNumberError}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formProvince">
                    <Form.Label>
                      Province{' '}
                      {aggrivedPersonDetails.identificationType ==
                      'BIRTH_CERTIFICATE' ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Select
                      styles={
                        errors.birthCertificateProvinceError
                          ? customStyles
                          : undefined
                      }
                      isDisabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        fileRegistrationObject.applicantType ==
                          'Primary Applicant'
                      }
                      value={provinceList.filter((province: ProvinceOption) => {
                        return (
                          province.value ===
                          aggrivedPersonDetails.birthCertificateDsDivision
                            ?.provinceId
                        )
                      })}
                      options={provinceList}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.birthCertificateDsDivision.provinceId =
                              parseInt(selectedOption?.value)
                            draft.birthCertificateDsDivision.districtId = null
                            draft.birthCertificateDsDivisionId = null
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.birthCertificateProvinceError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.birthCertificateProvinceError}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDistrict">
                    <Form.Label>
                      District{' '}
                      {aggrivedPersonDetails.identificationType ==
                      'BIRTH_CERTIFICATE' ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Select
                      styles={
                        errors.birthCertificateDistrictError
                          ? customStyles
                          : undefined
                      }
                      value={districtList.filter((district: DistrictOption) => {
                        return (
                          district.value ===
                          aggrivedPersonDetails.birthCertificateDsDivision
                            ?.districtId
                        )
                      })}
                      isDisabled={
                        aggrivedPersonDetails.birthCertificateDsDivision
                          ?.provinceId === undefined ||
                        fileRegistrationObject.modifyIndex > 0
                      }
                      options={districtList.filter(
                        (district: DistrictOption) => {
                          return (
                            district.provinceId ===
                            aggrivedPersonDetails.birthCertificateDsDivision
                              ?.provinceId
                          )
                        }
                      )}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.birthCertificateDsDivision.districtId =
                              parseInt(selectedOption?.value)
                            draft.birthCertificateDsDivisionId = null
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.birthCertificateDistrictError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.birthCertificateDistrictError}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBirthCDsDivision">
                    <Form.Label>
                      Ds Division of Birth Certificate{' '}
                      {aggrivedPersonDetails.identificationType ==
                      'BIRTH_CERTIFICATE' ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Select
                      styles={
                        errors.birthCertificateDsDivisionError
                          ? customStyles
                          : undefined
                      }
                      value={dsList.filter((ds: DsOption) => {
                        return (
                          ds.value ===
                          aggrivedPersonDetails.birthCertificateDsDivisionId
                        )
                      })}
                      isDisabled={
                        aggrivedPersonDetails.birthCertificateDsDivision
                          ?.districtId === undefined ||
                        fileRegistrationObject.modifyIndex > 0
                      }
                      options={dsList.filter((ds: DsOption) => {
                        return (
                          ds.districtId ===
                          aggrivedPersonDetails.birthCertificateDsDivision
                            ?.districtId
                        )
                      })}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.birthCertificateDsDivision.id =
                              selectedOption?.value
                            draft.birthCertificateDsDivisionId =
                              selectedOption?.value
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.birthCertificateDsDivisionError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.birthCertificateDsDivisionError}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Row className="mt-3 mb-3">
                <Form.Group as={Col} controlId="formAge">
                  <Form.Label>
                    Age{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.ageError}
                    disabled={
                      fileRegistrationObject.modifyIndex > 0 ||
                      aggrivedPersonDetails.identificationType === 'NIC'
                    }
                    type="text"
                    placeholder="Enter Age"
                    value={
                      aggrivedPersonDetails.age ? aggrivedPersonDetails.age : ''
                    }
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.ageError = validateForm({
                          type: number,
                          maxLength: 3,
                          value: aggrivedPersonDetails.age,
                          section: 'Age',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const ageValue =
                        e.target.value == '' ? '0' : e.target.value
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.age = parseInt(ageValue)
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                    }}
                  />
                  <InvalidFeedback message={errors.ageError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGender">
                  <Form.Label>
                    Gender{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.genderError}
                    disabled={checkGenderDisable(
                      fileRegistrationObject.modifyIndex,
                      aggrivedPersonDetails.identificationType
                    )}
                    className="form-select"
                    as="select"
                    placeholder="Enter Gender"
                    value={aggrivedPersonDetails.gender}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.genderError = validateForm({
                          type: text,
                          value: aggrivedPersonDetails.gender,
                          section: 'Gender',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.gender = e.target.value
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.genderError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    <option value="N/A">--Select Gender--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGroupTitle">
                  <Form.Label>
                    Marital Status{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.maritalStatusError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    className="form-select"
                    as="select"
                    value={aggrivedPersonDetails.maritalStatus}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.maritalStatusError = validateForm({
                          type: text,
                          value: aggrivedPersonDetails.maritalStatus,
                          section: 'Marital status',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.maritalStatus = e.target.value
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.maritalStatusError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    {1 === 1 ? <option value="">--Select Title--</option> : ''}
                    {martialStatusList.map(
                      (title: { name: string; id: number }, index: number) => {
                        return (
                          <option key={`country-${index}`} value={title.name}>
                            {title.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <InvalidFeedback message={errors.maritalStatusError} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="formPermanentAddressWhenIncidentOccurred"
                >
                  <Form.Label>
                    Permanent Address{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.addressError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    type="text"
                    placeholder="Enter Permanent Address"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.addressError = validateForm({
                          type: text,
                          value: aggrivedPersonDetails.address,
                          maxLength: 255,
                          section:
                            'Permanent Address of Aggrieved person when incident occurred',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.address = e.target.value
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.addressError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={aggrivedPersonDetails.address}
                  />
                  <InvalidFeedback message={errors.addressError} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAgProvince">
                  <Form.Label>
                    Province <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    styles={errors.provinceIdError ? customStyles : undefined}
                    isDisabled={fileRegistrationObject.modifyIndex > 0}
                    value={provinceList.filter((province: ProvinceOption) => {
                      return province.value === aggrivedPersonDetails.provinceId
                    })}
                    options={provinceList}
                    onChange={(selectedOption) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.provinceId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.provinceIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.provinceIdError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgDistrict">
                  <Form.Label>
                    District <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    styles={errors.districtIdError ? customStyles : undefined}
                    value={districtList.filter((district: DistrictOption) => {
                      return district.value === aggrivedPersonDetails.districtId
                    })}
                    isDisabled={
                      aggrivedPersonDetails.provinceId === undefined ||
                      aggrivedPersonDetails.provinceId === 0 ||
                      fileRegistrationObject.modifyIndex > 0
                    }
                    options={districtList.filter((district: DistrictOption) => {
                      return (
                        district.provinceId === aggrivedPersonDetails.provinceId
                      )
                    })}
                    onChange={(selectedOption) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.districtId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.districtIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.districtIdError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgDsDivision">
                  <Form.Label>
                    {`DS Division`}{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Select
                    styles={errors.dsDivisionIdError ? customStyles : undefined}
                    value={dsList.filter((ds: DsOption) => {
                      return ds.value === aggrivedPersonDetails.dsDivisionId
                    })}
                    isDisabled={
                      aggrivedPersonDetails.districtId === undefined ||
                      aggrivedPersonDetails.districtId === 0 ||
                      fileRegistrationObject.modifyIndex > 0
                    }
                    options={dsList.filter((ds: DsOption) => {
                      return ds.districtId === aggrivedPersonDetails.districtId
                    })}
                    onChange={(selectedOption) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.dsDivisionId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.dsDivisionIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.dsDivisionIdError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgGnDivision">
                  <Form.Label>
                    {`GN Division`}{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Select
                    styles={errors.gnDivisionIdError ? customStyles : undefined}
                    value={gnList.filter((gn: GnOption) => {
                      return gn.value === aggrivedPersonDetails.gnDivisionId
                    })}
                    isDisabled={
                      aggrivedPersonDetails.dsDivisionId === undefined ||
                      aggrivedPersonDetails.dsDivisionId === 0 ||
                      fileRegistrationObject.modifyIndex > 0
                    }
                    options={gnList.filter((gn: GnOption) => {
                      return gn.dsId === aggrivedPersonDetails.dsDivisionId
                    })}
                    onChange={(selectedOption) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.gnDivisionId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.gnDivisionIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.gnDivisionIdError} />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formIncidentDate">
                <Form.Label>
                  {fileRegistrationObject.scheme != 'MP'
                    ? 'Incident Date'
                    : 'Last Seen At Alive Date'}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <ReactDatePicker
                  required={true}
                  maxDate={new Date()}
                  disabled={true}
                  className={
                    errors.incidentDateError
                      ? 'date-picker-validate'
                      : 'form-control'
                  }
                  placeholderText="Incident Date"
                  selected={
                    fileRegistrationObject.incidentDate != null
                      ? new Date(fileRegistrationObject.incidentDate * 1000)
                      : null
                  }
                />
                <InvalidFeedback message={errors.incidentDateError} />
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formPlaceOfTheIncident">
                  <Form.Label>
                    Place of the Incident occurred{' '}
                    {fileRegistrationObject.scheme != 'MP' && (
                      <span className="required-field-astrix">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.placeOfTheIncidentOccurredError}
                    type="text"
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    placeholder="Enter Place of the Incident occurred"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.placeOfTheIncidentOccurredError = validateForm({
                          type: text,
                          maxLength: 100,
                          value:
                            aggrivedPersonDetails.placeOfTheIncidentOccurred,
                          section: 'Place of the Incident occurred',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.placeOfTheIncidentOccurred = e.target.value
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.placeOfTheIncidentOccurredError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={aggrivedPersonDetails.placeOfTheIncidentOccurred}
                  />
                  <InvalidFeedback
                    message={errors.placeOfTheIncidentOccurredError}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formIncidantType">
                  <Form.Label>Incident Type</Form.Label>
                  <Select
                    styles={
                      errors.incidentTypeIdError ? customStyles : undefined
                    }
                    isDisabled={true}
                    value={incidentList.filter(
                      (incident: IncidentTypeOption) => {
                        return (
                          incident.value ===
                          fileRegistrationObject.incidentTypeId
                        )
                      }
                    )}
                    options={incidentList}
                    onChange={(selectedOption) => {
                      const newFormValue = produce(
                        aggrivedPersonDetails,
                        (draft: PdAggrivedPersonDetailsModel) => {
                          draft.incidentTypeId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setAggrivedPersonDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.incidentTypeIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.incidentTypeIdError} />
                </Form.Group>
              </Row>
              {/* {!(fileRegistrationObject.scheme === 'GSD') && (
                
              )} */}
              <Form.Group className="mb-3" controlId="formReasonsForDamage">
                <Form.Label>Reasons for Damage </Form.Label>
                <Form.Control
                  isInvalid={!!errors.reasonsForDamageError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  type="text"
                  placeholder="Enter Reasons for Damage"
                  onBlur={() => {
                    if (aggrivedPersonDetails.reasonsForDamage) {
                      const newErrors = produce(errors, (draft) => {
                        draft.reasonsForDamageError = validateForm({
                          type: text,
                          maxLength: 50,
                          value: aggrivedPersonDetails.reasonsForDamage,
                          section: 'Reasons for the damage',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      aggrivedPersonDetails,
                      (draft: PdAggrivedPersonDetailsModel) => {
                        draft.reasonsForDamage = e.target.value
                      }
                    )
                    setAggrivedPersonDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.reasonsForDamageError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={aggrivedPersonDetails.reasonsForDamage}
                />
                <InvalidFeedback message={errors.reasonsForDamageError} />
              </Form.Group>
              {fileRegistrationObject.scheme != 'MP' && (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">
                    Details of the Occupations
                  </div>
                  <Row>
                    <Form.Group as={Col} controlId="formAgDesignation">
                      <Form.Label>
                        Designation{' '}
                        {(fileRegistrationObject.scheme === 'GSD' ||
                          fileRegistrationObject.scheme === 'GSI') && (
                          <span className="required-field-astrix">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.designationError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Designation"
                        onBlur={() => {
                          if (aggrivedPersonDetails.designation) {
                            const newErrors = produce(errors, (draft) => {
                              draft.designationError = validateForm({
                                type: text,
                                maxLength: 40,
                                value: aggrivedPersonDetails.designation,
                                section: 'Designation',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.designation = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.designationError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.designation}
                      />
                      <InvalidFeedback message={errors.designationError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgOrganization">
                      <Form.Label>
                        Name of the Organization{' '}
                        {(fileRegistrationObject.scheme === 'GSD' ||
                          fileRegistrationObject.scheme === 'GSI') && (
                          <span className="required-field-astrix">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.organizationError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        className="form-select"
                        as="select"
                        placeholder="Enter Name of the Organization"
                        onBlur={() => {
                          if (aggrivedPersonDetails.organization) {
                            const newErrors = produce(errors, (draft) => {
                              draft.organizationError = validateForm(
                                {
                                  type: text,
                                  maxLength: 60,
                                  value: aggrivedPersonDetails.organization,
                                  section: 'Organization',
                                } as ValidationObjectModel,
                                false
                              )
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.organization = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.organizationError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.organization}
                      >
                        <option value="">--Select Organization--</option>
                        {organizationList.map(
                          (
                            org: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`country-${index}`} value={org.name}>
                                {org.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback message={errors.organizationError} />
                    </Form.Group>
                  </Row>
                  <Row className="mt-2">
                    <Form.Group as={Col} controlId="formAgEarnings">
                      <Form.Label>Salary/ Wages/ Earnings </Form.Label>
                      <PriceInputComponent
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.salaryError}
                        value={aggrivedPersonDetails.salary}
                        placeholder="Enter Salary/ Wages/ Earnings"
                        onChange={(price: string) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.salary = parseFloat(price)
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          if (price) {
                            const newErrors = produce(errors, (draft) => {
                              draft.salaryError = validateForm(
                                {
                                  value: parseFloat(price),
                                  section: 'Salary/ Wages/ Earnings',
                                  maxLength: 6,
                                  type: 'number',
                                } as ValidationObjectModel,
                                false
                              )
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newFormValue = produce(
                              aggrivedPersonDetails,
                              (draft: PdAggrivedPersonDetailsModel) => {
                                draft.salary = 0
                              }
                            )
                            setAggrivedPersonDetails(newFormValue)
                          } else {
                            const clearError = produce(errors, (draft) => {
                              draft.salaryError = ''
                            })
                            setErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback message={errors.salaryError} />
                    </Form.Group>
                    {(fileRegistrationObject.scheme === 'GSD' ||
                      fileRegistrationObject.scheme === 'GSI') && (
                      <>
                        <Form.Group as={Col} controlId="formAgEPFOrStaffNumber">
                          <Form.Label>EPF or Staff number </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.epfOrStaffNumberError}
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            type="text"
                            placeholder="Enter EPF or Staff number"
                            onBlur={(e) => {
                              if (e.target.value) {
                                const newErrors = produce(errors, (draft) => {
                                  draft.epfOrStaffNumberError = validateForm(
                                    {
                                      type: text,
                                      maxLength: 15,
                                      value:
                                        aggrivedPersonDetails.epfOrStaffNumber,
                                      section: 'EPF or Staff number',
                                    } as ValidationObjectModel,

                                    false
                                  )
                                })
                                setErrors(newErrors)
                              }
                            }}
                            onChange={(e) => {
                              const newFormValue = produce(
                                aggrivedPersonDetails,
                                (draft: PdAggrivedPersonDetailsModel) => {
                                  draft.epfOrStaffNumber = e.target.value
                                }
                              )
                              setAggrivedPersonDetails(newFormValue)
                              const clearError = produce(errors, (draft) => {
                                draft.epfOrStaffNumberError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={aggrivedPersonDetails.epfOrStaffNumber}
                          />
                          <InvalidFeedback
                            message={errors.epfOrStaffNumberError}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          controlId="formAgBalanceLoanAmount"
                        >
                          <Form.Label>Balance Loan amount (Rs.)</Form.Label>
                          <PriceInputComponent
                            isInvalid={!!errors.balanceLoanAmountError}
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            value={aggrivedPersonDetails.balanceLoanAmount}
                            placeholder="Enter Balance Loan amount (Rs.)"
                            onChange={(price: string) => {
                              const newFormValue = produce(
                                aggrivedPersonDetails,
                                (draft: PdAggrivedPersonDetailsModel) => {
                                  draft.balanceLoanAmount = parseFloat(price)
                                }
                              )
                              setAggrivedPersonDetails(newFormValue)
                              if (parseFloat(price) > 0) {
                                const newErrors = produce(errors, (draft) => {
                                  draft.balanceLoanAmountError = validateForm({
                                    value: parseFloat(price),
                                    section: 'Balance Loan amount',
                                    minValue: 1,
                                    maxLength: 9,
                                    type: 'number',
                                  } as ValidationObjectModel)
                                })
                                setErrors(newErrors)
                              }
                            }}
                            onErrorHandle={(price: string) => {
                              if (!price) {
                                const newFormValue = produce(
                                  aggrivedPersonDetails,
                                  (draft: PdAggrivedPersonDetailsModel) => {
                                    draft.balanceLoanAmount = 0
                                  }
                                )
                                setAggrivedPersonDetails(newFormValue)
                              } else {
                                const clearError = produce(errors, (draft) => {
                                  draft.balanceLoanAmountError = ''
                                })
                                setErrors(clearError)
                              }
                            }}
                          />
                          <InvalidFeedback
                            message={errors.balanceLoanAmountError}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Row>
                </Col>
              )}
              {getConfirmationOfDeath(fileRegistrationObject.scheme) && (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">Confirmation of Death</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgDeathCNumber">
                      <Form.Label>Death Certificate Number</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.deathCertificateNumberError}
                        type="text"
                        disabled={true}
                        placeholder="Enter Death Certificate Number"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.deathCertificateNumberError = validateForm({
                              type: text,
                              maxLength: 8,
                              value:
                                aggrivedPersonDetails.deathCertificateNumber,
                              section: 'Death certificate number',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.deathCertificateNumber = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.deathCertificateNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.deathCertificateNumber}
                      />
                      <InvalidFeedback
                        message={errors.deathCertificateNumberError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgProvince">
                      <Form.Label>Province</Form.Label>
                      <Select
                        styles={
                          errors.dcIssuedDsProvinceError
                            ? customStyles
                            : undefined
                        }
                        isDisabled={true}
                        value={provinceList.filter(
                          (province: ProvinceOption) => {
                            return (
                              province.value ===
                              selectedDeathCertificateDs.provinceId
                            )
                          }
                        )}
                        options={provinceList}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.provinceOption = selectedOption
                                ? selectedOption
                                : ({} as ProvinceOption)
                              draft.provinceId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.districtOption = null
                              draft.districtId = undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.dcIssuedDsProvinceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback
                        message={errors.dcIssuedDsProvinceError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgDistrict">
                      <Form.Label>District</Form.Label>
                      <Select
                        styles={
                          errors.dcIssuedDsDistrictError
                            ? customStyles
                            : undefined
                        }
                        isDisabled={true}
                        value={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.value ===
                              selectedDeathCertificateDs.districtId
                            )
                          }
                        )}
                        options={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.provinceId ===
                              selectedDeathCertificateDs.provinceId
                            )
                          }
                        )}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.districtOption = selectedOption
                                ? selectedOption
                                : ({} as DistrictOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.dcIssuedDsDistrictError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback
                        message={errors.dcIssuedDsDistrictError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgDeathCDsDivision">
                      <Form.Label>Ds Division of Death Certificate</Form.Label>
                      <Select
                        styles={
                          errors.dcIssuedDsDivisionIdError
                            ? customStyles
                            : undefined
                        }
                        value={dsList.filter((ds: DsOption) => {
                          return ds.value === selectedDeathCertificateDs.dsId
                        })}
                        isDisabled={true}
                        options={dsList.filter((ds: DsOption) => {
                          return (
                            ds.districtId ===
                            selectedDeathCertificateDs.districtId
                          )
                        })}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.dsOption = selectedOption
                                ? selectedOption
                                : ({} as DsOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.dcIssuedDsDivisionIdError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback
                        message={errors.dcIssuedDsDivisionIdError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgIssuedDate">
                      <Form.Label>
                        Issued Date{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <ReactDatePicker
                        maxDate={new Date()}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        required={true}
                        placeholderText="Issued Date"
                        className={
                          errors.issuedDateError
                            ? 'date-picker-validate'
                            : 'form-control'
                        }
                        selected={
                          aggrivedPersonDetails.issuedDate != null
                            ? new Date(aggrivedPersonDetails.issuedDate * 1000)
                            : null
                        }
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.issuedDateError = validateForm({
                              type: 'date',
                              maxValue: new Date().getTime() / 1000,
                              value: aggrivedPersonDetails.issuedDate,
                              section: 'Issued Date',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(date: Date) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.issuedDate = date
                                ? date.getTime() / 1000
                                : null
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.issuedDateError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback message={errors.issuedDateError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgIssuedPlace">
                      <Form.Label>Issued Place</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.issuedPlaceError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Issued Place"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.issuedDateError = validateForm(
                              {
                                type: text,
                                maxLength: 30,
                                value: aggrivedPersonDetails.issuedDate,
                                section: 'Issued Place',
                              } as ValidationObjectModel,
                              false
                            )
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.issuedPlace = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.issuedPlaceError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.issuedPlace}
                      />
                      <InvalidFeedback message={errors.issuedPlaceError} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgMOReport">
                      <Form.Label>JMO Report </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.jmoReportError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter JMO Report"
                        onBlur={() => {
                          if (aggrivedPersonDetails.jmoReport) {
                            const newErrors = produce(errors, (draft) => {
                              draft.jmoReportError = validateForm({
                                maxLength: 30,
                                type: text,
                                value: aggrivedPersonDetails.jmoReport,
                                section: 'JMO report',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.jmoReport = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.jmoReportError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.jmoReport}
                      />
                      <InvalidFeedback message={errors.jmoReportError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgCoronerReport">
                      <Form.Label>Coroner Report</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.coronerReportError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Coroner Report"
                        onBlur={() => {
                          if (aggrivedPersonDetails.coronerReport) {
                            const newErrors = produce(errors, (draft) => {
                              draft.coronerReportError = validateForm({
                                maxLength: 30,
                                type: text,
                                value: aggrivedPersonDetails.coronerReport,
                                section: 'Coroner report',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.coronerReport = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.coronerReportError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.coronerReport}
                      />
                      <InvalidFeedback message={errors.coronerReportError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgOther">
                      <Form.Label>Other</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.otherError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Other"
                        onBlur={() => {
                          if (aggrivedPersonDetails.other) {
                            const newErrors = produce(errors, (draft) => {
                              draft.otherError = validateForm({
                                maxLength: 30,
                                type: text,
                                value: aggrivedPersonDetails.other,
                                section: 'Any other valid report details',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.other = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.otherError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.other}
                      />
                      <InvalidFeedback message={errors.otherError} />
                    </Form.Group>
                  </Row>
                </Col>
              )}

              {getConfirmationOfInjury(fileRegistrationObject.scheme) && (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">
                    Confirmation of Injury
                  </div>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      controlId="formAgDiagnosedOrTreatmentsGivenBy"
                    >
                      <Form.Label>
                        Diagnosed or Treatments given by{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.diagnosedOrTreatmentsGivenByError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Diagnosed or Treatments given by"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.diagnosedOrTreatmentsGivenByError =
                              validateForm({
                                maxLength: 30,
                                type: text,
                                value:
                                  aggrivedPersonDetails.diagnosedOrTreatmentsGivenBy,
                                section: 'Diagnosed or Treatments given by',
                              } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.diagnosedOrTreatmentsGivenBy =
                                e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.diagnosedOrTreatmentsGivenByError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={
                          aggrivedPersonDetails.diagnosedOrTreatmentsGivenBy
                        }
                      />
                      <InvalidFeedback
                        message={errors.diagnosedOrTreatmentsGivenByError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgNameOfTheHospital">
                      <Form.Label>
                        Name of the Hospital{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.nameOfTheHospitalError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        type="text"
                        placeholder="Enter Name of the Hospital"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.nameOfTheHospitalError = validateForm({
                              maxLength: 30,
                              type: text,
                              value: aggrivedPersonDetails.nameOfTheHospital,
                              section: 'Name Of the hospital',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.nameOfTheHospital = e.target.value
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.nameOfTheHospitalError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={aggrivedPersonDetails.nameOfTheHospital}
                      />
                      <InvalidFeedback
                        message={errors.nameOfTheHospitalError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formTypeOfDisablment">
                      <Form.Label>
                        Type of Disablement{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.typeOfDisablementError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        className="form-select"
                        as="select"
                        value={aggrivedPersonDetails.typeOfDisablement}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.typeOfDisablementError = validateForm({
                              type: text,
                              value: aggrivedPersonDetails.typeOfDisablement,
                              section: 'Type of Disablement',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.typeOfDisablement = e.target.value
                              draft.typeOfInjuryId = 0
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.typeOfDisablementError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {1 === 1 ? (
                          <option value="">
                            --Select Type Of Disablement--
                          </option>
                        ) : (
                          ''
                        )}
                        {typeOfDisablementList.map(
                          (
                            title: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option
                                key={`country-${index}`}
                                value={title.name}
                              >
                                {title.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback
                        message={errors.typeOfDisablementError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formCaregoryOfInjury">
                      <Form.Label>
                        Category of Injury{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.categoryOfInjuryError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        className="form-select"
                        as="select"
                        value={aggrivedPersonDetails.categoryOfInjuryId}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.categoryOfInjuryError = validateForm({
                              type: text,
                              value: aggrivedPersonDetails.categoryOfInjuryId,
                              section: 'Category of injury',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.categoryOfInjuryId = parseInt(
                                e.target.value
                              )
                              draft.typeOfInjuryId = 0
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.categoryOfInjuryError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {1 === 1 ? (
                          <option value="">
                            --Select Category Of Injury--
                          </option>
                        ) : (
                          ''
                        )}
                        {categoryInjuryList.map(
                          (
                            title: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`country-${index}`} value={title.id}>
                                {title.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback message={errors.categoryOfInjuryError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formTypeOfInjuryInjury">
                      <Form.Label>
                        Type of Injury{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.typeOfInjuryError ? customStyles : undefined
                        }
                        value={getSelectedInjuryTypeList(
                          injuryList,
                          aggrivedPersonDetails.typeOfInjuryId
                        )}
                        isDisabled={
                          aggrivedPersonDetails.categoryOfInjuryId == null ||
                          aggrivedPersonDetails.typeOfDisablement == '' ||
                          fileRegistrationObject.modifyIndex > 0
                        }
                        options={filterInjuryType()}
                        onChange={(selectedOption) => {
                          console.log(selectedOption)
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.typeOfInjuryId = selectedOption
                                ? selectedOption.value
                                : 0

                              const disablePeriod =
                                disablementPeriodList.filter((disable) => {
                                  return (
                                    disable.name ===
                                    selectedOption?.disablementPeriod
                                  )
                                })[0]
                              if (disablePeriod != null) {
                                draft.disablementPeriod = disablePeriod
                                draft.disablementPeriodId = disablePeriod.id
                              } else {
                                const notDefined = disablementPeriodList.filter(
                                  (disable) => {
                                    return disable.name === 'Not Defined'
                                  }
                                )[0]
                                draft.disablementPeriod = notDefined
                                draft.disablementPeriodId = notDefined.id
                              }
                              draft.eligibleCompensationPercentage =
                                selectedOption?.compensationEligibility
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.typeOfInjuryError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback message={errors.typeOfInjuryError} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formDisablePeriod">
                      <Form.Label>Disablement Period</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Disablement Period"
                        value={aggrivedPersonDetails.disablementPeriod?.name}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formPercentageLoss">
                      <Form.Label>
                        Percentage of loss of earning capacity %{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            if (
                              aggrivedPersonDetails.percentageOfLossOfEarningCapacity >
                              100
                            ) {
                              draft.percentageOfLossOfEarningCapacityError =
                                'Percentage of Loss of Earning Capacity is not valid !'
                            } else if (
                              !aggrivedPersonDetails.percentageOfLossOfEarningCapacity
                            ) {
                              draft.percentageOfLossOfEarningCapacityError =
                                'Percentage of Loss of Earning Capacity cannot be empty !'
                            }
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.percentageOfLossOfEarningCapacity =
                                parseInt(e.target.value)
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)

                          const clearError = produce(errors, (draft) => {
                            draft.percentageOfLossOfEarningCapacityError = ''
                          })
                          setErrors(clearError)
                        }}
                        placeholder="Enter Percentage of loss of earning capacity"
                        value={
                          aggrivedPersonDetails.percentageOfLossOfEarningCapacity
                        }
                      />
                      <InvalidFeedback
                        message={errors.percentageOfLossOfEarningCapacityError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formEligible">
                      <Form.Label>
                        Eligible Compensation Percentage %
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Eligible Compensation Percentage"
                        value={
                          aggrivedPersonDetails.eligibleCompensationPercentage
                        }
                      />
                    </Form.Group>
                  </Row>
                </Col>
              )}
              <Col sm={12} className="border-label mb-3 mt-3">
                <div className="border-label-span">
                  Details of Police Complain
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAgPoliceStation">
                    <Form.Label>Police Station</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.policeStationError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      className="form-select"
                      as="select"
                      value={aggrivedPersonDetails.policeStation}
                      onBlur={() => {
                        if (aggrivedPersonDetails.policeStation) {
                          const newErrors = produce(errors, (draft) => {
                            draft.policeStationError = validateForm({
                              maxLength: 30,
                              type: text,
                              value: aggrivedPersonDetails.policeStation,
                              section: 'Police station',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.policeStation = e.target.value
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.policeStationError = ''
                          draft.complaintDateError = ''
                          draft.complaintNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value="">--Select Police Station--</option>
                      {policeStationList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.policeStationError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgComplainDate">
                    <Form.Label>
                      Complain Date{' '}
                      {fileRegistrationObject.scheme != 'MP' && (
                        <span className="required-field-astrix">*</span>
                      )}
                    </Form.Label>
                    <ReactDatePicker
                      maxDate={new Date()}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      className={
                        errors.complaintDateError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      placeholderText="Complain Date"
                      selected={
                        aggrivedPersonDetails.complaintDate != null
                          ? new Date(aggrivedPersonDetails.complaintDate * 1000)
                          : null
                      }
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.complaintDateError = validateForm({
                            type: 'date',
                            maxValue: new Date().getTime() / 1000,
                            value: aggrivedPersonDetails.complaintDate,
                            section: 'Complain Date',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(date: Date) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.complaintDate = date
                              ? date.getTime() / 1000
                              : null
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.complaintDateError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.complaintDateError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgComplainNumber">
                    <Form.Label>Complain Number</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.complaintNumberError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      type="text"
                      placeholder="Enter Complain Number"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.complaintNumberError = validateForm(
                            {
                              maxLength: 10,
                              type: text,
                              value: aggrivedPersonDetails.complaintNumber,
                              section: 'Complaint number',
                            } as ValidationObjectModel,
                            false
                          )
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.complaintNumber = e.target.value
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.complaintNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={aggrivedPersonDetails.complaintNumber}
                    />
                    <InvalidFeedback message={errors.complaintNumberError} />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mb-3">
                <div className="border-label-span">
                  Other Assistance Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAgNameOfTheInstitution">
                    <Form.Label>Name of the Institution</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.institutionError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      type="text"
                      placeholder="Enter Name of the Institution"
                      onBlur={() => {
                        if (otherAssistantance.institution) {
                          const newErrors = produce(errors, (draft) => {
                            draft.institutionError = validateForm({
                              type: 'text',
                              maxLength: 40,
                              value: otherAssistantance.institution,
                              section: 'Name of the institution',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newOtherAssistantance = produce(
                          otherAssistantance,
                          (draft: OtherAssistanceModel) => {
                            draft.institution = e.target.value
                          }
                        )
                        setOtherAssistantance(newOtherAssistantance)
                        const clearError = produce(errors, (draft) => {
                          draft.institutionError = ''
                          draft.amountReceivedError = ''
                          draft.receivedDateError = ''
                        })
                        setErrors(clearError)
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.compensationAmount = ''
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                      }}
                      value={otherAssistantance.institution}
                    />
                    <InvalidFeedback message={errors.institutionError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgAmountReceived">
                    <Form.Label>Amount Received (Rs.)</Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.amountReceivedError}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        otherAssistantance.institution
                          ? false
                          : true
                      }
                      value={otherAssistantance.amountReceived}
                      placeholder="Enter Amount Received (Rs.)"
                      onChange={(price: string) => {
                        const newFormValue = produce(
                          otherAssistantance,
                          (draft: OtherAssistanceModel) => {
                            draft.amountReceived = parseFloat(price)
                          }
                        )
                        setOtherAssistantance(newFormValue)

                        const newErrors = produce(errors, (draft) => {
                          draft.amountReceivedError = validateForm({
                            value: parseFloat(price),
                            section: 'Amount Received',
                            maxLength: 9,
                            minValue: 1,
                            type: 'number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                        const newValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.compensationAmount = ''
                          }
                        )
                        setAggrivedPersonDetails(newValue)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newFormValue = produce(
                            otherAssistantance,
                            (draft: OtherAssistanceModel) => {
                              draft.amountReceived = 0
                            }
                          )
                          setOtherAssistantance(newFormValue)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.amountReceivedError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback message={errors.amountReceivedError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgReceivedDate">
                    <Form.Label>Received Date</Form.Label>
                    <ReactDatePicker
                      maxDate={new Date()}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        otherAssistantance.institution
                          ? false
                          : true
                      }
                      className={
                        errors.receivedDateError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      placeholderText="Recieved Date"
                      selected={
                        otherAssistantance.receivedDate != null
                          ? new Date(otherAssistantance.receivedDate * 1000)
                          : null
                      }
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.receivedDateError = validateForm({
                            type: 'date',
                            maxValue: new Date().getTime() / 1000,
                            value: otherAssistantance.receivedDate,
                            section: 'Recieved Date',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(date: Date) => {
                        const newOtherAssistantance = produce(
                          otherAssistantance,
                          (draft: OtherAssistanceModel) => {
                            draft.receivedDate = date
                              ? date.getTime() / 1000
                              : null
                          }
                        )
                        setOtherAssistantance(newOtherAssistantance)
                        const clearError = produce(errors, (draft) => {
                          draft.receivedDateError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.receivedDateError} />
                  </Form.Group>
                </Row>
              </Col>
              {(fileRegistrationObject.scheme === 'PD' ||
                fileRegistrationObject.scheme === 'GSD') && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="funeralExpensess">
                    <Form.Label>Funeral Expenses (Rs.)</Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.funeralExpensesError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      value={aggrivedPersonDetails.funeralExpenses}
                      placeholder="Enter Funeral Expenses"
                      onChange={(price: string) => {
                        const newErrors = produce(errors, (draft) => {
                          draft.funeralExpensesError = validateForm(
                            {
                              type: number,
                              maxLength: 9,
                              value: parseFloat(price),
                              section: 'Funeral Expenses',
                            } as ValidationObjectModel,
                            false
                          )
                        })
                        setErrors(newErrors)
                        const newFormValue = produce(
                          aggrivedPersonDetails,
                          (draft: PdAggrivedPersonDetailsModel) => {
                            draft.funeralExpenses = parseFloat(price)
                          }
                        )
                        setAggrivedPersonDetails(newFormValue)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newFormValue = produce(
                            aggrivedPersonDetails,
                            (draft: PdAggrivedPersonDetailsModel) => {
                              draft.funeralExpenses = parseFloat(price)
                            }
                          )
                          setAggrivedPersonDetails(newFormValue)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.funeralExpensesError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback message={errors.funeralExpensesError} />
                  </Form.Group>
                </Row>
              )}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAgCompensationAmount">
                  <Form.Label>Compensation Amount (Rs.)</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.compensationAmountError}
                    disabled
                    placeholder="Compensation Amount (Rs.)"
                    value={
                      aggrivedPersonDetails.compensationAmount &&
                      currencyMask(aggrivedPersonDetails.compensationAmount)
                    }
                  />
                  <InvalidFeedback message={errors.compensationAmountError} />
                  {checkApplicationRegistrationButtonEnable(
                    fileRegistrationObject.status,
                    fileRegistrationObject.modifyIndex
                  ) && (
                    <button
                      disabled={
                        fileRegistrationObject.scheme == 'PI' &&
                        (aggrivedPersonDetails.typeOfInjuryId == 0 ||
                          aggrivedPersonDetails.typeOfInjuryId == null)
                      }
                      type="button"
                      className={`${
                        fileRegistrationObject.scheme == 'PI' &&
                        (aggrivedPersonDetails.typeOfInjuryId == 0 ||
                          aggrivedPersonDetails.typeOfInjuryId == null)
                          ? 'disable-button'
                          : 'save-button'
                      } custom-margin-right mt-3`}
                      onClick={handleCalculation}
                    >
                      Calculate
                    </button>
                  )}
                </Form.Group>
              </Row>
              {installmentList.length > 0 && (
                <Col sm={12} className="border-label mt-4 pb-3">
                  <div className="border-label-span">Installments</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="installmentList">
                      <Row>{renderInstallmentList()}</Row>
                      {checkInstallmentMergeEnable(
                        installmentList.length,
                        fileRegistrationObject.status,
                        fileRegistrationObject.modifyIndex
                      ) && (
                        <button
                          type="button"
                          className="save-button custom-margin-right"
                          onClick={handleMerge}
                        >
                          Merge
                        </button>
                      )}
                    </Form.Group>
                  </Row>
                </Col>
              )}
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={checkSectionThreeNextButtonDisable(
                      fileRegistrationObject.paymentType,
                      installmentList.length
                    )}
                    className={`${
                      checkSectionThreeNextButtonDisable(
                        fileRegistrationObject.paymentType,
                        installmentList.length
                      )
                        ? 'disable-button'
                        : 'save-button'
                    } custom-margin-right`}
                    onClick={() => {
                      if (fileRegistrationObject.modifyIndex == 0) {
                        onClickNext()
                      } else {
                        onClickNextSection()
                      }
                    }}
                  >
                    Next
                  </button>
                  {fileRegistrationObject.modifyIndex == 0 && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  )}
                </>
              )}
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionThree
