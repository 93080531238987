import { SchemeCodeModel } from '../../../models/systemConfiguration/incidentType/schemeCodeModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validateTypeOfDisablement = (value: string) => {
  if (!value) {
    return errorMessages.typeOfDisablementEmpty
  }
}
export const validateCategoryOfInjury = (value: string) => {
  if (!value) {
    return errorMessages.categoryOfInjuryEmpty
  }
}
export const validateInjuryDescription = (value: string) => {
  if (!value) {
    return errorMessages.incidentDescriptionEmpty
  } else if (value.length > 50) {
    return errorMessages.incidentDescriptionLength
  }
}
// export const validateEarningCapacityLoss = (value: number) => {
//   if (value < 0 || value > 100) {
//     return errorMessages.earningCapacityLossLength
//   }
// }
export const validateDisablementPeriod = (value: string) => {
  if (!value) {
    return errorMessages.disablementPeriodEmpty
  }
}
export const validateCompensationEligibility = (value: number) => {
  if (!value) {
    return errorMessages.compensationEligibilityEmpty
  } else if (value < 0 || value > 100) {
    return errorMessages.compensationEligibilityLength
  }
}
export const validateSchemeList = (schemeList: SchemeCodeModel[] | any) => {
  if (!schemeList || schemeList.length === 0) {
    return errorMessages.schemeCodeEmpty
  }
}
