import React, { FC, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ChangePassword } from '../../models/userProfileManager/changePassword'
import { produce } from 'immer'
import { passwordChangedMassage } from '../../utils/successMessages'
import userProfileManageService from '../../services/userProfileManagement/userProfileManageService'
import './Modals.scss'
import { ChangePasswordError } from '../../models/userProfileManager/changePasswordError'
// D:\iterminal\workspace\orims_project\orims_front\orims\orims_front_end\src\models\userProfileManager\changePasswordError.ts
import * as validation from './validationsChangePasword'
import { showTotast } from '../../utils/toast'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { validationError } from '../../utils/errorMessages'
import * as constants from '../../utils/constants'
import { useAuth } from '../../providers/AuthProvider'

export interface changePasswordProps {
  name: string
  message?: string
  modalShow?: React.MouseEventHandler<HTMLButtonElement>
  modalClose?: React.MouseEventHandler<HTMLButtonElement>
}

const MyProfileChangePassword: FC<changePasswordProps> = ({
  name,
  modalShow,
  modalClose,
}) => {
  const [changePassword, setChangePassword] = useState({} as ChangePassword)
  const [errors, setErrors] = useState({} as ChangePasswordError)
  const { logout } = useAuth()
  useEffect(() => {
    setChangePassword((changePassword) => ({
      ...changePassword,
      username: name,
    }))
  }, [name])
  const changePass = async () => {
    const foundErrros = findChangePasswordErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      const response = await userProfileManageService.changePassword(
        changePassword
      )
      let success = false
      let message = response.errorMessage ? response.errorMessage : ''
      if (response.status === 200) {
        success = true
        message = passwordChangedMassage
        logout()
        // history.go()
      }

      showTotast(
        success ? constants.ALERT_SUCCESS : constants.ALERT_WARNING,
        message,
        constants.TOAST_POSITION_TOP_RIGHT,
        5,
        constants.TOAST_TRANSITION_SLIDE
      )
    }
  }

  const findChangePasswordErrors = () => {
    const newErrors = {} as ChangePasswordError
    if (validation.validateCurrentPassword(changePassword.password)) {
      newErrors.password = validation.validateCurrentPassword(
        changePassword.password
      )
    }
    if (validation.validateNewPassword(changePassword.newPassword)) {
      newErrors.newPassword = validation.validateNewPassword(
        changePassword.newPassword
      )
    }
    if (
      validation.validateReEnterdewPassword(
        changePassword.ReEnterdNewPassword,
        changePassword.newPassword
      )
    ) {
      newErrors.ReEnterdNewPassword = validation.validateReEnterdewPassword(
        changePassword.ReEnterdNewPassword,
        changePassword.newPassword
      )
    }

    return newErrors
  }
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={modalClose}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupTitle">
            <Form.Label>
              Current Password <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              isInvalid={!!errors.password}
              type="password"
              onBlur={(e) => {
                const newError = produce(errors, (draft) => {
                  draft.password = validation.validateCurrentPassword(
                    e.target.value
                  )
                })
                setErrors(newError)
              }}
              onChange={(e) => {
                const newFormValue = produce(
                  changePassword,
                  (draft: ChangePassword) => {
                    draft.password = e.target.value
                  }
                )
                setChangePassword(newFormValue)
              }}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupTitle">
            <Form.Label>
              New Password <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={!!errors.newPassword}
              onBlur={(e) => {
                const newError = produce(errors, (draft) => {
                  draft.newPassword = validation.validateNewPassword(
                    e.target.value
                  )
                })
                setErrors(newError)
              }}
              onChange={(e) => {
                const newFormValue = produce(
                  changePassword,
                  (draft: ChangePassword) => {
                    draft.ReEnterdNewPassword = e.target.value
                  }
                )
                setChangePassword(newFormValue)
              }}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.newPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupTitle">
            <Form.Label>
              Re Enter New Password{' '}
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={!!errors.ReEnterdNewPassword}
              onBlur={(e) => {
                const newError = produce(errors, (draft) => {
                  draft.ReEnterdNewPassword =
                    validation.validateReEnterdewPassword(
                      e.target.value,
                      changePassword.newPassword
                    )
                })
                setErrors(newError)
              }}
              onChange={(e) => {
                const newFormValue = produce(
                  changePassword,
                  (draft: ChangePassword) => {
                    draft.newPassword = e.target.value
                  }
                )
                setChangePassword(newFormValue)
              }}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.ReEnterdNewPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button" onClick={modalClose}>
          Close
        </button>
        <button type="button" className="save-button" onClick={changePass}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MyProfileChangePassword
