/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import dashboard_icon from '../../assets/images/dashboard-icon.svg'
import dashboardService from '../../services/dashboardService'
import LoadingComponent from '../../components/common/loading/LoadingComponent'
import filter from '../../assets/images/filter-icon.svg'
import arrowdDown from '../../assets/images/arrow-down.svg'
import DashboardTimeFilter from './DashboardTimeFilter'
import { DashboardFilterModel } from '../../models/utils/dashboardFilterModel'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoadingRequest } from '../../store/app/actionTypes'
import { returnDashboardData } from '../../utils/utilFunctions'
import {
  DashboardFiltersSetRequestAction,
  DashboardTimeFilterUpdateRequest,
  ResetTimeFilterRequest,
} from '../../store/dashboard/actions'
import DashbaordStatusFilter from './DashbaordStatusFilter'
import {
  getStatusilters,
  getTimeFilters,
} from '../../store/dashboard/selectors'
import created from '../../assets/images/dashboard-icons/created.svg'
import recreated from '../../assets/images/dashboard-icons/recreated.svg'
import rejected from '../../assets/images/dashboard-icons/rejected.svg'
import drafted from '../../assets/images/dashboard-icons/drafted.svg'
import returned from '../../assets/images/dashboard-icons/return.svg'
import DashboardItemsModal from '../../components/modals/DashboardItemsModal'
import produce from 'immer'

const DashBoard = () => {
  const [statusList, setStatusList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showTimeFilter, setShowTimeFilter] = useState(false)
  const [showStatusFilter, setShowStatusFilter] = useState(false)
  const [showFiles, setShowFiles] = useState(false)
  const [status, setStatus] = useState('')
  const statusFilters = useSelector(getStatusilters)
  const timeFilters = useSelector(getTimeFilters)
  const dispatch = useDispatch()
  useEffect(() => {
    async function getDashboardData() {
      const newFilter = produce(
        timeFilters[2],
        (draft: DashboardFilterModel) => {
          draft.selected = true
        }
      )
      dispatch(
        DashboardTimeFilterUpdateRequest({
          filter: newFilter,
          index: 2,
        })
      )
      const dashboardData = await dashboardService.apiDashboardData({
        from: 0,
        to: 0,
        timeFilterIndex: 2,
      })
      console.log(dashboardData)
      if (dashboardData.status === 200) {
        const dataList = returnDashboardData(dashboardData.data)
        dispatch(DashboardFiltersSetRequestAction({ filters: dataList }))
        setStatusList(dashboardData.data)
      }
      setLoading(false)
    }
    dispatch(ResetTimeFilterRequest())
    getDashboardData()
  }, [loading])

  const renderIcon = (status: string) => {
    const stringList = status.split(' ')
    let lastString = 'DRAFTED'
    if (stringList.length > 0) {
      lastString = stringList[stringList.length - 1]
    }
    console.log(lastString)
    switch (lastString.toUpperCase()) {
      case 'DRAFTED':
        return <img src={drafted} alt="" />
      case 'RECREATED':
        return <img src={recreated} alt="" />
      case 'REJECTED':
        return <img src={rejected} alt="" />
      case 'RETURNED':
        return <img src={returned} alt="" />
      default:
        return <img src={created} alt="" />
    }
  }

  const renderStatus = () => {
    const selectedIndexList = [] as number[]
    statusFilters.map((filter: DashboardFilterModel, index: number) => {
      if (filter.selected) {
        selectedIndexList.push(index)
        return filter
      }
    })
    console.log(selectedIndexList)
    return statusList?.map((statusItem: any, index: number) => {
      console.log(index)
      const selected = selectedIndexList.indexOf(index)
      if (selected > -1) {
        return (
          <Col key={`status-${index}`} xs={6} sm={6} md={6} lg={3}>
            <button
              className="status-button-layout"
              onClick={() => {
                setStatus(statusItem.status)
                setShowFiles(true)
              }}
            >
              {renderIcon(statusItem.status)}
              {statusItem.status}
            </button>
            <div key={`status-count-${index}`} className="status-count-layout">
              {statusItem.count}
            </div>
          </Col>
        )
      }
    })
  }

  const handleTimeFilter = async (
    filter: DashboardFilterModel,
    index: number
  ) => {
    dispatch(changeLoadingRequest())
    const filterObject = {
      from: filter.value,
      to: filter.to,
      timeFilterIndex: index,
    }
    const dashboardData = await dashboardService.apiDashboardData(filterObject)
    if (dashboardData.status === 200) {
      const dataList = returnDashboardData(dashboardData.data)
      console.log(dataList)
      dispatch(DashboardFiltersSetRequestAction({ filters: dataList }))
      setStatusList(dashboardData.data)
    }
    dispatch(changeLoadingRequest())
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={dashboard_icon} alt="" />
          <span className="m-2">Dashboard</span>
        </Col>
      </Row>
      <Row className="page-tiles-header-layout">
        <Col sm={8}>
          <span className="typo-800">Application</span>
          <span className="typo-600">&ensp;overview</span>
        </Col>
        <Col sm={4}>
          <Row>
            <div className="dashboard-filter">
              <div className="col-4 dashboard-filter-button " id="status">
                <span>Status</span>
                <img
                  role={'button'}
                  onKeyPress
                  onClick={() => {
                    setShowTimeFilter(false)
                    setShowStatusFilter(true)
                  }}
                  src={arrowdDown}
                  alt=""
                />
              </div>
              <div className="col-4 dashboard-filter-button " id="time">
                <span>Time</span>
                <img // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role={'button'}
                  onKeyPress
                  onClick={() => {
                    setShowTimeFilter(true)
                    setShowStatusFilter(false)
                  }}
                  src={arrowdDown}
                  alt=""
                />
              </div>
              <div className="col-4 dashboard-filter-icon" id="icon">
                <img
                  role={'button'}
                  onKeyPress
                  onClick={() => {
                    setShowTimeFilter(false)
                    setShowStatusFilter(false)
                  }}
                  src={filter}
                  alt=""
                />
              </div>
            </div>
          </Row>
          {showTimeFilter && (
            <Row>
              <DashboardTimeFilter
                onFilter={(selected: DashboardFilterModel, index: number) => {
                  setShowTimeFilter(false)
                  handleTimeFilter(selected, index)
                }}
                onCancel={() => {
                  setShowTimeFilter(false)
                }}
              />
            </Row>
          )}
          {showStatusFilter && (
            <Row>
              <DashbaordStatusFilter
                onCancel={() => {
                  setShowStatusFilter(false)
                }}
              />
            </Row>
          )}
        </Col>
      </Row>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Row className="page-tiles-layout">{renderStatus()}</Row>
      )}
      {showFiles && (
        <DashboardItemsModal
          status={status}
          onCancel={() => {
            setShowFiles(false)
          }}
        />
      )}
    </>
  )
}

export default DashBoard
