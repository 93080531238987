import React, { useState } from 'react'
import { Row, Nav, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import AhFileView from '../../../compensationRecommendation/loan/ah/AhFileView'
import LoanAttribute from '../LoanAttribute'
import AhApplication from './AhApplication'
import AhAttribute from './AhAttribute'
import AhFileRegistration from './AhFileRegistration'
import AhReturn from './AhReturn'

const Ah = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('ah')
  const actionListModule = useSelector(
    getUserModuleActions('application-registration', 'loan', 'ah')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'ah',
      'file-return'
    )
  )

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'ah',
      'attribute'
    )
  )

  const renderSwitch = (selection: string) => {
    switch (selection) {
      case 'ah':
        return <AhApplication />
      case 'ah-return':
        return <AhReturn />
      case 'ah-attribute':
        return <AhAttribute />
      default:
        return <AhReturn />
    }
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="ah">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('ah')
                    }}
                    eventKey="ah"
                  >
                    AH Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('ah-return')
                    }}
                    eventKey="ah-return"
                  >
                    AH Return
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', attributeActionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('ah-attribute')
                    }}
                    eventKey="ah-attribute"
                  >
                    AH Attribute Details
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {renderSwitch(selectedSection)}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <AhFileRegistration type="create" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <AhFileRegistration type="return" {...props} />}
      />
      <Route
        path={`${path}/view-similar/:id`}
        render={(props) => <AhFileView type="similar" {...props} />}
      />
      <Route
        path={`${path}/attribute/:id`}
        render={(props) => <LoanAttribute type="ah" {...props} />}
      />
    </Switch>
  )
}

export default Ah
