import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import FileRecommendationList from './FileRecommendationList'
import ShortComingList from './ShortComingList'

const ReportForProperty = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('recommendation')

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="recommendation">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('recommendation')
                  }}
                  eventKey="recommendation"
                >
                  File Recommendation List
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('shortComing')
                  }}
                  eventKey="shortComing"
                >
                  Short Coming List
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'recommendation' ? (
              <FileRecommendationList />
            ) : (
              <ShortComingList />
            )}
          </Row>
        </Tab.Container>
      </Route>
    </Switch>
  )
}

export default ReportForProperty
