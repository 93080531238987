import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { financeBranchReportList } from '../../../utils/metaData'
import ApprovalFileListDetails from './ApprovalFileListDetails'
import CashBookPaymentDetails from './CashBookPaymentDetails'
import ChequesPaymentDetails from './ChequesPaymentDetails'
import TotalPaymentDetails from './TotalPaymentDetails'
import CertificationFileListUserWise from './CertificationFileListUserWise'
import ChequesHandoverToBranch from './ChequesHandoverToBranch'

const FinanceBranchReport = () => {
  const { path } = useRouteMatch()

  const renderModules = () => {
    return financeBranchReportList.map((data: any, index: number) => {
      return (
        <Col key={`module-${index}`} xs={12} sm={12} lg={4} className="mb-4">
          <TileButtonComponent name={data.description} routeURL={data.name} />
        </Col>
      )
    })
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">Finance Branch Reports</Row>
        <Row className="page-tiles-layout">{renderModules()}</Row>
      </Route>

      <Route path={`${path}/approval-file-list-details`}>
        <Row className="title-layout">Approval File List Details</Row>
        <Row className="page-tiles-layout">
          <ApprovalFileListDetails />
        </Row>
      </Route>

      <Route path={`${path}/total-payment-details`}>
        <Row className="title-layout">Total Payment Details</Row>
        <Row className="page-tiles-layout">
          <TotalPaymentDetails />
        </Row>
      </Route>

      <Route path={`${path}/cheques-handover-to-branch`}>
        <Row className="title-layout">Cheques Handover To Branch</Row>
        <Row className="page-tiles-layout">
          <ChequesHandoverToBranch />
        </Row>
      </Route>

      <Route path={`${path}/cash-book-payment-details`}>
        <Row className="title-layout">Cash Book Payment Details</Row>
        <Row className="page-tiles-layout">
          <CashBookPaymentDetails />
        </Row>
      </Route>

      <Route path={`${path}/cheques-payment-details`}>
        <Row className="title-layout">Cheques Payment Details</Row>
        <Row className="page-tiles-layout">
          <ChequesPaymentDetails />
        </Row>
      </Route>

      <Route path={`${path}/certification-file-list-user-wise`}>
        <Row className="title-layout">Certification File List - User Wise</Row>
        <Row className="page-tiles-layout">
          <CertificationFileListUserWise />
        </Row>
      </Route>
    </Switch>
  )
}

export default FinanceBranchReport
