import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import AddSupportingDocuments from './AddSupportingDocuments'
import GeneralDocumentsComponent from './GeneralDocumentsComponent'
import ModifySupportingDocuments from './ModifySupportingDocuments'
import SupportingDocumentsComponent from './SupportingDocumentsComponent'
import ViewGeneralDocument from './ViewGeneralDocument'
import ViewSupportingDocument from './ViewSupportingDocument'

const SupportingDocuments = () => {
  const [selectedSection, setSelectedSection] = useState('pd')
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="pd">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('pd')
                  }}
                  eventKey="pd"
                >
                  Supporting Documents
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('pd-return')
                  }}
                  eventKey="pd-return"
                >
                  General Documents
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'pd' ? (
              <SupportingDocumentsComponent />
            ) : (
              <>
                <GeneralDocumentsComponent />
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route path={`${path}/add`} component={AddSupportingDocuments} />
      <Route path={`${path}/view/:id`} component={ViewSupportingDocument} />
      <Route
        path={`${path}/modify/:id`}
        component={ModifySupportingDocuments}
      />
      <Route path={`${path}/:code`} component={ViewGeneralDocument} exact />
    </Switch>
  )
}

export default SupportingDocuments
