import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { Switch, Route } from 'react-router-dom'
import AddBank from './manageBank/AddBank'
import ManageBank from './manageBank/ManageBank'
import ModifyBank from './manageBank/ModifyBank'
import ViewBank from './manageBank/ViewBank'
import AddBranch from './manageBranch/AddBranch'
import BankList from './manageBranch/BankList'
import ManageBranch from './manageBranch/ManageBranch'
import ModifyBranch from './manageBranch/ModifyBranch'
import ViewBranch from './manageBranch/ViewBranch'

const ManageBankBranch = () => {
  const [selectedSection, setSelectedSection] = useState('pd')
  // const path = useRouteMatch()
  return (
    <Switch>
      <Route path={`/system-configuration/bank-and-branch`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="pd">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('pd')
                  }}
                  eventKey="pd"
                >
                  Manage Bank
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('pd-return')
                  }}
                  eventKey="pd-return"
                >
                  Manage Branch
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'pd' ? (
              <ManageBank />
            ) : (
              <>
                <BankList />
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`/system-configuration/bank-and-branch/bank/add`}
        component={AddBank}
      />
      <Route
        path={`/system-configuration/bank-and-branch/bank/view/:id`}
        component={ViewBank}
      />
      <Route
        path={`/system-configuration/bank-and-branch/bank/modify/:id`}
        component={ModifyBank}
      />
      <Route
        path={`/system-configuration/bank-and-branch/manage-branch/:id`}
        component={ManageBranch}
      />

      <Route
        path={`/system-configuration/bank-and-branch/branch/add/:id`}
        component={AddBranch}
      />

      <Route
        path={`/system-configuration/bank-and-branch/branch/view/:id`}
        component={ViewBranch}
      />
      <Route
        path={`/system-configuration/bank-and-branch/branch/modify/:id`}
        component={ModifyBranch}
      />
      {/* <Route path={`${path}/:code`} component={ViewGeneralDocument} exact /> */}
    </Switch>
  )
}

export default ManageBankBranch
