import { RelationShipModel } from '../../../models/systemConfiguration/compensationValues/relationShipModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validateTypeofProgram = (value: string) => {
  if (!value) {
    return errorMessages.typeofProgramEmpty
  }
}
export const validateScheme = (value: string) => {
  if (!value) {
    return errorMessages.schemeEmpty
  }
}
export const validateIncidentType = (value: number) => {
  if (!value) {
    return errorMessages.incidentTypeEmpty
  }
}
export const validateCompensationfor = (value: string) => {
  if (!value) {
    return errorMessages.compensationforEmpty
  }
}
export const validatePercentagesAmongRelationship = (
  value: RelationShipModel[]
) => {
  if (!value || value.length === 0) {
    return errorMessages.percentagesAmongRelationshipEmpty
  }
}
export const validateDamageType = (value: string) => {
  if (!value) {
    return errorMessages.damageTypeEmpty
  }
}
export const validateMaximumAmount = (value: number) => {
  if (!value) {
    return errorMessages.maximumAmountEmpty
  } else if (value <= 0 || value > 999999999) {
    return errorMessages.maximumAmountNotValid
  }
}
export const validateMinimumAmount = (value: number) => {
  if (value < 0 || value > 999999999) {
    return errorMessages.minimumAmountNotValid
  }
}
