import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import TileButtonComponent from '../../components/common/tileButton/TileButtonComponent'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { getUserSubPackages } from '../../store/user/selectors'
import Compensation from '../compensationApprovals/compensation/Compensation'
import Loan from '../compensationApprovals/loan/Loan'
import application_registration from '../../assets/images/approval.svg'

const CompensationApprovals = () => {
  const { path } = useRouteMatch()
  const subPackageList = useSelector(
    getUserSubPackages('compensation-approval')
  )

  const renderSubPackages = () => {
    return subPackageList.map((subPackage: SubPackageModal, index: number) => {
      return (
        <Col key={`sub-package-${index}`} xs={4}>
          <TileButtonComponent
            name={subPackage.description}
            routeURL={subPackage.name}
          />
        </Col>
      )
    })
  }
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={application_registration} alt="" />
            <span className="m-2">Compensation Approvals</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">{renderSubPackages()}</Row>
      </Route>
      <Route path={`${path}/compensation`} component={Compensation} />
      <Route path={`${path}/loan`} component={Loan} />
    </Switch>
  )
}

export default CompensationApprovals
