import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { RoleListModal } from '../../../models/user/roleListModel'
import { UserModal } from '../../../models/usermanagement/userModel'
import userManagementService from '../../../services/userManagementService'
import {
  districtFetchRequest,
  dsFetchRequest,
  gnFetchRequest,
  provinceFetchRequest,
} from '../../../store/administrativeArea/actions'
import './ViewUserComponent.scss'
import { ProvinceModal } from '../../../models/administrativeArea/provinceModal'
import Province from './userAdministrativeAreas/Province'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import ReactTableComponent from '../../../components/table/ReactTableComponent'
import {
  userActive,
  userBlock,
  userInActive,
  userUnblock,
} from '../../../utils/successMessages'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
type TParams = { epfNumber: string }
const ViewUserComponent = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [userInfo, setUserInfo] = useState({} as UserModal)
  const [auditHistory, setAuditHistory] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const [reLoad, setReLoad] = useState({ reload: String })
  const actionList = useSelector(
    getUserSubPackageActions('user-management', 'user')
  )
  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getUserDetails() {
      const userData = await userManagementService.apiFetchUserDataByEpfNumber(
        match.params.epfNumber
      )
      const userAuditHistory =
        await userManagementService.apiFetchUserAuditHistory(
          match.params.epfNumber
        )
      setUserInfo(userData.data)
      setAuditHistory(userAuditHistory.data)
    }
    getUserDetails()
    setloading(false)
  }, [reLoad])

  const roleListColumns = React.useMemo(
    () => [
      {
        Header: 'Role Name',
        accessor: 'name', // accessor is the "key" in the data
      },
    ],
    []
  )
  const adutHistoryColumns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title', // accessor is the "key" in the data
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  )
  const handleBack = () => {
    history.goBack()
  }
  const handleModifyButton = async () => {
    history.push(`/user-management/user/modify/${match.params.epfNumber}`)
    dispatch(provinceFetchRequest())
    dispatch(districtFetchRequest())
    dispatch(dsFetchRequest())
    dispatch(gnFetchRequest())
  }
  const renderRoleTable = () => {
    if (userInfo.roleList) {
      return (
        <ReactTableComponent
          columns={roleListColumns}
          data={userInfo.roleList.map((role: RoleListModal) => {
            return role.role
          })}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <h4>No Roles Found</h4>
    }
  }
  // const renderApplicationProgramTypesandSchemesTable = () => {
  //   return <h5>Pending...</h5>
  // }
  const renderAdministrativeAreas = () => {
    if (userInfo.userAdministrativeAreas) {
      return (
        <Col>
          {userInfo.userAdministrativeAreas.map(
            (province: ProvinceModal, index: number) => {
              return (
                <ul className="custom-ul" key={`province-${province.id}`}>
                  <Province
                    key={`province-${index}`}
                    name={province.provinceName}
                    districtList={province.districtList}
                  />
                </ul>
              )
            }
          )}
        </Col>
      )
    } else {
      return <span>No Roles Found</span>
    }
  }
  const renderAuditHistoryTable = () => {
    if (auditHistory) {
      return (
        <ReactTableWithPaginationComponent
          columns={adutHistoryColumns}
          data={auditHistory}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <span>Audit history not found</span>
    }
  }

  let statusAction = ''
  let statusAction2 = ''
  if (userInfo.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
    checkPermission('block') === true && (statusAction2 = 'Block')
  }
  if (userInfo.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  if (userInfo.status == 'Block') {
    checkPermission('unblock') === true && (statusAction = 'Unblock')
  }
  const handleSelect = async (e: any) => {
    setloading(true)
    if (e == 'Inactive') {
      const activeData = await userManagementService.apiUserInactive(
        match.params.epfNumber
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = userInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setUserInfo(activeData.data)
      setReLoad(e)
      setloading(false)
    }
    if (e == 'Active') {
      const inactiveData = await userManagementService.apiUserActive(
        match.params.epfNumber
      )
      let success = false
      let message = inactiveData.errorMessage ? inactiveData.errorMessage : ''
      if (inactiveData.status === 200) {
        success = true
        message = userActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setUserInfo(inactiveData.data)
      setReLoad(e)
      setloading(false)
    }
    if (e == 'Block') {
      const blockData = await userManagementService.apiUserBlock(
        match.params.epfNumber
      )
      let success = false
      let message = blockData.errorMessage ? blockData.errorMessage : ''
      if (blockData.status === 200) {
        success = true
        message = userBlock
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setUserInfo(blockData.data)
      setReLoad(e)
      setloading(false)
    }
    if (e == 'Unblock') {
      const unblockData = await userManagementService.apiUserUnblock(
        match.params.epfNumber
      )
      let success = false
      let message = unblockData.errorMessage ? unblockData.errorMessage : ''
      if (unblockData.status === 200) {
        success = true
        message = userUnblock
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setUserInfo(unblockData.data)
      setReLoad(e)
      setloading(false)
    }
  }

  return (
    <>
      {loading ? (
        <Container className="user-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-user-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View User</div>
            <Form>
              <Row className="text-align-right mb-2">
                <Col>
                  {checkPermission('inactive') === true ||
                  checkPermission('Active') === true ||
                  checkPermission('block') === true ||
                  checkPermission('unblock') === true ? (
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={{ lg: 'end' }}>
                        {statusAction.length > 0 ? (
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        ) : (
                          ''
                        )}
                        {statusAction2.length > 0 ? (
                          <Dropdown.Item eventKey={statusAction2}>
                            {statusAction2}
                          </Dropdown.Item>
                        ) : (
                          ''
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" disabled value={userInfo.title} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" disabled value={userInfo.firstName} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" disabled value={userInfo.lastName} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEPFNumber">
                <Form.Label>EPF Number</Form.Label>
                <Form.Control type="text" disabled value={userInfo.epfNumber} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="text" disabled value={userInfo.email} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={userInfo.mobileNumber}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupOrganization">
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={userInfo.organization}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupDesignation">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={userInfo.designation}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control type="text" disabled value={userInfo.status} />
              </Form.Group>
            </Form>
            <br />
            <Row sm={4}>
              <Col>
                <Form.Label>Role List</Form.Label>
                <br />
                {renderRoleTable()}
              </Col>
            </Row>
            <br />
            {/* <Row>
              <Form.Label>Application Program Types and Schemes </Form.Label>
              <br />
              <Col>{renderApplicationProgramTypesandSchemesTable()}</Col>
            </Row> */}
            <br />
            <Row>
              <Col>
                <Form.Label>Administrative Area List</Form.Label>
                <br />
                <Row className="admin-area-list">
                  {renderAdministrativeAreas()}
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Audit History </Form.Label>
                <br />
                <br />
                {renderAuditHistoryTable()}
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="ok-button" onClick={handleBack}>
              Ok
            </button>
            {checkPermission('modify') === true && (
              <button className="modify-button" onClick={handleModifyButton}>
                Modify
              </button>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewUserComponent
