import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import IterminalTableComponent from '../../../../components/table/IterminalTableComponent'
import { FilterModel } from '../../../../models/utils/filterModel'
import { FilterOptionModel } from '../../../../models/utils/filterOption'
import { filtersSetRequest } from '../../../../store/filter/actions'
import { getFilters } from '../../../../store/filter/selectors'
import { compensationApplicationsFilters } from '../../../../utils/metaData'
import { getPageCount } from '../../../../utils/tableFunctions'
import ChequePrintService from '../../../../services/chequePayments/chequePrint/chequePrintService'
import ViewChequeList from '../ViewChequeList'
import CompensationApplicationsFilter from '../../../../components/filters/CompensationApplicationsFilter'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../../utils/constants'

const GcpApplication = () => {
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
    filterData: [
      {
        operator: 'STRING_EQUAL',
        property: 'scheme',
        value: 'GPC',
      },
    ] as FilterOptionModel[],
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const [state, setstate] = useState([])
  const [loading, setLoading] = useState(true)
  const filters = useSelector(getFilters)
  const { path } = useRouteMatch()

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationApplicationsFilters }))
    async function getApplicationFiles() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getApplicationFiles()
  }, [])

  const retriveApplicationFilesData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)
    const applicationData = await ChequePrintService.apiFetchFileList(newFilter)
    setstate(applicationData.data)
    setLoading(false)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  async function retriveDataCountAndData(filterData: FilterModel) {
    const applicationCountData = await ChequePrintService.apiFetchFileListCount(
      filterData
    )
    if (applicationCountData.status === 200) {
      setDatacount(applicationCountData.data.count)
      setPageCount(
        getPageCount(applicationCountData.data.count, filterOption.limit)
      )
      const applicationData = await ChequePrintService.apiFetchFileList(
        filterData
      )
      setstate(applicationData.data)
    } else {
      setDatacount(0)
      setPageCount(0)
      setstate([])
    }
  }

  const handleView = (original: any) => {
    history.push(`${path}/view-cheques/${original.id}`)
  }
  const fileRegistrationColumns = [
    {
      Header: 'Application Reference',
      accessor: 'referenceNumber',
    },
    {
      Header: 'Incident Type',
      accessor: 'incidentType.incidentType',
    },
    {
      Header: 'Scheme',
      accessor: 'scheme',
    },
    {
      Header: 'Applicant Name',
      accessor: 'applicantName',
    },
    {
      Header: 'NIC',
      accessor: 'newNicNumber',
    },
    {
      Header: 'Province',
      accessor: 'province.name',
    },
    {
      Header: 'District',
      accessor: 'district.name',
    },
    {
      Header: 'DS Division',
      accessor: 'dsDivision.name',
    },
    {
      Header: 'GN Division',
      accessor: 'gnDivision.name',
    },
    {
      Header: 'File Received Date',
      accessor: 'fileReceivedDate',
    },
    {
      Header: 'Created Date and Time',
      accessor: 'createdDateTime',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (e: any) => {
        if (e.cell.value != '') {
          return (
            <button
              className="save-button"
              onClick={(action) => {
                action.preventDefault()
                action.stopPropagation()
                handleView(e.row.original)
              }}
            >
              Cheque Details
            </button>
          )
        }
      },
    },
  ]
  const columns = React.useMemo(() => fileRegistrationColumns, [])

  const handleFilter = async (value: string) => {
    const filterValues = {
      descending: true,
      limit: defaultPageLimit,
      orderFields: defaultOrderListLimit,
      filterData: [
        {
          operator: 'STRING_EQUAL',
          groupingOperator: 'AND',
          property: 'scheme',
          value: 'GPC',
        },
        {
          operator: 'LIKE',
          groupingOperator: 'AND',
          property: 'reference_number',
          value: value,
        },
        {
          operator: 'LIKE',
          groupingOperator: 'OR',
          property: 'voucher_number',
          value: value,
        },
        {
          operator: 'LIKE',
          groupingOperator: 'OR',
          property: 'cheque_number',
          value: value,
        },
      ] as FilterOptionModel[],
    } as FilterModel
    if (value) {
      await retriveDataCountAndData(filterValues)
    } else {
      await retriveDataCountAndData(filter)
    }
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="m-2">GPC Applications</span>
            </Col>
          </Row>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <Row>
                <Col sm={12} className="d-flex">
                  {!loading && (
                    <>
                      <div className="mb-4">
                        <CompensationApplicationsFilter
                          scheme="GPC"
                          filterData={filters}
                          onFilter={(e: FilterOptionModel[]) => {
                            dispatch(filtersSetRequest({ filters: e }))
                            retriveFilterData(
                              e.filter(
                                (filter: FilterOptionModel) =>
                                  filter.selected === true
                              )
                            )
                          }}
                        />
                      </div>
                      <div className="mb-4 ml-2">
                        <Form.Group controlId="filter">
                          <Form.Control
                            placeholder="Enter the application reference number or voucher number or cheque number to filter records."
                            type="text"
                            onChange={(e: any) => {
                              handleFilter(e.target.value)
                            }}
                          />
                        </Form.Group>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <IterminalTableComponent
                    columns={columns}
                    data={state}
                    fetchData={retriveApplicationFilesData}
                    loading={loading}
                    pageCount={pageCount}
                    totalCount={dataCount}
                    onClickRow={(row: any) => {
                      console.log(row)
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Route>
        <Route
          path={`${path}/view-cheques/:id`}
          render={(props) => <ViewChequeList {...props} />}
        />
      </Switch>
    </>
  )
}

export default GcpApplication
