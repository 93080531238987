/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ActionListModal } from '../../../models/package/actionListModal'
import { SubModuleModal } from '../../../models/package/subModuleModal'
import Action from './Action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

export interface SubModuleProps {
  subModuleModal: SubModuleModal
  mode: boolean
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
  subModuleIndex: number
}

const SubModule: FC<SubModuleProps> = ({
  subModuleModal,
  mode,
  subModuleIndex,
  packageIndex,
  subPackageIndex,
  moduleIndex,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const renderActionList = () => {
    return subModuleModal.actionList.map(
      (action: ActionListModal, index: number) => {
        return (
          <Action
            key={`sub-module-action-${subModuleModal.id}-${index}`}
            actionListModal={action}
            index={index}
            mode={mode}
            relevantModue={`sub-module`}
            packageIndex={packageIndex}
            subPackageIndex={subPackageIndex}
            moduleIndex={moduleIndex}
            subModuleIndex={subModuleIndex}
          />
        )
      }
    )
  }
  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {subModuleModal.description}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">{renderActionList()}</Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default SubModule
