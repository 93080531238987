import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../models/utils/filterModel'
import apiService from '../api/apiManager'

class ApplicationFilesDataService {
  async apiFetchApplicationFilesList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/application-files/view/list`,
      filterData
    )
  }

  async apiFetchApplicationFilesListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/application-files/view/count`,
      filterData
    )
  }

  async apiFetchApplicationFilesView(id: number) {
    return apiService.apiGet(
      `/application-registration/file-registration/application-files/view/${id}`
    )
  }

  async apiFetchApplicationFilesHistory(id: number) {
    return apiService.apiGet(`/meta-data/application-file/${id}/audit-history`)
  }

  async apiFetchApplicationFilesReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/application-files/file-return/view/list`,
      filterData
    )
  }

  async apiFetchApplicationFilesReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/application-files/file-return/view/count`,
      filterData
    )
  }

  async apiFetchApplicationFilesReturnView(id: number) {
    return apiService.apiGet(
      `/application-registration/file-registration/application-files/file-return/view/${id}`
    )
  }

  async apiFetchApplicationFilesReturnCancel(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/application-files/file-return/cancel/${id}`,
      { remark: remark }
    )
  }

  async apiFetchApplicationFilesReturnModify(
    id: number,
    file: FileRegistrationModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/application-files/file-return/modify/${id}`,
      file
    )
  }

  async apiUpdatBasicInformation(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/application-files/${id}/modify`,
      file
    )
  }
}

export default new ApplicationFilesDataService()
