import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { BreadCrumbModal } from '../../../models/breadcrumb/breadCrumb'
import { breadCrumbNavigationAddRequest } from '../../../store/breadCrumb/actions'
import './NavItem.scss'

export interface NavItemProps {
  id: number
  name: string
  routeURL: string
  isSelected?: boolean
}

const NavItem: FC<NavItemProps> = ({
  id,
  name,
  routeURL,
  isSelected = false,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleButtonClick = () => {
    const breadCrumb: BreadCrumbModal = {
      name: name,
      route: routeURL,
      active: true,
    }
    dispatch(breadCrumbNavigationAddRequest({ breadcrumb: breadCrumb }))
    history.push(`/${routeURL}`)
  }

  const className = isSelected
    ? 'nav-button-layout-active'
    : 'nav-button-layout'
  return (
    <div
      key={id}
      aria-hidden="true"
      role="button"
      className={className}
      onClick={() => {
        handleButtonClick()
      }}
    >
      <img
        className="navigation-icon"
        src={`/src/assets/images/nav-icons/${routeURL}.svg`}
        alt={``}
      />
      <span>{name}</span>
    </div>
  )
}

export default NavItem
