import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import FilterButton from '../common/filterButton/FilterButton'

export interface FileAllocationFiltersProps {
  filterData: FilterOptionModel[]
  onFilter: any
}

const SupportingDocumentFilters: FC<FileAllocationFiltersProps> = ({
  filterData,
  onFilter,
}) => {
  const [filters, setFilters] = useState(filterData)
  const [show, setShow] = useState(false)

  useEffect(() => {
    setFilters(filterData)
  }, [show])
  return (
    <>
      <FilterButton
        onClick={() => {
          setShow(true)
        }}
      />
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false)
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="custom-modal-layout"
        contentClassName="custom-modal-content"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="custom-modal-header"
        >
          <Modal.Title id="example-modal-sizes-title-sm">{`Filter`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            {filters.length > 0 && (
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="documentName">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[0].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Document Name`}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={filters[0].value}
                      disabled={!filters[0].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="scheme">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[1].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Scheme`}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      value={filters[1].value}
                      disabled={!filters[1].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="save-button"
            onClick={() => {
              onFilter(filters)
              setShow(false)
            }}
          >
            Filter Results
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SupportingDocumentFilters
