/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ITerminalWizardComponent from '../../components/common/ITerminalWizardComponent/ITerminalWizardComponent'
import LoadingComponent from '../../components/common/loading/LoadingComponent'
import PermissionDeniedModal from '../../components/modals/PermissionDeniedModal'
import AffectedHouseSection from '../../components/sections/AffectedHouseSection'
import FamilyDetailsSection from '../../components/sections/FamilyDetailsSection'
import DependentAndRehabilitationSection from '../../components/sections/DependentAndRehabilitationSection'
import OtherDetailsSection from '../../components/sections/OtherDetailsSection'
import SectionAffectedProperty from '../../components/sections/SectionAffectedProperty'
import SectionBankDetails from '../../components/sections/SectionBankDetails'
import SectionOne from './sections/SectionOne'
import SectionTwo from '../../components/sections/SectionTwo'
import SupportDocumentSection from '../../components/sections/SupportDocumentSection'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { getUserPackages } from '../../store/user/selectors'
import {
  pdSectionListForEnquire,
  seSectionList,
  srtSectionList,
  ahSectionList,
  gpcSectionListForEnquire,
  rlpSectionListForEnquire,
  mpSectionListForEnquire,
} from '../../utils/metaData'
import ChequePaymentDetails from './chequePaymentDetails/ChequePaymentDetails'
import LoanAttributeDetails from './loanAttributeDetails/LoanAttributeDetails'
import SectionFour from './sections/SectionFour'
import SectionFive from './sections/SectionFive'
import SectionThree from './sections/SectionThree'
// import SectionThree from './sections/SectionThree'

const FileView = (props: any) => {
  const {
    applicationFile,
    match: { params },
  } = props
  const [loading, setLoading] = useState(true)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const history = useHistory()

  useEffect(() => {
    getFileDetails()
  }, [])

  async function getFileDetails() {
    const result = await enquireApplicationService.apiGetFile(
      parseInt(params.id)
    )
    setFileRegistrationObject(result.data)
    setLoading(false)
  }

  const handleClick = (data: any) => {
    if (data.section === 'ok') {
      history.goBack()
    }
  }
  const handleGoback = () => {
    history.goBack()
  }
  const selectSection = () => {
    switch (fileRegistrationObject.scheme) {
      case 'MP': {
        return mpSectionListForEnquire
        break
      }
      case 'PD': {
        return pdSectionListForEnquire
        break
      }
      case 'GPC': {
        return gpcSectionListForEnquire
        break
      }
      case 'GSC': {
        return gpcSectionListForEnquire
        break
      }
      case 'GSD': {
        return pdSectionListForEnquire
        break
      }
      case 'GSI': {
        return pdSectionListForEnquire
        break
      }
      case 'PI': {
        return pdSectionListForEnquire
        break
      }
      case 'RLP': {
        return rlpSectionListForEnquire
        break
      }
      case 'SE': {
        const newSeSectionList = produce(seSectionList, (draft) => {
          draft.push({
            name: ' Attributes',
            id: 6,
          })
        })
        return newSeSectionList
        break
      }
      case 'AH': {
        const newAhSectionList = produce(ahSectionList, (draft) => {
          draft.push({
            name: ' Attributes',
            id: 5,
          })
        })
        return newAhSectionList
        break
      }
      case 'SRT': {
        const newSrtSectionList = produce(srtSectionList, (draft) => {
          draft.push({
            name: ' Attributes',
            id: 5,
          })
        })
        return newSrtSectionList
        break
      }
      default: {
        return []
        break
      }
    }
  }

  return (
    <>
      {true ? (
        <>
          {loading ? (
            <div className="loading-layout">
              <LoadingComponent />
            </div>
          ) : (
            <>
              <Row className="title-layout">
                <Col className="d-flex justify-content-start">
                  <span className="compensation-title mt-2 mb-2 ml-2">
                    {`Application reference : ${fileRegistrationObject.referenceNumber}`}
                  </span>
                </Col>
              </Row>
              <ITerminalWizardComponent
                sectionList={selectSection()}
                activeSection={activeSection}
                validationLevel={
                  fileRegistrationObject.validationLevel == 5
                    ? fileRegistrationObject.validationLevel
                    : fileRegistrationObject.validationLevel - 1
                }
                onClick={(sectionNumber: number) => {
                  setActiveSection(sectionNumber)
                }}
              />
              <Row className="section-layout">
                {activeSection === 1 && (
                  <SectionOne
                    fileRegistrationObject={fileRegistrationObject}
                    onClick={(data: any) => {
                      handleClick(data)
                    }}
                    packageName={'enquire-application'}
                    onReload={getFileDetails}
                  />
                )}
                {activeSection === 2 && (
                  <SectionTwo
                    fileRegistrationObject={fileRegistrationObject}
                    onOk={handleGoback}
                  />
                )}
                {activeSection === 3 &&
                  (fileRegistrationObject.scheme === 'PD' ||
                    fileRegistrationObject.scheme === 'MP' ||
                    fileRegistrationObject.scheme === 'GSD' ||
                    fileRegistrationObject.scheme === 'PI' ||
                    fileRegistrationObject.scheme === 'GSI') && (
                    <SectionThree
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}

                {activeSection === 3 &&
                  (fileRegistrationObject.scheme === 'GPC' ||
                    fileRegistrationObject.scheme === 'GSC' ||
                    fileRegistrationObject.scheme === 'RLP') && (
                    <SectionAffectedProperty
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 3 &&
                  fileRegistrationObject.scheme === 'SE' && (
                    <FamilyDetailsSection
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 3 &&
                  fileRegistrationObject.scheme === 'AH' && (
                    <AffectedHouseSection
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 3 &&
                  fileRegistrationObject.scheme === 'SRT' && (
                    <DependentAndRehabilitationSection
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 4 &&
                  (fileRegistrationObject.scheme === 'PD' ||
                    fileRegistrationObject.scheme === 'MP' ||
                    fileRegistrationObject.scheme === 'GSD' ||
                    fileRegistrationObject.scheme === 'PI' ||
                    fileRegistrationObject.scheme === 'GSI') && (
                    <SectionFour
                      fileRegistrationObject={fileRegistrationObject}
                    />
                  )}

                {activeSection === 4 &&
                  (fileRegistrationObject.scheme === 'GPC' ||
                    fileRegistrationObject.scheme === 'GSC' ||
                    fileRegistrationObject.scheme === 'RLP') && (
                    <SectionBankDetails
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                      applicantName={fileRegistrationObject.applicantName}
                      scheme={fileRegistrationObject.scheme}
                    />
                  )}
                {activeSection === 4 &&
                  (fileRegistrationObject.scheme === 'AH' ||
                    fileRegistrationObject.scheme === 'SRT') && (
                    <SupportDocumentSection
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 4 &&
                  fileRegistrationObject.scheme === 'SE' && (
                    <OtherDetailsSection
                      packageName={'enquire-application'}
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 5 &&
                  !(
                    fileRegistrationObject.scheme === 'SRT' ||
                    fileRegistrationObject.scheme === 'AH'
                  ) && (
                    <SectionFive
                      fileRegistrationObject={fileRegistrationObject}
                    />
                  )}
                {activeSection === 5 &&
                  (fileRegistrationObject.scheme === 'SRT' ||
                    fileRegistrationObject.scheme === 'AH') && (
                    <LoanAttributeDetails
                      fileRegistrationObject={fileRegistrationObject}
                    />
                  )}
                {activeSection === 6 &&
                  fileRegistrationObject.programType === 'COMPENSATION' && (
                    <ChequePaymentDetails
                      fileRegistrationObject={fileRegistrationObject}
                      onOk={handleGoback}
                    />
                  )}
                {activeSection === 6 &&
                  fileRegistrationObject.programType === 'LOAN' && (
                    <LoanAttributeDetails
                      fileRegistrationObject={fileRegistrationObject}
                    />
                  )}
              </Row>
            </>
          )}
        </>
      ) : (
        <PermissionDeniedModal
          name={'permission denied'}
          message={'You do not have permission for enquire application !'}
          onOk={() => {
            // history.push('/')
            window.close()
          }}
        />
      )}
    </>
  )
}

export default FileView
