import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  ALERT_SUCCESS,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import { validateForm } from '../../../../utils/utilFunctions'
import BankAndBranchServices from '../../../../services/systemConfiguration/manegeBankAndBranch/bankAndBranchServices'
import { IBank } from '../../../../models/systemConfiguration/manageBankAndBranch/ManageBankAndBranch'

interface BnakErrorModel {
  codeError?: string
  nameError?: string
  descriptionError?: string
}
type TParams = { id: string }
const ModifyBank = ({ match }: RouteComponentProps<TParams>) => {
  const [state, setSate] = useState({} as IBank)
  const history = useHistory()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as BnakErrorModel)
  useEffect(() => {
    async function getData() {
      const result = await BankAndBranchServices.apiGetBank(
        parseInt(match.params.id)
      )
      setSate(result.data)
    }
    getData()
  }, [])
  const findFormErrors = () => {
    const newErrors = {} as BnakErrorModel
    if (
      validateForm({
        maxLength: 50,
        type: 'text',
        value: state.name,
      } as ValidationObjectModel)
    ) {
      newErrors.nameError = validateForm({
        type: 'text',
        maxLength: 50,
        value: state.name,
        section: 'Name of the bank',
      } as ValidationObjectModel)
    }
    if (state.description) {
      if (
        validateForm({
          maxLength: 50,
          type: 'text',
          value: state.description,
        } as ValidationObjectModel)
      ) {
        newErrors.descriptionError = validateForm({
          type: 'text',
          maxLength: 50,
          value: state.description,
          section: 'Description',
        } as ValidationObjectModel)
      }
    }
    return newErrors
  }

  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await BankAndBranchServices.apiUpdateBank(
      match.params.id,
      state
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = 'Bank successfully updated'
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img
            src={
              '/src/assets/images/systemConfiguration/supporting-document.svg'
            }
            alt=""
          />
          <span className="m-2">Manage banks</span>
        </Col>
      </Row>
      <Row className="add-Incident-Type-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Modify Bank</div>
          <Form>
            <Form.Group className="mb-3" controlId="branchCode">
              <Form.Label>
                Code <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                value={state.code}
                isInvalid={!!errors.codeError}
                type="text"
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.code = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.codeError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.codeError} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bankName">
              <Form.Label>
                Name <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                value={state.name}
                isInvalid={!!errors.nameError}
                type="text"
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.name = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.nameError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.nameError} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bankDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={state.description}
                isInvalid={!!errors.descriptionError}
                type="text"
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.description = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.descriptionError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.descriptionError} />
            </Form.Group>
          </Form>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="cancel-button"
                onClick={(e) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-button"
                onClick={handleSubmit}
              >
                Save
              </button>
            </Col>
          </Row>
        </Col>
        {showConfirmation && (
          <ConfirmationModal
            name={'Confirmation'}
            message={'Are you sure to update this bank ?'}
            onCancel={() => {
              setShowConfirmation(false)
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
    </>
  )
}

export default ModifyBank
