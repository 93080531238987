import produce from 'immer'
import React, { FC, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { ReminderModel } from '../../models/applicationRegistration/compensation/reminderModel'
import { SupportingDocumentsModel } from '../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import InvalidFeedback from '../common/invalidFeedback/InvalidFeedback'
import './Modals.scss'
import Select from 'react-select'
import { CommonOptionModel } from '../../models/selectOptionModels/CommonOptionModel'
import { languages } from '../../utils/constants'

export interface ConfirmationModalProps {
  supportingDocumentList: SupportingDocumentsModel[]
  draftedReminderList: ReminderModel[]
  onConfirm?: any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  unUploadedDocList: SupportingDocumentsModel[]
}
export interface ReminderPrintModel {
  reminder: number
  supportingDocumentIds: number[]
  language: string
  type: string
}
interface ReminderPrintErrorModel {
  reminderError: string
  supportingDocumentIdError: string
  languageError: string
  typeError: string
}
const ReminderPrintModal: FC<ConfirmationModalProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  supportingDocumentList,
  draftedReminderList,
  onConfirm,
  onCancel,
  unUploadedDocList,
}) => {
  const [reminder, setrRminder] = useState({} as ReminderPrintModel)
  const [errors, setErrors] = useState({} as ReminderPrintErrorModel)

  const findErrors = () => {
    const newErrors = {} as ReminderPrintErrorModel

    if (!reminder.reminder) {
      newErrors.reminderError = 'Please select a reminder !'
    }
    if (!reminder.supportingDocumentIds) {
      newErrors.supportingDocumentIdError =
        'Please select a supporting document !'
    }
    if (!reminder.language) {
      newErrors.languageError = 'Please select a language !'
    }

    return newErrors
  }
  const onClickPrint = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      setErrors(foundErrros)
    } else {
      console.log(reminder)
      onConfirm(reminder)
    }
  }
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }
  const unUploadedDocListOptions = () => {
    const unUploadedDocs: CommonOptionModel[] = []
    for (let i = 0; i < unUploadedDocList.length; i++) {
      const unUploadedDocObject = {} as CommonOptionModel
      unUploadedDocObject.value = unUploadedDocList[i].id.toString()
      unUploadedDocObject.label = unUploadedDocList[i].documentNameEnglish
      unUploadedDocs.push(unUploadedDocObject)
    }
    return unUploadedDocs
  }
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          {'Print Reminder'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="supportingDocId">
            <Form.Label column sm="5">
              {'Supporting Document'}
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Col sm="6">
              <Select
                isMulti
                styles={
                  errors.supportingDocumentIdError ? customStyles : undefined
                }
                onChange={(selectedOption: any) => {
                  const supportingDocumentId: number[] = []
                  for (let i = 0; i < selectedOption.length; i++) {
                    supportingDocumentId.push(selectedOption[i].value)
                  }
                  const newValue = produce(
                    reminder,
                    (draft: ReminderPrintModel) => {
                      draft.supportingDocumentIds = supportingDocumentId
                    }
                  )
                  setrRminder(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.supportingDocumentIdError = ''
                  })
                  setErrors(clearError)
                }}
                options={unUploadedDocListOptions()}
              />
              <InvalidFeedback message={errors.supportingDocumentIdError} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="reminderNo">
            <Form.Label column sm="5">
              {'Reminder No'}
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Col sm="6">
              <Form.Control
                isInvalid={!!errors.reminderError}
                type="text"
                className="form-select"
                as="select"
                onChange={(e) => {
                  const newValue = produce(
                    reminder,
                    (draft: ReminderPrintModel) => {
                      draft.reminder = parseInt(e.target.value)
                    }
                  )
                  setrRminder(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.reminderError = ''
                  })
                  setErrors(clearError)
                }}
              >
                {!reminder.reminder ? (
                  <option value="">--Select Reminder--</option>
                ) : (
                  ''
                )}
                {draftedReminderList.map(
                  (reminder: ReminderModel, index: number) => {
                    return (
                      <option key={`reminder-${index}`} value={reminder.id}>
                        {reminder.reminder}
                      </option>
                    )
                  }
                )}
              </Form.Control>
              <InvalidFeedback message={errors.reminderError} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="language">
            <Form.Label column sm="5">
              {'Language'}
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Col sm="6">
              <Select
                styles={errors.languageError ? customStyles : undefined}
                options={languages}
                onChange={(selectedOption) => {
                  const newValue = produce(
                    reminder,
                    (draft: ReminderPrintModel) => {
                      draft.language = selectedOption
                        ? selectedOption.value
                        : ''
                    }
                  )
                  setrRminder(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.languageError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.languageError} />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="mb-3" controlId="exportType">
            <Form.Label column sm="5">
              {'Export Type'}
            </Form.Label>
            <Col sm="6">
              <Select
                styles={errors.typeError ? customStyles : undefined}
                options={types}
                onChange={(selectedOption) => {
                  const newValue = produce(
                    reminder,
                    (draft: ReminderPrintModel) => {
                      draft.type = selectedOption ? selectedOption.value : ''
                    }
                  )
                  setrRminder(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.typeError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.typeError} />
            </Col>
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="save-button" onClick={onClickPrint}>
          Print
        </button>
        <button className="save-button" onClick={onCancel}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReminderPrintModal
