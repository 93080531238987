import { MpAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/mp/mpAggrivedPerson'
import { MpDependentDetailsModel } from '../../../models/applicationRegistration/compensation/mp/mpDependentDetails'
import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class MpDataService {
  async apiFetchMpList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/mp/view/list`,
      filterData
    )
  }

  async apiFetchMpListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/mp/view/count`,
      filterData
    )
  }

  async apiFetchMpReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/mp/file-return/view/list`,
      filterData
    )
  }

  async apiFetchMpReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/mp/file-return/view/count`,
      filterData
    )
  }

  async apiFetchMpFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${id}`
    )
  }

  async apiMpProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/proceed`,
      file
    )
  }

  async apiMpModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/file-return/modify/${file.id}`,
      file
    )
  }

  async apiMpReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/${id}/reject`,
      { remark: remark }
    )
  }

  async apiMpReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/${id}/return`,
      { remark: remark }
    )
  }

  async apiMpClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiMpReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiMpMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/${id}/merge`,
      idList
    )
  }

  async apiMpAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/applicant`,
      file
    )
  }

  async apiMpAddAggrivedPersonDetailsDetails(
    file: MpAggrivedPersonDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/${file.applicationFileId}/aggrieved_person`,
      file
    )
  }

  async apiMpGetAggrivedPersonDetailsDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${id}/aggrieved_person`
    )
  }

  async apiMpGetDependentDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${id}/dependents`
    )
  }

  async apiMpAddDependantDetails(file: MpDependentDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/${file.applicationFileId}/dependent`,
      file
    )
  }

  async apiMpDeleteDependantDetails(file: MpDependentDetailsModel) {
    return apiService.apiDelete(
      `/application-registration/compensation/mp/add/${file.applicationFileId}/dependent`,
      file.id
    )
  }

  async apiMpAddDependantNextOfKinDetails(file: NextOfKinModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/${file.applicationFileId}/dependent/${file.dependentId}/next_of_kin`,
      file
    )
  }

  async apiMpGetDependantNextOfKinDetails(fileId: number, dependentId: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiMpGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${id}/document`
    )
  }

  async apiMpAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/mp/add/${id}/document`,
      formData
    )
  }

  async apiMpRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/mp/add/${id}/register`,
      {}
    )
  }
}

export default new MpDataService()
