import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory, useRouteMatch } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import SchemeService from '../../../services/systemConfiguration/schemes/schemeService'
// import ViewGeneralDocument from './ViewGeneralDocument'

const GeneralDocumentsComponent = () => {
  const [schemeList, setSchemeList] = useState([])
  const pending = false
  const { path } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    async function getSchemesData() {
      const schemes = await SchemeService.apiGetAllSchemesList()
      setSchemeList(schemes.data)
    }
    getSchemesData()
  }, [location.pathname])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Program Type',
        accessor: 'programType',
      },
      {
        Header: 'Scheme Code',
        accessor: 'code',
      },
      {
        Header: 'Scheme Description',
        accessor: 'description',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (code: string) => {
    history.push(`${path}${code}`)
  }
  return (
    <>
      {pending ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            {schemeList ? (
              <ReactTableWithPaginationComponent
                columns={columns}
                data={schemeList}
                hover={true}
                onClickRow={(row: any) => {
                  handleView(row.code)
                }}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
        // <Switch>
        //   <Route path={`${path}/`} exact>

        //   </Route>
        //   <Route
        //     path={`${path}/general-document/:code`}
        //     component={ViewGeneralDocument}
        //   />
        // </Switch>
      )}
    </>
  )
}

export default GeneralDocumentsComponent
