/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import './AdministrativeArea.scss'
import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DsDivision from './DsDivision'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DSDivisionModal } from '../../../../models/administrativeArea/dsDivisionModal'

export interface DistrictProps {
  name?: string
  dsList: DSDivisionModal[]
}

const District: FC<DistrictProps> = ({ name, dsList }) => {
  const [isSelected, setIsSelected] = useState(false)

  const renderDsList = () => {
    return dsList.map((ds: DSDivisionModal, index: number) => {
      return (
        <ul className="custom-ul" key={`ds-divission-${ds.dsDivisionId}`}>
          <DsDivision
            key={`ds-${ds.dsDivisionId}-${index}`}
            name={ds.dsDivisionName}
            id={ds.dsDivisionId}
          />
        </ul>
      )
    })
  }
  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {name}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">{renderDsList()}</Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default District
