/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ActionListModal } from '../../../models/package/actionListModal'
import { ModuleModal } from '../../../models/package/moduleModal'
import { SubModuleModal } from '../../../models/package/subModuleModal'
import Action from './Action'
import SubModule from './SubModule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

export interface ModuleProps {
  moduleModal: ModuleModal
  mode: boolean
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
}

const Module: FC<ModuleProps> = ({
  moduleModal,
  mode,
  packageIndex,
  subPackageIndex,
  moduleIndex,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const renderSubModules = () => {
    return moduleModal.subModuleList.map(
      (subModule: SubModuleModal, subModuleIndex: number) => {
        return (
          <ul className="custom-ul" key={`module-${subModule.id}`}>
            <SubModule
              subModuleModal={subModule}
              mode={mode}
              subModuleIndex={subModuleIndex}
              packageIndex={packageIndex}
              subPackageIndex={subPackageIndex}
              moduleIndex={moduleIndex}
            />
          </ul>
        )
      }
    )
  }
  const renderActionList = () => {
    return moduleModal.actionList.map(
      (action: ActionListModal | any, index: number) => {
        return (
          <Action
            key={`module-action-${moduleModal.id}-${index}`}
            actionListModal={action}
            index={index}
            mode={mode}
            relevantModue={`module`}
            packageIndex={packageIndex}
            subPackageIndex={subPackageIndex}
            moduleIndex={moduleIndex}
          />
        )
      }
    )
  }
  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {moduleModal.description}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">
              {renderActionList()}
              {renderSubModules()}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Module
