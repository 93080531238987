import { PdDependentDetailsModel } from '../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { NextOfKinModel } from '../../models/applicationRegistration/compensation/nextOfKinModel'
import { ReminderModel } from '../../models/applicationRegistration/compensation/reminderModel'
import apiService from '../api/apiManager'

class CommonDataService {
  async apiMergeInstallment(
    installmentList: number[],
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/compensation/${scheme}/add/${fileId}/merge`,
      installmentList
    )
  }

  async apiUnMergeInstallment(
    mergeNumber: number,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiDeleteCommon(
      `/application-registration/compensation/${scheme}/add/${fileId}/merge/${mergeNumber}`
    )
  }

  async apiGetDependentSuggestAmount(id: number, scheme: string) {
    return apiService.apiGet(
      `/meta-data/compensation-percentage/${id}/COMPENSATION/${scheme}`
    )
  }

  async apiGetNextOfKin(
    packageName: string,
    fileId: number,
    dependentId: number,
    scheme: string
  ) {
    return apiService.apiGet(
      `/${packageName}/compensation/${scheme}/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiUpdateNextOfKin(
    fileId: number,
    dependentId: number,
    scheme: string,
    nextOfKin: NextOfKinModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/${scheme}/add/${fileId}/dependent/${dependentId}/next_of_kin`,
      nextOfKin
    )
  }

  async apiAddDependentCompensationPercentage(
    dependentList: PdDependentDetailsModel[],
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/${scheme}/add/${fileId}/dependents/amount-percentage`,
      {
        dependents: dependentList,
      }
    )
  }

  async apiCompleteLevelFour(fileId: number, scheme: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/${scheme}/add/${fileId}/complete-level-4`,
      {}
    )
  }

  async apiUploadSupportingDocument(
    fileId: number,
    programmeType: string,
    scheme: string,
    data: FormData
  ) {
    return apiService.apiPutFormData(
      `/application-registration/${programmeType}/${scheme}/add/${fileId}/document`,
      data
    )
  }

  async apiAddReminder(
    fileId: number,
    programmeType: string,
    scheme: string,
    data: ReminderModel[]
  ) {
    return apiService.apiPutCommon(
      `/application-registration/${programmeType}/${scheme}/add/${fileId}/reminder`,
      data
    )
  }

  async apiCloseApplication(
    fileId: number,
    programmeType: string,
    scheme: string,
    description: string,
    remark: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/${programmeType}/${scheme}/${fileId}/close`,
      {
        description: description,
        remark: remark,
      }
    )
  }

  async apiReOpenApplication(
    fileId: number,
    programmeType: string,
    scheme: string,
    description: string,
    remark: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/${programmeType}/${scheme}/${fileId}/reopen`,
      {
        description: description,
        remark: remark,
        allocateTo: allocateTo,
      }
    )
  }

  async apiDeleteSupportDocument(
    fileId: number,
    programmeType: string,
    scheme: string,
    documentId: number
  ) {
    return apiService.apiDelete(
      `/application-registration/${programmeType}/${scheme}/add/${fileId}/document`,
      documentId
    )
  }

  async apiUploadInstallmentSupportDocument(
    fileId: number,
    scheme: string,
    merge_installment_number: number,
    formData: FormData
  ) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/${scheme}/add/${fileId}/installment/${merge_installment_number}/document`,
      formData
    )
  }

  async apiGetSimilarRecords(id: number) {
    return apiService.apiGet(
      `/meta-data/application-file/${id}/similar-record/list`
    )
  }

  async apiGetAggrivedDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/aggrieved_person`
    )
  }
  async apiGetDependentDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/dependents`
    )
  }
  async apiGetSupportingDocument(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/document`
    )
  }
  async apiGetAffectedPropertyData(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/affected_property`
    )
  }
  async apiGetBankData(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/bank`
    )
  }
  async apiGetBoardOfTrustee(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/board_of_trustee_and_bank_list`
    )
  }
  async apiGetOthersDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/other-details`
    )
  }
  async apiGetFamilyDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/family-details`
    )
  }
  async apiGetAffectedHouseDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/affected-house-details`
    )
  }
  async apiGetDependentAndOtherDetails(
    packageName: string,
    programeType: string,
    scheme: string,
    id: number
  ) {
    return apiService.apiGet(
      `/${packageName}/${programeType}/${scheme}/view/${id}/dependent-and-other-details`
    )
  }
}

export default new CommonDataService()
