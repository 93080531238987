/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationDetailsModel } from '../../models/common/notificationModel'
import myProfileService from '../../services/myProfile/myProfileService'
import { fileRedirectUtil } from '../../utils/utilFunctions'
import './Modals.scss'

export interface NotificationModalProps {
  notificationList: NotificationDetailsModel[]
  onCancel?: any
  onNotificationClick?: any
}

const NotificationModal: FC<NotificationModalProps> = ({
  notificationList,
  onCancel,
  onNotificationClick,
}) => {
  const history = useHistory()
  const viewAllNotification = () => {
    onCancel()
    history.push(`/all-notification`)
  }

  const handleNotificationClick = async (
    notification: NotificationDetailsModel
  ) => {
    if (!notification.readMessage) {
      await myProfileService.apiReadNotification(notification.id)
    }

    const basePath = fileRedirectUtil(
      notification.applicationStatus,
      notification.programType,
      notification.scheme,
      notification.applicationFileId
    )
    onNotificationClick(basePath)
  }

  const renderNotifications = () => {
    return notificationList.map(
      (notification: NotificationDetailsModel, index: number) => {
        return (
          <div
            key={index}
            className={`notification-card  ${
              notification.readMessage ? 'read' : ''
            }`}
          >
            <div
              className="notification-icon"
              role={'button'}
              onClick={() => {
                handleNotificationClick(notification)
              }}
            >
              <img src={`/src/assets/images/notification-icon.svg`} alt="" />
            </div>
            <div className={`notification-details`}>
              <div className="notification-message">
                <span>{notification.messageData}</span>
              </div>
              <div className="notification-date">
                <span>
                  {new Date(notification.sendDateTime * 1000).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        )
      }
    )
  }

  return (
    <div className="notification-drawer">
      <div className="notification-drawer-header">
        <span>{`NOTIFICATION (${notificationList.length})`}</span>
        <button className="notification-close-button" onClick={onCancel}>
          X
        </button>
      </div>
      <div className="notification-body">
        {notificationList.length > 0 ? (
          <div className="notification-area">{renderNotifications()}</div>
        ) : (
          <div className="notification-empty">
            <p>No new notifications</p>
          </div>
        )}
      </div>
      <div className="notification-drawer-header">
        <button
          className="notification-close-button"
          onClick={viewAllNotification}
        >
          View All
        </button>
      </div>
    </div>
  )
}

export default NotificationModal
