import React, { FC, createContext, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import authServices from '../services/authService'
import { breadCrumbResetRequest } from '../store/breadCrumb/actions'
import { loginUserSuccess } from '../store/user/actions'
import { handleNotification } from '../utils/utilFunctions'

interface authResponseObject {
  isFirstTime: boolean
  isAuthenticate: boolean
}

interface IAuthContext {
  isLoading: boolean
  isAuthenticate: boolean
  error: string
  login: (email: string, password: string) => authResponseObject
  checkAuth: () => void
  logout: () => void
}

const AuthContext = createContext<IAuthContext>({
  isLoading: true,
  isAuthenticate: false,
  error: '',
  login: () => login(),
  checkAuth: () => checkAuthentication(),
  logout: () => logout(),
})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider: FC = ({ children }) => {
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  const setLogin = async (username: string, password: string) => {
    const response = await authServices.apiLoginPost({
      username: username,
      password: password,
    })
    const authResponse: authResponseObject = {
      isAuthenticate: false,
      isFirstTime: false,
    }
    if (
      response &&
      response.status === 200 &&
      response.token &&
      response.refreshToken
    ) {
      setIsAuthenticate(true)
      console.log(response)
      const userModel = {
        user: response.data,
      }
      dispatch(loginUserSuccess(userModel))
      dispatch(breadCrumbResetRequest())
      localStorage.setItem('user', JSON.stringify(response.data))
      localStorage.setItem('token', response.token)
      localStorage.setItem('refreshToken', response.refreshToken)
      authResponse.isAuthenticate = true
      authResponse.isFirstTime = userModel.user.firstTime
    } else {
      setIsAuthenticate(false)
      setError(response.errorMessage !== undefined ? response.errorMessage : '')
    }
    // await new Promise((f) => setTimeout(f, 5000))
    handleNotification(response, 'Login Success')
    return authResponse
  }

  const checkAuthentication = async () => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    if (token) {
      const response = await authServices.apiCheckAuth()
      if (response.status === 200 || response.status === 204) {
        setIsAuthenticate(true)
        const userModel = {
          user: response.data,
        }
        dispatch(loginUserSuccess(userModel))
      } else {
        setIsAuthenticate(false)
      }
    } else {
      setIsAuthenticate(false)
    }
    setIsLoading(false)
  }

  const logout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('refreshToken')
    setIsAuthenticate(false)
    history.push('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading: isLoading,
        isAuthenticate: isAuthenticate,
        error: error,
        login: (email: string, password: string) => setLogin(email, password),
        checkAuth: () => checkAuthentication(),
        logout: () => logout(),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function checkAuthentication(): void {
  throw new Error('Function not implemented.')
}

function login(): authResponseObject {
  throw new Error('Function not implemented.')
}

function logout(): void {
  throw new Error('Function not implemented.')
}
