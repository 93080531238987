import produce from 'immer'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DsOption } from '../../../../models/selectOptionModels/dsOption'
import metaDataService from '../../../../services/metaDataService'
import { getDsOptions } from '../../../../store/administrativeArea/selectors'
import {
  checkGenderDisable,
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  FetchRequesthandleNotification,
  getAgeByMiliSeconds,
  getApplicantTypeDisabled,
  getGenderByNic,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
  setApplicantselectOptions,
  validateForm,
} from '../../../../utils/utilFunctions'
import { AllocateToTypeOption } from '../../../../models/selectOptionModels/allocateOption'
import { OccupationDetailModel } from '../../../../models/applicationRegistration/compensation/occupationDetailModel'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import { FileRegistrationErrorModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import { getUser } from '../../../../store/user/selectors'
import IdentificationTypesComponent from '../../../../components/common/identificationComponent/IdentificationTypesComponent'
import AcknowledgmentPrintModal from '../../../../components/modals/AcknowledgmentPrintModal'
import { ClosePrintModel } from '../../../../models/utils/closePrintModel'
import { saveAs } from 'file-saver'

export interface SectionTwoProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
  onNextSection: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const SectionTwo: FC<SectionTwoProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
  onNextSection,
}) => {
  const [errors, setErrors] = useState({} as FileRegistrationErrorModel)
  const [organizationList, setOrganizationList] = useState([])
  const sectionTwoForm = useRef(null)
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [titleList, setTitleList] = useState([])
  const [applicantTypeList, setApplicantTypeList] = useState(
    [] as AllocateToTypeOption[]
  )
  const [relationshipList, setRelationShipList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAcknowledgmentPrintModal, setShowAcknowledgmentPrintModal] =
    useState(false)
  const [sectionTwoData, setSectionTwoData] = useState(fileRegistrationObject)
  const dsList = useSelector(getDsOptions)
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      const titleData = await metaDataService.apiTitleListFetch()
      const applicantData = await metaDataService.getApplicantTypeList()
      const relationshipData = await metaDataService.getRelationshipList()
      const organizationData = await metaDataService.apiOrganizationFetch()
      if (organizationData.status === 200) {
        setOrganizationList(organizationData.data)
      }
      if (titleData.status == 200) {
        setTitleList(titleData.data)
      }
      if (relationshipData.status == 200) {
        setRelationShipList(relationshipData.data)
      }
      if (applicantData.status == 200) {
        setApplicantTypeList(setApplicantselectOptions(applicantData.data))
      }

      const newFormValue = produce(
        sectionTwoData,
        (draft: FileRegistrationModel) => {
          draft.age = fileRegistrationObject.age
            ? fileRegistrationObject.age
            : getAgeByMiliSeconds(fileRegistrationObject.dateOfBirth)
          if (draft.gender == '' || draft.gender == 'N.A') {
            draft.gender = getGenderByNic(draft.newNicNumber)
          }

          if (draft.occupationDetail == null) {
            draft.occupationDetail = {} as OccupationDetailModel
            draft.occupationDetail.applicationFileId = fileRegistrationObject.id
          }
          if (draft.scheme === 'PD' || draft.scheme === 'GSD') {
            draft.applicantType = 'Applicant'
          } else if (draft.scheme === 'GPC') {
            draft.applicantType = 'Primary Applicant'
          } else {
            draft.applicantType = fileRegistrationObject.applicantType
          }
        }
      )

      setSectionTwoData(newFormValue)
      setLoading(false)
      console.log(newFormValue)
    }
    getMetaData()
  }, [loading])

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }
  const findErrors = () => {
    const newErrors = {} as FileRegistrationErrorModel
    if (
      validateForm({
        value: sectionTwoData.applicantType,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.applicantTypeError = validateForm({
        value: sectionTwoData.applicantType,
        section: 'Applicant type',
        type: 'text',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        value: sectionTwoData.applicantTitle,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.applicantTitleError = validateForm({
        value: sectionTwoData.applicantTitle,
        section: 'Applicant title',
        type: 'text',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          value: sectionTwoData.permanentAddress,
          maxLength: 255,
          type: 'text',
        } as ValidationObjectModel,
        fileRegistrationObject.scheme === 'RLP' ? false : true
      )
    ) {
      newErrors.permanentAddressError = validateForm(
        {
          value: sectionTwoData.permanentAddress,
          section: 'Permanent address',
          maxLength: 255,
          type: 'text',
        } as ValidationObjectModel,
        fileRegistrationObject.scheme === 'RLP' ? false : true
      )
    }
    if (
      validateForm({
        value: sectionTwoData.age,
        maxLength: 3,
        minValue: 1,
        type: 'number',
      } as ValidationObjectModel)
    ) {
      newErrors.ageError = validateForm({
        value: sectionTwoData.age,
        maxLength: 3,
        minValue: 1,
        section: 'Age',
        type: 'number',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        value: sectionTwoData.gender,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.genderError = validateForm({
        value: sectionTwoData.gender,
        section: 'Gender',
        type: 'text',
      } as ValidationObjectModel)
    }
    // if (
    //   !(
    //     sectionTwoData.applicantType === 'Primary Applicant' ||
    //     fileRegistrationObject.scheme === 'RLP'
    //   )
    // ) {
    //   if (
    //     validateForm({
    //       value: sectionTwoData.relationship,
    //     } as ValidationObjectModel)
    //   ) {
    //     newErrors.relationshipError = validateForm({
    //       value: sectionTwoData.relationship,
    //       section: 'Applicant’s relationship to the aggrieved person',
    //     } as ValidationObjectModel)
    //   }
    // }

    if (fileRegistrationObject.scheme === 'GSC') {
      if (
        validateForm({
          value: sectionTwoData.occupationDetail.designation,
          maxLength: 40,
          type: 'text',
        } as ValidationObjectModel)
      ) {
        newErrors.designationError = validateForm({
          value: sectionTwoData.occupationDetail.designation,
          section: 'Designation',
          maxLength: 40,
          type: 'text',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          value: sectionTwoData.occupationDetail.organization,
          maxLength: 60,
          type: 'text',
        } as ValidationObjectModel)
      ) {
        newErrors.organizationError = validateForm({
          value: sectionTwoData.occupationDetail.organization,
          section: 'Name of the Organization',
          maxLength: 60,
          type: 'text',
        } as ValidationObjectModel)
      }
      if (sectionTwoData.occupationDetail?.monthlySalary) {
        if (
          validateForm({
            value: sectionTwoData.occupationDetail.monthlySalary,
            minValue: 1,
            maxLength: 6,
            type: 'number',
          } as ValidationObjectModel)
        ) {
          newErrors.monthlySalaryError = validateForm({
            value: sectionTwoData.occupationDetail.monthlySalary,
            section: 'Salary/ Wages/ Earnings',
            minValue: 1,
            maxLength: 6,
            type: 'number',
          } as ValidationObjectModel)
        }
      }
      if (sectionTwoData.occupationDetail?.monthlySalary) {
        if (
          validateForm({
            value: sectionTwoData.occupationDetail.staffRegistrationNumber,
            maxLength: 15,
            type: 'text',
          } as ValidationObjectModel)
        ) {
          newErrors.staffRegistrationNumberError = validateForm({
            value: sectionTwoData.occupationDetail.staffRegistrationNumber,
            section: 'EPF or Staff number',
            maxLength: 15,
            type: 'text',
          } as ValidationObjectModel)
        }
      }

      // if (isNaN(sectionTwoData.occupationDetail.balanceLoanAmount)) {
      //   if (
      //     validateForm({
      //       value: sectionTwoData.occupationDetail.balanceLoanAmount,
      //     } as ValidationObjectModel)
      //   ) {
      //     newErrors.balanceLoanAmountError = validateForm({
      //       value: sectionTwoData.occupationDetail.balanceLoanAmount,
      //       regex: /^\d*\.?\d*$/,
      //       customMessage: 'Invalid input !',
      //     } as ValidationObjectModel)
      //   }
      // }
    }

    if (fileRegistrationObject.scheme === 'RLP') {
      if (
        validateForm({
          value: sectionTwoData.applicationCapacity,
          maxLength: 100,
          type: 'text',
        } as ValidationObjectModel)
      ) {
        newErrors.applicationCapacityError = validateForm({
          value: sectionTwoData.applicationCapacity,
          maxLength: 100,
          section: 'In what capacity the application is made',
          type: 'text',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          value: sectionTwoData.appointmentDate,
          type: 'number',
        } as ValidationObjectModel)
      ) {
        newErrors.appointmentDateError = validateForm({
          value: sectionTwoData.appointmentDate,
          section:
            'Date of Appointment to the post of Chief Incumbent/ Priest/ Caretaker',
          type: 'number',
        } as ValidationObjectModel)
      }
    }
    if (
      !(
        fileRegistrationObject.scheme === 'RLP' ||
        fileRegistrationObject.scheme === 'GPC' ||
        fileRegistrationObject.scheme === 'GSC'
      ) &&
      validateForm(
        {
          value: sectionTwoData.compensationDetails,
          maxLength: 150,
          type: 'text',
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.compensationDetailsError = validateForm(
        {
          value: sectionTwoData.compensationDetails,
          maxLength: 150,
          type: 'text',
          section: 'Details of the applicant’s right to claim for compensation',
        } as ValidationObjectModel,
        false
      )
    }
    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    console.log(sectionTwoData)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    console.log(sectionTwoData)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNextSection = () => {
    onNextSection()
  }

  const handleConfirm = () => {
    const sectionTwoDataSave = produce(sectionTwoData, (draft) => {
      draft.birthCertificateDsDivision = null
    })
    if (showConfirm.section === 'save') {
      onSave(sectionTwoDataSave)
    } else {
      onNext(sectionTwoDataSave)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const handleAcknowledgementPrint = async (printData: ClosePrintModel) => {
    if (printData.language == 'eng') {
      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}application-registration/file-registration/application-files/view/${fileRegistrationObject.id}/print-acknowledgment/pdf`,
        {
          headers,
        }
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          FetchRequesthandleNotification(
            response,
            'Acknowledgement ready to print'
          )
          if (response.status === 200) {
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowAcknowledgmentPrintModal(false)
            const fileName = `application_file_${fileRegistrationObject.id}_acknowledgment.pdf`
            saveAs(response, fileName)
            setLoading(true)
          }
          // return response.blob()
        })
    } else {
      console.log(printData)

      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}application-registration/file-registration/application-files/view/${fileRegistrationObject.id}/print-acknowledgment/non-english/${printData.language}/docx`,
        {
          headers,
        }
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          FetchRequesthandleNotification(
            response,
            'Acknowledgement ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowAcknowledgmentPrintModal(false)
            const fileName = `application_file_${fileRegistrationObject.id}_acknowledgment.docx`
            saveAs(response, fileName)
            setLoading(true)
          }
          // return response.blob()
        })
    }
    // history.goBack()
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Applicant Details</div>
            <Form ref={sectionTwoForm} id="file-applicant-details-form">
              <Form.Group className="mb-3" controlId="formApplicantType">
                <Form.Label>Applicant Type</Form.Label>
                <Select
                  styles={errors.applicantTypeError ? customStyles : undefined}
                  isDisabled={
                    getApplicantTypeDisabled(sectionTwoData.scheme) ||
                    fileRegistrationObject.modifyIndex > 0
                  }
                  value={applicantTypeList.filter((applicant: any) => {
                    return applicant.value == sectionTwoData.applicantType
                  })}
                  onChange={(selectedOption) => {
                    const newSectionTwoData = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.applicantType = selectedOption
                          ? selectedOption.value
                          : ''
                      }
                    )
                    setSectionTwoData(newSectionTwoData)
                    // const clearError = produce(errors, (draft) => {
                    //   draft.applicantTypeError = ''
                    // })
                    setErrors({} as FileRegistrationErrorModel)
                  }}
                  options={applicantTypeList}
                />
                <span className="feedback-error-span-custom">
                  {errors.applicantTypeError}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>
                  Applicant Title{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.applicantTitleError}
                  required
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  className="form-select"
                  as="select"
                  value={sectionTwoData.applicantTitle}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.applicantTitleError = validateForm({
                        value: sectionTwoData.applicantTitle,
                        section: 'Applicant title',
                        type: 'text',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.applicantTitle = e.target.value
                      }
                    )
                    setSectionTwoData(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.applicantTitleError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {1 === 1 ? <option value="">--Select Title--</option> : ''}
                  {titleList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.applicantTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantName">
                <Form.Label>Applicant Name</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Enter Applicant Name"
                  value={sectionTwoData.applicantName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantAddress">
                <Form.Label>
                  Permenant Address{' '}
                  {!(fileRegistrationObject.scheme === 'RLP') && (
                    <span className="required-field-astrix">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.permanentAddressError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  type="text"
                  placeholder="Enter Permenant Address"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.permanentAddressError = validateForm(
                        {
                          value: sectionTwoData.permanentAddress,
                          section: 'Permanent address',
                          maxLength: 255,
                          type: 'text',
                        } as ValidationObjectModel,
                        fileRegistrationObject.scheme === 'RLP' ? false : true
                      )
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newSectionTwoData = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.permanentAddress = e.target.value
                      }
                    )
                    setSectionTwoData(newSectionTwoData)
                    const clearError = produce(errors, (draft) => {
                      draft.permanentAddressError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={sectionTwoData.permanentAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.permanentAddressError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSelection">
                <Form.Label>Identification Type</Form.Label>
                <IdentificationTypesComponent
                  isDisabled={true}
                  identificationType={sectionTwoData.identificationType}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantDob">
                <Form.Label>Date of birth</Form.Label>
                <DatePicker
                  disabled={true}
                  readOnly={true}
                  className="form-control"
                  selected={
                    sectionTwoData.dateOfBirth != null
                      ? new Date(sectionTwoData.dateOfBirth * 1000)
                      : null
                  }
                />
              </Form.Group>
              {sectionTwoData.identificationType != 'BIRTH_CERTIFICATE' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formNICNumber">
                      <Form.Label>
                        <Row>
                          <Col>NIC Number</Col>
                          <Col>
                            <Form.Check
                              disabled={true}
                              inline
                              name="formNICNumberType"
                              label="New"
                              type="radio"
                              id={`formNICNumberType-N`}
                              checked={sectionTwoData.newNic}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              disabled={true}
                              inline
                              name="formNICNumberType"
                              label="Old"
                              type="radio"
                              id={`formNICNumberType-O`}
                              checked={!sectionTwoData.newNic}
                            />
                          </Col>
                        </Row>
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter NIC Number"
                        value={
                          sectionTwoData.newNic
                            ? sectionTwoData.newNicNumber
                            : sectionTwoData.oldNicNumber
                        }
                      />
                    </Form.Group>
                  </Row>
                  {sectionTwoData.identificationType != 'NIC' && (
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formPSDNumber">
                        <Form.Label>
                          {renderIdentificationTypeName(
                            sectionTwoData.identificationType
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled={true}
                          placeholder={`Enter ${renderIdentificationTypeName(
                            sectionTwoData.identificationType
                          )}`}
                          value={renderIdentificationTypeValue(
                            sectionTwoData.identificationType,
                            sectionTwoData
                          )}
                        />
                      </Form.Group>
                    </Row>
                  )}
                </>
              )}
              <Col sm={12} className="border-label mt-4 mb-3">
                <div className="border-label-span">
                  Birth Certificate Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>Birth Certificate Number</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Enter Birth Certificate Number"
                      value={sectionTwoData.birthCertificateNumber}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCDsDivision">
                    <Form.Label>Ds Division of Birth Certificate</Form.Label>
                    <Select
                      placeholder="Enter Ds Division of Birth Certificate"
                      styles={
                        errors.birthCertificateDsDivisionIdError
                          ? customStyles
                          : undefined
                      }
                      value={
                        sectionTwoData.birthCertificateDsDivisionId === null
                          ? null
                          : dsList.filter((ds: DsOption) => {
                              return (
                                ds.value ===
                                sectionTwoData.birthCertificateDsDivisionId
                              )
                            })[0]
                      }
                      isDisabled={true}
                      options={dsList}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.birthCertificateDsDivisionIdError}
                    </span>
                  </Form.Group>
                </Row>
              </Col>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formNicIssuedDate">
                  <Form.Label>NIC Issued Date</Form.Label>
                  <DatePicker
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    required={true}
                    maxDate={new Date()}
                    className={
                      errors.nicIssuedDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="NIC Issued Date"
                    selected={
                      sectionTwoData.nicIssuedDate != null
                        ? new Date(sectionTwoData.nicIssuedDate * 1000)
                        : null
                    }
                    onChange={(date: Date) => {
                      const newFormValue = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.nicIssuedDate = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setSectionTwoData(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.nicIssuedDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.nicIssuedDateError}
                  </span>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAge">
                  <Form.Label>
                    Age <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    isInvalid={!!errors.ageError}
                    type="number"
                    min={0}
                    placeholder="Enter Age"
                    defaultValue={sectionTwoData.age}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.ageError = validateForm({
                          value: sectionTwoData.age,
                          maxLength: 3,
                          section: 'Age',
                          minValue: 1,
                          type: 'number',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const ageValue =
                        e.target.value == '' ? '0' : e.target.value
                      const newSectionTwoData = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.age = parseInt(ageValue)
                        }
                      )
                      setSectionTwoData(newSectionTwoData)
                      const clearError = produce(errors, (draft) => {
                        draft.ageError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ageError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGender">
                  <Form.Label>
                    Gender <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={checkGenderDisable(
                      fileRegistrationObject.modifyIndex,
                      fileRegistrationObject.identificationType
                    )}
                    isInvalid={!!errors.genderError}
                    className="form-select"
                    as="select"
                    placeholder="Enter Gender"
                    value={sectionTwoData.gender}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.genderError = validateForm({
                          value: sectionTwoData.gender,
                          section: 'Gender',
                          type: 'text',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newSectionTwoData = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.gender = e.target.value
                        }
                      )
                      setSectionTwoData(newSectionTwoData)
                      const clearError = produce(errors, (draft) => {
                        draft.genderError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    <option value="N/A">--Select Gender--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.genderError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {!(
                sectionTwoData.applicantType === 'Primary Applicant' ||
                fileRegistrationObject.scheme === 'RLP' ||
                fileRegistrationObject.scheme === 'GPC' ||
                fileRegistrationObject.scheme === 'GSC'
              ) && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRelationshipAgPerson">
                    <Form.Label>
                      Relationship to the Applicant{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.relationshipError}
                      className="form-select"
                      as="select"
                      disabled={
                        sectionTwoData.applicantType === 'Primary Applicant' ||
                        fileRegistrationObject.modifyIndex > 0
                      }
                      value={sectionTwoData.relationship}
                      onChange={(e) => {
                        const newFormValue = produce(
                          sectionTwoData,
                          (draft: FileRegistrationModel) => {
                            draft.relationship = e.target.value
                          }
                        )
                        setSectionTwoData(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.relationshipError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {1 === 1 ? (
                        <option value="">--Select Relationship--</option>
                      ) : (
                        ''
                      )}
                      {relationshipList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.relationshipError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}

              {fileRegistrationObject.scheme === 'GSC' && (
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">
                    Details of the Occupations
                  </div>
                  <Row>
                    <Form.Group as={Col} controlId="formDesignation">
                      <Form.Label>
                        Designation
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.designationError}
                        type="text"
                        placeholder="Enter Designation"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.designationError = validateForm({
                              value:
                                sectionTwoData.occupationDetail.designation,
                              section: 'Designation',
                              maxLength: 40,
                              type: 'text',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.occupationDetail.designation =
                                e.target.value
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.designationError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={sectionTwoData.occupationDetail?.designation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.designationError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formOrganizationName">
                      <Form.Label>
                        Name of the Organization
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.organizationError}
                        className="form-select"
                        as="select"
                        placeholder="Enter Name of the Organization"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.organizationError = validateForm({
                              value:
                                sectionTwoData.occupationDetail.organization,
                              section: 'Name of the Organization',
                              maxLength: 60,
                              type: 'text',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.occupationDetail.organization =
                                e.target.value
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.organizationError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={sectionTwoData.occupationDetail?.organization}
                      >
                        <option value="">--Select Organization--</option>
                        {organizationList.map(
                          (
                            org: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`country-${index}`} value={org.name}>
                                {org.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.organizationError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mt-2">
                    <Form.Group as={Col} controlId="formEarnings">
                      <Form.Label>Salary/ Wages/ Earnings</Form.Label>
                      <PriceInputComponent
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.monthlySalaryError}
                        placeholder="Enter Salary/ Wages/ Earnings"
                        onChange={(price: string) => {
                          if (price) {
                            const newErrors = produce(errors, (draft) => {
                              draft.monthlySalaryError = validateForm({
                                value: parseFloat(price),
                                section: 'Salary/ Wages/ Earnings',
                                maxLength: 6,
                                type: 'number',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.occupationDetail.monthlySalary =
                                parseFloat(price)
                            }
                          )
                          setSectionTwoData(newFormValue)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newFormValue = produce(
                              sectionTwoData,
                              (draft: FileRegistrationModel) => {
                                draft.occupationDetail.monthlySalary = 0
                              }
                            )
                            setSectionTwoData(newFormValue)
                          } else {
                            const clearError = produce(errors, (draft) => {
                              draft.monthlySalaryError = ''
                            })
                            setErrors(clearError)
                          }
                        }}
                        value={sectionTwoData.occupationDetail?.monthlySalary}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.monthlySalaryError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formEPFStaffNumber">
                      <Form.Label>EPF or Staff number</Form.Label>
                      <Form.Control
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.staffRegistrationNumberError}
                        type="text"
                        placeholder="Enter EPF or Staff number"
                        onBlur={(e: any) => {
                          if (e.target.value) {
                            const newErrors = produce(errors, (draft) => {
                              draft.staffRegistrationNumberError = validateForm(
                                {
                                  value:
                                    sectionTwoData.occupationDetail
                                      .staffRegistrationNumber,
                                  section: 'EPF or Staff number',
                                  maxLength: 15,
                                  type: 'text',
                                } as ValidationObjectModel
                              )
                            })
                            setErrors(newErrors)
                          }
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.occupationDetail.staffRegistrationNumber =
                                e.target.value
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.staffRegistrationNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={
                          sectionTwoData.occupationDetail
                            ?.staffRegistrationNumber
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.staffRegistrationNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBalanceLoanAmount">
                      <Form.Label>Balance Loan amount (Rs.)</Form.Label>
                      <PriceInputComponent
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.balanceLoanAmountError}
                        placeholder="Enter Balance Loan amount (Rs.)"
                        onChange={(price: string) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.occupationDetail.balanceLoanAmount =
                                parseFloat(price)
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.balanceLoanAmountError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={
                          sectionTwoData.occupationDetail?.balanceLoanAmount
                        }
                      />
                      <div className="feedback-error-span-custom">
                        {errors.balanceLoanAmountError}
                      </div>
                    </Form.Group>
                  </Row>
                </Col>
              )}
              {fileRegistrationObject.scheme === 'RLP' && (
                <>
                  <Row>
                    <Form.Group as={Col} controlId="formCapacity">
                      <Form.Label>
                        In which capacity the Application is made{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        isInvalid={!!errors.applicationCapacityError}
                        type="text"
                        placeholder="Enter In which capacity the Application is made"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.applicationCapacityError = validateForm({
                              value: sectionTwoData.applicationCapacity,
                              section:
                                'In which capacity the Application is made',
                              type: 'text',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.applicationCapacity = e.target.value
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.applicationCapacityError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={sectionTwoData.applicationCapacity}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.applicationCapacityError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="formAppointmentDate">
                      <Form.Label>
                        Date of Appointment to the post of Chief Incumbent/
                        Priest/ Caretaker{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <DatePicker
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        required={true}
                        className={
                          errors.appointmentDateError
                            ? 'date-picker-validate'
                            : 'form-control'
                        }
                        maxDate={new Date()}
                        placeholderText="Date of Appointment"
                        selected={
                          sectionTwoData.appointmentDate != null
                            ? new Date(sectionTwoData.appointmentDate * 1000)
                            : null
                        }
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.appointmentDateError = validateForm({
                              value: sectionTwoData.appointmentDate,
                              section: 'Date of Appointment',
                              type: 'number',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(date: Date) => {
                          const newFormValue = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.appointmentDate = date
                                ? date.getTime() / 1000
                                : null
                            }
                          )
                          setSectionTwoData(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.appointmentDateError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.appointmentDateError}
                      </span>
                    </Form.Group>
                  </Row>
                </>
              )}
              {!(
                fileRegistrationObject.scheme === 'RLP' ||
                fileRegistrationObject.scheme === 'GPC' ||
                fileRegistrationObject.scheme === 'GSC'
              ) && (
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    controlId="formDetailsOfTheApplicantsRightClaimForCompensation"
                  >
                    <Form.Label>
                      Details of the Applicants right to claim for Compensation
                    </Form.Label>
                    <Form.Control
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      isInvalid={!!errors.compensationDetailsError}
                      type="text"
                      placeholder="Enter Details of the Applicants right to claim for Compensation"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.compensationDetailsError = validateForm(
                            {
                              value: sectionTwoData.compensationDetails,
                              section:
                                'Details of the Applicants right to claim for Compensation',
                              maxLength: 150,
                              type: 'text',
                            } as ValidationObjectModel,
                            false
                          )
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newSectionTwoData = produce(
                          sectionTwoData,
                          (draft: FileRegistrationModel) => {
                            draft.compensationDetails = e.target.value
                          }
                        )
                        setSectionTwoData(newSectionTwoData)
                        const clearError = produce(errors, (draft) => {
                          draft.compensationDetailsError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={sectionTwoData.compensationDetails}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.compensationDetailsError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={fileRegistrationObject.validationLevel < 2}
                    className={`${
                      fileRegistrationObject.validationLevel < 2
                        ? 'print-acknowledgement-disable-button'
                        : 'print-acknowledgement-button'
                    }`}
                    onClick={() => {
                      setShowAcknowledgmentPrintModal(true)
                    }}
                  >
                    Print Acknowledgement
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      if (fileRegistrationObject.modifyIndex == 0) {
                        onClickNext()
                      } else {
                        onClickNextSection()
                      }
                    }}
                  >
                    Next
                  </button>
                  {fileRegistrationObject.modifyIndex == 0 && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  )}
                </>
              )}
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}

      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
            })
          }}
          isRemark={false}
          onConfirm={handleConfirm}
        />
      )}

      {showAcknowledgmentPrintModal && (
        <AcknowledgmentPrintModal
          onConfirm={(data: ClosePrintModel) => {
            handleAcknowledgementPrint(data)
            setShowAcknowledgmentPrintModal(false)
          }}
          onCancel={() => {
            setShowAcknowledgmentPrintModal(false)
          }}
        />
      )}
    </>
  )
}

export default SectionTwo
