import React from 'react'
import { Modal } from 'react-bootstrap'
import LoadingComponent from '../common/loading/LoadingComponent'

const LoadingModal = () => {
  return (
    <Modal
      size="lg"
      show={true}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center loading-modal-container">
            <div className="col-sm-4">
              <LoadingComponent />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal
