import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FamilyDetailsModel } from '../../models/applicationRegistration/loan/familyDetails'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { currencyMask } from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'

export interface FamilyDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  packageName?: string
}

const FamilyDetailsSection: FC<FamilyDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  packageName = 'application-registration',
}) => {
  const [loading, setLoading] = useState(true)
  const [familyDetails, setFamilyDetails] = useState({} as FamilyDetailsModel)
  useEffect(() => {
    async function getMetaData() {
      let familyDetailsData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        familyDetailsData = await enquireApplicationService.apiGetFamilyDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        familyDetailsData = await common.apiGetFamilyDetails(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }
      if (familyDetailsData.status === 200) {
        setFamilyDetails(familyDetailsData.data)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Family Details</div>
            <Form id="file-family-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Deceased Member Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyFamilyMember">
                    <Form.Label>
                      Any family member is victimized due to the war
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyFamilyMember"
                          label="Yes"
                          type="radio"
                          id={`formAnyFamilyMember-N`}
                          checked={
                            familyDetails.anyFamilyMemberDiedDueToViolenceActivity
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formAnyFamilyMember"
                          label="No"
                          type="radio"
                          id={`formAnyFamilyMember-O`}
                          checked={
                            !familyDetails.anyFamilyMemberDiedDueToViolenceActivity
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {familyDetails.anyFamilyMemberDiedDueToViolenceActivity && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formTypeOfDamage">
                        <Form.Label>Type of damage</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          placeholder="Type of damage"
                          value={familyDetails.typeOfWarDamage}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDeceasedHead">
                        <Form.Label>
                          Deceased person is the Head of the family
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Check
                              disabled
                              inline
                              name="formDeceasedHead"
                              label="Yes"
                              type="radio"
                              id={`formDeceasedHead-N`}
                              checked={
                                familyDetails.deceasedPersonIsTheHeadOfTheFamily
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              disabled
                              inline
                              name="formDeceasedHead"
                              label="No"
                              type="radio"
                              id={`formDeceasedHead-O`}
                              checked={
                                !familyDetails.deceasedPersonIsTheHeadOfTheFamily
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Row>
                    {!familyDetails.deceasedPersonIsTheHeadOfTheFamily && (
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formRelationshipToHead">
                          <Form.Label>
                            Relationship to the Head of the Family
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            placeholder="Relationship to the Head of the Family"
                            value={
                              familyDetails.relationshipToTheHeadOfTheFamily
                            }
                          />
                        </Form.Group>
                      </Row>
                    )}
                  </>
                )}
              </Col>
              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">Other Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formwidow">
                    <Form.Label>Widow due to the war</Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formwidow"
                          label="Yes"
                          type="radio"
                          id={`formwidow-N`}
                          checked={familyDetails.widowDueToTheWar}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formwidow"
                          label="No"
                          type="radio"
                          id={`formwidow-O`}
                          checked={!familyDetails.widowDueToTheWar}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formReason">
                  <Form.Label>
                    If Head of the Family is not the Applicant, specify the
                    reason
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="If Head of the Family is not the Applicant, specify the
                    reason"
                    value={familyDetails.specifyTheReason}
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRelationshipToAggrived">
                    <Form.Label>
                      Applicant’s Relationship to the Aggrieved Person
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Applicant’s Relationship to the Aggrieved Person"
                      value={familyDetails.relationshipToTheAggrievedPerson}
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formIncidentDate">
                  <Form.Label>Incident Date</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Incident Date"
                    value={
                      fileRegistrationObject.incidentDate != null
                        ? new Date(
                            fileRegistrationObject.incidentDate * 1000
                          ).toLocaleDateString()
                        : undefined
                    }
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formIncidentOccur">
                    <Form.Label>Place of the Incident occurred</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Place of the Incident occurred"
                      value={familyDetails.placeOfTheIncidentOccurred}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formFamilyIncome">
                    <Form.Label>Family Monthly Income</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Family Monthly Income"
                      value={currencyMask(familyDetails.familyMonthlyIncome)}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default FamilyDetailsSection
