import React, { useState } from 'react'
import { Container, Row, Col, Form, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { CompensationValuesModel } from '../../../models/systemConfiguration/compensationValues/compensationValuesModel'
import './AddCompensationValues.scss'
import CompensationValuesServices from '../../../services/systemConfiguration/compensationValues/compensationValuesServices'
import produce from 'immer'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { createCompensationValue } from '../../../utils/successMessages'
import * as validation from './validations'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import { CompensationValuesErrorModel } from '../../../models/systemConfiguration/compensationValues/compensationValuesErrorModel'
import { validationError } from '../../../utils/errorMessages'
import { RelationShipModel } from '../../../models/systemConfiguration/compensationValues/relationShipModel'
import PriceInputComponent from '../../../components/common/priceInputComponent/PriceInputComponent'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import { validateForm } from '../../../utils/utilFunctions'
import metaDataService from '../../../services/metaDataService'

const AddCompensationValues = () => {
  const [loading, setLoading] = useState(false)
  const [schemeList, setSchemeList] = useState([])
  const [incidentTypeList, setIncidentTypeList] = useState([])
  const [errors, setErrors] = useState({} as CompensationValuesErrorModel)
  const [damageTypeList, setDamageTypeList] = useState([])
  const [relationship, setRelationship] = useState([] as RelationShipModel[])
  const [compensationForList, setCompensationForList] = useState([])
  const [
    percentagesAmongRelationshipList,
    setPercentagesAmongRelationshipList,
  ] = useState([])
  const [selectdRelationship, setselectdRelationship] = useState(
    {} as RelationShipModel
  )
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [compensationValuesState, setCompensationValuesState] = useState(
    {} as CompensationValuesModel
  )
  const history = useHistory()

  async function handleProgramType(programType: string) {
    const schemes = await CompensationValuesServices.getSchemeListByProgramType(
      programType
    )
    setSchemeList(schemes.data)
    const newValue = produce(compensationValuesState, (draft) => {
      draft.programType = programType
    })
    setCompensationValuesState(newValue)
  }
  async function handleScheme(scheme: string) {
    setLoading(true)

    const newValue = produce(compensationValuesState, (draft) => {
      draft.scheme = scheme
      draft.compensationFor = ''
    })
    setCompensationValuesState(newValue)

    setRelationship([] as RelationShipModel[])

    if (compensationValuesState.programType === 'Compensation') {
      const incidentType = await metaDataService.apiIncidentTypeList()
      setIncidentTypeList(incidentType.data)

      const compensationFor =
        await CompensationValuesServices.getCompensationForListByScheme(scheme)
      setCompensationForList(compensationFor.data)

      const percentageAmongRelationship =
        await CompensationValuesServices.getPercentageAmongRelationshipList()
      setPercentagesAmongRelationshipList(percentageAmongRelationship.data)

      const damageType = await CompensationValuesServices.getDamageTypeList()
      setDamageTypeList(damageType.data)

      if (
        incidentType.status === 200 &&
        compensationFor.status === 200 &&
        percentageAmongRelationship.status === 200 &&
        damageType.status === 200
      ) {
        setLoading(false)
      }
    }
  }
  async function handleCompensationFor(value: string) {
    const newValue = produce(compensationValuesState, (draft) => {
      draft.compensationFor = value
    })
    setCompensationValuesState(newValue)
  }
  const percentagesAmongRelationship = (value: string) => {
    const filterdRelationShip = percentagesAmongRelationshipList.filter(
      (relation: RelationShipModel) => {
        return relation.name == value
      }
    )
    const filterdRelationShipObject = {} as RelationShipModel
    filterdRelationShip.map((filterd: RelationShipModel) => {
      filterdRelationShipObject.id = filterd.id
      filterdRelationShipObject.name = filterd.name
      filterdRelationShipObject.rule = filterd.rule
    })
    setselectdRelationship(filterdRelationShipObject)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRelationshipTable = (e: any) => {
    const clearError = produce(errors, (draft) => {
      draft.percentageAmongRelationshipListError = ''
    })
    setErrors(clearError)
    e.preventDefault()
    const duplicate = relationship.filter((element) => {
      if (element.id === selectdRelationship.id) {
        return element
      }
    })[0]
    if (duplicate == null) {
      const newValue = produce(relationship, (draft) => {
        draft.push(selectdRelationship)
      })
      setRelationship(newValue)
    }
  }
  const handleRemove = (e: any, id: number) => {
    e.preventDefault()
    const newValue = produce(relationship, (draft) => {
      const index = draft.findIndex((element) => element.id === id)
      if (index !== -1) draft.splice(index, 1)
    })
    setRelationship(newValue)
  }
  const findFormErrors = () => {
    const newErrors = {} as CompensationValuesErrorModel
    if (validation.validateTypeofProgram(compensationValuesState.programType)) {
      newErrors.programTypeError = validation.validateTypeofProgram(
        compensationValuesState.programType
      )
    }
    if (validation.validateScheme(compensationValuesState.scheme)) {
      newErrors.schemeError = validation.validateScheme(
        compensationValuesState.scheme
      )
    }
    if (
      validation.validateIncidentType(compensationValuesState.incidentTypeId)
    ) {
      newErrors.incidentTypeIdError = validation.validateIncidentType(
        compensationValuesState.incidentTypeId
      )
    }
    if (
      validation.validateCompensationfor(
        compensationValuesState.compensationFor
      )
    ) {
      newErrors.compensationForError = validation.validateCompensationfor(
        compensationValuesState.compensationFor
      )
    }
    if (
      compensationValuesState.scheme == 'PD' ||
      compensationValuesState.scheme == 'GSD'
    ) {
      if (validation.validatePercentagesAmongRelationship(relationship)) {
        newErrors.percentageAmongRelationshipListError =
          validation.validatePercentagesAmongRelationship(relationship)
      }
    }

    if (
      compensationValuesState.scheme == 'GPC' ||
      compensationValuesState.scheme == 'GSC' ||
      compensationValuesState.scheme == 'RLP'
    ) {
      if (validation.validateDamageType(compensationValuesState.damageType)) {
        newErrors.damageTypeError = validation.validateDamageType(
          compensationValuesState.damageType
        )
      }
    }

    // if (
    //   validation.validateMaximumAmount(compensationValuesState.maximumAmount)
    // ) {
    //   newErrors.maximumAmountError = validation.validateMaximumAmount(
    //     compensationValuesState.maximumAmount
    //   )
    // }
    // if (
    //   validation.validateMinimumAmount(compensationValuesState.minimumAmount)
    // ) {
    //   newErrors.minimumAmountError = validation.validateMinimumAmount(
    //     compensationValuesState.minimumAmount
    //   )
    // }
    if (
      validateForm({
        value: compensationValuesState.maximumAmount,
        maxLength: 9,
        type: 'number',
        minValue: 1,
      } as ValidationObjectModel)
    ) {
      newErrors.maximumAmountError = validateForm({
        value: compensationValuesState.maximumAmount,
        section: 'Maximum Amount',
        maxLength: 9,
        minValue: 1,
        type: 'number',
      } as ValidationObjectModel)
    }
    if (compensationValuesState.minimumAmount) {
      if (
        validateForm({
          value: compensationValuesState.minimumAmount,
          maxLength: 9,
          type: 'number',
        } as ValidationObjectModel)
      ) {
        newErrors.minimumAmountError = validateForm({
          value: compensationValuesState.minimumAmount,
          section: 'Minimum Amount',
          maxLength: 9,
          type: 'number',
        } as ValidationObjectModel)
      }
    }
    return newErrors
  }
  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleCancel = () => {
    history.goBack()
  }
  const handleConfirm = async () => {
    const selectedRelationShip: RelationShipModel[] = []
    for (let i = 0; i < relationship.length; i++) {
      const relationShipObject: RelationShipModel = {
        percentageAmongRelationship: relationship[i].name,
        rule: relationship[i].rule,
        id: relationship[i].id,
        name: '',
      }
      selectedRelationShip.push(relationShipObject)
    }
    const newValue = produce(compensationValuesState, (draft) => {
      draft.percentageAmongRelationshipList = selectedRelationShip
    })
    setCompensationValuesState(newValue)
    console.log(newValue)
    const result = await CompensationValuesServices.apiAddCompensationValues(
      newValue
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = createCompensationValue
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Add Compensation Values</div>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="typeofProgram">
                    <Form.Label>
                      Type of Program{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.programTypeError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.programTypeError =
                            validation.validateTypeofProgram(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        handleProgramType(e.target.value)
                        const clearError = produce(errors, (draft) => {
                          draft.programTypeError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!compensationValuesState.programType ? (
                        <option value="">--Select Type of Program--</option>
                      ) : (
                        ''
                      )}
                      <option value="Compensation">Compensation</option>
                      {/* <option value="Loan">Loan</option> */}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.programTypeError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="scheme">
                    <Form.Label>
                      Scheme <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.schemeError}
                      value={compensationValuesState.scheme}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.schemeError = validation.validateScheme(
                            e.target.value
                          )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        handleScheme(e.target.value)
                        const clearError = produce(errors, (draft) => {
                          draft.schemeError = ''
                          draft.damageTypeError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!compensationValuesState.scheme ? (
                        <option value="">--Select Scheme--</option>
                      ) : (
                        ''
                      )}
                      {schemeList.map((scheme: { code: string }) => {
                        return (
                          <option key={scheme.code} value={scheme.code}>
                            {scheme.code}
                          </option>
                        )
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.schemeError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="incidentType">
                    <Form.Label>
                      Incident Type{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.incidentTypeIdError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.incidentTypeIdError =
                            validation.validateIncidentType(
                              parseInt(e.target.value)
                            )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(
                          compensationValuesState,
                          (draft) => {
                            draft.incidentTypeId = parseInt(e.target.value)
                          }
                        )
                        setCompensationValuesState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.incidentTypeIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!compensationValuesState.incidentTypeId ? (
                        <option value="">--Select Incident Type--</option>
                      ) : (
                        ''
                      )}
                      {incidentTypeList.map(
                        (incident: { incidentType: string; id: number }) => {
                          return (
                            <option key={incident.id} value={incident.id}>
                              {incident.incidentType}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.incidentTypeIdError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="compensationFor">
                    <Form.Label>
                      Compensation for{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.compensationForError}
                      value={compensationValuesState.compensationFor}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.compensationForError =
                            validation.validateCompensationfor(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        handleCompensationFor(e.target.value)
                        const clearError = produce(errors, (draft) => {
                          draft.compensationForError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!compensationValuesState.compensationFor ? (
                        <option value="">--Select Compensation for--</option>
                      ) : (
                        ''
                      )}
                      {compensationForList.map((Comfor: { name: string }) => {
                        return (
                          <option key={Comfor.name} value={Comfor.name}>
                            {Comfor.name}
                          </option>
                        )
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.compensationForError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {(compensationValuesState.scheme === 'PD' ||
                    compensationValuesState.scheme === 'GSD') && (
                    <>
                      <Col sm={12} className=" border-label mb-3 mt-4">
                        <div className="border-label-span">
                          Percentages among Relationship
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="percentagesAmongRelationship"
                          as={Row}
                        >
                          <Col md={6}>
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-select"
                              as="select"
                              onChange={(e) => {
                                percentagesAmongRelationship(e.target.value)
                              }}
                            >
                              {!compensationValuesState.percentageAmongRelationshipList ? (
                                <option value="">
                                  --Select Relationship--
                                </option>
                              ) : (
                                ''
                              )}
                              {percentagesAmongRelationshipList.map(
                                (pARelationship: {
                                  name: string
                                  id: number
                                }) => {
                                  return (
                                    <option
                                      key={pARelationship.id}
                                      value={pARelationship.name}
                                    >
                                      {pARelationship.name}
                                    </option>
                                  )
                                }
                              )}
                            </Form.Control>
                          </Col>
                          <Col md={5}>
                            <Form.Label>Rule</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectdRelationship.rule}
                            />
                          </Col>
                          <Col
                            md={1}
                            className="d-flex"
                            style={{ paddingTop: '2rem' }}
                          >
                            <button
                              disabled={
                                selectdRelationship.name != null ? false : true
                              }
                              className="action-button"
                              onClick={(e) => {
                                handleRelationshipTable(e)
                              }}
                            >
                              Add
                            </button>
                          </Col>
                        </Form.Group>
                        <Row>
                          <Col>
                            <span className="feedback-error-span-custom">
                              {errors.percentageAmongRelationshipListError}
                            </span>
                            <Table bordered>
                              <thead className="custom-table-header">
                                <tr>
                                  <th>Relationship</th>
                                  <th>Rule</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {relationship.map(
                                (relation: RelationShipModel) => {
                                  return (
                                    <tbody
                                      key={relation.id}
                                      className="custom-table-body"
                                    >
                                      <tr key={`tr-${relation.id}`}>
                                        <td>{relation.name}</td>
                                        <td>{relation.rule}</td>
                                        <td className="action-column">
                                          <button
                                            className="save-button"
                                            onClick={(e) => {
                                              handleRemove(e, relation.id)
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                }
                              )}
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}

                  {compensationValuesState.scheme === 'GPC' ||
                  compensationValuesState.scheme === 'GSC' ||
                  compensationValuesState.scheme === 'RLP' ? (
                    <Form.Group className="mb-3" controlId="damageType">
                      <Form.Label>
                        Damage Type{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.damageTypeError}
                        type="text"
                        className="form-select"
                        as="select"
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.damageTypeError =
                              validation.validateDamageType(e.target.value)
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(
                            compensationValuesState,
                            (draft) => {
                              draft.damageType = e.target.value
                            }
                          )
                          setCompensationValuesState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.damageTypeError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {!compensationValuesState.damageType ? (
                          <option value="">--Select Damage Type--</option>
                        ) : (
                          ''
                        )}
                        {damageTypeList.map(
                          (damage: { name: string; id: number }) => {
                            return (
                              <option key={damage.id} value={damage.name}>
                                {damage.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.damageTypeError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}
                  <Form.Group className="mb-3" controlId="minimumAmount">
                    <Form.Label>Minimum Amount (Rs.)</Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.minimumAmountError}
                      placeholder="Maximum Amount"
                      onChange={(price: string) => {
                        const newErrors = produce(errors, (draft) => {
                          draft.minimumAmountError = validateForm({
                            value: parseFloat(price),
                            section: 'Maximum Amount',
                            maxLength: 9,
                            type: 'number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                        const newValue = produce(
                          compensationValuesState,
                          (draft) => {
                            draft.minimumAmount = parseFloat(price)
                          }
                        )
                        setCompensationValuesState(newValue)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newValue = produce(
                            compensationValuesState,
                            (draft) => {
                              draft.minimumAmount = 0
                            }
                          )
                          setCompensationValuesState(newValue)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.minimumAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                      value={compensationValuesState.minimumAmount}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.minimumAmountError}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="maximumAmount">
                    <Form.Label>
                      Maximum Amount (Rs.){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>

                    <PriceInputComponent
                      isInvalid={!!errors.maximumAmountError}
                      placeholder="Maximum Amount"
                      onChange={(price: string) => {
                        const newErrors = produce(errors, (draft) => {
                          draft.maximumAmountError = validateForm({
                            value: parseFloat(price),
                            section: 'Maximum Amount',
                            minValue: 1,
                            maxLength: 9,
                            type: 'number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                        const newValue = produce(
                          compensationValuesState,
                          (draft) => {
                            draft.maximumAmount = parseFloat(price)
                          }
                        )
                        setCompensationValuesState(newValue)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newValue = produce(
                            compensationValuesState,
                            (draft) => {
                              draft.maximumAmount = 0
                            }
                          )
                          setCompensationValuesState(newValue)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.maximumAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                      value={compensationValuesState.maximumAmount}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.maximumAmountError}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button className="cancel-button" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to create compensation values ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default AddCompensationValues
