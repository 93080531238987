import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ITerminalWizardComponent from '../../../../components/common/ITerminalWizardComponent/ITerminalWizardComponent'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FamilyDetailsModel } from '../../../../models/applicationRegistration/loan/familyDetails'
import { OtherDetailsModel } from '../../../../models/applicationRegistration/loan/otherDetails'
import { ResponseObject } from '../../../../services/api/apiManager'
import seService from '../../../../services/applicationRegistration/loan/seService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { seSectionList } from '../../../../utils/metaData'
import {
  getSectionFiveSuccessMessage,
  handleNotification,
} from '../../../../utils/utilFunctions'
import SectionFive from '../../compensation/sections/SectionFive'
import FamilyDetailsSection from '../sections/FamilyDetailsSection'
import OtherDetailsSection from '../sections/OtherDetailsSection'
import SectionOne from '../../compensation/sections/SectionOne'
import SectionTwo from '../sections/SectionTwo'
import DialogModal from '../../../../components/modals/DialogModal'
import { applicationReadyToModify } from '../../../../utils/constants'

import SectionTwoView from '../../../../components/sections/SectionTwo'
import FamilyDetailsSectionView from '../../../../components/sections/FamilyDetailsSection'
import OtherDetailsSectionView from '../../../../components/sections/OtherDetailsSection'
import SupportDocumentSectionView from '../../../../components/sections/SupportDocumentSection'

const SeFileRegistration = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  console.log(type)
  console.log(params)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const history = useHistory()
  const dispatch = useDispatch()
  async function getFileDetails() {
    const result = await seService.apiFetchSeFile(parseInt(params.id))
    console.log(result)
    setFileRegistrationObject(result.data)
    setLoading(false)
  }
  useEffect(() => {
    getFileDetails()
  }, [])

  const handleOk = () => {
    history.goBack()
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleReject = async (remark: string) => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeReject(
      fileRegistrationObject.id,
      remark
    )
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Reject Successfully')
    if (result.status === 200) {
      history.goBack()
    }
  }

  const handleReturn = async (remark: string) => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeReturn(
      fileRegistrationObject.id,
      remark
    )
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Return Successfully')
    if (result.status === 200) {
      history.goBack()
    }
  }

  const handleProceed = async () => {
    // setLoading(true)
    dispatch(changeLoadingRequest())
    console.log('proceed')
    const result = await seService.apiSeProceed(fileRegistrationObject)
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      setActiveSection(activeSection + 1)
    }
    dispatch(changeLoadingRequest())
    // setLoading(false)
  }

  const handleSectionTwo = async (
    type: string,
    sectionTwo: FileRegistrationModel
  ) => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeAddApplicantDetails(sectionTwo)
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      if (type === 'next') {
        setActiveSection(activeSection + 1)
      }
    }
  }

  const handleSectionThree = async (
    type: string,
    sectionThree: FamilyDetailsModel
  ) => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeAddFamilyDetails(sectionThree)
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      const pdFile = await seService.apiFetchSeFile(parseInt(params.id))
      setFileRegistrationObject(pdFile.data)
      if (type === 'next') {
        setActiveSection(activeSection + 1)
      }
    }
  }

  const handleSectionFour = async (
    type: string,
    sectionFour: OtherDetailsModel
  ) => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeAddOthersDetails(sectionFour)
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Drafted Successfully')
    if (result.status === 200) {
      const pdFile = await seService.apiFetchSeFile(parseInt(params.id))
      setFileRegistrationObject(pdFile.data)
      if (type === 'next') {
        setActiveSection(activeSection + 1)
      }
    }
  }

  const handleSectionFive = async (type: string, formData: FormData) => {
    dispatch(changeLoadingRequest())
    let result = {} as ResponseObject
    let message = 'Application Drafted Successfully'
    if (type === 'save') {
      result = await seService.apiSeAddSupportingDocument(
        formData,
        fileRegistrationObject.id
      )
    } else {
      message = getSectionFiveSuccessMessage(fileRegistrationObject)
      result = await seService.apiSeRegisterDocument(fileRegistrationObject.id)
      if (result.status === 200) {
        const pdFile = await seService.apiFetchSeFile(parseInt(params.id))
        setFileRegistrationObject(pdFile.data)
        if (type === 'submit') {
          setShowModal(true)
        }
      }
    }
    handleNotification(result, message)
    dispatch(changeLoadingRequest())
  }

  const handleModify = async () => {
    dispatch(changeLoadingRequest())
    const result = await seService.apiSeModify(fileRegistrationObject)
    handleNotification(result, applicationReadyToModify)
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      setActiveSection(activeSection + 1)
    }
    dispatch(changeLoadingRequest())
  }

  const handleNextClick = () => {
    setActiveSection(activeSection + 1)
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                Application Registration
              </span>
              <span className="m-2">
                {`Application reference : ${fileRegistrationObject.referenceNumber}`}
              </span>
            </Col>
          </Row>
          <ITerminalWizardComponent
            sectionList={seSectionList}
            activeSection={activeSection}
            validationLevel={fileRegistrationObject.validationLevel}
            onClick={(sectionNumber: number) => {
              setActiveSection(sectionNumber)
            }}
          />
          <Row className="section-layout">
            {activeSection === 1 && (
              <SectionOne
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleOk}
                onCancel={handleCancel}
                onProceed={handleProceed}
                onModify={handleModify}
                onReject={handleReject}
                onReturn={handleReturn}
                onReload={getFileDetails}
              />
            )}
            {activeSection === 2 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <SectionTwo
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(registerFile: FileRegistrationModel) => {
                    handleSectionTwo('next', registerFile)
                  }}
                  onSave={(registerFile: FileRegistrationModel) => {
                    handleSectionTwo('save', registerFile)
                  }}
                  onNextSection={handleNextClick}
                />
              ) : (
                <SectionTwoView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
            {activeSection === 3 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <FamilyDetailsSection
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(familyDetails: FamilyDetailsModel) => {
                    handleSectionThree('next', familyDetails)
                  }}
                  onSave={(familyDetails: FamilyDetailsModel) => {
                    handleSectionThree('save', familyDetails)
                  }}
                />
              ) : (
                <FamilyDetailsSectionView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
            {activeSection === 4 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <OtherDetailsSection
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(otherDetails: OtherDetailsModel) => {
                    handleSectionFour('next', otherDetails)
                  }}
                  onSave={(otherDetails: OtherDetailsModel) => {
                    handleSectionFour('save', otherDetails)
                  }}
                />
              ) : (
                <OtherDetailsSectionView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
            {activeSection === 5 &&
              (fileRegistrationObject.status == 'Application Drafted' ||
              fileRegistrationObject.status == 'Application Modify Draft' ? (
                <SectionFive
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                  onNext={(formData: FormData) => {
                    handleSectionFive('submit', formData)
                  }}
                  onSave={(formData: FormData) => {
                    handleSectionFive('save', formData)
                  }}
                />
              ) : (
                <SupportDocumentSectionView
                  fileRegistrationObject={fileRegistrationObject}
                  onOk={handleOk}
                />
              ))}
          </Row>
        </>
      )}
      {showModal && (
        <DialogModal
          name="Application Creation"
          body={
            <div className="custom-dialog-body">
              <p>{getSectionFiveSuccessMessage(fileRegistrationObject)}</p>
              <p>
                {`Application Number : ${fileRegistrationObject.referenceNumber}`}
              </p>
            </div>
          }
          onOk={() => {
            history.goBack()
          }}
        />
      )}
    </>
  )
}

export default SeFileRegistration
