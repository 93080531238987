import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import NoDataBanner from '../../../components/common/noData/NoDataBanner'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ChequeDetailsModel } from '../../../models/chequePayments/chequeDetailsModel'
import ManageChequePaymentsService from '../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import { currencyMask, handleNotification } from '../../../utils/utilFunctions'
import ApplicationModificationService from '../../../services/applicationModification/ApplicationModificationService'
import { useDispatch } from 'react-redux'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import produce from 'immer'
import ReactDatePicker from 'react-datepicker'

export interface ChequePaymentDetailsProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
}
const ChequePaymentDetails: FC<ChequePaymentDetailsProps> = ({
  fileRegistrationObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [available, setAvailable] = useState(true)
  const [permission, setPermission] = useState(true)
  const [chequeDetailsList, setChequeDetailsList] = useState(
    [] as ChequeDetailsModel[]
  )
  const [chequeDetails, setChequeDetails] = useState({} as ChequeDetailsModel)
  const dispatch = useDispatch()
  useEffect(() => {
    async function getData() {
      const chequeDetailsData =
        await ManageChequePaymentsService.apiGetChequePaymentDetails(
          fileRegistrationObject.id
        )
      if (chequeDetailsData.status === 200) {
        setChequeDetailsList(chequeDetailsData.data)
      } else {
        setAvailable(false)
        if (chequeDetailsData.status === 401) {
          setPermission(false)
        }
      }
      setLoading(false)
    }
    getData()
  }, [])

  const renderChequeDetailsTable = () => {
    return (
      <ReactTableWithPaginationComponent
        columns={columns}
        data={chequeDetailsList}
        onClickRow={(row: any) => {
          console.log(row.id)
        }}
      />
    )
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Payee',
        accessor: 'payee',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'NIC Number',
        accessor: '0',
      },
      {
        Header: 'Cheque Number',
        accessor: 'chequeNumber',
      },
      {
        Header: 'Cheque Issued Date',
        accessor: 'chequeIssuedDate',
      },
      {
        Header: 'Cheque Amount (Rs.)',
        accessor: 'chequeAmount',
      },
      {
        Header: 'Voucher Number',
        accessor: 'voucherNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: (e: any) => {
          if (e.cell.value != '') {
            return (
              <button
                className="save-button"
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  setChequeDetails(e.row.original)
                }}
              >
                Edit
              </button>
            )
          }
        },
      },
    ],
    []
  )

  const onClickSave = async () => {
    dispatch(changeLoadingRequest())
    const results = await ApplicationModificationService.apiUpdateChequeDetails(
      chequeDetails
    )
    if (results.status == 200) {
      setChequeDetailsList(results.data)
    }
    handleNotification(
      results,
      'The application has been modified successfully'
    )
    dispatch(changeLoadingRequest())
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row>
            <Col sm={12} className="border-label">
              <div className="border-label-span">Supporting Documents</div>
              <Col sm={12} className="mb-4">
                {!available ? (
                  <NoDataBanner permission={permission} />
                ) : (
                  <Row>
                    {chequeDetailsList.length > 0
                      ? renderChequeDetailsTable()
                      : 'No Records Available.'}
                  </Row>
                )}
              </Col>

              {chequeDetails.id && (
                <Col sm={12}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formStatus">
                      <Form.Label>Cheque Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.status}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPayee">
                      <Form.Label>Payee</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.payee}
                      />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formNic">
                          <Form.Label>New NIC</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={chequeDetails.newNicNumber}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formNic">
                          <Form.Label>Old NIC</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={chequeDetails.oldNicNumber}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBankName">
                          <Form.Label>Bank Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={chequeDetails.bankName}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBaranchName"
                        >
                          <Form.Label>Branch Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={chequeDetails.branchName}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formAccountNumber"
                        >
                          <Form.Label>Account Number</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={chequeDetails.accountNumber}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formIdentificationNumber"
                        >
                          <Form.Label>Identification Number</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={
                              chequeDetails.identificationNumber
                                ? chequeDetails.identificationNumber
                                : '-'
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formChequeNumber"
                        >
                          <Form.Label>Cheque Number</Form.Label>
                          <Form.Control
                            type="text"
                            value={chequeDetails.chequeNumber}
                            onChange={(e) => {
                              const newValue = produce(
                                chequeDetails,
                                (draft) => {
                                  draft.chequeNumber = e.target.value
                                }
                              )
                              setChequeDetails(newValue)
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formVoucherNumber"
                        >
                          <Form.Label>Voucher Number </Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              const newValue = produce(
                                chequeDetails,
                                (draft) => {
                                  draft.voucherNumber = e.target.value
                                }
                              )
                              setChequeDetails(newValue)
                            }}
                            value={chequeDetails.voucherNumber}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formChequeAmount"
                        >
                          <Form.Label>Cheque Amount (Rs.)</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={
                              chequeDetails.chequeAmount
                                ? currencyMask(chequeDetails.chequeAmount)
                                : '0'
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formChequeIssuedDate"
                        >
                          <Form.Label>Cheque Issued Date</Form.Label>
                          <ReactDatePicker
                            className="form-control"
                            maxDate={new Date()}
                            placeholderText="--Select--"
                            selected={
                              chequeDetails.chequeIssuedDate != null
                                ? new Date(
                                    chequeDetails.chequeIssuedDate * 1000
                                  )
                                : null
                            }
                            onChange={(date: any) => {
                              const newValue = produce(
                                chequeDetails,
                                (draft: any) => {
                                  draft.chequeIssuedDate = date
                                    ? date.getTime() / 1000
                                    : null
                                }
                              )
                              setChequeDetails(newValue)
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              )}
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  disabled={Object.keys(chequeDetails)?.length < 1}
                  className="save-button custom-margin-right"
                  onClick={onClickSave}
                >
                  Update
                </button>
              </Col>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ChequePaymentDetails
