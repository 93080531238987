import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import NicInputComponent from '../../../../components/common/identificationComponent/NicInputComponent'
import NicTypeSelectionComponent from '../../../../components/common/identificationComponent/NicTypeSelectionComponent'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { GuarantorDetailsModel } from '../../../../models/applicationRegistration/loan/guarantorDetails'
import { GuarantorDetailsErrorModel } from '../../../../models/applicationRegistration/loan/gurantorDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import attributeService from '../../../../services/applicationRegistration/loan/attributeService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { getUserSubModuleActions } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { gurantorsHistoryColumns } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkPermission,
  handleNotification,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface GuarantorDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  gurantorDetailsList: GuarantorDetailsModel[]
  onOk: any
  onUpdate: any
  onRemove: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
  id: number
}

const GuarantorDetailsSection: FC<GuarantorDetailsSectionProps> = ({
  fileRegistrationObject,
  gurantorDetailsList,
  onOk,
  onUpdate,
  onRemove,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const [gurantorDetails, setGurantorDetails] = useState({
    newNic: false,
  } as GuarantorDetailsModel)
  const [historyList, setHistoryList] = useState([])
  const [errors, setErrors] = useState({} as GuarantorDetailsErrorModel)

  useEffect(() => {
    async function getData() {
      const historyData = await attributeService.apiFetchGurantorDetailsHistory(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [onRemove, onUpdate])

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      fileRegistrationObject.scheme.toLowerCase(),
      'attribute'
    )
  )

  const findErrors = () => {
    const newErrors = {} as GuarantorDetailsErrorModel
    if (
      validateForm({
        type: text,
        value: gurantorDetails.name,
      } as ValidationObjectModel)
    ) {
      newErrors.nameError = validateForm({
        type: text,
        value: gurantorDetails.name,
        section: 'Name of the Gurantor',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: gurantorDetails.address,
      } as ValidationObjectModel)
    ) {
      newErrors.addressError = validateForm({
        type: text,
        value: gurantorDetails.address,
        section: 'Address of the Gurantor',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'mobile',
        value: gurantorDetails.phoneNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.phoneNumberError = validateForm({
        type: 'mobile',
        value: gurantorDetails.phoneNumber,
        section: 'Phone Number of the Gurantor',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: gurantorDetails.newNicNumber,
      } as ValidationObjectModel) &&
      gurantorDetails.newNic
    ) {
      newErrors.nicError = validateForm({
        type: text,
        value: gurantorDetails.newNicNumber,
        section: 'Nic Number of the Gurantor',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: gurantorDetails.oldNicNumber,
      } as ValidationObjectModel) &&
      !gurantorDetails.newNic
    ) {
      newErrors.nicError = validateForm({
        type: text,
        value: gurantorDetails.oldNicNumber,
        section: 'Nic Number of the Gurantor',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const handleRemove = async (gurantorId: number) => {
    dispatch(changeLoadingRequest())
    const gurantorDataAdd = await attributeService.apiDeleteGurantorDetail(
      gurantorId,
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    handleNotification(gurantorDataAdd, 'Gurantor Removed Successfully')
    if (gurantorDataAdd.status == 204) {
      handleClear()
      onRemove(gurantorId)
    }
    dispatch(changeLoadingRequest())
  }

  const handleCancelGurantor = async (gurantorId: number, remark: string) => {
    dispatch(changeLoadingRequest())
    const gurantorDataAdd = await attributeService.apiCancelGurantorDetail(
      gurantorId,
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase(),
      remark
    )
    handleNotification(gurantorDataAdd, 'Gurantor Canceled Successfully')
    if (gurantorDataAdd.status == 200) {
      handleClear()
      onRemove(gurantorId)
    }
    dispatch(changeLoadingRequest())
  }

  const handleConfirm = (remark: string) => {
    if (showConfirm.section === 'cancel') {
      handleCancelGurantor(showConfirm.id, remark)
    }
    setShowConfirm({
      isShow: false,
      message: '',
      section: '',
      isRemark: false,
      id: 0,
    })
  }

  const renderGuarantorDetailsTable = () => {
    return gurantorDetailsList.map((gurantor: GuarantorDetailsModel) => {
      return (
        <tr key={`gurantor-${gurantor.id}`}>
          <td>{gurantor.name}</td>
          <td>
            {gurantor.newNic ? gurantor.newNicNumber : gurantor.oldNicNumber}
          </td>
          <td>{gurantor.address}</td>
          <td>{gurantor.phoneNumber}</td>
          <td>{gurantor.status}</td>
          {fileRegistrationObject.status === 'Disbursement Pending' &&
          gurantor.status != 'Cancel' &&
          checkPermission('modify', attributeActionListSubModule) ? (
            <td>
              <button
                className="save-button custom-margin-right"
                type="button"
                onClick={() => {
                  setGurantorDetails(gurantor)
                }}
              >
                Edit
              </button>
              <button
                className="save-button custom-margin-right"
                type="button"
                onClick={() => {
                  setShowConfirm({
                    isShow: true,
                    message: 'Do you wish to cancel this gurantor?',
                    section: 'cancel',
                    isRemark: true,
                    id: gurantor.id,
                  })
                }}
              >
                Cancel
              </button>
              <button
                className="save-button custom-margin-right"
                type="button"
                onClick={() => {
                  handleRemove(gurantor.id)
                }}
              >
                Remove
              </button>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      )
    })
  }

  const handleClear = () => {
    document.getElementById('file-guarantor-details-form').reset()

    const resetGurantor = {
      newNic: false,
      applicationFileId: fileRegistrationObject.id,
    } as GuarantorDetailsModel

    const resetGurantorData = produce(resetGurantor, (draft) => {
      draft.name = ''
      draft.oldNicNumber = ''
      draft.newNicNumber = ''
      draft.address = ''
      draft.phoneNumber = ''
    })
    setGurantorDetails(resetGurantorData)
    setErrors({})
  }

  const handleAdd = async (action: string) => {
    console.log(action)
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      dispatch(changeLoadingRequest())
      let gurantorDataAdd = {} as ResponseObject
      if (action === 'add') {
        gurantorDataAdd = await attributeService.apiAddGurantorDetails(
          gurantorDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        gurantorDataAdd = await attributeService.apiModifyGurantorDetail(
          gurantorDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      }
      handleNotification(
        gurantorDataAdd,
        action === 'add'
          ? 'Gurantor Added Successfully'
          : 'Gurantor Updated Successfully'
      )
      if (gurantorDataAdd.status == 200) {
        handleClear()
        onUpdate(gurantorDataAdd.data)
      }
      dispatch(changeLoadingRequest())
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {fileRegistrationObject.status === 'Disbursement Pending' && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Guarantor Details</div>
              <Form id="file-guarantor-details-form">
                <Form.Group className="mb-3" controlId="formGuarantorName">
                  <Form.Label>
                    Name <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.nameError}
                    type="text"
                    placeholder="Enter Name"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.nameError = validateForm({
                          type: text,
                          value: gurantorDetails.name,
                          section: 'Full name',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        gurantorDetails,
                        (draft: GuarantorDetailsModel) => {
                          draft.name = e.target.value
                        }
                      )
                      setGurantorDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.nameError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={gurantorDetails.name}
                  />
                  <InvalidFeedback message={errors.nameError} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="formNICNumber">
                  <Form.Label>
                    NIC Number <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <NicTypeSelectionComponent
                    isDisabled={false}
                    newNic={gurantorDetails.newNic}
                    onChange={(value: boolean) => {
                      const newFormValue = produce(
                        gurantorDetails,
                        (draft: GuarantorDetailsModel) => {
                          draft.newNic = value
                        }
                      )
                      setGurantorDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.nicError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <NicInputComponent
                    errors={errors}
                    isDisabled={false}
                    newNic={gurantorDetails.newNic}
                    value={
                      gurantorDetails.newNic
                        ? gurantorDetails.newNicNumber
                        : gurantorDetails.oldNicNumber
                    }
                    onChange={(identity: any) => {
                      if (identity.error) {
                        const clearError = produce(errors, (draft) => {
                          draft.nicError = 'Not a valid nic number'
                        })
                        setErrors(clearError)
                      } else {
                        const newFormValue = produce(
                          gurantorDetails,
                          (draft: GuarantorDetailsModel) => {
                            draft.newNicNumber = identity.newNic
                            draft.oldNicNumber = identity.oldNic
                          }
                        )
                        setGurantorDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.nicError = ''
                        })
                        setErrors(clearError)
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGuarantorAddress">
                  <Form.Label>
                    Address <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.addressError}
                    type="text"
                    placeholder="Enter Address"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.addressError = validateForm({
                          type: text,
                          value: gurantorDetails.address,
                          section: 'Address',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        gurantorDetails,
                        (draft: GuarantorDetailsModel) => {
                          draft.address = e.target.value
                        }
                      )
                      setGurantorDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.addressError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={gurantorDetails.address}
                  />
                  <InvalidFeedback message={errors.addressError} />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="formGuarantorPhoneNumber"
                >
                  <Form.Label>
                    Phone Number{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.phoneNumberError}
                    type="text"
                    placeholder="Enter Phone Number"
                    pattern="/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.phoneNumberError = validateForm({
                          type: 'mobile',
                          value: gurantorDetails.phoneNumber,
                          section: 'Phone Number',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        gurantorDetails,
                        (draft: GuarantorDetailsModel) => {
                          draft.phoneNumber = e.target.value
                        }
                      )
                      setGurantorDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.phoneNumberError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={gurantorDetails.phoneNumber}
                  />
                  <InvalidFeedback message={errors.phoneNumberError} />
                </Form.Group>
              </Form>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                {checkPermission('add', attributeActionListSubModule) &&
                  gurantorDetails.id == null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        handleAdd('add')
                      }}
                    >
                      Add
                    </button>
                  )}
                {checkPermission('modify', attributeActionListSubModule) &&
                  gurantorDetails.id != null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        handleAdd('update')
                      }}
                    >
                      Update
                    </button>
                  )}
                <button
                  className="save-button custom-margin-right"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </Col>
            </Col>
          )}
          {gurantorDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Guarantors</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Guarantor Name</th>
                        <th>NIC Number</th>
                        <th>Address</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderGuarantorDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={gurantorsHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}

          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
              id: 0,
            })
          }}
          isReason={false}
          isRemark={showConfirm.isRemark}
          onConfirm={(remark: string) => {
            handleConfirm(remark)
          }}
        />
      )}
    </>
  )
}

export default GuarantorDetailsSection
