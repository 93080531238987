import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class PropertyDamageTypeServices {
  async apiFetchPropertyList(filterData: FilterModel) {
    return apiService.apiPost(`/system-configuration/property/list`, filterData)
  }

  async apiFetchPropertyListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/property/count`,
      filterData
    )
  }
  async apiAddProperty(Property: any) {
    return apiService.apiPost(`/system-configuration/property/add`, Property)
  }
  async getPropertyById(id: number) {
    return apiService.apiGet(`/system-configuration/property/view/${id}`)
  }
  async apiActiveProperty(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/property/${id}/active`
    )
  }
  async apiInactiveProperty(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/property/${id}/inactive`
    )
  }
  async getPropertyHistoryById(id: number) {
    return apiService.apiGet(`/system-configuration/property/${id}/history`)
  }
  async apiUpdateProperty(id: number | undefined, property: any) {
    return apiService.apiPutCommon(
      `/system-configuration/property/modify/${id}`,
      property
    )
  }
}
export default new PropertyDamageTypeServices()
