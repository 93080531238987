import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { schemeTiles } from '../../../utils/metaData'
import GpcApplication from './gpc/GpcApplication'
import GscApplication from './gsc/GscApplication'
import GsiApplication from './gsi/GsiApplication'
import GsdApplication from './gsd/GsdApplication'
import PdApplication from './pd/PdApplication'
import PiApplication from './pi/PiApplication'
import RlpApplication from './rlp/RlpApplication'
import MpApplication from './mp/MpApplication'

const ChequePrint = () => {
  const { path } = useRouteMatch()
  const renderModules = () => {
    return schemeTiles.map(
      (module: { name: string; description: string }, index: number) => {
        return (
          <Col key={`module-${index}`} xs={4}>
            <TileButtonComponent
              isIcon={false}
              name={module.description}
              routeURL={module.name}
            />
          </Col>
        )
      }
    )
  }
  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">Cheque Print</Row>
          <Row className="page-tiles-layout">{renderModules()}</Row>
        </Route>
        <Route path={`${path}/gpc`} component={GpcApplication} />
        <Route path={`${path}/gsc`} component={GscApplication} />
        <Route path={`${path}/gsi`} component={GsiApplication} />
        <Route path={`${path}/gsd`} component={GsdApplication} />
        <Route path={`${path}/pd`} component={PdApplication} />
        <Route path={`${path}/pi`} component={PiApplication} />
        <Route path={`${path}/rlp`} component={RlpApplication} />
        <Route path={`${path}/mp`} component={MpApplication} />
      </Switch>
    </>
  )
}

export default ChequePrint
