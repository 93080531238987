import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import './Modals.scss'

export interface DialogModalProps {
  name: string
  body: React.ReactNode
  onOk?: React.MouseEventHandler<HTMLButtonElement>
  otherButtons?: React.ReactNode
}

const DialogModal: FC<DialogModalProps> = ({
  name,
  body,
  onOk,
  otherButtons,
}) => {
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onOk}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {otherButtons}
        <button className="save-button" onClick={onOk}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogModal
