import { text } from '@fortawesome/fontawesome-svg-core'
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import DialogModal from '../../../../components/modals/DialogModal'
import Select from 'react-select'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanNPADetailsModel } from '../../../../models/applicationRegistration/loan/loanNPADetails'
import { LoanRepaymentDetailsModel } from '../../../../models/applicationRegistration/loan/loanRepaymentDetails'
import { LoanRepaymentDetailsErrorModel } from '../../../../models/applicationRegistration/loan/loanRepaymentDetailsErrorModel'
import { LoanRescheduleDetailsModel } from '../../../../models/applicationRegistration/loan/loanRescheduleDetails'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import attributeService from '../../../../services/applicationRegistration/loan/attributeService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { getUserSubModuleActions } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { repaymentHistoryColumns } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkPermission,
  FetchRequesthandleNotification,
  handleNotification,
  renderPayeeNames,
  thousandSeperator,
  validateForm,
} from '../../../../utils/utilFunctions'
import { GuarantorDetailsModel } from '../../../../models/applicationRegistration/loan/guarantorDetails'
import { LoanRepaymentGurantorDetailsModel } from '../../../../models/applicationRegistration/loan/repaymentGurantorDetails'
import CommonPrintModal, {
  CommonPrintModel,
} from '../../../../components/modals/CommonPrintModal'
import { saveAs } from 'file-saver'
export interface RepaymentDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  npaDetailsList: LoanNPADetailsModel[]
  gurantorDetailsList: GuarantorDetailsModel[]
  onOk: any
  onUpdate: any
}

const RepaymentDetailsSection: FC<RepaymentDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  npaDetailsList,
  gurantorDetailsList,
  onUpdate,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showRepaymentPrintModal, setShowRepaymentPrintModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isValid, setisValid] = useState(true)
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [payeeOptions, setPayeeOptions] = useState([])
  const [repaymentDetails, setRepaymentDetails] = useState(
    {} as LoanRepaymentDetailsModel
  )
  const [repaymentDetailsList, setRepaymentDetailsList] = useState(
    [] as LoanRepaymentDetailsModel[]
  )
  const [rescheduleDetailsList, setRecheduleDetailsList] = useState(
    [] as LoanRescheduleDetailsModel[]
  )
  const [errors, setErrors] = useState({} as LoanRepaymentDetailsErrorModel)
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      const repaymentDetailsData =
        await attributeService.apiFetchRepaymentDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      if (repaymentDetailsData.status === 200) {
        setRepaymentDetailsList(repaymentDetailsData.data)
      }
      const rescheduleData = await attributeService.apiFetchRescheduleDetails(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
      if (rescheduleData.status === 200) {
        setRecheduleDetailsList(rescheduleData.data)
      }
      getHistoryData()
      const payeeOptionList: constants.RoleOption[] = []
      for (let i = 0; i < gurantorDetailsList.length; i++) {
        if (gurantorDetailsList[i].status === 'Active') {
          const payeeOption: constants.RoleOption = {
            value: gurantorDetailsList[i].id,
            label: gurantorDetailsList[i].name,
          }
          payeeOptionList.push(payeeOption)
        }
      }
      setPayeeOptions(payeeOptionList)

      if (checkEnablePayeeDropDown()) {
        const newRepaymentDetails = produce(
          repaymentDetails,
          (draft: LoanRepaymentDetailsModel) => {
            draft.guarantorPayment = true
          }
        )
        setRepaymentDetails(newRepaymentDetails)
      }
      setLoading(false)
    }
    getData()
  }, [])

  const getHistoryData = async () => {
    const historyData = await attributeService.apiFetchRepaymentHistoryDetails(
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (historyData.status === 200) {
      setHistoryList(historyData.data)
    }
  }

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      fileRegistrationObject.scheme.toLowerCase(),
      'attribute'
    )
  )

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      },
    }),
  }

  const handleClear = () => {
    document.getElementById('file-repayment-details-form').reset()

    const resetRepayment = {
      id: repaymentDetails.id == null ? null : repaymentDetails.id,
      applicationFileId: fileRegistrationObject.id,
      guarantorPayment: checkEnablePayeeDropDown(),
    } as LoanRepaymentDetailsModel

    const resetRepaymentData = produce(resetRepayment, (draft) => {
      draft.repaymentCapitalAmount = ''
      draft.repaymentInterestAmount = ''
      draft.paidDate = null
      draft.loanStatus = null
      draft.specialRemarks = null
    })
    setRepaymentDetails(resetRepaymentData)
    setErrors({})
  }

  const handleAdd = async (action: string) => {
    setShowConfirm(false)
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      dispatch(changeLoadingRequest())
      let repaymentDataAdd = {} as ResponseObject
      if (action === 'add') {
        repaymentDataAdd = await attributeService.apiAddRepaymentDetails(
          repaymentDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        repaymentDataAdd = await attributeService.apiModifyRepaymentDetail(
          repaymentDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      }
      handleNotification(
        repaymentDataAdd,
        action === 'add'
          ? 'Repayment Added Successfully'
          : 'Repayment Updated Successfully'
      )
      if (repaymentDataAdd.status == 200) {
        if (repaymentDetails.loanStatus === 'Completed') {
          setPaymentComplete(true)
        } else {
          handleClear()
          const repaymentDetailsData =
            await attributeService.apiFetchRepaymentDetails(
              fileRegistrationObject.id,
              fileRegistrationObject.scheme.toLowerCase()
            )
          if (repaymentDetailsData.status === 200) {
            setRepaymentDetailsList(repaymentDetailsData.data)
          }
          onUpdate()
          getHistoryData()
        }
      }
      dispatch(changeLoadingRequest())
    }
  }

  const findErrors = () => {
    const newErrors = {} as LoanRepaymentDetailsErrorModel
    if (
      validateForm({
        type: 'number',
        value: repaymentDetails.repaymentCapitalAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.repaymentCapitalAmountError = validateForm({
        type: 'number',
        value: repaymentDetails.repaymentCapitalAmount,
        section: 'Repayment Amount Captial',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: repaymentDetails.repaymentInterestAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.repaymentInterestAmountError = validateForm({
        type: 'number',
        value: repaymentDetails.repaymentInterestAmount,
        section: 'Repayment Amount Interest',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: repaymentDetails.paidDate,
      } as ValidationObjectModel)
    ) {
      newErrors.paidDateError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: repaymentDetails.paidDate,
        section: 'Paid Date',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: repaymentDetails.gracePeriod,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.gracePeriodError = validateForm({
        type: 'number',
        value: repaymentDetails.gracePeriod,
        section: 'Repayment Grace Period',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: repaymentDetails.loanStatus,
      } as ValidationObjectModel)
    ) {
      newErrors.loanStatusError = validateForm({
        type: text,
        value: repaymentDetails.loanStatus,
        section: 'Loan Status',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: repaymentDetails.specialRemarks,
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.reasonError = validateForm(
        {
          type: text,
          value: disbursmentDetails.specialRemarks,
          section: 'Special Remark',
        } as ValidationObjectModel,
        false
      )
    }
    return newErrors
  }

  const checkEnableButton = (repayment: LoanRepaymentDetailsModel) => {
    if (rescheduleDetailsList.length == 0) {
      return true
    }
    const activeReschedule = rescheduleDetailsList.find(
      (element) => element.active === true
    )
    if (activeReschedule) {
      if (repayment.loanRescheduleDetailsId === activeReschedule.id) {
        return true
      }
    }
    return false
  }

  const checkEnableAction = () => {
    if (
      fileRegistrationObject.status === 'Loan Disbursed' ||
      fileRegistrationObject.status === 'Loan Rescheduled'
    ) {
      return true
    }
    return false
  }

  const checkEnablePayeeDropDown = () => {
    if (npaDetailsList.length == 0) {
      return false
    }
    const lastActiveNPA = npaDetailsList[npaDetailsList.length - 1]
    if (lastActiveNPA && lastActiveNPA.guarantorPayment) {
      return true
    }
    return false
  }

  const handleRemove = async (repaymentId: number) => {
    dispatch(changeLoadingRequest())
    const repaymentataAdd = await attributeService.apiDeleteRepaymentDetail(
      repaymentId,
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    handleNotification(repaymentataAdd, 'Repayment Removed Successfully')
    if (repaymentataAdd.status == 204) {
      handleClear()
      const newRepaymentList = repaymentDetailsList.filter(
        (repayment: LoanRepaymentDetailsModel) => {
          if (repayment.id != repaymentId) {
            return repayment
          }
        }
      )
      setRepaymentDetailsList(newRepaymentList)
      getHistoryData()
    }
    dispatch(changeLoadingRequest())
  }

  const renderRepaymentDetailsTable = () => {
    return repaymentDetailsList.map(
      (repayment: LoanRepaymentDetailsModel, index: number) => {
        return (
          <tr key={`repayment-${repayment.id}`}>
            <td>
              {repayment.guarantorPayment
                ? renderPayeeNames(repayment.loanRepaymentGuarantorList)
                : 'Applicant'}
            </td>
            <td>{thousandSeperator(repayment.repaymentCapitalAmount)}</td>
            <td>{thousandSeperator(repayment.repaymentInterestAmount)}</td>
            <td>{new Date(repayment.paidDate * 1000).toLocaleDateString()}</td>
            <td>{repayment.gracePeriod}</td>
            <td>{repayment.loanStatus}</td>
            <td>{repayment.specialRemarks}</td>
            {checkEnableAction() &&
              checkEnableButton(repayment) &&
              index === repaymentDetailsList.length - 1 &&
              checkPermission('modify', attributeActionListSubModule) && (
                <td>
                  <button
                    className="save-button custom-margin-right"
                    type="button"
                    onClick={() => {
                      setRepaymentDetails(repayment)
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    type="button"
                    onClick={() => {
                      handleRemove(repayment.id)
                    }}
                  >
                    Remove
                  </button>
                </td>
              )}
          </tr>
        )
      }
    )
  }

  const handleRepaymentPrint = (printData: CommonPrintModel) => {
    if (printData.language == 'eng') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-repayment-reminder-letter/${printData.language}/pdf`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Repayment reminder letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowRepaymentPrintModal(false)
            const fileName = `repayment_reminder${fileRegistrationObject.id}.pdf`
            saveAs(response, fileName)
          }
        })
    } else {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-repayment-reminder-letter/${printData.language}/docx`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Repayment reminder letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowRepaymentPrintModal(false)
            const fileName = `repayment_reminder_${fileRegistrationObject.id}.docx`
            saveAs(response, fileName)
          }
        })
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {checkEnableAction() && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Repayment Details</div>
              <Form id="file-repayment-details-form">
                <Row>
                  {checkEnablePayeeDropDown() && (
                    <Form.Group className="mb-4" controlId="Payee">
                      <Form.Label>
                        Payee <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={customStyles}
                        isMulti
                        placeholder="--Select Payee--"
                        options={payeeOptions}
                        value={payeeOptions.filter((option) => {
                          if (
                            repaymentDetails.loanRepaymentGuarantorList?.find(
                              (elemet) =>
                                elemet.guarantorDetailsId === option.value
                            )
                          ) {
                            return option
                          }
                        })}
                        onChange={(selectedOption) => {
                          selectedOption.length === 0
                            ? setisValid(false)
                            : setisValid(true)
                          const payeeList =
                            [] as LoanRepaymentGurantorDetailsModel[]

                          for (let i = 0; i < selectedOption.length; i++) {
                            const payeeObject =
                              {} as LoanRepaymentGurantorDetailsModel
                            payeeObject.guarantorDetailsId =
                              selectedOption[i].value

                            payeeList.push(payeeObject)
                          }
                          const newRepaymentDetails = produce(
                            repaymentDetails,
                            (draft: LoanRepaymentDetailsModel) => {
                              draft.loanRepaymentGuarantorList = payeeList
                            }
                          )
                          setRepaymentDetails(newRepaymentDetails)
                          const newError = produce(errors, (draft) => {
                            draft.payeeError =
                              selectedOption.length === 0
                                ? 'Payee cannot be empty'
                                : ''
                          })
                          setErrors(newError)
                        }}
                      />
                      <span className="feedback-error-span">
                        {errors.payeeError}
                      </span>
                    </Form.Group>
                  )}
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formRepaymentAmountCapital"
                  >
                    <Form.Label>
                      Amount Capital
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.repaymentCapitalAmountError}
                      value={repaymentDetails.repaymentCapitalAmount}
                      placeholder="Repayment Amount Capital (Rs.)"
                      onChange={(price: string) => {
                        const newRepaymentDetails = produce(
                          repaymentDetails,
                          (draft: LoanRepaymentDetailsModel) => {
                            draft.repaymentCapitalAmount = parseFloat(price)
                          }
                        )
                        setRepaymentDetails(newRepaymentDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.repaymentCapitalAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Repayment Amount Capital (Rs.)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newRepaymentDetails = produce(
                            repaymentDetails,
                            (draft: LoanRepaymentDetailsModel) => {
                              draft.repaymentCapitalAmount = 0
                            }
                          )
                          setRepaymentDetails(newRepaymentDetails)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.repaymentCapitalAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback
                      message={errors.repaymentCapitalAmountError}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formRepaymentAmountInterest"
                  >
                    <Form.Label>
                      Amount Interest
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.repaymentInterestAmountError}
                      value={repaymentDetails.repaymentInterestAmount}
                      placeholder="Repayment Amount Interest (Rs.)"
                      onChange={(price: string) => {
                        const newRepaymentDetails = produce(
                          repaymentDetails,
                          (draft: LoanRepaymentDetailsModel) => {
                            draft.repaymentInterestAmount = parseFloat(price)
                          }
                        )
                        setRepaymentDetails(newRepaymentDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.repaymentInterestAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Repayment Amount Interest (Rs.)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newRepaymentDetails = produce(
                            repaymentDetails,
                            (draft: LoanRepaymentDetailsModel) => {
                              draft.repaymentInterestAmount = 0
                            }
                          )
                          setRepaymentDetails(newRepaymentDetails)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.repaymentInterestAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback
                      message={errors.repaymentInterestAmountError}
                    />
                  </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-4" controlId="formPaidDate">
                  <Form.Label>
                    Paid Date <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <ReactDatePicker
                    required={true}
                    maxDate={new Date()}
                    className={
                      errors.paidDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="Paid Date"
                    selected={
                      repaymentDetails.paidDate != null
                        ? new Date(repaymentDetails.paidDate * 1000)
                        : null
                    }
                    onChange={(date: Date) => {
                      const newRepaymentDetails = produce(
                        repaymentDetails,
                        (draft: LoanRepaymentDetailsModel) => {
                          draft.paidDate = date ? date.getTime() / 1000 : null
                        }
                      )
                      setRepaymentDetails(newRepaymentDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.paidDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.paidDateError} />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-4"
                  controlId="formGracePeriod"
                >
                  <Form.Label>
                    Grace Period (in months)
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.gracePeriodError}
                    type="text"
                    placeholder="Grace Period (in months)"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.gracePeriodError = validateForm({
                          type: 'number',
                          maxLength: 3,
                          value: repaymentDetails.gracePeriod,
                          section: 'Grace period',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        repaymentDetails,
                        (draft: LoanRepaymentDetailsModel) => {
                          draft.gracePeriod = parseInt(e.target.value)
                        }
                      )
                      setRepaymentDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.gracePeriodError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={repaymentDetails.gracePeriod}
                  />
                  <InvalidFeedback message={errors.gracePeriodError} />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-4"
                  controlId="formDpLoanStatus"
                >
                  <Form.Label>
                    {`Loan Status`}{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.loanStatusError}
                    className="form-select"
                    as="select"
                    value={repaymentDetails.loanStatus}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.loanStatusError = validateForm({
                          type: text,
                          value: repaymentDetails.loanStatus,
                          section: 'Loan Status',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        repaymentDetails,
                        (draft: LoanRepaymentDetailsModel) => {
                          draft.loanStatus = e.target.value
                        }
                      )
                      setRepaymentDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.loanStatusError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    <option value="">--Select Loan Status--</option>
                    <option value="Pending">Processing</option>
                    <option value="Completed">Completed</option>
                  </Form.Control>
                  <InvalidFeedback message={errors.loanStatusError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSpecialRemark">
                  <Form.Label>Special Remark</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.specialRemarksError}
                    type="text"
                    placeholder="Enter Special Remark"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.specialRemarksError = validateForm(
                          {
                            type: text,
                            value: repaymentDetails.specialRemarks,
                            section: 'Special Remark',
                          } as ValidationObjectModel,
                          false
                        )
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newRepaymentDetails = produce(
                        repaymentDetails,
                        (draft: LoanRepaymentDetailsModel) => {
                          draft.specialRemarks = e.target.value
                        }
                      )
                      setRepaymentDetails(newRepaymentDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.specialRemarksError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={repaymentDetails.specialRemarks}
                  />
                  <InvalidFeedback message={errors.specialRemarksError} />
                </Form.Group>
              </Form>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                {checkPermission('add', attributeActionListSubModule) &&
                  repaymentDetails.id == null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                    >
                      Add
                    </button>
                  )}
                {checkPermission('modify', attributeActionListSubModule) &&
                  repaymentDetails.id != null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                    >
                      Update
                    </button>
                  )}
                <button
                  className="save-button custom-margin-right"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </Col>
            </Col>
          )}

          {repaymentDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Repayments</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Payee</th>
                        <th>Capital Amount</th>
                        <th>Interest Amount</th>
                        <th>Paid Date</th>
                        <th>Grace Period (in months)</th>
                        <th>Loan Status</th>
                        <th>Special Remark</th>
                        {checkEnableAction() &&
                          checkPermission(
                            'modify',
                            attributeActionListSubModule
                          ) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderRepaymentDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={repaymentHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button
              className={'print-acknowledgement-button'}
              onClick={() => {
                setShowRepaymentPrintModal(true)
              }}
            >
              Print Reminder Letter
            </button>
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
      {showConfirm && (
        <ConfirmationModal
          name={'Confirmation'}
          message={`Do you wish to add repayment ${repaymentDetails.loanStatus?.toLowerCase()} details?`}
          onCancel={() => {
            setShowConfirm(false)
          }}
          isRemark={false}
          onConfirm={() => {
            handleAdd(repaymentDetails.id != null ? 'update' : 'add')
          }}
        />
      )}
      {paymentComplete && (
        <DialogModal
          name="Loan Attribute"
          body={
            <div className="custom-dialog-body">
              <p>Payment Completed Successfully</p>
              <p>
                {`Application Number : ${fileRegistrationObject.referenceNumber}`}
              </p>
            </div>
          }
          onOk={() => {
            history.goBack()
          }}
        />
      )}
      {showRepaymentPrintModal && (
        <CommonPrintModal
          onConfirm={(data: CommonPrintModel) => {
            handleRepaymentPrint(data)
            setShowRepaymentPrintModal(false)
          }}
          onCancel={() => {
            setShowRepaymentPrintModal(false)
          }}
          title={'Print Repayment Reminder Letter'}
        />
      )}
    </>
  )
}

export default RepaymentDetailsSection
