import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { OtherDetailsModel } from '../../../../models/applicationRegistration/loan/otherDetails'
import { OtherDetailsErrorModel } from '../../../../models/applicationRegistration/loan/otherDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import seService from '../../../../services/applicationRegistration/loan/seService'
import metaDataService from '../../../../services/metaDataService'
import { getUser } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import {
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface OtherDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const OtherDetailsSection: FC<OtherDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [relationshipList, setRelationShipList] = useState([])
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [categoryList, setCategoryList] = useState([] as any[])
  const [subCategoryList, setSubCategoryListList] = useState([] as any[])
  const [loading, setLoading] = useState(true)
  const [otherDetails, setOtherDetails] = useState({} as OtherDetailsModel)
  const [errors, setErrors] = useState({} as OtherDetailsErrorModel)
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      console.log(relationshipList)
      console.log(subCategoryList)
      const categoryData =
        await metaDataService.apiNatureOfSelfEmployementCategories()
      if (categoryData.status === 200) {
        setCategoryList(categoryData.data)
      }
      const relationshipData = await metaDataService.getRelationshipList()
      if (relationshipData.status === 200) {
        setRelationShipList(relationshipData.data)
      }

      const bankData = await metaDataService.getBankList()
      if (bankData.status === 200) {
        setBankList(bankData.data)
      }
      const otherDetailsData = await seService.apiSeGetOthersDetails(
        fileRegistrationObject.id
      )

      if (otherDetailsData.status === 200 && otherDetailsData.data.id != null) {
        getBranchesList(otherDetailsData.data.bankId)
        getSubCategoryList(otherDetailsData.data.categoryId)
        setOtherDetails(otherDetailsData.data)
      } else {
        const newOtherDetails = produce(otherDetails, (draft) => {
          draft.applicationFileId = fileRegistrationObject.id
          draft.anyRehabilitatedLoansReceivedPreviously = false
          draft.anyPropertyDamagesDuetoViolenceActivities = false
          draft.requestForAnyPropertyCompensation = false
          draft.havePreviousExperiences = false
        })
        setOtherDetails(newOtherDetails)
      }
      setLoading(false)
    }
    getMetaData()
  }, [fileRegistrationObject])

  const getSubCategoryList = async (categoryId: number) => {
    const subCategoryData =
      await metaDataService.apiNatureOfSelfEmployementSubCategories(categoryId)
    if (subCategoryData.status === 200) {
      setSubCategoryListList(subCategoryData.data)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findErrors = () => {
    const newErrors = {} as OtherDetailsErrorModel
    if (otherDetails.anyRehabilitatedLoansReceivedPreviously) {
      if (
        validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: otherDetails.outstandingAmount,
        } as ValidationObjectModel)
      ) {
        newErrors.outstandingAmountError = validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: otherDetails.outstandingAmount,
          section: 'Outstanding Amount',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'number',
          value: otherDetails.bankId,
        } as ValidationObjectModel)
      ) {
        newErrors.bankIdError = validateForm({
          type: 'number',
          value: otherDetails.bankId,
          section: 'Bank Name',
        } as ValidationObjectModel)
      }
    }
    if (otherDetails.requestForAnyPropertyCompensation) {
      if (
        validateForm({
          type: 'text',
          maxLength: 50,
          value: otherDetails.detailsOfTheApplication,
        } as ValidationObjectModel)
      ) {
        newErrors.detailsOfTheApplicationError = validateForm({
          type: 'text',
          maxLength: 50,
          value: otherDetails.detailsOfTheApplication,
          section: 'Details of the Application',
        } as ValidationObjectModel)
      }
    }

    // if (
    //   validateForm({
    //     type: 'text',
    //     maxLength: 100,
    //     value: otherDetails.natureOfTheSelfEmployment,
    //   } as ValidationObjectModel)
    // ) {
    //   newErrors.natureOfTheSelfEmploymentError = validateForm({
    //     type: 'text',
    //     maxLength: 100,
    //     value: otherDetails.natureOfTheSelfEmployment,
    //     section: 'Nature of the Self-Employment that apply for loan',
    //   } as ValidationObjectModel)
    // }
    if (otherDetails.havePreviousExperiences) {
      if (
        validateForm({
          type: 'text',
          maxLength: 2,
          value: otherDetails.yearOfPreviousExperiences,
        } as ValidationObjectModel)
      ) {
        newErrors.yearOfPreviousExperiencesError = validateForm({
          type: 'text',
          maxLength: 2,
          value: otherDetails.yearOfPreviousExperiences,
          section: 'For how many years',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          maxLength: 2,
          value: otherDetails.yearOfTrainedExperiences,
        } as ValidationObjectModel)
      ) {
        newErrors.yearOfTrainedExperiencesError = validateForm({
          type: 'text',
          maxLength: 2,
          value: otherDetails.yearOfTrainedExperiences,
          section: 'If trained, for how many years',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: otherDetails.requiredLoanAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.requiredLoanAmountError = validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: otherDetails.requiredLoanAmount,
        section: 'Required Loan Amount',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        value: otherDetails.repaymentPeriod,
      } as ValidationObjectModel)
    ) {
      newErrors.repaymentPeriodError = validateForm({
        type: 'text',
        value: otherDetails.repaymentPeriod,
        section: 'Repayment period (in months)',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        value: otherDetails.gracePeriod,
      } as ValidationObjectModel)
    ) {
      newErrors.gracePeriodError = validateForm({
        type: 'text',
        value: otherDetails.gracePeriod,
        section: 'Grace Period (in months)',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        value: otherDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankIdError = validateForm({
        type: 'text',
        value: otherDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: otherDetails.grantedLoanAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.grantedLoanAmountError = validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: otherDetails.grantedLoanAmount,
        section: 'Approved Loan Amount',
      } as ValidationObjectModel)
    }
    if (otherDetails.bankId) {
      if (
        validateForm({
          type: 'number',
          value: otherDetails.branchId,
        } as ValidationObjectModel)
      ) {
        newErrors.branchIdError = validateForm({
          type: 'number',
          value: otherDetails.branchId,
          section: 'Branch Name',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm({
        type: 'text',
        value: otherDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: 'text',
        value: otherDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    }

    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const handleConfirm = () => {
    if (showConfirm.section === 'save') {
      onSave(otherDetails)
    } else {
      onNext(otherDetails)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Other Details</div>
            <Form id="file-other-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Other Loan Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyRehabilitated">
                    <Form.Label>
                      Any Rehabilitated Loans received previously{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyRehabilitated"
                          label="Yes"
                          type="radio"
                          id={`formAnyRehabilitated-N`}
                          checked={
                            otherDetails.anyRehabilitatedLoansReceivedPreviously
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.anyRehabilitatedLoansReceivedPreviously =
                                  true
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.outstandingAmountError = ''
                              draft.bankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyRehabilitated"
                          label="No"
                          type="radio"
                          id={`formAnyRehabilitated-O`}
                          checked={
                            !otherDetails.anyRehabilitatedLoansReceivedPreviously
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.anyRehabilitatedLoansReceivedPreviously =
                                  false
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.outstandingAmountError = ''
                              draft.bankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {otherDetails.anyRehabilitatedLoansReceivedPreviously && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formOutStandingAmount">
                        <Form.Label>
                          Outstanding Amount{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <PriceInputComponent
                          isInvalid={!!errors.outstandingAmountError}
                          value={otherDetails.outstandingAmount}
                          placeholder="Enter Outstanding Amount"
                          onChange={(price: string) => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.outstandingAmount = parseFloat(price)
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const newErrors = produce(errors, (draft) => {
                              draft.outstandingAmountError = validateForm({
                                type: 'number',
                                maxLength: 9,
                                minValue: 1,
                                value: parseFloat(price),
                                section: 'Outstanding Amount',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onErrorHandle={() => {
                            const clearError = produce(errors, (draft) => {
                              draft.outstandingAmountError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.outstandingAmountError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formOutStandingBankName">
                        <Form.Label>
                          {`Bank Name`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.bankIdError}
                          className="form-select"
                          as="select"
                          value={otherDetails.bankId}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.bankIdError = validateForm({
                                type: 'number',
                                value: otherDetails.bankId,
                                section: 'Bank Name',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.bankId = parseInt(e.target.value)
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.bankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          <option value="0">--Select Bank--</option>
                          {bankList.map(
                            (
                              bank: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option key={`bank-${index}`} value={bank.id}>
                                  {bank.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback message={errors.bankIdError} />
                      </Form.Group>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyPropertyCom">
                    <Form.Label>
                      Request for any Property Compensation{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyPropertyCom"
                          label="Yes"
                          type="radio"
                          id={`formAnyPropertyCom-N`}
                          checked={
                            otherDetails.requestForAnyPropertyCompensation
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.requestForAnyPropertyCompensation = true
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.detailsOfTheApplicationError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyPropertyCom"
                          label="No"
                          type="radio"
                          id={`formAnyPropertyCom-O`}
                          checked={
                            !otherDetails.requestForAnyPropertyCompensation
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.requestForAnyPropertyCompensation = false
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.detailsOfTheApplicationError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {otherDetails.requestForAnyPropertyCompensation && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDetailsOfApplicant">
                        <Form.Label>
                          Details of the Application{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.detailsOfTheApplicationError}
                          type="text"
                          placeholder="Enter Details of the Application"
                          onBlur={() => {
                            if (
                              otherDetails.requestForAnyPropertyCompensation
                            ) {
                              const newErrors = produce(errors, (draft) => {
                                draft.detailsOfTheApplicationError =
                                  validateForm({
                                    type: 'text',
                                    maxLength: 50,
                                    value: otherDetails.detailsOfTheApplication,
                                    section: 'Details of the Application',
                                  } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }
                          }}
                          onChange={(e) => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.detailsOfTheApplication = e.target.value
                              }
                            )
                            setOtherDetails(newOtherDetails)
                          }}
                          value={otherDetails.detailsOfTheApplication}
                        />
                        <InvalidFeedback
                          message={errors.detailsOfTheApplicationError}
                        />
                      </Form.Group>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyPropertyDamage">
                    <Form.Label>
                      Any Property damages due to violence activities?{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyPropertyDamage"
                          label="Yes"
                          type="radio"
                          id={`formAnyPropertyDamage-N`}
                          checked={
                            otherDetails.anyPropertyDamagesDuetoViolenceActivities
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.anyPropertyDamagesDuetoViolenceActivities =
                                  true
                              }
                            )
                            setOtherDetails(newOtherDetails)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyPropertyDamage"
                          label="No"
                          type="radio"
                          id={`formAnyPropertyDamage-O`}
                          checked={
                            !otherDetails.anyPropertyDamagesDuetoViolenceActivities
                          }
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.anyPropertyDamagesDuetoViolenceActivities =
                                  false
                              }
                            )
                            setOtherDetails(newOtherDetails)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Col>

              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">
                  Self Employement Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      value={otherDetails.categoryId}
                      className="form-select"
                      as="select"
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.categoryId = parseInt(e.target.value)
                            draft.subCategoryId = null
                          }
                        )
                        getSubCategoryList(parseInt(e.target.value))
                        setOtherDetails(newOtherDetails)
                      }}
                    >
                      <option value={''}>--Select Category--</option>
                      {categoryList.map(
                        (
                          category: { categoryName: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`bank-${index}`} value={category.id}>
                              {category.categoryName}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>Sub Category</Form.Label>
                    <Form.Control
                      value={otherDetails.subCategoryId}
                      disabled={otherDetails.categoryId < 1}
                      className="form-select"
                      as="select"
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.subCategoryId = parseInt(e.target.value)
                            draft.natureOfTheSelfEmployment =
                              subCategoryList.filter(
                                (sub) => sub.id === parseInt(e.target.value)
                              )[0].value
                          }
                        )
                        console.log(newOtherDetails)
                        setOtherDetails(newOtherDetails)
                      }}
                    >
                      <option value={''}>--Select Sub Category--</option>
                      {subCategoryList
                        .filter(
                          (sub) => sub.categoryId === otherDetails.categoryId
                        )
                        .map(
                          (
                            subcategory: {
                              subCategoryName: string
                              id: number
                              categoryId: number
                            },
                            index: number
                          ) => {
                            return (
                              <option
                                key={`bank-${index}`}
                                value={subcategory.id}
                              >
                                {subcategory.subCategoryName}
                              </option>
                            )
                          }
                        )}
                    </Form.Control>
                  </Form.Group>
                  {/* <Form.Group as={Col} controlId="formNature">
                    <Form.Label>
                      Nature of the Self-Employment that apply for loan{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={true}
                      isInvalid={!!errors.natureOfTheSelfEmploymentError}
                      type="text"
                      placeholder="Enter Nature of the Self-Employment that apply for loan"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.natureOfTheSelfEmploymentError = validateForm({
                            type: 'text',
                            maxLength: 100,
                            value: otherDetails.natureOfTheSelfEmployment,
                            section:
                              'Nature of the Self-Employment that apply for loan',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.natureOfTheSelfEmployment = e.target.value
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.natureOfTheSelfEmploymentError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={otherDetails.natureOfTheSelfEmployment}
                    />
                    <InvalidFeedback
                      message={errors.natureOfTheSelfEmploymentError}
                    />
                  </Form.Group> */}
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formPreviousExp">
                    <Form.Label>
                      Have Previous experiences in self-employment that applying
                      for <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formPreviousExp"
                          label="Yes"
                          type="radio"
                          id={`formPreviousExp-N`}
                          checked={otherDetails.havePreviousExperiences}
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.havePreviousExperiences = true
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.yearOfPreviousExperiencesError = ''
                              draft.yearOfTrainedExperiencesError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formPreviousExp"
                          label="No"
                          type="radio"
                          id={`formPreviousExp-O`}
                          checked={!otherDetails.havePreviousExperiences}
                          onChange={() => {
                            const newOtherDetails = produce(
                              otherDetails,
                              (draft: OtherDetailsModel) => {
                                draft.havePreviousExperiences = false
                              }
                            )
                            setOtherDetails(newOtherDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.yearOfPreviousExperiencesError = ''
                              draft.yearOfTrainedExperiencesError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formYears">
                    <Form.Label>
                      For how many years{' '}
                      {otherDetails.havePreviousExperiences && (
                        <span className="required-field-astrix">*</span>
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.yearOfPreviousExperiencesError}
                      type="number"
                      placeholder="Enter For how many years"
                      value={otherDetails.yearOfPreviousExperiences}
                      onBlur={() => {
                        if (otherDetails.havePreviousExperiences) {
                          const newErrors = produce(errors, (draft) => {
                            draft.yearOfPreviousExperiencesError = validateForm(
                              {
                                type: 'number',
                                maxLength: 2,
                                value: otherDetails.yearOfPreviousExperiences,
                                section: 'For how many years',
                              } as ValidationObjectModel,
                              otherDetails.havePreviousExperiences
                            )
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            if (e.target.value) {
                              draft.yearOfPreviousExperiences = parseInt(
                                e.target.value
                              )
                            } else {
                              draft.yearOfPreviousExperiences = ''
                            }
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.yearOfPreviousExperiencesError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.yearOfPreviousExperiencesError}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formTrainedYears">
                    <Form.Label>
                      If trained, for how many years{' '}
                      {otherDetails.havePreviousExperiences && (
                        <span className="required-field-astrix">*</span>
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.yearOfTrainedExperiencesError}
                      type="number"
                      placeholder="Enter for how many years"
                      value={otherDetails.yearOfTrainedExperiences}
                      onBlur={() => {
                        if (otherDetails.havePreviousExperiences) {
                          const newErrors = produce(errors, (draft) => {
                            draft.yearOfTrainedExperiencesError = validateForm(
                              {
                                type: 'number',
                                maxLength: 2,
                                value: otherDetails.yearOfTrainedExperiences,
                                section: 'If trained, for how many years',
                              } as ValidationObjectModel,
                              otherDetails.havePreviousExperiences
                            )
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            if (e.target.value) {
                              draft.yearOfTrainedExperiences = parseInt(
                                e.target.value
                              )
                            } else {
                              draft.yearOfTrainedExperiences = ''
                            }
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.yearOfTrainedExperiencesError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.yearOfTrainedExperiencesError}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">Loan Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRequiredLoanAmount">
                    <Form.Label>
                      Required Loan Amount{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.requiredLoanAmountError}
                      value={otherDetails.requiredLoanAmount}
                      placeholder="Enter Required Loan Amount"
                      onChange={(price: string) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.requiredLoanAmount = parseFloat(price)
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.requiredLoanAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Required Loan Amount',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={() => {
                        const clearError = produce(errors, (draft) => {
                          draft.requiredLoanAmountError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.requiredLoanAmountError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formRepaymentPeriod">
                    <Form.Label>
                      Repayment period (in months){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.repaymentPeriodError}
                      type="number"
                      min={0}
                      placeholder="Enter Repayment period (in months)"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.repaymentPeriodError = validateForm({
                            type: 'text',
                            value: otherDetails.repaymentPeriod,
                            section: 'Repayment period (in months)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.repaymentPeriod = parseInt(e.target.value)
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.repaymentPeriodError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={otherDetails.repaymentPeriod}
                    />
                    <InvalidFeedback message={errors.repaymentPeriodError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGracePeriod">
                    <Form.Label>
                      Grace Period (in months){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.gracePeriodError}
                      type="number"
                      min={0}
                      placeholder="Enter Grace Period (in months)"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.gracePeriodError = validateForm({
                            type: 'text',
                            value: otherDetails.gracePeriod,
                            section: 'Grace Period (in months)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.gracePeriod = parseInt(e.target.value)
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.gracePeriodError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={otherDetails.gracePeriod}
                    />
                    <InvalidFeedback message={errors.gracePeriodError} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGrantedLoanAmount">
                    <Form.Label>
                      Approved Loan Amount{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.grantedLoanAmountError}
                      value={otherDetails.grantedLoanAmount}
                      placeholder="Enter Approved Loan Amount"
                      onChange={(price: string) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.grantedLoanAmount = parseFloat(price)
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.grantedLoanAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Approved Loan Amount',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={() => {
                        const clearError = produce(errors, (draft) => {
                          draft.grantedLoanAmountError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.grantedLoanAmountError} />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formBankName">
                    <Form.Label>
                      {`Bank Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.bankIdError}
                      className="form-select"
                      as="select"
                      value={otherDetails.bankId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.bankIdError = validateForm({
                            type: 'text',
                            value: otherDetails.bankId,
                            section: 'Bank Name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.bankId = parseInt(e.target.value)
                          }
                        )
                        getBranchesList(parseInt(e.target.value))
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.bankIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value="0">--Select Bank--</option>
                      {bankList.map(
                        (bank: { name: string; id: number }, index: number) => {
                          return (
                            <option key={`bank-${index}`} value={bank.id}>
                              {bank.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.bankIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBranchkName">
                    <Form.Label>
                      {`Branch Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.branchIdError}
                      className="form-select"
                      as="select"
                      disabled={
                        otherDetails.bankId == null || otherDetails.bankId == 0
                      }
                      value={otherDetails.branchId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.branchIdError = validateForm({
                            type: 'text',
                            value: otherDetails.branchId,
                            section: 'Branch Name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.branchId = parseInt(e.target.value)
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.branchIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {otherDetails.branchId == null ||
                      otherDetails.branchId == 0 ? (
                        <option value="0">--Select Branch--</option>
                      ) : (
                        ''
                      )}
                      {branchList.map(
                        (
                          branch: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`branch-${index}`} value={branch.id}>
                              {branch.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.branchIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAccountNumber">
                    <Form.Label>
                      Account Number{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.accountNumberError}
                      type="text"
                      placeholder="Enter Account Number"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.accountNumberError = validateForm({
                            type: 'text',
                            maxLength: 15,
                            value: otherDetails.accountNumber,
                            section: 'Account Number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newOtherDetails = produce(
                          otherDetails,
                          (draft: OtherDetailsModel) => {
                            draft.accountNumber = e.target.value
                          }
                        )
                        setOtherDetails(newOtherDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.accountNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={otherDetails.accountNumber}
                    />
                    <InvalidFeedback message={errors.accountNumberError} />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickNext}
                  >
                    Next
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickSave}
                  >
                    Save
                  </button>
                </>
              )}

            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default OtherDetailsSection
