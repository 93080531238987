import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class PdDataService {
  async apiFetchPdList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/pd/view/list`,
      filterData
    )
  }

  async apiFetchPdListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/pd/view/count`,
      filterData
    )
  }

  async apiFetchPdReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/pd/file-return/view/list`,
      filterData
    )
  }

  async apiFetchPdReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/pd/file-return/view/count`,
      filterData
    )
  }
  async apiApprovePdFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/pd/${id}/approve`,
      {}
    )
  }

  async apiRejectPdFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/pd/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnPdFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/pd/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdatePdFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/pd/file-return/${id}`,
      file
    )
  }
  async apiGetReturnPdFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/pd/file-return/view/${id}`
    )
  }
  async apiGetPdFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/pd/view/${id}`
    )
  }
}

export default new PdDataService()
