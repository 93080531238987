import axios, { AxiosResponse } from 'axios'
import { networkError } from '../../utils/constants'
// import { saveAs } from 'file-saver'
// import { handleNotification } from '../../utils/utilFunctions'

const http = axios.create({
  baseURL: process.env.baseUrl,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
    'Access-Control-Expose-Headers': 'Authorization',
    // Authorization: localStorage.getItem('token'),
  },
})

const httpFormData = axios.create({
  baseURL: process.env.baseUrl,
  headers: {
    'Content-type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
    'Access-Control-Expose-Headers': 'Authorization',
    // Authorization: localStorage.getItem('token'),
  },
})

const httpBinary = axios.create({
  baseURL: process.env.baseUrl,
  headers: {
    'Content-type': 'application/pdf',
    responseType: 'blob',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
    'Access-Control-Expose-Headers': 'Authorization, Content-Disposition',
    // Authorization: localStorage.getItem('token'),
  },
})

export interface ResponseObject {
  data?: any
  status?: number
  token?: string
  refreshToken?: string
  errorCode?: number
  errorMessage?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandling = (error: any) => {
  const { response } = error

  const errorObject: ResponseObject = {}
  if (response && response.status === 401) {
    const isTokenExpired = /JWT token is expired/i.test(response.data)

    errorObject.status = 401
    errorObject.errorCode = isTokenExpired ? 9 : response.data.errorCode
    errorObject.errorMessage =
      'You are not authorized to perform this operation'
    errorObject.data = {}
  } else if (response && response.status === 400) {
    // Request made and server responded
    errorObject.status = error.response.status
    errorObject.errorCode = error.response.data.errorCode
    errorObject.errorMessage = error.response.data.message
    errorObject.data = {}
  } else {
    // The request was made but no response was received
    // Something happened in setting up the request that triggered an Error
    errorObject.status = 408
    errorObject.errorMessage = networkError
  }
  // handleNotification(errorObject, '')
  return errorObject
}

const responseHandling = (response: AxiosResponse) => {
  const responseObject: ResponseObject = {
    data: response.data,
    status: response.status,
    token: response.headers['authorization'],
    refreshToken: response.headers['refreshtoken'],
  }
  return responseObject
}

const handleRefreshResponseError = (error: any) => {
  const { response } = error
  if (response.data.message == 'Invalid token.') {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.setItem('refreshToken', 'expired')
  }
}

class ApiService {
  /**
   * Api get function.
   * @param {string} path path.
   */
  async apiGet(path: string) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }

    let response = await http
      .get(path, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })

    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
          return false
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .get(path, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }

    return response
  }

  /**
   * Api post function.
   * @param {Object} body body.
   * @param {string} path path.
   */
  async apiPost(path: string, body: any) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    const json = JSON.stringify(body)
    let response = await http
      .post(path, json, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .post(path, json, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  /**
   * Api put function.
   * @param {Object} body body.
   * @param {string} path path.
   * @param {number} id id.
   */
  async apiPut(path: string, id: number, body: Record<string, unknown>) {
    const json = JSON.stringify(body)
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await http
      .put(`${path}/${id}`, json, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .put(`${path}/${id}`, json, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  /**
   * Api put function.
   * @param {Object} body body.
   * @param {string} path path.
   * @param {number} id id.
   */
  async apiPutCommon(path: string, body: any) {
    const json = JSON.stringify(body)
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await http
      .put(`${path}`, json, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .put(`${path}`, json, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  /**
   * Api delete function.
   * @param {string} path path.
   * @param {number} id id.
   */
  async apiDelete(path: string, id: number) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await http
      .delete(`${path}/${id}`, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .delete(`${path}/${id}`, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  /**
   * Api delete function.
   * @param {string} path path.
   */
  async apiDeleteCommon(path: string) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await http
      .delete(`${path}`, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .delete(`${path}`, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  async apiActiveInactive(path: string) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await http
      .put(path, {}, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .put(path, {}, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  async apiPutFormData(path: string, formData: FormData) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await httpFormData
      .put(`${path}`, formData, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })
    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .put(`${path}`, formData, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }

    return response
  }
  async apiPostBinary(path: string, body: any) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await httpBinary
      .post(`${path}`, body, { ...config })
      .then(function (response) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
        // const link = document.createElement('a')
        // link.href = fileURL
        // link.download = 'test.pdf'
        // link.click()
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })

    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .post(path, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }

  async apiDownloadFile(path: string) {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }
    let response = await httpBinary
      .get(`${path}`, { ...config })
      .then(function (response) {
        return responseHandling(response)
      })
      .catch(function (error) {
        return errorHandling(error)
      })

    if (response.errorCode === 9) {
      const isTokenRefresh = await http
        .post(
          '/auth/refresh-token',
          {
            refreshToken: localStorage.getItem('refreshToken'),
          },
          { ...config }
        )
        .then(function (refreshResponse) {
          const refreshToken = refreshResponse.data.refreshToken
          const token = refreshResponse.data.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('refreshToken', refreshToken)
          return true
        })
        .catch(function (refreshResponseError) {
          handleRefreshResponseError(refreshResponseError)
          return false
        })

      if (isTokenRefresh) {
        const refreshConfig = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }

        response = await http
          .get(path, { ...refreshConfig })
          .then(function (response) {
            return responseHandling(response)
          })
          .catch(function (error) {
            return errorHandling(error)
          })
      }
    }
    return response
  }
}

export default new ApiService()
