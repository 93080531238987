import { AffectedPropertyModel } from '../../../models/applicationRegistration/compensation/affectedPropertyModel'
import { BankDetailsModel } from '../../../models/applicationRegistration/compensation/bankDetailsModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GpcDataService {
  async apiFetchGpcList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gpc/view/list`,
      filterData
    )
  }

  async apiFetchGpcListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gpc/view/count`,
      filterData
    )
  }

  async apiFetchGpcReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gpc/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGpcReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gpc/file-return/view/count`,
      filterData
    )
  }

  async apiFetchGpcFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gpc/view/${id}`
    )
  }

  async apiGpcProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/proceed`,
      file
    )
  }

  async apiGpcModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/file-return/modify/${file.id}`,
      file
    )
  }

  async apiGpcReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/${id}/reject`,
      { remark: remark }
    )
  }

  async apiGpcReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/${id}/return`,
      { remark: remark }
    )
  }

  async apiGpcClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiGpcReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiGpcMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/${id}/merge`,
      idList
    )
  }

  async apiGpcAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/applicant`,
      file
    )
  }

  async apiGpcAddAffectedPropertyDetails(
    file: AffectedPropertyModel,
    id: number
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/${id}/affected_property`,
      file
    )
  }

  async apiGpcAddBankDetails(file: BankDetailsModel, id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/${id}/bank`,
      file
    )
  }

  async apiGetGpcAffectedPropertyData(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gpc/view/${id}/affected_property`
    )
  }
  async apiGetGpcBankData(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gpc/view/${id}/bank`
    )
  }
  async apiGpcGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gpc/view/${id}/document`
    )
  }
  async apiGpcAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/gpc/add/${id}/document`,
      formData
    )
  }

  async apiGpcRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gpc/add/${id}/register`,
      {}
    )
  }
}

export default new GpcDataService()
