import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import {
  programeTypes,
  Schemeption,
  schemesList,
  statusList,
} from '../../utils/metaData'
import FilterButton from '../common/filterButton/FilterButton'
import Select from 'react-select'
import {
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
  getProvincesOptions,
} from '../../store/administrativeArea/selectors'
import { useSelector } from 'react-redux'
import { DistrictOption } from '../../models/selectOptionModels/districtOption'
import { DsOption } from '../../models/selectOptionModels/dsOption'
import { GnOption } from '../../models/selectOptionModels/gnOption'
import metaDataService from '../../services/metaDataService'
import { CommonTypeOption } from '../../models/selectOptionModels/commonOptionType'
import ReactDatePicker from 'react-datepicker'

export interface FileAllocationFiltersProps {
  filterData: FilterOptionModel[]
  onFilter: any
  scheme?: string
}

const CompensationApplicationsFilter: FC<FileAllocationFiltersProps> = ({
  filterData,
  onFilter,
  scheme,
}) => {
  const [filters, setFilters] = useState(filterData)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [show, setShow] = useState(false)
  const [incidentTypeList, setIncidentTypeList] = useState(
    [] as CommonTypeOption[]
  )

  useEffect(() => {
    async function getData() {
      const result = await metaDataService.apiIncidentTypeList()
      const incidentTypeArray: CommonTypeOption[] = []
      for (let i = 0; i < result.data.length; i++) {
        const incidentTypeObject = {} as CommonTypeOption
        incidentTypeObject.value = result.data[i].id
        incidentTypeObject.label = result.data[i].incidentType
        incidentTypeArray.push(incidentTypeObject)
      }
      setIncidentTypeList(incidentTypeArray)
    }
    getData()
    setFilters(filterData)
  }, [show])
  return (
    <>
      <FilterButton
        onClick={() => {
          setShow(true)
        }}
      />
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false)
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="custom-modal-layout"
        contentClassName="custom-modal-content"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="custom-modal-header"
        >
          <Modal.Title id="example-modal-sizes-title-sm">{`Filter`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            {filters.length > 0 && (
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formReferenceNumber"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[0].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[0].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[0].selected}
                      type="text"
                      placeholder={`Reference Number`}
                      value={filters[0].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formProgrameType"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[19].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[19].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[19].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[19].dropdownValue}
                      isDisabled={!filters[19].selected}
                      options={programeTypes}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[19].value = selectedOption?.value
                            draft[19].dropdownValue = selectedOption
                            draft[1].value = null
                            draft[1].dropdownValue = null
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formScheme">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[1].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[1].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[1].dropdownValue}
                      isDisabled={!filters[1].selected}
                      options={schemesList.filter((scheme: Schemeption) => {
                        return scheme.programeType === filters[19].value
                      })}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].value = selectedOption?.value
                            draft[1].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="incidentType">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[2].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[2].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[2].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[2].dropdownValue}
                      isDisabled={!filters[2].selected}
                      options={incidentTypeList}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[2].value = selectedOption?.value
                            draft[2].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formNic">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[3].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[3].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[3].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[3].selected}
                      type="text"
                      placeholder={`NIC`}
                      value={filters[3].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[3].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formNic">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[18].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[18].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[18].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[18].selected}
                      type="text"
                      placeholder={`Old NIC`}
                      value={filters[18].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[18].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="passportNumber"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[4].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[4].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[4].selected}
                      type="text"
                      placeholder={`Passport Number`}
                      value={filters[4].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="SeniorCitizenID"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[5].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[5].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[5].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[5].selected}
                      type="text"
                      placeholder={`Senior Citizen ID`}
                      value={filters[5].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[5].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="drivingLicenseNumber"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[6].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[6].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[6].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[6].selected}
                      type="text"
                      placeholder={`Driving License Number`}
                      value={filters[6].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[6].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="birthCertificateNumber"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[7].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[7].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[7].label}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[7].selected}
                      type="text"
                      placeholder={`Birth Certificate Number`}
                      value={filters[7].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[7].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                {scheme == 'PD' || scheme == 'GSD' ? (
                  <>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="deathCertificateNumber"
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[8].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[8].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[8].label}
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!filters[8].selected}
                          type="text"
                          placeholder={`Death Certificate Number`}
                          value={filters[8].value}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[8].value = e.target.value
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
                {scheme === 'PD' ||
                scheme === 'PI' ||
                scheme === 'GSD' ||
                scheme === 'GSI' ? (
                  <>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="aggrievedPerson’sNIC "
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[9].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[9].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[9].label}
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!filters[9].selected}
                          type="text"
                          placeholder={`Aggrieved Person’s NIC`}
                          value={filters[9].value}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[9].value = e.target.value
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
                {scheme === 'GPC' || scheme === 'GSC' ? (
                  <>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="assetIdentificationNumber "
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[10].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[10].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[10].label}
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!filters[10].selected}
                          type="text"
                          placeholder={`Asset Identification Number`}
                          value={filters[10].value}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[10].value = e.target.value
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
                {scheme === 'RLP' ? (
                  <>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="rlpRegistrationNumber"
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[11].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[11].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[11].label}
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!filters[11].selected}
                          type="text"
                          placeholder={`RLP Registration Number`}
                          value={filters[11].value}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[11].value = e.target.value
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
                <Form.Group as={Row} className="mb-3" controlId="formProvince">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[12].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[12].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[12].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[12].dropdownValue}
                      isDisabled={!filters[12].selected}
                      options={provinceList}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[12].value = selectedOption?.value
                            draft[12].dropdownValue = selectedOption
                            draft[13].value = null
                            draft[13].dropdownValue = null
                            draft[13].selected = false
                            draft[14].value = null
                            draft[14].dropdownValue = null
                            draft[14].selected = false
                            draft[15].value = null
                            draft[15].dropdownValue = null
                            draft[15].selected = false
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formDistrict">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      disabled={filters[12].dropdownValue == null}
                      type="checkbox"
                      checked={filters[13].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[13].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[13].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[13].dropdownValue}
                      isDisabled={
                        !filters[13].selected ||
                        filters[12].dropdownValue == null
                      }
                      options={districtList.filter(
                        (district: DistrictOption) => {
                          return district.provinceId === filters[12].value
                        }
                      )}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[13].value = selectedOption?.value
                            draft[13].dropdownValue = selectedOption
                            draft[14].value = null
                            draft[14].selected = false
                            draft[14].dropdownValue = null
                            draft[15].value = null
                            draft[15].dropdownValue = null
                            draft[15].selected = false
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formDs">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      disabled={filters[13].dropdownValue == null}
                      checked={filters[14].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[14].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[14].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[14].dropdownValue}
                      isDisabled={
                        !filters[14].selected ||
                        filters[13].dropdownValue == null
                      }
                      options={dsList.filter((ds: DsOption) => {
                        return ds.districtId === filters[13].value
                      })}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[14].value = selectedOption?.value
                            draft[14].dropdownValue = selectedOption
                            draft[15].value = null
                            draft[15].dropdownValue = null
                            draft[15].selected = false
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formGN">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      disabled={filters[14].dropdownValue == null}
                      checked={filters[15].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[15].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[15].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[15].dropdownValue}
                      isDisabled={
                        !filters[15].selected ||
                        filters[14].dropdownValue == null
                      }
                      options={gnList.filter((ds: GnOption) => {
                        return ds.dsId === filters[14].value
                      })}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[15].value = selectedOption?.value
                            draft[15].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="fileReceivedDate"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[16].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[16].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[16].label}
                  </Form.Label>
                  <Col sm="6">
                    <ReactDatePicker
                      maxDate={new Date()}
                      disabled={!filters[16].selected}
                      className={'form-control'}
                      placeholderText="File Recieved Date"
                      selected={
                        filters[9].value != null
                          ? new Date(filters[16].value * 1000)
                          : null
                      }
                      onChange={(date: Date) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            const newDate = new Date(
                              `${date.getFullYear()}-${
                                date.getMonth() + 1
                              }-${date.getDate()}`
                            )

                            draft[16].value = date
                              ? newDate.getTime() / 1000
                              : null
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="status">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[17].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[17].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[17].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[17].dropdownValue}
                      isDisabled={!filters[17].selected}
                      options={statusList}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[17].value = selectedOption?.value
                            draft[17].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="save-button"
            onClick={() => {
              onFilter(filters)
              setShow(false)
            }}
          >
            Filter Results
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CompensationApplicationsFilter
