import React, { FC, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import NoDataBanner from '../../../components/common/noData/NoDataBanner'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ChequeDetailsModel } from '../../../models/chequePayments/chequeDetailsModel'
import ManageChequePaymentsService from '../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'

export interface ChequePaymentDetailsProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
}
const ChequePaymentDetails: FC<ChequePaymentDetailsProps> = ({
  fileRegistrationObject,
  onOk,
}) => {
  const [loading, setLoading] = useState(true)
  const [available, setAvailable] = useState(true)
  const [permission, setPermission] = useState(true)
  const [chequeDetailsList, setChequeDetailsList] = useState(
    [] as ChequeDetailsModel[]
  )

  useEffect(() => {
    async function getData() {
      const chequeDetailsData =
        await ManageChequePaymentsService.apiGetChequePaymentDetails(
          fileRegistrationObject.id
        )
      if (chequeDetailsData.status === 200) {
        setChequeDetailsList(chequeDetailsData.data)
      } else {
        setAvailable(false)
        if (chequeDetailsData.status === 401) {
          setPermission(false)
        }
      }
      setLoading(false)
    }
    getData()
  }, [])

  const renderChequeDetailsTable = () => {
    return (
      <ReactTableWithPaginationComponent
        columns={columns}
        data={chequeDetailsList}
        onClickRow={(row: any) => {
          console.log(row.id)
        }}
      />
    )
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Payee',
        accessor: 'payee',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'NIC Number',
        accessor: '0',
      },
      {
        Header: 'Cheque Number',
        accessor: 'chequeNumber',
      },
      {
        Header: 'Cheque Issued Date',
        accessor: 'chequeIssuedDate',
      },
      {
        Header: 'Cheque Amount (Rs.)',
        accessor: 'chequeAmount',
      },
      {
        Header: 'Voucher Number',
        accessor: 'voucherNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="mb-4">
            {!available ? (
              <NoDataBanner permission={permission} />
            ) : (
              <Row>
                {chequeDetailsList.length > 0
                  ? renderChequeDetailsTable()
                  : 'No Records Available.'}
              </Row>
            )}
          </Col>

          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default ChequePaymentDetails
