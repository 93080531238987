import { combineReducers } from 'redux'

import todoReducer from './todo/reducer'
import userManagementReducer from './usermanagement/reducer'
import userReducer from './user/reducer'
import PermissionsListReducer from './permissions/reducer'
import administrativeArea from './administrativeArea/reducer'
import breadCrumb from './breadCrumb/reducer'
import filters from './filter/reducer'
import application from './app/reducer'
import dashobardFilter from './dashboard/reducer'

const rootReducer = combineReducers({
  todo: todoReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  permissionsListState: PermissionsListReducer,
  administrativeArea: administrativeArea,
  breadCrumb: breadCrumb,
  filters: filters,
  dashboardFilters: dashobardFilter,
  application: application,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
