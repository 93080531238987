import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanRescheduleDetailsModel } from '../../../models/applicationRegistration/loan/loanRescheduleDetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import { rescheduleHistoryColumns } from '../../../utils/metaData'
import { thousandSeperator } from '../../../utils/utilFunctions'

export interface RescheduleDetailsProps {
  fileRegistrationObject: FileRegistrationModel
}

const RescheduleDetails: FC<RescheduleDetailsProps> = ({
  fileRegistrationObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [rescheduleDetailsList, setRecheduleDetailsList] = useState(
    [] as LoanRescheduleDetailsModel[]
  )
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      const rescheduleData =
        await enquireApplicationService.apiFetchRescheduleDetails(
          fileRegistrationObject.id
        )
      if (rescheduleData.status === 200) {
        setRecheduleDetailsList(rescheduleData.data)
      }
      const historyData =
        await enquireApplicationService.apiFetchRescheduleHistoryDetails(
          fileRegistrationObject.id
        )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])

  const renderRescheduleDetailsTable = () => {
    return rescheduleDetailsList.map(
      (reschedule: LoanRescheduleDetailsModel) => {
        return (
          <tr key={`reschedule-${reschedule.id}`}>
            <td>{reschedule.reschedulePeriod}</td>
            <td>{thousandSeperator(reschedule.rescheduleAmount)}</td>
            <td>
              {new Date(reschedule.rescheduleDate * 1000).toLocaleDateString()}
            </td>
            <td>{reschedule.instalmentAmount}</td>
          </tr>
        )
      }
    )
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {rescheduleDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Reschedule List</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Reschedule Period</th>
                        <th>Reschedule Amount</th>
                        <th>Reschedule Date</th>
                        <th>Installment Amount</th>
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderRescheduleDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={rescheduleHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
        </>
      )}
    </>
  )
}

export default RescheduleDetails
