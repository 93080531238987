import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class BankAndBranchServices {
  async apiFetchBankList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-bank/list`,
      filterData
    )
  }

  async apiFetchBankListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-bank/count`,
      filterData
    )
  }

  async apiAddBank(Bank: any) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-bank/add`,
      Bank
    )
  }

  async apiGetBank(id: number) {
    return apiService.apiGet(
      `/system-configuration/bank-and-branch/manage-bank/view/${id}`
    )
  }

  async apiActiveBank(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/bank-and-branch/manage-bank/${id}/active`
    )
  }

  async apiInactiveBank(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/bank-and-branch/manage-bank/${id}/inactive`
    )
  }

  async apiUpdateBank(id: any, data: any) {
    return apiService.apiPutCommon(
      `/system-configuration/bank-and-branch/manage-bank/modify/${id}`,
      data
    )
  }

  async apiFetchBranchList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-branch/list`,
      filterData
    )
  }

  async apiFetchBranchListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-branch/count`,
      filterData
    )
  }

  async apiAddBranch(Bank: any) {
    return apiService.apiPost(
      `/system-configuration/bank-and-branch/manage-branch/add`,
      Bank
    )
  }

  async apiGetBranch(id: number) {
    return apiService.apiGet(
      `/system-configuration/bank-and-branch/manage-branch/view/${id}`
    )
  }

  async apiActiveBranch(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/bank-and-branch/manage-branch/${id}/active`
    )
  }

  async apiInactiveBranch(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/bank-and-branch/manage-branch/${id}/inactive`
    )
  }

  async apiUpdateBranch(id: any, data: any) {
    return apiService.apiPutCommon(
      `/system-configuration/bank-and-branch/manage-branch/modify/${id}`,
      data
    )
  }
  async getBranchesList(id: number) {
    return apiService.apiGet(`/meta-data/bank/${id}/branch-list`)
  }
}
export default new BankAndBranchServices()
