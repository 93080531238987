import produce from 'immer'
import { BreadCrumbModal } from '../../models/breadcrumb/breadCrumb'
import {
  BreadCrumbState,
  BreadCrumbActions,
  ADD_BREAD_CRUMB,
  ADD_BREAD_CRUMB_NAVIGATION,
  SELECT_BREAD_CRUMB,
  ADD_BREAD_CRUMB_LIST,
  RESET_BREAD_CRUMB,
} from './actionTypes'

const home: BreadCrumbModal = {
  active: true,
  name: 'Home',
  route: '/',
}

const initialState: BreadCrumbState = {
  breadcrumbs: [home],
}

export default (state = initialState, action: BreadCrumbActions) => {
  switch (action.type) {
    case ADD_BREAD_CRUMB_NAVIGATION:
      return produce(state, (draft: BreadCrumbState) => {
        draft.breadcrumbs = [home]
        const route = `${draft.breadcrumbs[0].route}${action.payload.breadcrumb.route}`
        action.payload.breadcrumb.route = route
        draft.breadcrumbs.push(action.payload.breadcrumb)
      })
    case RESET_BREAD_CRUMB:
      return produce(state, (draft: BreadCrumbState) => {
        draft.breadcrumbs = [home]
      })
    case ADD_BREAD_CRUMB:
      return produce(state, (draft: BreadCrumbState) => {
        const lastIndex = draft.breadcrumbs.length - 1
        const route = `${draft.breadcrumbs[lastIndex].route}/${action.payload.breadcrumb.route}`
        action.payload.breadcrumb.route = route
        draft.breadcrumbs.push(action.payload.breadcrumb)
      })
    case ADD_BREAD_CRUMB_LIST:
      return produce(state, (draft: BreadCrumbState) => {
        draft.breadcrumbs = action.payload.breadcrumbs
      })
    case SELECT_BREAD_CRUMB:
      return produce(state, (draft: BreadCrumbState) => {
        draft.breadcrumbs.length = action.payload.index + 1
      })
    default:
      return {
        ...state,
      }
  }
}
