import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
//import supporting_document from '../../../assets/images/systemConfiguration/supporting-document.svg'
import SupportingDocumentServices from '../../../services/systemConfiguration/supportingDocuments/supportingDocumentServices'
// import AddSupportingDocuments from './AddSupportingDocuments'
// import ViewSupportingDocument from './ViewSupportingDocument'
// import ModifySupportingDocuments from './ModifySupportingDocuments'
import { FilterModel } from '../../../models/utils/filterModel'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { supportingDocumentFilters } from '../../../utils/metaData'
import { getPageCount } from '../../../utils/tableFunctions'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import produce from 'immer'
import SupportingDocumentFilters from '../../../components/filters/SupportingDocumentFilters'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'

const SupportingDocumentsComponent = () => {
  const [supportingDocuments, setSupportingDocumentsList] = useState([])
  const pending = false
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'supporting-document')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    return !!filterdAction
    // if (filterdAction != null) {
    //   return true
    // } else {
    //   return false
    // }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: supportingDocumentFilters }))
    async function getSupportingDocumentsData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getSupportingDocumentsData()
  }, [location.pathname])
  async function retriveDataCountAndData(filterData: FilterModel) {
    const supportingDocumentCountData =
      await SupportingDocumentServices.apiFetchSupportingDocumentCount(
        filterData
      )
    setDatacount(supportingDocumentCountData.data.count)
    setPageCount(
      getPageCount(supportingDocumentCountData.data.count, filterOption.limit)
    )
    const supportingDocument =
      await SupportingDocumentServices.apiFetchSupportingDocumentList(
        filterData
      )
    setSupportingDocumentsList(supportingDocument.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retriveIncidentData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const supportingDocument =
      await SupportingDocumentServices.apiFetchSupportingDocumentList(newFilter)
    setSupportingDocumentsList(supportingDocument.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Sequential Number',
        accessor: 'sequentialNumber',
      },
      {
        Header: 'Document Name',
        accessor: 'documentNameEnglish',
      },

      {
        Header: 'Scheme',
        id: 'schemeList',
        accessor: (data: { schemeList: any[] }) => {
          const output: any[] = []
          data.schemeList.map((item) => {
            return output.push(item.schemeCode)
          })
          return output.join(', ')
        },
      },
      {
        Header: 'Mandatory',
        id: 'isMandatory',
        accessor: (data: { isMandatory: any }) => {
          if (!!data.isMandatory) {
            return 'Yes'
          } else {
            return 'No'
          }
        },
      },
      {
        Header: 'Multiple allowed',
        id: 'isMultipleAllowed',
        accessor: (data: { isMultipleAllowed: any }) => {
          if (!!data.isMultipleAllowed) {
            return 'Yes'
          } else {
            return 'No'
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}view/${id}`)
  }
  return (
    <>
      {/* <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={supporting_document} alt="" />
          <span className="m-2">Supporting Document</span>
        </Col>
      </Row> */}
      {pending ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Row>
          <Row>
            <Col sm={6}>
              {checkPermission('view') === true && !loading && (
                <SupportingDocumentFilters
                  filterData={filters}
                  onFilter={(e: FilterOptionModel[]) => {
                    dispatch(filtersSetRequest({ filters: e }))
                    retriveFilterData(
                      e.filter(
                        (filter: FilterOptionModel) => filter.selected === true
                      )
                    )
                  }}
                />
              )}
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              {checkPermission('add') === true && (
                <ActionButtonComponent name="Add" routeURL="add" />
              )}
            </Col>
          </Row>
          <Col sm={12}>
            <IterminalTableComponent
              columns={columns}
              data={supportingDocuments}
              fetchData={retriveIncidentData}
              loading={loading}
              pageCount={pageCount}
              totalCount={dataCount}
              onClickRow={(row: any) => {
                console.log(row)
                handleView(row.id)
              }}
            />
          </Col>
        </Row>

        // <Switch>
        //   <Route path={`${path}/`} exact>

        //   </Route>
        //   <Route path={`${path}/add`} component={AddSupportingDocuments} />
        //   <Route path={`${path}/view/:id`} component={ViewSupportingDocument} />
        //   <Route
        //     path={`${path}/modify/:id`}
        //     component={ModifySupportingDocuments}
        //   />
        // </Switch>
      )}
    </>
  )
}

export default SupportingDocumentsComponent
