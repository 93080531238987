import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ViewGeneralDocument.scss'

const ViewGeneralDocument = (props: any) => {
  const {
    match: { params },
  } = props
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img
            src={
              '/src/assets/images/systemConfiguration/supporting-document.svg'
            }
            alt=""
          />
          <span className="m-2"> View {params.code} General Documents</span>
        </Col>
      </Row>
      <Row className="incident-types-body-layout mt-4 p-4">
        <Col sm={12} className="border-label">
          <div className="border-label-span">
            View {params.code} General Documents
          </div>
          <Container>
            <Row>
              <Col className="general-document-label" sm={6}>
                Acknowledgment
              </Col>
              <Col>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Sinhala
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Tamil
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  English
                </button>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="general-document-label" sm={6}>
                Reminder Letters
              </Col>
              <Col>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Sinhala
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Tamil
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  English
                </button>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="general-document-label" sm={6}>
                Closing Letters
              </Col>
              <Col>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Sinhala
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Tamil
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  English
                </button>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="general-document-label" sm={6}>
                Cover Letters
              </Col>
              <Col>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Sinhala
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  Tamil
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    window.open('/src/assets/documents/acknowledgment.pdf')
                  }}
                >
                  English
                </button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  )
}

export default ViewGeneralDocument
