import React, { FC, useEffect, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import { AllocateToTypeOption } from '../../models/selectOptionModels/allocateOption'
import metaDataService from '../../services/metaDataService'
import { seAllocateUsersOptions } from '../../utils/utilFunctions'

export interface ApplicationCloseReopenModalProps {
  message: string
  type: string
  dsId: number
  onSave?: any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
}

const ApplicationCloseReopenModal: FC<ApplicationCloseReopenModalProps> = ({
  message,
  type,
  dsId,
  onSave,
  onCancel,
}) => {
  const [userList, setUserList] = useState([] as AllocateToTypeOption[])
  const [selectedUser, setSelectedUser] = useState('')
  const [remark, setRemark] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    async function getAllocateUsers() {
      const response = await metaDataService.apiGetUserListByDsDivision(dsId)
      setUserList(seAllocateUsersOptions(response.data))
    }
    if (type === 're-open') {
      getAllocateUsers()
    }
  }, [])

  const handleClick = () => {
    onSave({
      allocateTo: selectedUser,
      remark: remark,
      description: description,
    })
  }

  const checkDisabled = () => {
    if (type === 're-open' && selectedUser === '') {
      return true
    }

    if (remark === '' || description === '') {
      return true
    }

    return false
  }

  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {message}
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formAllocate">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                maxLength={50}
                type="text"
                placeholder={`Enter Remark`}
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formAllocate">
              <Form.Label>Description</Form.Label>
              <Form.Control
                maxLength={2000}
                type="text-area"
                placeholder={`Enter Description`}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              />
            </Form.Group>
          </Row>
          {type === 're-open' && (
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="formAllocate">
                <Form.Label>Allocate</Form.Label>
                <Select
                  options={userList}
                  onChange={(selectedOption) => {
                    setSelectedUser(selectedOption ? selectedOption.value : '')
                  }}
                />
              </Form.Group>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={checkDisabled() ? true : false}
          className={`${checkDisabled() ? 'disable-button' : 'save-button'}`}
          onClick={handleClick}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ApplicationCloseReopenModal
