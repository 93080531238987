import {
  SetFilterPayload,
  FiltersSetRequest,
  SET_FILTERS,
  FilterResetRequest,
  RESET_FILTERS,
  FiltersRemovedRequest,
  REMOVE_FILTERS,
  UpdateFilterPayload,
  FiltersUpdateRequest,
  UPDATE_FILTER,
} from './actionTypes'

export const filtersSetRequest = (
  payload: SetFilterPayload
): FiltersSetRequest => ({
  type: SET_FILTERS,
  payload,
})

export const filtersResetRequest = (): FilterResetRequest => ({
  type: RESET_FILTERS,
})

export const filtersRemoveRequest = (): FiltersRemovedRequest => ({
  type: REMOVE_FILTERS,
})

export const filterUpdateRequest = (
  payload: UpdateFilterPayload
): FiltersUpdateRequest => ({
  type: UPDATE_FILTER,
  payload,
})
