import React, { FC } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export interface NicTypeSelectionComponentProps {
  newNic: boolean
  isDisabled: boolean
  onChange: any
}

const NicTypeSelectionComponent: FC<NicTypeSelectionComponentProps> = ({
  newNic,
  isDisabled,
  onChange,
}) => {
  return (
    <Row>
      <Col sm={1}>
        <Form.Check
          disabled={isDisabled}
          inline
          name="formNICNumberType"
          label="New"
          type="radio"
          id={`formNICNumberType-N`}
          checked={newNic}
          onChange={() => {
            onChange(true)
          }}
        />
      </Col>
      <Col sm={1}>
        <Form.Check
          disabled={isDisabled}
          inline
          name="formNICNumberType"
          label="Old"
          type="radio"
          id={`formNICNumberType-O`}
          checked={!newNic}
          onChange={() => {
            onChange(false)
          }}
        />
      </Col>
    </Row>
  )
}

export default NicTypeSelectionComponent
