import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanNPADetailsModel } from '../../../../models/applicationRegistration/loan/loanNPADetails'
import { LoanNPADetailsErrorModel } from '../../../../models/applicationRegistration/loan/loanNPADetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import attributeService from '../../../../services/applicationRegistration/loan/attributeService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { getUserSubModuleActions } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { npaHistoryColumns } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkPermission,
  handleNotification,
  thousandSeperator,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface NPASectionSectionProps {
  fileRegistrationObject: FileRegistrationModel
  npaDetailsList: LoanNPADetailsModel[]
  onOk: any
  onUpdate: any
  onRemove: any
}

const NPASection: FC<NPASectionSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onUpdate,
  npaDetailsList,
  onRemove,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [npaDetails, setNpaDetails] = useState({} as LoanNPADetailsModel)
  const [errors, setErrors] = useState({} as LoanNPADetailsErrorModel)
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      getHistoryData()
      setLoading(false)
    }
    getData()
  }, [onRemove, onUpdate])

  const getHistoryData = async () => {
    const historyData = await attributeService.apiFetchNPAHistoryDetails(
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (historyData.status === 200) {
      setHistoryList(historyData.data)
    }
  }

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      fileRegistrationObject.scheme.toLowerCase(),
      'attribute'
    )
  )

  const findErrors = () => {
    const newErrors = {} as LoanNPADetailsErrorModel
    if (
      validateForm({
        type: 'number',
        value: npaDetails.outstandingAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.outstandingAmountError = validateForm({
        type: 'number',
        value: npaDetails.outstandingAmount,
        section: 'Outstanding Amount',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: npaDetails.npaAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.npaAmountError = validateForm({
        type: 'number',
        value: npaDetails.npaAmount,
        section: 'NPA Amount',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: npaDetails.npaNumber,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.npaNumberError = validateForm({
        type: 'number',
        value: npaDetails.npaNumber,
        section: 'NPA Number',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: npaDetails.npaRemarks,
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.npaRemarksError = validateForm(
        {
          type: text,
          value: npaDetails.npaRemarks,
          section: 'NPA Remark',
        } as ValidationObjectModel,
        false
      )
    }
    return newErrors
  }

  const handleClear = () => {
    document.getElementById('file-npa-details-form').reset()

    const resetNPA = {
      applicationFileId: fileRegistrationObject.id,
    } as LoanNPADetailsModel

    const resetNPAData = produce(resetNPA, (draft) => {
      draft.outstandingAmount = ''
      draft.npaAmount = ''
      draft.npaNumber = null
      draft.npaRemarks = null
    })
    setNpaDetails(resetNPAData)
    setErrors({})
  }

  const handleAdd = async (action: string) => {
    console.log(action)
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      dispatch(changeLoadingRequest())
      let npaDataAdd = {} as ResponseObject
      if (action === 'add') {
        npaDataAdd = await attributeService.apiAddNPADetails(
          npaDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        npaDataAdd = await attributeService.apiModifyNPADetail(
          npaDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      }
      handleNotification(
        npaDataAdd,
        action === 'add' ? 'NPA Added Successfully' : 'NPA Updated Successfully'
      )
      if (npaDataAdd.status == 200) {
        handleClear()
        onUpdate(npaDataAdd.data)
      }
      dispatch(changeLoadingRequest())
    }
  }

  const handleRemove = async (npaId: number) => {
    dispatch(changeLoadingRequest())
    const npaData = await attributeService.apiDeleteNPADetail(
      npaId,
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    handleNotification(npaData, 'NPA Removed Successfully')
    if (npaData.status == 204) {
      handleClear()
      onRemove()
    }
    dispatch(changeLoadingRequest())
  }

  const renderNPADetailsTable = () => {
    return npaDetailsList.map((npa: LoanNPADetailsModel) => {
      return (
        <tr key={`npa-${npa.id}`}>
          <td>{thousandSeperator(npa.outstandingAmount)}</td>
          <td>{thousandSeperator(npa.npaAmount)}</td>
          <td>{npa.npaNumber}</td>
          <td>{npa.npaRemarks}</td>
          <td>
            <Form.Check
              disabled={true}
              checked={npa.legalActionTaken}
              type="checkbox"
              className="mb-2"
            />
          </td>
          <td>
            <Form.Check
              disabled={true}
              checked={npa.guarantorPayment}
              type="checkbox"
              className="mb-2"
            />
          </td>
          {npa.active &&
            (fileRegistrationObject.status === 'Loan NPA' ||
              fileRegistrationObject.status === 'Legal Action taken') &&
            checkPermission('modify', attributeActionListSubModule) && (
              <td>
                <button
                  className="save-button custom-margin-right"
                  type="button"
                  onClick={() => {
                    setNpaDetails(npa)
                  }}
                >
                  Edit
                </button>
                <button
                  className="save-button custom-margin-right"
                  type="button"
                  onClick={() => {
                    handleRemove(npa.id)
                  }}
                >
                  Remove
                </button>
              </td>
            )}
        </tr>
      )
    })
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label mb-4">
            <div className="border-label-span">NPA Details</div>
            <Form id="file-npa-details-form">
              <Form.Group className="mb-3" controlId="formOutStandingAmount">
                <Form.Label>
                  Outstanding Amount{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  isInvalid={!!errors.outstandingAmountError}
                  value={npaDetails.outstandingAmount}
                  placeholder="Outstanding Amount (Rs.)"
                  onChange={(price: string) => {
                    const newFormValue = produce(
                      npaDetails,
                      (draft: LoanNPADetailsModel) => {
                        draft.outstandingAmount = parseFloat(price)
                      }
                    )
                    setNpaDetails(newFormValue)
                    const newErrors = produce(errors, (draft) => {
                      draft.outstandingAmountError = validateForm({
                        type: 'number',
                        maxLength: 9,
                        minValue: 1,
                        value: parseFloat(price),
                        section: 'Outstanding Amount (Rs.)',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newFormValue = produce(
                        npaDetails,
                        (draft: LoanNPADetailsModel) => {
                          draft.outstandingAmount = 0
                        }
                      )
                      setNpaDetails(newFormValue)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.outstandingAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                />
                <InvalidFeedback message={errors.outstandingAmountError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNpaAmount">
                <Form.Label>
                  NPA Amount <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  isInvalid={!!errors.npaAmountError}
                  value={npaDetails.npaAmount}
                  placeholder="NPA Amount (Rs.)"
                  onChange={(price: string) => {
                    const newFormValue = produce(
                      npaDetails,
                      (draft: LoanNPADetailsModel) => {
                        draft.npaAmount = parseFloat(price)
                      }
                    )
                    setNpaDetails(newFormValue)
                    const newErrors = produce(errors, (draft) => {
                      draft.npaAmountError = validateForm({
                        type: 'number',
                        maxLength: 9,
                        minValue: 1,
                        value: parseFloat(price),
                        section: 'NPA Amount (Rs.)',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newFormValue = produce(
                        npaDetails,
                        (draft: LoanNPADetailsModel) => {
                          draft.npaAmount = 0
                        }
                      )
                      setNpaDetails(newFormValue)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.npaAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                />
                <InvalidFeedback message={errors.npaAmountError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNpaNumber">
                <Form.Label>
                  NPA Number <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.npaNumberError}
                  type="text"
                  placeholder="Enter NPA Number"
                  value={npaDetails.npaNumber}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.npaNumberError = validateForm({
                        type: number,
                        maxLength: 3,
                        value: npaDetails.npaNumber,
                        section: 'NPA Number',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const numberValue =
                      e.target.value == '' ? '0' : e.target.value
                    const newFormValue = produce(
                      npaDetails,
                      (draft: LoanNPADetailsModel) => {
                        draft.npaNumber = parseInt(numberValue)
                      }
                    )
                    setNpaDetails(newFormValue)
                  }}
                />
                <InvalidFeedback message={errors.npaNumberError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNpaRemark">
                <Form.Label>
                  NPA Reamrk <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.npaRemarksError}
                  type="text"
                  placeholder="Enter NPA Reamrk"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.npaRemarksError = validateForm(
                        {
                          type: text,
                          value: npaDetails.npaRemarks,
                          section: 'NPA Reamrk',
                        } as ValidationObjectModel,
                        false
                      )
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newValue = produce(
                      npaDetails,
                      (draft: LoanNPADetailsModel) => {
                        draft.npaRemarks = e.target.value
                      }
                    )
                    setNpaDetails(newValue)
                    const clearError = produce(errors, (draft) => {
                      draft.npaRemarksError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={npaDetails.npaRemarks}
                />
                <InvalidFeedback message={errors.npaRemarksError} />
              </Form.Group>
              {npaDetails.id != null && (
                <Row className="mt-4 mb-3 ml-2">
                  <Form.Check
                    checked={npaDetails.legalActionTaken}
                    type="checkbox"
                    label="Legal action taken"
                    className="mb-2"
                    onChange={(e) => {
                      const newValue = produce(
                        npaDetails,
                        (draft: LoanNPADetailsModel) => {
                          draft.legalActionTaken = e.target.checked
                          draft.guarantorPayment = false
                        }
                      )
                      setNpaDetails(newValue)
                    }}
                  />
                  <Form.Check
                    checked={npaDetails.guarantorPayment}
                    disabled={!npaDetails.legalActionTaken}
                    type="checkbox"
                    label="Gurantor Payment"
                    className="mb-2"
                    onChange={(e) => {
                      const newValue = produce(
                        npaDetails,
                        (draft: LoanNPADetailsModel) => {
                          draft.guarantorPayment = e.target.checked
                        }
                      )
                      setNpaDetails(newValue)
                    }}
                  />
                </Row>
              )}
            </Form>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {checkPermission('modify', attributeActionListSubModule) &&
                fileRegistrationObject.status === 'Loan NPA' &&
                npaDetails.id != null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('update')
                    }}
                  >
                    Update
                  </button>
                )}
              {fileRegistrationObject.status != 'Loan NPA' &&
                checkPermission('add', attributeActionListSubModule) &&
                npaDetails.id == null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('add')
                    }}
                  >
                    Add
                  </button>
                )}
              {fileRegistrationObject.status != 'Loan NPA' &&
                checkPermission('modify', attributeActionListSubModule) &&
                npaDetails.id != null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('update')
                    }}
                  >
                    Update
                  </button>
                )}
              <button
                className="save-button custom-margin-right"
                onClick={handleClear}
              >
                Clear
              </button>
            </Col>
          </Col>
          {npaDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">NPA List</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>OutStanding Amount</th>
                        <th>NPA Amount</th>
                        <th>NPA Number</th>
                        <th>NPA Remarks</th>
                        <th>Legal Action</th>
                        <th>Gurantor Payment</th>
                        {(fileRegistrationObject.status === 'Loan NPA' ||
                          fileRegistrationObject.status ===
                            'Legal Action taken') &&
                          checkPermission(
                            'modify',
                            attributeActionListSubModule
                          ) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderNPADetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={npaHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default NPASection
