import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class SrtDataService {
  async apiFetchSrtList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/loan/srt/view/list`,
      filterData
    )
  }

  async apiFetchSrtListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/loan/srt/view/count`,
      filterData
    )
  }

  async apiFetchSrtReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/loan/srt/file-return/view/list`,
      filterData
    )
  }

  async apiFetchSrtReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/loan/srt/file-return/view/count`,
      filterData
    )
  }
  async apiApproveSrtFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/loan/srt/${id}/approve`,
      {}
    )
  }
  async apiRejectSrtFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/loan/srt/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnSrtFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/loan/srt/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateSrtFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/loan/srt/file-return/${id}`,
      file
    )
  }
  async apiGetReturnSrtFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/loan/srt/file-return/view/${id}`
    )
  }
  async apiGetSrtFile(id: number) {
    return apiService.apiGet(`/compensation-certification/loan/srt/view/${id}`)
  }
}

export default new SrtDataService()
