import { PdAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { PdDependentDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class PdDataService {
  async apiFetchPdList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pd/view/list`,
      filterData
    )
  }

  async apiFetchPdListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pd/view/count`,
      filterData
    )
  }

  async apiFetchPdReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pd/file-return/view/list`,
      filterData
    )
  }

  async apiFetchPdReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pd/file-return/view/count`,
      filterData
    )
  }

  async apiFetchPdFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pd/view/${id}`
    )
  }

  async apiPdProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/proceed`,
      file
    )
  }

  async apiPdModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/file-return/modify/${file.id}`,
      file
    )
  }

  async apiPdReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/${id}/reject`,
      { remark: remark }
    )
  }

  async apiPdReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/${id}/return`,
      { remark: remark }
    )
  }

  async apiPdClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiPdReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiPdMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/${id}/merge`,
      idList
    )
  }

  async apiPdAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/applicant`,
      file
    )
  }

  async apiPdAddAggrivedPersonDetailsDetails(
    file: PdAggrivedPersonDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/${file.applicationFileId}/aggrieved_person`,
      file
    )
  }

  async apiPdGetAggrivedPersonDetailsDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pd/view/${id}/aggrieved_person`
    )
  }

  async apiPdGetDependentDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pd/view/${id}/dependents`
    )
  }

  async apiPdAddDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/${file.applicationFileId}/dependent`,
      file
    )
  }

  async apiPdDeleteDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiDelete(
      `/application-registration/compensation/pd/add/${file.applicationFileId}/dependent`,
      file.id
    )
  }

  async apiPdAddDependantNextOfKinDetails(file: NextOfKinModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/${file.applicationFileId}/dependent/${file.dependentId}/next_of_kin`,
      file
    )
  }

  async apiPdGetDependantNextOfKinDetails(fileId: number, dependentId: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pd/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiMPGetDependantNextOfKinDetails(fileId: number, dependentId: number) {
    return apiService.apiGet(
      `/application-registration/compensation/mp/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiPdGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pd/view/${id}/document`
    )
  }

  async apiPdAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/pd/add/${id}/document`,
      formData
    )
  }

  async apiPdRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pd/add/${id}/register`,
      {}
    )
  }
}

export default new PdDataService()
