/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import {
  programeTypes,
  ProgramOption,
  Schemeption,
  schemesList,
  schemepes,
} from '../../../utils/metaData'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
} from '../../../store/administrativeArea/selectors'
import { DsOption } from '../../../models/selectOptionModels/dsOption'
import { ProvinceOption } from '../../../models/selectOptionModels/provinceOption'
import { DistrictOption } from '../../../models/selectOptionModels/districtOption'
import { GnOption } from '../../../models/selectOptionModels/gnOption'
import {
  handleDateValue,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
} from '../../../utils/utilFunctions'
import IdentificationTypesComponent from '../identificationComponent/IdentificationTypesComponent'

export interface ApplicationDataViewProps {
  fileRegistrationObject: FileRegistrationModel
  isOverAge: boolean
  isDraft?: boolean
  isSearchState?: boolean
}

const ApplicationDataView: FC<ApplicationDataViewProps> = ({
  fileRegistrationObject,
  isOverAge,
  isDraft = false,
  isSearchState = false,
}) => {
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  return (
    <Form id="file-registration-form">
      {!isSearchState && (
        <Col sm={12} className="border-label">
          <div className="border-label-span">Application Details</div>
          {fileRegistrationObject.similarRecordExist && (
            <div className="row justify-content-end">
              <div className="col-3">
                <span className="similar-record-span">
                  Similar Record Exist
                </span>
              </div>
            </div>
          )}
          <Form.Group className="mb-3" controlId="formReference">
            <Form.Label>Reference No.</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Reference No."
              value={fileRegistrationObject.referenceNumber}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Status"
              value={fileRegistrationObject.status}
            />
          </Form.Group>
        </Col>
      )}
      <Col sm={12} className="border-label mt-4">
        <div className="border-label-span">Application Type Details</div>
        <Form.Group className="mb-3" controlId="formProgramType">
          <Form.Label>Program Type</Form.Label>
          <Select
            isDisabled={true}
            value={
              programeTypes.filter((programeType: ProgramOption) => {
                if (programeType.value === fileRegistrationObject.programType)
                  return programeType
              })[0]
            }
            options={programeTypes}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSchema">
          <Form.Label>Scheme</Form.Label>
          <Select
            isDisabled={true}
            value={
              schemesList.filter(
                (scheme: Schemeption) =>
                  scheme.value === fileRegistrationObject.scheme
              )[0]
            }
            options={schemesList}
          />
        </Form.Group>

        {fileRegistrationObject.scheme == 'MP' && (
          <Form.Group className="mb-3" controlId="formSchema">
            <Form.Label>OMP Number</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={fileRegistrationObject.ompNumber}
            />
          </Form.Group>
        )}

        {fileRegistrationObject.programType != 'LOAN' && (
          <>
            <Form.Group className="mb-3" controlId="formIncidantType">
              <Form.Label>Incident Type</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Incident Type"
                value={fileRegistrationObject.incidentType?.description}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formApplicantDob">
              <Form.Label>Incident Date</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={handleDateValue(fileRegistrationObject.incidentDate)}
              />
            </Form.Group>
          </>
        )}
      </Col>
      <Col sm={12} className="border-label mt-4">
        <div className="border-label-span">Applicant Details</div>
        <Form.Group className="mb-3" controlId="formApplicantName">
          <Form.Label>Applicant Name</Form.Label>
          <Form.Control
            disabled={true}
            type="text"
            placeholder="Applicant Name"
            value={fileRegistrationObject.applicantName}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSchema">
          <Form.Label>Contact Number</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={fileRegistrationObject.contactNo}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSelection">
          <Form.Label>Identification Type</Form.Label>
          <IdentificationTypesComponent
            isDisabled={true}
            identificationType={fileRegistrationObject.identificationType}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formApplicantDob">
          <Form.Label>Applicants DoB </Form.Label>
          <Form.Control
            disabled={true}
            type="text"
            value={new Date(
              fileRegistrationObject.dateOfBirth * 1000
            ).toLocaleDateString()}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formApplicantDob">
          <Form.Label>Age</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              style={{ width: '100px' }}
              disabled={true}
              type="text"
              value={fileRegistrationObject.age}
            />
            <span>&nbsp; (Years)</span>

            <Form.Control
              className="ms-3"
              style={{ width: '100px' }}
              disabled={true}
              type="text"
              value={fileRegistrationObject.ageInMonths}
            />
            <span>&nbsp; (Months)</span>

            <Form.Control
              className="ms-3"
              style={{ width: '100px' }}
              disabled={true}
              type="text"
              value={fileRegistrationObject.ageInDays}
            />
            <span>&nbsp; (Days)</span>
          </div>
        </Form.Group>
        {fileRegistrationObject.identificationType != 'BIRTH_CERTIFICATE' && (
          <>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formNICNumber">
                <Form.Label>NIC Number</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="NIC Number"
                  value={
                    fileRegistrationObject.newNic
                      ? fileRegistrationObject.newNicNumber
                      : fileRegistrationObject.oldNicNumber
                  }
                />
              </Form.Group>
            </Row>
            {fileRegistrationObject.identificationType != 'NIC' && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formPSDNumber">
                  <Form.Label>
                    {renderIdentificationTypeName(
                      fileRegistrationObject.identificationType
                    )}
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    value={renderIdentificationTypeValue(
                      fileRegistrationObject.identificationType,
                      fileRegistrationObject
                    )}
                  />
                </Form.Group>
              </Row>
            )}
          </>
        )}

        <Form.Group className="mb-3" controlId="idNumberRemark">
          <Form.Label>ID Number Remark</Form.Label>
          <Form.Control
            disabled={true}
            type="text"
            value={fileRegistrationObject.idNumberRemark}
          />
        </Form.Group>

        {(fileRegistrationObject.scheme == 'PD' ||
          fileRegistrationObject.scheme == 'GSD') && (
          <Form.Group as={Col} controlId="certificateOfAbsence">
            <Form.Label>Certificate Of Absence</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Certificate Of Absence"
              value={fileRegistrationObject.certificateOfAbsence}
            />
          </Form.Group>
        )}
        <Col sm={12} className="border-label mt-4">
          <div className="border-label-span">Birth Certificate Details</div>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBirthCNumber">
              <Form.Label>Birth Certificate Number</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Birth Certificate Number"
                value={fileRegistrationObject.birthCertificateNumber}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBirthCDsDivision">
              <Form.Label>Ds Division of Birth Certificate</Form.Label>
              <Select
                value={
                  fileRegistrationObject.birthCertificateDsDivisionId != null
                    ? dsList.filter((ds: DsOption) => {
                        return (
                          ds.value ===
                          fileRegistrationObject.birthCertificateDsDivisionId
                        )
                      })[0]
                    : null
                }
                placeholder="Ds Division of Birth Certificate"
                isDisabled={true}
                options={dsList}
              />
            </Form.Group>
          </Row>
        </Col>
      </Col>
      {fileRegistrationObject.programType === 'COMPENSATION' &&
        fileRegistrationObject.scheme != 'MP' && (
          <Col sm={12} className="border-label mt-4">
            <div className="border-label-span">Aggrieved Person Details</div>
            {(fileRegistrationObject.scheme === 'PD' ||
              fileRegistrationObject.scheme === 'GSD') && (
              <Col sm={12} className="border-label mt-1 mb-4">
                <div className="border-label-span">
                  Death Certificate Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>Death Certificate Number</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Death Certificate Number"
                      value={fileRegistrationObject.deathCertificateNumber}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDeathCDsDivision">
                    <Form.Label>Ds Division of Death Certificate</Form.Label>
                  </Form.Group>
                  <Select
                    value={
                      dsList.filter((ds: DsOption) => {
                        return (
                          ds.value ===
                          fileRegistrationObject.deathCertificateDsDivisionId
                        )
                      })[0]
                    }
                    isDisabled={true}
                    options={dsList}
                  />
                </Row>
              </Col>
            )}
            {(fileRegistrationObject.scheme === 'PI' ||
              fileRegistrationObject.scheme === 'GSI') && (
              <>
                <Form.Group className="mb-3" controlId="formAggrievedPersonNIC">
                  <Form.Label>Aggrieved Persons NIC</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Aggrieved Persons NIC"
                    value={
                      fileRegistrationObject.aggrievedPersonNewNic
                        ? fileRegistrationObject.aggrievedPersonNewNicNumber
                        : fileRegistrationObject.aggrievedPersonOldNicNumber
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAggrievedPersonAge">
                  <Form.Label>Aggrieved Persons Age</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Aggrieved Persons Age"
                    value={fileRegistrationObject.aggrievedPersonAge}
                  />
                </Form.Group>
              </>
            )}
            {fileRegistrationObject.scheme === 'PI' && (
              <Col sm={12} className="border-label mt-4 mb-4">
                <div className="border-label-span">{`Aggrieved Person's Birth Certificate Details`}</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>{`Aggrieved Person's Birth Certificate Number`}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder={`Aggrieved Person's Birth Certificate Number`}
                      value={
                        fileRegistrationObject.aggrievedPersonBirthCertificateNumber
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAgBirthCDsDivision">
                    <Form.Label>{`DS Division of Aggrieved Person’s Birth Certificate`}</Form.Label>
                    <Select
                      value={
                        dsList.filter((ds: DsOption) => {
                          return (
                            ds.value ===
                            fileRegistrationObject.aggrievedPersonBirthCertificateDsDivisionId
                          )
                        })[0]
                      }
                      isDisabled={true}
                      options={dsList}
                    />
                  </Form.Group>
                </Row>
              </Col>
            )}
            {(fileRegistrationObject.scheme === 'GPC' ||
              fileRegistrationObject.scheme === 'GSC') && (
              <Col sm={12} className="border-label mt-4 mb-4">
                <div className="border-label-span">{`Instrument Details`}</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{`Asset Identification Type`}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder={`Asset Identification Type`}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{`Asset Identification Number`}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder={`Asset Identification Number`}
                      value={fileRegistrationObject.assetIdentificationNumber}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDeathCDsDivision">
                    <Form.Label>{`DS Division of Instrument`}</Form.Label>
                    <Select
                      value={
                        dsList.filter((ds: DsOption) => {
                          return (
                            ds.value ===
                            fileRegistrationObject.instrumentDsDivisionId
                          )
                        })[0]
                      }
                      isDisabled={true}
                      options={dsList}
                    />
                  </Form.Group>
                </Row>
              </Col>
            )}
            {fileRegistrationObject.scheme === 'RLP' && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>{`Religious Place Reg. Number`}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder={`Religious Place Reg. Number`}
                    value={fileRegistrationObject.religiousPlaceRegNumber}
                  />
                </Form.Group>
              </Row>
            )}
          </Col>
        )}
      {!isDraft && (
        <Col sm={12} className="border-label mt-4 mb-4">
          <div className="border-label-span">{`Other Details`}</div>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formProvince">
              <Form.Label>Province</Form.Label>
              <Select
                value={
                  provinceList.filter((province: ProvinceOption) => {
                    return province.value === fileRegistrationObject.provinceId
                  })[0]
                }
                isDisabled={true}
                options={provinceList}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formDeathCDsDivision">
              <Form.Label>District</Form.Label>
              <Select
                value={
                  districtList.filter((district: DistrictOption) => {
                    return district.value === fileRegistrationObject.districtId
                  })[0]
                }
                isDisabled={true}
                options={districtList}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formDeathCDsDivision">
              <Form.Label>{`DS Division`}</Form.Label>
              <Select
                value={
                  dsList.filter((ds: DsOption) => {
                    return ds.value === fileRegistrationObject.dsDivisionId
                  })[0]
                }
                isDisabled={true}
                options={dsList}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formDeathCDsDivision">
              <Form.Label>{`GN Division`}</Form.Label>
              <Select
                value={
                  gnList.filter((gn: GnOption) => {
                    return gn.value === fileRegistrationObject.gnDivisionId
                  })[0]
                }
                isDisabled={true}
                options={gnList}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formApplicantName">
            <Form.Label>File Applied Date</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              value={new Date(
                fileRegistrationObject.appliedDate * 1000
              ).toLocaleDateString()}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formApplicantName">
            <Form.Label>File Recieved Date</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              value={new Date(
                fileRegistrationObject.fileReceivedDate * 1000
              ).toLocaleDateString()}
            />
          </Form.Group>
          {fileRegistrationObject.remarksToProceedWithDuplicates && (
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="formRemark">
                <Form.Label>Remark</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  value={fileRegistrationObject.remarksToProceedWithDuplicates}
                />
              </Form.Group>
            </Row>
          )}
        </Col>
      )}
    </Form>
  )
}

export default ApplicationDataView
