import React, { useEffect, useState } from 'react'
import { AdvancedFilterModel } from '../../../models/utils/filterModel'
import { Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import produce from 'immer'
import {
  applicationStatus,
  programeTypes,
  SchemeptionReport,
} from '../../../utils/metaData'
import { IncidentTypeOption } from '../../../models/selectOptionModels/incidentTypeOption'
import metaDataService from '../../../services/metaDataService'
import Select from 'react-select'
import FilterButton from '../../common/filterButton/FilterButton'
import FilterRestButton from '../../common/filterButton/FilterRestButton'
interface Props {
  filterData: AdvancedFilterModel
  initFilterData: AdvancedFilterModel
  onFilter: (data: AdvancedFilterModel) => void
}
const AdvancedSearchTableFilters: React.FC<Props> = ({
  filterData,
  initFilterData,
  onFilter,
}) => {
  const [state, setState] = useState(filterData)
  const [schemesList, setSchemesList] = useState([] as SchemeptionReport[])
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onFilter(state)
    }
  }

  useEffect(() => {
    getMetaData()
  }, [])

  async function getMetaData() {
    const incidentTypeData = await metaDataService.apiIncidentTypeList()
    const incidentTypeTempList: IncidentTypeOption[] = []
    for (let i = 0; i < incidentTypeData.data.length; i++) {
      const incident = {} as IncidentTypeOption
      incident.value = incidentTypeData.data[i].id
      incident.label = incidentTypeData.data[i].incidentType
      incidentTypeTempList.push(incident)
    }
    setIncidentList(incidentTypeTempList)
  }

  const getSchemeListByProgrammeType = async (programeType: string) => {
    const selectedProgrameType =
      programeType === 'COMPENSATION' ? 'Compensation' : 'Loan'
    const response = await metaDataService.getSchemeListByProgramTypeAndStatus(
      selectedProgrameType,
      'Active'
    )
    if (response.status === 200) {
      const schemeList = [] as SchemeptionReport[]
      for (let index = 0; index < response.data.length; index++) {
        const element = {} as SchemeptionReport
        element.value = response.data[index].code
        element.label = response.data[index].description
        element.programeType = programeType
        schemeList.push(element)
      }
      setSchemesList(schemeList)
    }
  }

  return (
    // <div className="mb-4 d-flex">
    //   <Form.Control
    //     type="text"
    //     className="me-3"
    //     onChange={(e: any) => {
    //       const newFilter = produce(state, (draft: AdvancedFilterModel) => {
    //         draft.filterValue = e.target.value
    //       })
    //       setState(newFilter)
    //     }}
    //     onKeyDown={handleKeyPress}
    //     placeholder="Enter text to filter data..."
    //   />
    //   <FilterButton
    //     onClick={() => {
    //       onFilter(state)
    //     }}
    //   />
    // </div>

    <Col sm={12} className="filter-section-border-label mb-2">
      <Row>
        <Col sm={4}>
          <Form.Group controlId="fromProgram">
            <Form.Label>Program Type </Form.Label>
            <Select
              value={
                programeTypes.filter(
                  (p) => p.value == state.programType
                )?.[0] || ''
              }
              isDisabled
              options={programeTypes}
              onChange={(selectedOption: any) => {
                const newfilters = produce(
                  state,
                  (draft: AdvancedFilterModel) => {
                    draft.programType = selectedOption
                      ? selectedOption.value
                      : ''
                    draft.scheme = ''
                  }
                )
                getSchemeListByProgrammeType(selectedOption.value)
                setState(newfilters)
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group controlId="fromProgram">
            <Form.Label>Scheme</Form.Label>
            <Select
              value={
                schemesList.filter((p) => p.value == state.scheme)?.[0] || ''
              }
              isDisabled
              options={schemesList.filter(
                (scheme) => scheme.programeType === state.programType
              )}
              onChange={(selectedOption: any) => {
                const newfilters = produce(
                  state,
                  (draft: AdvancedFilterModel) => {
                    draft.scheme = selectedOption ? selectedOption.value : ''
                  }
                )
                getSchemeListByProgrammeType(selectedOption.value)
                setState(newfilters)
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group controlId="fromProgram">
            <Form.Label>Incident Type </Form.Label>
            <Col>
              <Select
                value={
                  incidentList.filter(
                    (p) => p.value == state.incidentTypeId
                  )?.[0] || ''
                }
                options={incidentList}
                onChange={(selectedOption: any) => {
                  const newfilters = produce(
                    state,
                    (draft: AdvancedFilterModel) => {
                      draft.incidentTypeId = selectedOption
                        ? selectedOption.value
                        : ''
                    }
                  )
                  getSchemeListByProgrammeType(selectedOption.value)
                  setState(newfilters)
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={4}>
          <Form.Group controlId="filterVal">
            <Form.Label>Filter Value </Form.Label>
            <Form.Control
              value={state.filterValue || ''}
              type="text"
              className="me-3"
              onChange={(e: any) => {
                const newFilter = produce(
                  state,
                  (draft: AdvancedFilterModel) => {
                    draft.filterValue = e.target.value
                  }
                )
                setState(newFilter)
              }}
              onKeyDown={handleKeyPress}
              placeholder="Enter text to filter data..."
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group controlId="fromProgram">
            <Form.Label>Status </Form.Label>
            <Select
              isDisabled
              options={applicationStatus}
              onChange={(selectedOption) => {
                const status = [selectedOption?.value]
                const newFilter = produce(
                  state,
                  (draft: AdvancedFilterModel) => {
                    draft.statusList = status
                  }
                )
                setState(newFilter)
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="fromProgram">
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  className={'form-control'}
                  selected={
                    state.createdDateFrom != null
                      ? new Date(state.createdDateFrom * 1000)
                      : null
                  }
                  onSelect={(date: Date) => {
                    const newFilters = produce(
                      state,
                      (draft: AdvancedFilterModel) => {
                        draft.createdDateFrom = date
                          ? new Date(date).getTime() / 1000
                          : null
                      }
                    )
                    setState(newFilters)
                  }}
                  onChange={(date: Date) => {
                    console.log(date)
                    const newFilters = produce(
                      state,
                      (draft: AdvancedFilterModel) => {
                        draft.createdDateFrom = date
                          ? new Date(date).getTime() / 1000
                          : null
                      }
                    )
                    setState(newFilters)
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="fromProgram">
                <Form.Label>To Date </Form.Label>
                <DatePicker
                  className={'form-control'}
                  selected={
                    state.createdDateTo != null
                      ? new Date(state.createdDateTo * 1000)
                      : null
                  }
                  onSelect={(date: Date) => {
                    const newFilters = produce(
                      state,
                      (draft: AdvancedFilterModel) => {
                        draft.createdDateTo = date
                          ? new Date(date).getTime() / 1000
                          : null
                      }
                    )
                    setState(newFilters)
                  }}
                  onChange={(date: Date) => {
                    console.log(date)
                    const newFilters = produce(
                      state,
                      (draft: AdvancedFilterModel) => {
                        draft.createdDateTo = date
                          ? new Date(date).getTime() / 1000
                          : null
                      }
                    )
                    setState(newFilters)
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-between">
          <FilterRestButton
            onClick={() => {
              setState(initFilterData)
              onFilter(initFilterData)
            }}
          />
          <FilterButton
            onClick={() => {
              onFilter(state)
            }}
          />
        </Col>
      </Row>
    </Col>
  )
}

export default AdvancedSearchTableFilters
