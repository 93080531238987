import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import { useDispatch, useSelector } from 'react-redux'
import { DisbursementDetailsModel } from '../../../../models/applicationRegistration/loan/disbursementDetails'
import { DisbursementDetailsErrorModel } from '../../../../models/applicationRegistration/loan/disbursementDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  checkPermission,
  FetchRequesthandleNotification,
  handleNotification,
  validateForm,
} from '../../../../utils/utilFunctions'
import ReactDatePicker from 'react-datepicker'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import metaDataService from '../../../../services/metaDataService'
import attributeService from '../../../../services/applicationRegistration/loan/attributeService'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { getUserSubModuleActions } from '../../../../store/user/selectors'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { disburstmentsHistoryColumns } from '../../../../utils/metaData'
import { LoanRepaymentDetailsModel } from '../../../../models/applicationRegistration/loan/loanRepaymentDetails'
import CommonPrintModal, {
  CommonPrintModel,
} from '../../../../components/modals/CommonPrintModal'
import { saveAs } from 'file-saver'

export interface DisbursmentDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onUpdate: any
}

const DisbursmentDetailsSection: FC<DisbursmentDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onUpdate,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [disbursmentDetails, setDisbursmentDetails] = useState(
    {} as DisbursementDetailsModel
  )
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDisbursmentPrintModal, setShowDisbursmentPrintModal] =
    useState(false)
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [errors, setErrors] = useState({} as DisbursementDetailsErrorModel)
  const [historyList, setHistoryList] = useState([])
  const [repaymentDetailsList, setRepaymentDetailsList] = useState(
    [] as LoanRepaymentDetailsModel[]
  )
  useEffect(() => {
    async function getData() {
      const disbursmentData =
        await attributeService.apiFetchDisbursementDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      const bankData = await metaDataService.getBankList()
      if (bankData.status == 200) {
        setBankList(bankData.data)
      }
      if (disbursmentData.status === 200) {
        getBranchesList(disbursmentData.data.bankId)
        setDisbursmentDetails(disbursmentData.data)
      }
      const repaymentDetailsData =
        await attributeService.apiFetchRepaymentDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      if (repaymentDetailsData.status === 200) {
        setRepaymentDetailsList(repaymentDetailsData.data)
      }
      getHistoryData()
      setLoading(false)
    }
    getData()
  }, [])

  const getHistoryData = async () => {
    const historyData =
      await attributeService.apiFetchDisbursementHistoryDetails(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
    if (historyData.status === 200) {
      setHistoryList(historyData.data)
    }
  }

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      fileRegistrationObject.scheme.toLowerCase(),
      'attribute'
    )
  )

  const findErrors = () => {
    const newErrors = {} as DisbursementDetailsErrorModel
    if (
      validateForm({
        type: 'number',
        value: disbursmentDetails.disbursementAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.disbursementAmountError = validateForm({
        type: 'number',
        value: disbursmentDetails.disbursementAmount,
        section: 'Disbursment Amount',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: disbursmentDetails.disbursementDate,
      } as ValidationObjectModel)
    ) {
      newErrors.disbursementDateError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: disbursmentDetails.disbursementDate,
        section: 'Disbursment Date',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: disbursmentDetails.reason,
      } as ValidationObjectModel)
    ) {
      newErrors.reasonError = validateForm({
        type: text,
        value: disbursmentDetails.reason,
        section: 'Reason',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        value: disbursmentDetails.bankId,
        minValue: 1,
      } as ValidationObjectModel)
    ) {
      newErrors.bankError = validateForm({
        type: number,
        value: disbursmentDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        minValue: 1,
        value: disbursmentDetails.branchId,
      } as ValidationObjectModel)
    ) {
      newErrors.branchError = validateForm({
        type: number,
        value: disbursmentDetails.branchId,
        section: 'Branch name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: disbursmentDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: text,
        value: disbursmentDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }

  const handleClear = () => {
    document.getElementById('file-disbursment-details-form').reset()

    const resetDisbursment = {
      id: disbursmentDetails.id === null ? null : disbursmentDetails.id,
      applicationFileId: fileRegistrationObject.id,
    } as DisbursementDetailsModel

    const resetDisbursmentData = produce(resetDisbursment, (draft) => {
      draft.disbursementAmount = ''
      draft.disbursementDate = null
      draft.reason = ''
      draft.bankId = null
      draft.branchId = null
      draft.accountNumber = ''
    })
    setDisbursmentDetails(resetDisbursmentData)
    setErrors({})
  }

  const handleAdd = async (action: string) => {
    const foundErrros = findErrors()
    setShowConfirm(false)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      dispatch(changeLoadingRequest())
      let disbursmentDataAdd = {} as ResponseObject
      if (action === 'add') {
        disbursmentDataAdd = await attributeService.apiAddDisbursementDetails(
          disbursmentDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        disbursmentDataAdd = await attributeService.apiModifyDisbursementDetail(
          disbursmentDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      }
      handleNotification(
        disbursmentDataAdd,
        action === 'add'
          ? 'Disbursment Added Successfully'
          : 'Disbursment Updated Successfully'
      )
      if (disbursmentDataAdd.status == 200) {
        setDisbursmentDetails(disbursmentDataAdd.data)
        onUpdate(disbursmentDataAdd.data)
        getHistoryData()
      }
      dispatch(changeLoadingRequest())
    }
  }

  const checkDisabled = () => {
    if (
      (fileRegistrationObject.status == 'Disbursement Pending' ||
        fileRegistrationObject.status == 'Loan Disbursed') &&
      repaymentDetailsList.length == 0
    ) {
      return false
    }
    return true
  }

  const handleDisbursmentPrint = (printData: CommonPrintModel) => {
    if (printData.language == 'eng') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-disbursment-letter/${printData.language}/pdf`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Disbursment letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowDisbursmentPrintModal(false)
            const fileName = `disbursment_letter_${fileRegistrationObject.id}.pdf`
            saveAs(response, fileName)
          }
        })
    } else {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-disbursment-letter/${printData.language}/docx`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'disbursment letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowDisbursmentPrintModal(false)
            const fileName = `disbursment_letter_${fileRegistrationObject.id}.docx`
            saveAs(response, fileName)
          }
        })
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label mb-4">
            <div className="border-label-span">Disbursement Details</div>
            <Form id="file-disbursment-details-form">
              <Form.Group className="mb-3" controlId="formDisbursementAmount">
                <Form.Label>
                  Amount <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  disabled={checkDisabled()}
                  isInvalid={!!errors.disbursementAmountError}
                  value={disbursmentDetails.disbursementAmount}
                  placeholder="Disbursement Amount (Rs.)"
                  onChange={(price: string) => {
                    const newDisbursementDetails = produce(
                      disbursmentDetails,
                      (draft: DisbursementDetailsModel) => {
                        draft.disbursementAmount = parseFloat(price)
                      }
                    )
                    setDisbursmentDetails(newDisbursementDetails)
                    const newErrors = produce(errors, (draft) => {
                      draft.disbursementAmountError = validateForm({
                        type: 'number',
                        maxLength: 9,
                        minValue: 1,
                        value: parseFloat(price),
                        section: 'Disbursement Amount (Rs.)',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newDisbursementDetails = produce(
                        disbursmentDetails,
                        (draft: DisbursementDetailsModel) => {
                          draft.disbursementAmount = 0
                        }
                      )
                      setDisbursmentDetails(newDisbursementDetails)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.disbursementAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                />
                <InvalidFeedback message={errors.disbursementAmountError} />
              </Form.Group>
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="formDisbursmentDate"
              >
                <Form.Label>
                  Date <span className="required-field-astrix">*</span>
                </Form.Label>
                <ReactDatePicker
                  disabled={checkDisabled()}
                  required={true}
                  maxDate={new Date()}
                  className={
                    errors.disbursementDateError
                      ? 'date-picker-validate'
                      : 'form-control'
                  }
                  placeholderText="Disbursment Date"
                  selected={
                    disbursmentDetails.disbursementDate != null
                      ? new Date(disbursmentDetails.disbursementDate * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    const newDisbursementDetails = produce(
                      disbursmentDetails,
                      (draft: DisbursementDetailsModel) => {
                        draft.disbursementDate = date
                          ? date.getTime() / 1000
                          : null
                      }
                    )
                    setDisbursmentDetails(newDisbursementDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.disbursementDateError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.disbursementDateError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDisbursmentReason">
                <Form.Label>
                  Reason <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  disabled={checkDisabled()}
                  isInvalid={!!errors.reasonError}
                  type="text"
                  placeholder="Reason"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.reasonError = validateForm({
                        type: text,
                        value: disbursmentDetails.reason,
                        section: 'Reason',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newDisbursementDetails = produce(
                      disbursmentDetails,
                      (draft: DisbursementDetailsModel) => {
                        draft.reason = e.target.value
                      }
                    )
                    setDisbursmentDetails(newDisbursementDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.reasonError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={disbursmentDetails.reason}
                />
                <InvalidFeedback message={errors.reasonError} />
              </Form.Group>
              <Col sm={12} className="border-label mt-3 mb-3">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>
                      {`Bank Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={checkDisabled()}
                      isInvalid={!!errors.bankError}
                      className="form-select"
                      as="select"
                      value={disbursmentDetails.bankId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.bankError = validateForm({
                            type: number,
                            value: disbursmentDetails.bankId,
                            section: 'Bank Name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          disbursmentDetails,
                          (draft: DisbursementDetailsModel) => {
                            draft.bankId = parseInt(e.target.value)
                            draft.branchId = null
                          }
                        )
                        getBranchesList(parseInt(e.target.value))
                        setDisbursmentDetails(newFormValue)

                        const clearError = produce(errors, (draft) => {
                          draft.bankError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value={null}>--Select Bank--</option>
                      {bankList.map(
                        (bank: { name: string; id: number }, index: number) => {
                          return (
                            <option key={`bank-${index}`} value={bank.id}>
                              {bank.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.bankError} />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formDpBankName"
                  >
                    <Form.Label>
                      {`Branch Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.branchError}
                      className="form-select"
                      as="select"
                      disabled={
                        disbursmentDetails.bankId == null ||
                        disbursmentDetails.bankId == 0 ||
                        checkDisabled()
                      }
                      value={disbursmentDetails.branchId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.branchError = validateForm({
                            type: number,
                            value: disbursmentDetails.branchId,
                            section: 'Branch name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          disbursmentDetails,
                          (draft: DisbursementDetailsModel) => {
                            draft.branchId = parseInt(e.target.value)
                          }
                        )
                        setDisbursmentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.branchError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value={null}>--Select Branch--</option>
                      {branchList.map(
                        (
                          branch: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`branch-${index}`} value={branch.id}>
                              {branch.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.branchError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpAccountNumber">
                    <Form.Label>
                      Account Number{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={checkDisabled()}
                      isInvalid={!!errors.accountNumberError}
                      type="text"
                      placeholder="Enter Account Number"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.accountNumberError = validateForm({
                            type: number,
                            value: disbursmentDetails.accountNumber,
                            section: 'Account Number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          disbursmentDetails,
                          (draft: DisbursementDetailsModel) => {
                            draft.accountNumber = e.target.value
                          }
                        )
                        setDisbursmentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.accountNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={disbursmentDetails.accountNumber}
                    />
                    <InvalidFeedback message={errors.accountNumberError} />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
            {(fileRegistrationObject.status == 'Disbursement Pending' ||
              fileRegistrationObject.status == 'Loan Disbursed') && (
              <Col sm={12} className="d-flex justify-content-end mt-4">
                {checkPermission('add', attributeActionListSubModule) &&
                  disbursmentDetails.id == null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                    >
                      Add
                    </button>
                  )}
                {checkPermission('modify', attributeActionListSubModule) &&
                  !checkDisabled() &&
                  disbursmentDetails.id != null && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                    >
                      Update
                    </button>
                  )}
                {!checkDisabled() && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={handleClear}
                  >
                    Clear
                  </button>
                )}
              </Col>
            )}
          </Col>
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={disburstmentsHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button
              className={'print-acknowledgement-button'}
              onClick={() => {
                setShowDisbursmentPrintModal(true)
              }}
            >
              Print Disbursment Letter
            </button>
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
      {showConfirm && (
        <ConfirmationModal
          name={'Confirmation'}
          message={`Do you wish to ${
            disbursmentDetails.id != null ? 'update' : 'add'
          } disbursment details?`}
          onCancel={() => {
            setShowConfirm(false)
          }}
          isRemark={false}
          onConfirm={() => {
            handleAdd(disbursmentDetails.id != null ? 'update' : 'add')
          }}
        />
      )}
      {showDisbursmentPrintModal && (
        <CommonPrintModal
          onConfirm={(data: CommonPrintModel) => {
            console.log(data)
            handleDisbursmentPrint(data)
            setShowDisbursmentPrintModal(false)
          }}
          onCancel={() => {
            setShowDisbursmentPrintModal(false)
          }}
          title={'Print Disbursment Letter'}
        />
      )}
    </>
  )
}

export default DisbursmentDetailsSection
