import produce from 'immer'
import React, { FC, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

import DatePicker from 'react-datepicker'
import { PaymentDetailsErrorModal } from '../../models/chequePayments/paymentDetailsErrorModel'
import { ValidationObjectModel } from '../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { validationError } from '../../utils/errorMessages'
import { showTotast } from '../../utils/toast'
import { validateForm } from '../../utils/utilFunctions'
import InvalidFeedback from '../common/invalidFeedback/InvalidFeedback'

export interface PaymentDetailsModal {
  postedDateTime: number
  postedReferenceNumber: string
  postedBy: string
  specialRemarks: string
}

export interface AddPaymentDetailsModalProps {
  onAdd: any
  onCancel: any
  data: PaymentDetailsModal
}
const AddPaymentDetailsModal: FC<AddPaymentDetailsModalProps> = ({
  onAdd,
  onCancel,
  data,
}) => {
  const [paymentDetails, setPaymentDetails] = useState(data)
  const [errors, setErrors] = useState({} as PaymentDetailsErrorModal)
  const findErrors = () => {
    const newErrors = {} as PaymentDetailsErrorModal
    if (
      validateForm({
        type: 'text',
        value: paymentDetails.postedDateTime,
      } as ValidationObjectModel)
    ) {
      newErrors.postedDateTimeError = validateForm({
        type: 'text',
        value: paymentDetails.postedDateTime,
        section: 'Posted date',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        maxLength: 15,
        value: paymentDetails.postedReferenceNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.postedReferenceNumberError = validateForm({
        type: 'text',
        maxLength: 15,
        value: paymentDetails.postedReferenceNumber,
        section: 'Posted reference number',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        maxLength: 50,
        value: paymentDetails.postedBy,
      } as ValidationObjectModel)
    ) {
      newErrors.postedByError = validateForm({
        type: 'text',
        maxLength: 50,
        value: paymentDetails.postedBy,
        section: 'Posted by',
      } as ValidationObjectModel)
    }
    if (paymentDetails.specialRemarks) {
      if (
        validateForm({
          type: 'text',
          maxLength: 150,
          value: paymentDetails.specialRemarks,
        } as ValidationObjectModel)
      ) {
        newErrors.specialRemarksError = validateForm({
          type: 'text',
          maxLength: 150,
          value: paymentDetails.specialRemarks,
          section: 'Special remarks',
        } as ValidationObjectModel)
      }
    }

    return newErrors
  }

  const onClickAdd = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      onAdd(paymentDetails)
    }
  }

  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Add Payment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formVoucherNumber">
            <Form.Label>
              Posted Date <span className="required-field-astrix">*</span>
            </Form.Label>
            <DatePicker
              disabled={data.postedReferenceNumber ? true : false}
              maxDate={new Date()}
              className={
                errors.postedDateTimeError
                  ? 'date-picker-validate'
                  : 'form-control'
              }
              placeholderText="Select posted date"
              selected={
                paymentDetails.postedDateTime != null
                  ? new Date(paymentDetails.postedDateTime * 1000)
                  : null
              }
              onBlur={() => {
                const newErrors = produce(errors, (draft) => {
                  draft.postedDateTimeError = validateForm({
                    type: 'text',
                    value: paymentDetails.postedDateTime,
                    section: 'Posted date',
                  } as ValidationObjectModel)
                })
                setErrors(newErrors)
              }}
              onSelect={(date: any) => {
                const newValue = produce(
                  paymentDetails,
                  (draft: PaymentDetailsModal) => {
                    draft.postedDateTime = date.getTime() / 1000
                  }
                )
                setPaymentDetails(newValue)
              }}
              onChange={(date: any) => {
                const newValue = produce(
                  paymentDetails,
                  (draft: PaymentDetailsModal) => {
                    draft.postedDateTime = date.getTime() / 1000
                  }
                )
                setPaymentDetails(newValue)
                const clearError = produce(errors, (draft) => {
                  draft.postedDateTimeError = ''
                })
                setErrors(clearError)
              }}
            />
            <InvalidFeedback message={errors.postedDateTimeError} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPostedReferenceNumber">
            <Form.Label>
              Posted Reference Number{' '}
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              disabled={data.postedReferenceNumber ? true : false}
              isInvalid={!!errors.postedReferenceNumberError}
              type="text"
              placeholder="Enter posted reference number"
              onBlur={() => {
                const newErrors = produce(errors, (draft) => {
                  draft.postedReferenceNumberError = validateForm({
                    type: 'text',
                    maxLength: 15,
                    value: paymentDetails.postedReferenceNumber,
                    section: 'Posted reference number',
                  } as ValidationObjectModel)
                })
                setErrors(newErrors)
              }}
              onChange={(e) => {
                const newValue = produce(paymentDetails, (draft) => {
                  draft.postedReferenceNumber = e.target.value
                })
                setPaymentDetails(newValue)
                const clearError = produce(errors, (draft) => {
                  draft.postedReferenceNumberError = ''
                })
                setErrors(clearError)
              }}
              value={paymentDetails.postedReferenceNumber}
            />
            <InvalidFeedback message={errors.postedReferenceNumberError} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPostedBy">
            <Form.Label>
              Posted by <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              disabled={data.postedReferenceNumber ? true : false}
              isInvalid={!!errors.postedByError}
              type="text"
              placeholder="Enter posted by officer's details"
              onBlur={() => {
                const newErrors = produce(errors, (draft) => {
                  draft.postedByError = validateForm({
                    type: 'text',
                    maxLength: 50,
                    value: paymentDetails.postedBy,
                    section: 'Posted by',
                  } as ValidationObjectModel)
                })
                setErrors(newErrors)
              }}
              onChange={(e) => {
                const newValue = produce(paymentDetails, (draft) => {
                  draft.postedBy = e.target.value
                })
                setPaymentDetails(newValue)
                const clearError = produce(errors, (draft) => {
                  draft.postedByError = ''
                })
                setErrors(clearError)
              }}
              value={paymentDetails.postedBy}
            />
            <InvalidFeedback message={errors.postedByError} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="forSpecialRemarks">
            <Form.Label>Special Remarks</Form.Label>
            <Form.Control
              disabled={data.postedReferenceNumber ? true : false}
              isInvalid={!!errors.specialRemarksError}
              type="text"
              placeholder="Enter Special remarks"
              onBlur={() => {
                if (paymentDetails.specialRemarks) {
                  const newErrors = produce(errors, (draft) => {
                    draft.specialRemarksError = validateForm({
                      type: 'text',
                      maxLength: 150,
                      value: paymentDetails.specialRemarks,
                      section: 'Special remarks',
                    } as ValidationObjectModel)
                  })
                  setErrors(newErrors)
                }
              }}
              onChange={(e) => {
                const newValue = produce(paymentDetails, (draft) => {
                  draft.specialRemarks = e.target.value
                })
                setPaymentDetails(newValue)
                const clearError = produce(errors, (draft) => {
                  draft.specialRemarksError = ''
                })
                setErrors(clearError)
              }}
              value={paymentDetails.specialRemarks}
            />
            <InvalidFeedback message={errors.specialRemarksError} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="cancel-button"
          onClick={() => {
            onCancel()
          }}
        >
          {!data.postedReferenceNumber ? ' Cancel' : 'Ok'}
        </button>
        {!data.postedReferenceNumber && (
          <button className="save-button" onClick={onClickAdd}>
            Add
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AddPaymentDetailsModal
