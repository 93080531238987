import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import GpcApplication from './GpcApplication'
import GpcFileView from './GpcFileView'
import GpcReturn from './GpcReturn'

const Gpc = () => {
  const { path } = useRouteMatch()

  const [selectedSection, setSelectedSection] = useState('gpc')

  const actionListModule = useSelector(
    getUserModuleActions('compensation-certification', 'compensation', 'gpc')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'compensation-certification',
      'compensation',
      'gpc',
      'file-return'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="gpc">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gpc')
                    }}
                    eventKey="gpc"
                  >
                    GPC Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gpc-return')
                    }}
                    eventKey="gpc-return"
                  >
                    GPC Return
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'gpc' ? (
              <>
                {checkPermission('view', actionListModule) && (
                  <GpcApplication />
                )}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && <GpcReturn />}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <GpcFileView type="view" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <GpcFileView type="file-return" {...props} />}
      />
    </Switch>
  )
}

export default Gpc
