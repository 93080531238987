export const roleCreate = 'Role successfully created'
export const userCreate = 'User successfully created'
export const userUpdate = 'User successfully updated'
export const roleModify = 'Role successfully modified'
export const roleActive = 'Role successfully activated'
export const roleInActive = 'Role successfully inactivated'
export const userActive = 'User successfully activated'
export const userBlock = 'User successfully blocked'
export const userUnblock = 'User successfully unblocked'
export const userInActive = 'User successfully inactivated'
export const schemeActive = 'Scheme successfully activated'
export const schemeInActive = 'Scheme successfully inactivated'
export const schemeModify = 'Scheme successfully modified'
export const incidentType = 'Incident Type successfully created'
export const incidentTypeModify = 'Incident Type successfully modified'
export const incidentTypeActive = 'Incident Type successfully activated'
export const incidentTypeInActive = 'Incident Type successfully inactivated'
export const injuryType = 'Injury Type successfully created'
export const injuryTypeActive = 'Injury Type successfully activated'
export const injuryTypeInActive = 'Injury Type successfully inactivated'
export const stapmpChargeAdd = 'StapmpCharge successfully created'
export const stapmpChargeAmend = 'StapmpCharge successfully modified'
export const myProfileAmendMassage = 'MyProfile successfully modified'
export const passwordChangedMassage = 'Password successfully Changed'
export const injuryTypemodified = 'Injury Type successfully modified'
export const createSupportingDocuments =
  'Supporting Document successfully created'
export const modifySupportingDocuments =
  'Supporting Document successfully modified'
export const supportingDocumentActive =
  'Supporting Document successfully activated'
export const supportingDocumentInActive =
  'Supporting Document successfully inactivated'
export const createCompensationValue = 'Compensation Value successfully created'
export const compensationValueActive =
  'Compensation Value successfully activated'
export const compensationValueInActive =
  'Compensation Value successfully inactivated'
export const compensationValuemodified =
  'Compensation Value successfully modified'
export const createRejection = 'Rejection/Return Reasons successfully created'
export const rejectionReturnActive =
  'Rejection/Return Reason successfully activated'
export const rejectionReturnInActive =
  'Rejection/Return Reason successfully inactivated'
export const modifyRejectionReturn =
  'Rejection/Return Reason successfully modified'
export const createProperty = 'Property damage type successfully created'

export const policeStationActive = 'Police station successfully activated'
export const policeStationInActive = 'Police station successfully inactivated'

export const propertyActive = 'Property damage type successfully activated'
export const propertyInActive = 'Property damage type successfully inactivated'
export const propertyModified = 'Property successfully modified'

//actions on application file
export const approved = 'Application Approved Successfully'
export const rejected = 'Application rejected Successfully'
export const returned = 'Application returned Successfully'

//cheque payments
export const chequetDetails = 'Cheque details successfully created'
export const paymentApproved = 'Cheque payments approved successfully'
export const messagePrintCheque = 'Cheque printed successfully'
export const messagePrintCoverLetter = 'Cover letter printed successfully'
export const messageReturnCheque = 'Cheque returned successfully'
export const messageChequeCancel = 'Cheque canceled successfully'
export const messageApplicationReturn = 'Application returned successfully'
export const messageModifyCheque = 'Cheque details modified successfully'
