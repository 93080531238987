import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import Select from 'react-select'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { SchemeCodeModel } from '../../../models/systemConfiguration/incidentType/schemeCodeModel'
import { FileTypeListModel } from '../../../models/systemConfiguration/supportingDocuments/fileTypeListModel'
import { SupportingDocumentsErrorModel } from '../../../models/systemConfiguration/supportingDocuments/supportingDocumentsErrorModel'
import { SupportingDocumentsModel } from '../../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import metaDataService from '../../../services/metaDataService'
import SupportingDocumentServices from '../../../services/systemConfiguration/supportingDocuments/supportingDocumentServices'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import { modifySupportingDocuments } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import * as validation from './validations'
interface ReactSelectModel {
  value: string
  label: string
}

type TParams = { id: string }
const ModifySupportingDocuments = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setLoading] = useState(true)
  const [isSchemesValid, setIsSchemesValid] = useState(true)
  const [isFileTypeValid, setIsFileTypeValid] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const Options: ReactSelectModel[] = []
  const [schemeOptions, setSchemeOptions] = useState(Options)
  const [defaultSchemeOptions, setDefaultSchemeOptions] = useState(Options)
  const [filetypesOptions, setFiletypesOptions] = useState(Options)
  const [defaultFiletypes, setDefaultFiletypes] = useState(Options)
  const [errors, setErrors] = useState({} as SupportingDocumentsErrorModel)
  const [supportingDocumentState, setSupportingDocumentState] = useState(
    {} as SupportingDocumentsModel
  )
  const history = useHistory()
  const customStylesScheme = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      borderColor: state.isFocused ? '#ddd' : isSchemesValid ? '#ddd' : 'red',
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isSchemesValid ? '#ddd' : 'red',
      },
    }),
  }
  const customStylesFileType = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      borderColor: state.isFocused ? '#ddd' : isFileTypeValid ? '#ddd' : 'red',
      '&:hover': {
        borderColor: state.isFocused
          ? '#ddd'
          : isFileTypeValid
          ? '#ddd'
          : 'red',
      },
    }),
  }
  useEffect(() => {
    async function getSchemesData() {
      const fileTypeData = await metaDataService.getFileTypeList()
      const supportingDocument =
        await SupportingDocumentServices.apiGetSupportingDocumenById(
          parseInt(match.params.id)
        )
      setSupportingDocumentState(supportingDocument.data)

      const defaultSchemeOptionList: ReactSelectModel[] = []
      for (let i = 0; i < supportingDocument.data.schemeList.length; i++) {
        const schemeOptionObject: ReactSelectModel = {
          value: supportingDocument.data.schemeList[i].schemeCode,
          label: supportingDocument.data.schemeList[i].schemeCode,
        }
        defaultSchemeOptionList.push(schemeOptionObject)
      }
      setDefaultSchemeOptions(defaultSchemeOptionList)

      const defaultFiletypesList: ReactSelectModel[] = []
      for (let i = 0; i < supportingDocument.data.fileTypeList.length; i++) {
        const defaultFiletypesObject: ReactSelectModel = {
          value: supportingDocument.data.fileTypeList[i].fileType,
          label: supportingDocument.data.fileTypeList[i].fileType,
        }
        defaultFiletypesList.push(defaultFiletypesObject)
      }
      setDefaultFiletypes(defaultFiletypesList)

      const schemeListData = await metaDataService.apiGetAllSchemesList()
      const schemeOptionList: ReactSelectModel[] = []
      for (let i = 0; i < schemeListData.data.length; i++) {
        const schemeOption: ReactSelectModel = {
          value: schemeListData.data[i].code,
          label: schemeListData.data[i].code,
        }
        schemeOptionList.push(schemeOption)
      }
      setSchemeOptions(schemeOptionList)

      const filetypesOptionsList: ReactSelectModel[] = []
      for (let i = 0; i < fileTypeData.data.length; i++) {
        const fileTypeOption: ReactSelectModel = {
          value: fileTypeData.data[i].name,
          label: fileTypeData.data[i].name,
        }
        filetypesOptionsList.push(fileTypeOption)
      }
      setFiletypesOptions(filetypesOptionsList)
      setLoading(false)
    }
    getSchemesData()
  }, [])
  const handleSchemeList = (selectedOption: any) => {
    const selectedSchemecode: SchemeCodeModel[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const schemeCodeObject = {} as SchemeCodeModel
      schemeCodeObject.schemeCode = selectedOption[i].label
      selectedSchemecode.push(schemeCodeObject)
    }
    const schemeCode = produce(supportingDocumentState, (draft) => {
      draft.schemeList = selectedSchemecode
    })
    setSupportingDocumentState(schemeCode)
  }
  const handleIsMandetary = (value: string) => {
    if (value === 'yes') {
      const newValue = produce(supportingDocumentState, (draft) => {
        draft.isMandatory = true
      })
      setSupportingDocumentState(newValue)
    } else if (value === 'no') {
      const newValue = produce(supportingDocumentState, (draft) => {
        draft.isMandatory = false
      })
      setSupportingDocumentState(newValue)
    }
  }
  const handleIMultipleAllowed = (value: string) => {
    if (value === 'yes') {
      const newValue = produce(supportingDocumentState, (draft) => {
        draft.isMultipleAllowed = true
      })
      setSupportingDocumentState(newValue)
    } else if (value === 'no') {
      const newValue = produce(supportingDocumentState, (draft) => {
        draft.isMultipleAllowed = false
      })
      setSupportingDocumentState(newValue)
    }
  }
  const handleFileType = (selectedOption: any) => {
    const fileType: FileTypeListModel[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const fileTypeObject = {} as FileTypeListModel
      fileTypeObject.fileType = selectedOption[i].label
      fileType.push(fileTypeObject)
    }
    const fileTypeList = produce(supportingDocumentState, (draft) => {
      draft.fileTypeList = fileType
    })
    setSupportingDocumentState(fileTypeList)
  }
  const findFormErrors = () => {
    const newErrors = {} as SupportingDocumentsErrorModel
    if (
      validation.validateDocumentNameEnglish(
        supportingDocumentState.documentNameEnglish
      )
    ) {
      newErrors.documentNameEnglishError =
        validation.validateDocumentNameEnglish(
          supportingDocumentState.documentNameEnglish
        )
    }

    if (
      validation.validateDocumentNameSinhala(
        supportingDocumentState.documentNameSinhala
      )
    ) {
      newErrors.documentNameSinhalaError =
        validation.validateDocumentNameSinhala(
          supportingDocumentState.documentNameSinhala
        )
    }

    if (
      validation.validateDocumentNameTamil(
        supportingDocumentState.documentNameTamil
      )
    ) {
      newErrors.documentNameTamilError = validation.validateDocumentNameTamil(
        supportingDocumentState.documentNameTamil
      )
    }
    if (validation.validateSchemeList(supportingDocumentState.schemeList)) {
      newErrors.schemeListError = validation.validateSchemeList(
        supportingDocumentState.schemeList
      )
      setIsSchemesValid(false)
    }
    if (validation.validatFileTypes(supportingDocumentState.fileTypeList)) {
      newErrors.fileTypeListError = validation.validatFileTypes(
        supportingDocumentState.fileTypeList
      )
      setIsFileTypeValid(false)
    }
    if (validation.validateFileSize(supportingDocumentState.fileSize)) {
      newErrors.fileSizeError = validation.validateFileSize(
        supportingDocumentState.fileSize
      )
    }
    return newErrors
  }
  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await SupportingDocumentServices.apiUpdateSupportingDocument(
      parseInt(match.params.id),
      supportingDocumentState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = modifySupportingDocuments
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <img
                src={
                  '/src/assets/images/systemConfiguration/supporting-document.svg'
                }
                alt=""
              />
              <span className="m-2">Supporting Documents</span>
            </Col>
          </Row>
          <Row className="incident-types-body-layout mt-4 p-4">
            <Col sm={12} className="border-label">
              <div className="border-label-span">
                Modify Supporting Documents
              </div>
              <Form>
                <Row>
                  <Col>
                    {/* <Form.Group className="mb-3" controlId="sequentialNumber"> */}
                    {/* <Form.Label>
                        Sequential Number{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        value={supportingDocumentState.sequentialNumber}
                        type="text"
                        onChange={(e) => {
                          const newValue = produce(
                            supportingDocumentState,
                            (draft) => {
                              draft.sequentialNumber = parseInt(e.target.value)
                            }
                          )
                          setSupportingDocumentState(newValue)
                        }}
                      />
                    </Form.Group> */}
                    <Form.Group
                      className="mb-3"
                      controlId="documentNameEnglish"
                    >
                      <Form.Label>
                        Document Name English{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameEnglish}
                        isInvalid={!!errors.documentNameEnglishError}
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.documentNameEnglishError =
                              validation.validateDocumentNameEnglish(
                                e.target.value
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(
                            supportingDocumentState,
                            (draft) => {
                              draft.documentNameEnglish = e.target.value
                            }
                          )
                          setSupportingDocumentState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.documentNameEnglishError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.documentNameEnglishError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="documentNameSinhala"
                    >
                      <Form.Label>
                        Document Name Sinhala{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameSinhala}
                        isInvalid={!!errors.documentNameSinhalaError}
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.documentNameSinhalaError =
                              validation.validateDocumentNameSinhala(
                                e.target.value
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(
                            supportingDocumentState,
                            (draft) => {
                              draft.documentNameSinhala = e.target.value
                            }
                          )
                          setSupportingDocumentState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.documentNameSinhalaError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.documentNameSinhalaError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="documentNameTamil">
                      <Form.Label>
                        Document Name Tamil{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameTamil}
                        isInvalid={!!errors.documentNameTamilError}
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.documentNameTamilError =
                              validation.validateDocumentNameTamil(
                                e.target.value
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(
                            supportingDocumentState,
                            (draft) => {
                              draft.documentNameTamil = e.target.value
                            }
                          )
                          setSupportingDocumentState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.documentNameTamilError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.documentNameTamilError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="scheme/s">
                      <Form.Label>
                        Scheme/s{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        placeholder="--Select Scheme--"
                        options={schemeOptions}
                        styles={customStylesScheme}
                        defaultValue={defaultSchemeOptions}
                        isMulti
                        onChange={(e) => {
                          handleSchemeList(e)
                          const newError = produce(errors, (draft) => {
                            draft.schemeListError =
                              validation.validateSchemeList(e)
                          })
                          setErrors(newError)
                          e.length === 0
                            ? setIsSchemesValid(false)
                            : setIsSchemesValid(true)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.schemeListError}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="mandatory">
                      <Form.Label>
                        Mandatory{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col sm={2}>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            value="yes"
                            name="mandatory"
                            id="radio1"
                            defaultChecked={supportingDocumentState.isMandatory}
                            onChange={(e) => {
                              handleIsMandetary(e.target.value)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="radio"
                            label="No"
                            value="no"
                            name="mandatory"
                            id="radio1"
                            defaultChecked={
                              !supportingDocumentState.isMandatory
                            }
                            onChange={(e) => {
                              handleIsMandetary(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId=" multipleallowed">
                      <Form.Label>
                        Multiple allowed{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col sm={2}>
                          <Form.Check
                            defaultChecked={
                              supportingDocumentState.isMultipleAllowed
                            }
                            type="radio"
                            label="Yes"
                            value="yes"
                            name="multipleallowed"
                            id="radio3"
                            onChange={(e) => {
                              handleIMultipleAllowed(e.target.value)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            defaultChecked={
                              !supportingDocumentState.isMultipleAllowed
                            }
                            type="radio"
                            label="No"
                            value="no"
                            name="multipleallowed"
                            id="radio4"
                            onChange={(e) => {
                              handleIMultipleAllowed(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fileType">
                      <Form.Label>
                        File Type{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        defaultValue={defaultFiletypes}
                        placeholder="--Select Scheme--"
                        options={filetypesOptions}
                        styles={customStylesFileType}
                        isMulti
                        onChange={(e) => {
                          handleFileType(e)
                          const newError = produce(errors, (draft) => {
                            draft.fileTypeListError =
                              validation.validatFileTypes(e)
                          })
                          setErrors(newError)
                          e.length === 0
                            ? setIsFileTypeValid(false)
                            : setIsFileTypeValid(true)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.fileTypeListError}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fileSize">
                      <Form.Label>File Size (MB)</Form.Label>
                      <Form.Control
                        defaultValue={supportingDocumentState.fileSize}
                        type="text"
                        isInvalid={!!errors.fileSizeError}
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.fileSizeError = validation.validateFileSize(
                              parseInt(e.target.value)
                            )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(
                            supportingDocumentState,
                            (draft) => {
                              draft.fileSize = parseFloat(e.target.value)
                            }
                          )
                          setSupportingDocumentState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.fileSizeError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.fileSizeError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="cancel-button"
                    onClick={(e: any) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="save-button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </Col>
            {showConfirmation && (
              <ConfirmationModal
                name={'Confirmation'}
                message={'Are you sure to create this incident type ?'}
                onCancel={() => {
                  setShowConfirmation(false)
                }}
                onConfirm={handleConfirm}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default ModifySupportingDocuments
