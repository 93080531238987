/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import './AdministrativeArea.scss'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import District from './District'
import { DistrictModal } from '../../../../models/administrativeArea/districtModal'

export interface ProvinceProps {
  name?: string

  districtList: DistrictModal[]
}

const Province: FC<ProvinceProps> = ({ name, districtList }) => {
  const [isSelected, setIsSelected] = useState(false)

  const renderDistrictList = () => {
    return districtList.map((district: DistrictModal, index: number) => {
      return (
        <ul className="custom-ul" key={`district-${district.districtId}`}>
          <District
            key={`district-${index}-${district.districtId}`}
            name={district.districtName}
            dsList={district.dsDivisionList}
          />
        </ul>
      )
    })
  }

  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {name}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">{renderDistrictList()}</Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Province
