import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import reportService from '../../services/report/reportService'

interface Props {
  showModal?: any
  onCancel?: any
  chequeId: number
  newNicNumber: string
}

const ChequeDetailsReportModal: FC<Props> = ({
  showModal,
  onCancel,
  chequeId,
  newNicNumber,
}) => {
  const [state, setState] = useState('')

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const getData = async () => {
    const result = await reportService.getSummaryReportByChequeId(chequeId)
    if (result.status == 200) {
      setState(result.data)
    }
  }

  const handlePrint = () => {
    const doc = new Blob([state], { type: 'application/msword' })
    // const doc = new Blob(['\ufeff', htmlContent], {
    //   type: 'application/doc',
    // })

    const docName = `${newNicNumber}.doc`
    const fileURL = URL.createObjectURL(doc)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = docName
    link.click()
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Summary Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="p-5 d-flex justify-content-center"
          dangerouslySetInnerHTML={{ __html: state }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className={`save-button`} onClick={handlePrint}>
          Print
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChequeDetailsReportModal
