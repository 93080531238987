import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import apiService from '../api/apiManager'

class ApplicationModifyService {
  async updateApplicationFile(fileRegistrationObj: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `meta-data/application-modification/remark-proceed/update`,
      fileRegistrationObj
    )
  }
}

export default new ApplicationModifyService()
