import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import system_configuration from '../../assets/images/systemConfiguration/system_configuration.svg'
import { ActionListModal } from '../../models/package/actionListModal'
import { getUserPackageActions } from '../../store/user/selectors'
import { saveAs } from 'file-saver'
import { changeLoadingRequest } from '../../store/app/actionTypes'
import {
  ALERT_SUCCESS,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  ALERT_WARNING,
} from '../../utils/constants'
import { showTotast } from '../../utils/toast'
const DatabaseManagement = () => {
  const { path } = useRouteMatch()
  const actionList = useSelector(getUserPackageActions('database-management'))
  const dispatch = useDispatch()
  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  const handleDatabaseDump = async () => {
    dispatch(changeLoadingRequest())
    const headers = {
      responseType: 'blob',
      'Content-Type': 'application/sql',
      Authorization: localStorage.getItem('token'),
    }
    await fetch(`${process.env.baseUrl}database-management/save-db-dump`, {
      headers,
    })
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          return response.blob()
        }
        return null
      })
      .then((data) => {
        if (data != null) {
          showTotast(
            ALERT_SUCCESS,
            'Database Dump Successfully',
            TOAST_POSITION_TOP_RIGHT,
            5,
            TOAST_TRANSITION_SLIDE
          )
          saveAs(data, 'db-dump.sql')
        } else {
          showTotast(
            ALERT_WARNING,
            'Database Dump UnSuccessfull',
            TOAST_POSITION_TOP_RIGHT,
            5,
            TOAST_TRANSITION_SLIDE
          )
        }
      })
      .catch(function (error) {
        showTotast(
          ALERT_WARNING,
          error.toString(),
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
      })
    dispatch(changeLoadingRequest())
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={system_configuration} alt="" />
            <span className="m-2">Database Management</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">
          {checkPermission('save-db-dump') && (
            <button className="save-button" onClick={handleDatabaseDump}>
              Dump Database
            </button>
          )}
        </Row>
      </Route>
    </Switch>
  )
}

export default DatabaseManagement
