import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { CommonTypeOption } from '../../../models/selectOptionModels/commonOptionType'
import { RejectionReturnReasonsModel } from '../../../models/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsModel'
import metaDataService from '../../../services/metaDataService'
import Select from 'react-select'
import { ActionTypeListModel } from '../../../models/systemConfiguration/rejectionReturnReasons/actionTypeListModel'
import RejectionReturnReasonsServices from '../../../services/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsServices'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { modifyRejectionReturn } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import * as validation from './validations'
import { RejectionReturnReasonsErrorModel } from '../../../models/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsErrorModel'
import { validationError } from '../../../utils/errorMessages'
type TParams = { id: string }
const ModifyRejectionReturnReasons = ({
  match,
}: RouteComponentProps<TParams>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [isValid, setisValid] = useState(true)
  const [errors, setErrors] = useState({} as RejectionReturnReasonsErrorModel)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [reasonTypeList, setReasonTypeList] = useState([])
  const options: CommonTypeOption[] = []
  const defaultOptions: CommonTypeOption[] = []
  const [actionTypeList, setActionTypeList] = useState(options)
  const [selectedActionTypes, setSelectedActionTypes] = useState(defaultOptions)
  const [rejectionReturnState, setRejectionReturnState] = useState(
    {} as RejectionReturnReasonsModel
  )
  const history = useHistory()
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      },
    }),
  }
  useEffect(() => {
    async function getMetaData() {
      const rejectionReturnData =
        await RejectionReturnReasonsServices.getRejectionReturnReasonsById(
          parseInt(match.params.id)
        )

      setRejectionReturnState(rejectionReturnData.data)
      const defaultActionTypeList: CommonTypeOption[] = []
      for (let i = 0; i < rejectionReturnData.data.actionTypeList.length; i++) {
        const actionTypeobject: CommonTypeOption = {
          value: rejectionReturnData.data.actionTypeList[i].actionType,
          label: rejectionReturnData.data.actionTypeList[i].actionType,
        }
        defaultActionTypeList.push(actionTypeobject)
      }
      setSelectedActionTypes(defaultActionTypeList)
      const reasonType = await metaDataService.getReasonTypeList()
      const actionType = await metaDataService.getActionTypeList()
      const actionTypeOptionList: CommonTypeOption[] = []
      for (let i = 0; i < actionType.data.length; i++) {
        const actionTypeOption: CommonTypeOption = {
          value: actionType.data[i].name,
          label: actionType.data[i].name,
        }
        actionTypeOptionList.push(actionTypeOption)
      }
      setReasonTypeList(reasonType.data)
      setActionTypeList(actionTypeOptionList)
    }
    getMetaData()
  }, [])
  const handeActionTypes = (selectedOption: any) => {
    setSelectedActionTypes(selectedOption)
    const actionTypes: ActionTypeListModel[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const actionTypeOption: ActionTypeListModel = {
        actionType: selectedOption[i].label,
      }
      actionTypes.push(actionTypeOption)
    }
    const newValue = produce(rejectionReturnState, (draft) => {
      draft.actionTypeList = actionTypes
    })
    setRejectionReturnState(newValue)
  }
  const findFormErrors = () => {
    const newErrors = {} as RejectionReturnReasonsErrorModel
    if (validation.validateReasonType(rejectionReturnState.reasonTypeId)) {
      newErrors.reasonTypeIdError = validation.validateReasonType(
        rejectionReturnState.reasonTypeId
      )
    }
    if (
      validation.validateReasonDescription(
        rejectionReturnState.reasonDescription
      )
    ) {
      newErrors.reasonDescriptionError = validation.validateReasonDescription(
        rejectionReturnState.reasonDescription
      )
    }
    if (
      validation.validateActionTypeList(rejectionReturnState.actionTypeList)
    ) {
      newErrors.actionTypeListError = validation.validateActionTypeList(
        rejectionReturnState.actionTypeList
      )
      setisValid(false)
    }
    return newErrors
  }
  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    console.log(rejectionReturnState)
    const result =
      await RejectionReturnReasonsServices.apiUpdateRejectionReturnReason(
        parseInt(match.params.id),
        rejectionReturnState
      )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = modifyRejectionReturn
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              Modify Rejection/Return Reasons
            </div>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="reasonType">
                    <Form.Label>Reason Type</Form.Label>
                    <Form.Control
                      value={rejectionReturnState.reasonTypeId}
                      isInvalid={!!errors.reasonTypeIdError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.reasonTypeIdError =
                            validation.validateReasonType(
                              parseInt(e.target.value)
                            )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(
                          rejectionReturnState,
                          (draft) => {
                            draft.reasonTypeId = parseInt(e.target.value)
                          }
                        )
                        setRejectionReturnState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.reasonTypeIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!rejectionReturnState.reasonTypeId ? (
                        <option value="">--Select Reason Type--</option>
                      ) : (
                        ''
                      )}
                      {reasonTypeList.map(
                        (
                          reason: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option
                              key={`reason-${index}-${reason.id}`}
                              value={reason.id}
                            >
                              {reason.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.reasonTypeIdError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="reasonDescription">
                    <Form.Label>Reason Description</Form.Label>
                    <Form.Control
                      value={rejectionReturnState.reasonDescription}
                      isInvalid={!!errors.reasonDescriptionError}
                      type="text"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.reasonDescriptionError =
                            validation.validateReasonDescription(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(
                          rejectionReturnState,
                          (draft) => {
                            draft.reasonDescription = e.target.value
                          }
                        )
                        setRejectionReturnState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.reasonDescriptionError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reasonDescriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="actionType">
                    <Form.Label>Action Type</Form.Label>
                    <Select
                      value={selectedActionTypes}
                      styles={customStyles}
                      placeholder="--Action Type--"
                      options={actionTypeList}
                      isMulti
                      onChange={(e) => {
                        handeActionTypes(e)
                        const newError = produce(errors, (draft) => {
                          draft.actionTypeListError =
                            validation.validateActionTypeList(e)
                        })
                        setErrors(newError)
                        e.length === 0 ? setisValid(false) : setisValid(true)
                      }}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.actionTypeListError}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="cancel-button"
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to create this Rejection/Return Reason ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default ModifyRejectionReturnReasons
