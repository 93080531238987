import React, { useEffect, useState } from 'react'
import { Row, Col, NavDropdown } from 'react-bootstrap'
import notification from '../../assets/images/notification.svg'
import profile from '../../assets/images/profile.svg'
import './Header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../store/user/selectors'
import { useAuth } from '../../providers/AuthProvider'
import myProfileService from '../../services/myProfile/myProfileService'
import NotificationModal from '../modals/NotificationModal'
import { useHistory } from 'react-router-dom'
import { breadCrumbNavigationAddRequest } from '../../store/breadCrumb/actions'
import { getApplicationNotificationCountState } from '../../store/app/selectors'
import { changeNotificationCountRequest } from '../../store/app/actionTypes'
import { BreadCrumbModal } from '../../models/breadcrumb/breadCrumb'

const Header = () => {
  const user = useSelector(getUser)
  const { logout } = useAuth()

  const [showNotification, setShowNotification] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const notificationCount = useSelector(getApplicationNotificationCountState)
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    async function loadCount() {
      const notificationData = await myProfileService.apiGetNotificationCount()
      if (notificationData.status === 200) {
        dispatch(changeNotificationCountRequest(notificationData.data.count))
      }
    }
    const interval = setInterval(async () => {
      const notificationData = await myProfileService.apiGetNotificationCount()
      if (notificationData.status === 200) {
        dispatch(changeNotificationCountRequest(notificationData.data.count))
      } else {
        if (localStorage.getItem('refreshToken') == 'expired') {
          logout()
        }
      }
    }, 1000 * 60)
    loadCount()
    return () => clearInterval(interval)
  }, [])

  const handleNotificationClick = async () => {
    const notificationData = await myProfileService.apiGetNotificationList()
    if (notificationData.status === 200) {
      setNotificationList(notificationData.data)
    }
    setShowNotification(true)
  }

  const handleNotificationItemClick = async (route: string) => {
    if (route != '') {
      // history.replace(`/${route}`)
      window.location.replace(`/${route}`)
    }
    const notificationData = await myProfileService.apiGetNotificationCount()
    if (notificationData.status === 200) {
      dispatch(changeNotificationCountRequest(notificationData.data.count))
    }
    setShowNotification(false)
  }
  return (
    <>
      <Row className="header-bar">
        <Col sm={6}>
          <p className="header-title">information management system</p>
        </Col>
        <Col sm={6} className="d-flex justify-content-end align-items-center">
          <p className="header-name">
            {user.firstName} {user.lastName}
          </p>
          <div
            tabIndex={0}
            onKeyPress
            role={'button'}
            onClick={handleNotificationClick}
            className="round-card"
          >
            <img src={notification} alt="" />
            <div className="notification-count">{notificationCount}</div>
          </div>
          <div className="round-card">
            <img
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role={'button'}
              onKeyPress
              src={profile}
              alt=""
              onClick={() => {
                const breadCrumb: BreadCrumbModal = {
                  name: 'My Profile',
                  route: 'my-profile',
                  active: true,
                }
                dispatch(
                  breadCrumbNavigationAddRequest({ breadcrumb: breadCrumb })
                )
                history.push('/my-profile')
              }}
            />
          </div>
          <div className="three-dots">
            <NavDropdown
              onClick={() => {
                setShowNotification(false)
              }}
              title={<FontAwesomeIcon icon={faEllipsisV} color="#FFFFFF" />}
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                // href="#action3"
                onClick={() => {
                  logout()
                }}
              >
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Col>
      </Row>
      {showNotification && (
        <NotificationModal
          notificationList={notificationList}
          onCancel={() => {
            setShowNotification(false)
          }}
          onNotificationClick={(routePath: string) => {
            handleNotificationItemClick(routePath)
          }}
        />
      )}
    </>
  )
}

export default Header
