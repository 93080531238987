import React from 'react'
import './SampleComponent.scss'

import {
  Column,
  initFilterOption,
} from '../table/advancedSearchTable/AdvancedSearchTableUtil'
import AdvancedSearchTable from '../table/advancedSearchTable/AdvancedSearchTable'
// import HTMLtoWord from './HTMLtoWord'

const SampleComponent = () => {
  const columns: Column[] = [
    { header: 'Application Reference', accessor: 'referenceNumber' },
    { header: 'Incident Type', accessor: 'incidentType.incidentType' },
    { header: 'Scheme', accessor: 'scheme' },
    { header: 'Applicant Name', accessor: 'applicantName' },
    { header: 'NIC', accessor: 'newNicNumber' },
    { header: 'Identification Type', accessor: 'identificationType' },
    { header: 'ID Number', accessor: 'idNumber' },
    { header: 'Province', accessor: 'province.name' },
    { header: 'District', accessor: 'district.name' },
    { header: 'DS Division', accessor: 'dsDivision.name' },
    { header: 'GN Division', accessor: 'gnDivision.name' },
    { header: 'File Received Date', accessor: 'fileReceivedDate' },
    { header: 'Created Date and Time', accessor: 'createdDateTime' },
    { header: 'Status', accessor: 'status' },
    {
      header: 'Actions',
      accessor: 'actions',
      // eslint-disable-next-line react/display-name
      render: (row: any): JSX.Element => (
        <button onClick={() => handleActionClick(row)}>Action</button>
      ),
    },
  ]

  const handleActionClick = (row: any) => {
    console.log(row)
  }

  return (
    <div>
      <AdvancedSearchTable
        initFilterOption={initFilterOption}
        columns={columns}
        dataApiPath={'/compensation-certification/compensation/mp/view/list'}
        countApiPath={'/compensation-certification/compensation/mp/view/count'}
        onClickRow={(row) => {
          console.log(row)
        }}
      />
    </div>
  )
}

export default SampleComponent
