import React from 'react'
import { Container, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProvinceModal } from '../../../models/administrativeArea/provinceModal'

import { getProvinces } from '../../../store/administrativeArea/selectors'
import './AdministrativeArea.scss'
import Province from './Province'

const AdministrativeArea = () => {
  const provinceList = useSelector(getProvinces)
  return (
    <Container>
      <Col>
        {provinceList.map((province: ProvinceModal, index) => {
          return (
            <ul className="custom-ul" key={`province-${province.id}`}>
              <Province
                key={`province-${index}`}
                id={province.id}
                name={province.name}
                code={province.code}
                isSelected={province.isSelected}
              />
            </ul>
          )
        })}
      </Col>
    </Container>
  )
}

export default AdministrativeArea
