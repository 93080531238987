import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { SupportingDocumentsModel } from '../../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import SupportingDocumentServices from '../../../services/systemConfiguration/supportingDocuments/supportingDocumentServices'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import {
  supportingDocumentActive,
  supportingDocumentInActive,
} from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
type TParams = { id: string }
const ViewSupportingDocument = ({ match }: RouteComponentProps<TParams>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [schemes, setSchemes] = useState()
  const [fileTypes, setFileTypes] = useState()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supportingDocumentHistory, setSupportingDocumentHistory] = useState([])
  const [supportingDocumentState, setSupportingDocumentState] = useState(
    {} as SupportingDocumentsModel
  )
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'supporting-document')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getSupportingDocumentsData() {
      const supportingDocument =
        await SupportingDocumentServices.apiGetSupportingDocumenById(
          parseInt(match.params.id)
        )
      setSupportingDocumentState(supportingDocument.data)
      const supportingDocumentHistoryData =
        await SupportingDocumentServices.apiGetSupportingDocumentsHistory(
          parseInt(match.params.id)
        )
      setSupportingDocumentHistory(supportingDocumentHistoryData.data)
      const schemeList = supportingDocument.data.schemeList.map(
        (scheme: { schemeCode: string }) => {
          return scheme.schemeCode
        }
      )
      const schemeCodeList = schemeList.join(', ')
      setSchemes(schemeCodeList)
      const fileTypeList = supportingDocument.data.fileTypeList.map(
        (filetype: { fileType: string }) => {
          return filetype.fileType
        }
      )
      const fileTypesObject = fileTypeList.join(', ')
      setFileTypes(fileTypesObject)
    }
    getSupportingDocumentsData()
  }, [])

  let isMandetary = 'No'
  if (supportingDocumentState.isMandatory === true) {
    isMandetary = 'Yes'
  }
  let isMultipleAllowed = 'No'
  if (supportingDocumentState.isMultipleAllowed === true) {
    isMultipleAllowed = 'Yes'
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Sequential Number',
        accessor: 'supportingDocumentId',
      },
      {
        Header: 'Document Name',
        accessor: 'documentName',
      },
      {
        Header: 'Mandatory',
        id: 'isMandatory',
        accessor: (data: { isMandatory: any }) => {
          if (!!data.isMandatory) {
            return 'Yes'
          } else {
            return 'No'
          }
        },
      },
      {
        Header: 'Multiple allowed',
        id: 'isMultipleAllowed',
        accessor: (data: { isMultipleAllowed: any }) => {
          if (!!data.isMultipleAllowed) {
            return 'Yes'
          } else {
            return 'No'
          }
        },
      },
      {
        Header: 'Scheme/s',
        accessor: 'schemeCodeList',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  )
  let statusAction = ''
  if (supportingDocumentState.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (supportingDocumentState.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData =
        await SupportingDocumentServices.apiInactiveSupportingDocument(
          parseInt(match.params.id)
        )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = supportingDocumentInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setSupportingDocumentState(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData =
        await SupportingDocumentServices.apiActiveSupportingDocument(
          parseInt(match.params.id)
        )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = supportingDocumentActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setSupportingDocumentState(activeData.data)
    }
    const supportingDocumentHistoryData =
      await SupportingDocumentServices.apiGetSupportingDocumentsHistory(
        parseInt(match.params.id)
      )
    setSupportingDocumentHistory(supportingDocumentHistoryData.data)
  }
  const handleModify = () => {
    history.push(
      `/system-configuration/supporting-document/modify/${match.params.id}`
    )
  }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <img
                src={
                  '/src/assets/images/systemConfiguration/supporting-document.svg'
                }
                alt=""
              />
              <span className="m-2">Supporting Documents</span>
            </Col>
          </Row>
          <Row className="incident-types-body-layout mt-4 p-4">
            <Col sm={12} className="border-label">
              <div className="border-label-span">View Supporting Document</div>
              <Form>
                <Row className="mb-4">
                  <Col sm={11}>
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={supportingDocumentState.status}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={1} className="text-align-righ mt-2">
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                      <Form.Label></Form.Label>
                      {checkPermission('inactive') ||
                      checkPermission('Active') ? (
                        <Dropdown onSelect={handleSelect}>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            Action
                          </Dropdown.Toggle>
                          <Dropdown.Menu align={{ lg: 'end' }}>
                            <Dropdown.Item eventKey={statusAction}>
                              {statusAction}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="sequentialNumber">
                      <Form.Label>Sequential Number </Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.sequentialNumber}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="documentNameEnglish"
                    >
                      <Form.Label>Document Name English</Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameEnglish}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="documentNameSinhala"
                    >
                      <Form.Label>Document Name Sinhala</Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameSinhala}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="documentNameTamil">
                      <Form.Label>Document Name Tamil</Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.documentNameTamil}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="scheme/s">
                      <Form.Label>Scheme/s</Form.Label>
                      <Form.Control type="text" value={schemes} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="mandatory">
                      <Form.Label>Mandatory</Form.Label>
                      <Form.Control type="text" value={isMandetary} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId=" multipleallowed">
                      <Form.Label>Multiple allowed </Form.Label>
                      <Form.Control
                        type="text"
                        value={isMultipleAllowed}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fileType">
                      <Form.Label>File Type</Form.Label>
                      <Form.Control type="text" value={fileTypes} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fileSize">
                      <Form.Label>File Size (MB)</Form.Label>
                      <Form.Control
                        type="text"
                        value={supportingDocumentState.fileSize}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Audit History</Form.Label>
                    {supportingDocumentHistory ? (
                      <ReactTableWithPaginationComponent
                        columns={columns}
                        data={supportingDocumentHistory}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="d-flex justify-content-end mt-4">
                    <button
                      className="ok-button"
                      onClick={(e: any) => {
                        e.preventDefault()
                        history.goBack()
                      }}
                    >
                      Ok
                    </button>
                    {checkPermission('modify') === true &&
                      supportingDocumentState.status === 'Active' && (
                        <button
                          className="modify-button"
                          onClick={handleModify}
                        >
                          Modify
                        </button>
                      )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ViewSupportingDocument
