import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import metaDataService from '../../../services/metaDataService'
import './AddTypeOfInjuries.scss'
import Select from 'react-select'
import produce from 'immer'
import { SchemeCodeModel } from '../../../models/systemConfiguration/incidentType/schemeCodeModel'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import * as validation from './validations'
import { injuryTypemodified } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { validationError } from '../../../utils/errorMessages'
import { TypeOfInjuriesModel } from '../../../models/systemConfiguration/typeOfInjuries/typeOfInjuriesModel'
import { TypeOfInjuriesErrorModel } from '../../../models/systemConfiguration/typeOfInjuries/typeOfInjuriesErrorModel'
import typeOfInjuriesServices from '../../../services/systemConfiguration/typeOfInjuries/typeOfInjuriesServices'
interface ReactSelectModel {
  value: string
  label: string
}

const initialState = {} as TypeOfInjuriesModel
type TParams = { id: string }
const ModifyTypeOfInjuries = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setLoading] = useState(true)
  const [isValid, setisValid] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [injuryTypeState, setInjuryTypeState] = useState(initialState)
  const [errors, setErrors] = useState({} as TypeOfInjuriesErrorModel)
  const [injuryCategoryList, setInjuryCategoryList] = useState([])
  const [typeofDisablement, setTypeofDisablement] = useState([])
  const [disablementPeriod, setDisablementPeriod] = useState([])
  const schemeOption: ReactSelectModel[] = []
  const [schemeOptions, setSchemeOptions] = useState(schemeOption)
  const [defaultSchemes, setDefaultSchemes] = useState(schemeOption)
  const history = useHistory()

  useEffect(() => {
    async function getInjuryTypeData() {
      const schemeData = await metaDataService.apiGetSchemeListBySchemeCategory(
        'Injury'
      )
      const typeofDisablementData =
        await metaDataService.apiGetTypeOfDisablementList()
      setTypeofDisablement(typeofDisablementData.data)
      const injuryCategoryListData =
        await metaDataService.apiGetCategoryOfInjuryList()
      setInjuryCategoryList(injuryCategoryListData.data)
      const disablementPeriodData =
        await metaDataService.apiGetDisablementPeriodList()
      setDisablementPeriod(disablementPeriodData.data)
      const injuryTypeData =
        await typeOfInjuriesServices.apiGetTypeOfInjuriesById(
          parseInt(match.params.id)
        )
      setInjuryTypeState(injuryTypeData.data)
      const defaultSchemeList: ReactSelectModel[] = []
      for (let i = 0; i < injuryTypeData.data.schemeList.length; i++) {
        const defaultschemeOption: ReactSelectModel = {
          value: injuryTypeData.data.schemeList[i].schemeCode,
          label: injuryTypeData.data.schemeList[i].schemeCode,
        }
        defaultSchemeList.push(defaultschemeOption)
      }
      setDefaultSchemes(defaultSchemeList)

      const schemeOptionList: ReactSelectModel[] = []
      for (let i = 0; i < schemeData.data.length; i++) {
        const schemeOption: ReactSelectModel = {
          value: schemeData.data[i].code,
          label: schemeData.data[i].code,
        }
        schemeOptionList.push(schemeOption)
      }
      setSchemeOptions(schemeOptionList)
      setLoading(false)
    }
    getInjuryTypeData()
  }, [])

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      },
    }),
  }
  const handleMultiselct = (selectedOption: any) => {
    setDefaultSchemes(selectedOption)
    const selectedSchemecode: SchemeCodeModel[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const schemeCodeObject = {} as SchemeCodeModel
      schemeCodeObject.schemeCode = selectedOption[i].label
      selectedSchemecode.push(schemeCodeObject)
    }
    const schemeCode = produce(injuryTypeState, (draft) => {
      draft.schemeList = selectedSchemecode
    })
    setInjuryTypeState(schemeCode)
  }

  const findFormErrors = () => {
    const newErrors = {} as TypeOfInjuriesErrorModel
    if (
      validation.validateTypeOfDisablement(injuryTypeState.typeOfDisablement)
    ) {
      newErrors.typeOfDisablementError = validation.validateTypeOfDisablement(
        injuryTypeState.typeOfDisablement
      )
    }
    if (validation.validateCategoryOfInjury(injuryTypeState.categoryOfInjury)) {
      newErrors.categoryOfInjuryError = validation.validateCategoryOfInjury(
        injuryTypeState.categoryOfInjury
      )
    }
    if (
      validation.validateInjuryDescription(injuryTypeState.injuryDescription)
    ) {
      newErrors.injuryDescriptionError = validation.validateInjuryDescription(
        injuryTypeState.injuryDescription
      )
    }
    // if (injuryTypeState.typeOfDisablement == 'Permanent') {
    //   if (
    //     validation.validateEarningCapacityLoss(
    //       injuryTypeState.earningCapacityLoss
    //     )
    //   ) {
    //     newErrors.earningCapacityLossError =
    //       validation.validateEarningCapacityLoss(
    //         injuryTypeState.earningCapacityLoss
    //       )
    //   }
    // }
    if (injuryTypeState.typeOfDisablement == 'Temporary') {
      if (
        validation.validateDisablementPeriod(injuryTypeState.disablementPeriod)
      ) {
        newErrors.disablementPeriodError = validation.validateDisablementPeriod(
          injuryTypeState.disablementPeriod
        )
      }
      if (
        validation.validateCompensationEligibility(
          injuryTypeState.compensationEligibility
        )
      ) {
        newErrors.compensationEligibilityError =
          validation.validateCompensationEligibility(
            injuryTypeState.compensationEligibility
          )
      }
    }
    if (validation.validateSchemeList(injuryTypeState.schemeList)) {
      newErrors.schemeListError = validation.validateSchemeList(
        injuryTypeState.schemeList
      )
      setisValid(false)
    }
    return newErrors
  }
  const handleSubmit = async () => {
    console.log(injuryTypeState)
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await typeOfInjuriesServices.apiUpdateInjuryType(
      injuryTypeState.id,
      injuryTypeState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = injuryTypemodified
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Modify Type of Injury</div>
            <Form>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="Form.Label>
                      typeofDisablement"
                  >
                    <Form.Label>
                      Type of Disablement{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={injuryTypeState.injuryDescription === 'Death'}
                      value={injuryTypeState.typeOfDisablement}
                      isInvalid={!!errors.typeOfDisablementError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.typeOfDisablementError =
                            validation.validateTypeOfDisablement(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(injuryTypeState, (draft) => {
                          draft.typeOfDisablement = e.target.value
                          draft.compensationEligibility = null
                          draft.earningCapacityLoss = null
                          draft.disablementPeriod = null
                        })
                        setInjuryTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.typeOfDisablementError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!injuryTypeState.typeOfDisablement ? (
                        <option value="">--Select Type of Disablement--</option>
                      ) : (
                        ''
                      )}
                      {typeofDisablement.map(
                        (type: { name: string; id: number }, index: number) => {
                          return (
                            <option
                              key={`disablement-${index}-${type.id}`}
                              value={type.name}
                            >
                              {type.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.typeOfDisablementError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="injuryCategory">
                    <Form.Label>
                      Category of Injury{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={injuryTypeState.injuryDescription === 'Death'}
                      value={injuryTypeState.categoryOfInjury}
                      isInvalid={!!errors.categoryOfInjuryError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.categoryOfInjuryError =
                            validation.validateCategoryOfInjury(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(injuryTypeState, (draft) => {
                          draft.categoryOfInjury = e.target.value
                        })
                        setInjuryTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.categoryOfInjuryError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!injuryTypeState.categoryOfInjury ? (
                        <option value="">--Select Category of Injury--</option>
                      ) : (
                        ''
                      )}
                      {injuryCategoryList.map(
                        (
                          category: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option
                              key={`category-${index}-${category.id}`}
                              value={category.name}
                            >
                              {category.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.categoryOfInjuryError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="injuryDescription">
                    <Form.Label>
                      Injury Description{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={injuryTypeState.injuryDescription === 'Death'}
                      value={injuryTypeState.injuryDescription}
                      isInvalid={!!errors.injuryDescriptionError}
                      type="text"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.injuryDescriptionError =
                            validation.validateInjuryDescription(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(injuryTypeState, (draft) => {
                          draft.injuryDescription = e.target.value
                        })
                        setInjuryTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.injuryDescriptionError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.injuryDescriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* {injuryTypeState.typeOfDisablement === 'Permanent' ? (
                    <Form.Group className="mb-3" controlId="lossOfEarning">
                      <Form.Label>
                        Percentage of Loss of Earning Capacity (%){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={injuryTypeState.injuryDescription === 'Death'}
                        value={injuryTypeState.earningCapacityLoss}
                        isInvalid={!!errors.earningCapacityLossError}
                        type="number"
                        maxLength={3}
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.earningCapacityLossError =
                              validation.validateEarningCapacityLoss(
                                parseInt(e.target.value)
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(injuryTypeState, (draft) => {
                            draft.earningCapacityLoss = parseInt(e.target.value)
                          })
                          setInjuryTypeState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.earningCapacityLossError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.earningCapacityLossError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )} */}
                  {injuryTypeState.typeOfDisablement === 'Temporary' ? (
                    <Form.Group className="mb-3" controlId="disablementPeriod">
                      <Form.Label>
                        Disablement Period{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={injuryTypeState.injuryDescription === 'Death'}
                        value={injuryTypeState.disablementPeriod}
                        isInvalid={!!errors.disablementPeriodError}
                        type="text"
                        className="form-select"
                        as="select"
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.disablementPeriodError =
                              validation.validateDisablementPeriod(
                                e.target.value
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(injuryTypeState, (draft) => {
                            draft.disablementPeriod = e.target.value
                          })
                          setInjuryTypeState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.disablementPeriodError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {!injuryTypeState.disablementPeriod ? (
                          <option value="">
                            --Select Disablement Period--
                          </option>
                        ) : (
                          ''
                        )}
                        {disablementPeriod.map(
                          (
                            Period: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option
                                key={`disablement-${index}-${Period.id}`}
                                value={Period.name}
                              >
                                {Period.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.disablementPeriodError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}
                  {injuryTypeState.typeOfDisablement === 'Temporary' ? (
                    <Form.Group
                      className="mb-3"
                      controlId="incidentDescription"
                    >
                      <Form.Label>
                        Eligible Compensation Percentage (%){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled={injuryTypeState.injuryDescription === 'Death'}
                        value={injuryTypeState.compensationEligibility}
                        isInvalid={!!errors.compensationEligibilityError}
                        type="number"
                        onBlur={(e) => {
                          const newError = produce(errors, (draft) => {
                            draft.compensationEligibilityError =
                              validation.validateCompensationEligibility(
                                parseInt(e.target.value)
                              )
                          })
                          setErrors(newError)
                        }}
                        onChange={(e) => {
                          const newValue = produce(injuryTypeState, (draft) => {
                            draft.compensationEligibility = parseInt(
                              e.target.value
                            )
                          })
                          setInjuryTypeState(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.compensationEligibilityError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.compensationEligibilityError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ''
                  )}

                  <Form.Group className="mb-3" controlId="schemeCode">
                    <Form.Label>
                      Scheme Code{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Select
                      isDisabled={injuryTypeState.injuryDescription === 'Death'}
                      value={defaultSchemes}
                      styles={customStyles}
                      placeholder="--Select Scheme--"
                      options={schemeOptions}
                      isMulti
                      onChange={(e) => {
                        handleMultiselct(e)
                        const newError = produce(errors, (draft) => {
                          draft.schemeListError =
                            validation.validateSchemeList(e)
                        })
                        setErrors(newError)
                        e.length === 0 ? setisValid(false) : setisValid(true)
                      }}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.schemeListError}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="cancel-button"
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  {' '}
                  Cancel
                </button>
                {injuryTypeState.injuryDescription !== 'Death' && (
                  <button
                    type="submit"
                    className="save-button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                )}
              </Col>
            </Row>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to update this incident type ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default ModifyTypeOfInjuries
