import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ChequeCancelReturnConfirmationModal from '../../../components/modals/ChequeCancelReturnConfirmationModal'
import { ChequeDetailsModel } from '../../../models/chequePayments/chequeDetailsModel'
import { ResponseObject } from '../../../services/api/apiManager'
import {
  messageApplicationReturn,
  messageChequeCancel,
  messagePrintCheque,
  messageReturnCheque,
} from '../../../utils/successMessages'
import {
  checkPermission,
  handleNotification,
  FetchRequesthandleNotification,
  currencyMask,
} from '../../../utils/utilFunctions'
import ChequePrintService from '../../../services/chequePayments/chequePrint/chequePrintService'
import { useHistory } from 'react-router-dom'

import ManageChequePaymentsService from '../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import {
  printCheque,
  printCoverLetter,
  chequeCancel,
  returnCheque,
  applicationReturnWithModifyIndex,
} from '../../../utils/constants'
import { useSelector } from 'react-redux'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { saveAs } from 'file-saver'
import { ClosePrintModel } from '../../../models/utils/closePrintModel'
import CoverLetterPrintModal from '../../../components/modals/CoverLetterPrintModal'
import AddPaymentDetailsModal, {
  PaymentDetailsModal,
} from '../../../components/modals/AddPaymentDetailsModal'
import ChequeDetailsReportModal from '../ChequeDetailsReportModal'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}
const ViewAndPrintCheque = (props: any) => {
  const [showReportModal, setShowReportModal] = useState(false)
  const history = useHistory()
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const {
    match: { params },
  } = props
  const [loading, setloading] = useState(true)
  const [scheme, setScheme] = useState()
  const [chequeDetails, setChequeDetails] = useState({} as ChequeDetailsModel)
  const actionList = useSelector(
    getUserSubPackageActions('cheque-payment', 'cheque-print')
  )
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [referenceNumber, setReferenceNumber] = useState()
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(
    {} as PaymentDetailsModal
  )
  useEffect(() => {
    async function getData() {
      const application = await ManageChequePaymentsService.apiGetFileDetails(
        parseInt(params.applicationFileId)
      )
      setReferenceNumber(application.data.referenceNumber)
      setScheme(application.data.scheme)
      const chequeDetailsData =
        await ManageChequePaymentsService.apiGetChequePaymentDetails(
          parseInt(params.applicationFileId)
        )
      if (chequeDetailsData.status === 200) {
        const cheque = chequeDetailsData.data.filter(
          (data: ChequeDetailsModel) => {
            return data.id == parseInt(params.id)
          }
        )[0]
        setChequeDetails(cheque)
        setPaymentDetails({
          postedDateTime: cheque.postedDateTime,
          postedBy: cheque.postedBy,
          specialRemarks: cheque.specialRemarks,
          postedReferenceNumber: cheque.postedReferenceNumber,
        })
      }
    }
    getData()

    setloading(false)
  }, [loading])

  const handleConfirm = async (remark: string) => {
    let result = {} as ResponseObject
    console.log(remark)
    if (showConfirm.section == printCheque) {
      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}cheque-payment/cheque-print/${chequeDetails.applicationFileId}/print/${chequeDetails.id}`,
        {
          headers,
        }
      )
        .then((response) => {
          console.log(response.status)
          FetchRequesthandleNotification(response, messagePrintCheque)
          if (response.status === 200 || response.status === 204) {
            setloading(true)
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }
          return response.blob()
        })
        .then((data) => {
          window.open(URL.createObjectURL(data))
        })
    } else if (showConfirm.section == printCoverLetter) {
      setShowPrintModal(true)
      setShowConfirm({
        isShow: false,
        message: '',
        section: '',
        isRemark: false,
      })
      // const headers = {
      //   responseType: 'blob',
      //   'Content-Type': 'application/pdf',
      //   Authorization: localStorage.getItem('token'),
      // }
      // fetch(
      //   `${process.env.baseUrl}cheque-payment/cheque-print/${chequeDetails.applicationFileId}/print-covering-letter/${chequeDetails.id}`,
      //   {
      //     headers,
      //   }
      // )
      //   .then((response) => {
      //     console.log(response.status)
      //     FetchRequesthandleNotification(response, messagePrintCoverLetter)
      //     if (response.status === 200 || response.status === 204) {
      //       setloading(true)
      //       setShowConfirm({
      //         isShow: false,
      //         message: '',
      //         section: '',
      //         isRemark: false,
      //       })
      //     }
      //     return response.blob()
      //   })
      //   .then((data) => {
      //     window.open(URL.createObjectURL(data))
      //   })
    } else if (showConfirm.section == returnCheque) {
      result = await ChequePrintService.apiReturnChequePrint(
        chequeDetails.applicationFileId,
        remark,
        chequeDetails.id
      )
      if (result.status === 200 || result.status === 204) {
        setShowConfirm({
          isShow: false,
          message: '',
          section: '',
          isRemark: false,
        })
        history.go(-2)
      }
      handleNotification(result, messageReturnCheque)
    } else if (showConfirm.section == chequeCancel) {
      result = await ChequePrintService.apiCancelChequePrint(
        chequeDetails.applicationFileId,
        chequeDetails.id,
        remark
      )
      if (result.status === 200 || result.status === 204) {
        setShowConfirm({
          isShow: false,
          message: '',
          section: '',
          isRemark: false,
        })
        setloading(true)
      }
      handleNotification(result, messageChequeCancel)
    } else if (showConfirm.section == applicationReturnWithModifyIndex) {
      result = await ChequePrintService.apiModifyChequePrintFile(
        chequeDetails.applicationFileId,
        remark
      )
      if (result.status === 200 || result.status === 204) {
        setShowConfirm({
          isShow: false,
          message: '',
          section: '',
          isRemark: false,
        })
        history.go(-2)
      }
      handleNotification(result, messageApplicationReturn)
    }
  }

  const handleCoverLetterPrint = async (printData: ClosePrintModel) => {
    if (printData.language == 'eng') {
      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}cheque-payment/cheque-print/${chequeDetails.applicationFileId}/print/covering-letter/${chequeDetails.id}/${printData.language}/pdf`,
        {
          headers,
        }
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          FetchRequesthandleNotification(
            response,
            'Covering letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })

            const fileName = `application_file_${chequeDetails.applicationFileId}_covering_letter.pdf`
            saveAs(response, fileName)
            setloading(true)
          }
          // return response.blob()
        })
    } else {
      try {
        const response = await fetch(
          `${process.env.baseUrl}cheque-payment/cheque-print/${chequeDetails.applicationFileId}/print/covering-letter/${chequeDetails.id}/${printData.language}/docx`,
          {
            method: 'GET', // or 'POST' depending on your API
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('token'),
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const htmlString = await response.text()
        const doc = new Blob([htmlString], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const fileName = `application_file_${chequeDetails.applicationFileId}_covering_letter.doc`
        saveAs(doc, fileName)
        setShowConfirm({
          isShow: false,
          message: '',
          section: '',
          isRemark: false,
        })
        setloading(true)
      } catch (error) {
        console.error('Error downloading the cover letter:', error)
        // Handle the error appropriately here
      }
    }
  }

  const addPaymentDetails = async (paymentDetails: PaymentDetailsModal) => {
    const result = await ChequePrintService.apiAddPaymentDetails(
      parseInt(params.applicationFileId),
      parseInt(params.id),
      paymentDetails
    )
    if (result.status === 200) {
      setShowPaymentDetailsModal(false)
      setloading(true)
    }
    handleNotification(result, 'Payment details added successfully.')
  }

  const validatePrintCoverLetter = () => {
    if (chequeDetails.status === 'Print Pending') {
      return true
    }
    return false
  }
  const validateAddPaymentDetails = () => {
    if (
      chequeDetails.status === 'Cover Letter Printed' ||
      chequeDetails.status === 'Cheque Posted'
    ) {
      return false
    }
    return true
  }
  const validateReturn = () => {
    if (
      chequeDetails.status === 'Cheque Posted' ||
      chequeDetails.status === 'Cheque Printed'
    ) {
      return true
    }
    return false
  }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                Print Cheques
              </span>
              <span className="m-2">
                {`Application reference : ${referenceNumber}`}
              </span>
            </Col>
          </Row>
          <Row className="view-IncidentTypes-page-layout">
            <Col sm={12} className="border-label">
              <div className="border-label-span">View And Print Cheque</div>
              <Form>
                <Form.Group className="mb-3" controlId="formStatus">
                  <Form.Label>Cheque Status</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={chequeDetails.status}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPayee">
                  <Form.Label>Payee</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={chequeDetails.payee}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formNic">
                      <Form.Label>New NIC</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.newNicNumber}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formNic">
                      <Form.Label>Old NIC</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.oldNicNumber}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBankName">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.bankName}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBaranchName">
                      <Form.Label>Branch Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.branchName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formAccountNumber">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.accountNumber}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formIdentificationNumber"
                    >
                      <Form.Label>Identification Number</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          chequeDetails.identificationNumber
                            ? chequeDetails.identificationNumber
                            : '-'
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formChequeNumber">
                      <Form.Label>Cheque Number</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.chequeNumber}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formVoucherNumber">
                      <Form.Label>Voucher Number </Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={chequeDetails.voucherNumber}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formChequeAmount">
                      <Form.Label>Cheque Amount (Rs.)</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          chequeDetails.paidAmount
                            ? currencyMask(chequeDetails.paidAmount)
                            : '0'
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="formChequeIssuedDate"
                    >
                      <Form.Label>Cheque Issued Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          chequeDetails.chequeIssuedDate !== null
                            ? new Date(
                                chequeDetails.chequeIssuedDate * 1000
                              ).toLocaleDateString()
                            : ''
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row className="print-cheque-buttons-section">
                <Col
                  sm={12}
                  className="print-cheque-buttons-section-inner d-flex justify-content-end mt-4"
                >
                  {checkPermission('print', actionList) &&
                    chequeDetails.status === 'Print Pending' &&
                    chequeDetails.printCount == 0 && (
                      <button
                        className="save-button custom-margin-right"
                        onClick={() => {
                          const message = 'Do you wish to print this cheque  ?'
                          const section = printCheque
                          setShowConfirm({
                            isShow: true,
                            message: message,
                            section: section,
                            isRemark: false,
                          })
                        }}
                      >
                        Print Cheque
                      </button>
                    )}
                  {checkPermission('reprint', actionList) &&
                    chequeDetails.printCount > 0 &&
                    chequeDetails.status === 'Cheque Printed' && (
                      <button
                        className="save-button custom-margin-right"
                        onClick={() => {
                          const message =
                            'Do you wish to re-print this cheque  ?'
                          const section = printCheque
                          setShowConfirm({
                            isShow: true,
                            message: message,
                            section: section,
                            isRemark: false,
                          })
                        }}
                      >
                        Re-Print Cheque
                      </button>
                    )}
                  {!validatePrintCoverLetter() && (
                    <button
                      className={`save-button custom-margin-right`}
                      onClick={() => {
                        setShowReportModal(true)
                      }}
                    >
                      Print Report
                    </button>
                  )}

                  <button
                    disabled={validatePrintCoverLetter()}
                    className={
                      validatePrintCoverLetter()
                        ? 'disable-button custom-margin-right'
                        : 'save-button custom-margin-right'
                    }
                    onClick={() => {
                      const message = 'Do you wish to print cover letter ?'
                      const section = printCoverLetter
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: false,
                      })
                    }}
                  >
                    Print Cover Letter
                  </button>
                  <button
                    disabled={validateAddPaymentDetails()}
                    className={
                      validateAddPaymentDetails()
                        ? 'disable-button custom-margin-right'
                        : 'save-button custom-margin-right'
                    }
                    onClick={() => {
                      // history.push(`${path}/payment-details`)
                      setShowPaymentDetailsModal(true)
                    }}
                  >
                    Payment Details
                  </button>
                  {checkPermission('return-cheque-payment', actionList) && (
                    <button
                      disabled={validateReturn()}
                      className={
                        validateReturn()
                          ? 'disable-button custom-margin-right'
                          : 'save-button custom-margin-right'
                      }
                      onClick={() => {
                        const message =
                          'Do you wish to return this cheque payment ?'
                        const section = returnCheque
                        setShowConfirm({
                          isShow: true,
                          message: message,
                          section: section,
                          isRemark: true,
                        })
                      }}
                    >
                      Return
                    </button>
                  )}
                  {checkPermission('cancel', actionList) && (
                    <button
                      disabled={
                        chequeDetails.status == 'Print Pending' &&
                        scheme == 'RLP'
                      }
                      className={
                        chequeDetails.status == 'Print Pending' &&
                        scheme == 'RLP'
                          ? 'disable-button custom-margin-right'
                          : 'save-button custom-margin-right'
                      }
                      onClick={() => {
                        const message =
                          'Do you wish to cancel this cheque payment ?'
                        const section = chequeCancel
                        setShowConfirm({
                          isShow: true,
                          message: message,
                          section: section,
                          isRemark: false,
                        })
                      }}
                    >
                      Cancel Cheque
                    </button>
                  )}

                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      const message = 'Do you wish to return application  ?'
                      const section = applicationReturnWithModifyIndex
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: false,
                      })
                    }}
                  >
                    File Return
                  </button>
                  <button
                    className="cancel-button-raw"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                </Col>
              </Row>
            </Col>
            {showConfirm.isShow && (
              <ChequeCancelReturnConfirmationModal
                name={'Confirmation'}
                message={showConfirm.message}
                section={showConfirm.section}
                onCancel={() => {
                  setShowConfirm({
                    isShow: false,
                    message: '',
                    section: '',
                    isRemark: false,
                  })
                }}
                isRemark={showConfirm.isRemark}
                onConfirm={(remark: string) => {
                  handleConfirm(remark)
                }}
                status={chequeDetails.status}
                scheme={scheme}
              />
            )}
            {showPrintModal && (
              <CoverLetterPrintModal
                onConfirm={(data: ClosePrintModel) => {
                  console.log(data)
                  handleCoverLetterPrint(data)
                  setShowPrintModal(false)
                }}
                onCancel={() => {
                  setShowPrintModal(false)
                }}
              />
            )}
            {showPaymentDetailsModal && (
              <AddPaymentDetailsModal
                onAdd={(data: PaymentDetailsModal) => {
                  addPaymentDetails(data)
                }}
                onCancel={() => {
                  setShowPaymentDetailsModal(false)
                }}
                data={paymentDetails}
              />
            )}

            <ChequeDetailsReportModal
              chequeId={chequeDetails.id}
              onCancel={() => {
                setShowReportModal(false)
              }}
              showModal={showReportModal}
              newNicNumber={chequeDetails.newNicNumber}
            />
          </Row>
        </>
      )}
    </>
  )
}
export default ViewAndPrintCheque
