import produce from 'immer'
import React, { FC } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardFilterModel } from '../../models/utils/dashboardFilterModel'
import { DashboardStatusFilterUpdateRequest } from '../../store/dashboard/actions'
import { getStatusilters } from '../../store/dashboard/selectors'

export interface DashbaordStatusFilterProps {
  onCancel: any
}

const DashbaordStatusFilter: FC<DashbaordStatusFilterProps> = ({
  onCancel,
}) => {
  const statusFilters = useSelector(getStatusilters)
  const dispatch = useDispatch()

  const renderFilters = () => {
    return statusFilters.map((filter: DashboardFilterModel, index: number) => {
      return (
        <Form.Group
          key={`status-filter-${index}`}
          as={Row}
          className="mb-3"
          controlId="formLast24"
        >
          <Col sm="2" className="align-self-center">
            <Form.Check
              type="checkbox"
              checked={filter.selected}
              onChange={(e) => {
                const newFilter = produce(
                  filter,
                  (draft: DashboardFilterModel) => {
                    draft.selected = e.target.checked
                  }
                )
                dispatch(
                  DashboardStatusFilterUpdateRequest({
                    filter: newFilter,
                    index: index,
                  })
                )
              }}
            />
          </Col>
          <Form.Label column sm="5">
            {`${filter.label}`}
          </Form.Label>
        </Form.Group>
      )
    })
  }
  return (
    <div className="time-filter-container">
      <div className="notification-drawer-header">
        <button
          className="notification-close-button"
          onClick={() => {
            onCancel()
          }}
        >
          X
        </button>
      </div>
      <div className="p-4 time-filter-body">
        <Form>{renderFilters()}</Form>
      </div>
    </div>
  )
}

export default DashbaordStatusFilter
