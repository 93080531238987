import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FilterModel } from '../../../models/utils/filterModel'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { propertyShortComingListFilters } from '../../../utils/metaData'
import { saveAs } from 'file-saver'
import PropertyShortComingListFilters from '../../../components/filters/PropertyShortComingListFilters'

const ShortComingList = () => {
  const filterOption = {} as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [loading, setLoading] = useState(true)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filtersSetRequest({ filters: propertyShortComingListFilters }))
    setLoading(false)
  }, [])

  async function retriveDataCountAndData(filterData: FilterModel) {
    const newFilterData = produce(filterData, (draft: FilterModel) => {
      draft.filterData[0].value = draft.filterData[0].value - 86400
      draft.filterData[1].value = draft.filterData[1].value + 86400
    })

    dispatch(changeLoadingRequest())
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify(newFilterData),
    }
    fetch(
      `${process.env.baseUrl}reports/property-reports/short-coming-report`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((data) => {
        saveAs(data, 'application-details-report.xls')
      })
    dispatch(changeLoadingRequest())
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(filter)
    await retriveDataCountAndData(newFilter)
  }

  return (
    <>
      {!loading && (
        <div className="mb-4 d-flex justify-content-center">
          <PropertyShortComingListFilters
            filterData={filters}
            onFilter={(e: FilterOptionModel[]) => {
              dispatch(filtersSetRequest({ filters: e }))
              retriveFilterData(
                e.filter(
                  (filter: FilterOptionModel) => filter.selected === true
                )
              )
            }}
          />
        </div>
      )}
    </>
  )
}

export default ShortComingList
