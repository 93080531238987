import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap'
import { NextOfKinErrorModel } from '../../models/applicationRegistration/compensation/nextOfKinErrorModel'
import { NextOfKinModel } from '../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ValidationObjectModel } from '../../models/common/validationObjectModel'
import metaDataService from '../../services/metaDataService'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { validationError } from '../../utils/errorMessages'
import { showTotast } from '../../utils/toast'
import {
  getOldAndNewNicFromGivenNic,
  validateForm,
} from '../../utils/utilFunctions'
import InvalidFeedback from '../common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../common/loading/LoadingComponent'
export interface NextOfKinModalProps {
  fileRegistartionObject: FileRegistrationModel
  nextOfKin: NextOfKinModel
  onSave?: any
  onCancel?: any
}

const NextOfKinModal: FC<NextOfKinModalProps> = ({
  nextOfKin,
  onSave,
  onCancel,
  fileRegistartionObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [nextOfKinDetails, setNextOfKinDetails] = useState(nextOfKin)
  const [relationshipList, setRelationShipList] = useState([])
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [errors, setErrors] = useState({} as NextOfKinErrorModel)
  useEffect(() => {
    async function getMetaData() {
      const relationshipData = await metaDataService.getRelationshipList()
      const bankData = await metaDataService.getBankList()

      if (relationshipData.status == 200) {
        setRelationShipList(relationshipData.data)
      }

      if (bankData.status == 200) {
        setBankList(bankData.data)
      }

      if (nextOfKinDetails.id != null) {
        await getBranchesList(nextOfKinDetails.bankId)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }
  const findErrors = () => {
    const newErrors = {} as NextOfKinErrorModel
    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.name,
      } as ValidationObjectModel)
    ) {
      newErrors.nameError = validateForm({
        type: 'text',
        value: nextOfKinDetails.name,
        section: 'Name',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.newNic
          ? nextOfKinDetails.newNicNumber
          : nextOfKinDetails.oldNicNumber,
        maxLength: nextOfKinDetails.newNic ? 12 : 10,
        minLength: nextOfKinDetails.newNic ? 12 : 10,
      } as ValidationObjectModel)
    ) {
      newErrors.nicError = validateForm({
        type: 'text',
        value: nextOfKinDetails.newNic
          ? nextOfKinDetails.newNicNumber
          : nextOfKinDetails.oldNicNumber,
        section: 'Nic Number',
        maxLength: nextOfKinDetails.newNic ? 12 : 10,
        minLength: nextOfKinDetails.newNic ? 12 : 10,
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.permanentAddress,
      } as ValidationObjectModel)
    ) {
      newErrors.permanentAddressError = validateForm({
        type: 'text',
        value: nextOfKinDetails.permanentAddress,
        section: 'Permenant Address',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.relationship,
      } as ValidationObjectModel)
    ) {
      newErrors.relationshipError = validateForm({
        type: 'text',
        value: nextOfKinDetails.relationship,
        section: 'Relationship',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankIdError = validateForm({
        type: 'text',
        value: nextOfKinDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.branchId,
      } as ValidationObjectModel)
    ) {
      newErrors.branchIdError = validateForm({
        type: 'text',
        value: nextOfKinDetails.branchId,
        section: 'Branch Name',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: nextOfKinDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: 'text',
        value: nextOfKinDetails.accountNumber,
        section: 'Account Number',
        maxLength: 15,
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const handleSave = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      onSave(nextOfKinDetails)
    }
  }
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">Next of Kin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="loading-layout">
            <LoadingComponent />
          </div>
        ) : (
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  isInvalid={!!errors.nameError}
                  type="text"
                  placeholder="Name"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.nameError = validateForm({
                        type: 'text',
                        value: nextOfKinDetails.name,
                        section: 'Name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      nextOfKinDetails,
                      (draft: NextOfKinModel) => {
                        draft.name = e.target.value
                      }
                    )
                    setNextOfKinDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.nameError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={nextOfKinDetails.name}
                />
                <InvalidFeedback message={errors.nameError} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formNICNumber">
                <Form.Label>NIC Number *</Form.Label>
                <Row>
                  <Col sm={1}>
                    <Form.Check
                      inline
                      name="formNICNumberType"
                      label="New"
                      type="radio"
                      id={`formNICNumberType-N`}
                      checked={nextOfKinDetails.newNic}
                      onChange={() => {
                        const newFormValue = produce(
                          nextOfKinDetails,
                          (draft: NextOfKinModel) => {
                            draft.newNic = true
                          }
                        )
                        setNextOfKinDetails(newFormValue)
                      }}
                    />
                  </Col>
                  <Col sm={1}>
                    <Form.Check
                      checked={!nextOfKinDetails.newNic}
                      inline
                      name="formNICNumberType"
                      label="Old"
                      type="radio"
                      id={`formNICNumberType-O`}
                      onChange={() => {
                        const newFormValue = produce(
                          nextOfKinDetails,
                          (draft: NextOfKinModel) => {
                            draft.newNic = false
                          }
                        )
                        setNextOfKinDetails(newFormValue)
                      }}
                    />
                  </Col>
                </Row>
                <Form.Control
                  isInvalid={!!errors.nicError}
                  type="text"
                  placeholder="NIC Number"
                  onBlur={async (e) => {
                    const identity = await getOldAndNewNicFromGivenNic(
                      fileRegistartionObject.incidentDate
                        ? new Date(fileRegistartionObject.incidentDate * 1000)
                        : new Date(),
                      e.target.value,
                      nextOfKinDetails.newNic ? 'N' : 'O'
                    )
                    const newFormValue = produce(
                      nextOfKinDetails,
                      (draft: NextOfKinModel) => {
                        draft.newNicNumber = identity.newNic
                        draft.oldNicNumber = identity.oldNic
                      }
                    )
                    setNextOfKinDetails(newFormValue)
                    const newErrors = produce(errors, (draft) => {
                      draft.nicError = validateForm({
                        type: 'text',
                        value: nextOfKinDetails.newNic
                          ? nextOfKinDetails.newNicNumber
                          : nextOfKinDetails.oldNicNumber,
                        section: 'Nic Number',
                        maxLength: nextOfKinDetails.newNic ? 12 : 10,
                        minLength: nextOfKinDetails.newNic ? 12 : 10,
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      nextOfKinDetails,
                      (draft: NextOfKinModel) => {
                        if (draft.newNic) {
                          draft.newNicNumber = e.target.value
                        } else {
                          draft.oldNicNumber = e.target.value
                        }
                      }
                    )
                    setNextOfKinDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.nicError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={
                    nextOfKinDetails.newNic
                      ? nextOfKinDetails.newNicNumber
                      : nextOfKinDetails.oldNicNumber
                  }
                />
                <InvalidFeedback message={errors.nicError} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formAddress">
                <Form.Label>Permenant Address</Form.Label>
                <Form.Control
                  isInvalid={!!errors.permanentAddressError}
                  type="text"
                  placeholder="Permenant Address"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.permanentAddressError = validateForm({
                        type: 'text',
                        value: nextOfKinDetails.permanentAddress,
                        section: 'Permenant Address',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      nextOfKinDetails,
                      (draft: NextOfKinModel) => {
                        draft.permanentAddress = e.target.value
                      }
                    )
                    setNextOfKinDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.permanentAddressError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={nextOfKinDetails.permanentAddress}
                />
                <InvalidFeedback message={errors.permanentAddressError} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formRelationship">
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  isInvalid={!!errors.relationshipError}
                  className="form-select"
                  as="select"
                  value={nextOfKinDetails.relationship}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.relationshipError = validateForm({
                        type: 'text',
                        value: nextOfKinDetails.relationship,
                        section: 'Relationship',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      nextOfKinDetails,
                      (draft: NextOfKinModel) => {
                        draft.relationship = e.target.value
                      }
                    )
                    setNextOfKinDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.relationshipError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {1 === 1 ? (
                    <option value="">--Select Relationship--</option>
                  ) : (
                    ''
                  )}
                  {relationshipList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.relationshipError} />
              </Form.Group>
            </Row>
            <Col sm={12} className="border-label mt-3 mb-3">
              <div className="border-label-span">{`Bank Details`}</div>
              <Row>
                <Form.Group as={Col} controlId="formDpBankName">
                  <Form.Label>{`Bank Name`}</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.bankIdError}
                    className="form-select"
                    as="select"
                    value={nextOfKinDetails.bankId}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.bankIdError = validateForm({
                          type: 'text',
                          value: nextOfKinDetails.bankId,
                          section: 'Bank Name',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        nextOfKinDetails,
                        (draft: NextOfKinModel) => {
                          draft.bankId = parseInt(e.target.value)
                          draft.branchId = 0
                        }
                      )
                      getBranchesList(parseInt(e.target.value))
                      setNextOfKinDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.bankIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    {nextOfKinDetails.bankId == null ||
                    nextOfKinDetails.bankId == 0 ? (
                      <option value="0">--Select Bank--</option>
                    ) : (
                      ''
                    )}
                    {bankList.map(
                      (bank: { name: string; id: number }, index: number) => {
                        return (
                          <option key={`bank-${index}`} value={bank.id}>
                            {bank.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <InvalidFeedback message={errors.bankIdError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpBankName">
                  <Form.Label>{`Branch Name`}</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.branchIdError}
                    className="form-select"
                    as="select"
                    value={nextOfKinDetails.branchId}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.branchIdError = validateForm({
                          type: 'text',
                          value: nextOfKinDetails.branchId,
                          section: 'Branch Name',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        nextOfKinDetails,
                        (draft: NextOfKinModel) => {
                          draft.branchId = parseInt(e.target.value)
                        }
                      )
                      setNextOfKinDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.branchIdError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    {nextOfKinDetails.branchId == null ||
                    nextOfKinDetails.branchId == 0 ? (
                      <option value="0">--Select Branch--</option>
                    ) : (
                      ''
                    )}
                    {branchList.map(
                      (branch: { name: string; id: number }, index: number) => {
                        return (
                          <option key={`branch-${index}`} value={branch.id}>
                            {branch.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <InvalidFeedback message={errors.branchIdError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpAccountNumber">
                  <Form.Label>Account Number</Form.Label>aaa
                  <Form.Control
                    isInvalid={!!errors.accountNumberError}
                    type="text"
                    placeholder="Account Number"
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.accountNumberError = validateForm({
                          type: 'text',
                          value: nextOfKinDetails.accountNumber,
                          section: 'Account Number',
                          maxLength: 15,
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newFormValue = produce(
                        nextOfKinDetails,
                        (draft: NextOfKinModel) => {
                          draft.accountNumber = e.target.value
                        }
                      )
                      setNextOfKinDetails(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.accountNumberError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={nextOfKinDetails.accountNumber}
                  />
                  <InvalidFeedback message={errors.accountNumberError} />
                </Form.Group>
              </Row>
            </Col>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="save-button" onClick={handleSave}>
          {`${nextOfKinDetails.id > 0 ? 'Update' : 'Add'}`}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NextOfKinModal
