import * as React from 'react'
import { useEffect } from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'
import authService from '../services/authService'

interface AuthorizationRouteProps extends RouteProps {
  component: any
}

const AuthorizationRoute = (props: AuthorizationRouteProps) => {
  const { component: Component, ...rest } = props
  const [loading, setloading] = React.useState(true)
  const [autherization, setAutherization] = React.useState(false)
  const location = useLocation()

  useEffect(() => {
    async function checkAuthorization() {
      const autherizationData = await authService.apiCheckAutharization({
        route: location.pathname,
      })
      setAutherization(autherizationData)
      setloading(false)
      // setCountries(data.data);
    }
    checkAuthorization()
  }, [])

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!loading) {
          return autherization ? (
            <Component {...routeProps} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: routeProps.location },
              }}
            />
          )
        }
      }}
    />
  )
}

export default AuthorizationRoute
