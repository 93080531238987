import * as React from 'react'
import { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import LoginPageComponent from '../pages/login/LoginPageComponent'
import { useAuth } from '../providers/AuthProvider'

interface PrivateRouteProps extends RouteProps {
  component: any
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props
  const { isLoading, isAuthenticate, checkAuth } = useAuth()

  useEffect(() => {
    checkAuth()
  }, [])

  console.log(props)
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isLoading) {
          return isAuthenticate ? (
            <Component {...routeProps} />
          ) : (
            <LoginPageComponent redirectUrl={props.location?.pathname} />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
