import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import {
  incidentTypeActive,
  incidentTypeInActive,
} from '../../../utils/successMessages'
import incidentTypesServices from '../../../services/systemConfiguration/incidentTypes/incidentTypesServices'
import { IncidentTypemodel } from '../../../models/systemConfiguration/incidentType/incidentTypemodel'
import './ViewIncidentTypes.scss'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
type TParams = { id: string }
const ViewIncidentTypes = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [incidentType, setIncidentType] = useState({} as IncidentTypemodel)
  const [audiHistory, setAudiHistory] = useState([])
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'incident-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getIncidentTypesData() {
      const incidentTypeData =
        await incidentTypesServices.apiGetIncidentTypesById(
          parseInt(match.params.id)
        )
      const incidentTypHistoryData =
        await incidentTypesServices.apiGetIncidentTypeHistory(
          parseInt(match.params.id)
        )
      setIncidentType(incidentTypeData.data)
      setAudiHistory(incidentTypHistoryData.data)
      setloading(false)
    }
    getIncidentTypesData()
  }, [])
  let statusAction = ''
  if (incidentType.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (incidentType.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData = await incidentTypesServices.apiInactiveIncidentType(
        parseInt(match.params.id)
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = incidentTypeInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setIncidentType(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData = await incidentTypesServices.apiActiveIncidentType(
        parseInt(match.params.id)
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = incidentTypeActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setIncidentType(activeData.data)
    }
    const incidentTypHistoryData =
      await incidentTypesServices.apiGetIncidentTypeHistory(
        parseInt(match.params.id)
      )
    setAudiHistory(incidentTypHistoryData.data)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Incident Type',
        accessor: 'incidentType',
      },
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Audit Description',
        accessor: 'auditDescription',
      },
    ],
    []
  )
  const handleModify = () => {
    history.push(
      `/system-configuration/incident-type/modify/${match.params.id}`
    )
  }
  const renderAuditHistoryTable = () => {
    if (audiHistory.length > 0) {
      return (
        <ReactTableWithPaginationComponent
          columns={columns}
          data={audiHistory}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <span>Audit history not found</span>
    }
  }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-IncidentTypes-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View Incident Types</div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={incidentType.status}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') === true ||
                    checkPermission('Active') === true ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Incident Category</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={incidentType.incidentCategory}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Incident Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={incidentType.incidentType}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Incident Description</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={incidentType.description}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Incident Date</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={new Date(
                        incidentType.effectiveDate * 1000
                      ).toLocaleDateString()}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Completion Date</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        incidentType.endDate == null
                          ? '-'
                          : new Date(
                              incidentType.endDate * 1000
                            ).toLocaleDateString()
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Audit History</Form.Label>
                  <br />
                  {renderAuditHistoryTable()}
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="ok-button"
                    onClick={(e) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                  {checkPermission('modify') === true &&
                    incidentType.status === 'Active' && (
                      <button className="modify-button" onClick={handleModify}>
                        Modify
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewIncidentTypes
