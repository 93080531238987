import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import {
  injuryTypeActive,
  injuryTypeInActive,
} from '../../../utils/successMessages'
import './ViewTypeOfInjuries.scss'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import typeOfInjuriesServices from '../../../services/systemConfiguration/typeOfInjuries/typeOfInjuriesServices'
import { TypeOfInjuriesModel } from '../../../models/systemConfiguration/typeOfInjuries/typeOfInjuriesModel'
type TParams = { id: string }
const ViewTypeOfInjuries = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [injuryType, setInjuryType] = useState({} as TypeOfInjuriesModel)
  const [audiHistory, setAudiHistory] = useState([])
  const history = useHistory()
  const [schemes, setSchemes] = useState()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'injury-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getIncidentTypesData() {
      const injuryTypeData =
        await typeOfInjuriesServices.apiGetTypeOfInjuriesById(
          parseInt(match.params.id)
        )
      const injuryTypHistoryData =
        await typeOfInjuriesServices.apiGetTypeOfInjuriesHistory(
          parseInt(match.params.id)
        )
      setInjuryType(injuryTypeData.data)
      setAudiHistory(injuryTypHistoryData.data)

      const schemeList = injuryTypeData.data.schemeList.map(
        (scheme: { schemeCode: string }) => {
          return scheme.schemeCode
        }
      )
      const schemeCodeList = schemeList.join(', ')
      setSchemes(schemeCodeList)
      setloading(false)
    }

    getIncidentTypesData()
  }, [])
  let statusAction = ''
  if (injuryType.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (injuryType.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData = await typeOfInjuriesServices.apiInactiveInjuryType(
        parseInt(match.params.id)
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = injuryTypeInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setInjuryType(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData = await typeOfInjuriesServices.apiActiveInjuryType(
        parseInt(match.params.id)
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = injuryTypeActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setInjuryType(activeData.data)
    }
    const injuryTypHistoryData =
      await typeOfInjuriesServices.apiGetTypeOfInjuriesHistory(
        parseInt(match.params.id)
      )
    setAudiHistory(injuryTypHistoryData.data)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Type of Disablement ',
        accessor: 'typeOfDisablement',
      },
      {
        Header: 'Category of Injury',
        accessor: 'categoryOfInjury',
      },
      {
        Header: 'Injury Description',
        accessor: 'injuryDescription',
      },
      {
        Header: 'Percentage of Loss of Earning Capacity (%)',
        accessor: 'earningCapacityLoss',
        Cell: (e: any) => {
          if (e.cell.value) {
            return <div>{e.row.original.earningCapacityLoss}</div>
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div>{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Disablement Period',
        accessor: 'disablementPeriod',
      },
      {
        Header: 'Eligible Compensation Percentage (%)',
        accessor: 'compensationEligibility',
        Cell: (e: any) => {
          if (e.cell.value) {
            return <div>{e.row.original.compensationEligibility}</div>
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div>{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Scheme/s',
        accessor: 'schemeCodeList',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Audit Description',
        accessor: 'description',
      },
    ],
    []
  )
  const handleModify = () => {
    history.push(`/system-configuration/injury-type/modify/${match.params.id}`)
  }
  const renderAuditHistoryTable = () => {
    if (audiHistory) {
      return (
        <ReactTableWithPaginationComponent
          columns={columns}
          data={audiHistory}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <span>Audit history not found</span>
    }
  }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-IncidentTypes-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View Injury Types</div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={injuryType.status}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') ||
                    checkPermission('Active') ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Type of Disablement</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={injuryType.typeOfDisablement}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Category of Injury</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={injuryType.categoryOfInjury}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Injury Description</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={injuryType.injuryDescription}
                    />
                  </Form.Group>
                  {/* {injuryType.typeOfDisablement === 'Permanent' && (
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                      <Form.Label>
                        Percentage of Loss of Earning Capacity (%)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={injuryType.earningCapacityLoss}
                      />
                    </Form.Group>
                  )} */}

                  {injuryType.typeOfDisablement === 'Temporary' && (
                    <>
                      <Form.Group className="mb-3" controlId="formGroupTitle">
                        <Form.Label>Disablement Period</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={injuryType.disablementPeriod}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formGroupTitle">
                        <Form.Label>
                          Eligible Compensation Percentage (%)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={injuryType.compensationEligibility}
                        />
                      </Form.Group>
                    </>
                  )}
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Scheme/s</Form.Label>
                    <Form.Control type="text" disabled value={schemes} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Audit History</Form.Label>
                  <br />
                  {renderAuditHistoryTable()}
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="ok-button"
                    onClick={(e) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                  {checkPermission('modify') === true &&
                    injuryType.injuryDescription !== 'Death' &&
                    injuryType.status === 'Active' && (
                      <button className="modify-button" onClick={handleModify}>
                        Modify
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewTypeOfInjuries
