import React, { FC, useState } from 'react'
import emlem from '../../assets/images/login-emlem.png'
import user from '../../assets/images/user.svg'
import lock from '../../assets/images/lock.svg'
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { officeTitle, officeSubTitle } from '../../utils/labels'
import { useDispatch } from 'react-redux'
import { loginUserSuccess } from '../../store/user/actions'
import authServices from '../../services/authService'
import { handleNotification } from '../../utils/utilFunctions'

export interface SessionTimeModalProps {
  onLogin: any
  onCancel: any
  username: string
}

const SessionTimeModal: FC<SessionTimeModalProps> = ({
  onLogin,
  onCancel,
  username,
}) => {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const validateLogin = async () => {
    setLoading(true)
    const response = await authServices.apiLoginPost({
      username: username,
      password: password,
    })
    if (
      response &&
      response.status === 200 &&
      response.token &&
      response.refreshToken
    ) {
      console.log(response)
      const userModel = {
        user: response.data,
      }
      dispatch(loginUserSuccess(userModel))
      localStorage.setItem('user', JSON.stringify(response.data))
      localStorage.setItem('token', response.token)
      localStorage.setItem('refreshToken', response.refreshToken)
      onLogin(password)
    }
    handleNotification(response, 'Login Success')
    setLoading(false)
  }

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    console.log(e.keycode)
    if (e.keyCode === 13) {
      validateLogin()
    }
  }
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Session Timeout
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          validateLogin()
        }}
        className="p-2"
      >
        <Modal.Body>
          <Container className="login-form-layout">
            <Row className="justify-content-center">
              <img src={emlem} alt="" className="login-emlem" />
            </Row>
            <Row>
              <Col sm={12}>
                <div className="login-heading-title">{officeTitle}</div>
                <div className="login-sub-heading-title">{officeSubTitle}</div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="login-header">{'LOGIN'}</div>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="loginUserName">
              <Form.Label className="login-label">User Name</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Username"
                  value={username}
                />
                <InputGroup.Text>
                  <img src={user} alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginPassword">
              <Form.Label className="login-label">Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />
                <InputGroup.Text>
                  <img src={lock} alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Close
          </button>
          <button
            disabled={loading}
            className={`${loading ? 'disable-button' : 'save-button'}`}
            type="submit"
            onKeyPress={handleKeypress}
          >
            {loading ? 'Login.....' : 'Login'}{' '}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default SessionTimeModal
