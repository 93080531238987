import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import IdentificationTypesComponent from '../../../../components/common/identificationComponent/IdentificationTypesComponent'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { FileRegistrationErrorModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationErrorModel'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { JointLoadDetailsModel } from '../../../../models/common/jointLoadDetails'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import metaDataService from '../../../../services/metaDataService'
import { getUser } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import {
  mobileNumberEmpty,
  mobileNumberInvalid,
  validationError,
} from '../../../../utils/errorMessages'
import { occupationList } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkGenderDisable,
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  FetchRequesthandleNotification,
  getAgeByMiliSeconds,
  getGenderByNic,
  getOldAndNewNicFromGivenNic,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
  validateForm,
} from '../../../../utils/utilFunctions'
import { saveAs } from 'file-saver'
import AcknowledgmentPrintModal from '../../../../components/modals/AcknowledgmentPrintModal'
import { ClosePrintModel } from '../../../../models/utils/closePrintModel'

export interface SectionTwoProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
  onNextSection: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const SectionTwo: FC<SectionTwoProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
  onNextSection,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState({} as FileRegistrationErrorModel)
  const [showAcknowledgmentPrintModal, setShowAcknowledgmentPrintModal] =
    useState(false)
  const [titleList, setTitleList] = useState([])
  const [educationLevelList, setEducationLevelList] = useState([])
  const [selfEmployementOccupationList, setSelfEmployementOccupationList] =
    useState([])
  const [martialStatusList, setMartialList] = useState([])
  const [loading, setLoading] = useState(true)
  const [sectionTwoData, setSectionTwoData] = useState(fileRegistrationObject)
  const [relationshipList, setRelationShipList] = useState([])
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      const titleData = await metaDataService.apiTitleListFetch()
      const relationshipData = await metaDataService.getRelationshipList()
      const selfEmployementOccupationData =
        await metaDataService.apiGetOccupationList()
      if (selfEmployementOccupationData.status == 200) {
        setSelfEmployementOccupationList(selfEmployementOccupationData.data)
      }
      if (relationshipData.status == 200) {
        setRelationShipList(relationshipData.data)
      }
      if (titleData.status === 200) {
        setTitleList(titleData.data)
      }

      const maritalData = await metaDataService.getMaritalList()
      if (maritalData.status === 200) {
        setMartialList(maritalData.data)
      }
      const educationData = await metaDataService.getEducationalList()
      if (educationData.status === 200) {
        setEducationLevelList(educationData.data)
      }

      const newFormValue = produce(
        sectionTwoData,
        (draft: FileRegistrationModel) => {
          draft.age = fileRegistrationObject.age
            ? fileRegistrationObject.age
            : getAgeByMiliSeconds(fileRegistrationObject.dateOfBirth)
          if (draft.gender == '' || draft.gender == 'N.A') {
            draft.gender = getGenderByNic(draft.newNicNumber)
          }
          draft.maritalStatus =
            fileRegistrationObject.maritalStatus == null
              ? ''
              : fileRegistrationObject.maritalStatus
          draft.jointLoan =
            fileRegistrationObject.jointLoanDetailId == null ? false : true
          draft.injuredDueToAViolenceActivity =
            fileRegistrationObject.injuredDueToAViolenceActivity
              ? fileRegistrationObject.injuredDueToAViolenceActivity
              : false
          draft.isAWidowDueToTheWar = fileRegistrationObject.isAWidowDueToTheWar
            ? fileRegistrationObject.isAWidowDueToTheWar
            : false
          draft.jointLoanDetails =
            fileRegistrationObject.jointLoanDetailId == null
              ? ({
                  newNic: true,
                  applicationFileId: fileRegistrationObject.id,
                } as JointLoadDetailsModel)
              : fileRegistrationObject.jointLoanDetails
        }
      )
      setSectionTwoData(newFormValue)
      setLoading(false)
    }
    getMetaData()
  }, [loading])

  const jointLoanDetailsNic = () => {
    if (sectionTwoData.jointLoanDetails) {
      if (sectionTwoData.jointLoanDetails.newNic) {
        return sectionTwoData.jointLoanDetails.newNicNumber
      } else {
        return sectionTwoData.jointLoanDetails.oldNicNumber
      }
    } else {
      return ''
    }
  }

  const handleAcknowledgementPrint = async (printData: ClosePrintModel) => {
    if (printData.language == 'eng') {
      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}application-registration/file-registration/application-files/view/${fileRegistrationObject.id}/print-acknowledgment/pdf`,
        {
          headers,
        }
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          FetchRequesthandleNotification(
            response,
            'Acknowledgement ready to print'
          )
          if (response.status === 200) {
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowAcknowledgmentPrintModal(false)
            const fileName = `application_file_${fileRegistrationObject.id}_acknowledgment.pdf`
            saveAs(response, fileName)
            setLoading(true)
          }
          // return response.blob()
        })
    } else {
      console.log(printData)

      const headers = {
        responseType: 'blob',
        'Content-Type': 'application/pdf',
        Authorization: localStorage.getItem('token'),
      }
      fetch(
        `${process.env.baseUrl}application-registration/file-registration/application-files/view/${fileRegistrationObject.id}/print-acknowledgment/non-english/${printData.language}/docx`,
        {
          headers,
        }
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          FetchRequesthandleNotification(
            response,
            'Acknowledgement ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowAcknowledgmentPrintModal(false)
            const fileName = `application_file_${fileRegistrationObject.id}_acknowledgment.docx`
            saveAs(response, fileName)
            setLoading(true)
          }
          // return response.blob()
        })
    }
    // history.goBack()
  }

  const findErrors = () => {
    const newErrors = {} as FileRegistrationErrorModel
    if (
      validateForm({
        type: 'text',
        value: sectionTwoData.applicantTitle,
        section: 'Applicant Title',
      } as ValidationObjectModel)
    ) {
      newErrors.applicantTitleError = validateForm({
        type: 'text',
        value: sectionTwoData.applicantTitle,
        section: 'Applicant Title',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        maxLength: 255,
        value: sectionTwoData.permanentAddress,
        section: 'Permenant Address',
      } as ValidationObjectModel)
    ) {
      newErrors.permanentAddressError = validateForm({
        type: 'text',
        maxLength: 255,
        value: sectionTwoData.permanentAddress,
        section: 'Permenant Address',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'number',
        maxLength: 3,
        value: sectionTwoData.age,
        section: 'Age',
      } as ValidationObjectModel)
    ) {
      newErrors.ageError = validateForm({
        type: 'number',
        maxLength: 3,
        value: sectionTwoData.age,
        section: 'Age',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: sectionTwoData.gender,
        section: 'Gender',
      } as ValidationObjectModel)
    ) {
      newErrors.genderError = validateForm({
        type: 'text',
        value: sectionTwoData.gender,
        section: 'Gender',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: sectionTwoData.maritalStatus,
        section: 'Marital Status',
      } as ValidationObjectModel)
    ) {
      newErrors.maritalStatusError = validateForm({
        type: 'text',
        value: sectionTwoData.maritalStatus,
        section: 'Marital Status',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: 'text',
        value: sectionTwoData.occupation,
        section: ' Occupation ',
      } as ValidationObjectModel)
    ) {
      newErrors.occupationError = validateForm({
        type: 'text',
        value: sectionTwoData.occupation,
        section: ' Occupation ',
      } as ValidationObjectModel)
    }

    if (fileRegistrationObject.scheme === 'SRT') {
      if (
        validateForm({
          type: 'boolean',
          value: sectionTwoData.injuredDueToAViolenceActivity,
          section: 'Injured Due to a violence Activity',
        } as ValidationObjectModel)
      ) {
        newErrors.injuredDueToAViolenceActivityError = validateForm({
          type: 'boolean',
          value: sectionTwoData.injuredDueToAViolenceActivity,
          section: 'Injured Due to a violence Activity',
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: 'boolean',
          value: sectionTwoData.isAWidowDueToTheWar,
          section: 'Is a Widow due to the war',
        } as ValidationObjectModel)
      ) {
        newErrors.isAWidowDueToTheWarError = validateForm({
          type: 'boolean',
          value: sectionTwoData.isAWidowDueToTheWar,
          section: 'Is a Widow due to the war',
        } as ValidationObjectModel)
      }
    }

    // if (
    //   validateForm({
    //     type: 'boolean',
    //     value: sectionTwoData.jointLoan,
    //     section: 'Joint Loan',
    //   } as ValidationObjectModel)
    // ) {
    //   newErrors.jointLoanError = validateForm({
    //     type: 'boolean',
    //     value: sectionTwoData.jointLoan,
    //     section: 'Joint Loan',
    //   } as ValidationObjectModel)
    // }
    if (sectionTwoData.jointLoan) {
      if (
        validateForm({
          type: 'text',
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.name
            : '',
          section: 'Name of the Joint Applicant',
        } as ValidationObjectModel)
      ) {
        newErrors.jointLoanAplicantNameError = validateForm({
          type: 'text',
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.name
            : '',
          section: 'Name of the Joint Applicant',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          value: jointLoanDetailsNic(),
          section: 'NIC Number',
        } as ValidationObjectModel)
      ) {
        newErrors.jointLoanAplicantNicError = validateForm({
          type: 'text',
          value: jointLoanDetailsNic(),
          section: 'NIC Number',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'number',
          maxLength: 3,
          minValue: 1,
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.age
            : '',
          section: 'Age',
        } as ValidationObjectModel)
      ) {
        newErrors.jointLoanAplicantAgeError = validateForm({
          type: 'number',
          maxLength: 3,
          minValue: 1,
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.age
            : '',
          section: 'Age',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          maxLength: 255,
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.permanentAddress
            : '',
          section: 'Address',
        } as ValidationObjectModel)
      ) {
        newErrors.jointLoanAplicantAddressError = validateForm({
          type: 'text',
          maxLength: 255,
          value: sectionTwoData.jointLoanDetails
            ? sectionTwoData.jointLoanDetails.permanentAddress
            : '',
          section: 'Address',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm(
        {
          type: 'number',
          value: sectionTwoData.educationLevelId,
          section: 'Education Level',
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.educationLevelIdError = validateForm(
        {
          type: 'number',
          value: sectionTwoData.educationLevelId,
          section: 'Education Level',
        } as ValidationObjectModel,
        false
      )
    }

    return newErrors
  }
  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    console.log(sectionTwoData)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNextSection = () => {
    onNextSection()
  }

  const handleConfirm = () => {
    const sectionTwoSaveData = produce(sectionTwoData, (draft) => {
      if (!draft.jointLoan) {
        draft.jointLoanDetails = null
      }
    })
    if (showConfirm.section === 'save') {
      onSave(sectionTwoSaveData)
    } else {
      onNext(sectionTwoSaveData)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const validateMobileNumber = (mobileNumber: string) => {
    const regex = /^(\+\d{1,3}[- ]?)?\d{10,15}$/
    if (!mobileNumber) {
      return mobileNumberEmpty
    } else if (!regex.test(mobileNumber)) {
      return mobileNumberInvalid
    }
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Applicant Details</div>
            <Form id="file-applicant-details-form">
              <Form.Group className="mb-3" controlId="formApplicantTitle">
                <Form.Label>
                  Applicant Title{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.applicantTitleError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  className="form-select"
                  as="select"
                  value={sectionTwoData.applicantTitle}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.applicantTitleError = validateForm({
                        type: 'text',
                        value: sectionTwoData.applicantTitle,
                        section: 'Applicant Title',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.applicantTitle = e.target.value
                      }
                    )
                    setSectionTwoData(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.applicantTitleError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {1 === 1 ? <option value="">--Select Title--</option> : ''}
                  {titleList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.applicantTitleError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantName">
                <Form.Label>Applicant Name</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Enter Applicant Name"
                  value={sectionTwoData.applicantName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantAddress">
                <Form.Label>
                  Permenant Address{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.permanentAddressError}
                  type="text"
                  placeholder="Enter Permenant Address"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.permanentAddressError = validateForm({
                        type: 'text',
                        maxLength: 255,
                        value: sectionTwoData.permanentAddress,
                        section: 'Permenant Address',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newSectionTwoData = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.permanentAddress = e.target.value
                      }
                    )
                    setSectionTwoData(newSectionTwoData)
                    const clearError = produce(errors, (draft) => {
                      draft.permanentAddressError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={sectionTwoData.permanentAddress}
                />
                <InvalidFeedback message={errors.permanentAddressError} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formContacNo">
                <Form.Label>Contact No</Form.Label>
                <Form.Control
                  isInvalid={!!errors.contactNoError}
                  type="text"
                  placeholder="Enter Contact No"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const newErrors = produce(errors, (draft) => {
                        draft.contactNoError = validateMobileNumber(
                          e.target.value
                        )
                      })
                      setErrors(newErrors)
                    }
                  }}
                  onChange={(e) => {
                    const newSectionTwoData = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.contactNo = e.target.value
                      }
                    )
                    setSectionTwoData(newSectionTwoData)
                    const clearError = produce(errors, (draft) => {
                      draft.contactNoError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={sectionTwoData.contactNo}
                />
                <InvalidFeedback message={errors.contactNoError} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formSelection">
                <Form.Label>Identification Type</Form.Label>
                <IdentificationTypesComponent
                  isDisabled={true}
                  identificationType={sectionTwoData.identificationType}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantDob">
                <Form.Label>Date of birth</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  value={new Date(
                    sectionTwoData.dateOfBirth * 1000
                  ).toLocaleDateString()}
                />
              </Form.Group>
              {sectionTwoData.identificationType != 'BIRTH_CERTIFICATE' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formNICNumber">
                      <Form.Label>
                        <Row>
                          <Col>NIC Number</Col>
                          <Col>
                            <Form.Check
                              disabled={true}
                              inline
                              name="formNICNumberType"
                              label="New"
                              type="radio"
                              id={`formNICNumberType-N`}
                              checked={sectionTwoData.newNic}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              disabled={true}
                              inline
                              name="formNICNumberType"
                              label="Old"
                              type="radio"
                              id={`formNICNumberType-O`}
                              checked={!sectionTwoData.newNic}
                            />
                          </Col>
                        </Row>
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter NIC Number"
                        value={
                          sectionTwoData.newNic
                            ? sectionTwoData.newNicNumber
                            : sectionTwoData.oldNicNumber
                        }
                      />
                    </Form.Group>
                  </Row>
                  {sectionTwoData.identificationType != 'NIC' && (
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formPSDNumber">
                        <Form.Label>
                          {renderIdentificationTypeName(
                            sectionTwoData.identificationType
                          )}{' '}
                          *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled={true}
                          placeholder={`Enter ${renderIdentificationTypeName(
                            sectionTwoData.identificationType
                          )}`}
                          value={renderIdentificationTypeValue(
                            sectionTwoData.identificationType,
                            sectionTwoData
                          )}
                        />
                      </Form.Group>
                    </Row>
                  )}
                </>
              )}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formNicIssueDate">
                  <Form.Label>Nic Issued Date</Form.Label>
                  <ReactDatePicker
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    required={true}
                    maxDate={new Date()}
                    className={
                      errors.nicIssuedDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="NIC Issued Date"
                    selected={
                      sectionTwoData.nicIssuedDate != null
                        ? new Date(sectionTwoData.nicIssuedDate * 1000)
                        : null
                    }
                    onChange={(date: Date) => {
                      const newFormValue = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.nicIssuedDate = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setSectionTwoData(newFormValue)
                      const clearError = produce(errors, (draft) => {
                        draft.nicIssuedDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.nicIssuedDateError} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAge">
                  <Form.Label>
                    Age <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.ageError}
                    disabled={true}
                    type="text"
                    placeholder="Enter Age"
                    value={sectionTwoData.age}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.ageError = validateForm({
                          type: 'number',
                          maxLength: 3,
                          minValue: 1,
                          value: sectionTwoData.age,
                          section: 'Age',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const ageValue =
                        e.target.value == '' ? '0' : e.target.value
                      const newSectionTwoData = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.age = parseInt(ageValue)
                        }
                      )
                      setSectionTwoData(newSectionTwoData)
                      const clearError = produce(errors, (draft) => {
                        draft.ageError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.ageError} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGender">
                  <Form.Label>
                    Gender <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.genderError}
                    disabled={checkGenderDisable(
                      fileRegistrationObject.modifyIndex,
                      fileRegistrationObject.identificationType
                    )}
                    className="form-select"
                    as="select"
                    placeholder="Enter Gender"
                    value={sectionTwoData.gender}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.genderError = validateForm({
                          type: 'text',
                          value: sectionTwoData.gender,
                          section: 'Gender',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newSectionTwoData = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.gender = e.target.value
                        }
                      )
                      setSectionTwoData(newSectionTwoData)
                      const clearError = produce(errors, (draft) => {
                        draft.genderError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    <option value="N/A">--Select Gender--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Control>
                  <InvalidFeedback message={errors.genderError} />
                </Form.Group>
                <Form.Group as={Col} controlId="formMaritalStatus">
                  <Form.Label>
                    Marital Status{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.maritalStatusError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    className="form-select"
                    as="select"
                    value={sectionTwoData.maritalStatus}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.maritalStatusError = validateForm({
                          type: 'text',
                          value: sectionTwoData.maritalStatus,
                          section: 'Marital Status',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newSectionTwoData = produce(
                        sectionTwoData,
                        (draft: FileRegistrationModel) => {
                          draft.maritalStatus = e.target.value
                        }
                      )
                      setSectionTwoData(newSectionTwoData)
                      const clearError = produce(errors, (draft) => {
                        draft.maritalStatusError = ''
                      })
                      setErrors(clearError)
                    }}
                  >
                    {sectionTwoData.maritalStatus === '' ? (
                      <option value="">--Select Marital Status--</option>
                    ) : (
                      ''
                    )}
                    {martialStatusList.map(
                      (title: { name: string; id: number }, index: number) => {
                        return (
                          <option key={`country-${index}`} value={title.name}>
                            {title.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <InvalidFeedback message={errors.maritalStatusError} />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formApplicantOccupation">
                <Form.Label>
                  Occupation <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.occupationError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  className="form-select"
                  as="select"
                  value={sectionTwoData.occupation}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.occupationError = validateForm({
                        type: 'text',
                        value: sectionTwoData.occupation,
                        section: ' Occupation ',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newFormValue = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.occupation = e.target.value
                      }
                    )
                    setSectionTwoData(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.occupationError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {sectionTwoData.occupation === '' ||
                  sectionTwoData.occupation == null ? (
                    <option value="">--Select Occupation--</option>
                  ) : (
                    ''
                  )}
                  {occupationList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.occupationError} />
              </Form.Group>
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="formSelfOccupation"
              >
                <Form.Label>Self employment to the occupation</Form.Label>
                <Form.Control
                  isInvalid={!!errors.selfEmploymentToTheOccupationError}
                  disabled={fileRegistrationObject.modifyIndex > 0}
                  className="form-select"
                  as="select"
                  value={sectionTwoData.selfEmploymentToTheOccupation}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.selfEmploymentToTheOccupationError = validateForm(
                        {
                          type: 'text',
                          value: sectionTwoData.selfEmploymentToTheOccupation,
                          section: 'Self employment to the occupation',
                        } as ValidationObjectModel,
                        false
                      )
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newSectionTwoData = produce(
                      sectionTwoData,
                      (draft: FileRegistrationModel) => {
                        draft.selfEmploymentToTheOccupation = e.target.value
                      }
                    )
                    setSectionTwoData(newSectionTwoData)
                    const clearError = produce(errors, (draft) => {
                      draft.selfEmploymentToTheOccupationError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  <option value="">--Select Self Employment Status--</option>
                  {selfEmployementOccupationList.map(
                    (
                      occupation: { occupationType: string; id: number },
                      index: number
                    ) => {
                      return (
                        <option
                          key={`country-${index}`}
                          value={occupation.occupationType}
                        >
                          {occupation.occupationType}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback
                  message={errors.selfEmploymentToTheOccupationError}
                />
              </Form.Group>
              {fileRegistrationObject.scheme === 'SRT' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formInjuredDueTo">
                      <Form.Label>
                        Injured Due to a violence Activity{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            inline
                            name="formInjuredDueTo"
                            label="Yes"
                            type="radio"
                            id={`formInjuredDueTo-N`}
                            checked={
                              sectionTwoData.injuredDueToAViolenceActivity
                            }
                            onChange={() => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.injuredDueToAViolenceActivity = true
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.injuredDueToAViolenceActivityError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            inline
                            name="formInjuredDueTo"
                            label="No"
                            type="radio"
                            id={`formInjuredDueTo-O`}
                            checked={
                              !sectionTwoData.injuredDueToAViolenceActivity
                            }
                            onChange={() => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.injuredDueToAViolenceActivity = false
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.injuredDueToAViolenceActivityError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                      </Row>
                      <InvalidFeedback
                        message={errors.injuredDueToAViolenceActivityError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formWidowDueTo">
                      <Form.Label>
                        Is a Widow due to the war?{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            inline
                            name="formWidowDueTo"
                            label="Yes"
                            type="radio"
                            id={`formWidowDueTo-N`}
                            checked={sectionTwoData.isAWidowDueToTheWar}
                            onChange={() => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.isAWidowDueToTheWar = true
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.isAWidowDueToTheWarError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            inline
                            name="formWidowDueTo"
                            label="No"
                            type="radio"
                            id={`formWidowDueTo-O`}
                            checked={!sectionTwoData.isAWidowDueToTheWar}
                            onChange={() => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.isAWidowDueToTheWar = false
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.isAWidowDueToTheWarError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                      </Row>
                      <InvalidFeedback
                        message={errors.isAWidowDueToTheWarError}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formJointLoan">
                  <Form.Label>
                    Joint Loan <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        inline
                        name="formJointLoan"
                        label="Yes"
                        type="radio"
                        id={`formJointLoan-N`}
                        checked={sectionTwoData.jointLoan}
                        onChange={() => {
                          const newSectionTwoData = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.jointLoan = true
                            }
                          )
                          setSectionTwoData(newSectionTwoData)
                          const clearError = produce(errors, (draft) => {
                            draft.jointLoanError = ''
                            draft.jointLoanAplicantNameError = ''
                            draft.jointLoanAplicantAddressError = ''
                            draft.jointLoanAplicantAgeError = ''
                            draft.jointLoanAplicantNicError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        inline
                        name="formJointLoan"
                        label="No"
                        type="radio"
                        id={`formJointLoan-O`}
                        checked={!sectionTwoData.jointLoan}
                        onChange={() => {
                          const newSectionTwoData = produce(
                            sectionTwoData,
                            (draft: FileRegistrationModel) => {
                              draft.jointLoan = false
                              draft.jointLoanDetails =
                                {} as JointLoadDetailsModel
                            }
                          )
                          setSectionTwoData(newSectionTwoData)
                          const clearError = produce(errors, (draft) => {
                            draft.jointLoanError = ''
                            draft.jointLoanAplicantNameError = ''
                            draft.jointLoanAplicantAddressError = ''
                            draft.jointLoanAplicantAgeError = ''
                            draft.jointLoanAplicantNicError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                    </Col>
                  </Row>
                  <InvalidFeedback message={errors.jointLoanError} />
                  {sectionTwoData.jointLoan && (
                    <Col sm={12} className="border-label mt-4">
                      <div className="border-label-span">
                        Joint Loan Details
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="formJointLoanName"
                      >
                        <Form.Label>
                          Name of the Joint Applicant{' '}
                          {sectionTwoData.jointLoan ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.jointLoanAplicantNameError}
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          type="text"
                          placeholder="Enter Name of the Joint Applicant"
                          value={sectionTwoData.jointLoanDetails.name}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.jointLoanAplicantNameError = validateForm({
                                type: 'text',
                                value: sectionTwoData.jointLoanDetails.name,
                                section: 'Name of the Joint Applicant',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          maxLength={50}
                          onChange={(e) => {
                            const newSectionTwoData = produce(
                              sectionTwoData,
                              (draft: FileRegistrationModel) => {
                                draft.jointLoanDetails.name = e.target.value
                              }
                            )
                            setSectionTwoData(newSectionTwoData)
                            const clearError = produce(errors, (draft) => {
                              draft.jointLoanAplicantNameError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.jointLoanAplicantNameError}
                        />
                      </Form.Group>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formJointLoanNICNumber">
                          <Form.Label>
                            NIC Number{' '}
                            {sectionTwoData.jointLoan ? (
                              <span className="required-field-astrix">*</span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Row>
                            <Col sm={1}>
                              <Form.Check
                                disabled={
                                  fileRegistrationObject.modifyIndex > 0
                                }
                                inline
                                name="formJointLoanNICNumberType"
                                label="New"
                                type="radio"
                                id={`formJointLoanNICNumberType-N`}
                                checked={sectionTwoData.jointLoanDetails.newNic}
                                onChange={() => {
                                  const newSectionTwoData = produce(
                                    sectionTwoData,
                                    (draft: FileRegistrationModel) => {
                                      draft.jointLoanDetails.newNic = true
                                    }
                                  )
                                  setSectionTwoData(newSectionTwoData)
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.jointLoanNewOrOldError = ''
                                    }
                                  )
                                  setErrors(clearError)
                                }}
                              />
                            </Col>
                            <Col sm={1}>
                              <Form.Check
                                disabled={
                                  fileRegistrationObject.modifyIndex > 0
                                }
                                inline
                                name="formJointLoanNICNumberType"
                                label="Old"
                                type="radio"
                                id={`formJointLoanNICNumberType-O`}
                                checked={
                                  !sectionTwoData.jointLoanDetails.newNic
                                }
                                onChange={() => {
                                  const newSectionTwoData = produce(
                                    sectionTwoData,
                                    (draft: FileRegistrationModel) => {
                                      draft.jointLoanDetails.newNic = false
                                    }
                                  )
                                  setSectionTwoData(newSectionTwoData)
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.jointLoanNewOrOldError = ''
                                    }
                                  )
                                  setErrors(clearError)
                                }}
                              />
                            </Col>
                          </Row>
                          <InvalidFeedback
                            message={errors.jointLoanNewOrOldError}
                          />
                          <Form.Control
                            isInvalid={!!errors.jointLoanAplicantNicError}
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            type="text"
                            placeholder="Enter NIC Number of the Joint Applicant"
                            onBlur={async (e) => {
                              const identity =
                                await getOldAndNewNicFromGivenNic(
                                  new Date(),
                                  e.target.value,
                                  sectionTwoData.jointLoanDetails.newNic
                                    ? 'N'
                                    : 'O'
                                )
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.jointLoanDetails.newNicNumber =
                                    identity.newNic
                                  draft.jointLoanDetails.oldNicNumber =
                                    identity.oldNic
                                  draft.jointLoanDetails.age = identity.age
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const newErrors = produce(errors, (draft) => {
                                draft.jointLoanAplicantNicError = validateForm({
                                  type: 'text',
                                  value: sectionTwoData.jointLoanDetails.newNic
                                    ? sectionTwoData.jointLoanDetails
                                        .newNicNumber
                                    : sectionTwoData.jointLoanDetails
                                        .oldNicNumber,
                                  section: 'NIC Number',
                                } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }}
                            onChange={(e) => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  if (draft.jointLoanDetails.newNic) {
                                    draft.jointLoanDetails.newNicNumber =
                                      e.target.value
                                  } else {
                                    draft.jointLoanDetails.oldNicNumber =
                                      e.target.value
                                  }
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.jointLoanAplicantNicError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={
                              sectionTwoData.jointLoanDetails.newNic
                                ? sectionTwoData.jointLoanDetails.newNicNumber
                                : sectionTwoData.jointLoanDetails.oldNicNumber
                            }
                          />
                          <InvalidFeedback
                            message={errors.jointLoanAplicantNicError}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formJointAge">
                          <Form.Label>
                            Age{' '}
                            {sectionTwoData.jointLoan ? (
                              <span className="required-field-astrix">*</span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.jointLoanAplicantAgeError}
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            type="text"
                            placeholder="Enter Age of the Joint Applicant"
                            value={sectionTwoData.jointLoanDetails.age}
                            onBlur={() => {
                              const newErrors = produce(errors, (draft) => {
                                draft.jointLoanAplicantAgeError = validateForm({
                                  type: 'number',
                                  maxLength: 3,
                                  value: sectionTwoData.jointLoanDetails.age,
                                  section: 'Age',
                                } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }}
                            onChange={(e) => {
                              const ageValue =
                                e.target.value == '' ? '0' : e.target.value
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.jointLoanDetails.age =
                                    parseInt(ageValue)
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.jointLoanAplicantAgeError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                          <InvalidFeedback
                            message={errors.jointLoanAplicantAgeError}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId="formRelationshipAgPerson"
                        >
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control
                            isInvalid={!!errors.jointLoanRelationshipError}
                            className="form-select"
                            as="select"
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            value={sectionTwoData.jointLoanDetails.relationship}
                            onChange={(e) => {
                              const newFormValue = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.jointLoanDetails.relationship =
                                    e.target.value
                                }
                              )
                              setSectionTwoData(newFormValue)
                              const clearError = produce(errors, (draft) => {
                                draft.jointLoanRelationshipError = ''
                              })
                              setErrors(clearError)
                            }}
                          >
                            <option value="">--Select Relationship--</option>
                            {relationshipList.map(
                              (
                                title: { name: string; id: number },
                                index: number
                              ) => {
                                return (
                                  <option
                                    key={`country-${index}`}
                                    value={title.name}
                                  >
                                    {title.name}
                                  </option>
                                )
                              }
                            )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.jointLoanRelationshipError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formJointAddress">
                          <Form.Label>
                            Address{' '}
                            {sectionTwoData.jointLoan ? (
                              <span className="required-field-astrix">*</span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.jointLoanAplicantAddressError}
                            disabled={fileRegistrationObject.modifyIndex > 0}
                            type="text"
                            placeholder="Enter Address of the Joint Applicant"
                            value={
                              sectionTwoData.jointLoanDetails.permanentAddress
                            }
                            onBlur={() => {
                              const newErrors = produce(errors, (draft) => {
                                draft.jointLoanAplicantAddressError =
                                  validateForm({
                                    type: 'text',
                                    maxLength: 255,
                                    value:
                                      sectionTwoData.jointLoanDetails
                                        .permanentAddress,
                                    section: 'Address',
                                  } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }}
                            onChange={(e) => {
                              const newSectionTwoData = produce(
                                sectionTwoData,
                                (draft: FileRegistrationModel) => {
                                  draft.jointLoanDetails.permanentAddress =
                                    e.target.value
                                }
                              )
                              setSectionTwoData(newSectionTwoData)
                              const clearError = produce(errors, (draft) => {
                                draft.jointLoanAplicantAddressError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                          <InvalidFeedback
                            message={errors.jointLoanAplicantAddressError}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                  )}
                  <Form.Group
                    className="mt-4 mb-3"
                    controlId="formApplicantEducationLevel"
                  >
                    <Form.Label>Education Level</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.educationLevelIdError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      className="form-select"
                      as="select"
                      value={sectionTwoData.educationLevelId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.educationLevelIdError = validateForm(
                            {
                              type: 'number',
                              value: sectionTwoData.educationLevelId,
                              section: 'Education Level',
                            } as ValidationObjectModel,
                            false
                          )
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          sectionTwoData,
                          (draft: FileRegistrationModel) => {
                            draft.educationLevelId = parseInt(e.target.value)
                          }
                        )
                        setSectionTwoData(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.educationLevelIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value="">--Select Education Level--</option>
                      {educationLevelList.map(
                        (
                          education: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option
                              key={`country-${index}`}
                              value={education.id}
                            >
                              {education.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.educationLevelIdError} />
                  </Form.Group>
                </Form.Group>
              </Row>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={fileRegistrationObject.validationLevel < 2}
                    className={`${
                      fileRegistrationObject.validationLevel < 2
                        ? 'print-acknowledgement-disable-button'
                        : 'print-acknowledgement-button'
                    }`}
                    onClick={() => {
                      setShowAcknowledgmentPrintModal(true)
                    }}
                  >
                    Print Acknowledgement
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      if (fileRegistrationObject.modifyIndex == 0) {
                        onClickNext()
                      } else {
                        onClickNextSection()
                      }
                    }}
                  >
                    Next
                  </button>
                  {fileRegistrationObject.modifyIndex == 0 && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  )}
                </>
              )}
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
          {showAcknowledgmentPrintModal && (
            <AcknowledgmentPrintModal
              onConfirm={(data: ClosePrintModel) => {
                handleAcknowledgementPrint(data)
                setShowAcknowledgmentPrintModal(false)
              }}
              onCancel={() => {
                setShowAcknowledgmentPrintModal(false)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionTwo
