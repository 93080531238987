import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { ModuleModal } from '../../../models/package/moduleModal'
import { getUserModules } from '../../../store/user/selectors'
import Gpc from './gpc/Gpc'
import Gsc from './gsc/Gsc'
import Gsi from './gsi/Gsi'
import Gsd from './gsd/Gsd'
import Pd from './pd/Pd'
import Pi from './pi/Pi'
import Rlp from './rlp/Rlp'
import Mp from './mp/Mp'

const Compensation = () => {
  const { path } = useRouteMatch()
  const moduleList = useSelector(
    getUserModules('compensation-certification', 'compensation')
  )

  const renderModules = () => {
    return moduleList.map((module: ModuleModal, index: number) => {
      return (
        <Col key={`module-${index}`} xs={4}>
          <TileButtonComponent
            isIcon={false}
            name={module.description}
            routeURL={module.name}
          />
        </Col>
      )
    })
  }
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">Compensation</Row>
        <Row className="page-tiles-layout">{renderModules()}</Row>
      </Route>
      <Route path={`${path}/gpc`} component={Gpc} />
      <Route path={`${path}/gsc`} component={Gsc} />
      <Route path={`${path}/gsi`} component={Gsi} />
      <Route path={`${path}/gsd`} component={Gsd} />
      <Route path={`${path}/pd`} component={Pd} />
      <Route path={`${path}/pi`} component={Pi} />
      <Route path={`${path}/rlp`} component={Rlp} />
      <Route path={`${path}/mp`} component={Mp} />
    </Switch>
  )
}

export default Compensation
