import produce from 'immer'
import { UserModal } from '../../models/userModel'
import {
  UserActions,
  UserState,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
} from './actionTypes'

const initialState: UserState = {
  pending: false,
  user: {} as UserModal,
  error: null,
}

const existUser = localStorage.getItem('user')

if (existUser != null) {
  initialState.user = JSON.parse(existUser)
}

export default (state = initialState, action: UserActions) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST:
      return produce(state, (draft: UserState) => {
        draft.user = {} as UserModal
      })
    case USER_LOGIN_REQUEST:
      return produce(state, (draft: UserState) => {
        draft.pending = true
      })
    case USER_LOGIN_SUCCESS:
      return produce(state, (draft: UserState) => {
        draft.pending = false
        draft.user = action.payload.user
        draft.error = null
      })
    case USER_LOGIN_FAILURE:
      return produce(state, (draft: UserState) => {
        draft.pending = false
        draft.user = {} as UserModal
        draft.error = action.payload.error
      })
    default:
      return {
        ...state,
      }
  }
}
