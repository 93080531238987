import produce from 'immer'
import './EnquireApplication.scss'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, useHistory, Route, Switch } from 'react-router-dom'
import CompensationApplicationsFilter from '../../components/filters/CompensationApplicationsFilter'
import IterminalTableComponent from '../../components/table/IterminalTableComponent'
import { FilterModel } from '../../models/utils/filterModel'
import { FilterOptionModel } from '../../models/utils/filterOption'
import { filtersSetRequest } from '../../store/filter/actions'
import { getFilters } from '../../store/filter/selectors'
import {
  compensationApplicationsFilters,
  fileRegistrationColumns,
} from '../../utils/metaData'
import { getPageCount } from '../../utils/tableFunctions'
import EnquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { Col, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import FileView from './FileView'
import dashboard_icon from '../../assets/images/nav-icons/enquire-application.svg'
import { defaultPageLimit, defaultOrderListLimit } from '../../utils/constants'

const EnquireApplication = () => {
  const [file, setFile] = useState({} as FileRegistrationModel)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const [state, setstate] = useState([])
  const [loading, setLoading] = useState(true)
  const filters = useSelector(getFilters)
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationApplicationsFilters }))
    async function getApplicationFiles() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getApplicationFiles()
  }, [])
  const columns = React.useMemo(() => fileRegistrationColumns, [])

  const retriveApplicationFilesData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)
    const applicationData = await EnquireApplicationService.apiGetFiles(
      newFilter
    )
    setstate(applicationData.data)
    setLoading(false)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  async function retriveDataCountAndData(filterData: FilterModel) {
    const applicationCountData =
      await EnquireApplicationService.apiGetFilesCount(filterData)
    if (applicationCountData.status === 200) {
      setDatacount(applicationCountData.data.count)
      setPageCount(
        getPageCount(applicationCountData.data.count, filterOption.limit)
      )
      const applicationData = await EnquireApplicationService.apiGetFiles(
        filterData
      )
      setstate(applicationData.data)
    } else {
      setDatacount(0)
      setPageCount(0)
      setstate([])
    }
  }

  const handleView = (row: FileRegistrationModel) => {
    setFile(row)
    history.push(`${path}/view/${row.id}`)
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <img src={dashboard_icon} alt="" />
              <span className="m-2">Enquire Applications</span>
            </Col>
          </Row>
          <Row className="enquire-page-layout">
            <Col>
              {!loading && (
                <div className="mb-4">
                  <CompensationApplicationsFilter
                    scheme="GPC"
                    filterData={filters}
                    onFilter={(e: FilterOptionModel[]) => {
                      dispatch(filtersSetRequest({ filters: e }))
                      retriveFilterData(
                        e.filter(
                          (filter: FilterOptionModel) =>
                            filter.selected === true
                        )
                      )
                    }}
                  />
                </div>
              )}
              <div>
                <IterminalTableComponent
                  columns={columns}
                  data={state}
                  fetchData={retriveApplicationFilesData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={dataCount}
                  onClickRow={(row: any) => {
                    handleView(row)
                  }}
                />
              </div>
            </Col>
          </Row>
        </Route>
        {/* <Route
          path={`${path}/view`}
          render={(props) => <FileView applicationFile={file} {...props} />}
        /> */}
        <Route
          path={`${path}/view/:id`}
          render={(props) => <FileView applicationFile={file} {...props} />}
        />
      </Switch>
    </>
  )
}
export default EnquireApplication
