import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import IterminalTableComponent from '../../../../components/table/IterminalTableComponent'
import { FilterModel } from '../../../../models/utils/filterModel'
import { defaultPageLimit } from '../../../../utils/constants'
import { getPageCount } from '../../../../utils/tableFunctions'

import BankAndBranchServices from '../../../../services/systemConfiguration/manegeBankAndBranch/bankAndBranchServices'

const BankList = () => {
  const [state, setState] = useState([])
  const pending = false
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    // orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)

  useEffect(() => {
    // dispatch(filtersSetRequest({ filters: propertyDamageTypeFilters }))
    async function getIncidentTypesData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getIncidentTypesData()
  }, [location.pathname])
  async function retriveDataCountAndData(filterData: FilterModel) {
    const propertyCountData = await BankAndBranchServices.apiFetchBankListCount(
      filterData
    )
    setDatacount(propertyCountData.data.count)
    setPageCount(getPageCount(propertyCountData.data.count, filterOption.limit))
    const propertyData = await BankAndBranchServices.apiFetchBankList(
      filterData
    )
    setState(propertyData.data)
  }

  const retriveData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const propertyData = await BankAndBranchServices.apiFetchBankList(newFilter)
    setState(propertyData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Bank Code',
        accessor: 'code',
      },
      {
        Header: 'Bank Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`/system-configuration/bank-and-branch/manage-branch/${id}`)
  }
  return (
    <>
      {pending ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            <Col sm={12} className="mb-4"></Col>
            <Col sm={12}>
              <IterminalTableComponent
                columns={columns}
                data={state}
                fetchData={retriveData}
                loading={loading}
                pageCount={pageCount}
                totalCount={dataCount}
                onClickRow={(row: any) => {
                  console.log(row)
                  handleView(row.id)
                }}
              />
            </Col>
          </Route>
        </Switch>
      )}
    </>
  )
}

export default BankList
