/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

export interface PriceInputComponentProps {
  value: number
  placeholder?: string
  disabled?: boolean
  onChange?: any
  isInvalid?: any
  onErrorHandle?: any
}

const PriceInputComponent: FC<PriceInputComponentProps> = ({
  value,
  placeholder,
  onChange,
  disabled = false,
  isInvalid = false,
  onErrorHandle,
}) => {
  const [amount, setAmount] = useState('')

  useEffect(() => {
    if (value != undefined) {
      const newAmount = formatCurrency(value.toString(), 'blur')
      setAmount(newAmount)
    }
  }, [value])

  const numberFormat = (x: any) => {
    if (x != null || x != '') {
      return x.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return ''
  }

  const formatCurrency = (input: any, blur: any) => {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let inputVal = input

    // don't validate empty input
    if (inputVal === '') {
      return
    }
    // check for decimal
    if (inputVal.indexOf('.') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimalPos = inputVal.indexOf('.')

      // split number by decimal point
      let leftSide = inputVal.substring(0, decimalPos)
      let rightSide = inputVal.substring(decimalPos)

      // add commas to left side of number
      leftSide = numberFormat(leftSide)

      // validate right side
      rightSide = numberFormat(rightSide)

      // On blur make sure 2 numbers after decimal
      if (blur === 'blur') {
        rightSide += '00'
      }

      // Limit decimal to only 2 digits
      rightSide = rightSide.substring(0, 2)

      // join number by .
      inputVal = leftSide + '.' + rightSide
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      inputVal = numberFormat(inputVal)
      inputVal = inputVal

      // final formatting
      if (blur === 'blur') {
        inputVal += '.00'
      }
    }

    return inputVal
  }

  return (
    <InputGroup>
      <FormControl
        isInvalid={isInvalid}
        disabled={disabled}
        value={amount}
        type="text"
        placeholder={placeholder}
        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
        data-type="currency"
        onBlur={(e: any) => {
          const returnValue = formatCurrency(e.target.value, 'blur')
          onChange(returnValue ? returnValue.replace(/,/g, '') : '0')
        }}
        onChange={(e) => {
          setAmount(formatCurrency(e.target.value, ''))
          if (onErrorHandle) {
            onErrorHandle(e.target.value)
          }
        }}
      />
    </InputGroup>
  )
}

export default PriceInputComponent
