import React from 'react'
import './Applicant360.scss'
import icon from '../../assets/images/nav-icons/applicant-360.svg'
import { Col, Form, Row } from 'react-bootstrap'
import produce from 'immer'
import {
  getOldAndNewNicFromGivenNic,
  nicFormatFormat,
} from '../../utils/utilFunctions'
import { PsychosocialDetailsModel } from '../../models/applicant360/PsychosocialDetailsModel'
import applicant360Service from '../../services/Applicant360Service/applicant360Service'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { showTotast } from '../../utils/toast'
import { useDispatch } from 'react-redux'
import { changeLoadingRequest } from '../../store/app/actionTypes'
import ReactTableWithPaginationComponent from '../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'

export interface IApplicant360 {
  newNicNumber: string
  oldNicNumber: string
}

interface Applicant360Errors {
  nicError: string
}

const Applicant360 = () => {
  const [state, setState] = React.useState({} as IApplicant360)
  const [newNic, setNewNic] = React.useState(false)
  const [errors, setErrors] = React.useState({} as Applicant360Errors)
  const [psychosocialDetails, stePsychosocialDetails] = React.useState(
    [] as PsychosocialDetailsModel[]
  )
  const [orimsDetails, steOrimsDetails] = React.useState(
    [] as FileRegistrationModel[]
  )
  const dispatch = useDispatch()

  const getData = async () => {
    dispatch(changeLoadingRequest())
    const result = await applicant360Service.getApplicant360Data(state)
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      if (
        result.data.orimsDetails.length > 0 ||
        result.data.psychosocialDetails.length > 0
      ) {
        message = 'Search records successful'
      } else {
        message = 'No records found'
        success = false
      }
      stePsychosocialDetails(result.data.psychosocialDetails)
      steOrimsDetails(result.data.orimsDetails)
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
    dispatch(changeLoadingRequest())
  }

  const handleReset = () => {
    const newState = produce(state, (draft: IApplicant360) => {
      draft.newNicNumber = ''
      draft.oldNicNumber = ''
    })
    setState(newState)
    setErrors({ ...errors, nicError: '' })
  }

  const isSearchDisabled = !state.newNicNumber && !state.oldNicNumber

  const orimsColumns = React.useMemo(
    () => [
      {
        Header: 'Application Reference',
        accessor: 'referenceNumber',
      },
      {
        Header: 'Incident Type',
        accessor: 'incidentType.incidentType',
      },
      {
        Header: 'Scheme',
        accessor: 'scheme',
      },
      {
        Header: 'Applicant Name',
        accessor: 'applicantName',
      },
      {
        Header: 'File Received Date',
        accessor: 'fileReceivedDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )

  const psychosociaColumns = React.useMemo(
    () => [
      {
        Header: 'Applicant Name',
        accessor: 'fullname',
      },
      {
        Header: 'Case Manager Id',
        accessor: 'casemanagerid',
      },
      {
        Header: 'Case Manager Name',
        accessor: 'casemanagername',
      },
      {
        Header: 'Place',
        accessor: 'placeanddate',
      },
      {
        Header: 'Date',
        accessor: 'placeanddate1',
      },
    ],
    []
  )

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={icon} alt="" />
          <span className="m-2">Applicant 360</span>
        </Col>
      </Row>
      <Row className="page-tiles-layout nic-card">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Enter NIC For Search Records</div>
          <Row className="d-flex justify-content-center">
            <Col className="d-flex col-1">
              <Form.Check
                className=" mt-1"
                inline
                name="formNICNumberType"
                label="New"
                type="radio"
                id={`formNICNumberType-N`}
                checked={newNic}
                onChange={() => {
                  setNewNic(true)
                }}
              />
            </Col>
            <Col className="d-flex col-1 ">
              <Form.Check
                className=" mt-1"
                inline
                name="formNICNumberType"
                label="Old"
                type="radio"
                id={`formNICNumberType-O`}
                checked={!newNic}
                onChange={() => {
                  setNewNic(false)
                }}
              />
            </Col>
            <Col className="col-sx-7">
              <Form.Control
                isInvalid={!!errors.nicError}
                maxLength={newNic ? 12 : 10}
                type="text"
                placeholder="Enter NIC Number"
                onBlur={async (e) => {
                  const identity = await getOldAndNewNicFromGivenNic(
                    new Date(),
                    e.target.value,
                    newNic ? 'N' : 'O'
                  )

                  if (identity.error) {
                    const clearError = produce(errors, (draft) => {
                      draft.nicError = 'Not a valid nic number'
                    })
                    setErrors(clearError)
                  } else {
                    const newObject = produce(state, (draft: IApplicant360) => {
                      draft.newNicNumber = identity.newNic
                      draft.oldNicNumber = identity.oldNic
                    })
                    setState(newObject)
                  }
                }}
                onChange={(e) => {
                  const newObject = produce(state, (draft: IApplicant360) => {
                    if (newNic) {
                      draft.newNicNumber = nicFormatFormat(e.target.value)
                    } else {
                      if (e.target.value.length < 10) {
                        draft.oldNicNumber = nicFormatFormat(e.target.value)
                      } else {
                        draft.oldNicNumber = e.target.value
                      }
                    }
                  })
                  setState(newObject)
                  const clearError = produce(errors, (draft) => {
                    draft.nicError = ''
                  })
                  setErrors(clearError)
                }}
                value={newNic ? state.newNicNumber : state.oldNicNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nicError}
              </Form.Control.Feedback>
            </Col>
            <Col className="col-auto">
              <button
                onClick={getData}
                className="action-button-layout m-0"
                disabled={isSearchDisabled}
              >
                Search
              </button>

              <button
                onClick={handleReset}
                className="action-button-layout m-0 ms-2"
              >
                Reset
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="page-tiles-layout nic-card">
        <Col sm={12} className="border-label">
          <div className="border-label-span">ORIMS Reference</div>
          <ReactTableWithPaginationComponent
            hover={false}
            columns={orimsColumns}
            data={orimsDetails}
            onClickRow={undefined}
          />
        </Col>
      </Row>
      <Row className="page-tiles-layout nic-card">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Psycho-Social Reference</div>
          <ReactTableWithPaginationComponent
            hover={false}
            columns={psychosociaColumns}
            data={psychosocialDetails}
            onClickRow={undefined}
          />
        </Col>
      </Row>
    </>
  )
}

export default Applicant360
