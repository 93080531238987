import {
  BreadCrumbAddRequest,
  ADD_BREAD_CRUMB,
  BreadCrumbSelectRequest,
  SELECT_BREAD_CRUMB,
  BreadCrumbPayload,
  ADD_BREAD_CRUMB_NAVIGATION,
  BreadCrumbNavigationAddRequest,
  BreadCrumbSelectedPayload,
  BreadCrumbListPayload,
  BreadCrumbListAddRequest,
  ADD_BREAD_CRUMB_LIST,
  RESET_BREAD_CRUMB,
  BreadCrumbResetRequest,
} from './actionTypes'

export const breadCrumbAddRequest = (
  payload: BreadCrumbPayload
): BreadCrumbAddRequest => ({
  type: ADD_BREAD_CRUMB,
  payload,
})

export const breadCrumbResetRequest = (): BreadCrumbResetRequest => ({
  type: RESET_BREAD_CRUMB,
})

export const breadCrumbSelectRequest = (
  payload: BreadCrumbSelectedPayload
): BreadCrumbSelectRequest => ({
  type: SELECT_BREAD_CRUMB,
  payload,
})

export const breadCrumbNavigationAddRequest = (
  payload: BreadCrumbPayload
): BreadCrumbNavigationAddRequest => ({
  type: ADD_BREAD_CRUMB_NAVIGATION,
  payload,
})

export const breadCrumbListAddRequest = (
  payload: BreadCrumbListPayload
): BreadCrumbListAddRequest => ({
  type: ADD_BREAD_CRUMB_LIST,
  payload,
})
