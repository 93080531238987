// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import { PdDependentDetailsModel } from '../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import {
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
} from '../../utils/utilFunctions'
import IdentificationTypesComponent from '../common/identificationComponent/IdentificationTypesComponent'

import LoadingComponent from '../common/loading/LoadingComponent'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import NextOfKinModal from '../modals/NextOfKinModal'

export interface SectionFourProps {
  fileRegistrationObject: FileRegistrationModel
  onOk?: any
  packageName?: string
  onSave?: any
  onNext?: any
}

const SectionFour: FC<SectionFourProps> = ({
  fileRegistrationObject,
  packageName = 'application-registration',
  onOk,
}) => {
  const [loading, setLoading] = useState(true)
  // const [selectDependentFirst, setSelectDependentFirst] = useState(true)
  const [dependentList, setDependentList] = useState([])
  const [dependentDetails, setDependentDetails] = useState(
    {} as PdDependentDetailsModel
  )
  useEffect(() => {
    async function getMetaData() {
      let dependentData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        dependentData = await enquireApplicationService.apiGetDependentDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        dependentData = await common.apiGetDependentDetails(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }

      if (dependentData.status == 200) {
        setDependentList(dependentData.data)
        if (dependentData.data.length == 1) {
          setDependentDetails(dependentData.data[0])
        }
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  const handlePayee = (id: number) => {
    // setSelectDependentFirst(false)
    const newValue = dependentList.filter(
      (dependent: PdDependentDetailsModel) => {
        return dependent.id === id
      }
    )[0]
    setDependentDetails(newValue)
  }
  // const handleNextOfKinButton = async (depended: PdDependentDetailsModel) => {
  //   if (depended.nextOfKinId > 0) {
  //     dispatch(changeLoadingRequest())
  //     const nextOfKinData =
  //       await pdService.apiPdGetDependantNextOfKinDetails(
  //         depended.applicationFileId,
  //         depended.id
  //       )
  //     if (nextOfKinData.status == 200) {
  //       setNextOfKinDetails(nextOfKinData.data)
  //       setNextOfKin(true)
  //     }
  //     dispatch(changeLoadingRequest())
  //   } else {
  //     const newNextOfKin = produce(
  //       nextOfKinDetails,
  //       (draft: NextOfKinModel) => {
  //         draft.dependentId = depended.id
  //         draft.applicationFileId = fileRegistrationObject.id
  //       }
  //     )
  //     setNextOfKinDetails(newNextOfKin)
  //     setNextOfKin(true)
  //   }
  // }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">{`Dependent & Bank Details`}</div>
            <Form id="file-dependent-details-form">
              {fileRegistrationObject.scheme == 'MP' ||
              fileRegistrationObject.scheme == 'PD' ||
              fileRegistrationObject.scheme == 'GSD' ? (
                <Form.Group className="mb-3" controlId="formDependentPdGsd">
                  <Form.Label>Select Dependent Name</Form.Label>
                  <Form.Control
                    value={dependentDetails.id}
                    className="form-select"
                    placeholder="Dependent Name"
                    as="select"
                    onChange={(e) => {
                      handlePayee(parseInt(e.target.value))
                    }}
                  >
                    {!dependentDetails.id ? (
                      <option value="">--Select Dependent--</option>
                    ) : (
                      ''
                    )}
                    {dependentList.map(
                      (dependent: PdDependentDetailsModel, index: number) => {
                        return (
                          <option
                            key={`payee-${index}-${dependent.id}`}
                            value={dependent.id}
                          >
                            {dependent.fullName}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                </Form.Group>
              ) : (
                <Form.Group className="mb-3" controlId="formGroupTitle">
                  <Form.Label>Dependent Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Dependent Name"
                    value={dependentDetails.fullName}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>
                  Title <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="Title"
                  value={dependentDetails.title}
                />
              </Form.Group>
              {fileRegistrationObject.scheme != 'GSI' && (
                <Form.Group as={Col} controlId="formRelationship">
                  <Form.Label>Relationship</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Relationship"
                    value={dependentDetails.relationship}
                  />
                </Form.Group>
              )}
              <Form.Group className="mb-3 mt-3" controlId="formSelection">
                <Form.Label>Identification Type</Form.Label>
                <IdentificationTypesComponent
                  isDisabled={true}
                  identificationType={dependentDetails.identificationType}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantDob">
                <Form.Label>Date of birth</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="Date of birth"
                  value={
                    dependentDetails.dateOfBirth
                      ? new Date(
                          dependentDetails.dateOfBirth * 1000
                        ).toLocaleDateString()
                      : ''
                  }
                />
              </Form.Group>
              {dependentDetails.identificationType != 'BIRTH_CERTIFICATE' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formNICNumber">
                      <Form.Label>NIC Number *</Form.Label>
                      <Row>
                        <Col sm={1}>
                          <Form.Check
                            inline
                            name="formNICNumberType"
                            label="New"
                            type="radio"
                            id={`formNICNumberType-N`}
                            disabled
                            checked={dependentDetails.newNic}
                          />
                        </Col>
                        <Col sm={1}>
                          <Form.Check
                            checked={!dependentDetails.newNic}
                            inline
                            name="formNICNumberType"
                            label="Old"
                            type="radio"
                            id={`formNICNumberType-O`}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          dependentDetails.newNic
                            ? dependentDetails.newNicNumber
                            : dependentDetails.oldNicNumber
                        }
                      />
                    </Form.Group>
                  </Row>
                  {dependentDetails.identificationType != 'NIC' && (
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formPSDNumber">
                        <Form.Label>
                          {renderIdentificationTypeName(
                            dependentDetails.identificationType
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={renderIdentificationTypeValue(
                            dependentDetails.identificationType,
                            dependentDetails
                          )}
                        />
                      </Form.Group>
                    </Row>
                  )}
                </>
              )}

              <Form.Group className="mb-3" controlId="idNumberRemark">
                <Form.Label>ID Number Remark</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={dependentDetails.idNumberRemark}
                />
              </Form.Group>

              {dependentDetails.nextOfKinId != null && (
                <Col sm={12} className="border-label mt-4">
                  <div className="border-label-span">Next of Kin Details</div>
                  <Row className="mb-3">
                    <Form.Group controlId="formNameofNextofKin">
                      <Form.Label>Name of ‘Next of Kin’</Form.Label>
                      <Form.Control
                        disabled
                        placeholder="Name of ‘Next of Kin"
                        type="text"
                        value={
                          dependentDetails.nextOfKin
                            ? dependentDetails.nextOfKin.name
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formPermanentAddress">
                      <Form.Label>Permanent Address</Form.Label>
                      <Form.Control
                        disabled
                        placeholder="Permanent Address"
                        type="text"
                        value={
                          dependentDetails.nextOfKin
                            ? dependentDetails.nextOfKin.permanentAddress
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formNICNumber">
                      <Row>
                        <Col>
                          <Form.Label>Old NIC Number</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Old NIC Number"
                            type="text"
                            value={
                              dependentDetails.nextOfKin
                                ? dependentDetails.nextOfKin.oldNicNumber
                                : ''
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Label>New NIC Number</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="New NIC Number"
                            type="text"
                            value={
                              dependentDetails.nextOfKin
                                ? dependentDetails.nextOfKin.newNicNumber
                                : ''
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group controlId="formRelationship">
                      <Form.Label>
                        Relationship to the Deceased person
                      </Form.Label>
                      <Form.Control
                        disabled
                        placeholder="Relationship to the Deceased person"
                        type="text"
                        value={
                          dependentDetails.nextOfKin
                            ? dependentDetails.nextOfKin.relationship
                            : ''
                        }
                      />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group controlId="formRelationship">
                          <Form.Label>Bank Name</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Bank Name"
                            type="text"
                            value={
                              dependentDetails.nextOfKin
                                ? dependentDetails.nextOfKin.bank.description
                                : ''
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formRelationship">
                          <Form.Label>Branch Name</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Branch Name"
                            type="text"
                            value={
                              dependentDetails.nextOfKin
                                ? dependentDetails.nextOfKin.branch.description
                                : ''
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="formRelationship">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        disabled
                        placeholder="Account Number"
                        type="text"
                        value={
                          dependentDetails.nextOfKin
                            ? dependentDetails.nextOfKin.accountNumber
                            : ''
                        }
                      />
                    </Form.Group>
                  </Row>
                </Col>
              )}
              <Col sm={12} className="border-label mt-4 mb-4">
                <div className="border-label-span">
                  Birth Certificate Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>Birth Certificate Number</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Birth Certificate Number"
                      value={dependentDetails.birthCertificateNumber}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDpBirthCDsDivision">
                    <Form.Label>Ds Division of Birth Certificate</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Ds Division of Birth Certificate"
                      value={
                        dependentDetails.birthCertificateDsDivision
                          ? dependentDetails.birthCertificateDsDivision.name
                          : ''
                      }
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formDpAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Age"
                    value={dependentDetails.age}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    placeholder="Gender"
                    disabled
                    value={dependentDetails.gender}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpMaritalStatus">
                  <Form.Label>Marital Status</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Marital Status"
                    value={dependentDetails.maritalStatus}
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formDpAddress">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Address"
                  type="text"
                  value={dependentDetails.address}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDpCity">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Address"
                  type="text"
                  value={dependentDetails.city}
                />
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formDpProvince">
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    disabled
                    placeholder="Province"
                    type="text"
                    value={
                      dependentDetails.province
                        ? dependentDetails.province?.name
                        : ''
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpDistrict">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    disabled
                    placeholder="District"
                    type="text"
                    value={
                      dependentDetails.district
                        ? dependentDetails.district?.name
                        : ''
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpDsDivision">
                  <Form.Label>{`DS Division`}</Form.Label>
                  <Form.Control
                    disabled
                    placeholder="DS Division"
                    type="text"
                    value={
                      dependentDetails.dsDivision
                        ? dependentDetails.dsDivision?.name
                        : ''
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDpGnDivision">
                  <Form.Label>{`GN Division`}</Form.Label>
                  <Form.Control
                    disabled
                    placeholder="GN Division"
                    type="text"
                    value={
                      dependentDetails.gnDivision
                        ? dependentDetails.gnDivision?.name
                        : ''
                    }
                  />
                </Form.Group>
              </Row>

              {!(fileRegistrationObject.scheme === 'GSI') && (
                <Form.Group className="mb-3" controlId="formDpBothParent">
                  <Form.Label>Both parents are deceased</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        disabled
                        inline
                        label="Yes"
                        name="formBothParent"
                        type="radio"
                        id={`both-1`}
                        checked={dependentDetails.bothParentsDeceased}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        disabled
                        inline
                        label="No"
                        name="formBothParent"
                        type="radio"
                        id={`both-2`}
                        checked={!dependentDetails.bothParentsDeceased}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}
              <Form.Group className="mb-3" controlId="compensationPercentage">
                <Form.Label>Compensation Percentage (%)</Form.Label>
                <Form.Control
                  disabled
                  placeholder="Compensation Percentage"
                  type="text"
                  value={
                    dependentDetails.amountPercentage
                      ? dependentDetails.amountPercentage
                      : 'N/A'
                  }
                />
              </Form.Group>
              <Col sm={12} className="border-label mt-4 mb-3">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>{`Bank Name`}</Form.Label>
                    <Form.Control
                      disabled
                      placeholder="Bank Name"
                      type="text"
                      value={
                        dependentDetails.bank
                          ? dependentDetails.bank.description
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>{`Branch Name`}</Form.Label>
                    <Form.Control
                      disabled
                      placeholder="Branch Name"
                      type="text"
                      value={
                        dependentDetails.branch
                          ? dependentDetails.branch.description
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpAccountNumber">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      disabled
                      placeholder="Account Number"
                      type="text"
                      value={dependentDetails.accountNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default SectionFour
