// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable prefer-const */
import produce from 'immer'
import { createSelector } from 'reselect'
import { ActionListModal } from '../../models/package/actionListModal'
import { ModuleModal } from '../../models/package/moduleModal'
import { PackageModal } from '../../models/package/packageModal'
import { SubModuleModal } from '../../models/package/subModuleModal'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { AppState } from '../rootReducer'
import { IRole } from '../usermanagement/actionTypes'

export const getPermissionList = (state: AppState) =>
  state.permissionsListState.permissions

export const getSelectedPackages = createSelector(
  getPermissionList,
  (packageList) => {
    const selectSelectedPackages: PackageModal[] = []

    if (packageList.length == 0) {
      return selectSelectedPackages
    } else {
      for (let i = 0; i < packageList.length; i++) {
        let isPackageSelected = false
        const packageModal = packageList[i]
        let packageObject = {} as PackageModal
        packageObject.id = packageModal.id
        packageObject.name = packageModal.name
        packageObject.description = packageModal.description
        const selectedPackageActionList = packageModal.actionList.filter(
          (packageAction) => {
            return packageAction.isSelected === true
          }
        )

        if (selectedPackageActionList.length > 0) {
          packageObject.actionList = selectedPackageActionList
          isPackageSelected = true
        }
        const selectedSubPackageList: SubPackageModal[] = []
        for (let j = 0; j < packageModal.subPackageList.length; j++) {
          let isSubPackageSelected = false
          const subPackageModal = packageModal.subPackageList[j]
          let subPackageObject = {} as SubPackageModal
          subPackageObject.id = subPackageModal.id
          subPackageObject.name = subPackageModal.name
          subPackageObject.description = subPackageModal.description
          const selectedSubPackageActionList =
            subPackageModal.actionList.filter((subpackageAction) => {
              return subpackageAction.isSelected === true
            })
          if (selectedSubPackageActionList.length > 0) {
            isSubPackageSelected = true
            subPackageObject.actionList = selectedSubPackageActionList
          }
          const selectedModuleList: ModuleModal[] = []
          for (let k = 0; k < subPackageModal.moduleList.length; k++) {
            let isModuleSelected = false
            const moduleModel = subPackageModal.moduleList[k]
            let moduleObject = {} as ModuleModal
            moduleObject.id = moduleModel.id
            moduleObject.name = moduleModel.name
            moduleObject.description = moduleModel.description
            const selectedModuleActionList = moduleModel.actionList.filter(
              (moduleAction) => {
                return moduleAction.isSelected === true
              }
            )
            if (selectedModuleActionList.length > 0) {
              isModuleSelected = true
              moduleObject.actionList = selectedModuleActionList
            }
            const selectedSubModuleList: SubModuleModal[] = []
            for (let l = 0; l < moduleModel.subModuleList.length; l++) {
              let isSubModuleSelected = false
              const subModuleModel = moduleModel.subModuleList[l]
              let subModuleObject = {} as SubModuleModal
              subModuleObject.id = subModuleModel.id
              subModuleObject.name = subModuleModel.name
              subModuleObject.description = subModuleModel.description
              const selectedSubModuleActionlist =
                subModuleModel.actionList.filter((subModuleAction) => {
                  return subModuleAction.isSelected === true
                })
              if (selectedSubModuleActionlist.length > 0) {
                isSubModuleSelected = true
                subModuleObject.actionList = selectedSubModuleActionlist
                if (isSubModuleSelected) {
                  selectedSubModuleList.push(subModuleObject)
                }
              }
            }

            if (selectedSubModuleList.length > 0) {
              isModuleSelected = true
            }
            moduleObject.subModuleList = selectedSubModuleList

            if (isModuleSelected) {
              selectedModuleList.push(moduleObject)
            }
          }
          if (selectedModuleList.length > 0) {
            isSubPackageSelected = true
          }
          subPackageObject.moduleList = selectedModuleList

          if (isSubPackageSelected) {
            selectedSubPackageList.push(subPackageObject)
          }
        }
        if (selectedSubPackageList.length > 0) {
          isPackageSelected = true
        }
        packageObject.subPackageList = selectedSubPackageList

        if (isPackageSelected) {
          selectSelectedPackages.push(packageObject)
        }
      }

      return selectSelectedPackages
    }
  }
)

export const selectedPermisionsByRoleId = (id: number) => (state: AppState) => {
  const role = state.userManagement.roles.filter((role: IRole) => {
    return role.id === id
  })[0]
  let packagesList: PackageModal[] = []
  let permissionList = state.permissionsListState
  for (let i = 0; i < permissionList.permissions.length; i++) {
    let permissionStateObject = permissionList.permissions[i]
    let newPackage: PackageModal = {
      subPackageList: [],
      actionList: permissionStateObject.actionList,
      description: permissionStateObject.description,
      id: permissionStateObject.id,
      name: permissionStateObject.name,
      isSelected: false,
    }
    let filterPermission = role.permissions.packageList.filter(
      (permissionModal: PackageModal) => {
        return permissionModal.id == permissionStateObject.id
      }
    )[0]
    if (filterPermission !== undefined) {
      if (filterPermission.actionList.length > 0) {
        newPackage = produce(permissionStateObject, (draft) => {
          draft.actionList.map((action) => {
            if (
              filterPermission.actionList.filter(
                (filterAction: ActionListModal) => {
                  return action.id === filterAction.id
                }
              )[0] != null
            ) {
              action.isSelected = true
            }
          })
        })
      }
      if (filterPermission.subPackageList.length > 0) {
        let subPackagesList: SubPackageModal[] = []
        for (let i = 0; i < permissionStateObject.subPackageList.length; i++) {
          let subPackageObject = permissionStateObject.subPackageList[i]
          let newSubPackgeObject: SubPackageModal = {
            description: subPackageObject.description,
            id: subPackageObject.id,
            packageId: subPackageObject.packageId,
            name: subPackageObject.name,
            actionList: subPackageObject.actionList,
            moduleList: [],
          }
          let filterSubpackage = filterPermission.subPackageList.filter(
            (subPackageModal) => {
              return subPackageModal.id === subPackageObject.id
            }
          )[0]

          if (filterSubpackage !== undefined) {
            if (subPackageObject.actionList.length > 0) {
              newSubPackgeObject = produce(subPackageObject, (draft) => {
                draft.actionList.map((action) => {
                  if (
                    filterSubpackage.actionList.filter(
                      (filterAction: ActionListModal) => {
                        return action.id === filterAction.id
                      }
                    )
                  ) {
                    action.isSelected = true
                  }
                })
              })
            }
            if (filterSubpackage.moduleList.length > 0) {
              let moduleList: ModuleModal[] = []
              for (let i = 0; i < filterSubpackage.moduleList.length; i++) {
                let moduleObject = filterSubpackage.moduleList[i]
                let newModuleObject: ModuleModal = {
                  id: moduleObject.id,
                  subPackageId: moduleObject.subPackageId,
                  name: moduleObject.name,
                  description: moduleObject.description,
                  actionList: moduleObject.actionList,
                  subModuleList: moduleObject.subModuleList,
                }
                let filterModule = filterSubpackage.moduleList.filter(
                  (module) => {
                    return module.id === moduleObject.id
                  }
                )[0]

                if (filterModule != null) {
                  if (filterModule.actionList.length > 0) {
                    newModuleObject = produce(moduleObject, (draft) => {
                      draft.actionList.map((action) => {
                        if (
                          filterModule.actionList.filter(
                            (filterAction: ActionListModal) => {
                              return action.id === filterAction.id
                            }
                          )
                        ) {
                          action.isSelected = true
                        }
                      })
                    })
                  }
                  if (filterModule.subModuleList.length > 0) {
                    let subModuleList: SubModuleModal[] = []
                    for (
                      let i = 0;
                      i < filterModule.subModuleList.length;
                      i++
                    ) {
                      let subModuleObject = filterModule.subModuleList[i]
                      let newSubModuleObject: SubModuleModal = {
                        id: subModuleObject.id,
                        moduleId: subModuleObject.moduleId,
                        name: subModuleObject.name,
                        description: subModuleObject.description,
                        actionList: subModuleObject.actionList,
                      }
                      let filterSubModule = filterModule.subModuleList.filter(
                        (subModule) => {
                          return subModule.id === subModuleObject.id
                        }
                      )[0]

                      if (filterSubModule != null) {
                        if (filterSubModule.actionList.length > 0) {
                          newSubModuleObject = produce(
                            subModuleObject,
                            (draft) => {
                              draft.actionList.map((action) => {
                                if (
                                  filterSubModule.actionList.filter(
                                    (filterAction: ActionListModal) => {
                                      return action.id === filterAction.id
                                    }
                                  ) != null
                                ) {
                                  action.isSelected = true
                                }
                              })
                            }
                          )
                        }
                      }
                      subModuleList.push(newSubModuleObject)
                    }

                    // for (let j = 0; j < subModuleList.length; j++) {
                    //   newModuleObject.subModuleList.push(subModuleList[j])
                    // }
                  }
                }
                moduleList.push(newModuleObject)
              }
              newSubPackgeObject.moduleList = moduleList
            }
          }
          subPackagesList.push(newSubPackgeObject)
        }
        newPackage.subPackageList = subPackagesList
      }
    }
    packagesList.push(newPackage)
  }

  return packagesList
}
