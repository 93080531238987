import { AffectedPropertyModel } from '../../../models/applicationRegistration/compensation/affectedPropertyModel'
import { BankDetailsModel } from '../../../models/applicationRegistration/compensation/bankDetailsModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GscDataService {
  async apiFetchGscList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsc/view/list`,
      filterData
    )
  }

  async apiFetchGscListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsc/view/count`,
      filterData
    )
  }

  async apiFetchGscReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsc/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGscReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsc/file-return/view/count`,
      filterData
    )
  }

  async apiFetchGscFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsc/view/${id}`
    )
  }

  async apiGscProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/proceed`,
      file
    )
  }

  async apiGscModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/file-return/modify/${file.id}`,
      file
    )
  }

  async apiGscReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/${id}/reject`,
      { remark: remark }
    )
  }

  async apiGscReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/${id}/return`,
      { remark: remark }
    )
  }

  async apiGscClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiGscReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiGscMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/${id}/merge`,
      idList
    )
  }

  async apiGscAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/applicant`,
      file
    )
  }

  async apiGscAddAffectedPropertyDetails(
    file: AffectedPropertyModel,
    id: number
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/${id}/affected_property`,
      file
    )
  }

  async apiGscAddBankDetails(file: BankDetailsModel, id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/${id}/bank`,
      file
    )
  }

  async apiGetGscAffectedPropertyData(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsc/view/${id}/affected_property`
    )
  }
  async apiGetGscBankData(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsc/view/${id}/bank`
    )
  }
  async apiGscGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsc/view/${id}/document`
    )
  }
  async apiGscAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/gsc/add/${id}/document`,
      formData
    )
  }

  async apiGscRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsc/add/${id}/register`,
      {}
    )
  }
}

export default new GscDataService()
