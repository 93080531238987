import { IDsDivision } from '../models/administrativeArea/IDsDivision'
import { IGnDivision } from '../models/administrativeArea/IGnDivision'
import { ICompensationValueDtoModel } from '../models/systemConfiguration/compensationValues/compensationValueDtoModel'
import apiService from './api/apiManager'

class MetaDataService {
  /**
   * Title List get function.
   */
  async apiTitleListFetch() {
    return apiService.apiGet('/meta-data/title/list')
  }

  /**
   * Organization get function.
   */
  async apiOrganizationFetch() {
    return apiService.apiGet('/meta-data/organization/list')
  }

  /**
   * Designation get function.
   */
  async apiDesignationFetch() {
    return apiService.apiGet('/meta-data/designation/list')
  }

  /**
   * Provinces get function.
   */
  async apiProvincesFetch() {
    return apiService.apiGet('/meta-data/province/list')
  }

  /**
   * Districts get function.
   */
  async apiDistrictsFetch() {
    return apiService.apiGet('/meta-data/district/list')
  }

  /**
   * DS-Divisions get function.
   */
  async apiDSDivisionsFetch() {
    return apiService.apiGet('/meta-data/ds-division/list')
  }

  /**
   * GN-Divisions get function.
   */
  async apiGNDivisionsFetch() {
    return apiService.apiGet('/meta-data/gn-division/list')
  }

  /**
   * Districts by province get function.
   */
  async apiDistrictsByProvinceFetch(id: number) {
    return apiService.apiGet(`/meta-data/province/${id}/district/list`)
  }

  async apiGetDsListByDistrictId(districtId: string) {
    return apiService.apiGet(
      `/meta-data/district/${districtId}/ds-division/list`
    )
  }

  async apiGetGnListByDsId(dsDivisionId: string) {
    return apiService.apiGet(
      `/meta-data/ds-division/${dsDivisionId}/gn-division/list`
    )
  }

  async apiAddDsDivistion(dsDivision: IDsDivision) {
    return apiService.apiPost(`/meta-data/ds-division/add`, dsDivision)
  }

  async apiUpdateDsDivistion(id: string, dsDivision: IDsDivision) {
    return apiService.apiPutCommon(
      `/meta-data/ds-division/modify/${id}`,
      dsDivision
    )
  }

  async apiDeleteDsDivistion(id: string) {
    return apiService.apiDeleteCommon(`/meta-data/ds-division/delete/${id}`)
  }

  async apiAddGnDivistion(gnDivision: IGnDivision) {
    return apiService.apiPost(`/meta-data/gn-division/add`, gnDivision)
  }

  async apiUpdateGnDivistion(id: string, gnDivision: IGnDivision) {
    return apiService.apiPutCommon(
      `/meta-data/gn-division/modify/${id}`,
      gnDivision
    )
  }

  async apiDeleteGnDivistion(id: string) {
    return apiService.apiDeleteCommon(`/meta-data/gn-division/delete/${id}`)
  }
  /**
   * DS by district get function.
   */
  // async apiDsByDistrictFetch(id: number) {
  //   return apiService.apiGet(`/district/${id}/ds-division/list`)
  // }

  /**
   * GN by Ds get function.
   */
  // async apiGnByDsFetch(id: number) {
  //   return apiService.apiGet(`ds-division/${id}/gn-division/list`)
  // }

  /**
   * All Permission List get function.
   */

  async apiPermissionListFetch() {
    return apiService.apiGet('/system-configuration/package/list')
  }

  /**
   * Get Role list function.
   */
  async apiGetRolesList() {
    return apiService.apiGet(`/user-management/role-and-permission/list`)
  }

  /**
   * Get Incident type list function.
   */
  async apiIncidentTypeList() {
    return apiService.apiGet(`/meta-data/incident-type/list/${'Active'}`)
  }
  async apiIncidentcategoryList() {
    return apiService.apiGet(`/meta-data/incident-category/list`)
  }
  async apiGetSchemeListBySchemeCategory(schemeCatagory: string) {
    return apiService.apiGet(
      `/system-configuration/scheme/scheme-category/${schemeCatagory}`
    )
  }
  async apiGetSchemeCategory() {
    return apiService.apiGet(`/meta-data/scheme-category/list`)
  }
  async apiGetCommunityType() {
    return apiService.apiGet(`/meta-data/community-type/list`)
  }
  async apiGetTypeOfDisablementList() {
    return apiService.apiGet(`/meta-data/type-of-disablement/list`)
  }
  async apiGetCategoryOfInjuryList() {
    return apiService.apiGet(`/meta-data/category-of-injury/list`)
  }
  async apiGetInjuryList() {
    return apiService.apiGet(`/meta-data/type-of-injury/list/${'Active'}`)
  }
  async apiGetDisablementPeriodList() {
    return apiService.apiGet(`/meta-data/disablement-period/list`)
  }
  async apiGetSchemeList(id: number) {
    return apiService.apiGet(`/meta-data/incident-category/${id}/scheme/list`)
  }
  async apiGetAllSchemesList() {
    return apiService.apiGet('/system-configuration/scheme/list')
  }
  async getFileTypeList() {
    return apiService.apiGet('/meta-data/file-type/list')
  }
  async apiGetUserListByDsDivision(id: number) {
    return apiService.apiGet(`/meta-data/ds_division/${id}/user-list`)
  }
  async apiGetActiveUserList() {
    return apiService.apiGet(`/meta-data/active-user-list`)
  }
  //get action type list reject/return reasons
  async getActionTypeList() {
    return apiService.apiGet(`/meta-data/action-type/list`)
  }
  //get reason type list reject/return reasons
  async getReasonTypeList() {
    return apiService.apiGet(`/meta-data/reason-type/list`)
  }
  //get Property Category
  async getPropertyCategoryList() {
    return apiService.apiGet(`/meta-data/property-category/list`)
  }
  //get Property Field
  async getPropertyFieldList() {
    return apiService.apiGet(`/meta-data/property-field/list`)
  }

  async getBankList() {
    return apiService.apiGet('/meta-data/bank-name/list/Active')
  }
  async getAllBranchList() {
    return apiService.apiGet('/meta-data/branch/list/Active')
  }
  async getBranchesList(id: number) {
    console.log(id)
    return apiService.apiGet(`/meta-data/bank/${id}/branch-list/Active`)
  }
  async getMaritalList() {
    return apiService.apiGet('/meta-data/marital-status/list')
  }
  async getRelationshipList() {
    return apiService.apiGet('/meta-data/relationship/list')
  }
  async getApplicantTypeList() {
    return apiService.apiGet('/meta-data/applicant-type/list')
  }
  async getEducationalList() {
    return apiService.apiGet('/meta-data/education-level/list')
  }
  async getDamageTypeList() {
    return apiService.apiGet('/meta-data/damage-type/list')
  }
  async getPoliceStationList() {
    return apiService.apiGet('/meta-data/police-station/list')
  }
  async getSupportingDocumentListByScheme(scheme: string) {
    return apiService.apiGet(
      `/system-configuration/supporting-document/scheme/${scheme}/list`
    )
  }
  async getPaymentTypeList() {
    return apiService.apiGet(`/meta-data/payment-type/list`)
  }

  async getDependentInstallmentAmountList(id: number, dependent_id: number) {
    return apiService.apiGet(
      `/meta-data/application-file/${id}/dependent/${dependent_id}/installment-amount/list`
    )
  }

  async getApplicationInstallmentAmountList(id: number) {
    return apiService.apiGet(
      `/meta-data/application-file/${id}/installment-amount/list`
    )
  }

  async getSchemeListByProgramType(programType: string) {
    return apiService.apiGet(
      `/meta-data/program-type/${programType}/scheme/list`
    )
  }

  async getSchemeListByProgramTypeAndStatus(
    programType: string,
    status: string
  ) {
    return apiService.apiGet(
      `/meta-data/program-type/${programType}/scheme/${status}/list`
    )
  }
  async apiGetCompensationValue(compensationData: ICompensationValueDtoModel) {
    return apiService.apiPost(
      `/meta-data/compensation-amount`,
      compensationData
    )
  }

  async getPropertyTypesByScheme(scheme: string) {
    return apiService.apiGet(`/meta-data/scheme/${scheme}/property/list`)
  }

  async getStampChargeList() {
    return apiService.apiGet(
      `/system-configuration/manage-stamp-charges/view/list`
    )
  }

  async downloadFile(url: string) {
    return apiService.apiDownloadFile(`${url}`)
  }

  async apiInstallmentListToSupportDocument(fileId: number) {
    return apiService.apiGet(
      `/meta-data/application-file/${fileId}/installment-amount/list`
    )
  }

  async apiGetRejectReturnReasons(data: any) {
    return apiService.apiPost(`/meta-data/reject-return-reason/list`, data)
  }

  async apiNatureOfSelfEmployementCategories() {
    return apiService.apiGet(
      `/meta-data/nature-of-self-employment/category/list`
    )
  }

  async apiNatureOfSelfEmployementSubCategories(categoryId: number) {
    return apiService.apiGet(
      `/meta-data/nature-of-self-employment/category/${categoryId}/sub-category/list`
    )
  }

  async apiGetOccupationList() {
    return apiService.apiGet(`/meta-data/occupation-type/list`)
  }

  async apiCommonAdvancedSearch(path: string, data: any) {
    return apiService.apiPost(path, data)
  }
}

export default new MetaDataService()
