import {
  SetDashboardFilterPayload,
  SET_STATUS_FILTERS,
  UpdateDashboardFilterPayload,
  StatusFiltersUpdateRequest,
  UPDATE_STATUS_FILTER,
  TimeFiltersUpdateRequest,
  UPDATE_TIME_FILTER,
  DashboardFiltersSetRequest,
  RESET_TIME_FILTER,
} from './actionTypes'

export const DashboardFiltersSetRequestAction = (
  payload: SetDashboardFilterPayload
): DashboardFiltersSetRequest => ({
  type: SET_STATUS_FILTERS,
  payload,
})

export const DashboardStatusFilterUpdateRequest = (
  payload: UpdateDashboardFilterPayload
): StatusFiltersUpdateRequest => ({
  type: UPDATE_STATUS_FILTER,
  payload,
})

export const DashboardTimeFilterUpdateRequest = (
  payload: UpdateDashboardFilterPayload
): TimeFiltersUpdateRequest => ({
  type: UPDATE_TIME_FILTER,
  payload,
})

export const ResetTimeFilterRequest = (): DashboardFiltersSetRequest => ({
  type: RESET_TIME_FILTER,
})
