import apiService from './api/apiManager'

class AuthDataService {
  /**
   * Login post function.
   * @param {Object} user user.
   * @param {string} username user username.
   * @param {string} password user password.
   */
  async apiLoginPost(user: { username: string; password: string }) {
    return apiService.apiPost('/auth/login', user)
  }

  /**
   * Login post function.
   * @param {Object} user user.
   * @param {string} username user username.
   * @param {string} password user password.
   */
  async apiCheckAuth() {
    return apiService.apiPost('/auth/authenticate', {})
  }

  /**
   * Autherization post function.
   * @param {string} route route.
   */
  async apiCheckAutharization(data: { route: string }) {
    console.log('hi')
    console.log(data)
    switch (data.route) {
      case '/application-registration':
        return true
      case '/user-management':
        return true
      case '/dashboard':
        return true
      case '/user-management/user':
        return true
      case '/user-management/role-and-permission/modify-role-permision/:id':
        return true
      default:
        return true
    }
  }

  async resetPassword(username: string) {
    return apiService.apiPost('/auth/reset-password/' + username, '')
  }
}

export default new AuthDataService()
