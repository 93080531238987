import { manageStampChargeModel } from '../../../models/systemConfiguration/manageStampCharges/manageStampChargeModel'
import apiService from '../../api/apiManager'

class ManageStampCharges {
  async apiAddStampCharges(stampCharge: manageStampChargeModel) {
    return apiService.apiPost(
      '/system-configuration/manage-stamp-charges/add',
      stampCharge
    )
  }

  async apiGetStampChargeList() {
    return apiService.apiGet(
      '/system-configuration/manage-stamp-charges/view/list'
    )
  }

  async apiGetStampChargeByID(id: number) {
    return apiService.apiGet(
      '/system-configuration/manage-stamp-charges/view/' + id
    )
  }
}

export default new ManageStampCharges()
