import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class TypeOfInjuriesServices {
  async apiFetchTypeOfInjuriesList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/type-of-injury/list`,
      filterData
    )
  }

  async apiFetchTypeOfInjuriesListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/type-of-injury/count`,
      filterData
    )
  }
  async apiGetAllTypeOfInjuriesList() {
    return apiService.apiGet('/system-configuration/type-of-injury/list')
  }
  async apiGetTypeOfInjuriesById(id: number) {
    return apiService.apiGet(`/system-configuration/type-of-injury/view/${id}`)
  }
  async apiGetTypeOfInjuriesHistory(id: number) {
    return apiService.apiGet(
      `/system-configuration/type-of-injury/${id}/history`
    )
  }
  async apiAddInjuryType(injury: any) {
    return apiService.apiPost(
      '/system-configuration/type-of-injury/add',
      injury
    )
  }
  async apiActiveInjuryType(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/type-of-injury/${id}/active`
    )
  }
  async apiInactiveInjuryType(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/type-of-injury/${id}/inactive`
    )
  }
  async apiUpdateInjuryType(id: number | undefined, injuryType: any) {
    return apiService.apiPutCommon(
      `/system-configuration/type-of-injury/modify/${id}`,
      injuryType
    )
  }
}
export default new TypeOfInjuriesServices()
