import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import './ViewSchemes.scss'
import { SchemeModel } from '../../../models/systemConfiguration/schemes/schemeModel'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import { schemeActive, schemeInActive } from '../../../utils/successMessages'
import schemeService from '../../../services/systemConfiguration/schemes/schemeService'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
type TParams = { code: string }
const ViewSchemes = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [scheme, setScheme] = useState({} as SchemeModel)
  const [audiHistory, setAudiHistory] = useState([])
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'scheme')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getSchemesData() {
      const schemesData = await schemeService.apiGetScheme(match.params.code)
      const schemeHistoryData = await schemeService.apiGetSchemeHistory(
        match.params.code
      )
      setScheme(schemesData.data)
      setAudiHistory(schemeHistoryData.data)
      setloading(false)
    }
    getSchemesData()
  }, [])
  let statusAction = ''
  if (scheme.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (scheme.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const activeData = await schemeService.apiInactiveScheme(
        match.params.code
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = schemeInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setScheme(activeData.data)
    }
    if (e == 'Active') {
      const inActiveData = await schemeService.apiActiveScheme(
        match.params.code
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = schemeActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setScheme(inActiveData.data)
    }
    const schemeHistoryData = await schemeService.apiGetSchemeHistory(
      match.params.code
    )
    setAudiHistory(schemeHistoryData.data)
  }
  const adutHistoryColumns = React.useMemo(
    () => [
      {
        Header: 'Scheme Code',
        accessor: 'code',
      },
      {
        Header: 'Scheme Description',
        accessor: 'description', // accessor is the "key" in the data
      },
      {
        Header: 'Community Type',
        accessor: 'communityType',
      },
      {
        Header: 'Scheme Category',
        accessor: 'schemeCategory',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },

      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
    ],
    []
  )
  const handleModify = () => {
    history.push(
      `/system-configuration/scheme/modify-schemes/${match.params.code}`
    )
  }
  // const renderInstallment = () => {
  //   return scheme.schemeInstallmentList.map((installment) => {
  //     return (
  //       <Row key={`Row-${installment.id}`}>
  //         <Col
  //           key={`Col-${installment.id}`}
  //           className="installment-margin-left"
  //         >
  //           <Form.Group className="mb-3">
  //             <FormLabel key={installment.id}>
  //               Installment No: {installment.installmentNumber}
  //             </FormLabel>
  //             <Form.Control
  //               type="text"
  //               disabled
  //               value={`${installment.installmentPercentage} %`}
  //             />
  //           </Form.Group>
  //         </Col>
  //       </Row>
  //     )
  //   })
  // }
  const renderAuditHistoryTable = () => {
    if (audiHistory) {
      return (
        <ReactTableWithPaginationComponent
          columns={adutHistoryColumns}
          data={audiHistory}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <span>Audit history not found</span>
    }
  }
  return (
    <>
      {loading ? (
        <Container className="scheme-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-scheme-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View Schemes</div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Status</Form.Label>
                    <Form.Control type="text" disabled value={scheme.status} />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') === true ||
                    checkPermission('Active') === true ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Program Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={scheme.programType}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Scheme Code</Form.Label>
                    <Form.Control type="text" disabled value={scheme.code} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Scheme Description</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={scheme.description}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Community Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={scheme.communityType}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Scheme Category</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={scheme.schemeCategory}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={new Date(
                        scheme.startDate * 1000
                      ).toLocaleDateString()}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        scheme.endDate == null
                          ? '-'
                          : new Date(scheme.endDate * 1000).toLocaleDateString()
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Audit History</Form.Label>
                  {renderAuditHistoryTable()}
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="ok-button"
                  onClick={(e: any) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Ok
                </button>
                {checkPermission('modify') === true &&
                  scheme.status === 'Active' && (
                    <button className="modify-button" onClick={handleModify}>
                      Modify
                    </button>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewSchemes
