import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { ActionListModal } from '../../../models/package/actionListModal'
import { IPoliceStation } from '../../../models/systemConfiguration/mangePoliceStations/policeStation'
import PoliceStationServices from '../../../services/systemConfiguration/manegePoliceStation/policeStationServices'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import {
  policeStationActive,
  policeStationInActive,
} from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
type TParams = { id: string }
const ViewPoliceStations = ({ match }: RouteComponentProps<TParams>) => {
  const [state, setSate] = useState({} as IPoliceStation)
  const history = useHistory()

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'police-station')
  )

  // const actionList = useSelector(getUserPackageActions('police-station'))

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    async function getData() {
      const result = await PoliceStationServices.apiGetPoliceStation(
        parseInt(match.params.id)
      )
      setSate(result.data)
    }
    getData()
  }, [])

  const handleModify = () => {
    history.push(
      `/system-configuration/police-station/modify/${match.params.id}`
    )
  }

  let statusAction = ''
  if (state.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (state.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData = await PoliceStationServices.apiInactivePoliceStation(
        parseInt(match.params.id)
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = policeStationInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setSate(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData = await PoliceStationServices.apiActivePoliceStation(
        parseInt(match.params.id)
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = policeStationActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setSate(activeData.data)
    }
  }

  return (
    <>
      <Row className="add-Incident-Type-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">View Police Station</div>
          <Form>
            <Row className="mb-4">
              <Col sm={11}>
                <Form.Group className="mb-3" controlId="formGroupTitle">
                  <Form.Label>Status</Form.Label>
                  <Form.Control type="text" disabled value={state.status} />
                </Form.Group>
              </Col>
              <Col sm={1} className="text-align-righ mt-2">
                <Form.Group className="mb-3" controlId="formGroupTitle">
                  <Form.Label></Form.Label>
                  {checkPermission('inactive') === true ||
                  checkPermission('Active') === true ? (
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={{ lg: 'end' }}>
                        <Dropdown.Item eventKey={statusAction}>
                          {statusAction}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="stampCharge">
              <Form.Label>Name</Form.Label>
              <Form.Control disabled type="text" value={state.name} />
            </Form.Group>
          </Form>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="cancel-button"
                onClick={(e) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Ok
              </button>
              {checkPermission('modify') === true && (
                <button
                  type="submit"
                  className="save-button"
                  onClick={handleModify}
                >
                  Modify
                </button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ViewPoliceStations
