import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Dropdown, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  // Link,
  // Route,
  RouteComponentProps,
  // Switch,
  useHistory,
} from 'react-router-dom'
import './ViewRoleComponent.scss'
import userManagementService from '../../../services/userManagementService'
import { IRole } from '../../../store/usermanagement/actionTypes'
import RolePermissionsComponent from './RolePermissionsComponent'
import { fetchPermissionsLisSuccess } from '../../../store/permissions/actions'
import metaDataService from '../../../services/metaDataService'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import { roleActive, roleInActive } from '../../../utils/successMessages'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
// import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
// import CreateRolesAndPermissionsComponent from './CreateRolesAndPermissionsComponent'
type TParams = { id: string }
const ViewRoleComponent = ({ match }: RouteComponentProps<TParams>) => {
  // const role = useSelector(getRolesById(parseInt(match.params.id)))
  const [role, setRole] = useState({} as IRole)
  const [roleHistory, setRoleHistory] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('user-management', 'role-and-permission')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    async function getRoleHistory() {
      const roleData = await userManagementService.apiFetchRole(
        parseInt(match.params.id)
      )
      setRole(roleData.data)
      const roleHistoryData = await userManagementService.apiRoleHistoryFetch(
        parseInt(match.params.id)
      )

      setRoleHistory(roleHistoryData.data)
    }
    getRoleHistory()
  }, [])

  const handleBack = () => {
    history.goBack()
  }

  const handleModify = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, errorMessage } =
      await metaDataService.apiPermissionListFetch()
    dispatch(
      fetchPermissionsLisSuccess({
        permissions: data,
      })
    )
    history.push(
      `/user-management/role-and-permission/modify/${match.params.id}`
    )
  }

  let statusAction = ''
  if (role.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (role.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    dispatch(changeLoadingRequest())
    if (e == 'Inactive') {
      const activeData = await userManagementService.apiRoleInactive(
        parseInt(match.params.id)
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = roleInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setRole(activeData.data)
    }
    if (e == 'Active') {
      const inActiveData = await userManagementService.apiRoleActive(
        parseInt(match.params.id)
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = roleActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setRole(inActiveData.data)
    }
    const roleHistoryData = await userManagementService.apiRoleHistoryFetch(
      parseInt(match.params.id)
    )
    setRoleHistory(roleHistoryData.data)
    dispatch(changeLoadingRequest())
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime', // accessor is the "key" in the data
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  )
  const renderAuditHistoryTable = () => {
    if (roleHistory) {
      return (
        <ReactTableWithPaginationComponent
          columns={columns}
          data={roleHistory}
          onClickRow={(row: any) => {
            console.log(row)
          }}
        />
      )
    } else {
      return <span>Audit history not found</span>
    }
  }
  return (
    <Row className="view-role-page-layout">
      <Col sm={12} className="border-label">
        <div className="border-label-span">View Role</div>
        <Row>
          <Col className="text-align-right">
            {checkPermission('inactive') === true ||
            checkPermission('Active') === true ? (
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Action
                </Dropdown.Toggle>
                <Dropdown.Menu align={{ lg: 'end' }}>
                  <Dropdown.Item eventKey={statusAction}>
                    {statusAction}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <Form>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" disabled value={role?.name} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" disabled value={role?.description} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control type="text" disabled value={role?.status} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPermission">
            <Form.Label>Permission</Form.Label>
            <Container>
              <RolePermissionsComponent id={parseInt(match.params.id)} />
            </Container>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPermission">
            <Form.Label>Audit History</Form.Label>
            <br />
            {renderAuditHistoryTable()}
          </Form.Group>
        </Form>
      </Col>
      <Col sm={12} className="d-flex justify-content-end mt-4">
        <button className="ok-button" onClick={handleBack}>
          Ok
        </button>
        {checkPermission('modify') === true && (
          <button className="modify-button" onClick={handleModify}>
            Modify
          </button>
        )}
      </Col>
    </Row>
  )
}
export default ViewRoleComponent
