import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
// import ApplicationDetailsReport from './ApplicationDetailsReport'
// import ApplicationPaymentReport from './ApplicationPaymentReport'
import CompleteDetailsReport from '../completeDetailsReport/CompleteDetailsReport'
import PaymentDetailsReport from '../completeDetailsReport/PaymentDetailsReport'
import PaymentStatsReport from '../completeDetailsReport/PaymentStatsReport'

const ApplicationReports = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('details')

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="details">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('details')
                  }}
                  eventKey="details"
                >
                  Application Details Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('payment')
                  }}
                  eventKey="payment"
                >
                  Application Payment Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link
                  onClick={() => {
                    setSelectedSection('payment-stats')
                  }}
                  eventKey="payment-stats"
                >
                  Payment Analysis Report
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          {/* <Row className="page-tiles-layout align-content-start"> */}
          <Row>
            {/* {selectedSection === 'details' ? (
              <CompleteDetailsReport />
            ) : (
              <PaymentDetailsReport />
            )} */}
            {selectedSection === 'details' && <CompleteDetailsReport />}
            {selectedSection === 'payment' && <PaymentDetailsReport />}
            {selectedSection === 'payment-stats' && <PaymentStatsReport />}
          </Row>
        </Tab.Container>
      </Route>
    </Switch>
  )
}

export default ApplicationReports
