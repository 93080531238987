import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SectionButton from '../../../components/common/ITerminalWizardComponent/SectionButton'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DisbursementDetailsModel } from '../../../models/applicationRegistration/loan/disbursementDetails'
import { GuarantorDetailsModel } from '../../../models/applicationRegistration/loan/guarantorDetails'
import { LoanNPADetailsModel } from '../../../models/applicationRegistration/loan/loanNPADetails'
import attributeService from '../../../services/applicationRegistration/loan/attributeService'
import { loadAttributeSectionList } from '../../../utils/metaData'
import DisbursmentDetailsSection from './sections/DisbursmentDetailsSection'
import GuarantorDetailsSection from './sections/GuarantorDetailsSection'
import NPASection from './sections/NPASection'
import RepaymentDetailsSection from './sections/RepaymentDetailsSection'
import RescheduleSection from './sections/RescheduleSection'

const LoanAttribute = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  const [loading, setLoading] = useState(true)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const [gurantorDetailsList, setGurantorDetailsList] = useState(
    [] as GuarantorDetailsModel[]
  )

  const [disbursmentDetails, setDisbursmentDetails] = useState(
    {} as DisbursementDetailsModel
  )

  const [npaDetailsList, setNpaDetailsList] = useState(
    [] as LoanNPADetailsModel[]
  )
  const history = useHistory()
  useEffect(() => {
    async function getFileDetails() {
      const result = await attributeService.apiFetchFileDetails(
        parseInt(params.id),
        type
      )
      if (result.status === 200) {
        setFileRegistrationObject(result.data)
        const gurantorData = await attributeService.apiFetchGurantorDetails(
          parseInt(params.id),
          result.data.scheme.toLowerCase()
        )
        if (gurantorData.status === 200) {
          setGurantorDetailsList(gurantorData.data)
        }
        const disbursmentData =
          await attributeService.apiFetchDisbursementDetails(
            parseInt(params.id),
            result.data.scheme.toLowerCase()
          )
        if (disbursmentData.status === 200) {
          setDisbursmentDetails(disbursmentData.data)
        }
        const npaData = await attributeService.apiFetchNPADetails(
          parseInt(params.id),
          result.data.scheme.toLowerCase()
        )
        if (npaData.status === 200) {
          setNpaDetailsList(npaData.data)
        }
      }

      setLoading(false)
    }
    getFileDetails()
  }, [])

  const handleOk = () => {
    history.goBack()
  }

  const checkAvailable = (index: number) => {
    switch (index) {
      case 0:
        return true
        break
      case 1:
        if (
          gurantorDetailsList?.find((element) => element.status === 'Active')
        ) {
          return true
        }
        return false
        break
      case 2:
        if (disbursmentDetails.id != null) {
          return true
        }
        return false
        break
      case 3:
        if (disbursmentDetails.id != null) {
          return true
        }
        return false
        break
      case 4:
        if (npaDetailsList.length > 0) {
          return true
        }
        return false
        break
      default:
        return false
    }
  }

  const renderSectionButton = () => {
    return loadAttributeSectionList.map((section: any, index: number) => {
      return (
        <SectionButton
          key={`section-button-${index}`}
          name={`${section.name}`}
          number={index + 1}
          isLeft={index === 0 ? false : true}
          isRight={index === loadAttributeSectionList.length - 1 ? false : true}
          available={checkAvailable(index)}
          active={activeSection === index + 1}
          onClick={() => {
            setActiveSection(index + 1)
          }}
        />
      )
    })
  }

  const updateFileRegistration = async () => {
    const result = await attributeService.apiFetchFileDetails(
      parseInt(params.id),
      type
    )
    if (result.status === 200) {
      setFileRegistrationObject(result.data)
    }
  }

  const updateGurantorDetails = async () => {
    updateFileRegistration()
    const gurantorData = await attributeService.apiFetchGurantorDetails(
      parseInt(params.id),
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (gurantorData.status === 200) {
      setGurantorDetailsList(gurantorData.data)
    }
  }

  const updateNPADetails = async () => {
    updateFileRegistration()
    const npaData = await attributeService.apiFetchNPADetails(
      parseInt(params.id),
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (npaData.status === 200) {
      setNpaDetailsList(npaData.data)
    }
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                Loan Attribute Details
              </span>
              <span className="m-2">
                {`Application reference : ${fileRegistrationObject.referenceNumber}`}
              </span>
            </Col>
          </Row>
          <Row className="section-layout">
            <Row className="section-tab-layout">{renderSectionButton()}</Row>
          </Row>
          <Row className="section-layout">
            {activeSection === 1 && (
              <GuarantorDetailsSection
                fileRegistrationObject={fileRegistrationObject}
                gurantorDetailsList={gurantorDetailsList}
                onOk={handleOk}
                onUpdate={updateGurantorDetails}
                onRemove={updateGurantorDetails}
              />
            )}
            {activeSection === 2 && (
              <DisbursmentDetailsSection
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleOk}
                onUpdate={(disbursmentDetails: DisbursementDetailsModel) => {
                  setDisbursmentDetails(disbursmentDetails)
                  updateFileRegistration()
                }}
              />
            )}
            {activeSection === 3 && (
              <RepaymentDetailsSection
                fileRegistrationObject={fileRegistrationObject}
                npaDetailsList={npaDetailsList}
                gurantorDetailsList={gurantorDetailsList}
                onOk={handleOk}
                onUpdate={updateFileRegistration}
              />
            )}
            {activeSection === 4 && (
              <NPASection
                fileRegistrationObject={fileRegistrationObject}
                npaDetailsList={npaDetailsList}
                onOk={handleOk}
                onUpdate={updateNPADetails}
                onRemove={updateNPADetails}
              />
            )}
            {activeSection === 5 && (
              <RescheduleSection
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleOk}
                onUpdate={updateFileRegistration}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default LoanAttribute
