import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Col, Container, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import metaDataService from '../../../services/metaDataService'
import './AddIncidentType.scss'
import produce from 'immer'
import { IncidentTypemodel } from '../../../models/systemConfiguration/incidentType/incidentTypemodel'
import incidentTypesServices from '../../../services/systemConfiguration/incidentTypes/incidentTypesServices'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import * as validation from './validations'
import { incidentType } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { IncidentTypeErrorModel } from '../../../models/systemConfiguration/incidentType/incidentTypeErrprModel'
import { validationError } from '../../../utils/errorMessages'
import PaymentType from './PaymentType'

const initialState = {} as IncidentTypemodel
const AddIncidentType = () => {
  const [loading, setLoading] = useState(true)
  const { path } = useRouteMatch()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [incidentTypeState, setIncidentTypeState] = useState(initialState)
  const [errors, setErrors] = useState({} as IncidentTypeErrorModel)
  const [incidentCategoryList, setincidentCategoryList] = useState([])

  const history = useHistory()
  useEffect(() => {
    async function getSchemesData() {
      const incidentTypeData = await metaDataService.apiIncidentcategoryList()
      setincidentCategoryList(incidentTypeData.data)
      setLoading(false)
    }
    getSchemesData()
  }, [])

  const findFormErrors = () => {
    const newErrors = {} as IncidentTypeErrorModel
    if (
      validation.validateIncidentCategory(incidentTypeState.incidentCategory)
    ) {
      newErrors.incidentCategoryError = validation.validateIncidentCategory(
        incidentTypeState.incidentCategory
      )
    }
    if (validation.validateIncidentType(incidentTypeState.incidentType)) {
      newErrors.incidentTypError = validation.validateIncidentType(
        incidentTypeState.incidentType
      )
    }
    if (
      validation.validateIncidentDescription(incidentTypeState.incidentType)
    ) {
      newErrors.descriptionError = validation.validateIncidentDescription(
        incidentTypeState.description
      )
    }
    if (validation.validateEffectiveDate(incidentTypeState.effectiveDate)) {
      newErrors.effectiveDateError = validation.validateEffectiveDate(
        incidentTypeState.effectiveDate
      )
    }
    if (validation.validateEndDate(incidentTypeState.endDate)) {
      newErrors.endDateError = validation.validateEndDate(
        incidentTypeState.endDate
      )
    }
    return newErrors
  }
  const handleSubmit = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await incidentTypesServices.apiAddIncidentType(
      incidentTypeState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = incidentType
      history.push(
        `/system-configuration/incident-type/update-payment-type/${result.data.id}`
      )
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            <Row className="add-Incident-Type-page-layout">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Add Incident Type</div>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="incidentCategory">
                        <Form.Label>
                          Incident Category{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.incidentCategoryError}
                          type="text"
                          className="form-select"
                          as="select"
                          onBlur={(e) => {
                            const newError = produce(errors, (draft) => {
                              draft.incidentCategoryError =
                                validation.validateIncidentCategory(
                                  e.target.value
                                )
                            })
                            setErrors(newError)
                          }}
                          onChange={(e) => {
                            // getSchemeList(parseInt(e.target.value))
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.incidentCategory = e.target.value
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.incidentCategoryError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          {!incidentTypeState.incidentCategory ? (
                            <option value="">
                              --Select Incident Category--
                            </option>
                          ) : (
                            ''
                          )}
                          {incidentCategoryList.map(
                            (
                              category: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option
                                  key={`category-${index}-${category.id}`}
                                  value={category.name}
                                >
                                  {category.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.incidentCategoryError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="incidentType">
                        <Form.Label>
                          Incident Type{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.incidentTypError}
                          type="text"
                          onBlur={(e) => {
                            const newError = produce(errors, (draft) => {
                              draft.incidentTypError =
                                validation.validateIncidentType(e.target.value)
                            })
                            setErrors(newError)
                          }}
                          onChange={(e) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.incidentType = e.target.value
                                draft.status = 'Active'
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.incidentTypError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.incidentTypError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="incidentDescription"
                      >
                        <Form.Label>
                          Incident Description{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.descriptionError}
                          type="text"
                          onBlur={(e) => {
                            const newError = produce(errors, (draft) => {
                              draft.descriptionError =
                                validation.validateIncidentDescription(
                                  e.target.value
                                )
                            })
                            setErrors(newError)
                          }}
                          onChange={(e) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.description = e.target.value
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.descriptionError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.descriptionError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="effectiveDate">
                        <Form.Label>
                          Incident Date{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <DatePicker
                          placeholderText="Effective Date"
                          className={
                            errors.effectiveDateError
                              ? 'date-picker-validate'
                              : 'form-control'
                          }
                          selected={
                            incidentTypeState.effectiveDate != null
                              ? new Date(incidentTypeState.effectiveDate * 1000)
                              : null
                          }
                          onSelect={(date: Date) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.effectiveDate =
                                  new Date(date).getTime() / 1000
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.effectiveDateError = ''
                            })
                            setErrors(clearError)
                          }}
                          onChange={(date: Date) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.effectiveDate =
                                  new Date(date).getTime() / 1000
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.effectiveDateError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <span className="feedback-error-span-custom">
                          {errors.effectiveDateError}
                        </span>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="endDate">
                        <Form.Label>
                          Completion Date{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <DatePicker
                          placeholderText="End Date"
                          className={
                            errors.endDateError
                              ? 'date-picker-validate'
                              : 'form-control'
                          }
                          selected={
                            incidentTypeState.endDate != null
                              ? new Date(incidentTypeState.endDate * 1000)
                              : null
                          }
                          onSelect={(date: Date) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.endDate = new Date(date).getTime() / 1000
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.endDateError = ''
                            })
                            setErrors(clearError)
                          }}
                          onChange={(date: Date) => {
                            const newValue = produce(
                              incidentTypeState,
                              (draft) => {
                                draft.endDate = new Date(date).getTime() / 1000
                              }
                            )
                            setIncidentTypeState(newValue)
                            const clearError = produce(errors, (draft) => {
                              draft.endDateError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <span className="feedback-error-span-custom">
                          {errors.endDateError}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col sm={12} className="d-flex justify-content-end mt-4">
                    <button
                      className="cancel-button"
                      onClick={(e) => {
                        e.preventDefault()
                        history.goBack()
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="save-button"
                      onClick={(e: any) => {
                        e.preventDefault()
                        handleSubmit()
                        console.log(incidentTypeState)
                      }}
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </Col>
              {showConfirmation && (
                <ConfirmationModal
                  name={'Confirmation'}
                  message={'Are you sure to create this incident type ?'}
                  onCancel={() => {
                    setShowConfirmation(false)
                  }}
                  onConfirm={handleConfirm}
                />
              )}
            </Row>
          </Route>
          <Route
            path={`${path}/update-payment-types/:id`}
            render={(props) => <PaymentType {...props} />}
          />
        </Switch>
      )}
    </>
  )
}

export default AddIncidentType
