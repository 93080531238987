import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ITerminalWizardComponent from '../../../../components/common/ITerminalWizardComponent/ITerminalWizardComponent'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import gpcService from '../../../../services/compensationRecommendation/compensation/gpcService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { handleNotification } from '../../../../utils/utilFunctions'
import SectionOne from '../../../../components/sections/SectionOne'
import SectionTwo from '../../../../components/sections/SectionTwo'
import SectionAffectedProperty from '../../../../components/sections/SectionAffectedProperty'
import { ResponseObject } from '../../../../services/api/apiManager'
import { approved, rejected, returned } from '../../../../utils/successMessages'
import { approve, reject, ApplicationReturn } from '../../../../utils/constants'
import { gpcSectionList } from '../../../../utils/metaData'
import SectionBankDetails from '../../../../components/sections/SectionBankDetails'
import SupportDocumentSection from '../../../../components/sections/SupportDocumentSection'

const GpcFileView = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  console.log(type)
  console.log(params)
  const [loading, setLoading] = useState(true)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    async function getFileDetails() {
      const result = await gpcService.apiGetGpcFile(parseInt(params.id))
      console.log(result)
      setFileRegistrationObject(result.data)
      setLoading(false)
    }
    getFileDetails()
  }, [])

  const handleClick = async (clickObject: any) => {
    console.log(clickObject)
    if (clickObject.section === 'ok') {
      history.goBack()
    } else {
      dispatch(changeLoadingRequest())
      let result = {} as ResponseObject
      if (clickObject.section === approve) {
        result = await gpcService.apiApproveGpcFile(fileRegistrationObject.id)
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, approved)
      }
      if (clickObject.section === reject) {
        result = await gpcService.apiRejectGpcFile(
          fileRegistrationObject.id,
          clickObject.remark
        )
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, rejected)
      }
      if (clickObject.section === ApplicationReturn) {
        result = await gpcService.apiReturnGpcFile(
          fileRegistrationObject.id,
          clickObject.remark
        )
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, returned)
      }
      dispatch(changeLoadingRequest())
    }
  }
  const handleGoback = () => {
    history.goBack()
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                General Public Property Compensation
              </span>
              <span className="m-2">
                {`Application reference : ${fileRegistrationObject.referenceNumber}`}
              </span>
            </Col>
          </Row>
          <ITerminalWizardComponent
            sectionList={gpcSectionList}
            activeSection={activeSection}
            validationLevel={fileRegistrationObject.validationLevel}
            onClick={(sectionNumber: number) => {
              setActiveSection(sectionNumber)
            }}
          />
          <Row className="section-layout">
            {activeSection === 1 && (
              <SectionOne
                packageName={'compensation-recommendation'}
                type={type}
                fileRegistrationObject={fileRegistrationObject}
                onClick={(data: any) => {
                  handleClick(data)
                }}
              />
            )}
            {activeSection === 2 && (
              <SectionTwo
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleGoback}
              />
            )}
            {activeSection === 3 && (
              <SectionAffectedProperty
                packageName={'compensation-recommendation'}
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleGoback}
              />
            )}
            {activeSection === 4 && (
              <SectionBankDetails
                packageName={'compensation-recommendation'}
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleGoback}
                applicantName={fileRegistrationObject.applicantName}
                scheme={fileRegistrationObject.scheme}
              />
            )}
            {activeSection === 5 && (
              <SupportDocumentSection
                packageName={'compensation-recommendation'}
                fileRegistrationObject={fileRegistrationObject}
                onOk={handleGoback}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default GpcFileView
