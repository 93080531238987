import apiService from '../../api/apiManager'
class SchemeService {
  async apiGetAllSchemesList() {
    return apiService.apiGet('/system-configuration/scheme/list')
  }
  async apiGetScheme(code: string) {
    return apiService.apiGet(`/system-configuration/scheme/${code}`)
  }
  async apiGetSchemeHistory(code: string) {
    return apiService.apiGet(`/system-configuration/scheme/${code}/history`)
  }
  async apiActiveScheme(code: string) {
    return apiService.apiActiveInactive(
      `/system-configuration/scheme/${code}/active`
    )
  }
  async apiInactiveScheme(code: string) {
    return apiService.apiActiveInactive(
      `/system-configuration/scheme/${code}/inactive`
    )
  }
  async apiUpdateScheme(code: string | undefined, scheme: any) {
    return apiService.apiPutCommon(
      `/system-configuration/scheme/modify/${code}`,
      scheme
    )
  }
}
export default new SchemeService()
