import React, { FC } from 'react'
import { Table } from 'react-bootstrap'
import { AffectedOtherAssistanceModel } from '../../../models/applicationRegistration/compensation/affectedPropertyOtherAsisstanceModel'
import { thousandSeperator } from '../../../utils/utilFunctions'

export interface OtherAssistanceDetailsTableProps {
  otherAssistanceList: AffectedOtherAssistanceModel[]
  onRemove?: any
}
const OtherAssistanceDetailsTable: FC<OtherAssistanceDetailsTableProps> = ({
  otherAssistanceList,
  onRemove,
}) => {
  return (
    <Table striped bordered hover size="sm">
      <thead className="custom-table-header">
        <tr>
          <th>Name of the Institution</th>
          <th>Amount Received (Rs.)</th>
          <th>Received Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className="custom-table-body">
        {otherAssistanceList.map(
          (record: AffectedOtherAssistanceModel, index: number) => (
            <tr key={record.id}>
              <td className="td">{record.institution}</td>
              <td className="td">{thousandSeperator(record.amountReceived)}</td>
              <td className="td">
                {record.receivedDate == null
                  ? 'N/A'
                  : new Date(record.receivedDate * 1000).toLocaleDateString()}
              </td>
              <td className="td">
                <button
                  type="button"
                  className="save-button"
                  onClick={() => {
                    onRemove(index)
                  }}
                >
                  Remove
                </button>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  )
}

export default OtherAssistanceDetailsTable
