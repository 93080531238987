import { DisbursementDetailsModel } from '../../../models/applicationRegistration/loan/disbursementDetails'
import { GuarantorDetailsModel } from '../../../models/applicationRegistration/loan/guarantorDetails'
import { LoanNPADetailsModel } from '../../../models/applicationRegistration/loan/loanNPADetails'
import { LoanRepaymentDetailsModel } from '../../../models/applicationRegistration/loan/loanRepaymentDetails'
import { LoanRescheduleDetailsModel } from '../../../models/applicationRegistration/loan/loanRescheduleDetails'
import apiService from '../../api/apiManager'

class AttributeDataService {
  // file details
  async apiFetchFileDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/view/${fileId}`
    )
  }

  // gurantor details
  async apiFetchGurantorDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/guarantor-details/list`
    )
  }

  async apiFetchGurantorDetailsHistory(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/guarantor-details/history`
    )
  }

  async apiAddGurantorDetails(
    gurantor: GuarantorDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/loan/${scheme}/attribute/add/${fileId}/guarantor-details`,
      gurantor
    )
  }

  async apiModifyGurantorDetail(
    gurantor: GuarantorDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/guarantor-details/${gurantor.id}`,
      gurantor
    )
  }

  async apiDeleteGurantorDetail(
    gurantorId: number,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiDeleteCommon(
      `application-registration/loan/${scheme}/attribute/modify/${fileId}/guarantor-details/${gurantorId}`
    )
  }

  async apiCancelGurantorDetail(
    gurantorId: number,
    fileId: number,
    scheme: string,
    remark: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/guarantor-details/${gurantorId}/cancel`,
      {
        remark: remark,
      }
    )
  }

  // disbursement details
  async apiFetchDisbursementDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/disbursement-details`
    )
  }

  async apiFetchDisbursementHistoryDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/disbursement-details/history`
    )
  }

  async apiAddDisbursementDetails(
    disbursement: DisbursementDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/loan/${scheme}/attribute/add/${fileId}/disbursement-details`,
      disbursement
    )
  }

  async apiModifyDisbursementDetail(
    disbursement: DisbursementDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/disbursement-details/${disbursement.id}`,
      disbursement
    )
  }

  // repayment details
  async apiFetchRepaymentDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/repayment-details/list`
    )
  }

  async apiFetchRepaymentHistoryDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/repayment-details/history`
    )
  }

  async apiAddRepaymentDetails(
    repayment: LoanRepaymentDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/loan/${scheme}/attribute/add/${fileId}/repayment-details`,
      repayment
    )
  }

  async apiModifyRepaymentDetail(
    repayment: LoanRepaymentDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/repayment-details/${repayment.id}`,
      repayment
    )
  }

  async apiDeleteRepaymentDetail(
    repaymentId: number,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiDeleteCommon(
      `application-registration/loan/${scheme}/attribute/modify/${fileId}/repayment-details/${repaymentId}`
    )
  }

  // npa details
  async apiFetchNPADetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/npa-details/list`
    )
  }

  async apiFetchNPAHistoryDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/npa-details/history`
    )
  }

  async apiAddNPADetails(
    npa: LoanNPADetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/loan/${scheme}/attribute/add/${fileId}/npa-details`,
      npa
    )
  }

  async apiModifyNPADetail(
    npa: LoanNPADetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/npa-details/${npa.id}`,
      npa
    )
  }

  async apiDeleteNPADetail(npaId: number, fileId: number, scheme: string) {
    return apiService.apiDeleteCommon(
      `application-registration/loan/${scheme}/attribute/modify/${fileId}/npa-details/${npaId}`
    )
  }

  // reschedule details
  async apiFetchRescheduleDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/reschedule-details/list`
    )
  }

  async apiFetchRescheduleHistoryDetails(fileId: number, scheme: string) {
    return apiService.apiGet(
      `/application-registration/loan/${scheme}/attribute/view/${fileId}/reschedule-details/history`
    )
  }

  async apiAddRescheduleDetails(
    reschedule: LoanRescheduleDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPost(
      `/application-registration/loan/${scheme}/attribute/add/${fileId}/reschedule-details`,
      reschedule
    )
  }

  async apiModifyRescheduleDetail(
    reschedule: LoanRescheduleDetailsModel,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/${scheme}/attribute/modify/${fileId}/reschedule-details/${reschedule.id}`,
      reschedule
    )
  }

  async apiDeleteRescheduleDetail(
    rescheduleId: number,
    fileId: number,
    scheme: string
  ) {
    return apiService.apiDeleteCommon(
      `application-registration/loan/${scheme}/attribute/modify/${fileId}/reschedule-details/${rescheduleId}`
    )
  }
}

export default new AttributeDataService()
