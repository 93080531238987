/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import React, { FC, useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AffectedPropertyDamageModel } from '../../models/applicationRegistration/compensation/affectedPropertyDamageModel'
import { PropertyDamageTypeModel } from '../../models/systemConfiguration/propertyDamageType/propertyDamageTypeModel'
import { PropertyFieldListModel } from '../../models/systemConfiguration/propertyDamageType/propertyFieldListModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { showTotast } from '../../utils/toast'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { currencyMaskForString } from '../../utils/utilFunctions'
// import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'

export interface PropertyAreaProps {
  property: PropertyDamageTypeModel
  properties: AffectedPropertyDamageModel[]
  disabled?: boolean
  onAdd?: any
  onRemove?: any
}

const PropertyArea: FC<PropertyAreaProps> = ({
  property,
  properties,
  disabled = false,
  onAdd,
  onRemove,
}) => {
  const propertyForm = useRef(HTMLFormElement)
  const [columns, setColumns] = useState([] as any[])
  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    const fieldColumns = [] as any[]
    property.propertyFieldList.map((field: PropertyFieldListModel) => {
      const columnObject = {
        Header: field.propertyField,
        accessor: field.propertyField,
      }
      fieldColumns.push(columnObject)
    })
    if (!disabled) {
      const columnObject = {
        Header: 'Action',
        accessor: '',
        Cell: (e: any) => {
          return (
            <Col className="d-flex justify-content-center">
              <button
                className="save-button"
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  handleRemove(e.row.original)
                }}
              >
                Remove
              </button>
            </Col>
          )
        },
      }
      fieldColumns.push(columnObject)
    }
    setColumns(fieldColumns)
  }, [])

  const handleRemove = (object: any) => {
    onRemove(object)
  }

  const numberFormat = (x: any) => {
    if (x != null || x != '') {
      return x.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return ''
  }

  const formatCurrency = (input: any, blur: any) => {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let inputVal = input

    // don't validate empty input
    if (inputVal === '') {
      return
    }
    // check for decimal
    if (inputVal.indexOf('.') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimalPos = inputVal.indexOf('.')

      // split number by decimal point
      let leftSide = inputVal.substring(0, decimalPos)
      let rightSide = inputVal.substring(decimalPos)

      // add commas to left side of number
      leftSide = numberFormat(leftSide)

      // validate right side
      rightSide = numberFormat(rightSide)

      // On blur make sure 2 numbers after decimal
      if (blur === 'blur') {
        rightSide += '00'
      }

      // Limit decimal to only 2 digits
      rightSide = rightSide.substring(0, 2)

      // join number by .
      inputVal = leftSide + '.' + rightSide
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      inputVal = numberFormat(inputVal)
      inputVal = inputVal

      // final formatting
      if (blur === 'blur') {
        inputVal += '.00'
      }
    }

    return inputVal
  }

  const renderFormFeilds = () => {
    console.log(property)
    return property.propertyFieldList.map(
      (field: PropertyFieldListModel, index: number) => {
        return (
          <Row key={`form-group-${index}`} className="mb-3">
            <Form.Group as={Col} controlId={field.propertyField}>
              <Form.Label>{field.propertyField}</Form.Label>
              <Form.Control
                disabled={disabled}
                name={field.propertyField}
                placeholder={`${field.propertyField}`}
                onChange={(e) => {
                  console.log(property)
                  if (field.propertyFieldType === 'currency') {
                    const value = formatCurrency(e.target.value, '')
                    console.log(value)
                    const formInputs = [...propertyForm.current.elements]
                    formInputs[index].value = value == undefined ? '' : value
                  }
                }}
                onBlur={(e) => {
                  if (field.propertyFieldType === 'currency') {
                    const value = formatCurrency(e.target.value, 'blur')
                    console.log(value)
                    const formInputs = [...propertyForm.current.elements]
                    formInputs[index].value = value == undefined ? '' : value
                  }
                }}
              />
            </Form.Group>
          </Row>
        )
      }
    )
  }

  const renderTableRows = () => {
    return columns.map((column: any, index: number) => {
      return <th key={`column-${index}`}>{column.Header}</th>
    })
  }

  const renderTableData = (property: any) => {
    return columns.map((column: any, index: number) => {
      if (column.accessor === '') {
        return (
          <Col className="d-flex justify-content-center">
            <button
              className="save-button"
              onClick={(action) => {
                action.preventDefault()
                action.stopPropagation()
                handleRemove(property)
              }}
            >
              Remove
            </button>
          </Col>
        )
      } else {
        return (
          <td key={`td-${index}`}>
            {currencyMaskForString(property[column.accessor])}
          </td>
        )
      }
    })
  }

  const renderTableDataRows = () => {
    return properties.map((property: any, index: number) => {
      return <tr key={`tr-${index}`}>{renderTableData(property)}</tr>
    })
  }

  const renderTable = () => {
    return (
      <div>
        <table className="table table-bordered">
          <thead className="custom-table-header">
            <tr>{renderTableRows()}</tr>
          </thead>
          <tbody className="custom-table-body">{renderTableDataRows()}</tbody>
        </table>
      </div>
    )
  }

  const handleAdd = (event: any) => {
    event.preventDefault()
    const formInputs = [...propertyForm.current.elements]
    let valid = false
    console.log(formInputs)
    // for (let index = 0; index < formInputs.length; index++) {
    //   const element = formInputs[index]
    //   if (
    //     element.value !== '' ||
    //     element.value !== null ||
    //     element.value !== undefined
    //   ) {
    //     valid = true
    //     break
    //   }
    // }
    formInputs.map((data) => {
      if (data.value !== '' && parseFloat(data.value) > 0) {
        console.log(data.value)
        valid = true
      }
    })

    if (valid) {
      const propertyObject = formInputs.reduce(
        (acc, input) => {
          return {
            ...acc,
            [input.name]: input.value,
          }
        },
        {
          propertyId: property.id,
          id: new Date().getTime() * 1000,
        }
      )
      onAdd(propertyObject)
      propertyForm.current.reset()
    } else {
      showTotast(
        ALERT_WARNING,
        'Validation error found. Please complete all fields and try again.',
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
    }
  }

  return (
    <Col sm={12} className="border-label mb-4">
      <Row>
        <div className="border-label-span">{property.name}</div>
        <button
          type="button"
          className="border-label-button"
          onClick={() => {
            setCollapse(!collapse)
          }}
        >
          {collapse ? (
            <FontAwesomeIcon icon={faMinusSquare} />
          ) : (
            <FontAwesomeIcon icon={faPlusSquare} />
          )}
        </button>
      </Row>
      {collapse && (
        <>
          <Form ref={propertyForm} id={`form-${property.name}`}>
            {renderFormFeilds()}
          </Form>
          <Row className="mt-2 mb-2 justify-content-end">
            <button
              disabled={disabled}
              className={`${
                disabled ? 'disable-button' : 'save-button'
              } custom-margin-right`}
              onClick={(e) => {
                handleAdd(e)
              }}
            >
              Add
            </button>
          </Row>
          <Row>
            {/* <ReactTableWithPaginationComponent
              columns={columns}
              data={properties}
            /> */}
            {renderTable()}
          </Row>
        </>
      )}
    </Col>
  )
}

export default PropertyArea
