import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import TileButtonComponent from '../../components/common/tileButton/TileButtonComponent'
import { SubPackageModal } from '../../models/package/subPackageModal'
import AuthorizationRoute from '../../providers/AuthorizationRouteProvider'
import { getUserSubPackages } from '../../store/user/selectors'
import './UserManagementPageComponent.scss'
import UsersComponent from './users/UsersComponent'
import RolesAndPermissionsComponent from './rolesAndPermissions/RolesAndPermissionsComponent'
import user_management from '../../assets/images/user-management.svg'
const UserManagementPageComponent = () => {
  const { path } = useRouteMatch()
  const subPackageList = useSelector(getUserSubPackages('user-management'))

  const renderSubPackages = () => {
    return subPackageList.map((subPackage: SubPackageModal, index: number) => {
      return (
        <Col key={`sub-package-${index}`} sm={12} lg={6}>
          <TileButtonComponent
            name={subPackage.description}
            routeURL={subPackage.name}
          />
        </Col>
      )
    })
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={user_management} alt="" />
            <span className="m-2">User Management</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">{renderSubPackages()}</Row>
      </Route>
      <AuthorizationRoute path={`${path}/user`} component={UsersComponent} />
      <Route
        path={`${path}/role-and-permission`}
        component={RolesAndPermissionsComponent}
      />
    </Switch>
  )
}
export default UserManagementPageComponent
