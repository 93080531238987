import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class RejectionReturnReasonsServices {
  async apiFetchRejectionReturnReasonsList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/rejection-return-reason/list`,
      filterData
    )
  }

  async apiFetchRejectionReturnReasonsCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/rejection-return-reason/count`,
      filterData
    )
  }
  async apiAddRejectionReturnReasons(RejectionReturnReasons: any) {
    return apiService.apiPost(
      `/system-configuration/rejection-return-reason/add`,
      RejectionReturnReasons
    )
  }
  async getRejectionReturnReasonsList() {
    return apiService.apiGet(
      `/system-configuration/rejection-return-reason/list/`
    )
  }
  async getRejectionReturnReasonsById(id: number) {
    return apiService.apiGet(
      `/system-configuration/rejection-return-reason/view/${id}`
    )
  }
  async apiActiveRejectionReturnReasons(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/rejection-return-reason/${id}/active`
    )
  }
  async apiInactiveRejectionReturnReasons(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/rejection-return-reason/${id}/inactive`
    )
  }
  async apiUpdateRejectionReturnReason(id: number | undefined, reason: any) {
    return apiService.apiPutCommon(
      `/system-configuration/rejection-return-reason/modify/${id}`,
      reason
    )
  }
}
export default new RejectionReturnReasonsServices()
