import React, { useEffect, useState } from 'react'
import { AdvancedFilterModel } from '../../../models/utils/filterModel'
import metaDataService from '../../../services/metaDataService'
import './AdvancedSearchTable.scss'
import Pagination from './Pagination'
import produce from 'immer'
import AdvancedSearchTableFilters from './AdvancedSearchTableFilters'
import { advancedTableCustomRender, Column } from './AdvancedSearchTableUtil'

interface Props {
  initFilterOption: AdvancedFilterModel
  columns: Column[]
  dataApiPath: string
  countApiPath: string
  onClickRow?: (row: any) => void
}

const AdvancedSearchTable: React.FC<Props> = ({
  initFilterOption,
  columns,
  dataApiPath,
  countApiPath,
  onClickRow,
}) => {
  const [filterOption, setFilterOption] = useState(initFilterOption)
  const [data, setData] = useState([] as any[])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchDataAndCount(initFilterOption)
  }, [])

  const fetchDataAndCount = async (filters: AdvancedFilterModel) => {
    try {
      const dataCountResponse = await metaDataService.apiCommonAdvancedSearch(
        countApiPath,
        filters
      )
      if (dataCountResponse.status == 200) {
        setTotalCount(dataCountResponse.data.count)
      }

      const dataListResponse = await metaDataService.apiCommonAdvancedSearch(
        dataApiPath,
        filters
      )

      if (dataListResponse.status == 200) {
        setData(dataListResponse.data)
        setLoading(false)
        setPage(1)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchData = async (filters: AdvancedFilterModel) => {
    setLoading(true)
    try {
      const dataListResponse = await metaDataService.apiCommonAdvancedSearch(
        dataApiPath,
        filters
      )
      if (dataListResponse.status == 200) {
        setData(dataListResponse.data)
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handlePageChange = async (newPage: number) => {
    const newFilterOption = produce(filterOption, (draft) => {
      draft.page = newPage
    })
    fetchData(newFilterOption)

    setPage(newPage)
    setFilterOption(newFilterOption)
  }

  const renderTableHeader = () => (
    <thead className="custom-table-header">
      <tr>
        {columns.map((column) => (
          <th key={column.accessor}>{column.header}</th>
        ))}
      </tr>
    </thead>
  )

  const renderTableBody = () => (
    <tbody className="custom-table-body">
      {data.map((item) => (
        <tr
          key={item.id}
          onClick={(e) => {
            e.preventDefault()
            onClickRow ? onClickRow(item) : ''
          }}
        >
          {columns.map((column) => (
            <td key={column.accessor}>
              {column.render
                ? column.render(item)
                : getNestedValue(item, column.accessor)}
            </td>
          ))}
        </tr>
      ))}
      <tr>
        {loading ? (
          // Use our custom loading state to show a loading indicator
          <td colSpan={columns.length}>Loading...</td>
        ) : (
          <td colSpan={columns.length}>
            Showing {data.length} of ~{totalCount} results
          </td>
        )}
      </tr>
    </tbody>
  )

  const getNestedValue = (obj: any, path: string) => {
    const data = path.split('.').reduce((value, key) => value?.[key], obj)
    return advancedTableCustomRender(data, path)
  }

  return (
    <div className="table-wrapper">
      <AdvancedSearchTableFilters
        filterData={filterOption}
        initFilterData={initFilterOption}
        onFilter={(data: AdvancedFilterModel) => {
          fetchDataAndCount(data)
          setFilterOption(data)
          console.log(data)
        }}
      />
      <table className="table table-bordered table-hover">
        {renderTableHeader()}
        {renderTableBody()}
      </table>
      <Pagination
        totalCount={totalCount}
        currentPage={page}
        pageSize={filterOption.limit}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default AdvancedSearchTable
