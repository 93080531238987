import React, { useEffect, useState } from 'react'
import './Scheme.scss'
import { Row, Container, Col } from 'react-bootstrap'
import {
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import SchemeService from '../../../services/systemConfiguration/schemes/schemeService'
import ViewSchemes from './ViewSchemes'
import SchemesModify from './SchemesModify'
import schemes from '../../../assets/images/systemConfiguration/schemes.svg'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'

const Scheme = () => {
  const [schemeList, setSchemeList] = useState([])
  const pending = false
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  useEffect(() => {
    async function getSchemesData() {
      const schemes = await SchemeService.apiGetAllSchemesList()
      setSchemeList(schemes.data)
    }
    getSchemesData()
  }, [location.pathname])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Program Type',
        accessor: 'programType', // accessor is the "key" in the data
      },
      {
        Header: 'Scheme Code',
        accessor: 'code',
      },
      {
        Header: 'Scheme Description',
        accessor: 'description', // accessor is the "key" in the data
      },
      {
        Header: 'Community Type',
        accessor: 'communityType',
      },
      {
        Header: 'Scheme Category',
        accessor: 'schemeCategory',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (code: string) => {
    history.push(`${path}/view-schemes/${code}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={schemes} alt="" />
          <span className="m-2">Schemes</span>
        </Col>
      </Row>

      {pending ? (
        <Container className="user-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            {/* <Row className="user-page-layout">
              <Col>Filter</Col>
            </Row> */}
            <Row className="scheme-page-layout">
              <Col>
                {schemeList ? (
                  <ReactTableWithPaginationComponent
                    columns={columns}
                    data={schemeList}
                    onClickRow={(row: any) => {
                      handleView(row.code)
                    }}
                  />
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/view-schemes/:code`} component={ViewSchemes} />
          <Route
            path={`${path}/modify-schemes/:code`}
            component={SchemesModify}
          />
        </Switch>
      )}
    </>
  )
}

export default Scheme
