import { ReportTableHeaderModel } from '../models/reports/ReportTableHeaderModel'
import { CommonOptionModel } from '../models/selectOptionModels/CommonOptionModel'
import { FilterOptionModel } from '../models/utils/filterOption'

export interface ProgramOption {
  readonly value: string
  readonly label: string
}

export interface SchemeptionReport {
  value: string
  label: string
  programeType: string
}

export const programeTypes: readonly ProgramOption[] = [
  { value: 'COMPENSATION', label: 'Compensation' },
  { value: 'LOAN', label: 'Loan' },
]

export const schemeSubtypes: readonly ProgramOption[] = [
  { value: 'DEATH', label: 'Death' },
  { value: 'MISSING', label: 'Missing' },
]

export interface Schemeption {
  readonly value: string
  readonly label: string
  readonly programeType: string
}

export const schemesList: readonly Schemeption[] = [
  {
    value: 'PD',
    label: 'Public Death',
    programeType: 'COMPENSATION',
  },
  {
    value: 'PI',
    label: 'General Public Injury',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSD',
    label: 'Government Servant Death',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSI',
    label: 'Government Servant Injury',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GPC',
    label: 'General Public Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSC',
    label: 'Government Servant Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'RLP',
    label: 'Religious Places Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'MP',
    label: 'Missing Persons',
    programeType: 'COMPENSATION',
  },
  {
    value: 'SE',
    label: 'Loan for Self-Employment',
    programeType: 'LOAN',
  },
  {
    value: 'AH',
    label: 'Loan for Affected Houses',
    programeType: 'LOAN',
  },
  {
    value: 'SRT',
    label: 'Loan for Socially Reintegrated Trainees',
    programeType: 'LOAN',
  },
]

export const compensationSchemesList: readonly Schemeption[] = [
  {
    value: 'PD',
    label: 'Public Death',
    programeType: 'COMPENSATION',
  },
  {
    value: 'PI',
    label: 'General Public Injury',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSD',
    label: 'Government Servant Death',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSI',
    label: 'Government Servant Injury',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GPC',
    label: 'General Public Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSC',
    label: 'Government Servant Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'RLP',
    label: 'Religious Places Compensation',
    programeType: 'COMPENSATION',
  },
]

export const injurySchemesList: readonly Schemeption[] = [
  {
    value: 'PI',
    label: 'General Public Injury',
    programeType: 'COMPENSATION',
  },

  {
    value: 'GSI',
    label: 'Government Servant Injury',
    programeType: 'COMPENSATION',
  },
]

export const deathSchemesList: readonly Schemeption[] = [
  {
    value: 'PD',
    label: 'Public Death',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSD',
    label: 'Government Servant Death',
    programeType: 'COMPENSATION',
  },
]

export const proprtySchemesList: readonly Schemeption[] = [
  {
    value: 'GPC',
    label: 'General Public Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'GSC',
    label: 'Government Servant Property Compensation',
    programeType: 'COMPENSATION',
  },
  {
    value: 'RLP',
    label: 'Religious Places Compensation',
    programeType: 'COMPENSATION',
  },
]

export const loanSchemesList: readonly Schemeption[] = [
  {
    value: 'SE',
    label: 'Loan for Self-Employment',
    programeType: 'LOAN',
  },
  {
    value: 'AH',
    label: 'Loan for Affected Houses',
    programeType: 'LOAN',
  },
  {
    value: 'SRT',
    label: 'Loan for Socially Reintegrated Trainees',
    programeType: 'LOAN',
  },
]

export interface StatusFilterOption {
  readonly value: string
  readonly label: string
}

export const statusFilters: readonly StatusFilterOption[] = [
  { label: 'File Created', value: 'a' },
  { label: 'File Allocated', value: 'b' },
  { label: 'File Rejected', value: 'c' },
  { label: 'File Re-created', value: 'd' },
  { label: 'File Cancelled', value: 'e' },
  { label: 'File Cancelled', value: 'f' },
]

//Relationship Types

export const relationshipList = [
  {
    name: 'Spouse',
    id: 1,
  },
]

export const occupationList = [
  {
    name: 'Government',
    id: 1,
  },
  {
    name: 'Semi Government',
    id: 2,
  },
  {
    name: 'Private',
    id: 3,
  },
]

export const ownershipList = [
  {
    name: 'Owner',
    id: 1,
  },
  {
    name: 'Tenant',
    id: 2,
  },
]

//Marital Status

export const martialStatusList = [
  {
    name: 'Married',
    id: 1,
  },
]

export const fileRegistrationDraftColumns = [
  {
    Header: 'Incident Type',
    accessor: 'incidentType.incidentType',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicantName',
  },
  {
    Header: 'NIC',
    accessor: 'newNicNumber',
  },
  {
    Header: 'Identification Type',
    accessor: 'identificationType',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'Created Date and Time',
    accessor: 'createdDateTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]

// file registration table columns

export const fileRegistrationColumns = [
  {
    Header: 'Application Reference',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Incident Type',
    accessor: 'incidentType.incidentType',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicantName',
  },
  {
    Header: 'NIC',
    accessor: 'newNicNumber',
  },
  {
    Header: 'Identification Type',
    accessor: 'identificationType',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'Province',
    accessor: 'province.name',
  },
  {
    Header: 'District',
    accessor: 'district.name',
  },
  {
    Header: 'DS Division',
    accessor: 'dsDivision.name',
  },
  {
    Header: 'GN Division',
    accessor: 'gnDivision.name',
  },
  {
    Header: 'File Received Date',
    accessor: 'fileReceivedDate',
  },
  {
    Header: 'Created Date and Time',
    accessor: 'createdDateTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]

export const fileRegistrationLoanColumns = [
  {
    Header: 'Application Reference',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicantName',
  },
  {
    Header: 'NIC',
    accessor: 'newNicNumber',
  },
  {
    Header: 'Identification Type',
    accessor: 'identificationType',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'Province',
    accessor: 'province.name',
  },
  {
    Header: 'District',
    accessor: 'district.name',
  },
  {
    Header: 'DS Division',
    accessor: 'dsDivision.name',
  },
  {
    Header: 'GN Division',
    accessor: 'gnDivision.name',
  },
  {
    Header: 'File Received Date',
    accessor: 'fileReceivedDate',
  },
  {
    Header: 'Created Date and Time',
    accessor: 'createdDateTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]

export const fileRegistrationHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Application Reference',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const status = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
]

export const dependentDetailsColumns = [
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'Relationship',
    accessor: 'relationship',
  },
  {
    Header: 'NIC',
    accessor: 'newNicNumber',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'Gender',
    accessor: 'gender',
  },
  {
    Header: 'Date of Birth',
    accessor: 'dateOfBirth',
  },
  {
    Header: 'Age',
    accessor: 'age',
  },
  {
    Header: 'Marital Status',
    accessor: 'maritalStatus',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Province',
    accessor: 'province.name',
  },
  {
    Header: 'District',
    accessor: 'district.name',
  },
  {
    Header: 'DS Division',
    accessor: 'dsDivision.name',
  },
  {
    Header: 'GN Division',
    accessor: 'gnDivision.name',
  },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'Bank Name',
    accessor: 'bank.name',
  },
  {
    Header: 'Branch Name',
    accessor: 'branch',
  },
  {
    Header: 'Account Number',
    accessor: 'accountNumber',
  },
]

export const gurantorsHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Remark',
    accessor: 'remark',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const disburstmentsHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const repaymentHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const npaHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const rescheduleHistoryColumns = [
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Performed By',
    accessor: 'performedBy',
  },
  {
    Header: 'Performed Date time',
    accessor: 'performedDateTime',
  },
]

export const fileAllocationFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'reference_number',
    value: null,
    selected: false,
    label: 'Reference Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'program_type',
    value: null,
    selected: false,
    label: 'Programe Type',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'new_nic_number',
    value: null,
    selected: false,
    label: 'NIC',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'Ds Division',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'gn_division_id',
    value: null,
    selected: false,
    label: 'Gn Division',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'file_received_date',
    value: null,
    selected: false,
    label: 'File Received Date',
    dropdownValue: null,
  },
]
export const compensationValueFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'program_type',
    value: null,
    selected: false,
    label: 'Type of Program',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'compensation_for',
    value: null,
    selected: false,
    label: 'Compensation for',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]

export const rejectionReturnReasonFilters: FilterOptionModel[] = [
  {
    operator: 'EQUAL',
    property: 'reason_type_id',
    value: null,
    selected: false,
    label: 'Reason Type',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'rejection_return_reason_id',
    value: null,
    selected: false,
    label: 'Rejection Return Reason Id',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]

export const incidentTypesFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'incident_type',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme_code',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'effective_date',
    value: null,
    selected: false,
    label: 'Effective Date',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]
export const typeOfInjuriesFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'type_of_disablement',
    value: null,
    selected: false,
    label: 'Type of Disablement',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'category_of_injury',
    value: null,
    selected: false,
    label: 'Category of Injury',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'injury_description',
    value: null,
    selected: false,
    label: 'Injury Description',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme_code',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]
export const supportingDocumentFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'document_name',
    value: null,
    selected: false,
    label: 'Document Name',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme_code',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
]

export const propertyDamageTypeFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'name',
    value: null,
    selected: false,
    label: 'Name of the Property',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'property_category_id',
    value: null,
    selected: false,
    label: 'Property Category',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]

export const seSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Family Details',
    id: 3,
  },
  {
    name: 'Other Loan and Self-Employment Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
]

export const loadAttributeSectionList = [
  {
    name: 'Guarantor Details',
    id: 1,
  },
  {
    name: 'Disbursment Details',
    id: 2,
  },
  {
    name: 'Re Payment Details',
    id: 3,
  },
  {
    name: 'NPA Details',
    id: 4,
  },
  {
    name: ' Re Schedule Details',
    id: 5,
  },
]

export const srtSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Dependents and Rehabilitation Details',
    id: 3,
  },
  {
    name: 'Supporting Documents',
    id: 4,
  },
]

export const ahSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Affected House Details and Other Loans',
    id: 3,
  },
  {
    name: 'Supporting Documents',
    id: 4,
  },
]

export const pdSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Aggrieved Person Details',
    id: 3,
  },
  {
    name: 'Dependent and Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
]

export const mpSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Aggrieved Person Details',
    id: 3,
  },
  {
    name: 'Dependent and Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
]

export const natureOfDamageList = [
  {
    name: 'Full',
    id: 1,
  },
  {
    name: 'Partial',
    id: 2,
  },
]

export const gpcSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Affected Property Information',
    id: 3,
  },
  {
    name: 'Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
]

export const rlpSectionList = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Affected Property Information',
    id: 3,
  },
  {
    name: 'Board of Trustee and Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
]

export const compensationApplicationsFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'reference_number',
    value: null,
    selected: false,
    label: 'Reference Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'new_nic_number',
    value: null,
    selected: false,
    label: 'New NIC',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'passport_number',
    value: null,
    selected: false,
    label: 'Passport Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'senior_citizen_id',
    value: null,
    selected: false,
    label: 'Senior Citizen ID',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'driving_license_number',
    value: null,
    selected: false,
    label: 'Driving License Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'birth_certificate_number',
    value: null,
    selected: false,
    label: 'Birth Certificate Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'death_certificate_number',
    value: null,
    selected: false,
    label: 'Death Certificate Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'aggrieved_person_new_nic_number',
    value: null,
    selected: false,
    label: 'Aggrieved Person’s NIC',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'asset_identification_number',
    value: null,
    selected: false,
    label: 'Asset Identification Number',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'religious_place_reg_number',
    value: null,
    selected: false,
    label: 'RLP Registration Number',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'Ds Division',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'gn_division_id',
    value: null,
    selected: false,
    label: 'Gn Division',
    dropdownValue: null,
  },
  {
    operator: 'EQUAL',
    property: 'file_received_date',
    value: null,
    selected: false,
    label: 'File Received Date',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'old_nic_number',
    value: null,
    selected: false,
    label: 'Old NIC',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'program_type',
    value: null,
    selected: false,
    label: 'Programe Type',
    dropdownValue: null,
  },
]

export const statusList = [
  {
    label: 'Application Creation Pending',
    value: 'Application Creation Pending',
  },
  {
    label: 'Application Drafted',
    value: 'Application Drafted',
  },
  {
    label: 'Application Re-Allocated',
    value: 'Application Re-Allocated',
  },
  {
    label: 'Application Closed',
    value: 'Application Closed',
  },
  {
    label: 'Recommendation Pending',
    value: 'Recommendation Pending',
  },
  {
    label: 'Recommendation Returned',
    value: 'Recommendation Returned',
  },
  {
    label: 'Recommendation Rejected',
    value: 'Recommendation Rejected',
  },
  {
    label: 'Approval Pending',
    value: 'Approval Pending',
  },
  {
    label: 'Approval Returned',
    value: 'Approval Returned',
  },
  {
    label: 'Approval Rejected',
    value: 'Approval Rejected',
  },
  {
    label: 'Certification Pending',
    value: 'Certification Pending',
  },
  {
    label: 'Certification Returned',
    value: 'Certification Returned',
  },
  {
    label: 'Certification Rejected',
    value: 'Certification Rejected',
  },
  {
    label: 'Application Rejected',
    value: 'Application Rejected',
  },
  {
    label: 'Application Modify Draft',
    value: 'Application Modify Draft',
  },
  {
    label: 'File Returned',
    value: 'File Returned',
  },
  {
    label: 'File Rejected',
    value: 'File Rejected',
  },
  {
    label: 'Application Returned',
    value: 'Application Returned',
  },
  {
    label: 'Application Returned',
    value: 'Application Returned',
  },
  {
    label: 'Modify Board of Trustee',
    value: 'Modify Board of Trustee',
  },
  {
    label: 'Modify Installment Pay',
    value: 'Modify Installment Pay',
  },
  {
    label: 'Applicant Diseased',
    value: 'Applicant Diseased',
  },
  {
    label: 'Cheque Payment Re-created',
    value: 'Cheque Payment Re-created',
  },
  {
    label: 'Cheque Payment Pending',
    value: 'Cheque Payment Pending',
  },
  {
    label: 'Payment Completed',
    value: 'Payment Completed',
  },
]

export const statsReportstatusList = [
  {
    label: 'Payment Completed',
    value: 'Payment Completed',
  },
  {
    label: 'Payment Not Completed(Submitted)',
    value: 'Payment Not Completed Submitted',
  },
  {
    label: 'Payment Not Completed(Not Submitted)',
    value: 'Payment Not Completed Not Submitted',
  },
]

export const schemeTiles = [
  {
    name: 'pd',
    description: 'PD Applications',
  },
  {
    name: 'pi',
    description: 'PI Applications',
  },
  {
    name: 'gsd',
    description: 'GSD Applications',
  },
  {
    name: 'gsi',
    description: 'GSI Applications',
  },
  {
    name: 'gpc',
    description: 'GPC Applications',
  },
  {
    name: 'gsc',
    description: 'GSC Applications',
  },
  {
    name: 'rlp',
    description: 'RLP Applications',
  },
  {
    name: 'mp',
    description: 'MP Applications',
  },
]

export const financeBranchReportList = [
  {
    description: 'Approval File List Details',
    name: 'approval-file-list-details',
  },
  {
    description: 'Total Payment Details',
    name: 'total-payment-details',
  },
  {
    description: 'Cheques Handover to Branch',
    name: 'cheques-handover-to-branch',
  },
  {
    description: 'Cash Book Payment Details',
    name: 'cash-book-payment-details',
  },
  {
    description: 'Cheques Payment Details',
    name: 'cheques-payment-details',
  },

  {
    description: 'Certification File List - User Wise',
    name: 'certification-file-list-user-wise',
  },
]

export const reasonsForCancelCheque = [
  {
    label: 'Cheque Damage',
    value: 'Cheque Damage',
  },
  {
    label: 'Half Printed',
    value: 'Half Printed',
  },
  {
    label: 'Applicant deceased',
    value: 'Applicant deceased',
  },
]

export const reasonsForCancelApplication = [
  {
    label: 'Merge Installments',
    value: '1',
  },
  {
    label: 'Update Board of Trustee',
    value: '2',
  },
  {
    label: 'Update Board of Trustee and Merge Installments',
    value: '3',
  },
  {
    label: 'Update Account Details',
    value: '4',
  },
  {
    label: 'Add Next of Kin',
    value: '5',
  },
  {
    label: 'Complete the previous Installment and proceed to next',
    value: '6',
  },
]
export const userFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'first_name',
    value: null,
    selected: false,
    label: 'First Name',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'last_name',
    value: null,
    selected: false,
    label: 'Last Name',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'epf_number',
    value: null,
    selected: false,
    label: 'EPF Number',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'email',
    value: null,
    selected: false,
    label: 'Email Address',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]

export const roleFilters: FilterOptionModel[] = [
  {
    operator: 'STRING_EQUAL',
    property: 'name',
    value: null,
    selected: false,
    label: 'Role Name',
    dropdownValue: null,
  },
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
  },
]

export const roleStatusList = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
]

export const userStatusList = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
  {
    label: 'Blocked',
    value: 'Blocked',
  },
]

export const pdSectionListForEnquire = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Aggrieved Person Details',
    id: 3,
  },
  {
    name: 'Dependent and Bank Details',
    id: 4,
  },
  {
    name: 'Supporting Documents',
    id: 5,
  },
  {
    name: 'Cheque Details',
    id: 6,
  },
]

export const mpSectionListForEnquire = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Aggrieved Person Details',
    id: 3,
  },
  {
    name: 'Dependent and Bank Details',
    id: 4,
  },
  {
    name: 'Supporting Documents',
    id: 5,
  },
  {
    name: 'Cheque Details',
    id: 6,
  },
]

export const gpcSectionListForEnquire = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Affected Property Information',
    id: 3,
  },
  {
    name: 'Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
  {
    name: 'Cheque Details',
    id: 6,
  },
]

export const rlpSectionListForEnquire = [
  {
    name: 'Basic Information',
    id: 1,
  },
  {
    name: 'Applicant Details',
    id: 2,
  },
  {
    name: 'Affected Property Information',
    id: 3,
  },
  {
    name: 'Board of Trustee and Bank Details',
    id: 4,
  },
  {
    name: ' Supporting Documents',
    id: 5,
  },
  {
    name: 'Cheque Details',
    id: 6,
  },
]
export const applicationReportColumns = [
  {
    Header: 'Application Reference',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Incident Type',
    accessor: 'incidentType.incidentType',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicantName',
  },
  {
    Header: 'NIC',
    accessor: 'newNicNumber',
  },
  {
    Header: 'Identification Type',
    accessor: 'identificationType',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'Province',
    accessor: 'province.name',
  },
  {
    Header: 'District',
    accessor: 'district.name',
  },
  {
    Header: 'DS Division',
    accessor: 'dsDivision.name',
  },
  {
    Header: 'GN Division',
    accessor: 'gnDivision.name',
  },
  {
    Header: 'File Received Date',
    accessor: 'fileReceivedDate',
  },
  {
    Header: 'Created Date and Time',
    accessor: 'createdDateTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]

export const applicationReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'STRING_EQUAL',
    property: 'program_type',
    value: null,
    selected: false,
    label: 'Programe Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //1
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'scheme',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'incident_category',
    value: null,
    selected: false,
    label: 'Incident Category',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_category',
  },
  //3
  {
    operator: 'STRING_EQUAL',
    property: 'incident_type_description',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_type_description',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'incident_date_readable',
    value: null,
    selected: false,
    label: 'Incident Date',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_date_readable',
  },
  //5
  {
    operator: 'STRING_EQUAL',
    property: 'reference_number',
    value: null,
    selected: false,
    label: 'Application Reference Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'reference_number',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'applicant_name',
    value: null,
    selected: false,
    label: 'Applicant Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'applicant_name',
  },
  //7
  {
    operator: 'STRING_EQUAL',
    property: 'applicant_type',
    value: null,
    selected: false,
    label: 'Applicant Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'applicant_type',
  },
  //8
  {
    operator: 'STRING_EQUAL',
    property: 'gender',
    value: null,
    selected: false,
    label: 'Gender',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'gender ',
  },
  //9
  {
    operator: 'STRING_EQUAL',
    property: 'nic_number',
    value: null,
    selected: false,
    label: 'NIC Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //10
  {
    operator: 'STRING_EQUAL',
    property: 'identification_type',
    value: null,
    selected: false,
    label: 'Identification Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'identification_type',
  },
  //11
  {
    operator: 'STRING_EQUAL',
    property: 'id_number',
    value: null,
    selected: false,
    label: 'Identification Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'id_number',
  },
  //12
  {
    operator: 'STRING_EQUAL',
    property: 'date_of_birth_readable',
    value: null,
    selected: false,
    label: 'Applicant DOB',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'date_of_birth_readable',
  },
  //13
  {
    operator: 'EQUAL',
    property: 'province_name',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'province_name',
  },
  //14
  {
    operator: 'EQUAL',
    property: 'district_name',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'district_name',
  },
  //15
  {
    operator: 'EQUAL',
    property: 'ds_division_name',
    value: null,
    selected: false,
    label: 'DS Division',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'ds_division_name',
  },
  //16
  {
    operator: 'EQUAL',
    property: 'gn_division_name',
    value: null,
    selected: false,
    label: 'GN Division',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'gn_division_name',
  },
  //17
  {
    operator: 'STRING_EQUAL',
    property: 'file_received_date_readable',
    value: null,
    selected: false,
    label: 'File Received Date',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'file_received_date_readable',
  },
  //18
  {
    operator: 'STRING_EQUAL',
    property: 'birth_certificate_number',
    value: null,
    selected: false,
    label: 'Birth Certificate Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'birth_certificate_number',
  },
  //19
  {
    operator: 'STRING_EQUAL',
    property: 'death_certificate_number',
    value: null,
    selected: false,
    label: 'Death Certificate Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //20
  {
    operator: 'STRING_EQUAL',
    property: 'aggrieved_person_nic_number',
    value: null,
    selected: false,
    label: 'Aggrieved Person NIC',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'aggrieved_person_nic_number',
  },
  //21
  {
    operator: 'STRING_EQUAL',
    property: 'epf_or_staff_number',
    value: null,
    selected: false,
    label: 'EPF or Staff number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'epf_or_staff_number',
  },
  //22
  {
    operator: 'STRING_EQUAL',
    property: 'asset_identification_number',
    value: null,
    selected: false,
    label: 'Asset Identification Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'asset_identification_number',
  },
  //23
  {
    operator: 'STRING_EQUAL',
    property: 'rlp_registration_number',
    value: null,
    selected: false,
    label: 'RLP Registration Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'rlp_registration_number',
  },
  //24
  {
    operator: 'STRING_EQUAL',
    property: 'religious_place_reg_number',
    value: null,
    selected: false,
    label: 'Religious Place Registration Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'religious_place_reg_number',
  },
  //25
  {
    operator: 'STRING_EQUAL',
    property: 'created_by',
    value: null,
    selected: false,
    label: 'Application Created By',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'created_by',
  },
  //26
  {
    operator: 'STRING_EQUAL',
    property: 'created_date_time_readable',
    value: null,
    selected: false,
    label: 'Application Created Period',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'created_date_time_readable',
  },
  //27
  {
    operator: 'STRING_EQUAL',
    property: 'allocate_to',
    value: null,
    selected: false,
    label: 'Allocate To',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'allocate_to',
  },
  //28
  {
    operator: 'STRING_EQUAL',
    property: 'grievance_type',
    value: null,
    selected: false,
    label: 'Grievance Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'grievance_type',
  },
  //29
  {
    operator: 'STRING_EQUAL',
    property: 'injury_description',
    value: null,
    selected: false,
    label: 'Type of Injury',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'injury_description',
  },
  //30
  {
    operator: 'STRING_EQUAL',
    property: 'category_of_injury',
    value: null,
    selected: false,
    label: 'Category of Injury',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'category_of_injury',
  },
  //31
  {
    operator: 'STRING_EQUAL',
    property: 'type_of_disablement',
    value: null,
    selected: false,
    label: 'Type of Disablement',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'type_of_disablement',
  },
  //32
  {
    operator: 'STRING_EQUAL',
    property: 'damage_type',
    value: null,
    selected: false,
    label: 'Damage Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'damage_type',
  },
  //33
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_name',
    value: null,
    selected: false,
    label: 'Dependent Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_name',
  },
  //34
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_identification_type',
    value: null,
    selected: false,
    label: 'Dependent Identification Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_identification_type',
  },
  //35
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_id_number',
    value: null,
    selected: false,
    label: 'Dependent Identification Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_id_number',
  },
  //36
  {
    operator: 'STRING_EQUAL',
    property: 'ownership_of_the_property',
    value: null,
    selected: false,
    label: 'Ownership of the Property',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'ownership_of_the_property',
  },
  //37
  {
    operator: 'STRING_EQUAL',
    property: '',
    value: null,
    selected: false,
    label: 'Property Category',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //38
  {
    operator: 'STRING_EQUAL',
    property: '',
    value: null,
    selected: false,
    label: 'Property Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //39
  {
    operator: 'STRING_EQUAL',
    property: 'name_of_the_worship_place',
    value: null,
    selected: false,
    label: 'Worship Place Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'name_of_the_worship_place',
  },
  //40
  {
    operator: 'STRING_EQUAL',
    property: 'police_division_name',
    value: null,
    selected: false,
    label: 'Worship Place Police Division',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'police_division_name',
  },
  //41
  {
    operator: 'STRING_EQUAL',
    property: 'rlp_registered_institution',
    value: null,
    selected: false,
    label: 'Registered Institution',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'rlp_registered_institution',
  },
  //42
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_marital_status',
    value: null,
    selected: false,
    label: 'Marital Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_marital_status',
  },
  //43
  {
    operator: 'STRING_EQUAL',
    property: 'bank_name',
    value: null,
    selected: false,
    label: 'Bank Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'bank_name',
  },
  //44
  {
    operator: 'STRING_EQUAL',
    property: 'branch_name',
    value: null,
    selected: false,
    label: 'Branch Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'branch_name',
  },
  //45
  {
    operator: 'STRING_EQUAL',
    property: 'account_number',
    value: null,
    selected: false,
    label: 'Account Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'account_number',
  },
  //46
  {
    operator: 'STRING_EQUAL',
    property: 'compensation_amount',
    value: null,
    selected: false,
    label: 'Compensation Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'compensation_amount',
  },
  //47
  {
    operator: 'STRING_EQUAL',
    property: 'eligible_compensation_percentage',
    value: null,
    selected: false,
    label: 'Eligible Compensation Percentage %',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'eligible_compensation_percentage',
  },
  //48
  {
    operator: 'STRING_EQUAL',
    property: 'percentage_of_loss_of_earing_capacity',
    value: null,
    selected: false,
    label: 'Loss of earning capacity %',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'percentage_of_loss_of_earing_capacity',
  },
  //49
  {
    operator: 'STRING_EQUAL',
    property: 'balance_loan_amount',
    value: null,
    selected: false,
    label: 'Balance Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'balance_loan_amount',
  },
  //50
  {
    operator: 'STRING_EQUAL',
    property: 'recommended_amount',
    value: null,
    selected: false,
    label: 'Recommended Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'recommended_amount',
  },
  //51
  {
    operator: 'STRING_EQUAL',
    property: 'required_loan_amount',
    value: null,
    selected: false,
    label: 'Required Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'required_loan_amount',
  },
  //52
  {
    operator: 'STRING_EQUAL',
    property: 'repayment_period',
    value: null,
    selected: false,
    label: 'Repayment Period',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'repayment_period',
  },
  //53
  {
    operator: 'STRING_EQUAL',
    property: 'expected_loan_amount',
    value: null,
    selected: false,
    label: 'Expected Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'expected_loan_amount',
  },
  //54
  {
    operator: 'STRING_EQUAL',
    property: 'granted_loan_amount',
    value: null,
    selected: false,
    label: 'Approved Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'granted_loan_amount',
  },
  //55
  {
    operator: 'STRING_EQUAL',
    property: 'voucher_number',
    value: null,
    selected: false,
    label: 'Voucher Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'voucher_number',
  },
  //56
  {
    operator: 'STRING_EQUAL',
    property: 'cheque_number',
    value: null,
    selected: false,
    label: 'Cheque Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'cheque_number',
  },
  //57
  {
    operator: 'STRING_EQUAL',
    property: 'cheque_issued_date_readable',
    value: null,
    selected: false,
    label: 'Cheque Issued Date',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'cheque_issued_date_readable',
  },
  //58
  {
    operator: 'STRING_EQUAL',
    property: 'paid_amount',
    value: null,
    selected: false,
    label: 'Paid Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'paid_amount',
  },
  //59
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Application File Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //60
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Application Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //61
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Recommendation Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //62
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Approval Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //63
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Certification Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //64
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Cheque Payment Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
]
export const applicationPaymentSummaryReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'STRING_EQUAL',
    property: 'program_type',
    value: null,
    selected: false,
    label: 'Programe Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //1
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'scheme',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'incident_category',
    value: null,
    selected: false,
    label: 'Incident Category',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_category',
  },
  //3
  {
    operator: 'STRING_EQUAL',
    property: 'incident_type_description',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_type_description',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'incident_date_readable',
    value: null,
    selected: false,
    label: 'Incident Date',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'incident_date_readable',
  },
  //5
  {
    operator: 'STRING_EQUAL',
    property: 'reference_number',
    value: null,
    selected: false,
    label: 'Application Reference Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'reference_number',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'applicant_name',
    value: null,
    selected: false,
    label: 'Applicant Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'applicant_name',
  },
  //7
  {
    operator: 'STRING_EQUAL',
    property: 'gender',
    value: null,
    selected: false,
    label: 'Gender',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'gender ',
  },
  //8
  {
    operator: 'STRING_EQUAL',
    property: 'nic_number',
    value: null,
    selected: false,
    label: 'NIC Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'nic_number',
  },
  //9
  {
    operator: 'STRING_EQUAL',
    property: 'identification_type',
    value: null,
    selected: false,
    label: 'Identification Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'identification_type',
  },
  //10
  {
    operator: 'STRING_EQUAL',
    property: 'id_number',
    value: null,
    selected: false,
    label: 'Identification Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'id_number',
  },
  //11
  {
    operator: 'STRING_EQUAL',
    property: 'date_of_birth_readable',
    value: null,
    selected: false,
    label: 'Applicant DOB',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'date_of_birth_readable',
  },
  //12
  {
    operator: 'EQUAL',
    property: 'province_name',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'province_name',
  },
  //13
  {
    operator: 'EQUAL',
    property: 'district_name',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'district_name',
  },
  //14
  {
    operator: 'EQUAL',
    property: 'ds_division_name',
    value: null,
    selected: false,
    label: 'DS Division',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'ds_division_name',
  },
  //15
  {
    operator: 'EQUAL',
    property: 'gn_division_name',
    value: null,
    selected: false,
    label: 'GN Division xxxx',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'gn_division_name',
  },
  //16
  {
    operator: 'STRING_EQUAL',
    property: 'grievance_type',
    value: null,
    selected: false,
    label: 'Grievance Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'grievance_type',
  },
  //17
  {
    operator: 'STRING_EQUAL',
    property: 'injury_description',
    value: null,
    selected: false,
    label: 'Type of Injury',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'injury_description',
  },
  //18
  {
    operator: 'STRING_EQUAL',
    property: 'category_of_injury',
    value: null,
    selected: false,
    label: 'Category of Injury',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'category_of_injury',
  },
  //19
  {
    operator: 'STRING_EQUAL',
    property: 'type_of_disablement',
    value: null,
    selected: false,
    label: 'Type of Disablement',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'type_of_disablement',
  },
  //20
  {
    operator: 'STRING_EQUAL',
    property: 'damage_type',
    value: null,
    selected: false,
    label: 'Damage Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'damage_type',
  },
  //21
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_name',
    value: null,
    selected: false,
    label: 'Dependent Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_name',
  },
  //22
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_identification_type',
    value: null,
    selected: false,
    label: 'Dependent Identification Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_identification_type',
  },
  //23
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_id_number',
    value: null,
    selected: false,
    label: 'Dependent Identification Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_id_number',
  },
  //24
  {
    operator: 'STRING_EQUAL',
    property: '',
    value: null,
    selected: false,
    label: 'Property Category',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //25
  {
    operator: 'STRING_EQUAL',
    property: '',
    value: null,
    selected: false,
    label: 'Property Type',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: '',
  },
  //26
  {
    operator: 'STRING_EQUAL',
    property: 'dependent_marital_status',
    value: null,
    selected: false,
    label: 'Marital Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'dependent_marital_status',
  },
  //27
  {
    operator: 'STRING_EQUAL',
    property: 'bank_name',
    value: null,
    selected: false,
    label: 'Bank Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'bank_name',
  },
  //28
  {
    operator: 'STRING_EQUAL',
    property: 'branch_name',
    value: null,
    selected: false,
    label: 'Branch Name',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'branch_name',
  },
  //29
  {
    operator: 'STRING_EQUAL',
    property: 'account_number',
    value: null,
    selected: false,
    label: 'Account Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'account_number',
  },
  //30
  {
    operator: 'STRING_EQUAL',
    property: 'compensation_amount',
    value: null,
    selected: false,
    label: 'Compensation Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'compensation_amount',
  },
  //31
  {
    operator: 'STRING_EQUAL',
    property: 'eligible_compensation_percentage',
    value: null,
    selected: false,
    label: 'Eligible Compensation Percentage %',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'eligible_compensation_percentage',
  },
  //32
  {
    operator: 'STRING_EQUAL',
    property: 'percentage_of_loss_of_earing_capacity',
    value: null,
    selected: false,
    label: 'Loss of earning capacity %',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'percentage_of_loss_of_earing_capacity',
  },
  //33
  {
    operator: 'STRING_EQUAL',
    property: 'balance_loan_amount',
    value: null,
    selected: false,
    label: 'Balance Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'balance_loan_amount',
  },
  //34
  {
    operator: 'STRING_EQUAL',
    property: 'recommended_amount',
    value: null,
    selected: false,
    label: 'Recommended Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'recommended_amount',
  },
  //35
  {
    operator: 'STRING_EQUAL',
    property: 'required_loan_amount',
    value: null,
    selected: false,
    label: 'Required Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'required_loan_amount',
  },
  //36
  {
    operator: 'STRING_EQUAL',
    property: 'repayment_period',
    value: null,
    selected: false,
    label: 'Repayment Period',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'repayment_period',
  },
  //37
  {
    operator: 'STRING_EQUAL',
    property: 'expected_loan_amount',
    value: null,
    selected: false,
    label: 'Expected Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'expected_loan_amount',
  },
  //38
  {
    operator: 'STRING_EQUAL',
    property: 'granted_loan_amount',
    value: null,
    selected: false,
    label: 'Approved Loan Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'granted_loan_amount',
  },
  //39
  {
    operator: 'STRING_EQUAL',
    property: 'voucher_number',
    value: null,
    selected: false,
    label: 'Voucher Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'voucher_number',
  },
  //40
  {
    operator: 'STRING_EQUAL',
    property: 'cheque_number',
    value: null,
    selected: false,
    label: 'Cheque Number',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'cheque_number',
  },
  //41
  {
    operator: 'STRING_EQUAL',
    property: 'cheque_issued_date_readable',
    value: null,
    selected: false,
    label: 'Cheque Issued Date',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'cheque_issued_date_readable',
  },
  //42
  {
    operator: 'STRING_EQUAL',
    property: 'paid_amount',
    value: null,
    selected: false,
    label: 'Paid Amount',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'paid_amount',
  },
  //43
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Application File Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //44
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Application Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //45
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Recommendation Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //46
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Approval Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //47
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Compensation Certification Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
  //48
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Cheque Payment Status',
    dropdownValue: null,
    groupingOperator: 'AND',
    groupProperty: 'status',
  },
]
const today = new Date().getTime() / 1000
export const adminAppDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const adminFileDeliveryReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const propertyFileRecommendationListFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const propertyShortComingListFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
    groupProperty: '',
  },
  //7
  {
    operator: 'STRING_EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
    groupProperty: '',
  },
]

// export const approvalFileDetailFilters: FilterOptionModel[] = [
//   //0
//   {
//     operator: 'GREATER_THAN',
//     property: 'last_printed_date_time',
//     value: Math.round(today),
//     selected: true,
//     label: 'FROM',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //1
//   {
//     operator: 'LESS_THAN',
//     property: 'last_printed_date_time',
//     value: Math.round(today),
//     selected: true,
//     label: 'TO',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //2
//   {
//     operator: 'STRING_EQUAL',
//     property: 'scheme',
//     value: null,
//     selected: false,
//     label: 'Scheme',
//     dropdownValue: null,
//     groupProperty: '',
//   },
// ]

// export const totalPaymentDetailFilters: FilterOptionModel[] = [
//   //0
//   {
//     operator: 'GREATER_THAN',
//     property: 'last_printed_date_time',
//     value: Math.round(today),
//     selected: true,
//     label: 'FROM',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //1
//   {
//     operator: 'LESS_THAN',
//     property: 'last_printed_date_time',
//     value: Math.round(today),
//     selected: true,
//     label: 'TO',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //2
//   {
//     operator: 'STRING_EQUAL',
//     property: 'scheme',
//     value: null,
//     selected: false,
//     label: 'Scheme',
//     dropdownValue: null,
//     groupProperty: '',
//   },
// ]

// export const paymentDetailFilters: FilterOptionModel[] = [
//   //0
//   {
//     operator: 'GREATER_THAN',
//     property: 'cheque_issued_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'FROM',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //1
//   {
//     operator: 'LESS_THAN',
//     property: 'cheque_issued_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'TO',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //2
//   {
//     operator: 'STRING_EQUAL',
//     property: 'scheme',
//     value: null,
//     selected: false,
//     label: 'Scheme',
//     dropdownValue: null,
//     groupProperty: '',
//   },
// ]

// export const ChequePaymentDetailFilters: FilterOptionModel[] = [
//   //0
//   {
//     operator: 'GREATER_THAN',
//     property: 'cheque_issued_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'FROM',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //1
//   {
//     operator: 'LESS_THAN',
//     property: 'cheque_issued_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'TO',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //2
//   {
//     operator: 'STRING_EQUAL',
//     property: 'scheme',
//     value: null,
//     selected: false,
//     label: 'Scheme',
//     dropdownValue: null,
//     groupProperty: '',
//   },
// ]

// export const certificationFileDetailFilters: FilterOptionModel[] = [
//   //0
//   {
//     operator: 'GREATER_THAN',
//     property: 'certified_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'FROM',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //1
//   {
//     operator: 'LESS_THAN',
//     property: 'certified_date',
//     value: Math.round(today),
//     selected: true,
//     label: 'TO',
//     dropdownValue: null,
//     groupProperty: '',
//   },
//   //2
//   {
//     operator: 'STRING_EQUAL',
//     property: 'scheme',
//     value: null,
//     selected: false,
//     label: 'Scheme',
//     dropdownValue: null,
//     groupProperty: '',
//   },
// ]

export const deathFileRecommendationListFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
    groupProperty: '',
  },
  //7
  {
    operator: 'EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const deathShortComingListFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'ds_division_id',
    value: null,
    selected: false,
    label: 'DS',
    dropdownValue: null,
    groupProperty: '',
  },
  //6
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
    groupProperty: '',
  },
  //7
  {
    operator: 'EQUAL',
    property: 'incident_type_id',
    value: null,
    selected: false,
    label: 'Incident Type',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const loanFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'disbursement_entered_date',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'disbursement_entered_date',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //3
  {
    operator: 'EQUAL',
    property: 'province_id',
    value: null,
    selected: false,
    label: 'Province',
    dropdownValue: null,
    groupProperty: '',
  },
  //4
  {
    operator: 'EQUAL',
    property: 'district_id',
    value: null,
    selected: false,
    label: 'District',
    dropdownValue: null,
    groupProperty: '',
  },
  //5
  {
    operator: 'EQUAL',
    property: 'bank_id',
    value: null,
    selected: false,
    label: 'Recommended Bank',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const approvalFileDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
]
export const totalPaymentDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const paymentDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'last_printed_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const cashBookPaymentDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'cheque_issued_date',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'cheque_issued_date',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const chequesPaymentDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'cheque_issued_date',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'cheque_issued_date',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const certificationFileDetailsReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'certified_date',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'certified_date',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
  //2
  {
    operator: 'STRING_EQUAL',
    property: 'scheme',
    value: null,
    selected: false,
    label: 'Scheme',
    dropdownValue: null,
    groupProperty: '',
  },
  //03
  {
    operator: 'STRING_EQUAL',
    property: 'status',
    value: null,
    selected: false,
    label: 'Status',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const gpcReportFilters: FilterOptionModel[] = [
  //0
  {
    operator: 'GREATER_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'FROM',
    dropdownValue: null,
    groupProperty: '',
  },
  //1
  {
    operator: 'LESS_THAN',
    property: 'created_date_time',
    value: Math.round(today),
    selected: true,
    label: 'TO',
    dropdownValue: null,
    groupProperty: '',
  },
]

export const applicantTypes: readonly CommonOptionModel[] = [
  { value: 'Applicant', label: 'Applicant' },
  { value: 'Primary Applicant', label: 'Primary Applicant' },
]

export const gender: readonly CommonOptionModel[] = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
]

export const identificationTypes: readonly CommonOptionModel[] = [
  { value: 'Passport', label: 'Passport' },
  { value: 'Senior Citizen ID', label: 'Senior Citizen ID' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'NIC', label: 'NIC' },
]
export const grievanceTypes: readonly CommonOptionModel[] = [
  { value: 'Death', label: 'Death' },
  { value: 'Injury', label: 'Injury' },
  { value: 'Property Compensation', label: 'Property Compensation' },
]
export const propertyTypes: readonly CommonOptionModel[] = [
  { value: 'House/Building ', label: 'House/Building ' },
  { value: 'Motor Vehicle', label: 'Motor Vehicle' },
  { value: 'Furniture', label: 'Furniture' },
  { value: 'Household Items', label: 'Household Items' },
  { value: 'Other Items', label: 'Other Items' },
]

export const maritalStatus: readonly CommonOptionModel[] = [
  { value: 'Marital Status', label: 'Marital Status' },
  { value: 'Single-Adult', label: 'Single-Adult' },
  { value: '/Single-Minor', label: '/Single-Minor' },
  { value: 'Divorced', label: 'Divorced' },
  { value: 'Widow', label: 'Widow' },
]

export const applicationFileStatus: readonly CommonOptionModel[] = [
  { value: 'File Drafted', label: 'File Drafted' },
  { value: 'File Allocation Pending', label: 'File Allocation Pending' },
  { value: 'File Rejected', label: 'File Rejected' },
  { value: 'File ReAllocated', label: 'File ReAllocated' },
  { value: 'File Cancelled', label: 'File Cancelled' },
  { value: 'File Returned', label: 'File Returned' },
]

export const applicationStatus: readonly CommonOptionModel[] = [
  {
    value: 'Application Creation Pending',
    label: 'Application Creation Pending',
  },
  { value: 'Application Drafted', label: 'Application Drafted' },
  { value: 'Application ReAllocated', label: 'Application ReAllocated' },
  { value: 'Recommendation Pending', label: 'Recommendation Pending' },
  { value: 'Application Closed', label: 'Application Closed' },
  { value: 'Application Rejected', label: 'Application Rejected' },
  { value: 'Application Modify Draft', label: 'Application Modify Draft' },
  {
    value: 'Application Returned Account Details Returned',
    label: 'Application Returned Account Details Returned',
  },
  { value: 'Modify Board of Trustee', label: 'Modify Board of Trustee' },
  { value: 'Modify Instalment Pay', label: 'Modify Instalment Pay' },
  { value: 'Applicant Diseased', label: 'Applicant Diseased' },
]

export const compensationRecommendationStatus: readonly CommonOptionModel[] = [
  { value: 'Recommendation Pending', label: 'Recommendation Pending' },
  { value: 'Recommendation Returned', label: 'Recommendation Returned' },
  { value: 'Compensation Pending', label: 'Compensation Pending' },
  { value: 'Application Returned', label: 'Application Returned' },
]

export const compensationApprovalStatus: readonly CommonOptionModel[] = [
  { value: 'Compensation Pending', label: 'Compensation Pending' },
  { value: 'Compensatio Rejected', label: 'CompensationRejected' },
  { value: 'Compensation Returned', label: 'Compensation Returned' },
  { value: 'Recommendation Returned', label: 'Recommendation Returned' },
  { value: 'Certification Pending', label: 'Certification Pending' },
]

export const compensationCertificationStatus: readonly CommonOptionModel[] = [
  { value: 'Certification Pending', label: 'Certification Pending' },
  { value: 'Certification Rejected', label: 'Certification Rejected' },
  { value: 'Compensation Returned', label: 'Compensation Returned' },
  { value: 'Certification Returned', label: 'Certification Returned' },
  { value: 'Cheque Payment Pending', label: 'Cheque Payment Pending' },
]

export const chequePaymentStatus: readonly CommonOptionModel[] = [
  { value: 'Cheque Payment Pending', label: 'Cheque Payment Pending' },
  { value: 'Cheques Payment Returned', label: 'Cheques Payment Returned' },
  { value: 'Cheque Print Pending', label: 'Cheque Print Pending' },
  { value: 'Cheques Payment Returned', label: 'Cheques Payment Returned' },
  { value: 'Cheque Printed', label: 'Cheque Printed' },
  { value: 'Cheque Cancelled', label: 'Cheque Cancelled' },
  { value: 'Cheque Re-Printed', label: 'Cheque Re-Printed' },
]

export const propertyFilterStatus: readonly CommonOptionModel[] = [
  { value: 'Call for Clarifications', label: 'Call for Clarifications' },
  { value: 'Approval Pending', label: 'Recommended' },
  { value: 'Certification Pending', label: 'Approved' },
  { value: 'Cheque Payment Pending', label: 'Certified' },
  { value: 'Cheque Print Pending', label: 'Cheque Print Pending' },
  { value: 'Payment Completed', label: 'Paid' },
  { value: 'Recommendation Rejected ', label: 'Recommendation Rejected' },
  { value: 'Approval Rejected', label: 'Approval Rejected' },
  { value: 'Certification Rejected', label: 'Certification Rejected' },
]

export const financeReportFilterStatus: readonly CommonOptionModel[] = [
  { value: 'Certification Pending', label: 'Approved' },
  { value: 'Cheque Payment Pending', label: 'Certified' },
]

export const metacategoryList = [
  { id: 1, value: 'Category 1' },
  { id: 2, value: 'Category 2' },
  { id: 3, value: 'Category 3' },
]

export const metasubCategoryList = [
  { id: 1, categoryId: 1, value: 'Sub Category 1' },
  { id: 2, categoryId: 1, value: 'Sub Category 2' },
  { id: 3, categoryId: 2, value: 'Sub Category 3' },
]

export const reportTableColumn = [
  {
    Header: 'Application Reference',
    accessor: 'referenceNumber',
  },
  {
    Header: 'Incident Type',
    accessor: 'incidentType.incidentType',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicantName',
  },

  {
    Header: 'Identification Type',
    accessor: 'identificationType',
  },
  {
    Header: 'ID Number',
    accessor: 'idNumber',
  },
  {
    Header: 'District',
    accessor: 'district.name',
  },
  {
    Header: 'Allocated User',
    accessor: 'allocateTo',
  },
  {
    Header: 'Created Date and Time',
    accessor: 'createdDateTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]

export const paymentReportTableColumns = [
  {
    Header: 'Application Reference',
    accessor: 'reference_number',
  },
  {
    Header: 'OMP Number',
    accessor: 'omp_number',
  },
  {
    Header: 'Scheme',
    accessor: 'scheme',
  },
  {
    Header: 'Applicant Name',
    accessor: 'applicant_name',
  },
  {
    Header: 'Identification Type',
    accessor: 'applicant_identification_type',
  },
  {
    Header: 'Applicant ID Number',
    accessor: 'applicant_id_number',
  },
  {
    Header: 'Dependent Name',
    accessor: 'dependent_full_name',
  },
  {
    Header: 'Cheque Issued Date',
    accessor: 'cheque_issued_date',
  },
  {
    Header: 'Cheque Status',
    accessor: 'cheque_status',
  },
  {
    Header: 'Paid Amount',
    accessor: 'cheque_amount',
  },

  {
    Header: 'Status',
    accessor: 'status',
  },
]

export const reportTableHeaders: ReportTableHeaderModel[] = [
  { headerName: 'Sequence', keyName: 'sequence', isSelected: false },
  { headerName: 'Age', keyName: 'age', isSelected: false },
  {
    headerName: 'Age In Days',
    keyName: 'ageInDays',
    isSelected: false,
  },
  {
    headerName: 'Age In Months',
    keyName: 'ageInMonths',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person Age',
    keyName: 'aggrievedPersonAge',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person DOB',
    keyName: 'aggrievedPersonDob',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person Name',
    keyName: 'aggrievedPersonName',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person New Nic Number',
    keyName: 'aggrievedPersonNewNicNumber',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person Old Nic Number',
    keyName: 'aggrievedPersonOldNicNumber',
    isSelected: false,
  },
  {
    headerName: 'Applicant Name',
    keyName: 'applicantName',
    isSelected: true,
  },
  {
    headerName: 'Applicant Title',
    keyName: 'applicantTitle',
    isSelected: false,
  },
  {
    headerName: 'Applicant Type',
    keyName: 'applicantType',
    isSelected: false,
  },
  {
    headerName: 'Application Reference',
    keyName: 'referenceNumber',
    isSelected: true,
  },
  {
    headerName: 'Applied Date',
    keyName: 'appliedDate',
    isSelected: false,
  },
  {
    headerName: 'Appointment Date',
    keyName: 'appointmentDate',
    isSelected: false,
  },
  {
    headerName: 'Approved By',
    keyName: 'approvedBy',
    isSelected: false,
  },
  {
    headerName: 'Approved Date Time',
    keyName: 'approvedDateTime',
    isSelected: false,
  },
  {
    headerName: 'Asset Identification Number',
    keyName: 'assetIdentificationNumber',
    isSelected: false,
  },
  {
    headerName: 'Balance Loan Amount',
    keyName: 'balanceLoanAmount',
    isSelected: false,
  },
  {
    headerName: 'Birth Certificate Ds Division',
    keyName: 'birthCertificateDsDivision',
    isSelected: false,
  },
  {
    headerName: 'Birth Certificate Number',
    keyName: 'birthCertificateNumber',
    isSelected: false,
  },
  {
    headerName: 'Certificate Of Absence',
    keyName: 'certificateOfAbsence',
    isSelected: false,
  },
  {
    headerName: 'Certified By',
    keyName: 'certifiedBy',
    isSelected: false,
  },
  {
    headerName: 'Certified Date Time',
    keyName: 'certifiedDateTime',
    isSelected: false,
  },
  {
    headerName: 'Compensation Amount',
    keyName: 'compensationAmount',
    isSelected: false,
  },
  {
    headerName: 'Contact Number',
    keyName: 'contactNo',
    isSelected: false,
  },
  {
    headerName: 'Created Date and Time',
    keyName: 'createdDateTime',
    isSelected: true,
  },
  {
    headerName: 'Date Of Birth',
    keyName: 'dateOfBirth',
    isSelected: false,
  },
  {
    headerName: 'Death Certificate Ds Division',
    keyName: 'deathCertificateDsDivision',
    isSelected: false,
  },
  {
    headerName: 'Death Certificate Number',
    keyName: 'deathCertificateNumber',
    isSelected: false,
  },
  { headerName: 'District', keyName: 'district', isSelected: true },
  {
    headerName: 'Driving License Number',
    keyName: 'drivingLicenseNumber',
    isSelected: false,
  },
  {
    headerName: 'Ds Division',
    keyName: 'dsDivision',
    isSelected: false,
  },
  {
    headerName: 'Eligible Compensation Percentage',
    keyName: 'eligibleCompensationPercentage',
    isSelected: false,
  },
  {
    headerName: 'Epf Or Staff Number',
    keyName: 'epfOrStaffNumber',
    isSelected: false,
  },
  {
    headerName: 'Expected Loan Amount',
    keyName: 'expectedLoanAmount',
    isSelected: false,
  },
  {
    headerName: 'File Received Date',
    keyName: 'fileReceivedDate',
    isSelected: false,
  },
  { headerName: 'Gender', keyName: 'gender', isSelected: false },
  {
    headerName: 'Gn Division',
    keyName: 'gnDivision',
    isSelected: false,
  },
  {
    headerName: 'Granted Loan Amount',
    keyName: 'grantedLoanAmount',
    isSelected: false,
  },
  {
    headerName: 'Grievance Type',
    keyName: 'grievanceType',
    isSelected: false,
  },
  { headerName: 'Id Number', keyName: 'idNumber', isSelected: true },
  {
    headerName: 'ID Number Remark',
    keyName: 'idNumberRemark',
    isSelected: true,
  },
  {
    headerName: 'Identification Type',
    keyName: 'identificationType',
    isSelected: true,
  },
  {
    headerName: 'Incident Date',
    keyName: 'incidentDate',
    isSelected: false,
  },
  {
    headerName: 'Incident Type',
    keyName: 'incidentType',
    isSelected: true,
  },
  {
    headerName: 'Marital Status',
    keyName: 'maritalStatus',
    isSelected: false,
  },
  {
    headerName: 'Name Of The Worship Place',
    keyName: 'nameOfTheWorshipPlace',
    isSelected: false,
  },
  {
    headerName: 'New Nic Number',
    keyName: 'newNicNumber',
    isSelected: false,
  },
  {
    headerName: 'Nic Issued Date',
    keyName: 'nicIssuedDate',
    isSelected: false,
  },
  { headerName: 'Nic Number', keyName: 'nicNumber', isSelected: false },
  {
    headerName: 'Occupation Detail',
    keyName: 'occupationDetail',
    isSelected: false,
  },
  {
    headerName: 'Old Nic Number',
    keyName: 'oldNicNumber',
    isSelected: false,
  },
  { headerName: 'Omp Number', keyName: 'ompNumber', isSelected: false },
  {
    headerName: 'Ownership Of The Property',
    keyName: 'ownershipOfTheProperty',
    isSelected: false,
  },
  {
    headerName: 'Passport Number',
    keyName: 'passportNumber',
    isSelected: false,
  },
  {
    headerName: 'Payment Type',
    keyName: 'paymentType',
    isSelected: false,
  },
  {
    headerName: 'Percentage Of Loss Of Earing Capacity',
    keyName: 'percentageOfLossOfEaringCapacity',
    isSelected: false,
  },
  {
    headerName: 'Permanent Address',
    keyName: 'permanentAddress',
    isSelected: false,
  },
  {
    headerName: 'Place Of Incident Occurred',
    keyName: 'placeOfIncidentOccurred',
    isSelected: false,
  },
  {
    headerName: 'Program Type',
    keyName: 'programType',
    isSelected: false,
  },
  {
    headerName: 'Recommended Amount',
    keyName: 'recommendedAmount',
    isSelected: false,
  },
  {
    headerName: 'Recommended By',
    keyName: 'recommendedBy',
    isSelected: false,
  },
  {
    headerName: 'Recommended Date Time',
    keyName: 'recommendedDateTime',
    isSelected: false,
  },

  {
    headerName: 'Relationship',
    keyName: 'relationship',
    isSelected: false,
  },
  {
    headerName: 'Religious Place Reg Number',
    keyName: 'religiousPlaceRegNumber',
    isSelected: false,
  },
  {
    headerName: 'Remarks To Proceed With Duplicates',
    keyName: 'remarksToProceedWithDuplicates',
    isSelected: false,
  },
  {
    headerName: 'Repayment Period',
    keyName: 'repaymentPeriod',
    isSelected: false,
  },
  {
    headerName: 'Required Loan Amount',
    keyName: 'requiredLoanAmount',
    isSelected: false,
  },
  {
    headerName: 'Rlp Registered Institution',
    keyName: 'rlpRegisteredInstitution',
    isSelected: false,
  },
  {
    headerName: 'Rlp Registration Number',
    keyName: 'rlpRegistrationNumber',
    isSelected: false,
  },
  { headerName: 'Scheme', keyName: 'scheme', isSelected: true },
  {
    headerName: 'Self Employment To The Occupation',
    keyName: 'selfEmploymentToTheOccupation',
    isSelected: false,
  },
  {
    headerName: 'Senior Citizen Id',
    keyName: 'seniorCitizenId',
    isSelected: false,
  },
  { headerName: 'Status', keyName: 'status', isSelected: true },
  { headerName: 'Tic Number', keyName: 'ticNumber', isSelected: false },
  {
    headerName: 'Type Of Injury',
    keyName: 'typeOfInjury',
    isSelected: false,
  },
]

export const paymentReportTableHeaders: ReportTableHeaderModel[] = [
  {
    headerName: 'Account Number',
    keyName: 'account_number',
    isSelected: false,
  },
  {
    headerName: 'Amount Percentage',
    keyName: 'amount_percentage',
    isSelected: false,
  },
  {
    headerName: 'Applicant Name',
    keyName: 'applicant_name',
    isSelected: false,
  },
  {
    headerName: 'Application File Id',
    keyName: 'application_file_id',
    isSelected: false,
  },
  { headerName: 'Bank Name', keyName: 'bank_name', isSelected: true },
  {
    headerName: 'Branch Name',
    keyName: 'branch_name',
    isSelected: false,
  },
  {
    headerName: 'Cheque Amount',
    keyName: 'cheque_amount',
    isSelected: true,
  },
  {
    headerName: 'Cheque Number',
    keyName: 'cheque_number',
    isSelected: true,
  },
  {
    headerName: 'Compensation Amount',
    keyName: 'compensation_amount',
    isSelected: true,
  },
  {
    headerName: 'Created Date Time',
    keyName: 'created_date_time',
    isSelected: false,
  },
  {
    headerName: 'Dependent Id',
    keyName: 'dependent_id',
    isSelected: false,
  },
  {
    headerName: 'Dependent Full Name',
    keyName: 'dependent_full_name',
    isSelected: true,
  },
  {
    headerName: 'Dependent Identification Type',
    keyName: 'dependent_identification_type',
    isSelected: true,
  },
  {
    headerName: 'Applicant Identification Type',
    keyName: 'applicant_identification_type',
    isSelected: false,
  },
  {
    headerName: 'Incident Type Id',
    keyName: 'incident_type_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant New Nic Number',
    keyName: 'applicant_new_nic_number',
    isSelected: false,
  },
  {
    headerName: 'Dependent Nic Number',
    keyName: 'dependent_nic_number',
    isSelected: true,
  },
  {
    headerName: 'Dependent Old Nic Number',
    keyName: 'dependent_old_nic_number',
    isSelected: true,
  },
  {
    headerName: 'Applicant Old Nic Number',
    keyName: 'applicant_old_nic_number',
    isSelected: false,
  },
  {
    headerName: 'Omp Number',
    keyName: 'omp_number',
    isSelected: true,
  },
  {
    headerName: 'Paid Amount',
    keyName: 'paid_amount',
    isSelected: true,
  },
  {
    headerName: 'Dependent Passport Number',
    keyName: 'dependent_passport_number',
    isSelected: false,
  },
  {
    headerName: 'Applicant Passport Number',
    keyName: 'applicant_passport_number',
    isSelected: false,
  },
  { headerName: 'Payee', keyName: 'payee', isSelected: false },
  {
    headerName: 'Payment Type',
    keyName: 'payment_type',
    isSelected: false,
  },
  { headerName: 'Posted By', keyName: 'posted_by', isSelected: false },
  {
    headerName: 'Posted Date Time',
    keyName: 'posted_date_time',
    isSelected: false,
  },
  {
    headerName: 'Posted Reference Number',
    keyName: 'posted_reference_number',
    isSelected: false,
  },
  {
    headerName: 'Print Count',
    keyName: 'print_count',
    isSelected: false,
  },
  {
    headerName: 'Print Covering Letter Count',
    keyName: 'print_covering_letter_count',
    isSelected: false,
  },
  {
    headerName: 'Program Type',
    keyName: 'program_type',
    isSelected: false,
  },
  {
    headerName: 'Dependent Province Id',
    keyName: 'dependent_province_id',
    isSelected: false,
  },
  {
    headerName: 'Reference Number',
    keyName: 'reference_number',
    isSelected: true,
  },
  {
    headerName: 'Relationship',
    keyName: 'relationship',
    isSelected: false,
  },
  {
    headerName: 'Religious Place Reg Number',
    keyName: 'religious_place_reg_number',
    isSelected: false,
  },
  { headerName: 'Scheme', keyName: 'scheme', isSelected: true },
  {
    headerName: 'Dependent Senior Citizen Id',
    keyName: 'dependent_senior_citizen_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant Senior Citizen Id',
    keyName: 'applicant_senior_citizen_id',
    isSelected: false,
  },
  {
    headerName: 'Special Remarks',
    keyName: 'special_remarks',
    isSelected: false,
  },
  {
    headerName: 'Stamp Charge',
    keyName: 'stamp_charge',
    isSelected: true,
  },
  { headerName: 'Status', keyName: 'status', isSelected: true },
  {
    headerName: 'Cheque Status',
    keyName: 'cheque_status',
    isSelected: true,
  },
  {
    headerName: 'Tic Number',
    keyName: 'tic_number',
    isSelected: false,
  },
  {
    headerName: 'Dependent Tic Number',
    keyName: 'dependent_tic_number',
    isSelected: false,
  },
  {
    headerName: 'Voucher Number',
    keyName: 'voucher_number',
    isSelected: true,
  },
  {
    headerName: 'District Id',
    keyName: 'district_id',
    isSelected: false,
  },
  {
    headerName: 'Ds Division Id',
    keyName: 'ds_division_id',
    isSelected: false,
  },
]

export const mpReportTableHeaders: ReportTableHeaderModel[] = [
  {
    headerName: 'Sequence',
    keyName: 'sequence',
    isSelected: true,
  },
  {
    headerName: 'Age',
    keyName: 'age',
    isSelected: true,
  },
  {
    headerName: 'Aggrieved Date of Birth',
    keyName: 'aggrievedPersonDateOfBirth',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person Name',
    keyName: 'aggrievedPersonName',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person NIdentification Type',
    keyName: 'aggrievedPersonIdentificationType',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person New Nic Number',
    keyName: 'aggrievedPersonNewNicNumber',
    isSelected: false,
  },
  {
    headerName: 'Aggrieved Person Old Nic Number',
    keyName: 'aggrievedPersonOldNicNumber',
    isSelected: false,
  },
  {
    headerName: 'Allocated To',
    keyName: 'allocateTo',
    isSelected: false,
  },
  {
    headerName: 'Applicant Name',
    keyName: 'applicantName',
    isSelected: true,
  },
  {
    headerName: 'Applicant Title',
    keyName: 'applicantTitle',
    isSelected: false,
  },
  {
    headerName: 'Applicant Type',
    keyName: 'applicantType',
    isSelected: false,
  },
  {
    headerName: 'Applicants DOB',
    keyName: 'dateOfBirth',
    isSelected: true,
  },
  {
    headerName: 'Application Reference',
    keyName: 'referenceNumber',
    isSelected: true,
  },
  {
    headerName: 'Compensation Amount',
    keyName: 'compensationAmount',
    isSelected: true,
  },
  {
    headerName: 'Contact Number',
    keyName: 'contactNo',
    isSelected: false,
  },
  {
    headerName: 'Created By',
    keyName: 'createdBy',
    isSelected: false,
  },
  {
    headerName: 'Created Date',
    keyName: 'createdDateTime',
    isSelected: false,
  },

  {
    headerName: 'District',
    keyName: 'district',
    isSelected: true,
  },
  {
    headerName: 'Ds Division',
    keyName: 'dsDivision',
    isSelected: false,
  },
  {
    headerName: 'File Applied Date',
    keyName: 'appliedDate',
    isSelected: false,
  },
  {
    headerName: 'File Received Date',
    keyName: 'fileReceivedDate',
    isSelected: false,
  },
  {
    headerName: 'Gender',
    keyName: 'gender',
    isSelected: false,
  },
  {
    headerName: 'Gn Division',
    keyName: 'gnDivision',
    isSelected: false,
  },
  {
    headerName: 'Identification Number',
    keyName: 'idNumber',
    isSelected: true,
  },
  {
    headerName: 'ID Number Remark',
    keyName: 'id_number_remark',
    isSelected: true,
  },
  {
    headerName: 'Identification Type',
    keyName: 'identificationType',
    isSelected: true,
  },
  {
    headerName: 'Incident Type',
    keyName: 'incidentType',
    isSelected: true,
  },
  {
    headerName: 'Last Seen At Alive Date',
    keyName: 'incidentDate',
    isSelected: false,
  },
  {
    headerName: 'OMP Number',
    keyName: 'ompNumber',
    isSelected: false,
  },
  {
    headerName: 'Permanent Address',
    keyName: 'permanentAddress',
    isSelected: false,
  },
  {
    headerName: 'Place Of Incident Occurred',
    keyName: 'placeOfIncidentOccurred',
    isSelected: false,
  },
  {
    headerName: 'Program Type',
    keyName: 'programType',
    isSelected: false,
  },
  {
    headerName: 'Province',
    keyName: 'province',
    isSelected: true,
  },
  {
    headerName: 'Relationship to the Applicant',
    keyName: 'relationship',
    isSelected: false,
  },
  {
    headerName: 'Scheme',
    keyName: 'scheme',
    isSelected: true,
  },

  {
    headerName: 'Status',
    keyName: 'status',
    isSelected: true,
  },
]

export const mpPaymentReportTableHeaders: ReportTableHeaderModel[] = [
  {
    headerName: 'Account Number',
    keyName: 'account_number',
    isSelected: true,
  },

  {
    headerName: 'Aggrieved Person Name',
    keyName: 'aggrieved_person_name',
    isSelected: true,
  },

  {
    headerName: 'Allocated To',
    keyName: 'allocateTo',
    isSelected: false,
  },
  {
    headerName: 'Applicant Province',
    keyName: 'applicant_province_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant District',
    keyName: 'applicant_district_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant  DS Division',
    keyName: 'applicant_ds_division_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant  GN Division',
    keyName: 'applicant_gn_division_id',
    isSelected: false,
  },
  {
    headerName: 'Applicant Gender',
    keyName: 'applicant_gender',
    isSelected: false,
  },
  {
    headerName: 'Applicant Identification Type',
    keyName: 'applicant_identification_type',
    isSelected: true,
  },
  {
    headerName: 'Applicant ID Number',
    keyName: 'applicant_id_number',
    isSelected: true,
  },
  {
    headerName: 'Applicant Address',
    keyName: 'applicant_address',
    isSelected: true,
  },
  {
    headerName: 'Applicant Name',
    keyName: 'applicant_name',
    isSelected: true,
  },
  {
    headerName: 'Applicant Title',
    keyName: 'applicant_title',
    isSelected: false,
  },
  {
    headerName: 'Approval Date',
    keyName: 'approved_date_time',
    isSelected: false,
  },
  { headerName: 'Bank Name', keyName: 'bank_name', isSelected: true },
  {
    headerName: 'Branch Name',
    keyName: 'branch_name',
    isSelected: true,
  },
  {
    headerName: 'Certification date',
    keyName: 'certified_date_time',
    isSelected: false,
  },
  {
    headerName: 'Compensation Amount',
    keyName: 'compensation_amount',
    isSelected: true,
  },
  {
    headerName: 'Created By',
    keyName: 'created_by',
    isSelected: false,
  },
  {
    headerName: 'Created Date Time',
    keyName: 'created_date_time',
    isSelected: false,
  },
  {
    headerName: 'Dependent Full Name',
    keyName: 'dependent_full_name',
    isSelected: true,
  },
  {
    headerName: 'Dependent Identification Type',
    keyName: 'dependent_identification_type',
    isSelected: true,
  },
  {
    headerName: 'Dependent ID Number',
    keyName: 'dependent_id_number',
    isSelected: true,
  },

  {
    headerName: 'File Applied Date',
    keyName: 'applied_date',
    isSelected: false,
  },
  {
    headerName: 'File Received Date',
    keyName: 'file_received_date',
    isSelected: false,
  },
  {
    headerName: 'Incident Type',
    keyName: 'incident_type_id',
    isSelected: false,
  },
  {
    headerName: 'Last Seen At Alive Date',
    keyName: 'incident_date',
    isSelected: false,
  },
  {
    headerName: 'OMP Number',
    keyName: 'omp_number',
    isSelected: true,
  },
  {
    headerName: 'Paid Amount',
    keyName: 'cheque_amount',
    isSelected: true,
  },
  {
    headerName: 'Paid Date',
    keyName: 'cheque_issued_date',
    isSelected: true,
  },
  {
    headerName: 'Place Of Incident Occurred',
    keyName: 'placeOfIncidentOccurred',
    isSelected: false,
  },
  {
    headerName: 'Program Type',
    keyName: 'program_type',
    isSelected: true,
  },
  {
    headerName: 'Recommended date',
    keyName: 'recommended_date_time',
    isSelected: false,
  },
  {
    headerName: 'Reference No.',
    keyName: 'reference_number',
    isSelected: false,
  },
  {
    headerName: 'Relationship to the Applicant',
    keyName: 'relationship_to_the_applicant',
    isSelected: false,
  },
  {
    headerName: 'Scheme',
    keyName: 'scheme',
    isSelected: true,
  },
  {
    headerName: 'Status',
    keyName: 'status',
    isSelected: true,
  },
  {
    headerName: 'Voucher Number',
    keyName: 'voucher_number',
    isSelected: true,
  },
]

export const mpStatReportTableHeaders = (status: string) => {
  const headers = [
    {
      headerName: 'Sequence',
      keyName: 'sequence',
      isSelected: true,
    },
    {
      headerName: 'Age',
      keyName: 'age',
      isSelected: true,
    },
    {
      headerName: 'Aggrieved Date of Birth',
      keyName: 'aggrievedPersonDateOfBirth',
      isSelected: false,
    },
    {
      headerName: 'Aggrieved Person Name',
      keyName: 'aggrievedPersonName',
      isSelected: false,
    },
    {
      headerName: 'Aggrieved Person NIdentification Type',
      keyName: 'aggrievedPersonIdentificationType',
      isSelected: false,
    },
    {
      headerName: 'Aggrieved Person New Nic Number',
      keyName: 'aggrievedPersonNewNicNumber',
      isSelected: false,
    },
    {
      headerName: 'Aggrieved Person Old Nic Number',
      keyName: 'aggrievedPersonOldNicNumber',
      isSelected: false,
    },
    {
      headerName: 'Allocated To',
      keyName: 'allocateTo',
      isSelected: false,
    },
    {
      headerName: 'Applicant Name',
      keyName: 'applicantName',
      isSelected: true,
    },
    {
      headerName: 'Applicant Title',
      keyName: 'applicantTitle',
      isSelected: false,
    },
    {
      headerName: 'Applicant Type',
      keyName: 'applicantType',
      isSelected: false,
    },
    {
      headerName: 'Applicants DOB',
      keyName: 'dateOfBirth',
      isSelected: true,
    },
    {
      headerName: 'Application Reference',
      keyName: 'referenceNumber',
      isSelected: true,
    },
    {
      headerName: 'Compensation Amount',
      keyName: 'compensationAmount',
      isSelected: true,
    },

    {
      headerName: 'Contact Number',
      keyName: 'contactNo',
      isSelected: false,
    },
    {
      headerName: 'Created By',
      keyName: 'createdBy',
      isSelected: false,
    },
    {
      headerName: 'Created Date',
      keyName: 'createdDateTime',
      isSelected: false,
    },

    {
      headerName: 'District',
      keyName: 'district',
      isSelected: true,
    },
    {
      headerName: 'Ds Division',
      keyName: 'dsDivision',
      isSelected: false,
    },
    {
      headerName: 'File Applied Date',
      keyName: 'appliedDate',
      isSelected: false,
    },
    {
      headerName: 'File Received Date',
      keyName: 'fileReceivedDate',
      isSelected: false,
    },
    {
      headerName: 'Gender',
      keyName: 'gender',
      isSelected: false,
    },
    {
      headerName: 'Gn Division',
      keyName: 'gnDivision',
      isSelected: false,
    },
    {
      headerName: 'Identification Number',
      keyName: 'idNumber',
      isSelected: true,
    },
    {
      headerName: 'ID Number Remark',
      keyName: 'id_number_remark',
      isSelected: true,
    },
    {
      headerName: 'Identification Type',
      keyName: 'identificationType',
      isSelected: true,
    },
    {
      headerName: 'Incident Type',
      keyName: 'incidentType',
      isSelected: true,
    },
    {
      headerName: 'Last Seen At Alive Date',
      keyName: 'incidentDate',
      isSelected: false,
    },
    {
      headerName: 'OMP Number',
      keyName: 'ompNumber',
      isSelected: false,
    },
    {
      headerName: 'Permanent Address',
      keyName: 'permanentAddress',
      isSelected: false,
    },
    {
      headerName: 'Place Of Incident Occurred',
      keyName: 'placeOfIncidentOccurred',
      isSelected: false,
    },
    {
      headerName: 'Program Type',
      keyName: 'programType',
      isSelected: false,
    },
    {
      headerName: 'Province',
      keyName: 'province',
      isSelected: true,
    },
    {
      headerName: 'Relationship to the Applicant',
      keyName: 'relationship',
      isSelected: false,
    },
    {
      headerName: 'Scheme',
      keyName: 'scheme',
      isSelected: true,
    },

    {
      headerName: 'Status',
      keyName: 'status',
      isSelected: true,
    },
  ]

  const obj1 = {
    headerName: 'Paid amount',
    keyName: 'paidAmount',
    isSelected: true,
  }

  const obj2 = {
    headerName: 'Amount to be paid',
    keyName: 'amountToBePaid',
    isSelected: true,
  }

  if (status === 'Payment Completed') {
    headers.splice(14, 0, obj1)
  } else {
    headers.splice(14, 0, obj2)
  }

  return headers
}
