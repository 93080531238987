import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import { programeTypes, Schemeption, schemesList } from '../../utils/metaData'
import FilterButton from '../common/filterButton/FilterButton'
import Select from 'react-select'
import {
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
  getProvincesOptions,
} from '../../store/administrativeArea/selectors'
import { useSelector } from 'react-redux'
import { DistrictOption } from '../../models/selectOptionModels/districtOption'
import { DsOption } from '../../models/selectOptionModels/dsOption'
import { GnOption } from '../../models/selectOptionModels/gnOption'
import { CommonTypeOption } from '../../models/selectOptionModels/commonOptionType'
import metaDataService from '../../services/metaDataService'
import ReactDatePicker from 'react-datepicker'

export interface FileAllocationFiltersProps {
  filterData: FilterOptionModel[]
  onFilter: any
  isDraft?: boolean
}

const FileAllocationFilters: FC<FileAllocationFiltersProps> = ({
  filterData,
  onFilter,
  isDraft = false,
}) => {
  const [filters, setFilters] = useState(filterData)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [show, setShow] = useState(false)
  const [incidentTypeList, setIncidentTypeList] = useState(
    [] as CommonTypeOption[]
  )

  useEffect(() => {
    async function getData() {
      const result = await metaDataService.apiIncidentTypeList()
      const incidentTypeArray: CommonTypeOption[] = []
      for (let i = 0; i < result.data.length; i++) {
        const incidentTypeObject = {} as CommonTypeOption
        incidentTypeObject.value = result.data[i].id
        incidentTypeObject.label = result.data[i].incidentType
        incidentTypeArray.push(incidentTypeObject)
      }
      setIncidentTypeList(incidentTypeArray)
    }
    getData()
    setFilters(filterData)
  }, [show])
  return (
    <>
      <FilterButton
        onClick={() => {
          setShow(true)
        }}
      />
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false)
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="custom-modal-layout"
        contentClassName="custom-modal-content"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="custom-modal-header"
        >
          <Modal.Title id="example-modal-sizes-title-sm">{`Filter`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            {filters.length > 0 && (
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formReferenceNumber"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[0].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Reference Number`}
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[0].selected}
                      type="text"
                      placeholder={`Reference Number`}
                      value={filters[0].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[0].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formProgrameType"
                >
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[1].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Programe Type`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[1].dropdownValue}
                      isDisabled={!filters[1].selected}
                      options={programeTypes}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[1].value = selectedOption?.value
                            draft[1].dropdownValue = selectedOption
                            draft[2].value = null
                            draft[2].dropdownValue = null
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formScheme">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      disabled={filters[1].dropdownValue === null}
                      checked={filters[2].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[2].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`Scheme`}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[2].dropdownValue}
                      isDisabled={
                        !filters[2].selected ||
                        filters[1].dropdownValue === null
                      }
                      options={schemesList.filter((scheme: Schemeption) => {
                        return scheme.programeType === filters[1].value
                      })}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[2].value = selectedOption?.value
                            draft[2].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="incidentType">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[3].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[3].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {filters[3].label}
                  </Form.Label>
                  <Col sm="6">
                    <Select
                      value={filters[3].dropdownValue}
                      isDisabled={!filters[3].selected}
                      options={incidentTypeList}
                      onChange={(selectedOption) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[3].value = selectedOption?.value
                            draft[3].dropdownValue = selectedOption
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formNic">
                  <Col sm="1" className="align-self-center">
                    <Form.Check
                      type="checkbox"
                      checked={filters[4].selected}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].selected = e.target.checked
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                  <Form.Label column sm="5">
                    {`${filters[4].label}`}
                    <Form.Check
                      disabled={!filters[4].selected}
                      inline
                      name="formNICNumberType"
                      label="Old"
                      type="radio"
                      id={`formNICNumberType-O`}
                      className="custom-margin-left"
                      checked={filters[4].property != 'new_nic_number'}
                      onChange={(e) => {
                        e.stopPropagation()
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].property = 'old_nic_number'
                            draft[4].operator = 'STRING_EQUAL'
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                    <Form.Check
                      disabled={!filters[4].selected}
                      inline
                      name="formNICNumberType"
                      label="New"
                      type="radio"
                      id={`formNICNumberType-N`}
                      checked={filters[4].property === 'new_nic_number'}
                      onChange={(e) => {
                        e.stopPropagation()
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].property = 'new_nic_number'
                            draft[4].operator = 'Equal'
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled={!filters[4].selected}
                      type="text"
                      placeholder={`NIC`}
                      value={filters[4].value}
                      onChange={(e) => {
                        const newFilters = produce(
                          filters,
                          (draft: FilterOptionModel[]) => {
                            draft[4].value = e.target.value
                          }
                        )
                        setFilters(newFilters)
                      }}
                    />
                  </Col>
                </Form.Group>
                {!isDraft && (
                  <>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formProvince"
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[5].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[5].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[5].label}
                      </Form.Label>
                      <Col sm="6">
                        <Select
                          value={filters[5].dropdownValue}
                          isDisabled={!filters[5].selected}
                          options={provinceList}
                          onChange={(selectedOption) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[5].value = selectedOption?.value
                                draft[5].dropdownValue = selectedOption
                                draft[6].value = null
                                draft[6].dropdownValue = null
                                draft[6].selected = false
                                draft[7].value = null
                                draft[7].dropdownValue = null
                                draft[7].selected = false
                                draft[8].value = null
                                draft[8].dropdownValue = null
                                draft[8].selected = false
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formDistrict"
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          disabled={filters[5].dropdownValue == null}
                          type="checkbox"
                          checked={filters[6].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[6].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[6].label}
                      </Form.Label>
                      <Col sm="6">
                        <Select
                          value={filters[6].dropdownValue}
                          isDisabled={
                            !filters[6].selected ||
                            filters[5].dropdownValue == null
                          }
                          options={districtList.filter(
                            (district: DistrictOption) => {
                              return district.provinceId === filters[5].value
                            }
                          )}
                          onChange={(selectedOption) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[6].value = selectedOption?.value
                                draft[6].dropdownValue = selectedOption
                                draft[7].value = null
                                draft[7].selected = false
                                draft[7].dropdownValue = null
                                draft[8].value = null
                                draft[8].dropdownValue = null
                                draft[8].selected = false
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formDs">
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          disabled={filters[6].dropdownValue == null}
                          checked={filters[7].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[7].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[7].label}
                      </Form.Label>
                      <Col sm="6">
                        <Select
                          value={filters[7].dropdownValue}
                          isDisabled={
                            !filters[7].selected ||
                            filters[6].dropdownValue == null
                          }
                          options={dsList.filter((ds: DsOption) => {
                            return ds.districtId === filters[6].value
                          })}
                          onChange={(selectedOption) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[7].value = selectedOption?.value
                                draft[7].dropdownValue = selectedOption
                                draft[8].value = null
                                draft[8].dropdownValue = null
                                draft[8].selected = false
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formGN">
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          disabled={filters[7].dropdownValue == null}
                          checked={filters[8].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[8].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[8].label}
                      </Form.Label>
                      <Col sm="6">
                        <Select
                          value={filters[8].dropdownValue}
                          isDisabled={
                            !filters[8].selected ||
                            filters[7].dropdownValue == null
                          }
                          options={gnList.filter((ds: GnOption) => {
                            return ds.dsId === filters[7].value
                          })}
                          onChange={(selectedOption) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[8].value = selectedOption?.value
                                draft[8].dropdownValue = selectedOption
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="fileReceivedDate"
                    >
                      <Col sm="1" className="align-self-center">
                        <Form.Check
                          type="checkbox"
                          checked={filters[9].selected}
                          onChange={(e) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                draft[9].selected = e.target.checked
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                      <Form.Label column sm="5">
                        {filters[9].label}
                      </Form.Label>
                      <Col sm="6">
                        <ReactDatePicker
                          maxDate={new Date()}
                          disabled={!filters[9].selected}
                          className={'form-control'}
                          placeholderText="File Recieved Date"
                          selected={
                            filters[9].value != null
                              ? new Date(filters[9].value * 1000)
                              : null
                          }
                          onChange={(date: Date) => {
                            const newFilters = produce(
                              filters,
                              (draft: FilterOptionModel[]) => {
                                const newDate = new Date(
                                  `${date.getFullYear()}-${
                                    date.getMonth() + 1
                                  }-${date.getDate()}`
                                )

                                draft[9].value = date
                                  ? newDate.getTime() / 1000
                                  : null
                              }
                            )
                            setFilters(newFilters)
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="save-button"
            onClick={() => {
              onFilter(filters)
              setShow(false)
            }}
          >
            Filter Results
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FileAllocationFilters
