import React, { FC } from 'react'
import { useTable } from 'react-table'
import { customCellRender } from '../../utils/utilFunctions'

export interface ReactTableComponentProps {
  columns: any
  data: any
  onClickRow?: any
}

const ReactTableComponent: FC<ReactTableComponentProps> = ({
  columns,
  data,
  onClickRow,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })
  return (
    <table className="table table-bordered table-hover" {...getTableProps()}>
      <thead className="custom-table-header">
        {headerGroups.map((headerGroup: any, index: number) => (
          <tr key={`header-${index}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index: number) => (
              <th key={`header-tr-${index}`} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="custom-table-body" {...getTableBodyProps()}>
        {rows.map((row: any, index: number) => {
          prepareRow(row)
          return (
            <tr
              onClick={() => onClickRow(row.original)}
              key={`row-tr-${index}`}
              {...row.getRowProps()}
            >
              {row.cells.map((cell: any, index: number) => {
                return (
                  <td
                    className="custom-table-data"
                    key={`col-td-${index}`}
                    {...cell.getCellProps()}
                  >
                    {customCellRender(cell)}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ReactTableComponent
