import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import AhApplication from './AhApplication'
import AhFileView from './AhFileView'
import AhReturn from './AhReturn'

const Ah = () => {
  const { path } = useRouteMatch()

  const [selectedSection, setSelectedSection] = useState('ah')

  const actionListModule = useSelector(
    getUserModuleActions('compensation-recommendation', 'loan', 'ah')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'compensation-recommendation',
      'loan',
      'ah',
      'file-return'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="ah">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('ah')
                    }}
                    eventKey="ah"
                  >
                    AH Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('ah-return')
                    }}
                    eventKey="ah-return"
                  >
                    AH Return
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'ah' ? (
              <>
                {checkPermission('view', actionListModule) && <AhApplication />}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && <AhReturn />}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <AhFileView type="view" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <AhFileView type="file-return" {...props} />}
      />
    </Switch>
  )
}

export default Ah
