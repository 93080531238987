import React, { FC } from 'react'
import { usePagination, useTable } from 'react-table'
import { customCellRender } from '../../utils/utilFunctions'

export interface IterminalTableComponentProps {
  columns: any
  data: any
  onClickRow?: any
  fetchData: any
  loading: boolean
  pageCount: number
  totalCount: number
}

const IterminalTableComponent: FC<IterminalTableComponentProps> = ({
  columns,
  data,
  onClickRow,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [pageIndex])

  const renderPageNumbers = (pageCount: number) => {
    const pageList: number[] = []
    if (pageCount > 10) {
      pageList.push(1)
      pageList.push(2)
      pageList.push(3)
      pageList.push(-1)
      pageList.push(pageCount - 2)
      pageList.push(pageCount - 1)
      pageList.push(pageCount)
    } else {
      for (let i = 0; i < pageCount; i++) {
        pageList.push(i + 1)
      }
    }
    console.log(pageList)
    return pageList.map((page) => {
      return (
        <>
          {page !== -1 && (
            <button
              className={`table-page-button ${
                pageIndex == page - 1 ? 'active-page' : ''
              }`}
              key={`page-${page}`}
              onClick={() => gotoPage(page - 1)}
            >
              {`${page}`}
            </button>
          )}
          {page === -1 && <span className="pagination-elipses">...</span>}
        </>
      )
    })
  }

  return (
    <div className="table-wrapper">
      <table className="table table-bordered table-hover " {...getTableProps()}>
        <thead className="custom-table-header">
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr key={`header-${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index: number) => (
                <th key={`header-tr-${index}`} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="custom-table-body" {...getTableBodyProps()}>
          {page.map((row: any, index: number) => {
            prepareRow(row)
            return (
              <tr
                onClick={() => onClickRow(row.original)}
                key={`row-tr-${index}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td
                      className="custom-table-data"
                      key={`col-td-${index}`}
                      {...cell.getCellProps()}
                    >
                      {customCellRender(cell)}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                Showing {page.length} of ~{totalCount} results
              </td>
            )}
          </tr>
        </tbody>
      </table>
      {pageOptions.length > 1 && (
        <div className="pagination">
          <button
            className={`table-nav-button ${canPreviousPage ? 'enable' : ''}`}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'< Previous'}
          </button>{' '}
          {renderPageNumbers(pageCount)}
          <button
            className={`table-nav-button ${canNextPage ? 'enable' : ''}`}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'Next >'}
          </button>{' '}
          <div className="pagination-go-to-page">
            <span> Go to page </span>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default IterminalTableComponent
