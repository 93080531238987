import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Col, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { AffectedPropertyModel } from '../../../../models/applicationRegistration/compensation/affectedPropertyModel'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import gpcService from '../../../../services/applicationRegistration/compensation/gpcService'
import gscService from '../../../../services/applicationRegistration/compensation/gscService'
import rlpService from '../../../../services/applicationRegistration/compensation/rlpService'
import commonService from '../../../../services/applicationRegistration/common'
import { OtherAssistanceModel } from '../../../../models/applicationRegistration/compensation/OtherAssistanceModel'
import metaDataService from '../../../../services/metaDataService'
import { useDispatch, useSelector } from 'react-redux'
import {
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
  getProvincesOptions,
} from '../../../../store/administrativeArea/selectors'
import { DistrictOption } from '../../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../../models/selectOptionModels/dsOption'
import { GnOption } from '../../../../models/selectOptionModels/gnOption'
import { ProvinceOption } from '../../../../models/selectOptionModels/provinceOption'
import { ResponseObject } from '../../../../services/api/apiManager'
import { ownershipList } from '../../../../utils/metaData'
import {
  checkApplicationRegistrationButtonEnable,
  checkInstallmentMergeEnable,
  checkLoggedUser,
  checkSectionThreeNextButtonDisable,
  checkStatsDraftOrModifyDraft,
  currencyMask,
  handleNotification,
  thousandSeperator,
  validateForm,
} from '../../../../utils/utilFunctions'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import { CompensationValueDtoModel } from '../../../../models/class/CompensationValueDto'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { PropertyDamageTypeModel } from '../../../../models/systemConfiguration/propertyDamageType/propertyDamageTypeModel'
import { AffectedOtherAssistanceModel } from '../../../../models/applicationRegistration/compensation/affectedPropertyOtherAsisstanceModel'
import { AffectedPropertyDamageModel } from '../../../../models/applicationRegistration/compensation/affectedPropertyDamageModel'
import PropertyArea from '../../../../components/property/PropertyArea'
import { InstallmentModel } from '../../../../models/applicationRegistration/compensation/installmentModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import { AffectedPropertyErrorModel } from '../../../../models/applicationRegistration/compensation/affectedPropertyErrorModel'
import { getUser } from '../../../../store/user/selectors'
import OtherAssistanceDetailsTable from '../../../../components/common/otherAssistanceDetailsTable/OtherAssistanceDetailsTable'

export interface GpcSectionThreeProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
  onNextSection: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const GpcSectionThree: FC<GpcSectionThreeProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
  onNextSection,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [loading, setLoading] = useState(true)
  const [damageTypeList, setDamageTypeList] = useState([])
  const [policeStationList, setPoliceStationList] = useState([])
  const [oRRecommendedAmount, setORRecommendedAmount] = useState(0)
  const [propertyTypeList, setPropertyTypeList] = useState(
    [] as PropertyDamageTypeModel[]
  )
  const [otherAssistanceDetail, setOtherAssistanceDetails] = useState(
    {} as OtherAssistanceModel
  )
  const [affectedProperty, setAffectedProperty] = useState(
    {} as AffectedPropertyModel
  )
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  const [errors, setErrors] = useState({} as AffectedPropertyErrorModel)

  const dispatch = useDispatch()
  const user = useSelector(getUser)
  useEffect(() => {
    async function getData() {
      const damagedata = await metaDataService.getDamageTypeList()
      if (damagedata.status == 200) {
        setDamageTypeList(damagedata.data)
      }
      const policeData = await metaDataService.getPoliceStationList()
      if (policeData.status == 200) {
        setPoliceStationList(policeData.data)
      }
      const propertyTypeData = await metaDataService.getPropertyTypesByScheme(
        fileRegistrationObject.scheme
      )
      if (propertyTypeData.status == 200) {
        const filterdProperty = propertyTypeData.data.filter(
          (data: { status: string }) => {
            return data.status == 'Active'
          }
        )
        setPropertyTypeList(filterdProperty)
      }
      let affectedPropertyData = {} as ResponseObject
      if (fileRegistrationObject.scheme === 'GPC') {
        affectedPropertyData = await gpcService.apiGetGpcAffectedPropertyData(
          fileRegistrationObject.id
        )
      } else if (fileRegistrationObject.scheme === 'GSC') {
        affectedPropertyData = await gscService.apiGetGscAffectedPropertyData(
          fileRegistrationObject.id
        )
      } else if (fileRegistrationObject.scheme === 'RLP') {
        affectedPropertyData = await rlpService.apiGetRlpAffectedPropertyData(
          fileRegistrationObject.id
        )
      }

      if (affectedPropertyData.status === 200) {
        const installmentData =
          await metaDataService.getApplicationInstallmentAmountList(
            fileRegistrationObject.id
          )

        if (installmentData.status === 200) {
          const sortedIntallment = installmentData.data.sort(
            (
              a: { installmentNumber: number },
              b: { installmentNumber: number }
            ) => {
              return a.installmentNumber - b.installmentNumber
            }
          )
          setInstallmentList(sortedIntallment)
        }
        const affectedPropertyDetails: AffectedPropertyModel =
          affectedPropertyData.data
        affectedPropertyDetails.propertyDamageList = JSON.parse(
          affectedPropertyDetails.propertyDamageData
        )
        setAffectedProperty(affectedPropertyDetails)
      } else {
        const newAffectedProperty = produce(
          affectedProperty,
          (draft: AffectedPropertyModel) => {
            draft.applicationFileId = fileRegistrationObject.id
            draft.provinceId = fileRegistrationObject.provinceId
            draft.districtId = fileRegistrationObject.districtId
            draft.dsDivisionId = fileRegistrationObject.dsDivisionId
            draft.gnDivisionId = fileRegistrationObject.gnDivisionId
            draft.incidentDate = fileRegistrationObject.incidentDate
            draft.otherAssistanceList = [] as AffectedOtherAssistanceModel[]
            draft.propertyDamageList = [] as AffectedPropertyDamageModel[]
            if (
              fileRegistrationObject.scheme === 'GPC' ||
              fileRegistrationObject.scheme === 'GSC'
            ) {
              draft.damageType = 'Full'
            }
          }
        )
        setAffectedProperty(newAffectedProperty)
      }

      setLoading(false)
    }

    getData()
  }, [fileRegistrationObject])
  // const customStyles = {
  //   control: (base: any, state: { isFocused: any }) => ({
  //     ...base,
  //     // state.isFocused can display different borderColor if you need it
  //     borderColor: state.isFocused ? '#ddd' : 'red',
  //     // overwrittes hover style
  //     '&:hover': {
  //       borderColor: state.isFocused ? '#ddd' : 'red',
  //     },
  //   }),
  // }

  const findErrors = () => {
    const newErrors = {} as AffectedPropertyErrorModel

    if (fileRegistrationObject.scheme === 'GPC') {
      if (
        validateForm(
          {
            type: text,
            maxLength: 100,
            value: affectedProperty.remark,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.gpcRemarkError = validateForm(
          {
            type: text,
            maxLength: 100,
            value: affectedProperty.remark,
            section: 'Remark',
          } as ValidationObjectModel,
          false
        )
      }
    }
    if (fileRegistrationObject.scheme === 'RLP') {
      if (
        validateForm({
          type: text,
          maxLength: 50,
          value: affectedProperty.nameOfTheWorshipPlace,
        } as ValidationObjectModel)
      ) {
        newErrors.nameOfTheWorshipPlaceError = validateForm({
          type: text,
          maxLength: 50,
          value: affectedProperty.nameOfTheWorshipPlace,
          section: 'Name of the Worship Place',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          maxLength: 20,
          value: affectedProperty.villageOrTown,
        } as ValidationObjectModel)
      ) {
        newErrors.villageOrTownError = validateForm({
          type: text,
          maxLength: 20,
          value: affectedProperty.villageOrTown,
          section: 'Village / Town',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: number,
          value: affectedProperty.detailsOfLocationPoliceStationId,
        } as ValidationObjectModel)
      ) {
        newErrors.detailsOfLocationPoliceStationError = validateForm({
          type: number,
          value: affectedProperty.detailsOfLocationPoliceStationId,
          section: 'Police Station',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          maxLength: 20,
          value: affectedProperty.registrationNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.registrationNumberError = validateForm({
          type: text,
          maxLength: 20,
          value: affectedProperty.registrationNumber,
          section: 'Registration Number of the Religious Place',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          maxLength: 40,
          value: affectedProperty.registeredInstitution,
        } as ValidationObjectModel)
      ) {
        newErrors.registeredInstitutionError = validateForm({
          type: text,
          maxLength: 40,
          value: affectedProperty.registeredInstitution,
          section: 'Registered Institution of the Religious Place',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: number,
          maxLength: 9,
          value: affectedProperty.assessmentOfTheDamage,
        } as ValidationObjectModel)
      ) {
        newErrors.assessmentOfTheDamageError = validateForm({
          type: number,
          maxLength: 9,
          value: affectedProperty.assessmentOfTheDamage,
          section: 'Assessment of the damage',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: text,
          maxLength: 200,
          value: affectedProperty.presentConditionOfTheDamagedPlace,
        } as ValidationObjectModel)
      ) {
        newErrors.presentConditionOfTheDamagedPlaceError = validateForm({
          type: text,
          maxLength: 200,
          value: affectedProperty.presentConditionOfTheDamagedPlace,
          section: 'Present condition of the damaged place',
        } as ValidationObjectModel)
      }
      if (
        validateForm(
          {
            type: number,
            maxLength: 9,
            value: affectedProperty.amountSpentForRepairs,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.amountSpentForRepairsError = validateForm(
          {
            type: number,
            maxLength: 9,
            value: affectedProperty.amountSpentForRepairs,
            section: 'Amount spent for repairs (Rs.)',
          } as ValidationObjectModel,
          false
        )
      }
    }

    if (
      validateForm({
        type: text,
        maxLength: 255,
        value: affectedProperty.addressOfTheProperty,
      } as ValidationObjectModel)
    ) {
      newErrors.addressOfThePropertyError = validateForm({
        type: text,
        maxLength: 255,
        value: affectedProperty.addressOfTheProperty,
        section: 'Address of the property',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        maxLength: 100,
        value: affectedProperty.placeOfTheIncidentOccurred,
      } as ValidationObjectModel)
    ) {
      newErrors.placeOfTheIncidentOccurredError = validateForm({
        type: text,
        maxLength: 100,
        value: affectedProperty.placeOfTheIncidentOccurred,
        section: 'Place where the incident has occurred',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: affectedProperty.damageType,
      } as ValidationObjectModel)
    ) {
      newErrors.damageTypeError = validateForm({
        type: text,
        value: affectedProperty.damageType,
        section: 'Type of the damage happened',
      } as ValidationObjectModel)
    }
    if (affectedProperty.damageType == 'Partial') {
      if (
        validateForm({
          type: number,
          maxLength: 2,
          value: affectedProperty.damage,
        } as ValidationObjectModel)
      ) {
        newErrors.damageError = validateForm({
          type: number,
          maxLength: 2,
          value: affectedProperty.damage,
          section: 'Percentage value of the damage',
        } as ValidationObjectModel)
      }
    }
    if (affectedProperty.reasonsForDamage) {
      if (
        validateForm({
          type: text,
          maxLength: 100,
          value: affectedProperty.reasonsForDamage,
        } as ValidationObjectModel)
      ) {
        newErrors.reasonsForDamageError = validateForm({
          type: text,
          maxLength: 100,
          value: affectedProperty.reasonsForDamage,
          section: 'Reasons for damage',
        } as ValidationObjectModel)
      }
    }
    if (fileRegistrationObject.scheme !== 'RLP') {
      if (
        validateForm({
          type: text,
          value: affectedProperty.ownershipOfTheProperty,
        } as ValidationObjectModel)
      ) {
        newErrors.ownershipOfThePropertyError = validateForm({
          type: text,
          value: affectedProperty.ownershipOfTheProperty,
          section: 'Ownership of the property',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: number,
          maxLength: 9,
          value: affectedProperty.recommendedAmount,
        } as ValidationObjectModel)
      ) {
        newErrors.recommendedAmountError = validateForm({
          type: number,
          maxLength: 9,
          value: affectedProperty.recommendedAmount,
          section: 'Recommended amount (Rs.)',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm({
        type: 'date',
        value: affectedProperty.complaintDate,
      } as ValidationObjectModel)
    ) {
      newErrors.complaintDateError = validateForm({
        type: 'date',
        value: affectedProperty.complaintDate,
        section: 'Complaint date',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          maxLength: 30,
          value: affectedProperty.complaintNumber,
        } as ValidationObjectModel,
        false
      )
    ) {
      newErrors.complaintNumberError = validateForm(
        {
          type: text,
          maxLength: 30,
          value: affectedProperty.complaintNumber,
          section: 'Complain number',
        } as ValidationObjectModel,
        false
      )
    }

    if (affectedProperty.policeStation) {
      if (
        validateForm(
          {
            type: text,
            maxLength: 30,
            value: affectedProperty.policeStation,
          } as ValidationObjectModel,
          false
        )
      ) {
        newErrors.policeStationError = validateForm(
          {
            type: text,
            maxLength: 30,
            value: affectedProperty.policeStation,
            section: 'Police station',
          } as ValidationObjectModel,
          false
        )
      }
    } else {
      if (affectedProperty.reasons) {
        if (
          validateForm({
            type: text,
            maxLength: 200,
            value: affectedProperty.reasons,
          } as ValidationObjectModel)
        ) {
          newErrors.reasonsError = validateForm({
            type: text,
            maxLength: 200,
            value: affectedProperty.reasons,
            section:
              'Reasons for not made the complaint promptly after the incident',
          } as ValidationObjectModel)
        }
      }
    }
    if (
      validateForm({
        type: number,
        maxLength: 9,
        value: affectedProperty.compensationAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.compensationAmountError = validateForm({
        type: number,
        maxLength: 9,
        value: affectedProperty.compensationAmount,
        section: 'Compensation amount',
      } as ValidationObjectModel)
    }
    if (
      fileRegistrationObject.scheme == 'GPC' ||
      fileRegistrationObject.scheme == 'GSC'
    ) {
      if (
        validateForm({
          type: number,
          minValue: 1,
          value: oRRecommendedAmount,
          customMessage: 'Value Approved by the OR cannot be empty !',
        } as ValidationObjectModel)
      ) {
        newErrors.oRRecommendedAmountError = validateForm({
          type: number,
          minValue: 1,
          value: oRRecommendedAmount,
          section: 'OR recommended amount',
          customMessage: 'Value Approved by the OR cannot be empty !',
        } as ValidationObjectModel)
      }
    }

    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNextSection = () => {
    onNextSection()
  }

  const handleConfirm = () => {
    const newAffectedProperty = produce(
      affectedProperty,
      (draft: AffectedPropertyModel) => {
        draft.propertyDamageData = JSON.stringify(draft.propertyDamageList)
      }
    )
    setAffectedProperty(newAffectedProperty)
    if (showConfirm.section === 'save') {
      onSave(newAffectedProperty)
    } else {
      onNext(newAffectedProperty)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const handleAddOtherAssistance = () => {
    const newAffectedDetails = produce(affectedProperty, (draft) => {
      draft.otherAssistanceList.push(otherAssistanceDetail)
      draft.compensationAmount = ''
    })
    setAffectedProperty(newAffectedDetails)
    setOtherAssistanceDetails({
      institution: '',
      amountReceived: 0,
    } as OtherAssistanceModel)
  }

  const checkAddOtherDetails = () => {
    if (
      otherAssistanceDetail == undefined ||
      otherAssistanceDetail.institution === '' ||
      otherAssistanceDetail.amountReceived == null ||
      otherAssistanceDetail.receivedDate == null
    ) {
      return true
    }
    return false
  }

  const handleCalculation = async () => {
    let totalDonation = 0.0
    if (affectedProperty.otherAssistanceList.length > 0) {
      let i = 0
      while (i < affectedProperty.otherAssistanceList.length) {
        totalDonation =
          totalDonation + affectedProperty.otherAssistanceList[i].amountReceived
        i++
      }
    }
    const compensationDataModel = CompensationValueDtoModel.create({
      balanceLoanAmount: 0,
      damagePercentage:
        affectedProperty.damageType === 'Full'
          ? 100.0
          : affectedProperty.damage
          ? affectedProperty.damage
          : 0,
      recommendedAmount: oRRecommendedAmount ? oRRecommendedAmount : 0,
      scheme: fileRegistrationObject.scheme,
      incidentTypeId: fileRegistrationObject.incidentTypeId,
      typeOfInjuryId: 0,
      receivedTotalDonations: totalDonation,
      otherAssistanceAmount: 0,
    })
    dispatch(changeLoadingRequest())
    const result = await metaDataService.apiGetCompensationValue(
      compensationDataModel
    )
    if (result.status === 200) {
      const newAffectedDetails = produce(affectedProperty, (draft) => {
        draft.compensationAmount = result.data
      })
      setAffectedProperty(newAffectedDetails)
      const clearError = produce(errors, (draft) => {
        draft.compensationAmountError = ''
      })
      setErrors(clearError)
    }
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Compensation calculated Successfully')
  }

  const renderPropertyTypes = () => {
    return propertyTypeList.map(
      (propertyType: PropertyDamageTypeModel, index: number) => {
        return (
          <PropertyArea
            key={`property-${index}`}
            property={propertyType}
            properties={affectedProperty.propertyDamageList.filter(
              (detail: any) => {
                return detail.propertyId === propertyType.id
              }
            )}
            onAdd={(propertyObject: any) => {
              const newAffectedDetails = produce(affectedProperty, (draft) => {
                draft.propertyDamageList.push(propertyObject)
                draft.compensationAmount = ''
              })
              setAffectedProperty(newAffectedDetails)
            }}
            onRemove={(object: any) => {
              const remain = affectedProperty.propertyDamageList.filter(
                (detail: any) => {
                  return detail.id !== object.id
                }
              )
              const newAffectedDetails = produce(affectedProperty, (draft) => {
                draft.propertyDamageList = remain
                draft.compensationAmount = ''
              })
              setAffectedProperty(newAffectedDetails)
            }}
          />
        )
      }
    )
  }

  const checkMergeDisabled = (installment: InstallmentModel, index: number) => {
    if (installment.merge) {
      return true
    } else {
      // check not last element
      const lastInstallment = installmentList[installmentList.length - 1]
      if (lastInstallment.installmentNumber != installment.installmentNumber) {
        // check any next installment checked
        for (let i = index + 1; i < installmentList.length; i++) {
          if (installmentList[i].isChecked) {
            return true
            break
          }
        }
      }
    }
    return false
  }

  const checkPreviousHasMergeSame = (index: number, mergeNumber: number) => {
    if (index == 0 || mergeNumber == 0) {
      return false
    }
    const previousInstallment = installmentList[index - 1]
    if (previousInstallment.mergeNumber === mergeNumber) {
      return true
    }

    return false
  }

  const handleUnMerge = async (mergeNumber: number) => {
    const scheme = fileRegistrationObject.scheme.toLocaleLowerCase()
    dispatch(changeLoadingRequest())
    const results = await commonService.apiUnMergeInstallment(
      mergeNumber,
      fileRegistrationObject.id,
      scheme
    )

    const installmentData =
      await metaDataService.getApplicationInstallmentAmountList(
        fileRegistrationObject.id
      )

    if (installmentData.status === 200) {
      const sortedIntallment = installmentData.data.sort(
        (
          a: { installmentNumber: number },
          b: { installmentNumber: number }
        ) => {
          return a.installmentNumber - b.installmentNumber
        }
      )
      setInstallmentList(sortedIntallment)
    }
    dispatch(changeLoadingRequest())
    handleNotification(results, 'Installments unmerged successfully')
  }

  const renderMergedInstallmentByMegeNumber = (mergeNumber: number) => {
    const mergedInstallment = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.mergeNumber === mergeNumber) {
          return installment
        }
      }
    )

    return mergedInstallment.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div
            className={`${index != mergedInstallment.length - 1 ? 'mb-3' : ''}`}
            key={`installment-merge-${index}`}
          >
            {`Installment ${
              installment.installmentNumber
            } Rs. ${thousandSeperator(installment.installmentAmount)}`}
          </div>
        )
      }
    )
  }

  const renderInstallmentList = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div key={`installment-${installment.id}`}>
            {!checkPreviousHasMergeSame(index, installment.mergeNumber) && (
              <ul key={`installment-ul-${installment.id}`}>
                {installment.mergeNumber == 0 && (
                  <Form.Check
                    key={`installment-check-${installment.id}`}
                    label={`Installment ${
                      installment.installmentNumber
                    } Rs. ${thousandSeperator(installment.installmentAmount)}`}
                    name={`installment`}
                    className="custom-form-check"
                    onChange={(e) => {
                      if (e.target.checked) {
                        checkIsMergeAvailable(installment)
                      } else {
                        const newInstallmentList = produce(
                          installmentList,
                          (draft: InstallmentModel[]) => {
                            const indexNumber = draft.findIndex(
                              (x) => x.id === installment.id
                            )
                            if (indexNumber > -1) {
                              draft[indexNumber].isChecked = false
                            }
                          }
                        )
                        setInstallmentList(newInstallmentList)
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    disabled={checkMergeDisabled(installment, index)}
                    checked={installment.merge || installment.isChecked}
                  />
                )}
                {installment.mergeNumber > 0 && (
                  <div className="row mb-0">
                    <div className="col max-width-content">
                      <Form.Check
                        className="custom-form-check"
                        disabled={true}
                        checked={true}
                        label={renderMergedInstallmentByMegeNumber(
                          installment.mergeNumber
                        )}
                      />
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className={`save-button`}
                        onClick={() => {
                          handleUnMerge(installment.mergeNumber)
                        }}
                      >
                        Un Merge
                      </button>
                    </div>
                  </div>
                )}
              </ul>
            )}
          </div>
        )
      }
    )
  }

  const checkIsMergeAvailable = (checkInstallment: InstallmentModel) => {
    let addStatus = true
    const previousInstallments = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.isChecked) {
          return installment
        }
      }
    )

    if (previousInstallments.length > 0) {
      const indexId = installmentList.findIndex(
        (x) => x.id === checkInstallment.id
      )
      const previousInstallment = installmentList[indexId - 1]
      if (!previousInstallment.isChecked) {
        addStatus = false
      }
    }
    if (addStatus) {
      const newInstallmentList = produce(
        installmentList,
        (draft: InstallmentModel[]) => {
          const indexNumber = draft.findIndex(
            (x) => x.id === checkInstallment.id
          )
          if (indexNumber > -1) {
            draft[indexNumber].isChecked = true
          }
        }
      )
      setInstallmentList(newInstallmentList)
    }
    // const previousInstallments = installmentList.filter(
    //   (installment: InstallmentModel) => {
    //     if (
    //       installment.installmentNumber < checkInstallment.installmentNumber &&
    //       (installment.merge || !installment.isChecked)
    //     ) {
    //       return installment
    //     }
    //   }
    // )

    // if (previousInstallments.length == 0) {
    //   const newInstallmentList = produce(
    //     installmentList,
    //     (draft: InstallmentModel[]) => {
    //       const indexNumber = draft.findIndex(
    //         (x) => x.id === checkInstallment.id
    //       )
    //       if (indexNumber > -1) {
    //         draft[indexNumber].isChecked = true
    //       }
    //     }
    //   )
    //   setInstallmentList(newInstallmentList)
    // }
  }

  const handleMerge = async () => {
    const mergeList = [] as number[]
    installmentList.forEach((installment: InstallmentModel) => {
      if (installment.isChecked) {
        mergeList.push(installment.installmentNumber)
      }
    })
    const scheme = fileRegistrationObject.scheme.toLocaleLowerCase()
    dispatch(changeLoadingRequest())
    const results = await commonService.apiMergeInstallment(
      mergeList,
      fileRegistrationObject.id,
      scheme
    )

    const installmentData =
      await metaDataService.getApplicationInstallmentAmountList(
        fileRegistrationObject.id
      )

    if (installmentData.status === 200) {
      const sortedIntallment = installmentData.data.sort(
        (
          a: { installmentNumber: number },
          b: { installmentNumber: number }
        ) => {
          return a.installmentNumber - b.installmentNumber
        }
      )
      setInstallmentList(sortedIntallment)
    }
    dispatch(changeLoadingRequest())
    handleNotification(results, 'Installments merged successfully')
  }

  useEffect(() => {
    if (
      fileRegistrationObject.scheme == 'GPC' ||
      fileRegistrationObject.scheme == 'GSC'
    ) {
      if (affectedProperty.propertyDamageList) {
        calORRecommendedAmount()
      }
    }
  }, [affectedProperty.propertyDamageList])

  const calORRecommendedAmount = () => {
    const dataArray = [] as any[]
    for (let i = 0; i < affectedProperty.propertyDamageList.length; i++) {
      if (affectedProperty.propertyDamageList[i]['Value Approved by the OR']) {
        const dataObj = {} as any
        dataObj.id = affectedProperty.propertyDamageList[i].propertyId
        const valueWithComma =
          affectedProperty.propertyDamageList[i]['Value Approved by the OR']
        const valueWithoutCommas = valueWithComma.replace(/,/g, '')
        dataObj.value = parseFloat(valueWithoutCommas)
        debugger
        dataArray.push(dataObj)
      }
    }

    const sum = dataArray.reduce((accumulator, object) => {
      return accumulator + object.value
    }, 0)
    setORRecommendedAmount(sum)

    const clearError = produce(errors, (draft) => {
      draft.oRRecommendedAmountError = ''
    })
    setErrors(clearError)
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              Affected Property Information
            </div>
            <Form id="affectedPropertyDetails">
              {fileRegistrationObject.scheme === 'RLP' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formWorshipName">
                      <Form.Label>
                        Name of the Worship Place{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Name of the Worship Place"
                        isInvalid={!!errors.nameOfTheWorshipPlaceError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        value={affectedProperty.nameOfTheWorshipPlace}
                        onChange={(e) => {
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.nameOfTheWorshipPlace = e.target.value
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                          const clearError = produce(errors, (draft) => {
                            draft.nameOfTheWorshipPlaceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback
                        message={errors.nameOfTheWorshipPlaceError}
                      />
                    </Form.Group>
                  </Row>
                  <Col sm={12} className="border-label mb-3">
                    <div className="border-label-span">Details of location</div>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formProvince">
                        <Form.Label>
                          Province{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          value={
                            provinceList.filter((province: ProvinceOption) => {
                              return (
                                province.value === affectedProperty.provinceId
                              )
                            })[0]
                          }
                          isDisabled={true}
                          options={provinceList}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDistrict">
                        <Form.Label>
                          District{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          value={
                            districtList.filter((district: DistrictOption) => {
                              return (
                                district.value === affectedProperty.districtId
                              )
                            })[0]
                          }
                          isDisabled={true}
                          options={districtList}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDs">
                        <Form.Label>
                          {`DS Division`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          value={
                            dsList.filter((ds: DsOption) => {
                              return ds.value === affectedProperty.dsDivisionId
                            })[0]
                          }
                          isDisabled={true}
                          options={dsList}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGn">
                        <Form.Label>
                          {`GN Division`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          value={
                            gnList.filter((gn: GnOption) => {
                              return gn.value === affectedProperty.gnDivisionId
                            })[0]
                          }
                          isDisabled={true}
                          options={gnList}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formVillage">
                        <Form.Label>
                          Village / Town{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter Village / Town"
                          isInvalid={!!errors.villageOrTownError}
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          value={affectedProperty.villageOrTown}
                          onChange={(e) => {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.villageOrTown = e.target.value
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.villageOrTownError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback message={errors.villageOrTownError} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formPoliceDn">
                        <Form.Label>
                          Police Station{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={
                            !!errors.detailsOfLocationPoliceStationError
                          }
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          className="form-select"
                          as="select"
                          value={
                            affectedProperty.detailsOfLocationPoliceStationId
                          }
                          onBlur={() => {
                            if (affectedProperty.policeStation) {
                              const newErrors = produce(errors, (draft) => {
                                draft.detailsOfLocationPoliceStationError =
                                  validateForm({
                                    type: number,
                                    value:
                                      affectedProperty.detailsOfLocationPoliceStationId,
                                    section: 'Police Station',
                                  } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }
                          }}
                          onChange={(e) => {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.detailsOfLocationPoliceStationId =
                                  parseInt(e.target.value)
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.detailsOfLocationPoliceStationError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          <option value="">--Select Police Station--</option>
                          {policeStationList.map(
                            (
                              damage: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option key={`bank-${index}`} value={damage.id}>
                                  {damage.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback
                          message={errors.detailsOfLocationPoliceStationError}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formRegisterNumber">
                        <Form.Label>
                          Registration Number{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter Registration Number"
                          isInvalid={!!errors.registrationNumberError}
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          value={affectedProperty.registrationNumber}
                          onChange={(e) => {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.registrationNumber = e.target.value
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.registrationNumberError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.registrationNumberError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formRegisterdIns">
                        <Form.Label>
                          Registered Institution{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter Registered Institution"
                          isInvalid={!!errors.registeredInstitutionError}
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          value={affectedProperty.registeredInstitution}
                          onChange={(e) => {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.registeredInstitution = e.target.value
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.registeredInstitutionError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.registeredInstitutionError}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </>
              )}
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">Incident Details</div>
                <Form.Group
                  className="mb-3"
                  controlId="addressOfAffectedProperty"
                >
                  <Form.Label>
                    Address of Affected Property{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Address of Affected Property"
                    isInvalid={!!errors.addressOfThePropertyError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    value={affectedProperty.addressOfTheProperty}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.addressOfThePropertyError = validateForm({
                          type: text,
                          maxLength: 255,
                          value: affectedProperty.addressOfTheProperty,
                          section: 'Address of the property',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.addressOfTheProperty = e.target.value
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.addressOfThePropertyError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.addressOfThePropertyError} />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="placeOfTheIncidentOccurred"
                >
                  <Form.Label>
                    Place of the incident occurred{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Place of the incident occurred"
                    isInvalid={!!errors.placeOfTheIncidentOccurredError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    value={affectedProperty.placeOfTheIncidentOccurred}
                    onBlur={() => {
                      const newErrors = produce(errors, (draft) => {
                        draft.placeOfTheIncidentOccurredError = validateForm({
                          type: text,
                          maxLength: 100,
                          value: affectedProperty.placeOfTheIncidentOccurred,
                          section: 'Place where the incident has occurred',
                        } as ValidationObjectModel)
                      })
                      setErrors(newErrors)
                    }}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.placeOfTheIncidentOccurred = e.target.value
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.placeOfTheIncidentOccurredError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback
                    message={errors.placeOfTheIncidentOccurredError}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formIncidentDate">
                  <Form.Label>
                    Incident date{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <DatePicker
                    maxDate={new Date()}
                    disabled={true}
                    className={
                      errors.incidentDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="Incident date"
                    selected={
                      fileRegistrationObject.incidentDate != null
                        ? new Date(fileRegistrationObject.incidentDate * 1000)
                        : null
                    }
                  />
                  <InvalidFeedback message={errors.incidentDateError} />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="damageType">
                    <Form.Label>
                      Damage Type{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.damageTypeError}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        fileRegistrationObject.scheme === 'GPC' ||
                        fileRegistrationObject.scheme === 'GSC'
                      }
                      className="form-select"
                      as="select"
                      value={affectedProperty.damageType}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.damageTypeError = validateForm({
                            type: text,
                            value: affectedProperty.damageType,
                            section: 'Type of the damage happened',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newAffectedDetails = produce(
                          affectedProperty,
                          (draft) => {
                            draft.damageType = e.target.value
                            draft.damage = 0
                          }
                        )
                        setAffectedProperty(newAffectedDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.damageTypeError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      <option value="">--Select Damage Type--</option>
                      {damageTypeList.map(
                        (
                          damage: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`bank-${index}`} value={damage.name}>
                              {damage.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.damageTypeError} />
                  </Form.Group>
                  {affectedProperty.damageType != 'Full' && (
                    <Form.Group as={Col} controlId="damagePercentage">
                      <Form.Label>
                        Damage %{' '}
                        {affectedProperty.damageType === 'Partial' ? (
                          <span className="required-field-astrix">*</span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Damage %"
                        isInvalid={!!errors.damageError}
                        disabled={
                          !(affectedProperty.damageType === 'Partial') ||
                          fileRegistrationObject.modifyIndex > 0
                        }
                        value={affectedProperty.damage}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.damageError = validateForm({
                              type: number,
                              maxLength: 2,
                              value: affectedProperty.damage,
                              section: 'Percentage value of the damage',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.damage = parseFloat(e.target.value)
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                          const clearError = produce(errors, (draft) => {
                            draft.damageError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback message={errors.damageError} />
                    </Form.Group>
                  )}
                </Row>
                {fileRegistrationObject.scheme === 'RLP' && (
                  <Form.Group className="mb-3" controlId="resoanForDamange">
                    <Form.Label>Reasons for Damage</Form.Label>
                    <Form.Control
                      placeholder="Enter Reasons for Damage"
                      isInvalid={!!errors.reasonsForDamageError}
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      value={affectedProperty.reasonsForDamage}
                      onBlur={() => {
                        if (affectedProperty.reasonsForDamage) {
                          const newErrors = produce(errors, (draft) => {
                            draft.reasonsForDamageError = validateForm({
                              type: text,
                              maxLength: 100,
                              value: affectedProperty.reasonsForDamage,
                              section: 'Reasons for Damage',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newAffectedDetails = produce(
                          affectedProperty,
                          (draft) => {
                            draft.reasonsForDamage = e.target.value
                          }
                        )
                        setAffectedProperty(newAffectedDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.reasonsForDamageError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.reasonsForDamageError} />
                  </Form.Group>
                )}
                {fileRegistrationObject.scheme === 'RLP' && (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="assesmentOfDamage">
                      <Form.Label>
                        Assessment of the damage{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <PriceInputComponent
                        isInvalid={!!errors.assessmentOfTheDamageError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        value={affectedProperty.assessmentOfTheDamage}
                        placeholder="Enter Assessment of the damage"
                        onChange={(price: string) => {
                          const newErrors = produce(errors, (draft) => {
                            draft.assessmentOfTheDamageError = validateForm({
                              type: number,
                              maxLength: 9,
                              value: parseFloat(price),
                              section: 'Assessment of the damage',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.assessmentOfTheDamage = parseFloat(price)
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.assessmentOfTheDamage = 0
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                          } else {
                            const clearError = produce(errors, (draft) => {
                              draft.assessmentOfTheDamageError = ''
                            })
                            setErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback
                        message={errors.assessmentOfTheDamageError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="presentSituation">
                      <Form.Label>
                        Present Situation of the Damaged place{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Present Situation of the Damaged place"
                        isInvalid={
                          !!errors.presentConditionOfTheDamagedPlaceError
                        }
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        value={
                          affectedProperty.presentConditionOfTheDamagedPlace
                        }
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.presentConditionOfTheDamagedPlaceError =
                              validateForm({
                                type: text,
                                maxLength: 200,
                                value:
                                  affectedProperty.presentConditionOfTheDamagedPlace,
                                section:
                                  'Present condition of the damaged place',
                              } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.presentConditionOfTheDamagedPlace =
                                e.target.value
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                          const clearError = produce(errors, (draft) => {
                            draft.presentConditionOfTheDamagedPlaceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <InvalidFeedback
                        message={errors.presentConditionOfTheDamagedPlaceError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="amountSpentForRepair">
                      <Form.Label>Amount spent for repairs (Rs.)</Form.Label>
                      <PriceInputComponent
                        isInvalid={!!errors.amountSpentForRepairsError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        value={affectedProperty.amountSpentForRepairs}
                        placeholder="Enter Amount spent for repairs (Rs.)"
                        onChange={(price: string) => {
                          const newErrors = produce(errors, (draft) => {
                            draft.amountSpentForRepairsError = validateForm({
                              type: text,
                              maxLength: 9,
                              value: parseFloat(price),
                              section: 'Amount spent for repairs',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)

                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.amountSpentForRepairs = parseFloat(price)
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.amountSpentForRepairs = 0
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                          } else {
                            const clearError = produce(errors, (draft) => {
                              draft.amountSpentForRepairsError = ''
                            })
                            setErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback
                        message={errors.amountSpentForRepairsError}
                      />
                    </Form.Group>
                  </Row>
                )}
                {fileRegistrationObject.scheme != 'RLP' && (
                  <Row>
                    <Form.Group as={Col} controlId="ownerType">
                      <Form.Label>
                        Ownership of the property{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.ownershipOfThePropertyError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        className="form-select"
                        as="select"
                        value={affectedProperty.ownershipOfTheProperty}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.ownershipOfThePropertyError = validateForm({
                              type: text,
                              value: affectedProperty.ownershipOfTheProperty,
                              section: 'Ownership of the property',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.ownershipOfTheProperty = e.target.value
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                          const clearError = produce(errors, (draft) => {
                            draft.ownershipOfThePropertyError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {affectedProperty.ownershipOfTheProperty == undefined ||
                        affectedProperty.ownershipOfTheProperty == '' ? (
                          <option value="">--Select Ownership Type--</option>
                        ) : (
                          ''
                        )}
                        {ownershipList.map(
                          (
                            damage: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`bank-${index}`} value={damage.name}>
                                {damage.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback
                        message={errors.ownershipOfThePropertyError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="recommendedAmount">
                      <Form.Label>
                        DS Recommended Amount (Rs.){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <PriceInputComponent
                        isInvalid={!!errors.recommendedAmountError}
                        disabled={fileRegistrationObject.modifyIndex > 0}
                        value={affectedProperty.recommendedAmount}
                        placeholder="Enter DS Recommended Amount (Rs.)"
                        onChange={(price: string) => {
                          const newErrors = produce(errors, (draft) => {
                            draft.recommendedAmountError = validateForm({
                              type: number,
                              maxLength: 9,
                              minValue: 1,
                              value: parseFloat(price),
                              section: 'Recommended Amount',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                          const newAffectedDetails = produce(
                            affectedProperty,
                            (draft) => {
                              draft.recommendedAmount = parseFloat(price)
                            }
                          )
                          setAffectedProperty(newAffectedDetails)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newAffectedDetails = produce(
                              affectedProperty,
                              (draft) => {
                                draft.recommendedAmount = 0
                              }
                            )
                            setAffectedProperty(newAffectedDetails)
                          } else {
                            const clearError = produce(errors, (draft) => {
                              draft.recommendedAmountError = ''
                            })
                            setErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback
                        message={errors.recommendedAmountError}
                      />
                    </Form.Group>
                  </Row>
                )}
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">
                  Details of Police Complaint
                </div>
                <Form.Group className="mb-3" controlId="policeStation">
                  <Form.Label>Police Station</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.policeStationError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    className="form-select"
                    as="select"
                    value={affectedProperty.policeStation}
                    onBlur={() => {
                      if (affectedProperty.policeStation) {
                        const newErrors = produce(errors, (draft) => {
                          draft.policeStationError = validateForm({
                            type: text,
                            maxLength: 30,
                            value: affectedProperty.policeStation,
                            section: 'Police Station',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }
                    }}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.policeStation = e.target.value
                          if (!e.target.value) {
                            draft.complaintDate = null
                            draft.complaintNumber = ''
                          }
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        if (e.target.value == '') {
                          draft.complaintDateError = ''
                          draft.complaintNumberError = ''
                        } else {
                          draft.policeStationError = ''
                        }
                      })
                      setErrors(clearError)
                    }}
                  >
                    <option value="">--Select Police Station--</option>
                    {policeStationList.map(
                      (damage: { name: string; id: number }, index: number) => {
                        return (
                          <option key={`bank-${index}`} value={damage.name}>
                            {damage.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <InvalidFeedback message={errors.policeStationError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formComplaintDate">
                  <Form.Label>
                    Complaint date{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <DatePicker
                    maxDate={new Date()}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    className={
                      errors.complaintDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="Complaint date"
                    selected={
                      affectedProperty.complaintDate != null
                        ? new Date(affectedProperty.complaintDate * 1000)
                        : null
                    }
                    onBlur={() => {
                      if (affectedProperty.policeStation) {
                        const newErrors = produce(errors, (draft) => {
                          draft.complaintDateError = validateForm({
                            type: 'date',
                            value: affectedProperty.complaintDate,
                            section: 'Complaint date',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }
                    }}
                    onChange={(date: Date) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.complaintDate =
                            date == null ? null : date.getTime() / 1000
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.complaintDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.complaintDateError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="complaintNumber">
                  <Form.Label>Complain Number </Form.Label>
                  <Form.Control
                    placeholder="Enter Complain Number"
                    isInvalid={!!errors.complaintNumberError}
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    value={affectedProperty.complaintNumber}
                    onBlur={() => {
                      if (affectedProperty.policeStation) {
                        const newErrors = produce(errors, (draft) => {
                          draft.complaintNumberError = validateForm(
                            {
                              type: text,
                              maxLength: 10,
                              value: affectedProperty.complaintNumber,
                              section: 'Complain Number',
                            } as ValidationObjectModel,
                            false
                          )
                        })
                        setErrors(newErrors)
                      }
                    }}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.complaintNumber = e.target.value
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.complaintNumberError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.complaintNumberError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="reasonNotMade">
                  <Form.Label>
                    Reasons for not made the complaint promptly after the
                    incident
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Reasons for not made the complaint promptly after the
                    incident"
                    isInvalid={!!errors.reasonsError}
                    disabled={
                      fileRegistrationObject.modifyIndex > 0 ||
                      affectedProperty.policeStation
                        ? true
                        : false
                    }
                    value={affectedProperty.reasons}
                    onBlur={() => {
                      if (affectedProperty.reasons) {
                        const newErrors = produce(errors, (draft) => {
                          draft.reasonsError = validateForm({
                            type: text,
                            maxLength: 200,
                            value: affectedProperty.reasons,
                            section:
                              'Reasons for not made the complaint promptly after the incident',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }
                    }}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.reasons = e.target.value
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.reasonsError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.reasonsError} />
                </Form.Group>
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">
                  Value of The Damage Caused
                </div>
                {renderPropertyTypes()}
              </Col>
              {(fileRegistrationObject.scheme == 'GPC' ||
                fileRegistrationObject.scheme == 'GSC') && (
                <Form.Group
                  className="mt-4 mb-4"
                  controlId="oRRecommendedAmount"
                >
                  <Form.Label>
                    Value Approved by the OR (Rs.){' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.oRRecommendedAmountError}
                    disabled
                    placeholder="Value Approved by the OR (Rs.)"
                    value={
                      oRRecommendedAmount && currencyMask(oRRecommendedAmount)
                    }
                  />
                  <InvalidFeedback message={errors.oRRecommendedAmountError} />
                </Form.Group>
              )}

              <Col sm={12} className="border-label mt-4 pb-3">
                <div className="border-label-span">
                  Other Assistance Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="nameOfInstitute">
                    <Form.Label>Name of the institution</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.institutionError}
                      placeholder="Enter Name of the institution"
                      disabled={fileRegistrationObject.modifyIndex > 0}
                      value={otherAssistanceDetail.institution}
                      onChange={(e) => {
                        const newOtherAssDetails = produce(
                          otherAssistanceDetail,
                          (draft) => {
                            draft.institution = e.target.value
                          }
                        )
                        setOtherAssistanceDetails(newOtherAssDetails)
                        const clearError = produce(errors, (draft) => {
                          if (e.target.value == '') {
                            draft.amountReceivedError = ''
                            draft.receivedDateError = ''
                          } else {
                            draft.institutionError = ''
                          }
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.institutionError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="amountRecieved">
                    <Form.Label>
                      Amount Recieved (Rs.){' '}
                      {otherAssistanceDetail.institution ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.amountReceivedError}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        !otherAssistanceDetail.institution
                          ? true
                          : false
                      }
                      value={otherAssistanceDetail.amountReceived}
                      placeholder="Enter Amount recieved (Rs.)"
                      onChange={(price: string) => {
                        if (otherAssistanceDetail.institution) {
                          const newErrors = produce(errors, (draft) => {
                            draft.amountReceivedError = validateForm({
                              type: number,
                              maxLength: 9,
                              minValue: 1,
                              value: parseFloat(price),
                              section: 'Amount recieved',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                        const newOtherAssDetails = produce(
                          otherAssistanceDetail,
                          (draft) => {
                            draft.amountReceived = parseFloat(price)
                          }
                        )
                        setOtherAssistanceDetails(newOtherAssDetails)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newOtherAssDetails = produce(
                            otherAssistanceDetail,
                            (draft) => {
                              draft.amountReceived = 0
                            }
                          )
                          setOtherAssistanceDetails(newOtherAssDetails)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.amountReceivedError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback message={errors.amountReceivedError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="recievedDate">
                    <Form.Label>
                      Recieved date{' '}
                      {otherAssistanceDetail.institution ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <DatePicker
                      maxDate={new Date()}
                      disabled={
                        fileRegistrationObject.modifyIndex > 0 ||
                        !otherAssistanceDetail.institution
                          ? true
                          : false
                      }
                      className={
                        errors.receivedDateError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      placeholderText="Recieved date"
                      selected={
                        otherAssistanceDetail.receivedDate != null
                          ? new Date(otherAssistanceDetail.receivedDate * 1000)
                          : null
                      }
                      onBlur={() => {
                        if (otherAssistanceDetail.institution) {
                          const newErrors = produce(errors, (draft) => {
                            draft.receivedDateError = validateForm({
                              type: number,

                              value: otherAssistanceDetail.receivedDate,
                              section: 'Recieved date',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(date: Date) => {
                        const newOtherAssDetails = produce(
                          otherAssistanceDetail,
                          (draft) => {
                            draft.receivedDate = date
                              ? date.getTime() / 1000
                              : null
                          }
                        )
                        setOtherAssistanceDetails(newOtherAssDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.receivedDateError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.receivedDateError} />
                  </Form.Group>
                </Row>
                {!(fileRegistrationObject.modifyIndex > 0) && (
                  <Row className="mb-3 justify-content-end">
                    <button
                      disabled={checkAddOtherDetails()}
                      type="button"
                      className={`${
                        checkAddOtherDetails()
                          ? 'disable-button'
                          : 'save-button'
                      }`}
                      onClick={handleAddOtherAssistance}
                    >
                      Add
                    </button>
                  </Row>
                )}

                {affectedProperty.otherAssistanceList.length > 0 && (
                  <OtherAssistanceDetailsTable
                    otherAssistanceList={affectedProperty.otherAssistanceList}
                    onRemove={(index: number) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.otherAssistanceList.splice(index, 1)
                          draft.compensationAmount = ''
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                    }}
                  />
                )}
              </Col>
              {fileRegistrationObject.scheme === 'GPC' && (
                <Form.Group as={Col} controlId="gpcRemark">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.gpcRemarkError}
                    placeholder="Enter Remark"
                    disabled={fileRegistrationObject.modifyIndex > 0}
                    value={otherAssistanceDetail.institution}
                    type="text"
                    maxLength={100}
                    onChange={(e) => {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.remark = e.target.value
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                      const clearError = produce(errors, (draft) => {
                        if (e.target.value == '') {
                          draft.gpcRemarkError = ''
                        }
                      })
                      setErrors(clearError)
                    }}
                  />
                  <InvalidFeedback message={errors.gpcRemarkError} />
                </Form.Group>
              )}
              <Form.Group className="mt-4 mb-3" controlId="compensastionValue">
                <Form.Label>
                  Compensation Amount (Rs.){' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.compensationAmountError}
                  disabled
                  placeholder="Compensation Amount (Rs.)"
                  value={
                    affectedProperty.compensationAmount &&
                    currencyMask(affectedProperty.compensationAmount)
                  }
                />
                {/* <PriceInputComponent
                  isInvalid={!!errors.compensationAmountError}
                  disabled={true}
                  value={affectedProperty.compensationAmount}
                  placeholder="Enter Compensation Amount (Rs.)"
                  onChange={(price: string) => {
                    const newAffectedDetails = produce(
                      affectedProperty,
                      (draft) => {
                        draft.compensationAmount = parseFloat(price)
                      }
                    )
                    setAffectedProperty(newAffectedDetails)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newAffectedDetails = produce(
                        affectedProperty,
                        (draft) => {
                          draft.compensationAmount = 0
                        }
                      )
                      setAffectedProperty(newAffectedDetails)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.compensationAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                /> */}
                <InvalidFeedback message={errors.compensationAmountError} />
                {checkApplicationRegistrationButtonEnable(
                  fileRegistrationObject.status,
                  fileRegistrationObject.modifyIndex
                ) && (
                  <button
                    disabled={
                      fileRegistrationObject.scheme != 'RLP' &&
                      (oRRecommendedAmount == 0 || oRRecommendedAmount == null)
                    }
                    type="button"
                    className={`${
                      fileRegistrationObject.scheme != 'RLP' &&
                      (oRRecommendedAmount == 0 || oRRecommendedAmount == null)
                        ? 'disable-button'
                        : 'save-button'
                    } custom-margin-right mt-3`}
                    onClick={handleCalculation}
                  >
                    Calculate
                  </button>
                )}
              </Form.Group>
              {installmentList.length > 0 && (
                <Col sm={12} className="border-label mt-4 pb-3">
                  <div className="border-label-span">Installments</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="installmentList">
                      <Row>{renderInstallmentList()}</Row>
                      {checkInstallmentMergeEnable(
                        installmentList.length,
                        fileRegistrationObject.status,
                        fileRegistrationObject.modifyIndex
                      ) && (
                        <button
                          type="button"
                          className="save-button custom-margin-right"
                          onClick={handleMerge}
                        >
                          Merge
                        </button>
                      )}
                    </Form.Group>
                  </Row>
                </Col>
              )}
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={checkSectionThreeNextButtonDisable(
                      fileRegistrationObject.paymentType,
                      installmentList.length
                    )}
                    className={`${
                      checkSectionThreeNextButtonDisable(
                        fileRegistrationObject.paymentType,
                        installmentList.length
                      )
                        ? 'disable-button'
                        : 'save-button'
                    } custom-margin-right`}
                    onClick={() => {
                      if (fileRegistrationObject.modifyIndex == 0) {
                        onClickNext()
                      } else {
                        onClickNextSection()
                      }
                    }}
                  >
                    Next
                  </button>
                  {fileRegistrationObject.modifyIndex == 0 && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  )}
                </>
              )}
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default GpcSectionThree
