import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import TileButtonComponent from '../../components/common/tileButton/TileButtonComponent'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { getUserSubPackages } from '../../store/user/selectors'
import { fileRegistrationRoute } from '../../utils/constants'
import FileRegistration from './fileRegistration/FileRegistration'
import application_registration from '../../assets/images/application_registration.svg'
import Compensation from './compensation/Compensation'
import Loan from './loan/Loan'
const ApplicationRegistration = () => {
  const { path } = useRouteMatch()
  const subPackageList = useSelector(
    getUserSubPackages('application-registration')
  )

  const renderSubPackages = () => {
    return subPackageList.map((subPackage: SubPackageModal, index: number) => {
      return (
        <Col
          key={`sub-package-${index}`}
          xs={12}
          sm={12}
          lg={4}
          className="mb-4"
        >
          <TileButtonComponent
            name={subPackage.description}
            routeURL={subPackage.name}
          />
        </Col>
      )
    })
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={application_registration} alt="" />
            <span className="m-2">Application Registration</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">{renderSubPackages()}</Row>
      </Route>
      <Route
        path={`${path}/${fileRegistrationRoute}`}
        component={FileRegistration}
      />
      <Route path={`${path}/compensation`} component={Compensation} />
      <Route path={`${path}/loan`} component={Loan} />
    </Switch>
  )
}

export default ApplicationRegistration
