import React, { FC } from 'react'
export interface InvalidFeedbackProps {
  message?: string
}

const InvalidFeedback: FC<InvalidFeedbackProps> = ({ message }) => {
  return <div className="feedback-error-span-custom mb-1">{message}</div>
}

export default InvalidFeedback
