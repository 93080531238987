import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { officeSubTitle, officeTitle } from '../../utils/labels'
import emlem from '../../assets/images/emblem.svg'
import './LeftNavigation.scss'
import NavItem from '../common/navItem/NavItem'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { getUserRoutes } from '../../store/user/selectors'

const LeftNavigation = () => {
  const location = useLocation()
  const loggedUserRoutes = useSelector(getUserRoutes)
  const renderPagesList = () => {
    return loggedUserRoutes.map((page: any, index: number) => {
      return (
        <NavItem
          key={index}
          id={index}
          name={page.name}
          routeURL={page.routeURL}
          isSelected={
            location.pathname.match(new RegExp(`${page.routeURL}`, 'gi')) ===
            null
              ? false
              : true
          }
        />
      )
    })
  }
  return (
    <>
      <Row className="navigation-panel">
        <Col sm={12} className="emlem-logo">
          <img src={emlem} alt="" />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="heading-title">{officeTitle}</div>
          <div className="sub-heading-title">{officeSubTitle}</div>
        </Col>
      </Row>
      <Row className="mt-4">{renderPagesList()}</Row>
    </>
  )
}

export default LeftNavigation
