import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { propertyFilterStatus, deathSchemesList } from '../../utils/metaData'
import { useSelector } from 'react-redux'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
} from '../../store/administrativeArea/selectors'
import { DistrictOption } from '../../models/selectOptionModels/districtOption'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DsOption } from '../../models/selectOptionModels/dsOption'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { showTotast } from '../../utils/toast'
import MetaDataService from '../../services/metaDataService'
import { CommonTypeOption } from '../../models/selectOptionModels/commonOptionType'

export interface FilterProps {
  filterData: FilterOptionModel[]
  onFilter: any
}

const DeathFileRecommendationListFiletrs: FC<FilterProps> = ({
  filterData,
  onFilter,
}) => {
  const [filters, setFilters] = useState(filterData)
  const [show, setShow] = useState(false)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dsList = useSelector(getDsOptions)
  const [IncidentTypeList, setIncidentTypeList] = useState(
    [] as CommonTypeOption[]
  )

  const getMedaData = async () => {
    const incidentTypeData = await MetaDataService.apiIncidentTypeList()
    if (incidentTypeData.status === 200) {
      setIncidentTypeList(
        setSelectOptionsForIncidentType(incidentTypeData.data)
      )
    }
  }
  const setSelectOptionsForIncidentType = (dataList: any) => {
    const OptionsList: CommonTypeOption[] = []
    for (let i = 0; i < dataList.length; i++) {
      const OptionsObject = {} as CommonTypeOption
      OptionsObject.value = dataList[i].id
      OptionsObject.label = dataList[i].incidentType
      OptionsList.push(OptionsObject)
    }
    return OptionsList
  }
  useEffect(() => {
    setFilters(filterData)
    getMedaData()
  }, [show])

  const validate = () => {
    let isValid = false
    const selectedFilters = filters.filter((data) => {
      return data.selected === true
    })

    selectedFilters.map((filterData) => {
      if (filterData.value == null) {
        isValid = false
      } else {
        isValid = true
      }
    })
    return isValid
  }

  const onSubmit = () => {
    if (!validate()) {
      showTotast(
        ALERT_WARNING,
        'Validation error found. Please complete all selected fields and try again.',
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
    } else {
      onFilter(filters)
      setShow(false)
    }
  }

  return (
    <>
      <div style={{ width: '70%' }}>
        <div className="p-4">
          {filters.length > 0 && (
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="from">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    disabled
                    type="checkbox"
                    checked={filters[0].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[0].label}
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    disabled={!filters[0].selected}
                    className={'form-control'}
                    selected={
                      filters[0].value != null
                        ? new Date(filters[0].value * 1000)
                        : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="to">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    disabled
                    type="checkbox"
                    checked={filters[1].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[1].label}
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    disabled={!filters[1].selected}
                    className={'form-control'}
                    selected={
                      filters[1].value != null
                        ? new Date(filters[1].value * 1000)
                        : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      debugger
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="scheme">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    type="checkbox"
                    checked={filters[2].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[2].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[2].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={deathSchemesList.filter(
                      (data) => data.value == filters[2].value
                    )}
                    isDisabled={!filters[2].selected}
                    options={deathSchemesList}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[2].value = selectedOption?.value
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="incidentType">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    type="checkbox"
                    checked={filters[7].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[7].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[7].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={IncidentTypeList.filter(
                      (data) => data.value == filters[7].value
                    )}
                    isDisabled={!filters[7].selected}
                    options={IncidentTypeList}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[7].value = selectedOption?.value
                          draft[7].dropdownValue = selectedOption
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formProvince">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    type="checkbox"
                    checked={filters[3].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[3].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[3].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={filters[3].dropdownValue}
                    isDisabled={!filters[3].selected}
                    options={provinceList}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[3].value = selectedOption?.value
                          draft[3].dropdownValue = selectedOption
                          draft[4].value = null
                          draft[4].dropdownValue = null
                          draft[4].selected = false
                          draft[5].value = null
                          draft[5].dropdownValue = null
                          draft[5].selected = false
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formDistrict">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    disabled={filters[3].dropdownValue == null}
                    type="checkbox"
                    checked={filters[4].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[4].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[4].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={filters[4].dropdownValue}
                    isDisabled={
                      !filters[4].selected || filters[3].dropdownValue == null
                    }
                    options={districtList.filter((district: DistrictOption) => {
                      return district.provinceId === filters[3].value
                    })}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[4].value = selectedOption?.value
                          draft[4].dropdownValue = selectedOption
                          draft[5].value = null
                          draft[5].selected = false
                          draft[5].dropdownValue = null
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              {/* <Form.Group as={Row} className="mb-3" controlId="formDs">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    type="checkbox"
                    disabled={filters[4].dropdownValue == null}
                    checked={filters[5].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[5].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[5].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={filters[5].dropdownValue}
                    isDisabled={
                      !filters[5].selected || filters[4].dropdownValue == null
                    }
                    options={dsList.filter((ds: DsOption) => {
                      return ds.districtId === filters[4].value
                    })}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[5].value = selectedOption?.value
                          draft[5].dropdownValue = selectedOption
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group> */}
              <Form.Group as={Row} className="mb-3" controlId="formStatus">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    type="checkbox"
                    checked={filters[6].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[6].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[6].label}
                </Form.Label>
                <Col sm="6">
                  <Select
                    value={filters[6].dropdownValue}
                    isDisabled={!filters[6].selected}
                    options={propertyFilterStatus}
                    onChange={(selectedOption) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[6].value = selectedOption?.value
                          draft[6].dropdownValue = selectedOption
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          )}
        </div>

        <div className="d-flex justify-content-right p-4">
          <button className="save-button" onClick={onSubmit}>
            Filter Results
          </button>
        </div>
      </div>
    </>
  )
}

export default DeathFileRecommendationListFiletrs
