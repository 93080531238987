import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ApplicationDataView from '../../../../components/common/view/ApplicationDataView'
import ApplicationCloseReopenModal from '../../../../components/modals/ApplicationCloseReOpenModal'
import ClosePrintModal from '../../../../components/modals/ClosePrintModal'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ClosePrintModel } from '../../../../models/utils/closePrintModel'
import applicationFiles from '../../../../services/applicationRegistration/applicationFiles'
import common from '../../../../services/applicationRegistration/common'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { fileRegistrationHistoryColumns } from '../../../../utils/metaData'
import {
  FetchRequesthandleNotification,
  handleNotification,
} from '../../../../utils/utilFunctions'
import { saveAs } from 'file-saver'
import { getUser } from '../../../../store/user/selectors'
import SimilarRecordTable from '../../../../components/common/similarRecordTable/SimilarRecordTable'
import ModifyBasicInformation from '../../../../components/common/modifyBasicInformation/ModifyBasicInformation'
import produce from 'immer'
import ApplicationModifyService from '../../../../services/applicationRegistration/ApplicationModifyService '
export interface SectionOneProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onCancel: any
  onProceed: any
  onReject: any
  onReturn: any
  onModify: any
  isSimilar?: boolean
  onReload: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
  isReason?: boolean
}

const SectionOne: FC<SectionOneProps> = ({
  fileRegistrationObject,
  onOk,
  onCancel,
  onProceed,
  onReject,
  onReturn,
  onModify,
  isSimilar = false,
  onReload,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [showClosePrintModal, setShowClosePrintModal] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showReOpenModal, setShowReOpenModal] = useState(false)
  const [updateMode, setUpdateMode] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  useEffect(() => {
    setUpdateMode(false)
    async function getFileDetails() {
      const fileHistoryData =
        await applicationFiles.apiFetchApplicationFilesHistory(
          fileRegistrationObject.id
        )
      const similarRecordData = await common.apiGetSimilarRecords(
        fileRegistrationObject.id
      )
      if (fileHistoryData.status === 200) {
        setHistoryList(fileHistoryData.data)
      }
      if (similarRecordData.status === 200) {
        setSimilarRecordList(similarRecordData.data)
      }
      setLoading(false)
    }
    getFileDetails()
  }, [location.pathname, loading])

  const handleConfirm = async (remark: string) => {
    if (showConfirm.section === 'proceed') {
      if (showConfirm.isRemark && remark) {
        const newFileRegistrationObject = produce(
          fileRegistrationObject,
          (draft) => {
            draft.remarksToProceedWithDuplicates = remark
          }
        )
        const result = await ApplicationModifyService.updateApplicationFile(
          newFileRegistrationObject
        )
        dispatch(changeLoadingRequest())

        handleNotification(
          result,
          'The application has been modified successfully'
        )

        if (result.status === 200) {
          onProceed()
          dispatch(changeLoadingRequest())
        }
      } else {
        onProceed()
      }
    } else if (showConfirm.section === 'modify') {
      onModify()
    } else if (showConfirm.section === 'reject') {
      onReject(remark)
    } else if (showConfirm.section === 'return') {
      onReturn(remark)
    } else {
      onCancel(showConfirm.section)
    }
    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
  }

  const checkLoggedUser = () => {
    if (user.epfNumber === fileRegistrationObject.allocateTo) {
      return true
    }
    return false
  }

  const handleReOpen = async (object: any) => {
    dispatch(changeLoadingRequest())
    const response = await common.apiReOpenApplication(
      fileRegistrationObject.id,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase(),
      object.description,
      object.remark,
      object.allocateTo
    )
    handleNotification(response, 'Application Re Open Succesfully')
    if (response.status === 200) {
      history.goBack()
    }
    dispatch(changeLoadingRequest())
  }

  const handleCloseLetterPrint = () => {
    setShowClosePrintModal(true)
  }

  const handleClosePrint = (printData: ClosePrintModel) => {
    if (printData.language == 'eng') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-close-letter/pdf`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Close letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
            if (printData.type == 'pdf') {
              window.open(URL.createObjectURL(response))
            } else {
              const fileName = `close_letter_${fileRegistrationObject.id}.pdf`
              saveAs(response, fileName)
            }
          }
        })
    } else {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-close-letter/non-english/${printData.language}/docx`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Close letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })

            const fileName = `close_letter_${fileRegistrationObject.id}.docx`
            saveAs(response, fileName)
          }
        })
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Basic Information</div>
            {!updateMode ? (
              <ApplicationDataView
                fileRegistrationObject={fileRegistrationObject}
                isOverAge={
                  fileRegistrationObject.identificationType !=
                  'BIRTH_CERTIFICATE'
                }
              />
            ) : (
              <ModifyBasicInformation
                information={fileRegistrationObject}
                onCancel={() => {
                  setLoading(true)
                }}
                onSuccess={onReload}
              />
            )}
          </Col>
          {!updateMode && fileRegistrationObject.validationLevel < 1 && (
            <button
              className="save-button ms-auto me-0 mt-3"
              onClick={() => {
                setUpdateMode(true)
              }}
            >
              Edit
            </button>
          )}

          <Col sm={12} className="mt-3 mb-3 p-3">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="audit-history"
            >
              <Row className="tab-page-layout">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="audit-history">Audit History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="similar-records">
                      Similar Record
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Row>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="audit-history">
                    <Container fluid className="mt-4">
                      <ReactTableWithPaginationComponent
                        columns={fileRegistrationHistoryColumns}
                        data={historyList}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="similar-records">
                    <SimilarRecordTable similarRecordList={similarRecordList} />
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </Col>
          {!isSimilar && (
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {checkLoggedUser() && (
                <>
                  {(fileRegistrationObject.validationLevel === 0 ||
                    fileRegistrationObject.status ==
                      'Application Re-allocated') && (
                    <button
                      className="save-button custom-margin-right"
                      // onClick={() => {
                      //   const message = 'Do you wish to proceed application ?'
                      //   const section = 'proceed'
                      //   setShowConfirm({
                      //     isShow: true,
                      //     message: message,
                      //     section: section,
                      //     isRemark: false,
                      //   })
                      // }}
                      onClick={() => {
                        if (similarRecordList.length > 0) {
                          const message =
                            'Do you wish to Proceed application with similar records?'
                          const section = 'proceed'
                          setShowConfirm({
                            isShow: true,
                            message: message,
                            section: section,
                            isRemark: true,
                            isReason: false,
                          })
                        } else {
                          const message = 'Do you wish to proceed application ?'
                          const section = 'proceed'
                          setShowConfirm({
                            isShow: true,
                            message: message,
                            section: section,
                            isRemark: false,
                          })
                        }
                      }}
                    >
                      Proceed
                    </button>
                  )}
                  {fileRegistrationObject.status === 'Application Returned' && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        const message = 'Do you wish to modify application ?'
                        const section = 'modify'
                        setShowConfirm({
                          isShow: true,
                          message: message,
                          section: section,
                          isRemark: false,
                        })
                      }}
                    >
                      Modify
                    </button>
                  )}
                  {fileRegistrationObject.status != 'Application Closed' && (
                    <>
                      {/* {(fileRegistrationObject.status ==
                        'Application Creation Pending' ||
                        fileRegistrationObject.status ==
                          'Application Drafted') && (
                        <>
                          <button
                            className="save-button custom-margin-right"
                            onClick={() => {
                              const message =
                                'Do you wish to Reject application ?'
                              const section = 'reject'
                              setShowConfirm({
                                isShow: true,
                                message: message,
                                section: section,
                                isRemark: true,
                              })
                            }}
                          >
                            Reject
                          </button>
                        </>
                      )} */}
                      {(fileRegistrationObject.status ==
                        'Application Creation Pending' ||
                        fileRegistrationObject.status ==
                          'Application Re-allocated') && (
                        <button
                          className="save-button custom-margin-right"
                          onClick={() => {
                            const message =
                              'Do you wish to Return application ?'
                            const section = 'return'
                            setShowConfirm({
                              isShow: true,
                              message: message,
                              section: section,
                              isRemark: true,
                            })
                          }}
                        >
                          Return
                        </button>
                      )}
                    </>
                  )}
                  {fileRegistrationObject.status === 'Application Closed' && (
                    <>
                      <button
                        className="save-button custom-margin-right"
                        onClick={() => {
                          setShowReOpenModal(true)
                        }}
                      >
                        Re Open
                      </button>
                      <button
                        className="print-acknowledgement-button custom-margin-right"
                        onClick={() => {
                          handleCloseLetterPrint()
                        }}
                      >
                        Print Close Letter
                      </button>
                    </>
                  )}
                </>
              )}
              <button className="cancel-button" onClick={onOk}>
                Ok
              </button>
            </Col>
          )}
        </>
      )}
      {showReOpenModal && (
        <ApplicationCloseReopenModal
          dsId={fileRegistrationObject.dsDivisionId}
          type={'re-open'}
          message={`Do you wish to re open this application`}
          onCancel={() => {
            setShowReOpenModal(false)
          }}
          onSave={(object: any) => {
            setShowReOpenModal(false)
            handleReOpen(object)
          }}
        />
      )}
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          isReason={showConfirm.isReason}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }}
          isRemark={showConfirm.isRemark}
          onConfirm={(remark: string) => {
            handleConfirm(remark)
          }}
          reasonType={showConfirm.section}
          section={'Application Registration'}
          remarkValue={fileRegistrationObject.remarksToProceedWithDuplicates}
        />
      )}
      {showClosePrintModal && (
        <ClosePrintModal
          onConfirm={(data: ClosePrintModel) => {
            console.log(data)
            handleClosePrint(data)
            setShowClosePrintModal(false)
          }}
          onCancel={() => {
            setShowClosePrintModal(false)
          }}
        />
      )}
    </>
  )
}

export default SectionOne
