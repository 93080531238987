import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import IterminalTableComponent from '../../../../components/table/IterminalTableComponent'
import { FilterModel } from '../../../../models/utils/filterModel'
import { FilterOptionModel } from '../../../../models/utils/filterOption'
import { filtersSetRequest } from '../../../../store/filter/actions'
import { getFilters } from '../../../../store/filter/selectors'
import { compensationApplicationsFilters } from '../../../../utils/metaData'
import { getPageCount } from '../../../../utils/tableFunctions'
import ManageChequePaymentsService from '../../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import { handleNotification } from '../../../../utils/utilFunctions'
import CompensationApplicationsFilter from '../../../../components/filters/CompensationApplicationsFilter'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../../utils/constants'

const RlpReturn = () => {
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
    filterData: [
      {
        operator: 'STRING_EQUAL',
        property: 'scheme',
        value: 'RLP',
      },
    ] as FilterOptionModel[],
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const [state, setstate] = useState([])
  const [loading, setLoading] = useState(true)
  const filters = useSelector(getFilters)
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationApplicationsFilters }))
    async function getApplicationFiles() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getApplicationFiles()
  }, [])

  const retriveApplicationFilesData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)
    const applicationData =
      await ManageChequePaymentsService.apiFetchReturnFileList(newFilter)
    setstate(applicationData.data)
    setLoading(false)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  async function retriveDataCountAndData(filterData: FilterModel) {
    const applicationCountData =
      await ManageChequePaymentsService.apiFetchReturnFileListCount(filterData)
    if (applicationCountData.status === 200) {
      setDatacount(applicationCountData.data.count)
      setPageCount(
        getPageCount(applicationCountData.data.count, filterOption.limit)
      )
      const applicationData =
        await ManageChequePaymentsService.apiFetchReturnFileList(filterData)
      setstate(applicationData.data)
    } else {
      setDatacount(0)
      setPageCount(0)
      setstate([])
    }
  }
  const fileRegistrationColumns = [
    {
      Header: 'Application Reference',
      accessor: 'referenceNumber',
    },
    {
      Header: 'Incident Type',
      accessor: 'incidentType.incidentType',
    },
    {
      Header: 'Scheme',
      accessor: 'scheme',
    },
    {
      Header: 'Applicant Name',
      accessor: 'applicantName',
    },
    {
      Header: 'NIC',
      accessor: 'newNicNumber',
    },
    {
      Header: 'Province',
      accessor: 'province.name',
    },
    {
      Header: 'District',
      accessor: 'district.name',
    },
    {
      Header: 'DS Division',
      accessor: 'dsDivision.name',
    },
    {
      Header: 'GN Division',
      accessor: 'gnDivision.name',
    },
    {
      Header: 'File Received Date',
      accessor: 'fileReceivedDate',
    },
    {
      Header: 'Created Date and Time',
      accessor: 'createdDateTime',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (e: any) => {
        if (e.cell.value != '') {
          return (
            <button
              className="save-button"
              onClick={(action) => {
                action.preventDefault()
                action.stopPropagation()
                handleView(e.row.original)
              }}
            >
              Modify
            </button>
          )
        }
      },
    },
  ]
  const columns = React.useMemo(() => fileRegistrationColumns, [])
  const handleView = async (object: any) => {
    if (object.status == 'Cheque Payment Returned') {
      const applicationData =
        await ManageChequePaymentsService.apiModifyChequePrintReturnFile(
          object.id
        )
      if (applicationData.status == 200) {
        handleNotification(applicationData, 'Ready to modify')
        history.push(`${path}add/${object.id}`)
      }
    }
  }

  return (
    <>
      {!loading && (
        <div className="mb-4">
          <CompensationApplicationsFilter
            scheme="RLP"
            filterData={filters}
            onFilter={(e: FilterOptionModel[]) => {
              dispatch(filtersSetRequest({ filters: e }))
              retriveFilterData(
                e.filter(
                  (filter: FilterOptionModel) => filter.selected === true
                )
              )
            }}
          />
        </div>
      )}
      <div className=" table-wrapper">
        <IterminalTableComponent
          columns={columns}
          data={state}
          fetchData={retriveApplicationFilesData}
          loading={loading}
          pageCount={pageCount}
          totalCount={dataCount}
          onClickRow={(row: any) => {
            console.log(row.id)
          }}
        />
      </div>
    </>
  )
}

export default RlpReturn
