import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanNPADetailsModel } from '../../../models/applicationRegistration/loan/loanNPADetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import { npaHistoryColumns } from '../../../utils/metaData'
import { thousandSeperator } from '../../../utils/utilFunctions'

export interface NPADetailsProps {
  fileRegistrationObject: FileRegistrationModel
  npaDetailsList: LoanNPADetailsModel[]
}

const NPADetails: FC<NPADetailsProps> = ({
  fileRegistrationObject,
  npaDetailsList,
}) => {
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function getData() {
      const historyData =
        await enquireApplicationService.apiFetchNPAHistoryDetails(
          fileRegistrationObject.id
        )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])
  const renderNPADetailsTable = () => {
    return npaDetailsList.map((npa: LoanNPADetailsModel) => {
      return (
        <tr key={`npa-${npa.id}`}>
          <td>{thousandSeperator(npa.outstandingAmount)}</td>
          <td>{thousandSeperator(npa.npaAmount)}</td>
          <td>{npa.npaNumber}</td>
          <td>{npa.npaRemarks}</td>
          <td>
            <Form.Check
              disabled={true}
              checked={npa.legalActionTaken}
              type="checkbox"
              className="mb-2"
            />
          </td>
          <td>
            <Form.Check
              disabled={true}
              checked={npa.guarantorPayment}
              type="checkbox"
              className="mb-2"
            />
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {npaDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">NPA List</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>OutStanding Amount</th>
                        <th>NPA Amount</th>
                        <th>NPA Number</th>
                        <th>NPA Remarks</th>
                        <th>Legal Action</th>
                        <th>Gurantor Payment</th>
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderNPADetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={npaHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
        </>
      )}
    </>
  )
}

export default NPADetails
