import apiService from '../api/apiManager'

class NewReportService {
  async getReportStatsFileList(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-payment-stats-report/list`,
      filterData
    )
  }

  async getReportStatsFileCount(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-payment-stats-report/count`,
      filterData
    )
  }

  async getReportApplicationFileList(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-details-report/list`,
      filterData
    )
  }

  async getReportApplicationCount(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-details-report/count`,
      filterData
    )
  }

  async generateExcelCompleteReport(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-details-report/download`,
      filterData
    )
  }

  async getAllDeatalReportHtml(id: number) {
    return apiService.apiGet(
      `/report/application-report/application-details-report/print/${id}`
    )
  }

  async getPaymentReportFileList(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-payment-summary-report/list`,
      filterData
    )
  }

  async getPaymentReportCount(filterData: any) {
    return apiService.apiPost(
      `/report/application-report/application-payment-summary-report/count`,
      filterData
    )
  }
}

export default new NewReportService()
