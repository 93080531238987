import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { GuarantorDetailsModel } from '../../../models/applicationRegistration/loan/guarantorDetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import { gurantorsHistoryColumns } from '../../../utils/metaData'

export interface GurantorDetailsProps {
  fileRegistrationObject: FileRegistrationModel
  gurantorDetailsList: GuarantorDetailsModel[]
}

const GurantorDetails: FC<GurantorDetailsProps> = ({
  fileRegistrationObject,
  gurantorDetailsList,
}) => {
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function getData() {
      const historyData =
        await enquireApplicationService.apiFetchGurantorHistoryDetails(
          fileRegistrationObject.id
        )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])

  const renderGuarantorDetailsTable = () => {
    return gurantorDetailsList.map((gurantor: GuarantorDetailsModel) => {
      return (
        <tr key={`gurantor-${gurantor.id}`}>
          <td>{gurantor.name}</td>
          <td>
            {gurantor.newNic ? gurantor.newNicNumber : gurantor.oldNicNumber}
          </td>
          <td>{gurantor.address}</td>
          <td>{gurantor.phoneNumber}</td>
          <td>{gurantor.status}</td>
        </tr>
      )
    })
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {gurantorDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Guarantors</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Guarantor Name</th>
                        <th>NIC Number</th>
                        <th>Address</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderGuarantorDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={gurantorsHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
        </>
      )}
    </>
  )
}

export default GurantorDetails
