import { ICompensationValueDtoModel } from '../systemConfiguration/compensationValues/compensationValueDtoModel'

export class CompensationValueDtoModel {
  constructor(
    public balanceLoanAmount: number,
    public damagePercentage: number,
    public recommendedAmount: number,
    public scheme: string,
    public incidentTypeId: number,
    public typeOfInjuryId: number,
    public receivedTotalDonations: number,
    public otherAssistanceAmount: number,
    public percentageOfLossOfEarningCapacity: number
  ) {}

  static create(event: ICompensationValueDtoModel) {
    return new CompensationValueDtoModel(
      event.balanceLoanAmount,
      event.damagePercentage,
      event.recommendedAmount,
      event.scheme,
      event.incidentTypeId,
      event.typeOfInjuryId,
      event.receivedTotalDonations,
      event.otherAssistanceAmount,
      event.percentageOfLossOfEarningCapacity
    )
  }
}
