import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class ChequePrintService {
  async apiFetchFileList(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/cheque-print/view/list`,
      filterData
    )
  }

  async apiFetchFileListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/cheque-print/view/count`,
      filterData
    )
  }
  async apiPrintCheque(id: number, cheque_details_id: number) {
    return apiService.apiPostBinary(
      `/cheque-payment/cheque-print/${id}/print/${cheque_details_id}`,
      {}
    )
  }
  async apiPrintCoverLetter(id: number, cheque_details_id: number) {
    return apiService.apiPostBinary(
      `/cheque-payment/cheque-print/${id}/print_covering_letter/${cheque_details_id}`,
      {}
    )
  }
  async apiReturnChequePrint(id: number, remark: string, chequeId: number) {
    return apiService.apiPutCommon(
      `/cheque-payment/cheque-print/${id}/return`,
      {
        chequeDetailsId: chequeId,
        remark: remark,
      }
    )
  }
  async apiCancelChequePrint(
    id: number,
    cheque_details_id: number,
    remark: string
  ) {
    return apiService.apiPutCommon(
      `/cheque-payment/cheque-print/${id}/cancel/${cheque_details_id}`,
      {
        cancellationReason: remark,
      }
    )
  }
  async apiAddPaymentDetails(
    id: number,
    cheque_details_id: number,
    paymentDetails: any
  ) {
    return apiService.apiPutCommon(
      `/cheque-payment/cheque-print/${id}/payment_details/${cheque_details_id}`,
      paymentDetails
    )
  }
  async apiModifyChequePrintFile(id: number, index: string) {
    return apiService.apiPutCommon(
      `/cheque-payment/cheque-print/${id}/modify/${index}`,
      {}
    )
  }

  async printCoverLetter(
    applicationFileId: number,
    chequeDetailsId: number,
    language: string
  ) {
    return apiService.apiGet(
      `/cheque-payment/cheque-print/${applicationFileId}/print/covering-letter/${chequeDetailsId}/${language}/docx`
    )
  }
}
export default new ChequePrintService()
