import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import './Modals.scss'

export interface ConfirmationModalProps {
  name: string
  message: string
  onOk: any
}
export interface RequestObjectModel {
  reasonType: string
  section: string
  status: string
}

const PermissionDeniedModal: FC<ConfirmationModalProps> = ({
  name,
  message,
  onOk,
}) => {
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onOk}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="cancel-button"
          onClick={() => {
            onOk()
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PermissionDeniedModal
