import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class MpDataService {
  async apiFetchMpList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/mp/view/list`,
      filterData
    )
  }

  async apiFetchMpListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/mp/view/count`,
      filterData
    )
  }

  async apiFetchMpReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/mp/file-return/view/list`,
      filterData
    )
  }

  async apiFetchMpReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/mp/file-return/view/count`,
      filterData
    )
  }
  async apiApproveMpFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/mp/${id}/approve`,
      {}
    )
  }

  async apiRejectMpFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/mp/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnMpFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/mp/${id}/return`,
      { remark: remark }
    )
  }
  async apiUmpateMpFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/mp/file-return/${id}`,
      file
    )
  }
  async apiGetReturnMpFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/mp/file-return/view/${id}`
    )
  }
  async apiGetMpFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/mp/view/${id}`
    )
  }
}

export default new MpDataService()
