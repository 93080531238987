/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import metaDataService from '../../services/metaDataService'
import {
  currencyMask,
  getAgeByMiliSeconds,
  getDateToInputField,
  getGenderByNic,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
} from '../../utils/utilFunctions'
import IdentificationTypesComponent from '../common/identificationComponent/IdentificationTypesComponent'

export interface SectionTwoProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
}

const SectionTwo: FC<SectionTwoProps> = ({ fileRegistrationObject, onOk }) => {
  const [loading, setLoading] = useState(true)
  const [sectionTwoData, setSectionTwoData] = useState(fileRegistrationObject)
  const [educationLevelList, setEducationLevelList] = useState([])

  useEffect(() => {
    async function getMetaData() {
      const educationData = await metaDataService.getEducationalList()
      if (educationData.status === 200) {
        setEducationLevelList(educationData.data)
      }
      const newFormValue = produce(
        sectionTwoData,
        (draft: FileRegistrationModel) => {
          draft.applicantType =
            draft.scheme === 'PD' || draft.scheme === 'GSD' ? 'Applicant' : ''
          // draft.age = getAgeByMiliSeconds(draft.dateOfBirth)
          draft.gender = getGenderByNic(draft.newNicNumber)
          draft.occupationDetail = {
            id: fileRegistrationObject.id,
          }
        }
      )
      setSectionTwoData(newFormValue)
      setLoading(false)
    }
    getMetaData()
  }, [])

  return (
    <>
      <Col sm={12} className="border-label">
        <div className="border-label-span">Applicant Details</div>
        <Form id="file-applicant-details-form">
          {sectionTwoData.applicantType ? (
            <Form.Group className="mb-3" controlId="formProgramType">
              <Form.Label>Applicant Type</Form.Label>
              <Form.Control
                disabled={true}
                value={sectionTwoData.applicantType}
                placeholder="Applicant Type"
              ></Form.Control>
            </Form.Group>
          ) : (
            ''
          )}
          <Form.Group className="mb-3" controlId="formGroupTitle">
            <Form.Label>
              Applicant Title <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              disabled={true}
              value={sectionTwoData.applicantTitle}
              placeholder="Applicant Title"
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formApplicantName">
            <Form.Label>Applicant Name</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Applicant Name"
              value={sectionTwoData.applicantName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPermenantAddress">
            <Form.Label>Permenant Address</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Permenant Address"
              value={sectionTwoData.permanentAddress}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formContactNo">
            <Form.Label>Contact No</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Contact No"
              value={sectionTwoData.contactNo}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSelection">
            <Form.Label>Identification Type</Form.Label>
            <IdentificationTypesComponent
              isDisabled={true}
              identificationType={sectionTwoData.identificationType}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formApplicantDob">
            <Form.Label>Date of birth</Form.Label>
            <Form.Control
              disabled={true}
              placeholder="Date of birth"
              type="text"
              value={
                sectionTwoData.dateOfBirth != null
                  ? new Date(
                      sectionTwoData.dateOfBirth * 1000
                    ).toLocaleDateString()
                  : undefined
              }
            />
          </Form.Group>
          {sectionTwoData.identificationType != 'BIRTH_CERTIFICATE' && (
            <>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formNICNumber">
                  <Form.Label>
                    <Row>
                      <Col>NIC Number</Col>
                      <Col>
                        <Form.Check
                          disabled={true}
                          inline
                          name="formNICNumberType"
                          label="New"
                          type="radio"
                          id={`formNICNumberType-N`}
                          checked={sectionTwoData.newNic}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled={true}
                          inline
                          name="formNICNumberType"
                          label="Old"
                          type="radio"
                          id={`formNICNumberType-O`}
                          checked={!sectionTwoData.newNic}
                        />
                      </Col>
                    </Row>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="NIC Number"
                    value={
                      sectionTwoData.newNic
                        ? sectionTwoData.newNicNumber
                        : sectionTwoData.oldNicNumber
                    }
                  />
                </Form.Group>
              </Row>
              {sectionTwoData.identificationType != 'NIC' && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formPSDNumber">
                    <Form.Label>
                      {renderIdentificationTypeName(
                        sectionTwoData.identificationType
                      )}{' '}
                      *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={true}
                      placeholder={`Enter ${renderIdentificationTypeName(
                        sectionTwoData.identificationType
                      )}`}
                      value={renderIdentificationTypeValue(
                        sectionTwoData.identificationType,
                        sectionTwoData
                      )}
                    />
                  </Form.Group>
                </Row>
              )}
            </>
          )}
          <Col sm={12} className="border-label mt-4 mb-3">
            <div className="border-label-span">Birth Certificate Details</div>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBirthCNumber">
                <Form.Label>Birth Certificate Number</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Birth Certificate Number"
                  value={sectionTwoData.birthCertificateNumber}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBirthCDsDivision">
                <Form.Label>Ds Division of Birth Certificate</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Ds Division of Birth Certificate"
                  value={
                    sectionTwoData.birthCertificateDsDivision != null
                      ? sectionTwoData.birthCertificateDsDivision.name
                      : ''
                  }
                />
              </Form.Group>
            </Row>
          </Col>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNicIssuedDate">
              <Form.Label>NIC Issued Date</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Nic Issued Date"
                value={
                  sectionTwoData.nicIssuedDate != null
                    ? new Date(
                        sectionTwoData.nicIssuedDate * 1000
                      ).toLocaleDateString()
                    : undefined
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formAge">
              <Form.Label>Age</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Age"
                value={sectionTwoData.age}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Gender"
                value={fileRegistrationObject.gender}
              />
            </Form.Group>
          </Row>
          {sectionTwoData.applicantType === 'Applicant' &&
            (fileRegistrationObject.scheme === 'PD' ||
              fileRegistrationObject.scheme === 'PI' ||
              fileRegistrationObject.scheme === 'GSI' ||
              fileRegistrationObject.scheme === 'GSD') && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formRelationshipAgPerson">
                  <Form.Label>Relationship to the Applicant</Form.Label>
                  <Form.Control
                    disabled={true}
                    value={sectionTwoData.relationship}
                    placeholder="Relationship to the Applicant"
                  ></Form.Control>
                </Form.Group>
              </Row>
            )}
          {fileRegistrationObject.scheme === 'GSC' && (
            <Col sm={12} className="border-label mt-4 mb-3">
              <div className="border-label-span">
                Details of the Occupations
              </div>
              <Row>
                <Form.Group as={Col} controlId="formDesignation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Designation"
                    value={sectionTwoData.occupationDetail.designation}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formOrganizationName">
                  <Form.Label>Name of the Organization</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Name of the Organization"
                    value={sectionTwoData.occupationDetail.organization}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Form.Group as={Col} controlId="formEarnings">
                  <Form.Label>Salary/ Wages/ Earnings</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Salary/ Wages/ Earnings"
                    value={currencyMask(
                      sectionTwoData.occupationDetail.monthlySalary
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formEPFStaffNumber">
                  <Form.Label>EPF or Staff number</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="EPF or Staff number"
                    value={
                      sectionTwoData.occupationDetail.staffRegistrationNumber
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formBalanceLoanAmount">
                  <Form.Label>Balance Loan amount</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Balance Loan amount"
                    value={
                      sectionTwoData.occupationDetail.balanceLoanAmount
                        ? currencyMask(
                            sectionTwoData.occupationDetail.balanceLoanAmount
                          )
                        : ''
                    }
                  />
                </Form.Group>
              </Row>
            </Col>
          )}
          {(fileRegistrationObject.scheme === 'SE' ||
            fileRegistrationObject.scheme === 'AH' ||
            fileRegistrationObject.scheme === 'SRT') && (
            <>
              <Row className="mt-4 mb-3">
                <Form.Group as={Col} controlId="formMaritalStatus">
                  <Form.Label>Marital Status</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Marital Status"
                    value={sectionTwoData.maritalStatus}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-4 mb-3">
                <Form.Group as={Col} controlId="formApplicantOccupation">
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Occupation"
                    value={sectionTwoData.occupation}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-4 mb-3">
                <Form.Group as={Col} controlId="formSelfOccupation">
                  <Form.Label>Self employment to the occupation</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Self employment to the occupation"
                    value={sectionTwoData.selfEmploymentToTheOccupation}
                  />
                </Form.Group>
              </Row>
            </>
          )}
          {fileRegistrationObject.scheme === 'SRT' && (
            <>
              <Row className="mt-4 mb-3">
                <Form.Group as={Col} controlId="formInjuredDueTo">
                  <Form.Label>Injured Due to a violence Activity</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        disabled={true}
                        inline
                        name="formInjuredDueTo"
                        label="Yes"
                        type="radio"
                        id={`formInjuredDueTo-N`}
                        checked={sectionTwoData.injuredDueToAViolenceActivity}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        disabled={true}
                        inline
                        name="formInjuredDueTo"
                        label="No"
                        type="radio"
                        id={`formInjuredDueTo-O`}
                        checked={!sectionTwoData.injuredDueToAViolenceActivity}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row className="mt-4 mb-3">
                <Form.Group as={Col} controlId="formWidowDueTo">
                  <Form.Label>Is a Widow due to the war?</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        disabled={true}
                        inline
                        name="formInjuredDueTo"
                        label="Yes"
                        type="radio"
                        id={`formInjuredDueTo-N`}
                        checked={sectionTwoData.isAWidowDueToTheWar}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        disabled={true}
                        inline
                        name="formInjuredDueTo"
                        label="No"
                        type="radio"
                        id={`formInjuredDueTo-O`}
                        checked={!sectionTwoData.isAWidowDueToTheWar}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </>
          )}
          {sectionTwoData.jointLoanDetailId != null && (
            <Col sm={12} className="border-label">
              <div className="border-label-span">Join Loan Details</div>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formJointName">
                  <Form.Label>Name of the Joint Applicant</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Name of the Joint Applicant"
                    value={sectionTwoData.jointLoanDetails.name}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formJointNICNumber">
                  <Form.Label>
                    <Row>
                      <Col>NIC Number *</Col>
                      <Col>
                        <Form.Check
                          disabled={true}
                          inline
                          name="formJointNICNumberType"
                          label="New"
                          type="radio"
                          id={`formJointNICNumberType-N`}
                          checked={sectionTwoData.jointLoanDetails.newNic}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled={true}
                          inline
                          name="formJointNICNumberType"
                          label="Old"
                          type="radio"
                          id={`formJointNICNumberType-O`}
                          checked={!sectionTwoData.jointLoanDetails.newNic}
                        />
                      </Col>
                    </Row>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="NIC Number"
                    value={
                      sectionTwoData.jointLoanDetails.newNic
                        ? sectionTwoData.jointLoanDetails.newNicNumber
                        : sectionTwoData.jointLoanDetails.oldNicNumber
                    }
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formJointAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Age"
                    value={sectionTwoData.jointLoanDetails.age}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formJointAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Address"
                    value={sectionTwoData.jointLoanDetails.permanentAddress}
                  />
                </Form.Group>
              </Row>
            </Col>
          )}
          {(fileRegistrationObject.scheme === 'SE' ||
            fileRegistrationObject.scheme === 'AH' ||
            fileRegistrationObject.scheme === 'SRT') && (
            <Row className="mt-4 mb-3">
              <Form.Group as={Col} controlId="formApplicantEducationLevel">
                <Form.Label>Education Level</Form.Label>
                <Form.Control
                  disabled={true}
                  className="form-select"
                  as="select"
                  placeholder="Education Level"
                  value={sectionTwoData.educationLevelId}
                >
                  {educationLevelList.map(
                    (
                      education: { name: string; id: number },
                      index: number
                    ) => {
                      return (
                        <option key={`country-${index}`} value={education.id}>
                          {education.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
              </Form.Group>
            </Row>
          )}
          {(fileRegistrationObject.scheme === 'PD' ||
            fileRegistrationObject.scheme === 'PI' ||
            fileRegistrationObject.scheme === 'GSI' ||
            fileRegistrationObject.scheme === 'GSD') && (
            <Row className="mb-3">
              <Form.Group
                as={Col}
                controlId="formDetailsOfTheApplicantsRightClaimForCompensation"
              >
                <Form.Label>
                  Details of the Applicants right to claim for Compensation
                </Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Details of the Applicants right to claim for Compensation"
                  value={sectionTwoData.compensationDetails}
                />
              </Form.Group>
            </Row>
          )}
        </Form>
      </Col>
      <Col sm={12} className="d-flex justify-content-end mt-4">
        <button className="cancel-button" onClick={onOk}>
          Ok
        </button>
      </Col>
    </>
  )
}

export default SectionTwo
