import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { commonColumns } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'

const MpApplication = () => {
  const initFilterOption = {
    filterValue: null,
    limit: defaultPageLimit,
    page: 1,
    orderBy: 'created_date_time',
    order: 'DESC',
  } as AdvancedFilterModel

  const { path } = useRouteMatch()
  const history = useHistory()

  const handleView = (id: number) => {
    history.push(`${path}view/${id}`)
  }

  return (
    <>
      {/* {!loading && (
        <div className="mb-4">
          <CompensationApplicationsFilter
            scheme="MP"
            filterData={filters}
            onFilter={(e: FilterOptionModel[]) => {
              dispatch(filtersSetRequest({ filters: e }))
              retriveFilterData(
                e.filter(
                  (filter: FilterOptionModel) => filter.selected === true
                )
              )
            }}
          />
        </div>
      )}
      <div>
        <IterminalTableComponent
          columns={columns}
          data={state}
          fetchData={retriveApplicationFilesData}
          loading={loading}
          pageCount={pageCount}
          totalCount={dataCount}
          onClickRow={(row: any) => {
            handleView(row.id)
          }}
        />
      </div> */}
      <AdvancedSearchTable
        initFilterOption={initFilterOption}
        columns={commonColumns}
        dataApiPath={'/compensation-certification/compensation/mp/view/list'}
        countApiPath={'/compensation-certification/compensation/mp/view/count'}
        onClickRow={(row: any) => {
          handleView(row.id)
        }}
      />
    </>
  )
}

export default MpApplication
