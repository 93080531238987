import React, { FC, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import FileNameModal from '../../../../components/modals/FileNameModal'
import { FileSupportingDocument } from '../../../../models/applicationRegistration/compensation/FileSupportingDocument'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FileTypeListModel } from '../../../../models/systemConfiguration/supportingDocuments/fileTypeListModel'
import { SupportingDocumentsModel } from '../../../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { showTotast } from '../../../../utils/toast'
import { returnFileType } from '../../../../utils/utilFunctions'

export interface SupportingDocumentProps {
  fileRegistrationObject: FileRegistrationModel
  supportDocument: SupportingDocumentsModel
  fileList: FileSupportingDocument[]
  upload: any
  remove: any
  download: any
  isDisabled: boolean
}

const SupportingDocument: FC<SupportingDocumentProps> = ({
  supportDocument,
  fileList,
  upload,
  remove,
  download,
  isDisabled,
}) => {
  const inputFile = useRef(null)
  const [modalShow, setModalShow] = useState(false)
  const [file, setFile] = useState({} as File)

  const handleUpload = (file: any) => {
    // check file size
    const fsize = file[0].size
    const fsizeMb = Math.round(fsize / (1024 * 1024))
    // check file type
    let fileTypeAllowed = true
    const fileType = returnFileType(file[0].type)
    if (supportDocument.fileTypeList.length > 0) {
      const filterType = supportDocument.fileTypeList.filter((type: any) => {
        if (type.fileType === fileType) {
          return type
        }
      })
      if (filterType.length < 1) {
        fileTypeAllowed = false
      }
    }

    if (fsizeMb > supportDocument.fileSize || !fileTypeAllowed) {
      showTotast(
        ALERT_WARNING,
        !fileTypeAllowed
          ? 'File Type not allowed'
          : 'You have exceeded allowed file size',
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
    } else {
      setFile(file[0])
      setModalShow(true)
    }
    inputFile.current.value = null
  }

  const handlePdf = async (file: any) => {
    const headers: any = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: localStorage.getItem('token'),
    }
    fetch(file?.fileDownloadUri, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        window.open(URL.createObjectURL(data))
      })
  }

  const renderTableData = () => {
    return fileList.map((file: FileSupportingDocument, index: number) => {
      return (
        <tr key={`file-${index}`}>
          <td>{file.name}</td>
          <td>
            {(file.fileType == 'image/jpeg' ||
              file.fileType == 'image/png' ||
              file.fileType == 'application/pdf') && (
              <button
                className="save-button custom-margin-right"
                onClick={() => {
                  handlePdf(file)
                }}
              >
                View
              </button>
            )}

            <button
              className="save-button custom-margin-right"
              onClick={() => {
                download(file)
              }}
            >
              Download
            </button>
            <button
              className="save-button"
              onClick={() => {
                remove(file)
              }}
            >
              Remove
            </button>
          </td>
        </tr>
      )
    })
  }

  const renderAllowedFileTypes = () => {
    return supportDocument.fileTypeList.map(
      (type: FileTypeListModel, index: number) => {
        return (
          <span key={`type-${index}`} className="text-capitalize">
            &nbsp;{`${type.fileType.toLowerCase()}`}&nbsp;
          </span>
        )
      }
    )
  }

  return (
    <>
      <Col sm={12} className="border-label mt-3 mb-4">
        <div className="border-label-span">
          {supportDocument.documentNameEnglish}
          {supportDocument.isMandatory && (
            <span className="required-field-astrix">*</span>
          )}

          <span className="support-text">{`( Max Size ${supportDocument.fileSize} Mb )`}</span>
          <div className="support-text">({renderAllowedFileTypes()})</div>
        </div>
        <Row className="justify-content-end">
          <div className="col-sm-4">
            <button
              disabled={
                (!supportDocument.isMultipleAllowed && fileList.length > 0) ||
                isDisabled
              }
              className={
                !supportDocument.isMultipleAllowed && fileList.length > 0
                  ? 'disable-button'
                  : 'save-button'
              }
              onClick={() => {
                inputFile.current.click()
              }}
            >
              Upload
            </button>

            <input
              ref={inputFile}
              id={`supporting-document-${supportDocument.id}`}
              type="file"
              style={{ display: 'none' }}
              onChange={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleUpload(event.target.files)
              }}
            />
          </div>
        </Row>
        <Row className="mt-3">
          <table className="table table-bordered">
            <thead className="custom-table-header">
              <tr>
                <th>File Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="custom-table-body">{renderTableData()}</tbody>
          </table>
        </Row>
      </Col>
      {modalShow && (
        <FileNameModal
          name={file.name}
          fileType={file.type}
          acceptFileTypeList={supportDocument.fileTypeList}
          onCancel={() => {
            setModalShow(false)
            // inputFile.current.value = null
          }}
          onConfirm={(name: string) => {
            upload({ file, name })
            setModalShow(false)
            // inputFile.current.value = null
          }}
        />
      )}
    </>
  )
}

export default SupportingDocument
