import produce from 'immer'
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  CLEAR_USERS_REQUEST,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  CLEAR_ROLES_REQUEST,
} from './actionTypes'

import {
  UserManagementActions,
  UserManagementState,
  IUser,
} from './actionTypes'

const initialState: UserManagementState = {
  pending: false,
  users: [],
  roles: [],
  error: null,
}

export default (state = initialState, action: UserManagementActions) => {
  switch (action.type) {
    case CLEAR_USERS_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.users = []
      })
    case FETCH_USERS_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = true
      })
    case FETCH_USERS_SUCCESS:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.users = action.payload.users
        draft.error = null
      })
    case FETCH_USERS_FAILURE:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.users = []
        draft.error = action.payload.error
      })
    case CREATE_USER_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = true
      })
    case CREATE_USER_SUCCESS:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.users.push(action.payload.user)
        draft.error = null
      })
    case CREATE_USER_FAILURE:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.error = action.payload.error
      })
    case UPDATE_USER_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = true
      })
    case UPDATE_USER_SUCCESS:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.users[
          draft.users.findIndex(
            (user: IUser) => user.id === action.payload.user.id
          )
        ] = action.payload.user
        draft.error = null
      })
    case UPDATE_USER_FAILURE:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.error = action.payload.error
      })
    case FETCH_ROLES_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = true
      })
    case FETCH_ROLES_SUCCESS:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.roles = action.payload.roles
        draft.error = null
      })
    case FETCH_ROLES_FAILURE:
      return produce(state, (draft: UserManagementState) => {
        draft.pending = false
        draft.roles = []
        draft.error = action.payload.error
      })
    case CLEAR_ROLES_REQUEST:
      return produce(state, (draft: UserManagementState) => {
        draft.roles = []
      })
    default:
      return {
        ...state,
      }
  }
}
