// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import UserManagementDataService from '../../services/userManagementService'

import {
  createUserFailure,
  createUserSuccess,
  fetchUsersFailure,
  fetchUsersSuccess,
  updateUserSuccess,
  updateUserFailure,
  // fetchRolesFailure,
  // fetchRolesSuccess,
} from './actions'
import {
  CREATE_USER_REQUEST,
  // FETCH_ROLES_REQUEST,
  FETCH_USERS_REQUEST,
  UPDATE_USER_REQUEST,
} from './actionTypes'

/*
  Worker Saga: Fired on FETCH_USERS_REQUEST action
*/
function* fetchUsersSagaWorker() {
  const { data, errorMessage } = yield call(
    UserManagementDataService.apiUsersFetch
  )
  if (data)
    yield put(
      fetchUsersSuccess({
        users: data,
      })
    )
  else
    yield put(
      fetchUsersFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `FETCH_USERS_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchUsersSaga() {
  yield all([takeLatest(FETCH_USERS_REQUEST, fetchUsersSagaWorker)])
}

/*
  Worker Saga: Fired on CREATE_USER_REQUEST action
*/
function* createUserSagaWorker(action: any) {
  const { data, errorMessage } = yield UserManagementDataService.apiUserCreate(
    action.user
  )
  if (data)
    yield put(
      createUserSuccess({
        user: data,
      })
    )
  else
    yield put(
      createUserFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `CREATE_USER_REQUEST` action.
  Allows concurrent increments.
*/
function* createUserSaga() {
  yield all([takeLatest(CREATE_USER_REQUEST, createUserSagaWorker)])
}

/*
  Worker Saga: Fired on UPDATE_USER_REQUEST action
*/
function* updateUserSagaWorker(action: any) {
  const { data, errorMessage } = yield UserManagementDataService.apiUserUpdate(
    action.user
  )
  if (data)
    yield put(
      updateUserSuccess({
        user: data,
      })
    )
  else
    yield put(
      updateUserFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `UPDATE_USER_REQUEST` action.
  Allows concurrent increments.
*/
function* updateUserSaga() {
  yield all([takeLatest(UPDATE_USER_REQUEST, updateUserSagaWorker)])
}

/*
  Worker Saga: Fired on FETCH_ROLES_REQUEST action
*/
// function* fetchRolesSagaWorker() {
//   const { data, errorMessage } = yield call(
//     UserManagementDataService.apiRolesFetch
//   )
//   if (data)
//     yield put(
//       fetchRolesSuccess({
//         roles: data,
//       })
//     )
//   else
//     yield put(
//       fetchRolesFailure({
//         error: errorMessage,
//       })
//     )
// }

// /*
//   Starts worker saga on latest dispatched `FETCH_ROLES_REQUEST` action.
//   Allows concurrent increments.
// */
// function* fetchRolesSaga() {
//   yield all([takeLatest(FETCH_ROLES_REQUEST, fetchRolesSagaWorker)])
// }

export default function* todoSaga() {
  yield all([
    fork(fetchUsersSaga),
    fork(createUserSaga),
    fork(updateUserSaga),
    // fork(fetchRolesSaga),
  ])
}
