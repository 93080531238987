import React, { useState } from 'react'
import { Container, Form, Row, Col, InputGroup } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import emlem from '../../assets/images/login-emlem.png'
import user from '../../assets/images/user.svg'
// import lock from '../../assets/images/lock.svg'
// import './LoginComponent.scss'
import './ForgotPasswordComponent.scss'
import { officeTitle, officeSubTitle } from '../../utils/labels'
import { Link } from 'react-router-dom'
import { showTotast } from '../../utils/toast'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import authDataService from '../../services/authService'

const ForgotPassword = () => {
  const [username, setUsername] = useState('')
  const [response, setResponse] = useState(false)
  const [captchaValue, setCaptchaValue] = useState<string | null>(null)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const resetPassword = async () => {
    setIsButtonClicked(true)
    console.log(username)
    if (!captchaValue) {
      showTotast(
        ALERT_WARNING,
        'Please complete the CAPTCHA',
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setIsButtonClicked(false)
      return
    }

    const response = await authDataService.resetPassword(username)
    console.log(response)
    // debugger
    let success = false
    let message = response.errorMessage ? response.errorMessage : ''
    if (response.token === undefined) {
      console.log('++++++++')
      setResponse(true)
      success = true
      message = 'We have sent an email to the requested user email address'
      // history.replace(`${path}/modify`)
    }

    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
    setIsButtonClicked(false)
  }

  return (
    <Container className="login-form-layout">
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          resetPassword()
        }}
        className="p-2"
      >
        <Row className="justify-content-center">
          <img src={emlem} alt="" className="login-emlem" />
        </Row>
        <Row>
          <Col sm={12}>
            <div className="login-heading-title">{officeTitle}</div>
            <div className="login-sub-heading-title">{officeSubTitle}</div>
          </Col>
        </Row>
        <>
          <br />
          <Row>
            <Col sm={12}>
              <div className="login-heading-title">{'Forgot Password ?'}</div>
            </Col>
          </Row>
          <br></br>

          {!response ? (
            <>
              <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="loginUserName">
                    <Form.Label className="login-label">User Name</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        required={true}
                        type="text"
                        placeholder="Enter User Name"
                        onChange={(e) => {
                          setUsername(e.target.value)
                        }}
                      />
                      <InputGroup.Text>
                        <img src={user} alt="" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={3}></Col>
              </Row>
              <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="recaptcha">
                    <ReCAPTCHA
                      sitekey="6LeDlyUqAAAAADcAFLXbqSYzk-g0uUORuxNeKd2g" // Replace with your Site Key
                      onChange={(value: string | null) => {
                        setCaptchaValue(value)
                        setIsCaptchaVerified(!!value)
                      }} // Correctly type the value parameter
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="loginUtil">
                    <button
                      className={`login-btn ${
                        !isCaptchaVerified ? 'disabled' : ''
                      }`}
                      type="submit"
                      disabled={!isCaptchaVerified || isButtonClicked}
                    >
                      Reset Password
                    </button>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="justify-content-center">
              <Col sm={12} className="text-center">
                <Form.Group className="mb-3" controlId="loginUtil">
                  <span>
                    We have sent an email to the requested user email address
                  </span>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={3}></Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="loginUtil">
                <Row className="justify-content-end">
                  <Link className="login-link" to={''}>
                    Back to Sign in
                  </Link>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </>
      </Form>
    </Container>
  )
}

export default ForgotPassword
