import React, { FC } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { SimilarRecordModel } from '../../models/applicationRegistration/fileRegistration/similarRecordModel'
import { currencyMask } from '../../utils/utilFunctions'
import './Modals.scss'

export interface SimlarRecordViewModalProps {
  similarRecord: SimilarRecordModel
  onCancel: any
}

const SimlarRecordViewModal: FC<SimlarRecordViewModalProps> = ({
  similarRecord,
  onCancel,
}) => {
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Similar Record View
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="similar-record-modal-body">
          <Row className="mb-3">
            <Form.Group as={Col} controlId="status">
              <Form.Label>File Status</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.fileStatus}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="fileNo">
              <Form.Label>File No.</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.mapFileNo}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.applicantName}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="address">
              <Form.Label>Address of Applicant</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.addressOfTheApplicant}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="nic">
              <Form.Label>NIC</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.nic}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="damageType">
              <Form.Label>Damage Type</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.damageType}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="distric">
              <Form.Label>District</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.district}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="ds">
              <Form.Label>DS</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.dsCode}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="bank">
              <Form.Label>Bank</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.bankName}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="branch">
              <Form.Label>Branch</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.branchName}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="accountNumber">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={similarRecord.accountNumber}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="amount">
              <Form.Label>Amount (Rs.)</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={
                  similarRecord.totalAmount
                    ? currencyMask(similarRecord.totalAmount)
                    : ''
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="save-button" onClick={onCancel}>
          {`Close`}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SimlarRecordViewModal
