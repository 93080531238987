import { IApplicant360 } from '../../pages/applicant360/Applicant360'
import apiService from '../api/apiManager'

class Applicant360Service {
  async getApplicant360Data(nicObj: IApplicant360) {
    return apiService.apiPost(`/applicant360/view`, nicObj)
  }
}

export default new Applicant360Service()
