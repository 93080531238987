//import produce from 'immer'
import React, { FC } from 'react'
import { Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PackageModal } from '../../../models/package/packageModal'
import Package from './Package'
import { getPermissionList } from '../../../store/permissions/selectors'
import './PermissionList.scss'

export interface PackageProps {
  unEditable: boolean
}
const PermissionsList: FC<PackageProps> = ({ unEditable }) => {
  const allPermissionsList = useSelector(getPermissionList)

  return (
    <Container>
      <Col>
        {allPermissionsList.map((pack: PackageModal, index: number) => (
          <ul className="custom-ul" key={`package-${pack.id}`}>
            <Package
              packageModal={pack}
              packageIndex={index}
              mode={unEditable}
            />
          </ul>
        ))}
      </Col>
    </Container>
  )
}

export default PermissionsList
